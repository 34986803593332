import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import { CrmManagerPanelSidebarData } from "../../DATA/crmManagerPanelSidebarData";
import Loading from "../../Component/loading";
import Header from "../../Component/header";

import ManagerDashbord from "../CRMTeamLeadPanel/managerDashbord";
import TeamLeadsOverview from "../CRMTeamLeadPanel/teamLeadsOverview";
import CounsellorLeadOverview from "../CRMTeamLeadPanel/counsellorLeadOverview";
import LeadAssignedDateAnalysis from "../CRMTeamLeadPanel/leadAssignedDateAnalysis";
import LeadAssignedStatusOverview from "../CRMTeamLeadPanel/leadAssignedStatusOverview";
import LeadAnalysis from "./leadAnalysis";
import CounsellorCallDetails from "../CRMTeamLeadPanel/counsellorCallDetails";
import CallerDetails from "./callerDetails";
import CompleteLeadDetail from "../CRMTeamLeadPanel/completeLeadDetail";
import EnrolledAnalysis from "../CRMTeamLeadPanel/enrolledAnalysis";
import SummeryOverview from "../CRMTeamLeadPanel/summeryOverview";
import TeamShrinkageAnalysis from "./teamShrinkageAnalysis";
import CourseAnalysis from "../CRMTeamLeadPanel/courseAnalysis";
import ReferenceAnalysisManager from "./referenceAnalysisManager";
import ReferenceAnalysis from "../CRMTeamLeadPanel/referenceAnalysis";
import TeamLeadAssignedDate from "../CRMTeamLeadPanel/teamLeadAssignedDate";
import TeamLeadAssignedDateAnalysis from "../CRMTeamLeadPanel/teamLeadAssignedDateAnalysis";
import ManagerAdmissionDate from "./managerAdmissionDate";
import ManagerExamRevenue from "./managerExamRevenue";
import ManagerTargetStatus from "./managerTargetStatus";
import IncentiveTargetStatus from "../CRMCounsellorPanel/incentiveTargetStatus";
import ActiveCounsellor from "./activeCounsellor";
import ExCounsellor from "./exCounsellor";
import CouponCode from "../CRMCounsellorPanel/couponCode";
// import WIP from "../wip";
import SkipDetails from "./skipDetails";
import LeadGenerationOverview from "./leadGenerationOverview";
import LeadGenerationAnalysis from "./leadGenerationAnalysis";
import LeadSearchByMobileNumber from "../CRMCounsellorPanel/leadSearchByMobileNumber";
import TopSellableExams from "./topSellableExams";
import MonthlyReport from "../CRMTeamLeadPanel/monthlyReport";
import TimeAnalysisManager from "./timeAnalysisManager";
import RevenueAnalysisManager from "./revenueAnalysisManager";
import DailyMeeting from "./dailyMeeting";
import IncentiveAllocation from "../CRMDataOperatorPanel/incentiveAllocation";
import CourseSubscriptionMonths from "./courseSubscriptionMonths";
import TimeAnalysisOverview from "../CRMTeamLeadPanel/timeAnalysisOverview";
import ManagerRemark from "./managerRemark";
import ResultCompilation from "./resultCompilation";
import RemarkAnalysisManager from "./remarkAnalysisManager";
import UserActivityTracker from "../CRMTeamLeadPanel/userActivityTracker";
import UserActivityDetails from "../CRMTeamLeadPanel/userActivityDetails";

function ManagerPanelDashboard(Props: any) {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={CrmManagerPanelSidebarData} pagename />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<ManagerDashbord />} />
              <Route path="managerDashbord" element={<ManagerDashbord />} />
              <Route path="teamLeadsOverview" element={<TeamLeadsOverview />} />
              <Route
                path="counsellorLeadOverview"
                element={<CounsellorLeadOverview />}
              />
              <Route
                path="leadAssignedDateAnalysis"
                element={<LeadAssignedDateAnalysis />}
              />
              <Route
                path="leadAssignedStatusOverview"
                element={<LeadAssignedStatusOverview />}
              />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route
                path="counsellorCallDetails"
                element={<CounsellorCallDetails />}
              />
              <Route path="callerDetails" element={<CallerDetails />} />
              <Route
                path="completeLeadDetail"
                element={<CompleteLeadDetail />}
              />
              <Route path="enrolledAnalysis" element={<EnrolledAnalysis />} />
              <Route path="summeryOverview" element={<SummeryOverview />} />
              <Route
                path="teamShrinkageAnalysis"
                element={<TeamShrinkageAnalysis />}
              />
              <Route path="courseAnalysis" element={<CourseAnalysis />} />
              <Route
                path="referenceAnalysisManager"
                element={<ReferenceAnalysisManager />}
              />
              <Route path="referenceAnalysis" element={<ReferenceAnalysis />} />
              <Route
                path="teamLeadAssignedDate"
                element={<TeamLeadAssignedDate />}
              />
              <Route
                path="teamLeadAssignedDateAnalysis"
                element={<TeamLeadAssignedDateAnalysis />}
              />
              <Route
                path="managerAdmissionDate"
                element={<ManagerAdmissionDate />}
              />
              <Route
                path="managerExamRevenue"
                element={<ManagerExamRevenue />}
              />
              <Route
                path="managerTargetStatus"
                element={<ManagerTargetStatus />}
              />
              <Route
                path="incentiveTargetStatus"
                element={<IncentiveTargetStatus />}
              />
              <Route path="activeCounsellor" element={<ActiveCounsellor />} />
              <Route path="exCounsellor" element={<ExCounsellor />} />
              <Route path="couponCode" element={<CouponCode />} />
              <Route path="skipDetails" element={<SkipDetails />} />
              <Route
                path="leadGenerationOverview"
                element={<LeadGenerationOverview />}
              />
              <Route
                path="leadGenerationAnalysis"
                element={<LeadGenerationAnalysis />}
              />
              {/* <Route path="leadOverview" element={<LeadOverview/>} />    */}
              <Route
                path="leadSearchByMobileNumber"
                element={<LeadSearchByMobileNumber />}
              />
              <Route path="topSellableExams" element={<TopSellableExams />} />
              <Route path="monthlyReport" element={<MonthlyReport />} />
              <Route
                path="timeAnalysisManager"
                element={<TimeAnalysisManager />}
              />
              <Route
                path="revenueAnalysisManager"
                element={<RevenueAnalysisManager />}
              />
              <Route
                path="remarkAnalysisManager"
                element={<RemarkAnalysisManager />}
              />
              <Route path="dailyMeeting" element={<DailyMeeting />} />
              <Route
                path="incentiveAllocation"
                element={<IncentiveAllocation />}
              />
              <Route
                path="courseSubscriptionMonths"
                element={<CourseSubscriptionMonths />}
              />
              <Route
                path="timeAnalysisOverview"
                element={<TimeAnalysisOverview />}
              />
              <Route path="managerRemark" element={<ManagerRemark />} />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route
                path="userActivityTracker"
                element={<UserActivityTracker />}
              />
              <Route
                path="userActivityDetails"
                element={<UserActivityDetails />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default ManagerPanelDashboard;
