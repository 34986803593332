export const BDEManagementPanel = [
  {
    title: "MANAGE EMPLOYEE",
    path: "bdeEmployee",
  },
  {
    title: "INCENTIVE ALLOCATION",
    subNav: [
      {
        title: "BDE: Incentive Allocation",
        path: "bdeIncentiveAllocation",
        cName: "sub-nav",
      },
      {
        title: "Team Lead/ Manager:Incentive Allocation",
        path: "managerTLIncentiveAllocation",
        cName: "sub-nav",
      },
      
      
    ],
  },
  {
    title: "TARGETS",
    subNav: [
      {
        title: "Call Time Target",
        path: "callTimeTarget",
        cName: "sub-nav",
      },
      {
        title: "Total Connected Call Target",
        path: "totalConnectedCount",
        cName: "sub-nav",
      },
      {
        title: "Unique Call Target",
        path: "uniqueCallTarget",
        cName: "sub-nav",
      },
      {
        title: "New Department Target",
        path: "departmentCallTarget",
        cName: "sub-nav",
      },
      {
        title: "Webinar Target",
        path: "webinarTarget",
        cName: "sub-nav",
      },
      
    ],
  },
  // {
  //   title: "RESULT COMPILATION",
  //   path: "resultCompilation",
  // },
  //  {
  //   title: "STUDENT RESULT",
  //   path: "studentResult",
  // },
   
];

 
