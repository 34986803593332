import { useEffect, useState } from "react";
import PageTitle from "../../../Component/pageTitle";
import Avatar from "@mui/material/Avatar";
import LabelField from "../../../Component/labelField";
import moment from "moment";
import StudentTestData from "./studentTestData";
import { useDispatch } from "react-redux";
import { getUserAnalysisDetails } from "../../../Redux/Actions/counsellorPanelAction";

const StudentDetails = (props: any) => {
  const dispatch = useDispatch();
  const { data: studentDetails } = props;
  const [viewData, setViewData] = useState<any>({});
  const [isViewTest, setIsViewTest] = useState<any>(false);
  const [preformanceData, setPerformanceData] = useState<any>([]);
  const [dppPerformance, setDppPerformance] = useState<any>([]);
  const [unitPerformance, setUnitPerformance] = useState<any>([]);
  const [mockPerformance, setMockPerformance] = useState<any>([]);

  const renderUserDetail = () => {
    return (
      <div className="d-flex mt-4 col-md-12">
        <div className="col-md-3 d-flex pt-1 px-5">
          <Avatar
            alt={preformanceData?.users}
            src={preformanceData?.userprofilePicture}
            sx={styles.img_style}
          />
        </div>
        <div className="col-md-6 d-flex">
          <div className="col-md-3 mt-4">
            <LabelField lableName={"Name:"} />
            <LabelField lableName={"Batch Registered:"} />
            <LabelField lableName={"Batch date:"} />
          </div>
          <div className="col-md-9 mt-4">
            <LabelField
              lableName={preformanceData?.users}
              style={styles.simple_txt}
            />
            <LabelField
              lableName={preformanceData?.BatchName}
              style={styles.simple_txt}
            />
            <LabelField
              lableName={moment(preformanceData?.BatchDate).format(
                "DD MMMM YYYY"
              )}
              style={styles.simple_txt}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex mb-2">
            <div className="rounded-4 p-4" style={styles.red_color}>
              <LabelField lableName={"Total Test"} style={styles.white_color} />
              <LabelField
                lableName={preformanceData?.totaltest}
                style={styles.label_txt}
              />
            </div>
            <div className="ms-3 rounded-4 p-4" style={styles.red_color}>
              <LabelField
                lableName={"Total Live Attended"}
                style={styles.white_color}
              />
              <LabelField
                lableName={
                  preformanceData?.totalLive ? preformanceData?.totalLive : 0
                }
                style={styles.label_txt}
              />
            </div>
          </div>
          <div className="text-center rounded-4 p-4" style={styles.gray_color}>
            <LabelField lableName={"Overall Rank"} style={styles.white_color} />
            <LabelField
              lableName={preformanceData?.overAllRank}
              style={styles.label_txt}
            />
          </div>
        </div>
      </div>
    );
  };
  const TableList = (data: any, isView: any) => {
    const color_code =
      data?.data?.examType === 1
        ? "#158833"
        : data?.data?.examType === 2
        ? "#3f51b5"
        : data?.data?.examType === 3
        ? "#f23e36"
        : "";
    return (
      <>
        <div className="container-fluid pt-1 pe-0 ps-2">
          <table className="w-100 mb-2">
            <thead>
              {isView ? (
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      backgroundColor: color_code,
                      width: "20%",
                    }}
                  >
                    Test Type
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      backgroundColor: color_code,
                      width: "20%",
                    }}
                  >
                    Total Count
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      backgroundColor: color_code,
                      width: "20%",
                    }}
                  >
                    Average Rank
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      backgroundColor: color_code,
                      width: "20%",
                    }}
                  >
                    Average Mark
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      backgroundColor: color_code,
                      width: "20%",
                    }}
                  >
                    Action
                  </th>
                </tr>
              ) : (
                <tr>
                  <th style={styles.gray_style_head}>Test Type</th>
                  <th style={styles.gray_style_head}>Total Count</th>
                  <th style={styles.gray_style_head}>Hours</th>
                </tr>
              )}
            </thead>
            <tbody>
              {isView ? (
                <tr>
                  <td style={{ textAlign: "center", width: "20%" }}>
                    {data?.data?.examType === 1
                      ? "Unit Test Attempted"
                      : data?.data?.examType === 2
                      ? "Mock Test Attempted"
                      : data?.data?.examType === 3
                      ? "DPP Attempted"
                      : ""}
                  </td>
                  <td style={{ textAlign: "center", width: "20%" }}>
                    {data?.data?.totalExams === 0
                      ? 0
                      : data?.data?.totalExams
                      ? data?.data?.totalExams
                      : "-"}
                  </td>
                  <td style={{ textAlign: "center", width: "20%" }}>
                    {data?.data?.averageUserRank === 0
                      ? 0
                      : data?.data?.averageUserRank
                      ? data?.data?.averageUserRank
                      : "-"}
                  </td>
                  <td style={{ textAlign: "center", width: "20%" }}>
                    {data?.data?.totalExams === 0
                      ? 0
                      : data?.data?.totalExams
                      ? `${data?.data?.averageMarks}/${data?.data?.averageTotalMarks}`
                      : "-"}
                  </td>
                  <td
                    onClick={() => onViewDetails(data?.data?.examDetails)}
                    style={{
                      textAlign: "center",
                      width: "20%",
                      color: "#158833",
                      cursor: "pointer",
                    }}
                  >
                    View Details
                  </td>
                </tr>
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }}>Total Live Watched</td>
                  <td style={{ textAlign: "center" }}>0</td>
                  <td style={{ textAlign: "center" }}>0</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };
  const onViewDetails = (data: any) => {
    setIsViewTest(true);
    let obj = {
      studentDetails: studentDetails,
      studentData: data,
    };
    setViewData(obj);
  };
  const handleGoBack = () => {
    setIsViewTest(false);
    setViewData({});
  };

  useEffect(() => {
    let reqBody = {
      userId: studentDetails?.userId,
      courseId: studentDetails?.courseId,
    };
    dispatch(
      getUserAnalysisDetails(
        reqBody,
        (resp: any) => {
          let unitTest = resp?.exam?.find((item: any) => {
            return item?.examType === 1;
          });
          let mockTest = resp?.exam?.find((item: any) => {
            return item?.examType === 2;
          });
          let dppTest = resp?.exam?.find((item: any) => {
            return item?.examType === 3;
          });
          setUnitPerformance(unitTest);
          setMockPerformance(mockTest);
          setDppPerformance(dppTest);
          setPerformanceData(resp);
        },
        () => {}
      )
    );
  }, []);
  return (
    <>
      {!isViewTest ? (
        <div className="contentBox">
          <PageTitle
            name={"STUDENT DETAILS"}
            isGoBack
            onClick={props?.handleGoBack}
          />
          <div className="row col-12">
            <div className="formDiv formWidth p-2">
              {renderUserDetail()}
              <TableList data={dppPerformance} isView={true} />
              <TableList data={unitPerformance} isView={true} />
              <TableList data={mockPerformance} isView={true} />
              <TableList />
            </div>
          </div>
        </div>
      ) : (
        <StudentTestData data={viewData} handleBack={handleGoBack} />
      )}
    </>
  );
};

const styles: any = {
  img_style: {
    height: 150,
    width: 150,
  },
  simple_txt: {
    width: "100%",
    fontWeight: "normal",
  },
  red_color: {
    backgroundColor: "#f23e36",
  },
  gray_color: {
    backgroundColor: "#707070",
  },
  white_color: {
    color: "#ffffff",
  },
  label_txt: {
    color: "#ffffff",
    fontWeight: "normal",
  },
  gray_style_head: {
    width: "20%",
    textAlign: "center",
    backgroundColor: "#707070",
  },
};

export default StudentDetails;
