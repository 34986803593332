import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import CustomDropdown from "../../Component/customDropdown";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getExamRevenueList,
  getExamStreamList,
  getExamList,
  getSubExam,
} from "../../Redux/Actions/counsellorPanelAction";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function ManagerExamRevenue() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [examStream, setExamStream] = useState<any>("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [examRevenueList, setExamRevenueList] = useState([]);
  const [examRevenueTotal, setExamRevenueTotal] = useState<any>("");
  const [subExam, setSubExam] = useState<any>();
  const [subExamList, setSubExamList] = useState([]);
  const [resetSubExam, setResetSubExam] = useState();
  const [counsellor, setCounsellor] = useState<any>();
  const [counsellorList, setCounsellorList] = useState([]);

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    const postData = {
      startDate: startDate,
      endDate: endDate,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
    };
    dispatch(
      getExamRevenueList(
        postData,
        (res: any) => {
          setExamRevenueList(res?.data);
          setExamRevenueTotal(res?.total[0]);
        },
        () => {}
      )
    );
    dispatch(
      getEmployeetByManagerId(
        managerId,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeExam = (val: any) => {
    setExam(val);
    if (subExamList) {
      dispatch(
        getSubExam(
          {
            examTypeId: val._id,
          },
          (res: any) => {
            if (res.length > 0) {
              setSubExamList(res);
            } else {
              dispatch(
                getExamStreamList(`${val._id}`, (res: any) => {
                  setExamStreamList(res);
                })
              );
            }
          }
        )
      );
    } else {
      dispatch(
        getExamStreamList(`${val._id}`, (res: any) => {
          setExamStreamList(res);
        })
      );
    }
  };

  const onChangeSubExam = (val: any) => {
    setSubExam(val);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };
  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      counsellorId: counsellor?._id,
    };
    dispatch(
      getExamRevenueList(
        postData,
        (res: any) => {
          setExamRevenueList(res?.data);
          setExamRevenueTotal(res?.total[0]);
        },
        () => {}
      )
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
  };
  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSearch}>
        <div className="col-md-4 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
              // Isrequired={true}
              reset={resetExam}
            />
          </div>
        </div>
        {subExamList?.length > 0 ? (
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Sub Exam Name" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Sub Exam Name"
                setInputText={(value: any) => onChangeSubExam(value)}
                value={subExam}
                options={subExamList}
                reset={resetSubExam}
                // reset={resetSubexamList}
              />
            </div>
          </div>
        ) : null}
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Stream" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam Stream"
              setInputText={(value: any) => onChangeExamStream(value)}
              value={examStream}
              options={examStreamList}
              // Isrequired={true}
              reset={resetExamStream}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Select Counsellor" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Counsellor"
              setInputText={(value: any) => onChangeCounsellor(value)}
              value={counsellor}
              options={counsellorList}
              defaultValue={counsellorList?.length > 0 ? counsellor : null}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };
  const renderListMode = () => {
    return (
      <div className="counsellortable table-responsive w-100">
        <table className=" w-100 text-center table-bordered border-secondary ">
          <tr>
            <th className=" text-start">Total</th>
            <th className=" text-start"> </th>
            <th className="text-primary">
              {examRevenueTotal?.TotalOnlineCourseAmount}
            </th>
            <th className="text-primary">
              {examRevenueTotal?.TotalStudyMaterialAmount}
            </th>
            {/* <th className="text-primary">
              {examRevenueTotal?.TotalClassroomAmount}
            </th> */}
          </tr>

          <tr>
            <th className=" text-start">Exam Name</th>
            <th className=" ">Exam Stream</th>
            <th>Online Course</th>
            <th>Study Material</th>
            {/* <th>Classroom</th> */}
          </tr>
          {examRevenueList?.length > 0 ? (
            <>
              {examRevenueList?.map((value: any) => {
                return (
                  <tbody>
                    <tr>
                      <td className=" text-start">
                        {value?.examName ? value?.examName : "-"}
                      </td>
                      <td className="">
                        {value?.baseCourseName ? value?.baseCourseName : "-"}
                      </td>
                      <td>{value?.OnlineCourseAmount}</td>
                      <td>{value?.StudyMaterialAmount}</td>
                      {/* <td>{value?.ClassroomAmount}</td> */}
                    </tr>
                  </tbody>
                );
              })}
            </>
          ) : (
            <tr> No Record Found</tr>
          )}
        </table>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Exam Revenue Based On Admission Date"} />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">
          {renderSearchMode()}

          <div>
            <h4 className="fw-bold">
              Grand Total={" "}
              <span className="required">{examRevenueTotal?.GrantTotal}</span>
            </h4>
          </div>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
