import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
}

export default function  EmailPopUp(props: props) {
  const {
    open,
    handleClose,
    data
  } = props
  const percentage = 66;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldivChat text-center p-md-4 py-md-4 ">
          <div className="d-flex justify-content-center ">
         <div className="col-md-4 ">
            
     Email content
           </div>
           </div>
           <div className="mt-4">
            <p className="text-primary fw-bold fs-3">Please Wait!</p>
           <p className="fs-3 ">Lead   is in progress...</p>
           </div>
           
        </div>
      </Fade>
    </Modal>

  );
}
