import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import moment from "moment";
import SalesPanelSummeryGraph from "../../Component/salesPanelSummeryGraph";
import SalesPanelSellableGraph from "../../Component/salesPanelSellableGraph";
import RoundChart from "../../Component/roundChart";
import CustomDropdown from "../../Component/customDropdown";
import { useSelector } from "react-redux";
import SalesPanelGraph from "../../Component/salesPanelGraph";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import SubmitButton from "../../Component/submitButton";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
import { getSMManagerList } from "../../Redux/Actions/superManagerAction";

export default function RevenueAnalysisSuperManager() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [totalRevenue, SetTotalRevenue] = useState<any>("");
  const [targetStartDate, SetTargetStartDate] = useState<any>("");
  const [targetEndDate, SetTargetEndDate] = useState<any>("");
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata, setGraphdata1] = useState<any>([]);
  const [piedata, setPiedata] = useState<any>([]);
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [graphSellable, setGraphSellable] = useState(false);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [ExamDataList, setExamDataList] = useState<any>([]);
  const [sellableExamListByExamID, setSellableExamListByExamID] = useState<any>(
    []
  );
  const [linkList, setLinkList] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [xLabel4, setXLabel4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [examId, setExamId] = useState("");
  const [examStreamId, setExamStreamId] = useState("");
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState(false);
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState(false);

  const { loggedInUserRole, loggedInUserId } = useSelector(
    (state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      crmEmployeeId: state.Auth.counsellorId,
      loggedInUserName: state.Auth.loggedInUserName,
      loggedInUserId: state.Auth.loggedInUserId,
    })
  );
  useEffect(() => {
    if (loggedInUserRole === 1) {
      setGraphSellable(true);
    }
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
  }, [loggedInUserRole]);

  const link3 = ["../topSellableExams"];

  const Piechartdata = {
    labels: ["Achieved Revenue", "Unachieved Revenue"],
    datasets: [
      {
        data: piedata,
        backgroundColor: ["#1F77B4", "#F23E36"],
        percentageInnerCutout: 80,
      },
    ],
  };
  const piechartData = (list: any) => {
    let arr = [];
    let unachieved = 0;
    let achieved = 0;
    list?.map((item: any) => {
      achieved = achieved + item?.totalRevenue;
      unachieved = unachieved + item?.UnachievedRevenue;
      SetTargetStartDate(item?.data?.startDate);
      SetTargetEndDate(item?.data?.endDate);
    });
    arr?.push(achieved);
    arr?.push(unachieved);
    setPiedata(arr);
  };
  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );

 
  }, []);

  const onChange = (e: any) => {
    e.preventDefault();
 
  };

  const showGraph = (list: any) => {
    let total = 0;
    while (xLabel.length) {
      xLabel.pop();
      Graphdata?.pop();
    }
    list?.map((item: any) => {
      total = total + item?.Revenue;
      xLabel?.push(item?.name);
      Graphdata?.push(item?.Revenue);
    });
    SetTotalRevenue(total);
  };

  const showGraph2 = (data: any) => {
    while (xLabel3.length) {
      xLabel3.pop();
      Graphdata4?.pop();
    }
    data?.map((val: any) => {
      xLabel3?.push(val?.exam);
      Graphdata4?.push(val?.revenue);
    });
  };

  const showGraph3 = (val: any) => {
    while (xLabel4.length) {
      xLabel4.pop();
      Graphdata5?.pop();
    }
    val?.map((data: any) => {
      xLabel4?.push(data?.examStream);
      Graphdata5?.push(data?.revenue);
    });
  };

  const getExamData = (val: any) => {
  
  };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  const getExamStreamData = (val: any) => {
    setExamStreamId(sellableExamListByExamID[val?.index]?.baseCourseId);
    setExamId(ExamDataList[val?.index]?.examTypeId);

    sessionStorage.setItem(
      "ExamStreamId",
      sellableExamListByExamID[val?.index]?.baseCourseId
    );
    sessionStorage.setItem("examId", ExamDataList[val?.index]?.examTypeId);
  };
  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
        
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);
 
  
  };
  const getManagerData = (val: any) => {
   
      // dispatch(updateCommonValues("selectedLeadId", tempData?._id));
      navigate("../summeryOverview", {
        state: {
          pageName: "summaryOverview",
          // counsellorId: teamLeadList[val?.index]?._id,
        },
      });
     
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Revenue Analysis"} />
      <div className="col-md-12 row">
        <div className="formDiv formWidth p-2">
          <form className="d-flex row px-5  mx-5" onSubmit={onChange}>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date :" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date :" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 mt-2">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Select Manager" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                  // Isrequired={true}
                  reset={resetManager}
                />
              </div>
            </div>
        <div className='col-md-4 d-flex mb-3'>
            <div className='col-md-3 mt-2'>
              <LabelField lableName='Select Team Lead' />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Team Lead"
                setInputText={(value: any) =>
                  onChangeTeamLead(value)
                }
                value={teamLead}
                options={teamLeadList}
                 Isrequired={true}
                reset={resetTeamLead}

              /></div>
          </div>
            <div className=" col-md-3 text-center mb-2  mt-2 me-4 ">
              <SubmitButton name={"Submit"} />
            </div>
          </form>
          <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
            <div className="col-md-8 p-3">
              <h4 className="fw-bold">Summary Overview </h4>
              <span className="fw-bold">Total Revenue : {totalRevenue}</span>
              {/* <SalesPanelSummeryGraph
                label={xLabel}
                value={Graphdata}
                link={linkList}
              /> */}
              <SalesPanelSatckGraph
                label={xLabel}
                value={Graphdata}
                getManagerData={(val: any) => getManagerData(val)}
              />
            </div>
            <div className="col-md-4 p-3 divBackground  ">
              <h4 className="fw-bold">Revenue Achieved</h4>
              {/* {targetStartDate && targetEndDate ? (
                <div className="d-flex">
                  <span className="me-2">
                    Start Date - {moment(targetStartDate).utc().format("DD-MM-YYYY")}
                  </span>
                  <span>End Date - {moment(targetEndDate).utc().format("DD-MM-YYYY")}</span>
                </div>
              ) : null} */}

              <p>Out Of</p>

              <div className=" d-flex justify-content-center">
                <RoundChart data={Piechartdata} />
              </div>
            </div>
          </div>
          {graphSellable ? (
            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-6 p-3">
                <h4 className="fw-bold">Top Sellable Exam</h4>
                <SalesPanelSellableGraph
                  label={xLabel3}
                  value={Graphdata4}
                  getExamData={(val: any) => [getExamData(val), setShow(true)]}
                />
              </div>
              {show ? (
                <div className="col-md-6 p-3">
                  <h4 className="fw-bold"> Top Sellable Exam Stream</h4>
                  <div className=" d-flex justify-content-center">
                    <SalesPanelGraph
                      label={xLabel4}
                      value={Graphdata5}
                      link={link3}
                      sDate={startDate}
                      eDate={endDate}
                      examId={examId}
                      managerId={manager?._id}
                      examStreamId={examStreamId}
                      getExamStreamData={(val: any) => getExamStreamData(val)}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
