
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { Chart } from 'chart.js'
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface props {
  label: any,
  value: any
  link?:any,
  getManagerData?:any,
  getExamData?:any,

}



export default function SalesPanelSellableGraph({ label, value ,link,getExamData,}: props) { 

  const navigate = useNavigate();
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
    
    },
    scales:{
      x:{
        stacked:true
      },
      y:{
        stacked:true
      },
    }

  };
  
  const graphData = {
    labels: label,
    datasets: [
      {
        label:"Count",
        data: value,
        backgroundColor: [
          "#1F77B4",
          "#1F77B4",
        ],
        borderColor: [
          "#1F77B4",
          "blue",
        ],
        borderWidth: 0.5,
        barPercentage: 0.2,
   

      },
      // {
      //   label: barLabel2,
      //   data: value,
      //   backgroundColor: [
      //     "#F23E36",
      //     "#1F77B4",
      //   ],
      //   borderColor: [
      //     "#F23E36",
      //     "blue",
      //   ],
      //   borderWidth: 0.5,
      //   barPercentage: 0.5,
   

      // }

    ]


  };
  
  const chartRef:any = useRef();

  const onClick =(event:any)=>{
      if (chartRef.current) {
        let selectedBar=getElementsAtEvent(chartRef.current, event);
        if(selectedBar?.length>0){
          getExamData(selectedBar[0])
        }
     }
    }
  return (
    <Bar options={options} data={graphData} onClick={onClick} ref={chartRef} />
    
  );
}
