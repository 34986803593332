import React from "react";
interface props {
  lableName?: string,
   id?: string,
  disabled?: any,
  isActive?: any,
  getToggleval?: any,
}

export default function ToggleSwitchbtn({ id,lableName,  disabled ,isActive, getToggleval }: props) {
  return (
    <div className="ps-0 form-check form-switch d-flex">
      <label
        className="col-2 form-check-label label"
        htmlFor="flexSwitchCheckDefault"
      >
        { lableName}
      </label>
      <input
        id={ id}
        className="form-check-input"
        type="checkbox"
        role="switch"
        disabled={ disabled}
        defaultChecked={isActive}
        onChange={ getToggleval}
        checked={isActive}
        
      />
      
    </div>
  );
}

 
