import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  title?: any;
  handleSubmitRestore: Function;
  buttonTitle?: any;
}

export default function RestorePopup(props: props) {
  const {
    open,
    handleClose,
    data,
    title,
    handleSubmitRestore,
    buttonTitle,
  } = props

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldivChat p-3">
            <div className="col mb-4 d-flex justify-content-between ">
              <p className="fw-bold">
                <span style={{ color: "#3F51B5" }}> {title}</span>
              </p>
              <ClearOutlinedIcon
                className="colorPurple cursor"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className="form-check">
              <div>
                <p className="text-primary fs-3">{data}</p>
              </div>
              {title !== "Manager Remark" ? (
                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => {
                      handleClose();
                    }}
                    type="submit"
                    className="btn btn-outline-dark mb-3 submitbtn me-3"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleSubmitRestore();
                    }}
                    type="submit"
                    className="btn btn-primary mb-3 submitbtn"
                  >
                    {buttonTitle}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
