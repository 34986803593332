import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";

import SalesPanelGraph from "../../Component/salesPanelGraph";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import RoundChart from "../../Component/roundChart";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SalesPanelSellableGraph from "../../Component/salesPanelSellableGraph";
import CustomDropdown from "../../Component/customDropdown";
import GenaralTable from "../../Component/genaralTable";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import {
  getRevenue,
  getSMManagerList,
  getTalkTimeData,
} from "../../Redux/Actions/superManagerAction";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import SubmitButton from "../../Component/submitButton";

export default function SuperAdminDashbord() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [totalRevenue, SetTotalRevenue] = useState<any>("");
  const [targetStartDate, SetTargetStartDate] = useState<any>("");
  const [targetEndDate, SetTargetEndDate] = useState<any>("");
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata, setGraphdata1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);
  const [piedata, setPiedata] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [xLabel4, setXLabel4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [ExamDataList, setExamDataList] = useState<any>([]);
  const [sellableExamListByExamID, setSellableExamListByExamID] = useState<any>(
    []
  );
  const [examId, setExamId] = useState("");
  const [examDataId, setExamDataId] = useState("");
  const [talkTimeList, setTalkTimeList] = useState([]);
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [graphSellable, setGraphSellable] = useState(false);
  const [examStreamId, setExamStreamId] = useState("");
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const [xLabelCallAll, setXLabelCallAll] = useState<any>([]);
  const [GraphdataCallAll, setGraphdataCallAll] = useState<any>([]);
  const [xLabelCallUnique, setXLabelCallUnique] = useState<any>([]);
  const [GraphdataCallUnique, setGraphdataCallUnique] = useState<any>([]);

  const { loggedInUserRole, loggedInUserId, loggedInUserName } = useSelector(
    (state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserName: state.Auth.loggedInUserName,
    })
  );

  const link1 = [
    "../sMSummeryOverview",
    "../sMSummeryOverview",
    "../sMSummeryOverview",
    "../sMSummeryOverview",
    "../sMSummeryOverview",
    "../sMSummeryOverview",
    "../sMSummeryOverview",
  ];

  const link2 = ["../teamShrinkageAnalysis", "../teamShrinkageAnalysis"];
  const Piechartlabels = ["Unachieved Revenue", "Achieved Revenue"];
  const link3 = ["../topSellableExams"];

  const Piechartdata = {
    labels: ["Achieved Revenue", "Unachieved Revenue"],
    datasets: [
      {
        data: piedata,
        backgroundColor: ["#1F77B4", "#F23E36"],
        percentageInnerCutout: 80,
      },
    ],
  };

  const showGraph = (list: any) => {
    let total = 0;
    while (xLabel.length) {
      xLabel.pop();
      Graphdata?.pop();
    }
    list?.map((item: any) => {
      total = total + item?.Revenue;
      xLabel?.push(item?.name);
      Graphdata?.push(item?.Revenue);
    });
    SetTotalRevenue(total);
  };

  const piechartData = (list: any) => {
    let arr = [];
    let unachieved = 0;
    let achieved = 0;
    list?.map((item: any) => {
      achieved = achieved + item?.totalRevenue;
      unachieved = unachieved + item?.UnachievedRevenue;
      SetTargetStartDate(item?.data?.startDate);
      SetTargetEndDate(item?.data?.endDate);
    });
    arr?.push(achieved);
    arr?.push(unachieved);
    setPiedata(arr);
  };

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );

    dispatch(
      getRevenue(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraph(res?.data);
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );

    dispatch(
      getTalkTimeData(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          setTalkTimeList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const showGraph2 = (data: any) => {
    while (xLabel3.length) {
      xLabel3.pop();
      Graphdata4?.pop();
    }
    data?.map((val: any) => {
      xLabel3?.push(val?.exam);
      Graphdata4?.push(val?.revenue);
    });
  };

  const showGraph3 = (val: any) => {
    while (xLabel4.length) {
      xLabel4.pop();
      Graphdata5?.pop();
    }
    val?.map((data: any) => {
      xLabel4?.push(data?.examStream);
      Graphdata5?.push(data?.revenue);
    });
  };

  const onChangeManager = (value: any) => {
    setManager(value);
    setGraphSellable(true);
    dispatch(
      getEmployeetByManagerId(
        value?._id,
        (res: any) => {
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let data = {
      startDate,
      endDate,
      teamLeadId: teamLead?._id,
      managerId: manager?._id,
    };

    dispatch(
      getTalkTimeData(
        data,
        (res: any) => {
          setTalkTimeList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (value: any) => {
    setTeamLead(value);
  };
  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    // dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    // dispatch(updateCommonValues("endDate", val));
  };

  const getManagerData = (val: any) => {
    // dispatch(updateCommonValues("selectedLeadId", tempData?._id));
    navigate("../sMSummeryOverview", {
      state: {
        pageName: "summaryOverview",
        counsellorId: teamLeadList[val?.index],
      },
    });
  };
  const renderListMode = () => {
    return (
      <div className=" col-md-12 formWidth">
        <h4 className="fw-bold">Team Talk Time</h4>
        <form className="  " onSubmit={handleSubmit}>
          <div className=" row  d-flex justify-content-between">
            <div className="col-md-3 d-flex justify-content-between">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date :" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-between">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date :" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-between">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Manager" />
              </div>
              <div className="col-md-8 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-between">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Team Lead" />
              </div>
              <div className="col-md-8 pe-2">
                <CustomDropdown
                  lableName="Select Team Lead"
                  setInputText={(value: any) => onChangeTeamLead(value)}
                  value={teamLead}
                  options={teamLeadList}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 text-center mt-3">
            <SubmitButton name={"Submit"} />
          </div>
        </form>
        <GenaralTable
          tableData={talkTimeList ? talkTimeList : []}
          pagename={"managerDashboard"}
          tableHead={[
            "Name",
            "Total",
            // "12AM-1AM",
            // "1AM-2AM",
            // "2AM-3AM",
            // "3AM-4AM",
            // "4AM-5AM",
            // "5AM-6AM",
            // "6AM-7AM",
            // "7AM-8AM",
            // "8AM-9AM",
            "9AM-10AM",
            "10AM-11AM",
            "11AM-12PM",
            "12PM-1PM",
            "1PM-2PM",
            "2PM-3PM",
            "3PM-4PM",
            "4PM-5PM",
            "5PM-6PM",
            "6PM-7PM",
            "7PM-8PM",
            "8PM-9PM",
            "9PM-10PM",
            "10PM-11PM",
            "11PM-12AM",
          ]}
        />
      </div>
    );
  };
  return (
    <div className="contentBox">
      {/* <PageTitle name={" "} /> */}
      <div className="formDiv  row">
        <div className="col-md-12  formWidth p-5  ">
          <h3 className="fw-bold text-primary">Hi,{loggedInUserName}</h3>
          <h5 className="fw-bold text-primary">Today's Dashboard</h5>
          <h6>Date: {moment.utc(startDate).format("DD-MM-YYYY")}</h6>

          <div className="d-flex justify-content-between px-5 mt-3 boxshadow">
            <div className="col-md-7 p-3">
              <h4 className="fw-bold">Summary Overview </h4>
              <span className="fw-bold">Total Revenue : {totalRevenue}</span>
              <SalesPanelSatckGraph
                label={xLabel}
                value={Graphdata}
                getManagerData={(val: any) => getManagerData(val)}
              />
            </div>
            <div className="col-md-4 p-3 divBackground  ">
              <h4 className="fw-bold">Revenue Achieved</h4>
              {targetStartDate && targetEndDate ? (
                <div className="d-flex">
                  <span className="me-2">
                    Start Date -{" "}
                    {moment(targetStartDate).utc().format("DD-MM-YYYY")}
                  </span>
                  <span>
                    End Date -{" "}
                    {moment(targetEndDate).utc().format("DD-MM-YYYY")}
                  </span>
                </div>
              ) : null}

              <p>Out Of</p>

              <div className=" d-flex justify-content-center">
                <RoundChart data={Piechartdata} />
              </div>
            </div>
          </div>

          {/* <div className="col-md-12 d-flex justify-content-center">
            <div className="col-md-5 d-flex mb-3 pe-3 mt-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Manager" />
              </div>
              <div className="col-md-8 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 pe-3 mt-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Team Lead" />
              </div>
              <div className="col-md-5 pe-2">
                <CustomDropdown
                  lableName="Select Team Lead"
                  setInputText={(value: any) => onChangeTeamLead(value)}
                  value={teamLead}
                  options={teamLeadList}
                />
              </div>
            </div>
          </div> */}
          <div className="row d-flex justify-content-between">
            <div className="col-md-6 p-3">
              <div className="row d-flex justify-content-between">
                <h5 className="col-md-8 fw-bold text-primary">
                  Top 5 Manager Revenue{" "}
                  <span className=" font12 text-secondary">
                    {" "}
                    (Amount in lakh)
                  </span>
                </h5>
                <p className="col-md-4 fw-bold font12 "> Today's Revenue</p>
              </div>

              <span className="fw-bold">Total Revenue : {totalRevenue}</span>
              <SalesPanelSatckGraph
                label={xLabel}
                value={Graphdata}
                getManagerData={(val: any) => getManagerData(val)}
              />
            </div>
            <div className="col-md-6 p-3">
              <div className="row d-flex justify-content-between">
                <h5 className="col-md-8 fw-bold text-primary">
                  Top 5 Counsellor Revenue{" "}
                  <span className=" font12 text-secondary">
                    {" "}
                    (Amount in lakh)
                  </span>
                </h5>
                <p className="col-md-4 fw-bold font12 "> Today's Revenue</p>
              </div>

              <span className="fw-bold">Total Revenue : {totalRevenue}</span>
              <SalesPanelSatckGraph
                label={xLabel}
                value={Graphdata}
                getManagerData={(val: any) => getManagerData(val)}
              />
            </div>
            <div className="col-md-6 p-3">
              <div className="row d-flex justify-content-between">
                <h5 className="col-md-8 fw-bold text-primary">
                  Top 5 Sell Course
                  <span className=" font12 text-secondary">
                    {" "}
                    (Amount in lakh)
                  </span>
                </h5>
                <p className="col-md-4 fw-bold font12 "> Today's Revenue</p>
              </div>
              <span className="fw-bold">Total Revenue : {totalRevenue}</span>
              <SalesPanelSatckGraph
                label={xLabel}
                value={Graphdata}
                getManagerData={(val: any) => getManagerData(val)}
              />
            </div>
            <div className="col-md-6 ">
              <h5 className="fw-bold text-primary">Top Sell Course</h5>
              <RoundChart data={Piechartdata} />
            </div>
          </div>

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
