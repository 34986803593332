import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import SubmitButton from "../../Component/submitButton";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import InputField from "../../Component/inputField";
import {
  containsNumber,
  containsNumberDecimal,
  length_One,
  length_Ten,
  length_Three,
  validateDecimalNumber,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  addIncentiveTarget,
  deleteIncentiveTarget,
  copyIncentiveTarget,
  getEmployeeRoleList,
  getManagerList,
  getSourceTypeList,
  getIncentiveTargetList,
  updateIncentiveTarget,
} from "../../Redux/Actions/leadManagementAction";
import Button from "../../Component/button";
import RestorePopup from "../../Component/restorePopup";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import moment from "moment";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";

export default function IncentiveAllocation() {
  const dispatch = useDispatch();
  const [searchStartDate, SetSearchStartDate] = useState<any>(
    moment().weekday(1).format("YYYY-MM-DD")
  );
  const [searchEndDate, SetSearchEndDate] = useState<any>(
    moment().weekday(7).format("YYYY-MM-DD")
  );
  const [searchCounsellorType, setSearchCounsellorType] = useState<any>("");
  const [searchManager, setSearchManager] = useState<any>("");
  const [searchCounsellor, setSearchCounsellor] = useState<any>("");

  const [resetSearchCounsellorType, setResetSearchCounsellorType] =
    useState(false);
  const [resetSearchManager, setResetSearchManager] = useState(false);
  const [resetSearchCounsellor, setResetSearchCounsellor] = useState(false);

  const [searchCounsellorTypeList, setSearchCounsellorTypeList] = useState([]);
  const [searchManagerList, setSearchManagerList] = useState([]);
  const [searchCounsellorList, setSearchCounsellorList] = useState([]);

  const [openAddIncentive, setOpenAddIncentive] = useState(false);
  const [incentiveId, setIncentiveId] = useState<any>("");
  const [addStartDate, setAddStartDate] = useState<any>("");
  const [addEndDate, setAddEndDate] = useState<any>("");
  const [source, setSource] = useState<any>("");
  const [counsellorType, setCounsellorType] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [counsellor, setCounsellor] = useState<any>("");
  const [bronzeTarget, setBronzeTarget] = useState<any>("");
  const [bronzeIncentivePercentage, setBronzeIncentivePercentage] =
    useState<any>("");

  const [sourceList, setSourceList] = useState([]);
  const [counsellorTypeList, setCounsellorTypeList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [counsellorList, setCounsellorList] = useState([]);

  const [resetSource, setResetSource] = useState(false);
  const [resetCounsellorType, setResetCounsellorType] = useState(false);
  const [resetManager, setResetManager] = useState(false);
  const [resetCounsellor, setResetCounsellor] = useState(false);

  const [newStartDate, setNewStartDate] = useState<any>("");
  const [newEndDate, setNewEndDate] = useState<any>("");

  const [incentiveList, setIncentiveList] = useState<any>("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModeCopy, setIsEditModeCopy] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(
      getEmployeeRoleList((res: any) => {
        let tempArr = res?.filter(
          (item: any) => item?._id !== 1 && item?._id !== 2
        );
        setSearchCounsellorTypeList(tempArr);
        setCounsellorTypeList(tempArr);
      })
    );
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
      })
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: searchStartDate,
        endDate: searchEndDate,
        counsellorType: searchCounsellorType?._id,
        managerId: searchManager?._id,
        counsellorId: searchCounsellor?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: searchStartDate,
        endDate: searchEndDate,
        counsellorType: searchCounsellorType?._id,
        managerId: searchManager?._id,
        counsellorId: searchCounsellor?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: searchStartDate,
        endDate: searchEndDate,
        counsellorType: searchCounsellorType?._id,
        managerId: searchManager?._id,
        counsellorId: searchCounsellor?._id,
      };
    }

    if (data["counsellorType"] === "" || data["counsellorType"] === undefined) {
      delete data["counsellorType"];
    }
    if (data["managerId"] === "" || data["managerId"] === undefined) {
      delete data["managerId"];
    }
    if (data["counsellorId"] === "" || data["counsellorId"] === undefined) {
      delete data["counsellorId"];
    }

    dispatch(
      getIncentiveTargetList(data, (res: any) => {
        setCount(res?.count);
        setIncentiveList(res?.data);
      })
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
  };

  const onChangeSearchCounsellorType = (val: any) => {
    setSearchCounsellorType(val);
    setResetSearchCounsellorType(false);
    setResetSearchManager(true);
    setResetSearchCounsellor(true);
    setSearchManagerList([]);
    setSearchCounsellorList([]);
    dispatch(
      getManagerList(
        val?._id,
        (res: any) => {
          setSearchManagerList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSearchManager = (val: any) => {
    setSearchManager(val);
    setResetSearchManager(false);
    setResetSearchCounsellor(true);
    setSearchCounsellorList([]);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setSearchCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeSearchCounsellor = (val: any) => {
    setSearchCounsellor(val);
    setResetSearchCounsellor(false);
  };

  const handleOpenAddIncentive = () => {
    setOpenAddIncentive(true);
  };

  const getList = () => {
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      startDate: searchStartDate,
      endDate: searchEndDate,
      counsellorType: searchCounsellorType?._id,
      managerId: searchManager?._id,
      counsellorId: searchCounsellor?._id,
    };

    if (
      postData["counsellorType"] === "" ||
      postData["counsellorType"] === undefined
    ) {
      delete postData["counsellorType"];
    }
    if (postData["managerId"] === "" || postData["managerId"] === undefined) {
      delete postData["managerId"];
    }
    if (
      postData["counsellorId"] === "" ||
      postData["counsellorId"] === undefined
    ) {
      delete postData["counsellorId"];
    }
    dispatch(
      getIncentiveTargetList(postData, (res: any) => {
        setCount(res?.count);
        setIncentiveList(res?.crmTargetList);
      })
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    getList();
  };
  const onChangeCounsellorType = (val: any) => {
    setCounsellorType(val);
    setResetCounsellorType(false);
    setResetManager(true);
    setResetCounsellor(true);
    setManagerList([]);
    setCounsellorList([]);
    dispatch(
      getManagerList(
        val?._id,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
  };

  const onChangeManager = (val: any) => {
    setManager(val);
    setResetManager(false);
    setResetCounsellor(true);
    setCounsellorList([]);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    setResetCounsellor(false);
  };

  const validateMinMaxLength = (value: any, maxValidationLength: any) => {
    const isValid = validateMaxLength(value, maxValidationLength);
    return isValid;
  };

  const validateMaxLength = (value: any, maxValidationLength: any) => {
    const errorMsg = `Length greater than ${maxValidationLength} not allowed`;
    if (maxValidationLength != null && value.length > maxValidationLength) {
      dispatch(showErrorSnackbar(errorMsg) as any);
      return false;
    }
    return true;
  };

  const onchangeBronzeTarget = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setBronzeTarget(val);
        return;
      }
    } else {
      setBronzeTarget(val);
    }
  };

  const onChangeBronzeIncentivePercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setBronzeIncentivePercentage(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setBronzeIncentivePercentage("");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setOpenAddIncentive(false);
    const PostData = {
      startDate: addStartDate,
      endDate: addEndDate,
      sourceType: source?._id,
      counsellorType: counsellorType?._id,
      managerId: manager?._id,
      counsellorId: counsellor?._id,
      target: {
        bronze_level: {
          incentivetarget: bronzeTarget,
          percent: bronzeIncentivePercentage,
        },
      },
    };

    if (
      PostData["counsellorType"] === "" ||
      PostData["counsellorType"] === undefined
    ) {
      delete PostData["counsellorType"];
    }

    dispatch(
      addIncentiveTarget(PostData, () => {
        getList();
      })
    );
  };

  const handleEdit = (value: any) => {
    setIsEditMode(true);
    setIncentiveId(value?._id);
    setAddStartDate(value?.startDate?.substring(0, 10));
    setAddEndDate(value?.endDate?.substring(0, 10));
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
        setSource(value?.sourcetype);
      })
    );
    dispatch(
      getEmployeeRoleList((res: any) => {
        let tempArr = res?.filter(
          (item: any) => item?._id !== 1 && item?._id !== 2
        );
        setCounsellorTypeList(tempArr);
        setCounsellorType(value?.counsellorType);
      })
    );
    dispatch(
      getManagerList(
        value?.counsellorType?._id,
        (res: any) => {
          setManagerList(res);
          setManager(value?.managerId);
        },
        () => {}
      )
    );
    dispatch(
      getEmployeetByManagerId(
        value?.managerId?._id,
        (res: any) => {
          setCounsellorList(res?.data);
          setCounsellor(value?.counsellorId);
        },
        () => {}
      )
    );
    setBronzeTarget(value?.target?.bronze_level?.incentivetarget);
    setBronzeIncentivePercentage(value?.target?.bronze_level?.percent);
  };

  const handleEditNew = (value: any) => {
    setIsEditModeCopy(true);
  };

  const handleDelete = (value: any) => {
    setOpenDeletePopup(true);
    setIncentiveId(value?._id);
  };

  const handleSubmitDelete = () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteIncentiveTarget(incentiveId, () => {
        getList();
      })
    );
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };

  const cancelAddIncentive = () => {
    setOpenAddIncentive(false);
    setIsEditMode(false);
    setIsEditModeCopy(false);
  };

  const cancelEditIncentive = () => {
    setIsEditMode(false);
  };

  const cancelEditNew = () => {
    setIsEditModeCopy(false);
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: addStartDate,
      endDate: addEndDate,
      counsellorType: counsellorType?._id,
      sourceType: source?._id,
      target: {
        bronze_level: {
          incentivetarget: bronzeTarget,
          percent: bronzeIncentivePercentage,
        },
      },
    };
    dispatch(
      updateIncentiveTarget(incentiveId, postData, () => {
        setIsEditMode(false);
        setSource("");
        setResetSource(true);
        setCounsellorType("");
        setResetCounsellorType(true);
        setManager("");
        setResetManager(true);
        setCounsellor("");
        setResetCounsellor(true);
        setBronzeTarget("");
        setBronzeIncentivePercentage("");

        getList();
      })
    );
  };

  const handleUpdateEdit = (e: any) => {
    let newArrr: Array<string> = [];
    incentiveList?.map((data: any, index: any) => {
      newArrr.push(data?._id);
    });
    e.preventDefault();
    const postData = {
      startDate: newStartDate,
      endDate: newEndDate,
      records: newArrr,
    };
    dispatch(
      copyIncentiveTarget(postData, () => {
        setIsEditModeCopy(false);
        setNewStartDate("");
        setNewEndDate("");
        getList();
      })
    );
  };

  const onChangeSource = (value: any) => {
    setSource(value);
  };

  const renderSearchMode = () => {
    return (
      <>
        <form
          className="row d-flex justify-content-between"
          onSubmit={handleSearch}
        >
          <div className="col-md-4 d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={searchStartDate}
                setInputText={(value: string) => SetSearchStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={searchEndDate}
                setInputText={(value: string) => SetSearchEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-2">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>

            <div className="col-md-2 ms-3">
              <button
                className="btn btn-primary "
                type="button"
                onClick={() => handleEditNew(incentiveList)}
              >
                {" "}
                Edit
              </button>
            </div>
          </div>
          <div className="col-md-4 d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Counsellor Type:" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Counsellor Type"
                setInputText={(value: any) =>
                  onChangeSearchCounsellorType(value)
                }
                value={searchCounsellorType}
                options={searchCounsellorTypeList}
                reset={resetSearchCounsellorType}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Manager:" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeSearchManager(value)}
                value={searchManager}
                options={searchManagerList}
                reset={resetSearchManager}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Counsellor:" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Counsellor"
                setInputText={(value: any) => onChangeSearchCounsellor(value)}
                value={searchCounsellor}
                options={searchCounsellorList}
                reset={resetSearchCounsellor}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between my-3">
            <div className="col-md-6 ">
              <button
                className="btn btn-primary "
                type="button"
                onClick={handleOpenAddIncentive}
              >
                {" "}
                Add Incentive
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {incentiveList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"IncentiveAllocation"}
            count={count}
            tableData={incentiveList ? incentiveList : []}
            onTabChange={(e: any, index: any) => showData(e, index)}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={incentiveList ? incentiveList : []}
          pagename={"IncentiveTargetAllocation"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Source",
            "Counsellor Type",
            "Manager Name",
            "Counsellor Name",
            "Bronze Incentive",
            "Edit",
            "Delete",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const renderAddIncentiveMode = () => {
    return (
      <>
        <form
          className="col-md-12 row"
          onSubmit={!isEditMode ? handleSubmit : handleUpdate}
        >
          <div className="row">
            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <DatePicker
                  value={addStartDate}
                  setInputText={(value: string) => setAddStartDate(value)}
                  Isrequired={true}
                  defaultValue={addStartDate}
                  // mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <DatePicker
                  value={addEndDate}
                  setInputText={(value: string) => setAddEndDate(value)}
                  Isrequired={true}
                  defaultValue={addEndDate}
                  mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Source:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Source"
                  setInputText={(value: any) => onChangeSource(value)}
                  value={source}
                  options={sourceList}
                  defaultValue={sourceList?.length > 0 ? source?._id : ""}
                  reset={resetSource}
                />
              </div>
            </div>
            <div className="col-6 d-flex my-2 ">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Counsellor Type:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Counsellor Type"
                  setInputText={(value: any) => onChangeCounsellorType(value)}
                  value={counsellorType}
                  options={counsellorTypeList}
                  defaultValue={
                    counsellorTypeList?.length > 0 ? counsellorType?._id : ""
                  }
                  Isrequired={true}
                  reset={resetCounsellorType}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex my-2 ">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Manager:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                  reset={resetManager}
                  Isrequired={true}
                  defaultValue={managerList?.length > 0 ? manager?._id : ""}
                />
              </div>
            </div>
            <div className="col-6 d-flex my-2 ">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Counsellor:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Counsellor"
                  setInputText={(value: any) => onChangeCounsellor(value)}
                  value={counsellor}
                  options={counsellorList}
                  reset={resetCounsellor}
                  Isrequired={true}
                  defaultValue={
                    counsellorList?.length > 0 ? counsellor?._id : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <h3 className="text-primary font-monospace"> Target</h3>
            <div className="row d-flex">
              <div className="row">
                <div className="col-4">
                  <p className="fw-bold">Target Level:</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Target Ratio :</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Incentive Percentage:</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Bronze Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={bronzeTarget}
                    onChangeInput={(value: any) => onchangeBronzeTarget(value)}
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Percentage"
                    value={bronzeIncentivePercentage}
                    onChangeInput={(value: any) =>
                      onChangeBronzeIncentivePercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isEditMode ? (
            <div className="d-flex justify-content-center mt-3">
              <div className="d-flex justify-content-center mx-1">
                <Button name={"Cancel"} handleClick={cancelAddIncentive} />
              </div>
              <div className="d-flex justify-content-center mx-1">
                <SubmitButton name={"Add Incentive"} />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-3">
              <Button name={"Cancel"} handleClick={cancelEditIncentive} />
              <div className="ms-3">
                <SubmitButton name={"Save & Update"} />
              </div>
            </div>
          )}
        </form>
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <form className=" col-md-9 row d-flex" onSubmit={handleUpdateEdit}>
          <div className="col-md-5 d-flex my-2  me-4">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={newStartDate}
                setInputText={(value: string) => setNewStartDate(value)}
                Isrequired={true}
                mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>

          <div className="col-md-5 d-flex my-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={newEndDate}
                setInputText={(value: string) => setNewEndDate(value)}
                Isrequired={true}
                mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <Button name={"Cancel"} handleClick={cancelEditNew} />
            <div className="ms-3">
              <SubmitButton name={"Save & Update"} />
            </div>
          </div>
        </form>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={
          !isEditMode
            ? "Incentive Target Allocation"
            : "Edit Incentive Target Allocation"
        }
        isEditMode={isEditMode}
        setisEditMode={setIsEditMode}
      />
      <div className="col-md-12 px-4 mx-0 mt-4 formDiv ">
        <div className="justify-content-start ">
          {!isEditModeCopy ? (
            <>
              {!openAddIncentive && !isEditMode && !isEditModeCopy ? (
                <>
                  {" "}
                  {renderSearchMode()}
                  {renderListMode()}
                </>
              ) : (
                renderAddIncentiveMode()
              )}
            </>
          ) : (
            <>
              {renderEditMode()}
              {renderListMode()}
            </>
          )}
        </div>

        {openDeletePopup ? (
          <RestorePopup
            open={openDeletePopup}
            handleClose={handleCloseDelete}
            handleSubmitRestore={() => {
              handleSubmitDelete();
            }}
            title={"Delete Allocated Target"}
            data={"Do you want to delete this ?"}
            buttonTitle={"Delete"}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
