import { useEffect, useState } from "react";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getIncentiveTargetStatus } from "../../Redux/Actions/counsellorPanelAction";
import "../../App.css";
import CustomDropdown from "../../Component/customDropdown";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
import SubmitButton from "../../Component/submitButton";

export default function IncentiveTargetStatus() {
  const [startDate, SetStartDate] = useState<any>(
    moment().weekday(1).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().weekday(7).format("YYYY-MM-DD")
  );
  const [counsellor, setCounsellor] = useState<any>("");
  const [manager, setManager] = useState<any>("");

  const [counsellorList, setCounsellorList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [resetTargetList, setResetTargetList] = useState<any>([]);

  const [selected, setSelected] = useState("allocated");

  const dispatch = useDispatch();

  const { loggedInUserId, loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  useEffect(() => {
    if (loggedInUserRole === 1) {
      dispatch(
        getEmployeetByManagerId(
          loggedInUserId,
          (res: any) => {
            setManagerList(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getEmployeetByManagerId(
          loggedInUserId,
          (res: any) => {
            setCounsellorList(res?.data);
          },
          () => {}
        )
      );
    }
    const postData = {
      id: loggedInUserId,
      role: loggedInUserRole,
      startDate: startDate,
      endDate: endDate,
    };
    getIncentiveTargetStatusData(postData);
  }, []);

  const onEndDateChange = (val: any) => {
    SetEndDate(val);
  };

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
  };

  const getIncentiveTargetStatusData = (postData: any) => {
    dispatch(
      getIncentiveTargetStatus(
        postData,
        (res: any) => {
          const mergedResult = mergeArrays(res);
          console.log("mergedResult", mergedResult);
          setResetTargetList([mergedResult]);
        },
        () => {}
      )
    );
  };

  const mergeArrays = (res: any) => {
    return res.reduce(
      (acc: any, current: any) => {
        const currentStartDate = new Date(current.startDate);
        const currentEndDate = new Date(current.endDate);

        if (!acc.startDate || currentStartDate < new Date(acc.startDate)) {
          acc.startDate = current.startDate;
        }
        if (!acc.endDate || currentEndDate > new Date(acc.endDate)) {
          acc.endDate = current.endDate;
        }

        acc.targetReferenceLeadCount += current.targetReferenceLeadCount || 0;
        acc.achievedReferenceLeadCount +=
          current.achievedReferenceLeadCount || 0;
        acc.allocatedExamsTargetRevenue +=
          current.allocatedExamsTargetRevenue || 0;
        acc.totalRevenueAchieved += current.totalRevenueAchieved || 0;

        current.target.forEach((target: any, index: number) => {
          if (!acc.target[index]) {
            acc.target[index] = {
              targetAmount: 0,
              startAmount: 0,
              endAmount: 0,
              maxIncentive: 0,
              totalRevenueAchieved: 0,
              allocatedExamsTargetRevenue: 0,
              incentiveRevenue: 0,
              level: target.level,
            };
          }
          acc.target[index].targetAmount += target.targetAmount || 0;
          acc.target[index].startAmount += target.startAmount || 0;
          acc.target[index].endAmount += target.endAmount || 0;
          acc.target[index].maxIncentive += target.maxIncentive || 0;
          acc.target[index].totalRevenueAchieved +=
            target.totalRevenueAchieved || 0;
          acc.target[index].allocatedExamsTargetRevenue +=
            target.allocatedExamsTargetRevenue || 0;
          acc.target[index].incentiveRevenue += target.incentiveRevenue || 0;
        });

        return acc;
      },
      {
        startDate: null,
        endDate: null,
        targetReferenceLeadCount: 0,
        achievedReferenceLeadCount: 0,
        allocatedExamsTargetRevenue: 0,
        totalRevenueAchieved: 0,
        target: [],
      }
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData: any = {};
    if (loggedInUserRole === 1 && manager !== "" && counsellor === "") {
      postData = {
        id: manager?._id,
        role: manager?.role,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (
      (loggedInUserRole === 2 || loggedInUserRole === 1) &&
      counsellor !== ""
    ) {
      postData = {
        id: counsellor?._id,
        role: counsellor?.role,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      postData = {
        id: loggedInUserId,
        role: loggedInUserRole,
        startDate: startDate,
        endDate: endDate,
      };
    }
    getIncentiveTargetStatusData(postData);
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          {resetTargetList?.map((item: any, index: any) => {
            return (
              <>
                <hr className="hr"></hr>
                <div className="d-flex justify-content-center">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="allocated"
                      autoComplete="off"
                      checked={selected === "allocated"}
                      onChange={() => setSelected("allocated")}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="allocated"
                    >
                      Allocated Exams
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="other"
                      autoComplete="off"
                      checked={selected === "other"}
                      onChange={() => setSelected("other")}
                    />
                    <label className="btn btn-outline-primary" htmlFor="other">
                      Other Exams
                    </label>
                  </div>
                </div>

                <div className="d-flex mt-2 justify-content-center mx-0 ">
                  <p className="fw-bold fs-5">
                    Target Start Date :{" "}
                    <span>
                      {moment(item.startDate).utc().format("DD-MM-YYYY")}
                    </span>
                  </p>
                  <p className="fw-bold fs-5 ms-5">
                    Target End Date :{" "}
                    <span>
                      {moment(item?.endDate).utc().format("DD-MM-YYYY")}
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-center m-0">
                  {selected === "allocated" ? (
                    <div className="col-md-3 text-center boxshadow rounded p-4 me-2">
                      <h4 className="font-monospace">Highest Target</h4>
                      <h2 className="fw-bold">
                        {item?.target[0]?.targetAmount
                          ? item?.target[0]?.targetAmount
                          : 0}
                      </h2>
                    </div>
                  ) : null}

                  <div className="col-md-3 text-center boxshadow  rounded p-4 me-2">
                    <h4 className="font-monospace">Revenue Achieved</h4>
                    <h2 className="fw-bold">
                      {selected === "allocated"
                        ? item?.allocatedExamsTargetRevenue
                        : item?.totalRevenueAchieved -
                          item?.allocatedExamsTargetRevenue}
                    </h2>
                  </div>
                  {selected === "allocated" ? (
                    <>
                      <div className="col-md-3 text-center boxshadow  rounded p-4 me-2">
                        <h4 className="font-monospace">Incentive Achieved</h4>
                        <h2 className="fw-bold">
                          {item?.target[0]?.incentiveRevenue > 0
                            ? item?.target[0]?.incentiveRevenue
                            : 0}
                        </h2>
                      </div>
                      <div className="col-md-3 text-center boxshadow  rounded p-4 me-0">
                        <h4 className="font-monospace">Reference Count</h4>
                        <h2 className="fw-bold">
                          {item?.achievedReferenceLeadCount} {"/"}{" "}
                          {item?.targetReferenceLeadCount}
                        </h2>
                      </div>
                    </>
                  ) : null}
                </div>

                {selected === "allocated" ? (
                  <div className="text-center mt-3">
                    <p className="font17 fw-bolder text-success">
                      {" "}
                      Weekly Revenue achieved equal to or above the target
                      mentioned in crm will be eligible for incentive as per
                      aligned criteria of the total amount achieved.
                    </p>
                  </div>
                ) : null}

                {selected === "allocated" ? (
                  <div className="d-flex justify-content-between mx-3 ">
                    <p className="fw-bold">
                      Target Achieved :{" "}
                      <span>
                        {item?.allocatedExamsTargetRevenue >=
                        item?.target[0]?.targetAmount
                          ? "YES"
                          : "NO"}
                      </span>
                    </p>

                    <p className="fw-bold">
                      Incentive Achieved :{" "}
                      <span>
                        {item?.target[0]?.incentiveRevenue > 0 ? "YES" : "NO"}
                      </span>
                    </p>
                  </div>
                ) : null}

                {selected === "allocated" ? (
                  <table className="w-100">
                    <tr>
                      <th>Target Level </th>
                      <th>Target Amount</th>
                      <th>Start Amount</th>
                      <th>End Amount</th>
                      <th>Total Revenue</th>
                      <th>Incentive</th>
                      <th>Max Incentive</th>
                    </tr>
                    <tbody>
                      {item?.target?.map((data: any, index: any) => {
                        if (data?.level === "bronze_level") {
                          return (
                            <tr>
                              <td className="fw-bold">
                                {data?.level === "bronze_level"
                                  ? "Bronze Level"
                                  : data?.level === "silver_level"
                                  ? "Silver Level"
                                  : data?.level === "gold_level"
                                  ? "Gold Level"
                                  : "Platinum Level"}{" "}
                              </td>
                              <td>{data?.targetAmount}</td>
                              <td>{data?.startAmount}</td>
                              <td>{data?.endAmount}</td>
                              <td>
                                {selected === "allocated"
                                  ? data?.allocatedExamsTargetRevenue
                                  : data?.totalRevenueAchieved -
                                    data?.allocatedExamsTargetRevenue}
                              </td>
                              <td>
                                {data?.incentiveRevenue
                                  ? data?.incentiveRevenue
                                  : 0}
                              </td>
                              <td>
                                {data?.maxIncentive ? data?.maxIncentive : 0}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                ) : null}
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Incentive Target Status"} />
      <div className="formDiv p-2">
        <div className="col-md-12 formWidth">
          <form onSubmit={handleSubmit}>
            <div className="d-flex">
              <div className="col-md-4 d-flex mb-3 me-2">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Start Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => SetStartDate(value)}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex mb-3">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="End Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => onEndDateChange(value)}
                    // Isrequired={true}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              {loggedInUserRole === 1 ? (
                <div className="col-md-4 d-flex mb-3 pe-3">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Manager" />
                  </div>
                  <div className="col-md-8">
                    <CustomDropdown
                      lableName="Select Manager"
                      setInputText={(value: any) => onChangeManager(value)}
                      value={manager}
                      options={managerList}
                    />
                  </div>
                </div>
              ) : null}
              {loggedInUserRole === 1 || loggedInUserRole === 2 ? (
                <div className="col-md-4 d-flex mb-3 pe-3">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Counsellor" />
                  </div>
                  <div className="col-md-8 ps-0">
                    <CustomDropdown
                      lableName="Select Counsellor"
                      setInputText={(value: any) => onChangeCounsellor(value)}
                      value={counsellor}
                      options={counsellorList}
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-md-4 d-flex justify-content-end mb-2">
                <div className="col-md-6 me-0">
                  <SubmitButton name={"Submit"} />
                </div>
              </div>
            </div>
          </form>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
