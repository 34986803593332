import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  elements,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import { useSelector } from "react-redux";
import moment from "moment";
// import { getCallRecordCount, getCallTimeCount, getLeadDepartmentCount, getOverdueDepartmentCount, getStatusWiseDepartmentCount, getTargetAchieved, getTotalDepartmentAchieved, getWebinarCompleteCount } from '../../Redux/Actions/bdExecutivePanelAction';
import {
  getAchievedUnachievedLeadtarget,
  getBdeTalkTimeList,
  getExecutiveConnectedCallTargetDetails,
  getExecutiveDeptTargetDetails,
  getExecutiveListTeamLeadTarget,
  getExecutiveWebinarTargetDetails,
  getLeadDepartmentCount,
  getMonthlyDepTargetDetails,
  getMonthlyWebinarTargetDetails,
} from "../../Redux/Actions/bdManagerPanelAction";
import SalesPanelSummeryGraph from "../../Component/salesPanelSummeryGraph";
import RoundChart from "../../Component/roundChart";
import GenaralTable from "../../Component/genaralTable";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import BarGraphWithoutOnclick from "../../Component/barGraphWithouOnclick";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function BDTLDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [startDate, setStartDate] = useState<any>(
  //   moment().startOf("month").format("YYYY-MM-DD")
  // );
  // const [endDate, setEndDate] = useState<any>(
  //   moment().endOf("month").format("YYYY-MM-DD")
  // );

  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [leadCount, setLeadCount] = useState<any>("");
  const [webinarCount, setWebinarCount] = useState<any>("");
  const [departmentCount, setDepartmentCount] = useState<any>("");
  const [talkTimeList, setTalkTimeList] = useState([]);
  const [webinarTotalRevenue, SetWebinarTotalRevenue] = useState<any>("");
  const [deptTotalRevenue, SetDeptTotalRevenue] = useState<any>("");
  const [monthlyDepTotaltarget, setMonthlyDepTotaltarget] = useState("");
  const [monthlyWebinarTotaltarget, setMonthlyWebinarTotaltarget] =
    useState("");
  const [achievedUnachievedTotal, setAchievedUnachievedTotal] = useState("");
  const [executiveListTLTarget, setExecutiveListTLTarget] = useState([]);

  const [piedata2, setPiedata2] = useState<any>([]);
  const [piedata3, setPiedata3] = useState<any>([]);
  const [piedata1, setPiedata1] = useState<any>([]);

  const [xLabel1, setXLabel1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [xLabelCallOld, setXLabelCallOld] = useState<any>([]);
  const [xLabelCallUnique, setXLabelCallUnique] = useState<any>([]);
  // const [xLabel7, setXLabel7] = useState<any>([]);

  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [Graphdata2, setGraphdata2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);
  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const [GraphdataCallOld, setGraphdataCallOld] = useState<any>([]);
  const [GraphdataCallUnique, setGraphdataCallUnique] = useState<any>([]);
  const [bdeTalkTimeList, setBdeTalkTimeList] = useState([]);
  // const [Graphdata7, setGraphdata7] = useState<any>([]);

  const xLabel = ["Achieved", "Target"];

  const { counsellorId } = useSelector((state: any) => ({
    counsellorId: state.Auth.counsellorId,
  }));

  const Graphdata4 = ["15", "10", "7", "5", "8"];
  // const xLabel1 = ["CSIT-NET", "SET", "IIT-JAM", "UGC-NET", "GATE"];
  const link3 = ["../leadOverViewDetails"];
  // const piedata1 = ["15", "20", "30", "5", "20", "5", "5"];
  // const Piechartdata1 = {
  //   labels: ["Achieved Revenue", "Unachieved Revenue"],
  //   datasets: [
  //     {
  //       data: piedata1,
  //       backgroundColor: ["#1F77B4", "#F23E36"],

  //       percentageInnerCutout: 80,
  //     },
  //   ],
  // };

  useEffect(() => {
    // onChangeGetRevenue();
    dispatch(
      getMonthlyWebinarTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          piechartData2(res);
          setMonthlyWebinarTotaltarget(res?.target);
        },
        () => {}
      )
    );
    dispatch(
      getExecutiveWebinarTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphExecutiveWebinar(res);
        },
        () => {}
      )
    );
    dispatch(
      getMonthlyDepTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          piechartData3(res);
          setMonthlyDepTotaltarget(res?.target);
        },
        () => {}
      )
    );

    dispatch(
      getExecutiveDeptTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphExecutiveDept(res);
        },
        () => {}
      )
    );

    dispatch(
      getExecutiveConnectedCallTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphCallTotalConnected(res?.targets);
          showGraphCallOldConnected(res?.targets);
          showGraphCallUniqueConnected(res?.targets);
        },
        () => {}
      )
    );

    dispatch(
      getBdeTalkTimeList(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          setBdeTalkTimeList(res);
        },
        () => {}
      )
    );
    dispatch(
      getAchievedUnachievedLeadtarget(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          piechartData1(res[0]);
          setAchievedUnachievedTotal(res[0]?.targetLeads);
        },
        () => {}
      )
    );
    dispatch(
      getExecutiveListTeamLeadTarget(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphExecutiveTLTarget(res);
        },
        () => {}
      )
    );
    let data = {
      startDate,
      endDate,
    };

    dispatch(
      getLeadDepartmentCount(
        data,
        (res: any) => {
          setDepartmentCount(res?.departmentCount);
          setWebinarCount(res?.webinarCount);
          setLeadCount(res?.leadCount);
        },
        () => {}
      )
    );
  }, []);

  const showGraphCallTotalConnected = (list: any) => {
    list?.map((item: any) => {
      xLabelCallTotal?.push(item?.executiveName);
      GraphdataCallTotal?.push(item?.totalConnectedCall?.achieved);
    });
  };
  const showGraphCallOldConnected = (list: any) => {
    list?.map((item: any) => {
      xLabelCallOld?.push(item?.executiveName);
      GraphdataCallOld?.push(item?.oldConnectedCall?.achieved);
    });
  };
  const showGraphCallUniqueConnected = (list: any) => {
    list?.map((item: any) => {
      xLabelCallUnique?.push(item?.executiveName);
      GraphdataCallUnique?.push(item?.uniqueConnectedCall?.achieved);
    });
  };

  const showGraphExecutiveTLTarget = (list: any) => {
    while (xLabel1.length) {
      xLabel1.pop();
      Graphdata1?.pop();
    }
    list?.map((item: any) => {
      xLabel1?.push(item?.name);
      Graphdata1?.push(item?.achievedLeads);
    });
  };

  const showGraphExecutiveWebinar = (list: any) => {
    let total = 0;
    while (xLabel2.length) {
      xLabel2.pop();
      Graphdata2?.pop();
    }
    list?.achieved?.map((item: any) => {
      total = total + item?.count;
      xLabel2?.push(item?.executiveName);
      Graphdata2?.push(item?.count);
    });
    SetWebinarTotalRevenue(total);
  };
  const showGraphExecutiveDept = (list: any) => {
    let total = 0;
    while (xLabel3.length) {
      xLabel3.pop();
      Graphdata3?.pop();
    }
    list?.achieved?.map((item: any) => {
      total = total + item?.count;
      xLabel3?.push(item?.executiveName);
      Graphdata3?.push(item?.count);
    });
    SetDeptTotalRevenue(total);
  };

  const piechartData1 = (list: any) => {
    let arr = [];
    let unachieved = 0;
    let achieved = 0;
    achieved = achieved + list?.achievedLeads;
    unachieved = unachieved + list?.unachievedLead;
    arr?.push(achieved);
    arr?.push(unachieved);
    setPiedata1(arr);
  };

  const AchievedUnachievedTarget = {
    labels: ["Achieved Leads", "Unachieved Leads"],
    datasets: [
      {
        data: piedata1,
        backgroundColor: ["#1F77B4", "#F23E36"],
      },
    ],
  };

  const piechartData2 = (list: any) => {
    let arr = [];
    let unachieved = 0;
    let achieved = 0;
    achieved = achieved + list?.achieved;
    unachieved = unachieved + list?.remaining;
    arr?.push(achieved);
    arr?.push(unachieved);
    setPiedata2(arr);
  };

  const MonthlyWebinarTarget = {
    labels: ["Achieved Webinar", "Unachieved Webinar"],
    datasets: [
      {
        data: piedata2,
        backgroundColor: ["#1F77B4", "#F23E36"],
      },
    ],
  };

  const piechartData3 = (list: any) => {
    let arr = [];
    let unachieved = 0;
    let achieved = 0;
    achieved = achieved + list?.achieved;
    unachieved = unachieved + list?.remaining;
    arr?.push(achieved);
    arr?.push(unachieved);
    setPiedata3(arr);
  };

  const MonthlyDeptTarget = {
    labels: ["Achieved Department", "Unachieved Department"],
    datasets: [
      {
        data: piedata3,
        backgroundColor: ["#1F77B4", "#F23E36"],
      },
    ],
  };

  // const onChangeGetRevenue = () => {
  //   let data = {
  //     startDate,
  //     endDate,
  //   };

  //   dispatch(
  //     getLeadDepartmentCount(
  //       data,
  //       (res: any) => {
  //         setDepartmentCount(res?.departmentCount);
  //         setWebinarCount(res?.webinarCount);
  //         setLeadCount(res?.leadCount);
  //       },
  //       () => {}
  //     )
  //   );
  // };
  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <h4 className="fw-bold">Team Talk Time</h4>
        <GenaralTable
          tableData={bdeTalkTimeList ? bdeTalkTimeList : []}
          pagename={"managerDashboard"}
          tableHead={[
            "Name",
            "Total",
            // "12AM-1AM",
            // "1AM-2AM",
            // "2AM-3AM",
            // "3AM-4AM",
            // "4AM-5AM",
            // "5AM-6AM",
            // "6AM-7AM",
            // "7AM-8AM",
            // "8AM-9AM",
            "9AM-10AM",
            "10AM-11AM",
            "11AM-12PM",
            "12PM-1PM",
            "1PM-2PM",
            "2PM-3PM",
            "3PM-4PM",
            "4PM-5PM",
            "5PM-6PM",
            "6PM-7PM",
            "7PM-8PM",
            "8PM-9PM",
            "9PM-10PM",
            "10PM-11PM",
            "11PM-12AM",
          ]}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Dashboard"} />

      <div className="formDiv ">
        <div className=" col-md-12 formWidth ">
          <div className=" counsellorDashbord d-flex px-2 mx-5">
            <div className="col-md-11 d-flex justify-content-between">
              <div className="col-md-2 d-flex justify-content-center pe-2 mt-3">
                <div className="col-md-5 d-flex flex-row-reverse ">
                  <LabelField lableName="Total Lead:" />
                </div>
                <div className="col-md-7 me-2">
                  <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4">
                    {leadCount ? leadCount : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-2 d-flex justify-content-center pe-2 mt-3">
                <div className="col-md-6 d-flex flex-row-reverse ">
                  <LabelField lableName="Total Webinar :" />
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4">
                    {webinarCount ? webinarCount : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center pe-2 mt-3">
                <div className="col-md-6 d-flex flex-row-reverse">
                  <LabelField lableName="Department Added :" />
                </div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4">
                    {departmentCount ? departmentCount : 0}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 d-flex mt-3 ">
                <div className="col-md-5 d-flex flex-row-reverse">
                  <LabelField lableName="Start Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => setStartDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                    //  mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex me-2  mt-3">
                <div className="col-md-5 d-flex flex-row-reverse">
                  <LabelField lableName="End Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => setEndDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                    //  mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div> */}
            </div>
            {/* <div className="col-md-1 d-flex justify-content-center">
              <button className="btn" onClick={onChangeGetRevenue}>
                Search
              </button>
            </div> */}
          </div>
          <div className="analysisDiv">
            {/* *************************************Lead OverView********************************************* */}
            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-7 p-3">
                <h4 className="fw-bold">Lead Overview </h4>

                {/* <SalesPanelSummeryGraph
                  label={xLabel1}
                  value={Graphdata1}
                  link={link3}
                />  */}
                <BarGraphWithoutOnclick label={xLabel1} value={Graphdata1} />
              </div>
              <div className="col-md-4 p-3 divBackground  ">
                <h4 className="fw-bold">Lead Achieved</h4>

                <div className="d-flex">
                  <p>Out Of :</p>
                  <div className="ms-2 fw-bold">{achievedUnachievedTotal}</div>
                </div>

                <div className=" d-flex justify-content-center">
                  <RoundChart data={AchievedUnachievedTarget} />
                </div>
              </div>
            </div>
            {/* ***********************************************Webinar Overview ************************************************** */}

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-7 p-3">
                <h4 className="fw-bold">Webinar Overview </h4>

                {/* <span className="fw-bold">
                  Total Revenue : {webinarTotalRevenue}
                </span> */}
                {/* <SalesPanelSummeryGraph
                  label={xLabel2}
                  value={Graphdata2}
                  link={link3}
                /> */}
                <BarGraphWithoutOnclick label={xLabel2} value={Graphdata2} />
              </div>
              <div className="col-md-4 p-3 divBackground  ">
                <h4 className="fw-bold">Webinar Achieved</h4>
                <div className="d-flex">
                  <p>Out Of :</p>
                  <div className="ms-2 fw-bold">
                    {monthlyWebinarTotaltarget}
                  </div>
                </div>

                <div className=" d-flex justify-content-center">
                  <RoundChart data={MonthlyWebinarTarget} />
                </div>
              </div>
            </div>
            {/* ***********************************************Department Overview ************************************************** */}

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-7 p-3">
                <h4 className="fw-bold">Department Overview </h4>
                {/* <span className="fw-bold">
                  Total Revenue : {deptTotalRevenue}
                </span> */}
                {/* <SalesPanelSummeryGraph
                  label={xLabel3}
                  value={Graphdata3}
                  link={link3}
                /> */}
                <BarGraphWithoutOnclick label={xLabel3} value={Graphdata3} />
              </div>
              <div className="col-md-4 p-3 divBackground  ">
                <h4 className="fw-bold">Department Achieved</h4>
                <div className="d-flex">
                  <p>Out Of</p>
                  <div className="ms-2 fw-bold">{monthlyDepTotaltarget}</div>
                </div>

                <div className=" d-flex justify-content-center">
                  <RoundChart data={MonthlyDeptTarget} />
                </div>
              </div>
            </div>
            {/* ********************************************************Team Shrinkage Analysis****************************************************** */}
            {/* <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-7 p-3">
                <h4 className="fw-bold">Team Shrinkage Analysis </h4>
                <span className="fw-bold">Total Revenue : {}</span>
                <SalesPanelSummeryGraph
                  label={xLabel1}
                  value={Graphdata4}
                  link={link3}
                />
              </div>
              <div className="col-md-4 p-3 divBackground  ">
                <p className="fw-bold">Present/Absent</p>

                <p>Out Of</p>
                <div className="  ">
                  <SalesPanelSummeryGraph
                    label={xLabel1}
                    value={Graphdata4}
                    link={link3}
                  />
                </div>
              </div>
            </div> */}

            {/***********************************************connected calls************************************************************ */}

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Total Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                <CallRecordedGraph
                  label={xLabelCallTotal}
                  value={GraphdataCallTotal}

                  // link={link1}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Old Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                <CallRecordedGraph
                  label={xLabelCallOld}
                  value={GraphdataCallOld}
                  // link={link1}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Unique Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                <CallRecordedGraph
                  label={xLabelCallUnique}
                  value={GraphdataCallUnique}
                />
              </div>
            </div>

            {/* ********************************************Talk Time list********************************************* */}

            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
