
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import SubmitButton from '../../Component/submitButton';
import InputField from '../../Component/inputField'
import LabelField from '../../Component/labelField';
import { containsNumber, length_Ten, validateValueWithoutSpace } from '../../DATA/validators';
import { getCronTime, updateCronTime } from '../../Redux/Actions/leadManagementAction';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';


export default function CronTime() {
  const dispatch = useDispatch();

  const [id, setId] = useState<any>("");
  const [updatedCronTime, setUpdatedCronTime] = useState("");

  useEffect(() => {
    dispatch(
      getCronTime(
        "",
        (res: any) => {
          setId(res?._id);
          setUpdatedCronTime(res?.cronTime_minute);
        },
        () => { }
      )
    );

  }, []);


  const onchangeCronTime = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setUpdatedCronTime(value);
      }
    } else {
      setUpdatedCronTime(value);
    }
  }



  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      cronTime_minute: updatedCronTime
    }
    dispatch(
      updateCronTime(
        id,
        postData,
        (res: any) => {
          dispatch(
            getCronTime(
              "",
              (res: any) => {
                setId(res?._id);
                setUpdatedCronTime(res?.cronTime_minute);

              },
              () => { }
            )
          );
        },
        () => { }
      )
    );

  };


  const renderSearchMode = () => {
    return (
      <form className="d-flex" onSubmit={handleSubmit}>

        <div className="d-flex mb-2 col-4">
          <LabelField lableName={"Cron Time :"} />
          <InputField
            placeholder="Enter Cron Time"
            value={updatedCronTime}
            onChangeInput={(value: any) => onchangeCronTime(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>
        <div className="d-flex mb-2 col-4 me-4">
          <div>
            <SubmitButton name={"Update"} />
          </div>
        </div>
      </form>
    );
  };


  return (
    <div className="contentBox">
      <PageTitle name={"Cron Time"} />
      <div className="row m-1">
        <div className="formDiv">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div>

              </div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}

