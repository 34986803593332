import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../../Component/pageTitle";
import { useNavigate } from "react-router-dom";
import {
  getExamList,
  getExamStreamList,
  getUserAnalysisByCID,
} from "../../../Redux/Actions/counsellorPanelAction";
import { useSelector } from "react-redux";
import GenaralTable from "../../../Component/genaralTable";
import StudentCourseDetails from "./studentCourseDetails";
import CustomDropdown from "../../../Component/customDropdown";
import LabelField from "../../../Component/labelField";
import SubmitButton from "../../../Component/submitButton";

export default function StudentAnalysis() {
  const dispatch = useDispatch();

  const { loggedInUserId } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");

  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);

  const [resetExam, setResetExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);

  const [isViewMode, setIsViewMode] = useState(false);
  const [studentViewData, setStudentViewData] = useState({});
  const [studentAnalysisList, setStudentAnalysisList] = useState([]);

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getUserAnalysisByCID(
        {
          counsellorId: loggedInUserId,
        },
        (res: any) => {
          let tempData = res?.allData.sort((a: any, b: any) => {
            return b.examCount - a.examCount;
          });
          setStudentAnalysisList(tempData);
        },
        () => {}
      )
    );
  }, []);

  const onClickDetails = (data: any) => {
    let obj = {
      userId: data?.userId,
    };
    setStudentViewData(obj);
    setIsViewMode(true);
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    setResetExam(false);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamStream = (val: string) => {
    setExamStream(val);
    setResetExamStream(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      counsellorId: loggedInUserId,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
    };
    dispatch(
      getUserAnalysisByCID(
        postData,
        (res: any) => {
          let tempData = res?.allData.sort((a: any, b: any) => {
            return b.examCount - a.examCount;
          });
          setStudentAnalysisList(tempData);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSubmit}>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
              // Isrequired={true}
              reset={resetExam}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Stream" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam Stream"
              setInputText={(value: any) => onChangeExamStream(value)}
              value={examStream}
              options={examStreamList}
              // Isrequired={true}
              reset={resetExamStream}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex justify-content-between mb-3">
          <div className="col-md-3 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <GenaralTable
        tableData={studentAnalysisList ? studentAnalysisList : []}
        pagename={"studentAnalysis"}
        tableHead={[
          "S.N",
          "First Name",
          "Last Name",
          "Total Test Completed",
          "Total Live Attended",
          "Action",
        ]}
        handleEdit={(value: any) => onClickDetails(value)}
      />
    );
  };

  const handleBackBtn = () => {
    setIsViewMode(false);
  };

  return (
    <>
      {!isViewMode ? (
        <div className="contentBox ">
          <PageTitle name={"STUDENT ANALYSIS"} />
          <div className="formDiv formWidth col-md-12">
            <div className="">
              <div className="row d-flex">
                <div className="p-2 col-12">
                  {renderSearchMode()}
                  {renderListMode()}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <StudentCourseDetails
          data={studentViewData}
          handleGoBack={handleBackBtn}
        />
      )}
    </>
  );
}
