
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface props {
  label: any,
  value: any,
  link?: any,
  getExamStreamData?: any,
  sDate?: any,
  eDate?: any,
  examId?: any,
  examStreamId?: any,
  managerId?: any,
}



export default function SalesPanelGraph({ label, value, link, getExamStreamData, sDate, eDate, examId, examStreamId, managerId }: props) {

  examStreamId = sessionStorage.getItem("ExamStreamId")

  const navigate = useNavigate();
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      }
    }

  };
  const graphData = {
    labels: label,
    datasets: [
      {
        label: " ",
        data: value,
        backgroundColor: [
          "#1F77B4",
          "#1F77B4",
        ],
        borderColor: [
          "#1F77B4",
          "blue",
        ],
        borderWidth: 0.5,
        barPercentage: 0.2,
        links: link

      }

    ]


  };

  const chartRef: any = useRef();

  const onClick = (event: any) => {
    if (chartRef.current) {
      let selectedBar = getElementsAtEvent(chartRef.current, event);
      if (selectedBar?.length > 0) {
        getExamStreamData(selectedBar[0])
      }
    }
    if (getElementsAtEvent(chartRef.current, event).length > 0) {
      const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0].datasetIndex
      const dataPoint = getElementsAtEvent(chartRef.current, event)[0].index
      if(link[0] === "../referenceAnalysis"){
        navigate("../referenceAnalysis",
        {
          state: {managerId: managerId }
        })
      }else if(link[0] === "../saReferenceAnalysis"){
        navigate("../saReferenceAnalysis",
        {
          state: {managerId: managerId }
        })

      }else {
        navigate("../topSellableExams",
        {
          state: { sDate: sDate, eDate: eDate, examId: examId, examStreamId: examStreamId, managerId: managerId }
        })
      }

    }


  }


  return (
    <Bar options={options} data={graphData} onClick={onClick} ref={chartRef} />

  );
}
