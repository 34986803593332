import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getDepartmentDetails } from "../../Redux/Actions/bdExecutivePanelAction";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import moment from "moment";

export default function CallBackBDELead() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sDate = location.state?.startDate;
  const [startDate, SetStartDate] = useState<any>(
    sDate ? sDate : moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const eDate = location.state?.endDate;
  const [endDate, SetEndDate] = useState<any>(
    eDate ? eDate : moment.utc(new Date()).format("YYYY-MM-DD")
  );
  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    const postData = {
      status: 3,
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate,
    };
    dispatch(
      getDepartmentDetails(
        postData,
        (res: any) => {
          setCount(res?.count);
          setDepartmentList(res?.departmentData);
        },
        () => {}
      )
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: 3,
        startDate,
        endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: 3,
        startDate,
        endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: 3,
        startDate,
        endDate,
      };
    }
    dispatch(
      getDepartmentDetails(
        data,
        (res: any) => {
          setCount(res?.count);
          setDepartmentList(res?.departmentData);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickLead = (data: any) => {
    navigate("/departmentdetails", {
      state: {
        departmentId: data?._id,
        pageName: "CallBackBDELead",
        IsEdit: "true",
        IsCheck: "true",
        IsWebinar: "true",
        college: data,
        startDate: startDate,
        endDate: endDate,
      },
    });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();

    const postData = {
      status: 3,
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate,
    };
    dispatch(
      getDepartmentDetails(
        postData,
        (res: any) => {
          setCount(res?.count);
          setDepartmentList(res?.departmentData);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={departmentList ? departmentList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <div>
            <PaginationTable
              tableData={departmentList ? departmentList : []}
              pagename={"CallBackBDELead"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Co-ordinator Name",
                "College Name",
                "Exam Name",
                "Department",
                "Details",
                "",
              ]}
              edit={false}
              handleNavigate={onClickLead}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Call Back Today"} />
      <div className="  p-2 formDiv">
        <form
          className="row d-flex justify-content-between"
          onSubmit={handleSearch}
        >
          <div className="col-md-12   formWidth">
            <div className="d-flex">
              <div className="col-md-4 row d-flex my-3 ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Start Date:" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => SetStartDate(value)}
                  />
                </div>
              </div>

              <div className="col-md-4 d-flex my-3 ms-3">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="End Date:" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => SetEndDate(value)}
                  />
                </div>
              </div>
              <div className="col-md-4 mt-3 ms-3">
                <button className="btn btn-primary " type="submit">
                  {" "}
                  Search
                </button>
              </div>
            </div>

            {renderListMode()}
          </div>
        </form>
      </div>
    </div>
  );
}
