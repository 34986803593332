import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getSMManagerList,
  getSMLeadAnalysis,
} from "../../Redux/Actions/superManagerAction";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
export default function SALeadAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [manager, setManager] = useState<any>("");
  const [analysis, setAnalysis] = useState<any>("ALL");
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState();
  const [analysisList, setAnalysisList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>("");
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState();
  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  const { loggedInUserRole, loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);
  };

  const handleAnalysis = (e: any, val: any, managerId: any) => {
    setAnalysis(val);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const data = {
      startDate,
      endDate,
      teamLeadId: teamLead?._id,
      managerId: manager?._id,
    };
    dispatch(
      getSMLeadAnalysis(
        data,
        (res: any) => {
          setAnalysisList(res?.record);
          setTotalCount(res?.totalStatusCounts);
        },
        () => {}
      )
    );
  };

  const onClickLead = (data: any, status: any) => {
    navigate("../counsellorCallDetails", {
      state: {
        data: data,
        pageName: "SMleadAnalysis",
        analysis,
        status: status,
      },
    });
  };
  const renderSearchMode = () => {
    return (
      <div className="row d-flex">
        <form className="col-md-12 d-flex " onSubmit={handleSearch}>
          <div className="col-md-4 d-flex mb-3 me-4">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Select Manager" />
            </div>
            <div className="col-md-8 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
                reset={resetManager}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3 me-4">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Select Team Lead" />
            </div>
            <div className="col-md-8 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Team Lead"
                setInputText={(value: any) => onChangeTeamLead(value)}
                value={teamLead}
                options={teamLeadList}
                // Isrequired={true}
                reset={resetTeamLead}
              />
            </div>
          </div>
          <div>
            <SubmitButton name="Search" />
          </div>
        </form>

        <div className="d-flex justify-content-end">
          <button
            type="button"
            className={`btn mb-3 me-2 cursor ${
              analysis === "ALL" ? "btn-primary" : "btn-secondary"
            }`}
            onClick={(e: any) => handleAnalysis(e, "ALL", managerId)}
          >
            All
          </button>
          <button
            type="button"
            className={`btn mb-3 me-2 cursor ${
              analysis === "OVERDUE" ? "btn-primary" : "btn-secondary"
            }`}
            onClick={(e: any) => handleAnalysis(e, "OVERDUE", managerId)}
          >
            Overdue
          </button>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <div className="salesTable table-responsive">
        <table className="w-100 mb-2">
          <thead>
            {analysis ? (
              <tr>
                <th rowSpan={2}>Counsellor Name</th>
                <th>New Leads </th>
                <th>Transfered </th>
                <th>Call Back</th>
                <th>Intrested</th>
                <th>Not Intrested</th>
                <th>Prospect</th>
                <th>Admission Done</th>
                <th>Lead Invalid</th>
                <th>Already Admission Done</th>
                <th>Expired</th>
                <th>CNR</th>
              </tr>
            ) : (
              <tr>
                <th rowSpan={2}>Counsellor Name</th>
                <th>Overdue Callback</th>
                <th>Overdue Interested</th>
                <th>Overdue Prospect</th>
              </tr>
            )}

            {totalCount ? (
              <tr>
                <th className="text-primary">
                  {totalCount?.["NEWLEAD(ASSIGNED)"]}
                </th>
                <th className="text-primary">{totalCount?.TRANSFERREDLEAD}</th>
                <th className="text-primary">{totalCount?.CALLBACK}</th>
                <th className="text-primary">{totalCount?.INTERESTED}</th>
                <th className="text-primary">{totalCount?.NOTINTERESTED}</th>
                <th className="text-primary">{totalCount?.PROSPECT}</th>
                <th className="text-primary">{totalCount?.ADMISSIONDONE}</th>
                <th className="text-primary">{totalCount?.LEADINVALID}</th>
                <th className="text-primary">
                  {totalCount?.ALREADYADMISSIONDONE}
                </th>
                <th className="text-primary">{totalCount?.EXPIRED}</th>
                <th className="text-primary">{totalCount?.CNR}</th>
              </tr>
            ) : (
              ""
            )}
          </thead>
          <tbody>
            {analysisList?.map((item: any, index: any) => {
              return analysisList ? (
                <tr>
                  <td>
                    {item?.firstName ? item?.firstName : ""}{" "}
                    {item?.lastName ? item?.lastName : ""}
                  </td>
                  <td onClick={() => onClickLead(item, 2)}>
                    {item["NEWLEAD(ASSIGNED"]?.count
                      ? item["NEWLEAD(ASSIGNED"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 3)}>
                    {" "}
                    {item["TRANSFERREDLEAD"]?.count
                      ? item["TRANSFERREDLEAD"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 6)}>
                    {" "}
                    {item["CALLBACK"]?.count ? item["CALLBACK"]?.count : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 4)}>
                    {" "}
                    {item["INTERESTED"]?.count
                      ? item["INTERESTED"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 9)}>
                    {item["NOTINTERESTED"]?.count
                      ? item["NOTINTERESTED"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 7)}>
                    {" "}
                    {item["PROSPECT"]?.count ? item["PROSPECT"]?.count : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 5)}>
                    {" "}
                    {item["ADMISSIONDONE"]?.count
                      ? item["ADMISSIONDONE"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 8)}>
                    {" "}
                    {item["LEADINVALID"]?.count
                      ? item["LEADINVALID"]?.count
                      : "-"}
                  </td>

                  <td onClick={() => onClickLead(item, 10)}>
                    {" "}
                    {item["ALREADYADMISSIONDONE"]?.count
                      ? item["ALREADYADMISSIONDONE"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 11)}>
                    {" "}
                    {item["EXPIRED"]?.count ? item["EXPIRED"]?.count : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 12)}>
                    {" "}
                    {item["CNR"]?.count ? item["CNR"]?.count : "-"}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    {item?.firstName ? item?.firstName : ""}{" "}
                    {item?.lastName ? item?.lastName : ""}
                  </td>
                  <td onClick={() => onClickLead(item, 6)}>
                    {" "}
                    {item["OVERDUECALLBACK"]?.count
                      ? item["OVERDUECALLBACK"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 4)}>
                    {" "}
                    {item["OVERDUEINTERESTED"]?.count
                      ? item["OVERDUEINTERESTED"]?.count
                      : "-"}
                  </td>
                  <td onClick={() => onClickLead(item, 7)}>
                    {" "}
                    {item["OVERDUEPROSPECT"]?.count
                      ? item["OVERDUEPROSPECT"]?.count
                      : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Analysis"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
