import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import { getState, getCity } from "../../Redux/Actions/bdeAdminPanelAction";
import { getDailyMeetingForManager } from "../../Redux/Actions/superManagerAction";

export default function DailyMeeting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [dailyMeetingList, setDailyMeetingList] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  // const [upComingList, setupComingList] = useState([]);

  useEffect(() => {
    dispatch(
      getDailyMeetingForManager(
        {
          startDate: startDate,
          endDate: endDate,
        },
        (res: any) => {
          setDailyMeetingList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getDailyMeetingForManager(
        postData,
        (res: any) => {
          setDailyMeetingList(res?.data);
        },
        () => {}
      )
    );
  };

  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        {/*  */}
        <div className="d-flex">
          <div className="col-md-4 row d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 ps-5 d-flex my-3">
            <div className="col-md-4">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="salesTable table-responsive">
          {dailyMeetingList?.map((item: any, index: any) => {
            return (
              <table className="w-100 mb-2">
                <thead>
                  <tr>
                    <th
                      className="bg-body fw-bold text-black text-align-center"
                      colSpan={2}
                    >
                      {item?.managerName}
                    </th>
                    <th
                      className="bg-body fw-bold text-black text-align-center"
                      colSpan={4}
                    >
                      Total Sales Revenue Target :
                      {item?.totalSalesRevenueTarget}
                    </th>
                    <th
                      className="bg-body fw-bold text-black text-align-center"
                      colSpan={4}
                    >
                      Total Sales Revenue Achieved :{item?.totalSalesRevenue}
                    </th>
                  </tr>
                  <tr>
                    <th>Team Lead Name</th>
                    {/* <th>Manager Name</th>
                  <th>Lead Type</th> */}
                    <th>Sales Revenue Target</th>
                    <th>Sales Revenue Achieved</th>
                    {/* <th>Prospect For Today</th> */}
                    <th>% Achieved Sales</th>
                    <th>Team Size</th>
                    <th>Present Counsellor</th>
                    <th>Absent</th>
                    <th>Shrinkage %</th>
                    <th>Calling Complete</th>
                    <th>Total Call Time</th>
                    <th>% Achieved Call Time</th>
                    <th>References Generated</th>
                    <th>References Target</th>
                    <th>References Deficite</th>
                  </tr>
                </thead>
                <tbody>
                  {item?.TL_LIST?.map((subItem: any) => {
                    return (
                      <tr>
                        <td>{subItem?.name ? subItem?.name : "-"}</td>
                        {/* <td>
                          {subItem?.name ? subItem?.managerName : "-"}
                        </td>
                        <td>
                          {subItem?.leadType ? subItem?.leadType : "-"}
                        </td> */}
                        <td>
                          {subItem?.salesRevenueTarget ||
                          subItem?.salesRevenueTarget >= 0
                            ? subItem?.salesRevenueTarget
                            : "-"}
                        </td>
                        <td>
                          {subItem?.salesRevenueAchieved ||
                          subItem?.salesRevenueAchieved >= 0
                            ? subItem?.salesRevenueAchieved
                            : "-"}
                        </td>
                        {/* <td>
                          {subItem?.prospectForToday ? subItem?.prospectForToday : "-"}
                        </td> */}
                        <td>
                          {subItem?.percentAchievedSales ||
                          subItem?.percentAchievedSales > 0
                            ? subItem?.percentAchievedSales
                            : "-"}
                        </td>
                        <td>{subItem?.teamSize ? subItem?.teamSize : "-"}</td>
                        <td>
                          {subItem?.present || subItem?.present >= 0
                            ? subItem?.present
                            : "-"}
                        </td>
                        <td>
                          {subItem?.absent || subItem?.absent >= 0
                            ? subItem?.absent
                            : "-"}
                        </td>
                        <td>
                          {subItem?.shrinkagePercentage ||
                          subItem?.shrinkagePercentage >= 0
                            ? subItem?.shrinkagePercentage
                            : "-"}
                        </td>
                        <td>
                          {subItem?.callingComplete ||
                          subItem?.callingComplete >= 0
                            ? subItem?.callingComplete
                            : "-"}
                        </td>
                        <td>
                          {subItem?.totalTalkTime || subItem?.totalTalkTime >= 0
                            ? subItem?.totalTalkTime
                            : "-"}
                        </td>
                        <td>
                          {subItem?.achievedCallTime ||
                          subItem?.achievedCallTime >= 0
                            ? subItem?.achievedCallTime
                            : "-"}
                        </td>
                        <td>
                          {subItem?.referenceGenerated ||
                          subItem?.referenceGenerated >= 0
                            ? subItem?.referenceGenerated
                            : "-"}
                        </td>
                        <td>
                          {subItem?.referenceLeadTarget ||
                          subItem?.referenceLeadTarget >= 0
                            ? subItem?.referenceLeadTarget
                            : "-"}
                        </td>
                        <td>
                          {subItem?.referenceDeficit ||
                          subItem?.referenceDeficit >= 0
                            ? subItem?.referenceDeficit
                            : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Daily Meeting"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12  formWidth">
          {SearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
