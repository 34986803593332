import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  elements,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getCallRecordCount,
  getCallTimeCount,
  getLeadDepartmentCount,
  getOverdueDepartmentCount,
  getStatusWiseDepartmentCount,
  getStatusWiseDepartmentRecordCount,
  getTargetAchieved,
  getTotalDepartmentAchieved,
  getWebinarCompleteCount,
  getWebinarCountExecutive,
  getOverDueIntrestCount,
  getOverDueCallBackCount,
} from "../../Redux/Actions/bdExecutivePanelAction";
import BarGraphWithoutOnclick from "../../Component/barGraphWithouOnclick";
import BDEDashboardGraph from "../../Component/bdeDashbordGraph";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function BDEDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [leadCount, setLeadCount] = useState<any>("");
  const [departmentCount, setDepartmentCount] = useState<any>("");

  const [notInterested, setNotInterested] = useState("");
  const [interestedCount, setInterestedCount] = useState("");
  const [webinarCompleted, setWebinarCompleted] = useState("");
  const [callBackToday, setCallBackToday] = useState("");
  const [overdueInterested, setOverdueInterested] = useState("");
  const [overdueCallBackCount, setOverdueCallBackCount] = useState("");
  const [webinarCompletedCount, setWebinarCompletedCount] = useState("");
  const [webinarCompletedCountExecutive, setWebinarCompletedCountExecutive] = useState("");
  const [overdueInterestedExecutive, setOverdueInterestedExecutive] = useState("");
  const [xLabel1, setXLabel1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [xLabel4, setXLabel4] = useState<any>([]);
  const [xLabel5, setXLabel5] = useState<any>([]);
  const [xLabel6, setXLabel6] = useState<any>([]);
  const [xLabel7, setXLabel7] = useState<any>([]);

  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [Graphdata2, setGraphdata2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [Graphdata6, setGraphdata6] = useState<any>([]);
  const [Graphdata7, setGraphdata7] = useState<any>([]);


  useEffect(() => {
    onChangeGetRevenue();
    let data = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getStatusWiseDepartmentCount(
        data,
        (res: any) => {
          setNotInterested(res?.NOTINTERESTEDCount);
          setWebinarCompleted(res?.WEBINARDONECount);
        },
        () => {}
      )
    );
    dispatch(
      getStatusWiseDepartmentRecordCount(
        data,
        (res: any) => {
          setInterestedCount(res?.INTERESTEDTODAYCount);
          setCallBackToday(res?.CALLBACKTODAYCount);
        },
        () => {}
      )
    );

    dispatch(
      getOverdueDepartmentCount(
        data,
        (res: any) => {
          setOverdueInterested(res?.data?.INTERESTEDTODAYCount);
        },
        () => {}
      )
    );
    dispatch(
      getCallRecordCount(
        "",
        (res: any) => {
          for (let i = 0; i <= xLabel4?.length; i++) {
            xLabel4?.pop();
            Graphdata4?.pop();
          }
          for (let i = 0; i <= xLabel5?.length; i++) {
            xLabel5?.pop();
            Graphdata5?.pop();
          }
          for (let i = 0; i <= xLabel6?.length; i++) {
            xLabel6?.pop();
            Graphdata6?.pop();
          }
          if (res) {
            if (res?.data?.totalConnectedCall?.target >= 0) {
              xLabel4?.push("Target");
              Graphdata4?.push(res?.data?.totalConnectedCall?.target);
            }
            if (res?.data?.totalConnectedCall?.achieved >= 0) {
              xLabel4?.push("Achieved");
              Graphdata4.push(res?.data?.totalConnectedCall?.achieved);
            }
            if (res?.data?.uniqueConnectedCall?.target >= 0) {
              xLabel5?.push("Target");
              Graphdata5?.push(res?.data?.uniqueConnectedCall?.target);
            }
            if (res?.data?.uniqueConnectedCall?.achieved >= 0) {
              xLabel5?.push("Achieved");
              Graphdata5.push(res?.data?.uniqueConnectedCall?.achieved);
            }
            if (res?.data?.oldConnectedCall?.target >= 0) {
              xLabel6?.push("Target");
              Graphdata6?.push(res?.data?.oldConnectedCall?.target);
            }
            if (res?.data?.oldConnectedCall?.achieved >= 0) {
              xLabel6?.push("Achieved");
              Graphdata6.push(res?.data?.oldConnectedCall?.achieved);
            }
          }
        },
        () => {}
      )
    );
    dispatch(
      getCallTimeCount(
        "",
        (res: any) => {
          for (let i = 0; i <= xLabel1?.length; i++) {
            xLabel1?.pop();
            Graphdata1?.pop();
          }

          if (res) {
            if (res?.data?.target >= 0) {
              xLabel1?.push("Target");
              Graphdata1?.push(res?.data?.target);
            }
            if (res?.data?.achieved >= 0) {
              xLabel1?.push("Achieved");
              Graphdata1.push(res?.data?.achieved);
            }
          }
        },
        () => {}
      )
    );
    dispatch(
      getTargetAchieved(
        {
          startDate:startDate,
          endDate:endDate
        },
        (res: any) => {
          for (let i = 0; i <= xLabel3?.length; i++) {
            xLabel3?.pop();
            Graphdata3?.pop();
          }
          if (res) {
            if (res[0]?.dailyTargetLeads >= 0) {
              xLabel3?.push("Target");
              Graphdata3?.push(res[0]?.dailyTargetLeads);
            }
            if (res[0]?.achievedLeads >= 0) {
              xLabel3?.push("Achieved");
              Graphdata3.push(res[0]?.achievedLeads);
            }
          }
        },
        () => {}
      )
    );
    dispatch(
      getTotalDepartmentAchieved(
        "",
        (res: any) => {
          for (let i = 0; i <= xLabel7?.length; i++) {
            xLabel7?.pop();
            Graphdata7?.pop();
          }
          if (res) {
            if (res?.data[0]?.DepartmentTarget >= 0) {
              xLabel7?.push("Target");
              Graphdata7?.push(res?.data[0]?.DepartmentTarget);
            }
            if (res?.data[0]?.achievedDepartment >= 0) {
              xLabel7?.push("Achieved");
              Graphdata7.push(res?.data[0]?.achievedDepartment);
            }
          }
        },
        () => {}
      )
    );

    dispatch(
      getWebinarCompleteCount(
        "",
        (res: any) => {
          setWebinarCompletedCount(res?.data?.achieved);
          for (let i = 0; i <= xLabel2?.length; i++) {
            xLabel2?.pop();
            Graphdata2.pop();
          }

          if (res) {
            if (res?.data?.target >= 0) {
              xLabel2?.push("Target");
              Graphdata2?.push(res?.data?.target);
            }
            if (res?.data?.achieved >= 0) {
              xLabel2?.push("Achieved");
              Graphdata2.push(res?.data?.achieved);
            }
          }
        },
        () => {}
      )
    );
    dispatch(
      getWebinarCountExecutive(
        {
          status: 2,
        },
        (res: any) => {
          setWebinarCompletedCountExecutive(res);
        },
        () => {}
      )
    );

    dispatch(
      getOverDueIntrestCount(
        {
          status: 2,
        },
        (res: any) => {
          setOverdueInterestedExecutive(res);
           
        },
        () => {}
      )
    );

    dispatch(
      getOverDueCallBackCount(
        {
          status: 3,
        },
        (res: any) => {
          setOverdueCallBackCount(res);
           
        },
        () => {}
      )
    );
  }, []);
  const onChange = (value: string) => {
    if (value === "notInterested") {
      navigate("../notIntrestedBDELead");
    } else if (value === "interestedToday") {
      navigate("../intrestedBDELead");
    } else if (value === "callBackToday") {
      navigate("../callBackBDELead");
    } else if (value === "overdueInterested") {
      navigate("../overdueInterestedBDELead");
    } else if (value === "overdueCallBackBDELead") {
      navigate("../overdueCallBackBDELead");
    } else if (value === "webinarCompleted") {
      navigate("../completeWebinar");
    }
  };

  const onChangeGetRevenue = () => {
    let data = {
      startDate,
      endDate,
    };

    dispatch(
      getLeadDepartmentCount(
        data,
        (res: any) => {
          setDepartmentCount(res?.departmentCount);
          setLeadCount(res?.leadCount);
        },
        () => {}
      )
    );
  };

  const renderGraphMode1 = () => {
    return (
      <div className="mt-3 mx-3 p-2 ">
        <div className="row d-flex mx-5 mt-3">
          <div className="col-md-4 pe-3 ">
            <div className="boxshadow  p-4  ">
              <div className="cursor">
                <BDEDashboardGraph label={xLabel1} value={Graphdata1} />
              </div>
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Calling Time</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <div className="cursor">
                <BDEDashboardGraph label={xLabel2} value={Graphdata2} />
              </div>
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Webinar Completed</label>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <div className="cursor">
                <BDEDashboardGraph label={xLabel3} value={Graphdata3} />
              </div>
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Lead Generated</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 pe-3">
            <div className="boxshadow  p-4  ">
              <div className="cursor">
                <BDEDashboardGraph label={xLabel4} value={Graphdata4} />
              </div>
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Total Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <div className="cursor">
                <BDEDashboardGraph label={xLabel5} value={Graphdata5} />
              </div>
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Unique Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <div className="cursor">
                <BDEDashboardGraph label={xLabel6} value={Graphdata6} />
              </div>
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Old Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <div className="cursor">
                <BDEDashboardGraph label={xLabel7} value={Graphdata7} />
              </div>
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Total Department Added</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"Dashboard"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth">
          <div className=" counsellorDashbord d-flex px-2 mx-5">
            <div className="col-md-10 d-flex">
              <div className="col-md-3 d-flex justify-content-center pe-2 mt-3">
                <div className="col-md-5 d-flex flex-row-reverse me-2">
                  <LabelField lableName="Total Lead:" />
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4">
                    {leadCount ? leadCount : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-center pe-2 mt-3">
                <div className="col-md-5 d-flex flex-row-reverse me-2">
                  <LabelField lableName="Department" />
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4">
                    {departmentCount ? departmentCount : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex mt-3 ">
                <div className="col-md-5 d-flex flex-row-reverse">
                  <LabelField lableName="Start Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => setStartDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex me-2  mt-3">
                <div className="col-md-5 d-flex flex-row-reverse">
                  <LabelField lableName="End Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => setEndDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-center">
              <button className="btn" onClick={onChangeGetRevenue}>
                Search
              </button>
            </div>
          </div>
          <div className="analysisDiv">
            <div className="row d-flex justify-content-between mx-5">
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("notInterested");
                  }}
                >
                  <h2 className="fw-bold text-success">
                    {notInterested ? notInterested : 0}
                  </h2>
                  <p className="fs-5">Not Intrested</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("webinarCompleted");
                  }}
                >
                  <h2 className="fw-bold text-success">
                     {webinarCompletedCountExecutive ? webinarCompletedCountExecutive : 0}  
                    {/* {webinarCompletedCount ? webinarCompletedCount : 0} */}
                  </h2>
                  <p className="fs-5">Webinar Completed</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("interestedToday");
                  }}
                >
                  <h2 className="fw-bold text-warning">
                    {interestedCount ? interestedCount : 0}
                  </h2>
                  <p className="fs-5">Interested Today</p>
                </div>
              </div>
              <div className="col-md-4 mt-3 text-center ">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("callBackToday");
                  }}
                >
                  <h2 className="fw-bold text-warning">
                    {callBackToday ? callBackToday : 0}
                  </h2>
                  <p className="fs-5">Call Back Today</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("overdueInterested");
                  }}
                >
                  <h2 className="fw-bold text-danger">
                    {overdueInterestedExecutive ? overdueInterestedExecutive : 0}
                  </h2>
                  <p className="fs-5">Overdue Interested</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("overdueCallBackBDELead");
                  }}
                >
                  <h2 className="fw-bold text-danger">
                    {overdueCallBackCount ? overdueCallBackCount : 0}
                  </h2>
                  <p className="fs-5">Overdue Call Back</p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="px-5 pt-5 mx-5 d-flex Justify-content-between">
                <h3 className=" col-md-3 text-primary fw-bold">Analysis</h3>

                <div className="col-md-2 d-flex  ">
                  <div className="teargetbg px-4 my-3 me-3"></div>
                  <p className="mt-3 fw-bold ">Target</p>
                </div>
                <div className="col-md-2 d-flex   me-5">
                  <div className="unachievedbg px-4 my-3 me-3"></div>
                  <p className="mt-3 fw-bold ">Achieved</p>
                </div>
              </div>

              {renderGraphMode1()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
