import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import SubmitBtn from "../../Component/submitBtn";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getCounsellorListById,
  getManagerReferencesTeamList,
  getReferenceCounsellorListById,
  getManagerReferencesByTeamLeadId,
  getEmployeetByManagerId,
} from "../../Redux/Actions/managerPanelAction";
import moment from "moment";
import { useSelector } from "react-redux";
import SubmitButton from "../../Component/submitButton";
import SalesPanelGraph from "../../Component/salesPanelGraph";
import CustomDropdown from "../../Component/customDropdown";
import {
  getSMManagerList,
  getSMReferenceAnalysis,
} from "../../Redux/Actions/superManagerAction";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SAReferenceAnalysisSuperManager() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);
  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [linkList, setLinkList] = useState<any>([]);
  const [teamLead, setTeamLead] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const { managerId, loggedInUserId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  //  const linkList =["../saReferenceAnalysis","../saReferenceAnalysis"]
  useEffect(() => {
    dispatch(
      getSMReferenceAnalysis(
        {
          startDate: startDate,
          endDate: endDate,
          superManagerId: loggedInUserId,
        },
        (res: any) => {
          setTeamLeadList(res?.data);
          showGraph(res?.data);
          let tempLinkList: any = [];
          for (let i = 0; i <= res?.data?.length; i++) {
            tempLinkList?.push("../saReferenceAnalysis");
          }
          setLinkList(tempLinkList);
        },
        () => {}
      )
    );

    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
  }, []);
  const OnchangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const showGraph = (list: any) => {
    setXLabel([]);
    setGraphdata1([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((val: any) => {
      labelList?.push(val?.name);
      graphDataList?.push(val?.studentleadCount);
    });
    setXLabel(labelList);
    setGraphdata1(graphDataList);
  };

  const getManagerData = (val: any) => {
    sessionStorage.setItem("managerId", teamLeadList[val?.index]?._id);
  };
  const onChangeTeamLead = (value: any) => {
    setTeamLead(value);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      superManagerId: loggedInUserId,
    };
    dispatch(
      getSMReferenceAnalysis(
        postdata,
        (res: any) => {
          setTeamLeadList(res?.data);
          showGraph(res?.data);
        },
        () => {}
      )
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"References Generated Analysis"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {/* <h3 className='fw-bold text-primary'></h3> */}
          <form className="col-md-12 row d-flex" onSubmit={handleSubmit}>
            <div className="col-md-3 d-flex pe-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex pe-2 mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>

            {/* <div className="col-md-3 d-flex pe-2 mt-3">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Manager" />
            </div>
            <div className="col-md-8 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => OnchangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
              />
            </div>
          </div>
            
          <div className="col-md-3 d-flex pe-2 mt-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Team Lead" />
              </div>
              <div className="col-md-8  ">
                <CustomDropdown
                  lableName="Select Team Lead"
                  setInputText={(value: any) => onChangeTeamLead(value)}
                  value={teamLead}
                  options={teamLeadList}
                />
              </div>
            </div> */}
            <div className=" col-md-3 d-flex justify-content-center mb-3">
              <SubmitButton name={"Search"} />
            </div>
          </form>
          <div className="  p-3">
            <h4 className="fw-bold">Summary Overview</h4>
            <SalesPanelGraph
              label={xLabel}
              link={linkList}
              value={Graphdata1}
              getExamStreamData={(val: any) => getManagerData(val)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
