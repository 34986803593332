import TablePagination from "@mui/material/TablePagination";
import { title } from "process";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from "react";
import "../App.css";
import { DownloadTableExcel } from "react-export-table-to-excel";

interface props {
  count?: any;
  tabs?: any;
  onTabChange?: any;
  selectedIndex?: any;
  title?: any;
  tableData?: any;
  page?: any;
  rowsPerPage?: any;
  handleChangePage?: any;
  handleChangeRowsPerPage?: any;
  rowsPerPageOptions?: any;
  handleClick?: any;
  pagename?: any;
  isExport?: any;
  exportRef?: any;
}
function PaginationView(props: props) {
  const {
    count,
    tabs,
    onTabChange,
    selectedIndex,
    title,
    tableData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPageOptions,
    handleClick,
    pagename,
    isExport,
    exportRef,
  } = props;

  return (
    <div className="pe-3">
      <h5 className="font-weight-bold">{title}</h5>
      <div className="d-flex justify-content-between align-items-center paginateno">
        <div>
          {tabs?.map(
            (
              data:
                | string
                | number
                | boolean
                | ReactElement<any, string | JSXElementConstructor<any>>
                | Iterable<ReactNode>
                | ReactPortal
                | null
                | undefined,
              index: number
            ) => {
              return (
                <button
                  className={`btn me-3 ${
                    selectedIndex === index + 1
                      ? "btn-primary"
                      : "btn btn-outline-dark"
                  }`}
                  onClick={(e) => onTabChange(e, index + 1)}
                >
                  {data}
                </button>
              );
            }
          )}
        </div>

        <div className="d-flex align-items-center">
          {pagename === "ManageEmployee" ? (
            <>
              {selectedIndex === 1 ? (
                <button
                  className="btn btn-primary my-3 mx-3"
                  onClick={() => handleClick()}
                >
                  {" "}
                  Add Employee
                </button>
              ) : (
                ""
              )}
              {isExport ? (
                <DownloadTableExcel
                  filename={pagename}
                  sheet={pagename}
                  currentTableRef={exportRef.current}
                >
                  <button className="btn btn-outline-dark my-3"> Export</button>
                </DownloadTableExcel>
              ) : null}
            </>
          ) : (
            ""
          )}
          <div className="mt-4">
            <TablePagination
              component="div"
              count={count ? count : tableData?.length}
              //   count={tableData?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaginationView;
