import { useState } from "react";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import {
  addLeadInCRM,
  addLeadOrderInCRM,
  checkOrderStatus,
  getLeadOrdersSearch,
} from "../../Redux/Actions/leadManagementAction";
import InputField from "../../Component/inputField";
import {
  containsNumber,
  length_Ten,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import ToggleSwitchbtn from "../../Component/toggleSwitchBtn";
import { useAppDispatch } from "../../hooks";

export default function LeadOrdersSearch() {
  const dispatch = useAppDispatch();
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [isActive, setIsActive] = useState<any>(true);

  const [leadsDataList, setLeadsDataList] = useState<any>([]);

  const onChangeToggle = (value: any) => {
    if (value.target.checked) {
      setIsActive(value.target.checked);
      setMobileNumber("");
      setLeadsDataList([]);
    } else {
      setIsActive(value.target.checked);
      setMobileNumber("");
      setLeadsDataList([]);
    }
  };

  const onChangeMobile = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMobileNumber(val);
      }
    } else {
      setMobileNumber(val);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData = {
      isMobile: isActive,
    };
    dispatch(
      getLeadOrdersSearch(
        mobileNumber,
        postData,
        (res: any) => {
          setLeadsDataList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleAddLead = () => {
    dispatch(
      addLeadInCRM(
        mobileNumber,
        (res: any) => {
          setLeadsDataList([]);
        },
        () => {}
      )
    );
  };

  const handleCheckOrder = (val: any) => {
    if (val?.paymentGatewayOrderId) {
      dispatch(
        checkOrderStatus(
          val?.paymentGatewayOrderId,
          (res: any) => {
            if (res?.data?.statusMonitor === "Success") {
              dispatch(showSuccessSnackbar(res?.data?.statusMonitor));
            } else if (res?.data?.statusMonitor === "Failure") {
              dispatch(showErrorSnackbar(res?.data?.statusMonitor));
            }
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Payment Gateway OrderId is not present."));
    }
  };

  const handleAdd = (val: any) => {
    dispatch(
      addLeadOrderInCRM(
        {
          orderUserPairs: [
            {
              userId: val?.userId,
              orderId: val?._id,
            },
          ],
        },
        () => {
          setLeadsDataList([]);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSubmit}>
        <div className="col-md-3 my-2">
          <ToggleSwitchbtn
            lableName="Active"
            getToggleval={(value: any) => {
              onChangeToggle(value);
            }}
            isActive={isActive}
          />
        </div>
        {isActive ? (
          <div className="col-md-4 d-flex mb-2 align-items-center me-3">
            <LabelField lableName={"Mobile No."} />
            <div className="removelabel">
              <InputField
                placeholder={"Enter Mobile Number"}
                onChangeInput={(value: any) => onChangeMobile(value)}
                value={mobileNumber}
                maxlength={length_Ten}
                minlength={length_Ten}
                isRequired={true}
              />
            </div>
          </div>
        ) : (
          <div className="col-md-4 d-flex mb-2 align-items-center me-3">
            <LabelField lableName={"Order No."} />
            <div className="removelabel">
              <InputField
                placeholder={"Enter Order No"}
                onChangeInput={(value: any) => onChangeMobile(value)}
                value={mobileNumber}
                // maxlength={length_Ten}
                // minlength={length_Ten}
                isRequired={true}
              />
            </div>
          </div>
        )}

        <div className="col-md-4 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          {leadsDataList?.map((item: any, index: number) => {
            return (
              <div className="boxshadow p-1 m-1" key={index}>
                <div className="mb-3">
                  <div className="fw-bold">User Details</div>
                  <table className="w-100 mb-2">
                    <tr>
                      <th>S.N</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Mob No</th>
                      <th>Email</th>
                      <th>Is Mobile Verified</th>
                      <th>Status</th>
                      <th>Number Present In CRM</th>
                      <th>Actions</th>
                    </tr>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.firstName}</td>
                      <td>{item?.lastName}</td>
                      <td>{item?.mobileNo}</td>
                      <td>{item?.email}</td>
                      <td>{item?.isMobileVerified === true ? "Yes" : "No"}</td>
                      <td>
                        {item?.status === 1
                          ? "Activate"
                          : item?.status === 9
                          ? "Deleted"
                          : "Deactivate"}
                      </td>
                      <td>{item?.numberPresent === true ? "Yes" : "No"}</td>
                      <td>
                        {!item?.numberPresent ? (
                          <span
                            className="text-danger fw-bold cursor"
                            onClick={() => handleAddLead()}
                          >
                            {" "}
                            Add{" "}
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="mb-3">
                  <div className="fw-bold">User Orders</div>
                  <table className="w-100 mb-2">
                    <tr>
                      <th>S.N</th>
                      <th>User Id</th>
                      <th>Order Id</th>
                      <th>Order No</th>
                      <th>Order Date</th>
                      <th>Courses</th>
                      <th>Status</th>
                      <th>Delivery Fee</th>
                      <th>NetPayable Amount</th>
                      <th>Check Order Status</th>
                      <th>Add in CRM Actions</th>
                    </tr>
                    {item?.orders?.length > 0 ? (
                      item?.orders?.map((subdata: any, i: number) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{subdata?.userId}</td>
                            <td>{subdata?._id}</td>
                            <td>{subdata?.orderNo}</td>
                            <td>
                              {" "}
                              {moment(subdata?.date)
                                .utc()
                                .format("DD-MM-YYYY hh:mm:ss A")}
                            </td>
                            <td>
                              {subdata?.courses?.map(
                                (data: any, Index: number) => {
                                  return (
                                    <>
                                      <span>
                                        {index + 1} {data?.couponName}
                                      </span>
                                    </>
                                  );
                                }
                              )}
                            </td>
                            <td>
                              {subdata?.status === 0
                                ? "Pending"
                                : subdata?.status === 2
                                ? "Successful"
                                : subdata?.status === 9
                                ? "Cancelled"
                                : "-"}
                            </td>
                            <td>{subdata?.deliveryFee}</td>
                            <td>{subdata?.netPayableAmount}</td>
                            <td>
                              {subdata?.status !== 2 ? (
                                <span
                                  className="text-danger fw-bold cursor"
                                  onClick={() => handleCheckOrder(subdata)}
                                >
                                  {" "}
                                  Check{" "}
                                </span>
                              ) : null}
                            </td>
                            <td>
                              {!subdata?.OrderIdPresent &&
                              subdata?.status === 2 &&
                              item?.numberPresent ? (
                                <span
                                  className="text-danger fw-bold cursor"
                                  onClick={() => handleAdd(subdata)}
                                >
                                  {" "}
                                  Add{" "}
                                </span>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>{/* <NoRecord /> */}</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"LEAD ORDERS SEARCH"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-4">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
