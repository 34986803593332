interface props {
    name: String,
    validation?:boolean,
    handleClick:any
 }
 
 export default function Button({ name ,validation,handleClick}: props) {
    return (
       <button type="button" className={validation ? " font14 btn btn-secondary px-5 searchButton ":" font14 btn btn-outline-secondary px-5 searchButton"} disabled={validation} onClick={handleClick}>{name}</button>
    );
 }
 