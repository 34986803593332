export const BDEPanel = [
  {
    title: "DASHBOARD",
    path: "bdeDashboard",
  },
  {
    title: "COLLEGE LIST",
    path: "collegeList",
  },
  {
    title: "WEBINAR",
    subNav: [
      {
        title: "Upcoming",
        path: "upcomingWebinar",
        cName: "sub-nav",
      },
      {
        title: "Completed",
        path: "completeWebinar",
        cName: "sub-nav",
      },
      {
        title: "Cancelled",
        path: "cancelledWebinar",
        cName: "sub-nav",
      },
      {
        title: "Pending",
        path: "pendingWebinar",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "WEBINAR ANALYSIS",
    subNav: [
      {
        title: "Not Interested",
        path: "notIntrestedBDELead",
        cName: "sub-nav",
      },
      {
        title: "Interested Today",
        path: "intrestedBDELead",
        cName: "sub-nav",
      },
      {
        title: "Call Back Today",
        path: "callBackBDELead",
        cName: "sub-nav",
      },
      {
        title: "Overdue Interested",
        path: "overdueInterestedBDELead",
        cName: "sub-nav",
      },
      {
        title: "Overdue Call Back",
        path: "overduecallBackBDELead",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "COLLEGE DEPARTMENT ADDED",
    path: "collegeDepartment",
  },
  {
    title: "TARGET DASHBOARD",
    path: "targetStatus",
  },
  {
    title: "MANAGER REMARK",
    path: "managerRemark",
  },

  {
    title: "LEAD ANALYSIS",
    subNav: [
      {
        title: "Lead Analysis",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Lead Department Wise Analysis",
        path: "departmentwiseleadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "RESULT COMPILATION",
  //   path: "resultCompilation",
  // },
];
