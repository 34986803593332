import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import {
  ResultOtherDetails,
  categoryList,
  yearData,
} from "../../DATA/dataConstant";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { pdf_size_1MB, validateIsFileImageType } from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../../DATA/errorMsg";
import SubmitButton from "../../Component/submitButton";
import { getVrifyStudentNumber } from "../../Redux/Actions/bdeAdminPanelAction";

export default function ResultCompilation() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [details, setDetails] = useState<any>("");
  const [rank, setRank] = useState<any>("");
  const [photo, setPhoto] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [mobNumber, setMobNumber] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [qualifyYear, setQualifyYear] = useState<any>("");
  const [qualifyFor, setQualifyFor] = useState<any>("");
  const [rollNumber, setRollNumber] = useState<any>("");
  const [studentName, setStudentName] = useState<any>("");
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
  }, []);

  const onClickverify = (val: any) => {
    dispatch(
      getVrifyStudentNumber(
        mobNumber,
        (res: any) => {},
        () => {}
      )
    );
  };

  const onChangeExam = (val: any) => {
    setExam(val);

    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };
  const onChangeQualifyFor = (val: string) => {
    setQualifyFor(val);
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 1 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
      // dispatch(uploadExamLogo(file, (res: any) => {
      //   delete res?.filePath;
      //   setUploadExamlogo(res);
      // }, () => { }));
    }
  };

  const onChangesetCategory = (val: string) => {
    setCategory(val);
  };

  const onChangeStudentname = (val: string) => {
    setStudentName(val);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const renderSearchMode = () => {
    return (
      <div className=" ms-5 p-2 formDiv">
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="col-md-4 d-flex  mt-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Mobile Number" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={mobNumber}
                  onChangeInput={(value: any) => setMobNumber(value)}
                  isRequired={true}
                />
              </div>
            </div>
            <div className=" d-flex col-md-9 ms-2 pe-2">
              <button
                type="submit"
                className="btn btn-outline-primary searchButton "
                onClick={onClickverify}
              >
                Verify Number
              </button>
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="First Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => setFirstName(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Last Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => setLastName(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Email Address " />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Email Address"
                value={email}
                onChangeInput={(value: any) => setEmail(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam"
                setInputText={(value: any) => onChangeExam(value)}
                value={exam}
                options={examList}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Stream" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam Stream"
                setInputText={(value: any) => onChangeExamStream(value)}
                value={examStream}
                options={examStreamList}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Qualify Year" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Qualify Year"
                setInputText={(value: any) => setQualifyYear(value)}
                value={qualifyYear}
                options={yearData}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Qualified for" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Qualified for"
                setInputText={(value: any) => onChangeQualifyFor(value)}
                value={qualifyFor}
                options={ResultOtherDetails}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Category" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Category"
                setInputText={(value: any) => onChangesetCategory(value)}
                value={category}
                options={categoryList}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Roll Number" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Roll Number"
                value={rollNumber}
                onChangeInput={(value: any) => setRollNumber(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Rank" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <InputField
                placeholder="Enter Rank"
                value={rank}
                onChangeInput={(value: any) => setRank(value)}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="IFAS Student Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select IFAS Student Name"
                setInputText={(value: any) => onChangeStudentname(value)}
                value={studentName}
                options={studentList}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName={"Photo"} />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <ImagePickerAndViewer
                lableName={"Upload Photo"}
                value={photo}
                handleChange={(e: any) => handleOptionImageChange(e)}
                isRequired={true}
              />
            </div>
          </div>

          <div className=" d-flex mb-3 ms-5">
            <SubmitButton name={"Submit"} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"RESULT COMPILATION"} />
      <div className="  p-3 formDiv">
        <div className="">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div></div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
