import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LabelField from "../../Component/labelField";
import { ifasianList } from "../../DATA/dataConstant";
import CustomDropdown from "../../Component/customDropdown";
import moment from "moment";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import Header from "../../Component/header";
import PageTitle from "../../Component/pageTitle";
import {
  containsNumber,
  hasWhiteSpace,
  length_One,
  length_Ten,
  length_Thirty,
  length_Three,
  length_Twenty,
  length_Two,
  startsWithSpace,
  validateFullName,
  validateIsLettersAndDigitsOnly,
  validateIsLettersAndDotOnly,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  validateIsAlphabetErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import { getWebinarListByDeptID } from "../../Redux/Actions/bdExecutivePanelAction";
import {
  getBDTeamLeadReamrk,
  getCallHistory,
} from "../../Redux/Actions/bdManagerPanelAction";
// import { getDepartmentWebinarList } from '../../Redux/Actions/bdManagerPanelAction';
import InputField from "../../Component/inputField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
export default function WebinarAnalysisDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = location.state?.pageName;
  const IsEdit = location.state?.IsEdit;
  const IsCheck = location.state?.IsCheck;
  const departmentData = location.state?.departmentData;
  const [department, setDepartment] = useState("");
  const [webinarList, setWebinarList] = useState([]);
  const [callHistory, setCallHistory] = useState<any>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [id, setId] = useState<any>("");
  const [managerRemark, setManagerRemark] = useState<any>("");
  useEffect(() => {
    // dispatch(
    // getDepartmentWebinarList(
    // departmentData?._id,
    // (res: any) => {
    // setWebinarList(res);

    // },
    // () => { }
    // )
    // );

    if (IsCheck === "true") {
      dispatch(
        getWebinarListByDeptID(
          departmentData?._id,
          (res: any) => {
            setWebinarList(res?.data);
          },
          () => {}
        )
      );
    }
    /********************************************** Call History************************************************************/
    if (IsCheck === "true") {
      const PostData = {
        departmentId: departmentData?.departmentId,
      };
      dispatch(
        getCallHistory(
          PostData,
          (res: any) => {
            setCallHistory(res);
          },
          () => {}
        )
      );
    }

    // if (IsCheck === "true" && pageName ==="timeAnalysisOverview") {
    // dispatch(
    // getWebinarListByDeptID(
    // departmentData?.departmentId,
    // (res: any) => {
    // setWebinarList(res?.data);
    // },
    // () => {}
    // )
    // );
    // }
  }, []);

  const onchangeDepartment = (val: any) => {
    if (val !== "") {
      if (!validateIsLettersAndDotOnly(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (startsWithSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setDepartment(val);
      }
    } else {
      setDepartment(val);
    }
  };

  const handleEdit = (val: any) => {
    setOpenPopup(true);
    setManagerRemark(val?.managerRemark);
    setId(val?._id);
  };
  const handleClose = () => {
    setOpenPopup(false);
  };
  const changeRemark = (val: any) => {
    setManagerRemark(val);
  };

  const handleSubmitManagerRemark = () => {
    setOpenPopup(false);
    dispatch(
      getBDTeamLeadReamrk(
        id,
        {
          managerRemark,
        },
        () => {
          dispatch(
            getCallHistory(
              {
                departmentId: departmentData?.departmentId,
              },
              (res: any) => {
                setCallHistory(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  return (
    <div className="contentBox">
      <div className=" ">
        <div className="col-md-12  formDiv formWidth  ">
          <div className="col-md-9 px-3">
            <h4 className="fw-bold">
              {departmentData?.college_data?.collegeName}
            </h4>
          </div>
          <div className="   font17">
            <div className=" row d-flex justify-content-center px-2 ">
              {/* *********************************************Deaprtment************************************************ */}
              <div className=" pt-2 ">
                {/* <div className="tabheader p-2">Department Details</div> */}
                <div className="col-md-7 row d-flex justify-content-between mt-2 pt-2">
                  <div className="col-md-4 ">
                    <LabelField lableName="Deaprtment Name" />
                  </div>
                  <div className="col-md-7">
                    <InputField
                      placeholder="Enter Deaprtment Name"
                      value={departmentData?.departmentData?.departmentName}
                      onChangeInput={(value: any) =>
                        onchangeDepartment(value?.toUpperCase())
                      }
                      disabled={IsEdit ? true : false}
                      maxlength={length_Thirty}
                      minlength={length_Three}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between ">
                <div className="col-md-6 col-sm-12 p-2">
                  {/* *********************************************Primary Contact ********************************************************** */}
                  <div className=" ">
                    <div className="tabheader p-2 d-flex ">Primary Contact</div>
                    <div className="px-3">
                      <div className="row d-flex justify-content-between pt-2 ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="First Name" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentData?.departmentData?.primaryContact
                            ?.firstName
                            ? departmentData?.departmentData?.primaryContact
                                ?.firstName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Last Name" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentData?.departmentData?.primaryContact
                            ?.lastName
                            ? departmentData?.departmentData?.primaryContact
                                ?.lastName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 1" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentData?.departmentData?.primaryContact
                            ?.contact1
                            ? departmentData?.departmentData?.primaryContact
                                ?.contact1
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 2" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentData?.departmentData?.primaryContact
                            ?.contact2
                            ? departmentData?.departmentData?.primaryContact
                                ?.contact2
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Email" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentData?.departmentData?.primaryContact?.email
                            ? departmentData?.departmentData?.primaryContact
                                ?.email
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Designation" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentData?.departmentData?.primaryContact
                            ?.designation
                            ? departmentData?.departmentData?.primaryContact
                                ?.designation
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="IFASian" />
                        </div>
                        <div className="col-md-7 mt-2 ps-3">
                          {departmentData?.departmentData?.primaryContact
                            ?.IFASian
                            ? departmentData?.departmentData?.primaryContact
                                ?.IFASian
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ************************************************Seconadry Contact************************************************** */}
                  <div className=" ">
                    <div className="tabheader p-2 d-flex">
                      Seconadry Contact
                    </div>
                    <div className="px-3">
                      <div className="row d-flex justify-content-between pt-2 ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="First Name" />
                        </div>
                        <div className="col-md-7">
                          {departmentData?.departmentData?.secondaryContact
                            ?.firstName
                            ? departmentData?.departmentData?.secondaryContact
                                ?.firstName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Last Name" />
                        </div>
                        <div className="col-md-7">
                          {departmentData?.departmentData?.secondaryContact
                            ?.lastName
                            ? departmentData?.departmentData?.secondaryContact
                                ?.lastName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 1" />
                        </div>
                        <div className="col-md-7">
                          {departmentData?.departmentData?.secondaryContact
                            ?.contact1
                            ? departmentData?.departmentData?.secondaryContact
                                ?.contact1
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 2" />
                        </div>
                        <div className="col-md-7">
                          {departmentData?.departmentData?.secondaryContact
                            ?.contact2
                            ? departmentData?.departmentData?.secondaryContact
                                ?.contact2
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Email" />
                        </div>
                        <div className="col-md-7">
                          {departmentData?.departmentData?.secondaryContact
                            ?.email
                            ? departmentData?.departmentData?.secondaryContact
                                ?.email
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Designation" />
                        </div>
                        <div className="col-md-7">
                          {departmentData?.departmentData?.secondaryContact
                            ?.designation
                            ? departmentData?.departmentData?.secondaryContact
                                ?.designation
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="IFASian" />
                        </div>
                        <div className="col-md-7 ps-3">
                          {departmentData?.departmentData?.secondaryContact
                            ?.IFASian
                            ? departmentData?.departmentData?.secondaryContact
                                ?.IFASian
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************Call History*********************************************************** */}
                <div className=" col-md-6 row d-flex">
                  <div className="mx-3 p-2">
                    <div className="tabheader p-2 d-flex">Call History</div>
                    <div className="webinarcallhistorydiv ">
                      {callHistory?.map((item: any, index: any) => {
                        return (
                          <div className="my-2">
                            {item?.call_recording === null ? (
                              <p className=" ps-4 fw-bold mb-0 text-primary">
                                Call Back Date :
                                {moment(item?.callBackDate).format(
                                  "YYYY-MM-DD"
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                            {item?.call_recording !== null ? (
                              <>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Called Date & Time :
                                    {moment(item?.call_date).format(
                                      "YYYY-MM-DD "
                                    )}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Call Type : {item?.calltype}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Call Duration : {item?.call_duration}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Call Status :{item?.call_transfer_status}
                                  </span>
                                </div>
                              </>
                            ) : null}
                            <p className=" ps-4 mb-0 text-primary ">
                              Status : {item?.departmentStatus}
                            </p>

                            <p className=" ps-4 mb-0 text-success">
                              Status Details :{item?.statusDetails}
                            </p>
                            {item?.call_recording &&
                            item?.call_recording !== "None" ? (
                              <div className="d-flex justify-content-center">
                                <audio
                                  className="w-100 p-2 mx-2"
                                  src={item?.call_recording}
                                  controls
                                ></audio>
                              </div>
                            ) : (
                              ""
                            )}
                            <p className=" ps-4 mb-0 ">
                              Comment :{item?.remark}
                              <span className=" mb-0 text-secondary"></span>
                            </p>
                            <p className=" ps-4 mb-0 ">
                              Manager Comment :{" "}
                              <span className=" mb-0 text-secondary"></span>
                              <span
                                className="text-Dark fw-bold me-2 ms-2 cursor"
                                onClick={() => handleEdit(item)}
                              >
                                <BorderColorIcon />
                              </span>
                            </p>
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modelChat"
          open={openPopup}
          onClose={() => {
            handleClose();
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPopup}>
            <div className="modeldivChat p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}> Manager Remark</span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
              <div className="form-check">
                <div>
                  <p className="text-primary">
                    <div className="row d-flex justify-content-between">
                      <textarea
                        className="form-control"
                        placeholder={"Comment"}
                        value={managerRemark}
                        onChange={(e) => changeRemark(e.target.value)}
                      ></textarea>
                    </div>
                  </p>
                </div>
                <div className="col-auto d-flex justify-content-center mt-2">
                  <button
                    onClick={() => {
                      handleClose();
                    }}
                    type="submit"
                    className="btn btn-outline-dark mb-3 submitbtn me-3"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleSubmitManagerRemark();
                    }}
                    type="submit"
                    className="btn btn-primary mb-3 submitbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
