import { useEffect, useState } from "react";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import GenaralTable from "../../Component/genaralTable";
import { useSelector } from "react-redux";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import moment from "moment";
import {
  getManagerCallRecord,
  getTeamLeadList,
  getManagerDailyTalkTime,
} from "../../Redux/Actions/managerPanelAction";
import SalesPanelSummeryGraph from "../../Component/salesPanelSummeryGraph";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function TimeAnalysis() {
  const dispatch = useAppDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const [xLabelCallAll, setXLabelCallAll] = useState<any>([]);
  const [GraphdataCallAll, setGraphdataCallAll] = useState<any>([]);
  const [xLabelCallUnique, setXLabelCallUnique] = useState<any>([]);
  const [GraphdataCallUnique, setGraphdataCallUnique] = useState<any>([]);
  const [talkTimeList, setTalkTimeList] = useState<any>([]);
  const [talkTimeLabelList, setTalkTimeLabelList] = useState<any>([]);
  const [linkList, setLinkList] = useState<any>([]);
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata, setGraphdata1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);
  const [piedata, setPiedata] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [xLabel4, setXLabel4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [totalRevenue, SetTotalRevenue] = useState<any>("");
  const { loggedInUserId, loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  useEffect(() => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    const postData = {
      startDate,
      endDate,
    };
    dispatch(
      getManagerDailyTalkTime(
        loggedInUserId,
        postData,
        (res: any) => {
          // let daylist = getDatesInRange(new Date(startDate),new Date(endDate));
          let daylist = res?.dates;
          daylist?.unshift("Name");
          setTalkTimeLabelList(daylist);
          setTalkTimeList(res?.data);
        },
        () => {}
      )
    );

    const data = {
      startTime: startDate,
      endTime: endDate,
    };

    dispatch(
      getManagerCallRecord(
        loggedInUserId,
        data,
        (res: any) => {
          showGraphCallTotalConnected(res?.data);
          showGraphCallAllConnected(res?.data);
          showGraphCallUniqueConnected(res?.data);
          let tempLinkList: any = [];
          for (let i = 0; i <= res?.data?.length; i++) {
            tempLinkList?.push("../timeAnalysisOverview");
          }
          setLinkList(tempLinkList);
        },
        () => {}
      )
    );
  }, []);

  const getDatesInRange = (startDate: any, endDate: any) => {
    for (
      var arr = [], dt = new Date(startDate);
      dt <= new Date(endDate);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    let temp = arr.map((item: any) => item.toISOString().slice(0, 10));
    return temp;
  };

  const showGraphCallTotalConnected = (list: any) => {
    setXLabelCallTotal([]);
    setGraphdataCallTotal([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.totalConnectedCall?.achieved);
    });
    setXLabelCallTotal(labelList);
    setGraphdataCallTotal(graphDataList);
  };

  const showGraphCallAllConnected = (list: any) => {
    setXLabelCallAll([]);
    setGraphdataCallAll([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.oldConnectedCall?.achieved);
    });
    setXLabelCallAll(labelList);
    setGraphdataCallAll(graphDataList);
  };

  const showGraphCallUniqueConnected = (list: any) => {
    setXLabelCallUnique([]);
    setGraphdataCallUnique([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.uniqueConnectedCall?.achieved);
    });
    setXLabelCallUnique(labelList);
    setGraphdataCallUnique(graphDataList);
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <h4 className="fw-bold">Team Talk Time</h4>
        <GenaralTable
          tableData={talkTimeList ? talkTimeList : []}
          pagename={"timeAnalysis"}
          tableHead={talkTimeLabelList}
        />
      </div>
    );
  };
  const showGraph = (list: any) => {
    let total = 0;
    while (xLabel.length) {
      xLabel.pop();
      Graphdata?.pop();
      xLabel2.pop();
      Graphdata3?.pop();
    }
    list?.map((item: any) => {
      total = total + item?.revenue;
      xLabel?.push(item?._id?.name);
      Graphdata?.push(item?.revenue);
      xLabel2?.push(item?._id?.name);
      Graphdata3?.push(item?._id?.status);
    });
    SetTotalRevenue(total);
  };

  const onChange = (e: any) => {
    e.preventDefault();
    let data = {
      startDate,
      endDate,
    };
    dispatch(
      getTeamLeadList(
        data,
        (res: any) => {
          showGraph(res?.data);
          // piechartData(res);
        },
        () => {}
      )
    );

    const postData = {
      startTime: startDate,
      endTime: endDate,
    };

    dispatch(
      getManagerCallRecord(
        loggedInUserId,
        postData,
        (res: any) => {
          showGraphCallTotalConnected(res?.data);
          showGraphCallAllConnected(res?.data);
          showGraphCallUniqueConnected(res?.data);
          let tempLinkList: any = [];
          for (let i = 0; i <= res?.data?.length; i++) {
            tempLinkList?.push("../timeAnalysisOverview");
          }
          setLinkList(tempLinkList);
        },
        () => {}
      )
    );

    dispatch(
      getManagerDailyTalkTime(
        loggedInUserId,
        {
          startDate,
          endDate,
        },
        (res: any) => {
          // let daylist = getDatesInRange(new Date(startDate),new Date(endDate));
          let daylist = res?.dates;
          daylist?.unshift("Name");
          setTalkTimeLabelList(daylist);
          setTalkTimeList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Time Analysis"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-2">
          <form className="  d-flex px-5  mx-5" onSubmit={onChange}>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className=" col-md-3 text-center mb-2  mt-2 me-4 ">
              <SubmitButton name={"Submit"} />
            </div>
          </form>

          {loggedInUserRole === 2 ? (
            <>
              <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
                <div className="col-md-12 p-3">
                  <h4 className="fw-bold">Total Connected calls</h4>
                  <SalesPanelSummeryGraph
                    label={xLabelCallTotal}
                    value={GraphdataCallTotal}
                    link={linkList}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
                <div className="col-md-12 p-3">
                  <h4 className="fw-bold">Old Connected calls</h4>
                  <CallRecordedGraph
                    label={xLabelCallAll}
                    value={GraphdataCallAll}
                    // link={link1}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
                <div className="col-md-12 p-3">
                  <h4 className="fw-bold">Unique Connected calls</h4>
                  <CallRecordedGraph
                    label={xLabelCallUnique}
                    value={GraphdataCallUnique}
                    // link={link1}
                  />
                </div>
              </div>
            </>
          ) : null}

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
