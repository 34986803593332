import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import { ifasianList } from "../../DATA/dataConstant";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import Header from "../../Component/header";
import {
  getDetailsbyDepartmentID,
  getWebinarListByDeptID,
} from "../../Redux/Actions/bdExecutivePanelAction";
import moment from "moment";
import {
  getCallHistory,
  getDeptDetailsByDeptID,
} from "../../Redux/Actions/bdManagerPanelAction";

export default function Details() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const collegeData = location.state?.college;
  const departmentID = location.state?.departmentId;
  // const departmentDetails = location.state?.departmentDetails;
  const [departmentDetails, setDepartmentDeatils] = useState<any>();
  const [webinarList, setWebinarList] = useState([]);
  const [callHistory, setCallHistory] = useState<any>([]);

  useEffect(() => {
    dispatch(
      getDeptDetailsByDeptID(
        departmentID,
        (res: any) => {
          setDepartmentDeatils(res[0]);
        },
        () => {}
      )
    );
    dispatch(
      getWebinarListByDeptID(
        departmentID,
        (res: any) => {
          setWebinarList(res?.data);
        },
        () => {}
      )
    );
    const PostData = {
      departmentId: departmentID,
      // collegeId: collegeData?._id,
      // webinarId:
    };
    dispatch(
      getCallHistory(
        PostData,
        (res: any) => {
          setCallHistory(res);
        },
        () => {}
      )
    );
  }, []);

  return (
    <div className="col-md-12">
      <Header />

      <div className="row m-0">
        <div className="col-md-12 detailsdiv font14">
          <div className="boxshadow mx-5 my-1">
            <div className="d-flex Justify-content-between p-2 pt-3">
              <div className="col-md-9 px-3">
                <h4 className="fw-bold">{collegeData?.collegeName}</h4>
              </div>
            </div>
            {/* *********************************************Deaprtment************************************************ */}
            <div className=" pt-2 ">
              {/* <div className="tabheader p-2">Department Details</div> */}
              <div className="col-md-7 row d-flex justify-content-between mt-2 p-2 px-4">
                <div className="col-md-4  ">
                  <LabelField lableName="Deaprtment Name" />
                </div>
                <div className="col-md-7"></div>
              </div>
            </div>
            <div className="p-1 d-flex justify-content-center  ">
              <div className="col-md-9 row d-flex justify-content-center px-2 ">
                {/* *********************************************Primary Contact ********************************************************** */}
                <div className="col-md-6 col-sm-12 p-2">
                  <div className=" border-bottom border-3 border-dark p-2 fw-bold text-primary font17 ">
                    Primary Contact
                  </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="First Name" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.primaryContact?.firstName}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Last Name" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.primaryContact?.lastName}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 1" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.primaryContact?.contact1}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 2" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.primaryContact?.contact2
                        ? departmentDetails?.primaryContact?.contact2
                        : "-"}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Email" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.primaryContact?.email}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Designation" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.primaryContact?.designation}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="IFASian" />
                    </div>
                    <div className="col-md-7  mt-2">
                      {departmentDetails?.primaryContact?.IFASian
                        ? departmentDetails?.primaryContact?.IFASian
                        : "-"}
                    </div>
                  </div>
                </div>
                {/* ************************************************Seconadry Contact************************************************** */}
                <div className="col-md-6 p-2">
                  <div className="border-bottom border-3 border-dark p-2 fw-bold text-primary font17">
                    Seconadry Contact
                  </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="First Name" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.secondaryContact?.firstName
                        ? departmentDetails?.secondaryContact?.firstName
                        : "-"}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Last Name" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.secondaryContact?.lastName
                        ? departmentDetails?.secondaryContact?.lastName
                        : "-"}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 1" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.secondaryContact?.contact1
                        ? departmentDetails?.secondaryContact?.contact1
                        : "-"}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 2" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.secondaryContact?.contact2
                        ? departmentDetails?.secondaryContact?.contact2
                        : "-"}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Email" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.secondaryContact?.email
                        ? departmentDetails?.secondaryContact?.email
                        : "-"}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Designation" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.secondaryContact?.designation
                        ? departmentDetails?.secondaryContact?.designation
                        : "-"}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="IFASian" />
                    </div>
                    <div className="col-md-7  mt-2">
                      {departmentDetails?.secondaryContact?.IFASian
                        ? departmentDetails?.secondaryContact?.IFASian
                        : "-"}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-2">
                  <div className="border-bottom border-3 border-dark p-2 fw-bold text-primary font17 ">
                    Exam
                  </div>
                  <div className="row d-flex justify-content-between  pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Exam" />
                    </div>
                    <div className="col-md-7 ps-3 mt-2">
                      {departmentDetails?.examType?.name}
                    </div>
                  </div>

                  {/* <div className="row d-flex justify-content-between">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Sub Exam Name" />
                    </div>
                    <div className="col-md-7 ps-3 mt-2">{departmentDetails?.subExamTypeId?.name}</div>
                  </div> */}

                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Exam Steram" />
                    </div>
                    <div className="col-md-7 ps-3 mt-2">
                      {departmentDetails?.baseCourse?.name}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="1st Year" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.FirstYear}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="2nd Year" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.SecondYear}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="3rd Year" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.ThirdYear}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="border-bottom border-3 border-dark p-2 fw-bold text-primary font17 ">
                    Action & Disposition
                  </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status" />
                    </div>

                    <div className="col-md-7 mt-2">
                      {departmentDetails?.leadStatus?.status}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status Details" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.leadSubStatus?.status}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Lead Type" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {departmentDetails?.leadType?.name}
                    </div>
                  </div>

                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Call Back Date" />
                    </div>
                    <div className="col-md-7 mt-2">
                      {moment(departmentDetails?.callBackDate).format(
                        "DD/MM/YYYY, h:mm:ss a"
                      )}
                    </div>
                  </div>
                  {/* <div className=" d-flex justify-content-end  ">
                    <SubmitButton name={IsEdit ?"Update":"Save"} validation={validation()} />
                  </div> */}
                  <></>
                </div>
              </div>
              <div className="col-md-3 p-3 pt-2">
                <div className="tabheader p-2">Call History</div>
                <div className="callhistorydetails font14">
                  {callHistory?.map((item: any) => {
                    return (
                      <div className="my-2 p-3 border-bottom  border-secondary shadow mb-2   rounded">
                        <p className=" ps-4 fw-bold    mb-0  ">
                          Name : {item?.name}
                        </p>

                        <p className=" ps-4 mb-0   ">Status :{item?.departmentStatus}</p>
                        <p className=" ps-4 mb-0   ">Status Details:{item?.statusDetails}</p>
                        {item?.call_recording?<p className=" ps-4 mb-0  ">
                          Comment :{" "}
                          <span className=" mb-0   ">{item?.remark}</span>
                        </p>:null}
                       { item?.call_recording?<p className=" ps-4 mb-0  ">
                          call Date :{" "}
                         {item?.call_date? <span className=" mb-0   ">
                            {moment(item?.call_date)
                              .utc()
                              .format("DD-MM-YYYY ")}
                          </span>:"-"}
                        </p>:null}
                        {item?.call_recording?<p className=" ps-4 mb-0  ">
                          call Time :{" "}
                          {item?.call_time?<span className=" mb-0   ">
                            {item?.call_time}
                          </span>:"-"}
                        </p>:null}

                       {item?.call_recording? <div className="justify-content-center">
                          {/* {item?.call_recording?.map((val: any) => {
                              return ( */}
                          <audio
                            className="ps-4 mb-0"
                            controls
                            src={item?.call_recording}
                          ></audio>
                          {/* );
                            })} */}
                        </div>:null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              <table className="salesTable w-100">
                <thead className=" ">
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Coordinator Name</th>
                    <th>Coordinator Phone</th>
                    <th>Coordinator Email</th>
                    <th>Webinar Teacher</th>
                    <th>Lead Generated</th>
                    <th>Admission</th>
                  </tr>
                </thead>
                <tbody>
                  {webinarList?.map((item: any) => {
                    return (
                      <tr>
                        <td>
                          {item?.webinarAt
                            ? moment(item?.webinarAt)
                                .utc()
                                .format("DD-MM-YYYY ")
                            : "-"}{" "}
                        </td>
                        <td>
                          {item?.webinarAt
                            ? moment(item?.webinarAt).utc().format("LT")
                            : "-"}
                        </td>
                        <td>
                          {item?.coordinatorName ? item?.coordinatorName : "-"}
                        </td>
                        <td>
                          {item?.coordinatorPhone
                            ? item?.coordinatorPhone
                            : "-"}
                        </td>
                        <td>
                          {item?.coordinatorEmail
                            ? item?.coordinatorEmail
                            : "-"}
                        </td>
                        <td>
                          {item?.teacher_data?.name
                            ? item?.teacher_data?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.generated_count ? item?.generated_count : "-"}
                        </td>
                        <td>
                          {" "}
                          {item?.admission_count ? item?.admission_count : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
