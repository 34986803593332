import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import Header from "../../Component/header";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import InputField from "../../Component/inputField";
import SubmitButton from "../../Component/submitButton";
import { PendingWebinarStatus, webinarStatus } from "../../DATA/dataConstant";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  containsNumber,
  length_Three,
  pdf_size_1MB,
  startsWithSpace,
  validateEmailAddress,
  validateFullName,
  validateIsFileImageType,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  validateEmailAddressErrMsg,
  validateIsFileImageTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import {
  getExamStreamList,
  updateCancelledWebinar,
  updateCompletedWebinar,
  updateRescheduleWebinar,
  uploadMailScreenshot,
} from "../../Redux/Actions/bdExecutivePanelAction";
import ReplyIcon from "@mui/icons-material/Reply";

export default function TLPendingwebinarDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const webinarId = location.state?.webinarId;
  const webinarData = location.state?.webinarData;

  const [status, setStatus] = useState<any>("");
  const [statusList, setStatusList] = useState([]);
  const [resetStatus, setResetStatus] = useState(false);
  const [dateTime, setDateTime] = useState("");
  const [remark, setRemark] = useState<any>("");
  const [image, setImage] = useState<any>("");
  const [UploadImage, setUploadImage] = useState<any>("");
  const [fName1, setFName1] = useState<any>("");
  const [lName1, setLName1] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [email1, setEmail1] = useState<any>("");
  const [fName2, setFName2] = useState<any>("");
  const [lName2, setLName2] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email2, setEmail2] = useState<any>("");
  const [fName3, setFName3] = useState<any>("");
  const [lName3, setLName3] = useState<any>("");
  const [contact3, setContact3] = useState<any>("");
  const [email3, setEmail3] = useState<any>("");
  const [fName4, setFName4] = useState<any>("");
  const [lName4, setLName4] = useState<any>("");
  const [contact4, setContact4] = useState<any>("");
  const [email4, setEmail4] = useState<any>("");
  const [fName5, setFName5] = useState<any>("");
  const [lName5, setLName5] = useState<any>("");
  const [contact5, setContact5] = useState<any>("");
  const [email5, setEmail5] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [uploadMailScreenshotFile, setUploadMailScreenshotFile] =
    useState<any>("");
  const [uploadMailScreenshotData, setUploadMailScreenshotData] =
    useState<any>("");

  const [examStream, setExamStream] = useState<any>("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [resetExamStreamList, setResetExamStreamList] = useState(false);

  useEffect(() => {
    dispatch(
      getExamStreamList(
        `${webinarData?.examType_data?._id}`,
        (res: any) => {
          setExamStreamList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeExamStream = (val: any) => {
    setExamStream(val);
  };

  const onChangeStatus = (val: any) => {
    setStatus(val);
  };

  useEffect(() => {
    setStatus(5);
  }, []);

  const handleImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 150 KB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setSelectedFile(reader.result);
        setUploadMailScreenshotData(file);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMailScreenshot(
          file,
          (res: any) => {
            delete res?.filePath;
            setUploadMailScreenshotFile(res);
          },
          () => {}
        )
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData1 = {
      status: 2,
      mailURL: uploadMailScreenshotFile?.imageLogo,
      rankers: [
        {
          firstName: fName1,
          lastName: lName1,
          phoneNo: contact1,
          email: email1,
          rank: "first",
        },
        {
          firstName: fName2,
          lastName: lName2,
          phoneNo: contact2,
          email: email2,
          rank: "Second",
        },
        {
          firstName: fName3,
          lastName: lName3,
          phoneNo: contact3,
          email: email3,
          rank: "Third",
        },
        {
          firstName: fName4,
          lastName: lName4,
          phoneNo: contact4,
          email: email4,
          rank: "Fourth",
        },
        {
          firstName: fName5,
          lastName: lName5,
          phoneNo: contact5,
          email: email5,
          rank: "Fifth",
        },
      ],
    };
    dispatch(
      updateCompletedWebinar(
        webinarId,
        postData1,
        (res: any) => {
          setStatus("");
          setSelectedFile("");
          setFName1("");
          setLName1("");
          setContact1("");
          setEmail1("");
          setFName2("");
          setLName2("");
          setContact2("");
          setEmail2("");
          setFName3("");
          setLName3("");
          setContact3("");
          setEmail3("");
          setFName4("");
          setLName4("");
          setContact4("");
          setEmail4("");
          setFName5("");
          setLName5("");
          setContact5("");
          setEmail5("");
        },
        () => {}
      )
    );
  };
  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const validation1 = () => {
    return (
      status === "" ||
      selectedFile === "" ||
      fName1 === "" ||
      lName1 === "" ||
      contact1 === "" ||
      fName2 === "" ||
      lName2 === "" ||
      contact2 === "" ||
      fName3 === "" ||
      lName3 === "" ||
      contact3 === "" ||
      fName4 === "" ||
      lName4 === "" ||
      contact4 === "" ||
      fName5 === "" ||
      lName5 === "" ||
      contact5 === ""
      // email1 === "" ||
      // email1?.length < length_Three ||
      // !email1.match(emailsCheck) ||
      // email2 === "" ||
      // email2?.length < length_Three ||
      // !email2.match(emailsCheck) ||
      // email3 === "" ||
      // email3?.length < length_Three ||
      // !email3.match(emailsCheck) ||
      // email4 === "" ||
      // email4?.length < length_Three ||
      // !email4.match(emailsCheck) ||
      // email5 === "" ||
      // email5?.length < length_Three ||
      // !email5.match(emailsCheck)
    );
  };

  // validations

  // Rank1
  const onChangeFirstName1 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName1(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName1 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName1(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact1 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact1(val?.toUpperCase());
      }
    } else {
      setContact1(val?.toUpperCase());
    }
  };

  const onChangeEmail1 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail1(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail1(val);
    }
  };

  //  Rank2

  const onChangeFirstName2 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName2(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName2 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName2(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact2 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val?.toUpperCase());
      }
    } else {
      setContact2(val?.toUpperCase());
    }
  };

  const onChangeEmail2 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail2(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail2(val);
    }
  };

  //  Rank3

  const onChangeFirstName3 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName3(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName3 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName3(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact3 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact3(val?.toUpperCase());
      }
    } else {
      setContact3(val?.toUpperCase());
    }
  };

  const onChangeEmail3 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail3(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail3(val);
    }
  };

  //  Rank4

  const onChangeFirstName4 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName4(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName4 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName4(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact4 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact4(val?.toUpperCase());
      }
    } else {
      setContact4(val?.toUpperCase());
    }
  };

  const onChangeEmail4 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail4(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail4(val);
    }
  };

  //  Rank5

  const onChangeFirstName5 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName5(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName5 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName5(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact5 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact5(val?.toUpperCase());
      }
    } else {
      setContact5(val?.toUpperCase());
    }
  };

  const onChangeEmail5 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail5(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail5(val);
    }
  };

  return (
    <div className="col-md-12 maindiv">
      <div className="mt-2 ">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => navigate(-1)}
        >
          <ReplyIcon /> Back
        </button>
      </div>
      <div className="row m-0">
        <div className="col-md-12 font17 p-5 pt-3 ">
          <div className="mx-5 detailsdiv boxshadow p-3">
            <div className="row d-flex justify-content-between  font14">
              {/* ************************ Co-Ordinator Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Co-Ordinator</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Name:</div>
                  <div className="col-md-8">{webinarData?.coordinatorName}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Phone :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorPhone}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Email :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorEmail}
                  </div>
                </div>
              </div>
              {/* ************************Webinar Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Webinar Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Date :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Time :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("hh:mm:ss a")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Link :</div>
                  {/* <div className='col-md-8'>{webinarData?.meetingURL}</div> */}
                  <div className="col-md-8 ">
                    <a
                      href={webinarData?.meetingURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {webinarData?.meetingURL}
                    </a>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Faculty :</div>
                  <div className="col-md-8">
                    {webinarData?.teacher_data?.name
                      ? webinarData?.teacher_data?.name
                      : "-"}
                  </div>
                </div>
              </div>

              {/* ************************Collage Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Collage Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Collage :</div>
                  <div className="col-md-8">
                    {webinarData?.college_data?.collegeName}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">State :</div>
                  <div className="col-md-8">
                    {webinarData?.state_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">City :</div>
                  <div className="col-md-8">{webinarData?.city_data?.name}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Exam :</div>
                  <div className="col-md-8">
                    {webinarData?.examType_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Department :</div>
                  <div className="col-md-8 ">
                    {webinarData?.department_data?.departmentName}
                  </div>
                </div>
              </div>

              {/* *********************************Lead Details *************************************** */}

              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Lead Details:</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Lead Registered :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.TotalCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">First Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.FirstYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Second Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.SecondYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Third Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.ThirdYearCount}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom border-3 border-dark">
              <h5 className="text-primary fw-bold">Webinar Status</h5>
            </div>
            {/* *************************************************Status****************************************************** */}
            <form className="col-md-12 mt-4" onSubmit={handleSubmit}>
              <div className="col-md-9 mt-4">
                <div className="col-md-6 row d-flex justify-content-between mt-1">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Status" />
                  </div>
                  <div className="col-md-7  ps-3">
                    <CustomDropdown
                      lableName="Select Status"
                      setInputText={(value: any) => onChangeStatus(value)}
                      value={status}
                      options={PendingWebinarStatus}
                      defaultValue={status}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Mail ScreenShot" />
                    </div>
                    <div className="col-md-8">
                      <ImagePickerAndViewer
                        lableName={"Upload Sample EXCEL"}
                        value={selectedFile}
                        removelabel={true}
                        htmlFor={`photo-upload`}
                        handleChange={(e: any) => handleImageChange(e)}
                        disabled={status?._id === 5}
                      />
                      <label className="col-md-8">
                        {uploadMailScreenshotData?.name
                          ? uploadMailScreenshotData?.name
                          : ""}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-4">
                <h5 className="fw-bold text-center">Topper List</h5>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 1</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={fName1}
                          onChangeInput={(value: any) =>
                            onChangeFirstName1(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lName1}
                          onChangeInput={(value: any) =>
                            onChangeLastName1(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={contact1}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            onChangeContact1(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={email1}
                          onChangeInput={(value: any) => onChangeEmail1(value)}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={examStream}
                          options={examStreamList}
                          disabled={status?._id === 5}
                          Isrequired={true}
                          reset={resetExamStreamList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 2</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={fName2}
                          onChangeInput={(value: any) =>
                            onChangeFirstName2(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lName2}
                          onChangeInput={(value: any) =>
                            onChangeLastName2(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={contact2}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            onChangeContact2(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={email2}
                          onChangeInput={(value: any) => onChangeEmail2(value)}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={examStream}
                          options={examStreamList}
                          disabled={status?._id === 5}
                          Isrequired={true}
                          reset={resetExamStreamList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 3</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={fName3}
                          onChangeInput={(value: any) =>
                            onChangeFirstName3(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lName3}
                          onChangeInput={(value: any) =>
                            onChangeLastName3(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={contact3}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            onChangeContact3(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={email3}
                          onChangeInput={(value: any) => onChangeEmail3(value)}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={examStream}
                          options={examStreamList}
                          disabled={status?._id === 5}
                          Isrequired={true}
                          reset={resetExamStreamList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 4</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={fName4}
                          onChangeInput={(value: any) =>
                            onChangeFirstName4(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lName4}
                          onChangeInput={(value: any) =>
                            onChangeLastName4(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={contact4}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            onChangeContact4(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={email4}
                          onChangeInput={(value: any) => onChangeEmail4(value)}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={examStream}
                          options={examStreamList}
                          disabled={status?._id === 5}
                          Isrequired={true}
                          reset={resetExamStreamList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 5</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={fName5}
                          onChangeInput={(value: any) =>
                            onChangeFirstName5(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lName5}
                          onChangeInput={(value: any) =>
                            onChangeLastName5(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={contact5}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            onChangeContact5(value)
                          }
                          isRequired={true}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={email5}
                          onChangeInput={(value: any) => onChangeEmail5(value)}
                          disabled={status?._id === 5}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={examStream}
                          options={examStreamList}
                          disabled={status?._id === 5}
                          Isrequired={true}
                          reset={resetExamStreamList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className=" col-md-12 text-center mb-2  mt-2 me-4 ">
                <SubmitButton name={"Submit"} validation={validation1()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
