export const CrmManagerPanelSidebarData = [
  {
    title: "DASHBOARD",
    path: "managerDashbord",
  },
  {
    title: "TEAM LEADS OVERVIEW",
    path: "teamLeadsOverview",
  },
  // {
  //   title: "LEAD GENERATION DASHBOARD",
  //   subNav: [
  //     {
  //       title: "Lead Generation Overview",
  //       path: "leadGenerationOverview",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Lead Generation Analysis",
  //       path: "leadGenerationAnalysis",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "TEAM ANALYSIS",
    subNav: [
      {
        title: "Lead Analysis",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Enrolled Analysis",
        path: "enrolledAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Course Analysis",
        path: "courseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "References Generated Analysis",
        path: "referenceAnalysisManager",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      // {
      //   title: "Leads Allotted Date",
      //   path: "teamLeadAssignedDate",
      //   cName: "sub-nav",
      // },
      {
        title: "Leads Allotted Date Analysis",
        path: "teamLeadAssignedDateAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Admission Date",
        path: "managerAdmissionDate",
        cName: "sub-nav",
      },
      {
        title: "Exam Revenue Based On Admission Date",
        path: "managerExamRevenue",
        cName: "sub-nav",
      },
      {
        title: "Course Subscription Months",
        path: "courseSubscriptionMonths",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "Target Status",
        path: "managerTargetStatus",
        cName: "sub-nav",
      },
      // {
      //   title: "PIP Status",
      //   path: "*",
      //   cName: "sub-nav",
      // },
      {
        title: "Direct Target Status",
        path: "incentiveTargetStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COUPON CODE",
    path: "couponCode",
  },
  {
    title: "MANAGE TEAM",
    subNav: [
      {
        title: "Active Counsellor",
        path: "activeCounsellor",
        cName: "sub-nav",
      },
      // {
      //   title: "Ex Counsellor",
      //   path: "exCounsellor",
      //   cName: "sub-nav",
      // },
    ],
  },
  {
    title: "SKIP DETAILS",
    path: "skipDetails",
  },
  // {
  //   title: "LEAD SEARCH",
  //   path: "leadSearchByMobileNumber",
  // },
  {
    title: "ATTENDANCE",
    subNav: [
      // {
      //   title: "Attendance",
      //   path: "attendance",
      //   cName: "sub-nav",
      // },
      {
        title: "Monthly Report",
        path: "monthlyReport",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DATA ANALYSIS",
    subNav: [
      {
        title: "Time Analysis",
        path: "timeAnalysisManager",
        cName: "sub-nav",
      },
      {
        title: "Revenue Analysis",
        path: "revenueAnalysisManager",
        cName: "sub-nav",
      },
      // {
      //   title: "Remark Analysis",
      //   path: "remarkAnalysisManager",
      //   cName: "sub-nav",
      // },
    ],
  },
  {
    title: "DAILY MEETING",
    subNav: [
      {
        title: "1 PM DAILY MEETING",
        path: "dailyMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INCENTIVE ALLOCATION",
    path: "incentiveAllocation",
  },
  {
    title: "MANAGER REMARK",
    path: "managerRemark",
  },
  // {
  //   title: "RESULT COMPILATION",
  //   path: "resultCompilation",
  // },
  {
    title: "USER ACTIVITY TRACKER",
    path: "userActivityTracker",
  },
];
