import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LabelField from "../../Component/labelField";
import moment from "moment";
import {
  showErrorSnackbar
} from "../../Redux/Actions/snackbarAction";
import {
  length_Thirty,
  length_Three,
  startsWithSpace,
  validateIsLettersAndDotOnly,
} from "../../DATA/validators";
import { validateIsAlphabetErrMsg } from "../../DATA/errorMsg";
import {
  getCallHistory,
  getDeptDetailsByDeptID,
} from "../../Redux/Actions/bdManagerPanelAction";
import InputField from "../../Component/inputField";
import ReplyIcon from "@mui/icons-material/Reply";
export default function ManagerRemarkDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const IsEdit = location.state?.IsEdit;
  const departmentID = location.state?.departmentID;
  const [department, setDepartment] = useState("");
  const [callHistory, setCallHistory] = useState<any>([]);
  const [departmentDetails, setDepartmentDeatils] = useState<any>();

  useEffect(() => {
    dispatch(
      getDeptDetailsByDeptID(
        departmentID,
        (res: any) => {
          setDepartmentDeatils(res[0]);
        },
        () => {}
      )
    );
    const PostData = {
      departmentId: departmentID,
    };
    dispatch(
      getCallHistory(
        PostData,
        (res: any) => {
          setCallHistory(res);
        },
        () => {}
      )
    );
  }, []);

  const onchangeDepartment = (val: any) => {
    if (val !== "") {
      if (!validateIsLettersAndDotOnly(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (startsWithSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setDepartment(val);
      }
    } else {
      setDepartment(val);
    }
  };

  return (
    <div className="contentBox">
      <div className="col-md-12 ">
        <div className="mt-2 ">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => navigate(-1)}
          >
            <ReplyIcon /> Back
          </button>
        </div>
        <div className=" col-md-12  formDiv formWidth  ">
          {/* <div className="col-md-9 px-3">
            <h4 className="fw-bold">
              {departmentDetails?.collegedetails?.collegeName}
            </h4>
          </div> */}
          <div className="  px-3 font17">
            <div className="col-md-7 row d-flex justify-content-between mt-2 pt-2">
              <div className="col-md-4 ">
                <LabelField lableName="Deaprtment Name" />
              </div>
              <div className="col-md-7">
                <InputField
                  placeholder="Enter Deaprtment Name"
                  value={departmentDetails?.departmentName}
                  onChangeInput={(value: any) =>
                    onchangeDepartment(value?.toUpperCase())
                  }
                  disabled={IsEdit ? true : false}
                  maxlength={length_Thirty}
                  minlength={length_Three}
                />
              </div>
            </div>
            <div className=" row d-flex justify-content-center px-2 ">
              {/* *********************************************Deaprtment************************************************ */}

              {/* <div className="tabheader p-2">Department Details</div> */}

              <div className="d-flex justify-content-between ">
                <div className="col-md-6 col-sm-12 p-2">
                  {/* *********************************************Primary Contact ********************************************************** */}
                  <div className=" ">
                    <div className="tabheader p-2 d-flex ">Primary Contact</div>
                    <div className="px-3">
                      <div className="row d-flex justify-content-between pt-2 ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="First Name" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentDetails?.primaryContact?.firstName
                            ? departmentDetails?.primaryContact?.firstName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Last Name" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentDetails?.primaryContact?.lastName
                            ? departmentDetails?.primaryContact?.lastName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 1" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentDetails?.primaryContact?.contact1
                            ? departmentDetails?.primaryContact?.contact1
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 2" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentDetails?.primaryContact?.contact2
                            ? departmentDetails?.primaryContact?.contact2
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Email" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentDetails?.primaryContact?.email
                            ? departmentDetails?.primaryContact?.email
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Designation" />
                        </div>
                        <div className="col-md-7 mt-2">
                          {departmentDetails?.primaryContact?.designation
                            ? departmentDetails?.primaryContact?.designation
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="IFASian" />
                        </div>
                        <div className="col-md-7 mt-2 ps-3">
                          {departmentDetails?.primaryContact?.IFASian
                            ? departmentDetails?.primaryContact?.IFASian
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ************************************************Seconadry Contact************************************************** */}
                  <div className=" ">
                    <div className="tabheader p-2 d-flex">
                      Seconadry Contact
                    </div>
                    <div className="px-3">
                      <div className="row d-flex justify-content-between pt-2 ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="First Name" />
                        </div>
                        <div className="col-md-7">
                          {departmentDetails?.secondaryContact?.firstName
                            ? departmentDetails?.secondaryContact?.firstName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Last Name" />
                        </div>
                        <div className="col-md-7">
                          {departmentDetails?.secondaryContact?.lastName
                            ? departmentDetails?.secondaryContact?.lastName
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 1" />
                        </div>
                        <div className="col-md-7">
                          {departmentDetails?.secondaryContact?.contact1
                            ? departmentDetails?.secondaryContact?.contact1
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 2" />
                        </div>
                        <div className="col-md-7">
                          {departmentDetails?.secondaryContact?.contact2
                            ? departmentDetails?.secondaryContact?.contact2
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Email" />
                        </div>
                        <div className="col-md-7">
                          {departmentDetails?.secondaryContact?.email
                            ? departmentDetails?.secondaryContact?.email
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Designation" />
                        </div>
                        <div className="col-md-7">
                          {departmentDetails?.secondaryContact?.designation
                            ? departmentDetails?.secondaryContact?.designation
                            : "-"}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between mt-1">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="IFASian" />
                        </div>
                        <div className="col-md-7 ps-3">
                          {departmentDetails?.secondaryContact?.IFASian
                            ? departmentDetails?.secondaryContact?.IFASian
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************Call History*********************************************************** */}
                <div className=" col-md-6 row d-flex">
                  <div className="mx-3 p-2">
                    <div className="tabheader p-2 d-flex">Call History</div>
                    <div className="webinarcallhistorydiv ">
                      {callHistory?.map((item: any, index: any) => {
                        return (
                          <div className="my-2">
                            {item?.call_recording === null ? (
                              <p className=" ps-4 fw-bold mb-0 text-primary">
                                Call Back Date :
                                {moment(item?.callBackDate).format(
                                  "YYYY-MM-DD"
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                            {item?.call_recording !== null ? (
                              <>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Called Date & Time :
                                    {moment(item?.call_date).format(
                                      "YYYY-MM-DD "
                                    )}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Call Type : {item?.calltype}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Call Duration : {item?.call_duration}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <span className="ps-4 fw-bold mb-0 text-secondary">
                                    Call Status :{item?.call_status}
                                  </span>
                                </div>
                              </>
                            ) : null}
                            <p className=" ps-4 mb-0 text-primary ">
                              Status : {item?.departmentStatus}
                            </p>

                            <p className=" ps-4 mb-0 text-success">
                              Status Details :{item?.statusDetails}
                            </p>
                            {item?.call_recording &&
                            item?.call_recording !== "None" ? (
                              <div className="d-flex justify-content-center">
                                <audio
                                  className="w-100 p-2 mx-2"
                                  src={item?.call_recording}
                                  controls
                                ></audio>
                              </div>
                            ) : (
                              ""
                            )}
                            <p className=" ps-4 mb-0">
                             <span className="fw-bold">Comment :</span> {item?.remark ? item?.remark : "-"}
                              <span className=" mb-0 text-secondary"></span>
                            </p>
                            <p className=" ps-4 mb-0 ">
                             <span className="fw-bold">Manager Comment :</span> {item?.managerRemark ? item?.managerRemark : "-"}
                              <span className=" mb-0 text-secondary"></span>
                            </p>
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
