
import { useEffect, useState } from 'react'
import PageTitle from '../../Component/pageTitle';
import DatePicker from '../../Component/datePicker';
import LabelField from '../../Component/labelField';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getTargetStatus } from '../../Redux/Actions/counsellorPanelAction';
import "../../App.css";
import CustomDropdown from '../../Component/customDropdown';
import { getEmployeetByManagerId, getTargetStatusByLeadId, getTargetStatusByManager } from '../../Redux/Actions/managerPanelAction';
import { getSMManagerList } from '../../Redux/Actions/superManagerAction';

export default function SMTargetStatus() {
  const [startDate, SetStartDate] = useState<any>(
    moment().weekday(1).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().weekday(6).format("YYYY-MM-DD")
  );
  const [resetTargetList, setResetTargetList] = useState<any>([]);
  const [counsellor, setCounsellor] = useState();
  const [manager, setManager] = useState<any>("");
  const [counsellorList, setCounsellorList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState(false);
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
 const [resetTeamLead, setResetTeamLead] = useState(false);
  // const [targetStatusByLeadId, setResetTargetList] = useState<any>([]);
  const [targetByManager, setTargetByManager] = useState(false);


  const dispatch = useDispatch();

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));
  const { loggedInUserId} = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,  
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );

    
    
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
        
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    // setTargetByManager(false);
   
  };

  const onEndDateChange = (val: any) => {
    SetEndDate(val);
  };

  const renderListMode = () => {
    return (
      <>

         
        <div>
         
              <>
                <hr className="hr"></hr>
                <div className="d-flex mt-5 justify-content-center mx-3 ">
                  <p className="fw-bold fs-5">
                    Target Start Date :{" "}
                    <span>
                       
                    </span>
                  </p>
                  <p className="fw-bold fs-5 ms-5">
                    Target End Date :{" "}
                    <span>
                      
                    </span>
                  </p>
                </div>
                <div className="d-flex  justify-content-center m-5">
                  <div className="col-md-3 text-center boxshadow rounded p-4 me-5 ">
                    <h4 className="font-monospace">Highest Target</h4>

                    <h2 className="fw-bold">
                       
                    </h2>
                  </div>
                  <div className="col-md-3 text-center boxshadow  rounded p-4 me-5">
                    <h4 className="font-monospace">Revenue Achieved</h4>
                    <h2 className="fw-bold">
                      
                    </h2>
                  </div>
                  <div className="col-md-3 text-center boxshadow  rounded p-4 me-5">
                    <h4 className="font-monospace">Incentive Achieved</h4>
                    <h2 className="fw-bold">
                       
                    </h2>
                  </div>
                  <div className='col-md-3 text-center boxshadow  rounded p-4 me-5'>
                    <h4 className='font-monospace'>Reference Count</h4>
                    <h2 className='fw-bold'> </h2>
                  </div>

                </div>
                <div className="d-flex justify-content-between mx-3 ">
                  <p className="fw-bold">
                    Target Achieved :{" "}
                    <span>
                       
                    </span>
                  </p>

                  <p className="fw-bold">
                    Incentive Achieved :{" "}
                    <span>
                      
                    </span>
                  </p>
                </div>
                <table className="w-100">
                  <tr>
                    <th>Target Level </th>
                    <th>Target Amount</th>
                    <th>Start Amount</th>
                    <th>End Amount</th>
                    <th>Total Revenue</th>
                    <th>Incentive</th>
                    <th>Max Incentive</th>
                  </tr>
                  <tbody>
                     
                        <tr>
                          <td className="fw-bold">
                            {/* {data?.level === "bronze_level"
                              ? "Bronze Level"
                              : data?.level === "silver_level"
                                ? "Silver Level"
                                : data?.level === "gold_level"
                                  ? "Gold Level"
                                  : "Platinum Level"}{" "} */}
                          </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td>
                           
                          </td>
                          <td> </td>
                        </tr>
                    
                  </tbody>
                </table>
              </>
         
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Target Status"} />
      <div className="col-md-12 ">
        <div className="formDiv formWidth p-5">
        <div className='row d-flex '>
            <div className="col-md-4 d-flex mb-3  ">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>

            <div className="col-md-4 d-flex mb-3  ">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onEndDateChange(value)}
                />
              </div>
            </div>
          
         
            <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Manager" />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
                reset={resetManager}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Team Lead" />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Team Lead"
                setInputText={(value: any) => onChangeTeamLead(value)}
                value={teamLead}
                options={teamLeadList}
                // Isrequired={true}
                reset={resetTeamLead}
              />
            </div>
          </div>
            
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Select Counsellor" />
              </div>
              <div className="col-md-8 ps-2">
                <CustomDropdown
                  lableName="Select Counsellor"
                  setInputText={(value: any) => onChangeCounsellor(value)}
                  value={counsellor}
                  options={counsellorList}
                />
              </div>
            </div>
          </div>

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}

