import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomDropdown from '../../Component/customDropdown';
import PageTitle from '../../Component/pageTitle';
import PaginationTable from '../../Component/paginationTable';
import PaginationView from '../../Component/paginationView';
import SubmitButton from '../../Component/submitButton';
import usePagination from '../../Component/usePagination';
import { getTableDataSlots } from '../../DATA/dataConstant';
import InputField from '../../Component/inputField';
import { containsNumber, length_Fifty, length_Hundred, length_Ten, length_Three, length_Two, startsWithSpace, validateFullName, validateUniqueId, validateValueWithoutSpace } from '../../DATA/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';
import DatePicker from '../../Component/datePicker';
import LabelField from '../../Component/labelField';
import ToggleSwitchbtn from '../../Component/toggleSwitchBtn';
import Button from '../../Component/button';
import moment from 'moment'; 
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation } from 'react-router-dom';
// import { Employee } from '../../DATA/dataConstant';
import { getExecutiveByManagerID,getTeamList,updateEmployeeStatus } from '../../Redux/Actions/bdManagerPanelAction';
const initialVal: {
  _id: any;
  isActive: boolean;
}[] = [
    {
      _id: "",
      isActive: false,
    },
  ];


export default function BdeEmployee() {
  const dispatch = useDispatch();
  const location = useLocation();
 const [isAddEmployee, setAddEmployee] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [firstName, setFirstname] = useState<any>("");
  const [lastName, setLastname] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [manager, setManager] = useState<any>("");
   const [userName, setUsername] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [status, setStatus] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");
  const [stateArr, setstateArr] = useState<any>(initialVal);
 const [employeeList, setEmployeeList] = useState([]);
  const [empId, setEmpId] = useState<any>("");
  const [teamLead, setTeamLead] = useState<any>("");
  const [executive, setExecutive] = useState<any>("")
  const [executiveList, setExecutiveList] = useState([])
  const [ResetExecutive, setResetExecutive] = useState(false); 


  const IsLogIn = location.state?.IsLogIn;



  useEffect(() => {
    getEmpList("active");


     
    dispatch(
      getExecutiveByManagerID(
          "",
        (res: any) => {
          setExecutiveList(res)
            
        },
        () => { }
      )
    );
  }, []);

  const getEmpList = (data: any) => {
    setCount(0);
    setEmployeeList([]);
    dispatch(
      getTeamList(
        {
          skip: 0,
          limit: rowsPerPage,
          status:data,
        },
        (res:any) => {
          setCount(res?.count);
          setEmployeeList(res?.data);
        },
        () => {}
      )
    );


  }
  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    }



  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
    setSelectedStatus(val);
    if(val === 1 )
    {
      getEmpList("active");
    }
    else if (val === 2)
    {
      getEmpList("deleted");
    }

  };
 
  const handleEdit = (value: any) => {
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    setRole(value?.role?.name);
    setstateArr(value?.states);
    setMob(value?.contactNo);
    setStatus(value?.status)
    setAddEmployee(true);
    setisEditMode(true);
  };
 

  const handleArrClose = (index: number) => {
    const newArrr = [...stateArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setstateArr(newArrr);
  };

  const onchangeFirstName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFirstname(val)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  }

  const onchangelastName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLastname(val)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  }
 

  const onChange = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(stateArr));
    if (key === "state") {
      newArrr?.map((item: any) => {
        if (item?._id === value?._id && !isEditMode) {
          dispatch(showErrorSnackbar("This State is already selected") as any)
        }
        else {
          newArrr[index]._id = value?._id;
          setstateArr(newArrr);
        }
      })
    }
    else if (key === "isActive") {
      if (value.target.checked) {
        newArrr[index].isActive = true;
        setstateArr(newArrr);
      } else {
        newArrr[index].isActive = false;
        setstateArr(newArrr);
      }
    }




  };

  const onchangeUserName = (val: string) => {
    setUsername(val)

  }
  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const onchangeEmail = (val: string) => {
    setEmail(val)
  }
  const onChangePassword = (val: string) => {
    setPassword(val)
  }
  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob(val)
      }
    } else {
      setMob(val)
    }
  }
  const onChangeStatus = (event: any) => {
    if (event.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const onchangeUniqueId = (val: string) => {
    if (validateUniqueId(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase())
    }
    else {
      dispatch(showErrorSnackbar("Please Character only & space is not allowed") as any);
      return;
    }
  }

  // window.onhashchange = function() {
  //   if (IsLogIn== true){
  //     history.back()
  //   }
  //   else{
  //     history.forward()
  //   }
  // }



  const cancelEditEmployee = () => {
    setAddEmployee(false)
    setisEditMode(false)
 
  }
 
  const onChangeBDExecutive=(val:any)=>{
    setExecutive(val)
   
  }

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const postData = {
      status: status,
    
  
      }
      dispatch(
        updateEmployeeStatus(
         empId,
          postData,
          (res: any) => {
            setAddEmployee(false);
            setisEditMode(false);
            getEmpList("active");
           

          },
          () => { }
        )
        
      )

   
  }
  const handleSearch = (e: any) => {
    e.preventDefault();
 
     dispatch(
      getTeamList(
        {
          skip: 0,
          limit: rowsPerPage,
          executiveId: executive?._id,
          status: "active",
    
        },
        (res:any) => {
          setCount(res?.count);
          setEmployeeList(res?.data);
        },
        () => {}
      )
    );
   
  }
  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
         
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="BD Executive" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select BD Executive"
              setInputText={(value: any) => onChangeBDExecutive(value)}
              value={executive}
              options={executiveList}
              Isrequired={true}
              reset={ResetExecutive}
            />
          </div>
        </div>
        
       
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-4">
            <button className="btn btn-primary " type="submit">
              {" "}
              Search
            </button>
          </div>
         

        </div>

      </form>
    )
  }

  const renderListMode = () => {
    return (


      <>
        {employeeList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        <PaginationView
          pagename={"ManageTeam"}
          count={count}
          tableData={employeeList ? employeeList : []}
          tabs={["Active", "Deleted"]}
          onTabChange={(e: any, index: any) => showData(e, index)}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}

        />

        {selectedStatus === 1 ? (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"ManageEmployee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "States Allocated",
              "Status",
              "Edit",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}

          />
        ) : (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"ManageEmployee2"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "States Allocated",
              "Status",


            ]}
            edit={true}

          />
        )}
      </>
    );
  }

  const renderAddMode = () => {
    return (
      <>  <h2 className="text-primary">Edit Employee</h2>  
      
      <form
        className="col-md-10  shadow-lg py-4 px-3"
        onSubmit={handleUpdate}
      >
        <div className="row d-flex justify-content-">
          <div className="col-md-4 my-3">
            <InputField
              placeholder="Enter First Name"
              value={firstName}
              onChangeInput={(value: any) => onchangeFirstName(value)}
              maxlength={length_Fifty - 1}
              minlength={length_Three}
              isRequired={true}
              disabled={isEditMode}
            />
          </div>
          <div className="col-md-4  my-3">
            <InputField
              placeholder="Enter Last Name"
              value={lastName}
              onChangeInput={(value: any) => onchangelastName(value)}
              maxlength={length_Fifty - 1}
              minlength={length_Three}
              isRequired={true}
              disabled={isEditMode}
            />
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-5 mt-2">
              <LabelField lableName="Date Of Joining" />
            </div>
            {/* <DatePicker
                labelName={"Date of Joining"}
                value={date}
                setInputText={(value: string) => setDate(value)}
                Isrequired={true}
                defaultValue={date}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              /> */}
            <InputField
              placeholder="Date of Joining"
              value={date}
              onChangeInput={(value: any) => setDate(value)}
              maxlength={length_Fifty - 1}
              minlength={length_Three}
              isRequired={true}
              disabled={isEditMode}
            />
          </div>
          <div className="col-md-4  my-3">
            {/* <CustomDropdown
                lableName="Select Role"
                setInputText={(value: any) => onChangeRole(value)}
                value={role}
                options={roleList}
                Isrequired={true}
                reset={resetRole}
                defaultValue={role?._id}
                disabled={isEditMode}
              /> */}
            <InputField
              placeholder="Enter Role"
              value={role}
              onChangeInput={(value: any) => setRole(value)}
              maxlength={length_Fifty - 1}
              minlength={length_Three}
              isRequired={true}
              disabled={isEditMode}
            />
          </div>

          {role?._id === 3 ? (
            <div className="col-md-4 my-3 ">
              {/* <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                  Isrequired={true}
                  reset={resetManager}
                  defaultValue={manager?._id}
                  disabled={role?._id === 2 ? true : false}
                /> */}
              <InputField
                placeholder="Enter Manager"
                value={manager}
                onChangeInput={(value: any) => setManager(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
          ) : null}
          {role?._id === 4 ? (
            <div className="col-md-4 my-3  ">
              <div className="">
                {/* <CustomDropdown
                    lableName="Select Team Lead"
                    setInputText={(value: any) => onChangeTL(value)}
                    value={teamLead}
                    options={teamLeadList}
                    Isrequired={true}
                    reset={resetTeamLead}
                    defaultValue={manager?._id}
                  // disabled={role?._id === 2 ? true : false}
                  /> */}
                <InputField
                  placeholder="Enter Team Lead"
                  value={teamLead}
                  onChangeInput={(value: any) => setTeamLead(value)}
                  maxlength={length_Fifty - 1}
                  minlength={length_Three}
                  isRequired={true}
                  disabled={isEditMode}
                />
              </div>
            </div>
          ) : null}
          {/* <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Current Salary"
                value={currentSalary}
                onChangeInput={(value: any) => onchangeCurrentSalary(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
              />
            </div> */}

          {role?._id !== 2 && role?._id !== 3 ? (
            <>
              {stateArr?.map((item: any, index: number) => {
                const { _id, isActive } = item;
                return (
                  <div className="position-relative" key={index}>
                    {/* <span className="d-flex justify-content-end removeButton">
                      {stateArr.length === 1 ? null : (
                        <HighlightOffIcon
                          className="cursor"
                          color="primary"
                          fontSize="small"
                          onClick={() => handleArrClose(index)}
                        />
                      )}
                    </span> */}
                    <div className="d-flex position-relative">
                      <div className="col-md-3 my-1 ">
                        {/* <CustomDropdown
                            lableName="Select State"
                            setInputText={(value: any) =>
                              onChange(index, value, "state")
                            }
                            value={_id}
                            options={stateList}
                            Isrequired={true}
                            defaultValue={stateList?.length > 0 && isEditMode ? _id : ""}
                          // disabled={
                          //   role?._id === 1 || role?._id === 2 || role?._id === 9 || role?._id === 5 ? true : false
                          // }
                          /> */}

                        <InputField
                          placeholder="Enter State"
                          value={item?.name}
                          onChangeInput={(value: any) => setTeamLead(value)}
                          maxlength={length_Fifty - 1}
                          minlength={length_Three}
                          isRequired={true}
                          disabled={isEditMode}
                        />
                      </div>

                      <div className="col-md-3 my-3">
                        <ToggleSwitchbtn
                          lableName="Active"
                          getToggleval={(value: any) => {
                            onChange(index, value, "isActive");
                          }}
                          isActive={isActive}
                          disabled={isEditMode}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

            </>
          ) : null}

          <div className="col-md-4 my-3">
            <InputField
              placeholder="Enter User Name"
              value={userName}
              onChangeInput={(value: any) => onchangeUserName(value)}
              maxlength={length_Hundred - 1}
              minlength={length_Three}
              isRequired={true}
              disabled={isEditMode}
            />
          </div>

          <div className="col-md-4 my-3">
            <InputField
              placeholder="Enter Password"
              value={password}
              onChangeInput={(value: any) => onChangePassword(value)}
              disabled={isEditMode}
              maxlength={length_Hundred - 1}
              isRequired={true}
            />
          </div>
          <div className="col-md-4 my-3">
            <InputField
              placeholder="Enter Email"
              value={email}
              onChangeInput={(value: any) => onchangeEmail(value)}
              isRequired={true}
              disabled={isEditMode}
            />
          </div>
          <div className="col-md-4 my-3">
            <InputField
              placeholder="Enter Mobile Number"
              value={mob}
              onChangeInput={(value: any) => onChangeContact(value)}
              maxlength={length_Ten}
              minlength={length_Ten}
              isRequired={true}
              disabled={isEditMode}
            />
          </div>

          <div className="col-md-4 my-3">
            <ToggleSwitchbtn
              lableName="Status"
              getToggleval={(value: any) => {
                onChangeStatus(value);
              }}
              isActive={status}
            />
          </div>
          <div className="col-md-4 my-3">
            <InputField
              placeholder="Enter Unique Id"
              value={uniqueID}
              onChangeInput={(value: any) => onchangeUniqueId(value)}
              isRequired={true}
              disabled={isEditMode}
              maxlength={length_Hundred - 1}
            />
          </div>
          <div className="col-md-4 my-3"></div>
        </div>
        <div className="d-flex justify-content-end me-3">
          <div className="me-2">

            <Button name={"Cancel"} handleClick={cancelEditEmployee} />

          </div>



          <div>
            <SubmitButton name={"Save & Update"} />
          </div>

        </div>
      </form>
      </>
    );
  }


  return (
    <div className="contentBox">
      <PageTitle
        name={"Manage Employee"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="col-md-12 px-4 px-0 pt-3 formDiv">
      {!isEditMode ?     SearchMode()  : ""}
   
        {!isEditMode ?   renderListMode()  : renderAddMode()}
      </div>

    </div>
  );
}

