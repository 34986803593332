import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import {
  getExamList,
  getExamStreamList,
  getRevenueLeadsList,
  getSubExam,
} from "../../Redux/Actions/counsellorPanelAction";
import CustomDropdown from "../../Component/customDropdown";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LeadAssignedDate() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");

  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata, setGraphdata] = useState<any>([]);

  const [revenueLeadList, setRevenueLeadList] = useState<any>([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [subExam, setSubExam] = useState<any>();
  const [subExamList, setSubExamList] = useState([]);
  const [resetSubExam, setResetSubExam] = useState();

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );

    const postData = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getRevenueLeadsList(
        postData,
        (res: any) => {
          setRevenueLeadList(res);
          showGraph(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeExam = (val: any) => {
    setExam(val);
    if (subExamList) {
      dispatch(
        getSubExam(
          {
            examTypeId: val._id,
          },
          (res: any) => {
            if (res.length > 0) {
              setSubExamList(res);
            } else {
              dispatch(
                getExamStreamList(`${val._id}`, (res: any) => {
                  setExamStreamList(res);
                })
              );
            }
          }
        )
      );
    } else {
      dispatch(
        getExamStreamList(`${val._id}`, (res: any) => {
          setExamStreamList(res);
        })
      );
    }
  };
  const onChangeSubExam = (val: any) => {
    setSubExam(val);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
    };

    dispatch(
      getRevenueLeadsList(
        postData,
        (res: any) => {
          setRevenueLeadList(res);
          showGraph(res);
        },
        () => {}
      )
    );
  };

  const onChangeGraphEndDate = (val: any) => {
    SetEndDate(val);
    const postData = {
      startDate: startDate,
      endDate: val,
    };
    dispatch(
      getRevenueLeadsList(
        postData,
        (res: any) => {
          showGraph(res);
        },
        () => {}
      )
    );
  };

  const showGraph = (list: any) => {
    while (xLabel?.length > 0 && Graphdata?.length > 0) {
      xLabel?.pop();
      Graphdata?.pop();
    }
    if (xLabel?.length === 0 && Graphdata?.length === 0) {
      if (list?.total?.WEBSITE_REGISTRATION_Leads > 0) {
        xLabel?.push("Website");
        Graphdata?.push(list?.total?.WEBSITE_REGISTRATION_Revenue);
      }
      if (list?.total?.WEBINAR_Leads > 0) {
        xLabel?.push("Webinar");
        Graphdata?.push(list?.total?.WEBINAR_Revenue);
      }
      if (list?.total?.FIELD_MARKETING_Leads > 0) {
        xLabel?.push("Field Marketing");
        Graphdata?.push(list?.total?.FIELD_MARKETING_Revenue);
      }
      if (list?.total?.PAID_AD_MANUAL_Leads > 0) {
        xLabel?.push("PAID AD MANUAL");
        Graphdata?.push(list?.total?.PAID_AD_MANUAL_Revenue);
      }
      if (list?.total?.PAID_AD_AUTOMATIC_Leads > 0) {
        xLabel?.push("PAID AD AUTOMATIC");
        Graphdata?.push(list?.total?.PAID_AD_AUTOMATIC_Revenue);
      }
      if (list?.total?.HP_ENQUIRY_Leads > 0) {
        xLabel?.push("HP Enquiry");
        Graphdata?.push(list?.total?.HP_ENQUIRY_Revenue);
      }
      if (list?.total?.ORGANIC_SOCIAL_MEDIA_Leads > 0) {
        xLabel?.push("Organic Social Media");
        Graphdata?.push(list?.total?.ORGANIC_SOCIAL_MEDIA_Revenue);
      }
      if (list?.total?.APP_REGISTRATION_Leads > 0) {
        xLabel?.push("App Registration");
        Graphdata?.push(list?.total?.APP_REGISTRATION_Revenue);
      }
      // if(list?.total?.REFERENCE_Leads >= 0){
      xLabel?.push("Reference");
      Graphdata?.push(list?.total?.REFERENCE_Revenue);
      // }
    }
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSubmit}>
        <div className="col-md-4 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
              // Isrequired={true}
              reset={resetExam}
            />
          </div>
        </div>
        {subExamList?.length > 0 ? (
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Sub Exam Name" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Sub Exam Name"
                setInputText={(value: any) => onChangeSubExam(value)}
                value={subExam}
                options={subExamList}
                reset={resetSubExam}
                // reset={resetSubexamList}
              />
            </div>
          </div>
        ) : null}
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Stream" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam Stream"
              setInputText={(value: any) => onChangeExamStream(value)}
              value={examStream}
              options={examStreamList}
              // Isrequired={true}
              reset={resetExamStream}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <div className="w-100 counsellortable table-responsive">
        <>
          <table className=" w-100 text-center table-bordered border-secondary ">
            {revenueLeadList?.data?.length > 0 ? (
              <>
                <tr>
                  {revenueLeadList?.data?.length > 0 ? (
                    <th rowSpan={3}>Date</th>
                  ) : null}
                  {revenueLeadList?.data?.length > 0 ? (
                    <th colSpan={4}>Total</th>
                  ) : null}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads ? (
                    <th colSpan={4}>Website</th>
                  ) : null}
                  {revenueLeadList?.total?.WEBINAR_Leads ? (
                    <th colSpan={4}>Webinar</th>
                  ) : null}
                  {revenueLeadList?.total?.FIELD_MARKETING_Leads ? (
                    <th colSpan={4}>Field Marketing</th>
                  ) : null}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_Leads ? (
                    <th colSpan={4}>PAID AD MANUAL</th>
                  ) : null}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads ? (
                    <th colSpan={4}>PAID AD AUTOMATIC</th>
                  ) : null}
                  {revenueLeadList?.total?.HP_ENQUIRY_Leads ? (
                    <th colSpan={4}>HP Enquiry</th>
                  ) : null}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads ? (
                    <th colSpan={4}>Organic Social Media</th>
                  ) : null}
                  {revenueLeadList?.total?.APP_REGISTRATION_Leads ? (
                    <th colSpan={4}>App Registration</th>
                  ) : null}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? ( */}
                  <th colSpan={4}>Reference</th>
                  {/* ) : null} */}
                </tr>

                <tr>
                  {/* ********total*********** */}
                  {revenueLeadList?.total?.total_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>Conversion</th>
                    </>
                  ) : null}

                  {/* ********website*********** */}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Webinar*********** */}
                  {revenueLeadList?.total?.WEBINAR_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Field Marketing*********** */}
                  {revenueLeadList?.total?.FIELD_MARKETING_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ******** PAID AD MANUAL*********** */}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ******** PAID AD AUTOMATIC*********** */}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********HP Enquiry*********** */}
                  {revenueLeadList?.total?.HP_ENQUIRY_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Organic Social Media*********** */}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********App Registration*********** */}
                  {revenueLeadList?.total?.APP_REGISTRATION_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}
                  {/* ********Reference*********** */}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? (
                <> */}
                  <th>Lead</th>
                  <th>Admission</th>
                  <th>Revenue</th>
                  <th>conversion</th>
                  {/*</>
               ) : null} */}
                </tr>
                <tr>
                  {/* ********total*********** */}
                  {revenueLeadList?.total?.total_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.total_Leads
                          ? revenueLeadList?.total?.total_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.total_AdmissionCount
                          ? revenueLeadList?.total?.total_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.total_Revenue
                          ? revenueLeadList?.total?.total_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.total_Percentage
                          ? revenueLeadList?.total?.total_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********website*********** */}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads
                          ? revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.WEBSITE_REGISTRATION_AdmissionCount
                          ? revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_Revenue
                          ? revenueLeadList?.total?.WEBSITE_REGISTRATION_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_Percentage
                          ? revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Webinar*********** */}
                  {revenueLeadList?.total?.WEBINAR_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_Leads
                          ? revenueLeadList?.total?.WEBINAR_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_AdmissionCount
                          ? revenueLeadList?.total?.WEBINAR_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_Revenue
                          ? revenueLeadList?.total?.WEBINAR_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_Percentage
                          ? revenueLeadList?.total?.WEBINAR_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Field Marketing*********** */}
                  {revenueLeadList?.total?.FIELD_MARKETING_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_Leads
                          ? revenueLeadList?.total?.FIELD_MARKETING_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_AdmissionCount
                          ? revenueLeadList?.total
                              ?.FIELD_MARKETING_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_Revenue
                          ? revenueLeadList?.total?.FIELD_MARKETING_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_Percentage
                          ? revenueLeadList?.total?.FIELD_MARKETING_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********PAID AD MANAUAL*********** */}
                  {revenueLeadList?.total?.PAID_AD_MANAUAL_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANAUAL_Leads
                          ? revenueLeadList?.total?.PAID_AD_MANAUAL_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANAUAL_AdmissionCount
                          ? revenueLeadList?.total
                              ?.PAID_AD_MANAUAL_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANAUAL_Revenue
                          ? revenueLeadList?.total?.PAID_AD_MANAUAL_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANAUAL_Percentage
                          ? revenueLeadList?.total?.PAID_AD_MANAUAL_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********PAID AD AUTOMATIC*********** */}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads
                          ? revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.PAID_AD_AUTOMATIC_AdmissionCount
                          ? revenueLeadList?.total
                              ?.PAID_AD_AUTOMATIC_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Revenue
                          ? revenueLeadList?.total?.PAID_AD_AUTOMATIC_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Percentage
                          ? revenueLeadList?.total?.PAID_AD_AUTOMATIC_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********HP Enquiry*********** */}
                  {revenueLeadList?.total?.HP_ENQUIRY_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_Leads
                          ? revenueLeadList?.total?.HP_ENQUIRY_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_AdmissionCount
                          ? revenueLeadList?.total?.HP_ENQUIRY_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_Revenue
                          ? revenueLeadList?.total?.HP_ENQUIRY_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_Percentage
                          ? revenueLeadList?.total?.HP_ENQUIRY_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Organic Social Media*********** */}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads
                          ? revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                          ? revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Revenue
                          ? revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Percentage
                          ? revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********App Registration*********** */}
                  {revenueLeadList?.total?.APP_REGISTRATION_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_Leads
                          ? revenueLeadList?.total?.APP_REGISTRATION_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_AdmissionCount
                          ? revenueLeadList?.total
                              ?.APP_REGISTRATION_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_Revenue
                          ? revenueLeadList?.total?.APP_REGISTRATION_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_Percentage
                          ? revenueLeadList?.total?.APP_REGISTRATION_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}
                  {/* ********Reference*********** */}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? ( */}
                  <>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_Leads
                        ? revenueLeadList?.total?.REFERENCE_Leads
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_AdmissionCount
                        ? revenueLeadList?.total?.REFERENCE_AdmissionCount
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_Revenue
                        ? revenueLeadList?.total?.REFERENCE_Revenue
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_Percentage
                        ? revenueLeadList?.total?.REFERENCE_Percentage
                        : 0}
                      %
                    </th>
                  </>
                  {/* ) : null} */}
                </tr>

                {revenueLeadList?.data?.map((data: any) => {
                  return (
                    <tbody>
                      <tr>
                        {/* **************total********** */}
                        <td>{data?._id}</td>
                        <td>{data?.total_Leads ? data?.total_Leads : 0}</td>
                        <td>
                          {data?.total_AdmissionCount
                            ? data?.total_AdmissionCount
                            : 0}
                        </td>
                        <td>{data?.total_Revenue ? data?.total_Revenue : 0}</td>
                        <td>
                          {data?.total_Percentage ? data?.total_Percentage : 0}%
                        </td>
                        {/* **************website********** */}
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads ? (
                          <>
                            <td>
                              {data?.WEBSITE_REGISTRATION_Leads
                                ? data?.WEBSITE_REGISTRATION_Leads
                                : 0}
                            </td>
                            <td>
                              {data?.WEBSITE_REGISTRATION_AdmissionCount
                                ? data?.WEBSITE_REGISTRATION_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.WEBSITE_REGISTRATION_Revenue
                                ? data?.WEBSITE_REGISTRATION_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.WEBSITE_REGISTRATION_Percentage
                                ? data?.WEBSITE_REGISTRATION_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}

                        {/* ********Webinar*********** */}
                        {revenueLeadList?.total?.WEBINAR_Leads ? (
                          <>
                            <td>
                              {data?.WEBINAR_Leads ? data?.WEBINAR_Leads : 0}
                            </td>
                            <td>
                              {data?.WEBINAR_AdmissionCount
                                ? data?.WEBINAR_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.WEBINAR_Revenue
                                ? data?.WEBINAR_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.WEBINAR_Percentage
                                ? data?.WEBINAR_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}

                        {/* ********Field Marketing*********** */}

                        {revenueLeadList?.total?.FIELD_MARKETING_Leads ? (
                          <>
                            <td>
                              {data?.FIELD_MARKETING_Leads
                                ? data?.FIELD_MARKETING_Leads
                                : 0}
                            </td>
                            <td>
                              {data?.FIELD_MARKETING_AdmissionCount
                                ? data?.FIELD_MARKETING_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.FIELD_MARKETING_Revenue
                                ? data?.FIELD_MARKETING_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.FIELD_MARKETING_Percentage
                                ? data?.FIELD_MARKETING_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}

                        {/* ********PAID AD MANUAL*********** */}

                        {revenueLeadList?.total?.PAID_AD_MANUAL_Leads ? (
                          <>
                            <td>
                              {data?.PAID_AD_MANUAL_Leads
                                ? data?.PAID_AD_MANUAL_Leads
                                : 0}
                            </td>
                            <td>
                              {data?.PAID_AD_MANUAL_AdmissionCount
                                ? data?.PAID_AD_MANUAL_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.PAID_AD_MANUAL_Revenue
                                ? data?.PAID_AD_MANUAL_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.PAID_AD_MANUAL_Percentage
                                ? data?.PAID_AD_MANUAL_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}

                        {/* ********PAID AD AUTOMATIC*********** */}

                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads ? (
                          <>
                            <td>
                              {data?.PAID_AD_AUTOMATIC_Leads
                                ? data?.PAID_AD_AUTOMATIC_Leads
                                : 0}
                            </td>
                            <td>
                              {data?.PAID_AD_AUTOMATIC_AdmissionCount
                                ? data?.PAID_AD_AUTOMATIC_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.PAID_AD_AUTOMATIC_Revenue
                                ? data?.PAID_AD_AUTOMATIC_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.PAID_AD_AUTOMATIC_Percentage
                                ? data?.PAID_AD_AUTOMATIC_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}
                        {/* ********HP Enquiry*********** */}

                        {revenueLeadList?.total?.HP_ENQUIRY_Leads ? (
                          <>
                            <td>
                              {data?.HP_ENQUIRY_Leads
                                ? data?.HP_ENQUIRY_Leads
                                : 0}
                            </td>
                            <td>
                              {data?.HP_ENQUIRY_AdmissionCount
                                ? data?.HP_ENQUIRY_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.HP_ENQUIRY_Revenue
                                ? data?.HP_ENQUIRY_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.HP_ENQUIRY_Percentage
                                ? data?.HP_ENQUIRY_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}
                        {/* ********Organic Social Media*********** */}

                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads ? (
                          <>
                            <td>
                              {data?.ORGANIC_SOCIAL_MEDIA_Leads
                                ? data?.ORGANIC_SOCIAL_MEDIA_Leads
                                : 0}
                            </td>
                            <td>
                              {data?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                                ? data?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.ORGANIC_SOCIAL_MEDIA_Revenue
                                ? data?.ORGANIC_SOCIAL_MEDIA_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.ORGANIC_SOCIAL_MEDIA_Percentage
                                ? data?.ORGANIC_SOCIAL_MEDIA_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}

                        {/* ********App Registration*********** */}

                        {revenueLeadList?.total?.APP_REGISTRATION_Leads ? (
                          <>
                            <td>
                              {data?.APP_REGISTRATION_Leads
                                ? data?.APP_REGISTRATION_Leads
                                : 0}
                            </td>
                            <td>
                              {data?.APP_REGISTRATION_AdmissionCount
                                ? data?.APP_REGISTRATION_AdmissionCount
                                : 0}
                            </td>
                            <td>
                              {data?.APP_REGISTRATION_Revenue
                                ? data?.APP_REGISTRATION_Revenue
                                : 0}
                            </td>
                            <td>
                              {data?.APP_REGISTRATION_Percentage
                                ? data?.APP_REGISTRATION_Percentage
                                : 0}
                              %
                            </td>
                          </>
                        ) : null}
                        {/* ********Reference*********** */}
                        {/* {data?.REFERENCE_Leads >= 0 ? (
                      <> */}
                        <td>
                          {data?.REFERENCE_Leads ? data?.REFERENCE_Leads : 0}
                        </td>
                        <td>
                          {data?.REFERENCE_AdmissionCount
                            ? data?.REFERENCE_AdmissionCount
                            : 0}
                        </td>
                        <td>
                          {data?.REFERENCE_Revenue
                            ? data?.REFERENCE_Revenue
                            : 0}
                        </td>
                        <td>
                          {data?.REFERENCE_Percentage
                            ? data?.REFERENCE_Percentage
                            : 0}
                          %
                        </td>
                        {/* </>
                    ) : null} */}
                      </tr>
                    </tbody>
                  );
                })}
              </>
            ) : (
              <tr> No Record Found</tr>
            )}
          </table>
        </>
      </div>
    );
  };

  const renderGraphMode = () => {
    return (
      <>
        {/* <div className="d-flex mt-5 mx-5">
          <div className="col-md-4 d-flex mb-3 me-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date" />
            </div>
            <div className="col-md-9 ">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeGraphEndDate(value)}
              />
            </div>
          </div>
        </div> */}
        {Graphdata?.length > 0 ? (
          <div className="col-md-6 p-3 ms-5">
            <BarGraph label={xLabel} value={Graphdata} />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Allotted Date"} />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">
          {renderSearchMode()}
          {renderListMode()}
          {renderGraphMode()}
        </div>
      </div>
    </div>
  );
}
