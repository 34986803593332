import { combineReducers } from "redux";
import { RESET_STORE } from "./Types/authTypes";
import {
  AuthReducerModal,
  LeadManagementModal,
  SnackbarReducerModal,
} from "./Modals";
import AuthReducer from "./Reducers/authReducer";
import LeadManagementReducer from "./Reducers/leadManagement";
import uiReducer from "./Reducers/uiReducer";

export interface AppState {
  Auth: AuthReducerModal;
  LeadManagement: LeadManagementModal;
  UI: SnackbarReducerModal;
}

const appReducer = combineReducers<AppState>({
  Auth: AuthReducer,
  LeadManagement: LeadManagementReducer,
  UI: uiReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
