import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "react-circular-progressbar/dist/styles.css";
import LabelField from "./labelField";
import InputField from "./inputField";
import SubmitButton from "./submitButton";
import DateTime from "./dateTime";
import {
  addWebinar,
  getTeachersList,
} from "../Redux/Actions/bdExecutivePanelAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  containsNumber,
  length_Fifteen,
  length_Four,
  length_Hundred,
  length_OneHundredFifty,
  length_Ten,
  length_Three,
  length_Two,
  validateFullName,
  validateValueWithoutSpace,
} from "../DATA/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../DATA/errorMsg";
import CustomDropdown from "./customDropdown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Radiobtn from "./radiobtn";
interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  college?: any;
}

export default function WebinarSchedule(props: props) {
  const { open, handleClose, data, college } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const IsWebinar = location.state?.IsWebinar;
  const [meetingURL, setMeetingURL] = useState<any>("");
  const [coordinatorname, setCoordinatorName] = useState("");
  const [webinarTopic, setWebinarTopic] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [registrationURL, setRegistrationURL] = useState<any>("");
  const [teachername, setTeacherName] = useState<any>("");
  const [teachernameList, setTeachernameList] = useState([]);
  const [ResetTeachername, setResetTeachername] = useState(false);
  const [copied, setcopied] = useState(false);
  const [mindate, setmindate] = useState("");
  const [type, setType] = useState(1);

  const [priCoordinatorname, setPriCoordinatorName] = useState("");
  const [priEmail, setPriemail] = useState<any>("");
  const [priPhone, setPriPhone] = useState<any>("");

  const [secCoordinatorname, setSecCoordinatorName] = useState("");
  const [secEmail, setSecemail] = useState<any>("");
  const [secPhone, setSecPhone] = useState<any>("");

  useEffect(() => {
    setmindate(moment.utc(new Date()).format("YYYY-MM-DD hh:mm:ss"));
    setPriCoordinatorName(
      `${data?.primaryContact?.firstName} ${data?.primaryContact?.lastName}`
    );
    setPriemail(data?.primaryContact?.email);
    setPriPhone(data?.primaryContact?.contact1);
  }, [data]);

  const onChangeType = (value: any) => {
    setType(value);
    setPriCoordinatorName(
      `${data?.primaryContact?.firstName} ${data?.primaryContact?.lastName}`
    );
    setPriemail(data?.primaryContact?.email);
    setPriPhone(data?.primaryContact?.contact1);
    setSecCoordinatorName(
      data?.secondaryContact?.firstName
        ? `${data?.secondaryContact?.firstName} ${data?.secondaryContact?.lastName}`
        : ""
    );
    setSecemail(
      data?.secondaryContact?.email ? data?.secondaryContact?.email : ""
    );
    setSecPhone(
      data?.secondaryContact?.contact1 ? data?.secondaryContact?.contact1 : ""
    );
  };

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  useEffect(() => {}, []);

  const onchangeDate = (val: any) => {
    setDate(val);
    dispatch(
      getTeachersList(
        {},
        (res: any) => {
          setTeachernameList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeMeetingURL = (val: any) => {
    setMeetingURL(val);
  };

  const onChangeTeacherName = (val: any) => {
    setTeacherName(val);
  };
  const onChangeCoordinatorName = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else {
        setCoordinatorName(val);
      }
    } else {
      setCoordinatorName(val);
    }
  };
  const onChangePriCoordinatorName = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else {
        setPriCoordinatorName(val);
      }
    } else {
      setPriCoordinatorName(val);
    }
  };
  const onChangeSecCoordinatorName = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else {
        setSecCoordinatorName(val);
      }
    } else {
      setSecCoordinatorName(val);
    }
  };
  const onChangeWebinarTopic = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else {
        setWebinarTopic(val);
      }
    } else {
      setWebinarTopic(val);
    }
  };
  const onChangePhone = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPhone(val);
      }
    } else {
      setPhone(val);
    }
  };
  const onChangePriPhone = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPriPhone(val);
      }
    } else {
      setPriPhone(val);
    }
  };
  const onChangeSecPhone = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setSecPhone(val);
      }
    } else {
      setSecPhone(val);
    }
  };

  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onChangeEmail = (val: any) => {
    setEmail(val);
  };
  const onChangePriEmail = (val: any) => {
    setPriemail(val);
  };
  const onChangeSecEmail = (val: any) => {
    setSecemail(val);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (type === 3) {
      const postdata = {
        coordinatorName: coordinatorname,
        coordinatorPhone: phone,
        coordinatorEmail: email,
        basecourseId: data?.baseCourseId,
        examTypeId: data?.examTypeId,
        collegeId: data?.collegeId,
        departmentId: data?._id,
        meetingURL: meetingURL,
        webinarAt: date,
        teacherId: teachername?._id,
        topic: webinarTopic,
        stateId: IsWebinar
          ? college?.college_data?.state?._id
          : college?.state?._id,
      };
      dispatch(
        addWebinar(
          postdata,
          (res: any) => {
            setRegistrationURL(res?.registerURL);
            setCoordinatorName("");
            setWebinarTopic("");
            setMeetingURL("");
            setDate("");
            setEmail("");
            setPhone("");
            setResetTeachername(true);
          },
          () => {}
        )
      );
    } else if (type === 1) {
      const postdata = {
        coordinatorName: priCoordinatorname,
        coordinatorPhone: priPhone,
        coordinatorEmail: priEmail,
        basecourseId: data?.baseCourseId,
        examTypeId: data?.examTypeId,
        collegeId: data?.collegeId,
        departmentId: data?._id,
        meetingURL: meetingURL,
        webinarAt: date,
        teacherId: teachername?._id,
        topic: webinarTopic,
        stateId: IsWebinar
          ? college?.college_data?.state?._id
          : college?.state?._id,
      };
      dispatch(
        addWebinar(
          postdata,
          (res: any) => {
            setRegistrationURL(res?.registerURL);
            setCoordinatorName("");
            setWebinarTopic("");
            setMeetingURL("");
            setDate("");
            setEmail("");
            setPhone("");
            setResetTeachername(true);
          },
          () => {}
        )
      );
    } else {
      const postdata = {
        coordinatorName: secCoordinatorname,
        coordinatorPhone: secPhone,
        coordinatorEmail: secEmail,
        basecourseId: data?.baseCourseId,
        examTypeId: data?.examTypeId,
        collegeId: data?.collegeId,
        departmentId: data?._id,
        meetingURL: meetingURL,
        webinarAt: date,
        teacherId: teachername?._id,
        topic: webinarTopic,
        stateId: IsWebinar
          ? college?.college_data?.state?._id
          : college?.state?._id,
      };
      dispatch(
        addWebinar(
          postdata,
          (res: any) => {
            setRegistrationURL(res?.registerURL);
            setCoordinatorName("");
            setWebinarTopic("");
            setMeetingURL("");
            setDate("");
            setEmail("");
            setPhone("");
            setResetTeachername(true);
          },
          () => {}
        )
      );
    }
  };

  const validation = () => {
    return date === "" ||
      meetingURL === "" ||
      teachername === "" ||
      teachername?.length < length_Two ||
      type === 3
      ? coordinatorname === ""
      : false || type === 3
      ? coordinatorname?.length < length_Two
      : false || type === 3
      ? phone === ""
      : false || type === 3
      ? phone?.length < length_Ten
      : false || type === 3
      ? email === ""
      : false || type === 3
      ? email?.length < length_Three
      : false || type === 3
      ? !email.match(emailsCheck)
      : false || type === 2
      ? secCoordinatorname === ""
      : false || type === 2
      ? secCoordinatorname?.length < length_Two
      : false || type === 2
      ? secPhone === ""
      : false || type === 2
      ? secPhone?.length < length_Ten
      : false || type === 2
      ? secEmail === ""
      : false || type === 2
      ? secEmail?.length < length_Three
      : false || type === 2
      ? !secEmail.match(emailsCheck)
      : false;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldivChat text-center p-md-4 py-md-4 ">
          <div className=" d-flex justify-content-between">
            <div>
              <h3 className="text-primary fw-bold">
                Webinar Alignment Details
              </h3>{" "}
            </div>
            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 my-2 ">
                  <LabelField lableName="College Name" />
                </div>
                <div className="col-md-8 ps-3 pt-2 text-start">
                  <span className="fw-bold font14">
                    {IsWebinar
                      ? college?.college_data?.collegeName
                      : college?.collegeName}
                  </span>
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 my-2 ">
                  <LabelField lableName="College State" />
                </div>
                <div className="col-md-8 ps-3 pt-2 text-start">
                  <span className="fw-bold font14">
                    {IsWebinar
                      ? college?.college_data?.state?.name
                      : college?.state?.name}
                  </span>
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 my-2 ">
                  <LabelField lableName="College District" />
                </div>
                <div className="col-md-8 ps-3 pt-2 text-start">
                  <span className="fw-bold font14">
                    {IsWebinar
                      ? college?.college_data?.city?.name
                      : college?.district?.name}
                  </span>
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Webinar Topic" />
                </div>
                <div className="col-md-8">
                  <InputField
                    placeholder="Enter Webinar Topic "
                    value={webinarTopic}
                    onChangeInput={(value: any) => onChangeWebinarTopic(value)}
                    minlength={length_Two}
                    maxlength={length_Hundred - 1}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Webinar Date" />
                </div>
                <div className="col-md-8 ps-3">
                  <DateTime
                    value={date}
                    setInputText={(value: any) => onchangeDate(value)}
                    mindate={mindate}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Meeting Url" />
                </div>
                <div className="col-md-8 ps-">
                  <InputField
                    placeholder="Enter Meeting Url"
                    value={meetingURL}
                    onChangeInput={(value: any) => onChangeMeetingURL(value)}
                  />
                </div>
              </div>
           
              <div className=" d-flex  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Teacher Name" />
                </div>
                <div className="col-md-8 ps-3 pt-1 ">
                  <CustomDropdown
                    lableName="Select Teacher Name"
                    setInputText={(value: any) => onChangeTeacherName(value)}
                    value={teachername}
                    options={teachernameList}
                    reset={ResetTeachername}
                  />
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="col-md-4 mt-2">
                  <Radiobtn labelName={"Select Type"} />
                </div>
                <span className="me-3 mt-2 ms-3">
                  <input
                    type="radio"
                    value={1}
                    checked={type === 1}
                    onChange={() => onChangeType(1)}
                  />{" "}
                  Primary Contact
                </span>
                <span className="me-3 mt-2">
                  <input
                    type="radio"
                    value={2}
                    checked={type === 2}
                    onChange={() => onChangeType(2)}
                  />{" "}
                  Secondary Contact
                </span>
                <span className="me-3 mt-2">
                  <input
                    type="radio"
                    value={3}
                    checked={type === 3}
                    onChange={() => onChangeType(3)}
                  />{" "}
                  Other
                </span>
              </div>
              {type === 3 ? (
                <div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Name" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Coordinator Name "
                        value={coordinatorname}
                        onChangeInput={(value: any) =>
                          onChangeCoordinatorName(value)
                        }
                        minlength={length_Two}
                        maxlength={length_Hundred - 1}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Phone" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Phone no"
                        value={phone}
                        onChangeInput={(value: any) => onChangePhone(value)}
                        minlength={length_Ten}
                        maxlength={length_Fifteen}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Email" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Coordinator Email"
                        value={email}
                        onChangeInput={(value: any) => onChangeEmail(value)}
                        minlength={length_Four}
                        maxlength={length_OneHundredFifty - 1}
                      />
                    </div>
                  </div>
                </div>
              ) : type === 1 ? (
                <div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Name" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Coordinator Name "
                        value={priCoordinatorname}
                        onChangeInput={(value: any) =>
                          onChangePriCoordinatorName(value)
                        }
                        minlength={length_Two}
                        maxlength={length_Hundred - 1}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Phone" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Phone no"
                        value={priPhone}
                        onChangeInput={(value: any) => onChangePriPhone(value)}
                        minlength={length_Ten}
                        maxlength={length_Fifteen}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Email" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Coordinator Email"
                        value={priEmail}
                        onChangeInput={(value: any) => onChangePriEmail(value)}
                        minlength={length_Four}
                        maxlength={length_OneHundredFifty - 1}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Name" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Coordinator Name "
                        value={secCoordinatorname}
                        onChangeInput={(value: any) =>
                          onChangeSecCoordinatorName(value)
                        }
                        minlength={length_Two}
                        maxlength={length_Hundred - 1}
                        disabled={
                          data?.secondaryContact?.firstName ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Phone" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Phone no"
                        value={secPhone}
                        onChangeInput={(value: any) => onChangeSecPhone(value)}
                        minlength={length_Ten}
                        maxlength={length_Fifteen}
                        disabled={data?.secondaryContact?.email ? true : false}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Coordinator Email" />
                    </div>
                    <div className="col-md-8">
                      <InputField
                        placeholder="Enter Coordinator Email"
                        value={secEmail}
                        onChangeInput={(value: any) => onChangeSecEmail(value)}
                        minlength={length_Four}
                        maxlength={length_OneHundredFifty - 1}
                        disabled={
                          data?.secondaryContact?.contact1 ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className=" d-flex justify-content-center mb-3">
                <SubmitButton name={"Submit"} validation={validation()} />
              </div>
            </div>
          </form>
          <div className="row d-flex justify-content-between  ">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Registration Url" />
            </div>
            <div className="col-md-8 d-flex">
             
              <input
                id="registrationURL"
                type="text"
                name="username"
                value={registrationURL}
                className="form-control"
                onChange={(value: any) => {
                  setRegistrationURL(value);
                }}
                disabled
              />

              <CopyToClipboard
                text={registrationURL}
                onCopy={() => setcopied(true)}
              >
                <button className="btn btn-font  ">
                  {" "}
                  <ContentCopyOutlinedIcon />
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
