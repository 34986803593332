import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import { useSelector } from "react-redux";
import moment from "moment";
import usePagination from "../../Component/usePagination";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import {
  length_Fifty,
  length_Ten,
  length_Three,
  length_Twenty,
  startsWithSpace,
  validateCouponCodeName,
} from "../../DATA/validators";
import { getTableDataSlots } from "../../DATA/dataConstant";
import { getCouponCodeList } from "../../Redux/Actions/managerPanelAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
export default function CouponCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeList, setCouponCodeList] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        name: couponCode,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        name: couponCode,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        name: couponCode,
      };
    }
    dispatch(
      getCouponCodeList(
        data,
        (res: any) => {
          setCount(res?.count);
          setCouponCodeList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      name: couponCode,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getCouponCodeList(
        postData,
        (res: any) => {
          setCount(res?.count);
          setCouponCodeList(res?.data);
        },
        () => {}
      )
    );
  };

  const onchangeCouponCode = (val: string) => {
    let Coupon = val?.toUpperCase();
    if (validateCouponCodeName(Coupon) && !startsWithSpace(Coupon)) {
      setCouponCode(Coupon);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          pagename={"CouponCode"}
          count={count}
          tableData={couponCodeList ? couponCodeList : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <PaginationTable
          tableData={couponCodeList ? couponCodeList : []}
          pagename={"CouponCode"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Coupon Code Name",
            "Exam",
            "Sub Exam",
            "Exam Stream",
            "Main Category",
            "Sub Category",
            "Status",
          ]}
        />
      </div>
    );
  };

  const searchValidation = () => {
    return couponCode === "" || couponCode.length < length_Three;
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Coupon Code"} />
      <div className="row m-1">
        <div className="formDiv">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <form className="d-flex " onSubmit={handleSubmit}>
                <div className="col-md-4 d-flex mb-2 align-items-center me-3">
                  <LabelField lableName={"Coupon Code Name"} />
                  <div className="removelabel">
                    <InputField
                      placeholder={"Enter Coupon Code"}
                      onChangeInput={(value: any) => onchangeCouponCode(value)}
                      value={couponCode}
                      maxlength={length_Twenty}
                      minlength={length_Three}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="d-flex mb-2 col-4 me-4">
                  <div>
                    <SubmitButton
                      name={"Search"}
                      validation={searchValidation()}
                    />
                  </div>
                </div>
              </form>
            </div>
            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
