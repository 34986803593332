import React from "react";
import "../App.css";
const Radiobtn = (props:any) => {
  return (
    <div className="d-flex mb-2">
      <label className="label">{props.labelName}</label>
      {props?.options?.map((item:any) => {
        return (
          <span className="radiobtnspan mx-2">
            <input
              key={item._id}
              type="radio"
              name="level"
              className="mx-2"
              checked={item._id === props?.value}
              onChange={() => props?.onChange(item._id)}
            />
            {item.name}
          </span>
        );
      })}
    </div>
  );
};
export default Radiobtn;
