import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import { useSelector } from "react-redux";
import {
  getAttendanceReq,
  getAttendanceUpdatedList,
  getEmployeetByManagerId,
  getTLAttendanceReq,
  markAttendance,
} from "../../Redux/Actions/managerPanelAction";

export default function Attendance() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState(false);
  const [counsellor, setCounsellor] = useState<any>("");
  const [counsellorList, setCounsellorList] = useState([]);
  const [resetCounsellor, setResetCounsellor] = useState(false);
  const [attendanceReqList, setAttendanceReqList] = useState([]);
  const [markAttendanceData, setMarkAttendanceData] = useState<any>("");
  const [isActive, setIsActive] = useState(false);

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
      };
    }
    if (loggedInUserRole === 1) {
      data["managerId"] = manager?._id;
    } else if (loggedInUserRole === 2) {
      data["managerId"] = managerId;
    }

    dispatch(
      getAttendanceReq(
        data,
        (res: any) => {
          setAttendanceReqList(
            checkAttendanceList(res?.data?.attendanceWithLoginDetails)
          );
          setCount(res?.data?.count);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const checkAttendanceList = (data: any) => {
    let tempData = data?.map((item: any) => {
      if (item?.status === "0" || item?.status === "1") {
        item["isSelected"] = true;
      } else {
        item["isSelected"] = false;
      }
      return item;
    });
    return tempData;
  };

  useEffect(() => {
    if (loggedInUserRole === 1) {
      dispatch(
        getEmployeetByManagerId(
          managerId,
          (res: any) => {
            setManagerList(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getEmployeetByManagerId(
          managerId,
          (res: any) => {
            setCounsellorList(res?.data);
          },
          () => {}
        )
      );
      dispatch(
        getAttendanceReq(
          {
            skip: 0,
            limit: rowsPerPage,
            managerId,
          },
          (res: any) => {
            setAttendanceReqList(
              checkAttendanceList(res?.data?.attendanceWithLoginDetails)
            );
            setCount(res?.data?.count);
          },
          () => {}
        )
      );
    }
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val?._id);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
    const data = {
      managerId: val?._id,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getTLAttendanceReq(
        data,
        (res: any) => {
          setAttendanceReqList(res?.data?.attendanceWithLoginDetails);
          setCount(res?.data?.count);
        },
        () => {}
      )
    );
  };

  const handleApprove = (val: any, status: any) => {
    const data = {
      status: status,
      _id: val?._id,
    };
    dispatch(
      markAttendance(
        data,
        (res: any) => {
          setMarkAttendanceData(res?.data);
          dispatch(
            getAttendanceReq(
              {
                skip: 0,
                limit: rowsPerPage,
                managerId,
              },
              (res: any) => {
                setAttendanceReqList(
                  checkAttendanceList(res?.data?.attendanceWithLoginDetails)
                );
                setCount(res?.data?.count);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const handleCancel = (val: any, status: any) => {
    const data = {
      status: status,
      _id: val?._id,
    };
    dispatch(
      markAttendance(
        data,
        (res: any) => {
          setMarkAttendanceData(res?.data);
          dispatch(
            getAttendanceReq(
              {
                skip: 0,
                limit: rowsPerPage,
                managerId,
              },
              (res: any) => {
                setAttendanceReqList(
                  checkAttendanceList(res?.data?.attendanceWithLoginDetails)
                );
                setCount(res?.data?.count);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const onChangeCounsellor = (val: any) => {
    setCounsellor(val?._id);
    const data: any = {
      counsellorId: val?._id,
      skip: 0,
      limit: rowsPerPage,
    };
    if (loggedInUserRole === 1) {
      data["managerId"] = manager?._id;
    } else if (loggedInUserRole === 2) {
      data["managerId"] = managerId;
    }
    dispatch(
      getAttendanceReq(
        data,
        (res: any) => {
          setAttendanceReqList(
            checkAttendanceList(res?.data?.attendanceWithLoginDetails)
          );
          setCount(res?.data?.count);
        },
        () => {}
      )
    );
    // dispatch(
    //   getTLAttendanceReq(
    //     data,
    //     (res: any) => {
    //       setAttendanceReqList(res?.data?.attendanceWithLoginDetails);
    //       setCount(res?.data?.count)

    //     },
    //     () => { }
    //   )
    // );
  };
  const handleSearch = () => {};
  const renderSearchMode = () => {
    return (
      <>
        <form className="row d-flex  " onSubmit={handleSearch}>
          {loggedInUserRole === 1 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Select Team Lead" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Team Lead"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                  // Isrequired={true}
                  reset={resetManager}
                />
              </div>
            </div>
          ) : null}
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Select Counsellor" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Counsellor"
                setInputText={(value: any) => onChangeCounsellor(value)}
                value={counsellor}
                options={counsellorList}
                // Isrequired={true}
                reset={resetCounsellor}
              />
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {attendanceReqList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"attendance"}
            count={count}
            tableData={attendanceReqList ? attendanceReqList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        {loggedInUserRole === 1 ? (
          <PaginationTable
            tableData={attendanceReqList ? attendanceReqList : []}
            pagename={"attendance"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={["S.N", "Status", "Name", "Login Time", "Logout Time"]}
            // handleClick={onClickLead}
            handleToggle={(data: any) => {
              if (markAttendanceData?.status === 1) {
                setIsActive(true);
              } else {
                setIsActive(false);
              }
            }}
            handleApprove={(value: any) => handleApprove(value, 1)}
            handleCancel={(value: any) => handleCancel(value, 0)}
          />
        ) : (
          <PaginationTable
            tableData={attendanceReqList ? attendanceReqList : []}
            pagename={"attendance"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Status",
              "Name",
              "Login Time",
              "Logout Time",
              "Attendance",
            ]}
            // handleClick={onClickLead}
            handleToggle={(data: any) => {
              if (markAttendanceData?.status === 1) {
                setIsActive(true);
              } else {
                setIsActive(false);
              }
            }}
            handleApprove={(value: any) => handleApprove(value, 1)}
            handleCancel={(value: any) => handleCancel(value, 0)}
          />
        )}
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Attendance"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth pt-2 p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
