
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import SubmitButton from '../../Component/submitButton';
import InputField from '../../Component/inputField'
import LabelField from '../../Component/labelField';
import { containsNumber, containsNumberwithDash, length_Ten, validateValueWithoutSpace } from '../../DATA/validators';
import { getGlobalSettingLeadScore, updateGlobalSettingLeadScore } from '../../Redux/Actions/leadManagementAction';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';


export default function CounsellorCount() {
  const dispatch = useDispatch();

  const [leadScoreId, setLeadScoreId] = useState("");
  const [otpVerified, setOtpVerified] = useState("");
  const [emailEntered, setEmailEntered] = useState("");
  const [emailOpened, setEmailOpened] = useState("");
  const [emailSpamed, setEmailSpamed] = useState("");
  const [leadInvalid, setLeadInvalid] = useState("");
  const [notInterested, setNotInterested] = useState("");
  const [callPicked, setCallPicked] = useState("");
  const [callLater, setCallLater] = useState("");
  const [prospectInterested, setProspectInterested] = useState("");
  const [whatsappBlock, setWhatsappBlock] = useState("");
  const [whatsappMsgRead, setWhatsappMsgRead] = useState("");

  useEffect(() => {
    getScore();

  }, []);

  const getScore = () => {
    dispatch(
      getGlobalSettingLeadScore(
        "",
        (res: any) => {
          setLeadScoreId(res?._id);
          setOtpVerified(res?.OTP_verified);
          setEmailEntered(res?.Email_entered);
          setEmailOpened(res?.Email_opened);
          setEmailSpamed(res?.Email_spam);
          setLeadInvalid(res?.Lead_invalid);
          setNotInterested(res?.Not_interested);
          setCallPicked(res?.Call_picked);
          setCallLater(res?.Call_later);
          setProspectInterested(res?.Prospect_interested);
          setWhatsappBlock(res?.Whatsapp_block);
          setWhatsappMsgRead(res?.Whatsapp_msg_read);
        },
        () => { }
      )
    );
  }

  const onchangeOtp = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setOtpVerified(value);
      }
    } else {
      setOtpVerified(value);
    }
  }

  const onchangeEmailEntered = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setEmailEntered(value);
      }
    } else {
      setEmailEntered(value);
    }
  }

  const onchangeEmailOpened = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setEmailOpened(value);
      }
    } else {
      setEmailOpened(value);
    }
  }

  const onchangeEmailSpamed = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setEmailSpamed(value);
      }
    } else {
      setEmailSpamed(value);
    }
  }

  const onchangeLeadInvalid = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setLeadInvalid(value);
      }
    } else {
      setLeadInvalid(value);
    }
  }

  const onchangeNotInterested = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setNotInterested(value);
      }
    } else {
      setNotInterested(value);
    }
  }

  const onchangeCallPicked = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setCallPicked(value);
      }
    } else {
      setCallPicked(value);
    }
  }

  const onchangeProspectInterested = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setProspectInterested(value);
      }
    } else {
      setProspectInterested(value);
    }
  }

  const onchangeWhatsappBlock = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setWhatsappBlock(value);
      }
    } else {
      setWhatsappBlock(value);
    }
  }

  const onchangeWhatsappMsgRead = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setWhatsappMsgRead(value);
      }
    } else {
      setWhatsappMsgRead(value);
    }
  }

  const onchangeCallLater = (value: any) => {
    if (value !== "") {
      if (!containsNumberwithDash(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setCallLater(value);
      }
    } else {
      setCallLater(value);
    }
  }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      Call_later: callLater,
      Call_picked: callPicked,
      Email_entered: emailEntered,
      Email_opened: emailOpened,
      Email_spam: emailSpamed,
      Lead_invalid: leadInvalid,
      Not_interested: notInterested,
      OTP_verified: otpVerified,
      Prospect_interested: prospectInterested,
      Whatsapp_block: whatsappBlock,
      Whatsapp_msg_read: whatsappMsgRead
    }
    dispatch(
      updateGlobalSettingLeadScore(
        leadScoreId,
        postData,
        (res: any) => {
          dispatch(
            getGlobalSettingLeadScore(
              "",
              (res: any) => {
                getScore();

              },
              () => { }
            )
          );
        },
        () => { }
      )
    );

  };


  const renderSearchMode = () => {
    return (
      <form className="col-6 px-2 ms-3" onSubmit={handleSubmit}>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Otp Verified:"} />
          </div>
          <InputField
            placeholder=""
            value={otpVerified}
            onChangeInput={(value: any) => onchangeOtp(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Email Entered:"} />
          </div>
          <InputField
            placeholder=""
            value={emailEntered}
            onChangeInput={(value: any) => onchangeEmailEntered(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Email Opened:"} />
          </div>
          <InputField
            placeholder=""
            value={emailOpened}
            onChangeInput={(value: any) => onchangeEmailOpened(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Email Spam:"} />
          </div>
          <InputField
            placeholder=""
            value={emailSpamed}
            onChangeInput={(value: any) => onchangeEmailSpamed(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Lead Invalid:"} />
          </div>
          <InputField
            placeholder=""
            value={leadInvalid}
            onChangeInput={(value: any) => onchangeLeadInvalid(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Not Interested:"} />
          </div>
          <InputField
            placeholder=""
            value={notInterested}
            onChangeInput={(value: any) => onchangeNotInterested(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Call Picked:"} />
          </div>
          <InputField
            placeholder=""
            value={callPicked}
            onChangeInput={(value: any) => onchangeCallPicked(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Call Later:"} />
          </div>
          <InputField
            placeholder=""
            value={callLater}
            onChangeInput={(value: any) => onchangeCallLater(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Prospect Interested:"} />
          </div>
          <InputField
            placeholder=""
            value={prospectInterested}
            onChangeInput={(value: any) => onchangeProspectInterested(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Whatsapp Block:"} />
          </div>
          <InputField
            placeholder=""
            value={whatsappBlock}
            onChangeInput={(value: any) => onchangeWhatsappBlock(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>

        <div className="d-flex Justify-content-between mb-2">
          <div className="col-4 align-item-center d-flex me-3">
            <LabelField lableName={"Whatsapp Message Read:"} />
          </div>
          <InputField
            placeholder=""
            value={whatsappMsgRead}
            onChangeInput={(value: any) => onchangeWhatsappMsgRead(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>
        <div className="col-6 d-flex justify-content-center">
          <div>
            <SubmitButton name={"Update"} />
          </div>
        </div>
      </form>
    );
  };


  return (
    <div className="contentBox">
      <PageTitle name={"Lead Score"} />
      <div className="row m-1">
        <div className="formDiv">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div>
              </div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}

