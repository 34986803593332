import services from "../../API/service";
import endpoint from "../../API/endpoint";
import { showSuccessSnackbar } from "./snackbarAction";
import { showErrorSnackbar } from "./snackbarAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SET, UPDATE } from "../Types/leadManagementTypes";
import { updateCommonValues } from "./CommonAction";

export const setParentCourse =
  (key: string, value: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: SET,
      payload: {
        [key]: value,
      },
    });
  };

export const modifyParentCourse =
  (key: string, value: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: UPDATE,
      payload: {
        [key]: value,
      },
    });
  };

//   **************************************

export const getDailyMeetingForManager = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManager.getDailyMeetingForManager,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      }, (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error)
      }
    )
  };


  export const getSMManagerList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.SuperManager.getSMManagerList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

  export const getRevenue =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerDashbord.getRevenue,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };


  export const getTalkTimeData = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerDashbord.getTalkTimeData,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      }, (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error)
      }
    )
  };

  export const getSMTeamLeadOverview =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMTeamLeadOverview + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

  export const getSMCouresAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMCouresAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };


  export const getSMReferenceAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMReferenceAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

  export const getSMReferenceAnalysisByID =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMReferenceAnalysisByID,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

  export const getSMLeadAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMLeadAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

  export const getSMEnrolledAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.SuperManagerTeamAnalysis.getSMEnrolledAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
