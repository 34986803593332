import { useEffect, useState } from "react";
import CustomDropdown from "../../Component/customDropdown";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import PageTitle from "../../Component/pageTitle";
import { getTableDataSlots } from "../../DATA/dataConstant";
import usePagination from "../../Component/usePagination";
import { Pagination } from "@mui/material";
import PaginationView from "../../Component/paginationView";
import PaginationTable from "../../Component/paginationTable";
import {
  getSourceTypeList,
  leadTypeList,
  uploadImportLeadSheeet,
} from "../../Redux/Actions/leadManagementAction";
import { useAppDispatch } from "../../hooks";
import axios from "axios";
import { useSelector } from "react-redux";

export default function CreateImportLeads() {
  const dispatch = useAppDispatch();
  const [lead, setLead] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState("");
  const [leadList, setLeadList] = useState<any>([]);
  const [sourceTypeList, setSourceTypeList] = useState<any>([]);
  const [uploadfile, setUploadfile] = useState<any>("");
  const [openPopup, setOpenPopup] = useState(false);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const { authToken } = useSelector((state: any) => ({
    authToken: state.Auth.authToken,
  }));

  useEffect(() => {
    dispatch(
      getSourceTypeList((res: any) => {
        let tempSourceList = res?.filter((item: any) => item?.isImported);
        setSourceTypeList(tempSourceList);
      })
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        type: lead?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        type: lead?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        type: lead?._id,
      };
    }

    dispatch(
      leadTypeList(
        data,
        (res: any) => {
          setCount(res?.count);
          setLeadList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeLeadRole = (value: any) => {
    setLead(value);
    if (value?._id !== 0) {
      const postData = {
        skip: 0,
        limit: rowsPerPage,
        type: value?._id,
      };

      dispatch(
        leadTypeList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const validations = () => {
    return selectedFile === "" || lead === "";
  };

  const handleEXCELChange = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onload = (e) => {
        setSelectedFile(file?.name);
      };

      reader.readAsBinaryString(file);
      dispatch(
        uploadImportLeadSheeet(
          file,
          (res: any) => {
            setUploadfile(res);
          },
          () => {}
        )
      );
    }
  };

  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  const onClickImportLead = async (e: any) => {
    e.preventDefault();
    setOpenPopup(true);
    const postdata = {
      filePath: uploadfile?.filePath,
      file: uploadfile?.file,
      originalName: uploadfile?.originalName,
    };

    const req = await axios({
      method: "post",
      url: process.env.REACT_APP_BASEURL + `/v1/admin/leads/import/` + lead._id,
      responseType: "blob",
      data: postdata,
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `report_${new Date().getTime()}.xlsx`;
    link.click();

    setSelectedFile("");
    setUploadfile("");
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      type: lead?._id,
    };

    dispatch(
      leadTypeList(
        postData,
        (res: any) => {
          setCount(res?.count);
          setLeadList(res?.data);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          {leadList?.length > 0 ? (
            <div className="d-flex paginationdiv justify-content-end align-items-center">
              <Pagination
                count={totalPages}
                size="large"
                page={currentPage}
                variant="outlined"
                shape="rounded"
                color={"primary"}
                onChange={handleChange}
              />
            </div>
          ) : null}
          {count > 0 ? (
            <PaginationView
              count={count}
              tableData={leadList ? leadList : []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={getTableDataSlots(count)}
            />
          ) : null}
          <div>
            <PaginationTable
              tableData={leadList ? leadList : []}
              pagename={"createImportLeads"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "First name",
                "Last name",
                // "Mobile Number",
                // "Mobile Number (Optional)",
                "Email",
                "State",
                "City",
                "Exam Stream",
                "Exam",
                "Education year",
                "Source",
                lead?._id === 1 ? "Reference" : "Counsellor",
              ]}
              edit={false}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"IMPORT LEAD"} />
      <div className="formDiv">
        <div className="row m-1">
          <form className="col-12 formWidth">
            <div className="row d-flex w-100">
              <div className="px-3 col-12 mt-3">
                <div className="d-flex col-3  mb-2 align-items-center">
                  <CustomDropdown
                    lableName="Select Lead Type"
                    setInputText={(value: any) => onChangeLeadRole(value)}
                    value={lead}
                    options={sourceTypeList}
                    Isrequired={true}
                  />
                </div>
                <div className="d-flex">
                  <div className="d-flex">
                    <ImagePickerAndViewer
                      lableName={"Upload Sample EXCEL"}
                      value={selectedFile}
                      doctype={"excel"}
                      removelabel={true}
                      htmlFor={`photo-upload`}
                      handleChange={(e: any) => handleEXCELChange(e)}
                    />
                    <label className="label">
                      {selectedFile ? selectedFile : "Upload Excel"}
                    </label>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <button
                      type="button"
                      className={`btn mb-3 submitbtn importLeadBtn ${
                        validations() ? "btn-secondary" : "btn-primary"
                      }`}
                      onClick={(e) => onClickImportLead(e)}
                      disabled={validations()}
                    >
                      Import Lead
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {renderListMode()}
        </div>
      </div>
      {/* { openPopup ? <ImportLeadPopUp open={openPopup} handleClose={handleClose}  /> :""} */}
    </div>
  );
}
