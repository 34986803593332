import { useEffect,useState } from 'react'
import { useDispatch } from 'react-redux'; 
import { useNavigate } from "react-router-dom";
import PageTitle from '../../Component/pageTitle';  
import DatePicker from '../../Component/datePicker';
import LabelField from '../../Component/labelField';
import SubmitButton from '../../Component/submitButton';
import moment from 'moment';
import { Pagination } from '@mui/material';
import PaginationTable from '../../Component/paginationTable';
import PaginationView from '../../Component/paginationView';
import usePagination from '../../Component/usePagination';
import { getTableDataSlots } from '../../DATA/dataConstant';
import { leadoverview } from '../../DATA/dataConstant';
export default function LeadGenerationOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [startDate, SetStartDate] = useState<any>(moment.utc(new Date()).format("YYYY-MM-DD"));
  const [endDate, SetEndDate] = useState<any>(moment.utc(new Date()).format("YYYY-MM-DD"));
  
  
    // pagination
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(count / rowsPerPage);
    const _DATA = usePagination(count, rowsPerPage);
  
    const handleChange = (e: any, page: number) => {
      onPageChange(page, rowsPerPage, 1);
      setPage(page - 1);
      setCurrentPage(page);
      _DATA.jump(page);
    };
    const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
      /************ page change****************** */
      let data = {};
      if (flag === 1) {
        data = {
          skip: (page - 1) * rowsPerPage,
          limit: rowsPerPage,
        };
      } else if (flag === 2) {
        data = {
          skip: 0,
          limit: rowsPerPage,
        };
      } else {
        data = {
          skip: 0,
          limit: count,
        };
      }
      // dispatch(
      //   getEmployeeList(
      //     data,
      //     (res:any) => {
      //       setCount(res?.count);
      //       setEmployeeList(res?.data);
      //     },
      //     () => {}
      //   )
      // );
    };
  
    const handleChangePage = (event: any, newPage: number) => {
      setPage(newPage);
      setCurrentPage(newPage + 1);
    };
  
    const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
      const value = event.target.value;
      if (value === "All") {
        setRowsPerPage(-1);
        setPage(0);
        setCurrentPage(1);
        return;
      }
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
      setCurrentPage(1);
      onPageChange(page, value, 2);
    };

    const onClickLead = (data: any) => {
      navigate("../leadOverview", {
       
      });
    }
  const handleSubmit = (e: any) => {
    e.preventDefault();
 

  }
  
  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSubmit}>
        <div className="col-md-4 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
            />
          </div>
        </div>
         
        <div className="col-md-4 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {leadoverview?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"TeamLeadManageEmployee"}
            count={count}
            tableData={leadoverview ? leadoverview : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={leadoverview ? leadoverview : []}
          pagename={"LeadGenerationOverview"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Date",
            "Total Generated Leads",
            "Total Allocated Leads",
            "Total Pending Leads",
            
          ]}
          handleClick={onClickLead}
        />
      </>
    );
  };

  
 



  return (
    <div className="contentBox">
      <PageTitle name={"Total Generated Leads"} />
      <div className="col-md-12 row">
         
        <div className=" formDiv formWidth p-5">
        {renderSearchMode()}
           {renderListMode()}  
          
        </div> 
      </div>
    </div>
  );
}

