import React from "react";
import LabelField from "./labelField";
import uploadImage from "../Assets/images/upload.png";
import uploadPDF from "../Assets/images/file-upload-blank.png";
import uploadedPDF from "../Assets/images/file-upload.png";
import uploadExcel from "../Assets/images/file-upload-blank.png";
import uploadedExcel from "../Assets/images/file-upload-xls.png";
import uploadVideo from "../Assets/images/upload-video.png";
import Edit from "../Assets/images/edit-grey.png";
import beforeUploadExcel from "../Assets/images/greyExcel.png";


interface props {
  value: any,
  handleChange: any,
  doctype?: any,
  lableName?: any,
  removelabel?: any,
  isRequired?: any,
  htmlFor?: string,
  disabled?:any,
  handleNavigate?:any
}

function ImagePickerAndViewer({
    lableName,
    value,
    handleChange, 
    removelabel,
    doctype,
    isRequired,
    htmlFor,
    disabled,
    handleNavigate
  }:props) {
    const renderPDF = () => {
      if (value) {
        return (
          <img 
            src={uploadedPDF}
            style={{
              width: "50px",
              height: "50px",
            }}
            alt={""}
          />
        );
      } else {
        return (
          <img 
            src={uploadPDF}
            style={{
              width: "50px",
              height: "50px",
            }}
            alt={""}
          />
        );
      }
    };
  
    const renderExcel = () => {
      if (value) {
        return (
          <img 
            src={uploadedExcel}
            style={{
              width: "60px",
              height: "60px",
            }}
            alt={""}
          />
        );
      } else {
        return (
          <img 
            src={beforeUploadExcel}
            style={{
              width: "60px",
              height: "60px",
          
            }}
            alt={""}
          />
        );
      }
    };
  
    const renderVideo = () => {
      if (value && value !== " ") {
        return <div>{value}</div>;
      } else {
        return (
          <img 
            src={uploadVideo}
            style={{
              width: "50px",
              height: "50px",
            }}
            alt={""}
          />
        );
      }
    };
  
    const renderImage = () => {
      if (value && value !== " ") {
        return (
          <img 
            src={value}
            style={{
              // width: "50px",
              height: "50px",
              maxWidth: "100%",
            }}
            alt={""}
            onClick={handleNavigate}
          />
        );
      } else {
        return (
          <img 
            src={uploadImage}
            style={{
              // width: "50px",
              height: "50px",
              maxWidth: "100%",
            }}
            alt={""}
            onClick={handleNavigate}

          />
        );
      }
    };
  
    const renderMediaInput = () => {
      if (doctype === "pdf") return renderPDF();
      else if (doctype === "video") return renderVideo();
      else if (!doctype) return renderImage();
      else if (doctype === "excel") return renderExcel();
      else if (doctype === "pdf/image") return renderPDF();
  
      <img
        src={Edit}
        className="cursor"
        alt="Edit"
        style={{
          width: "13px",
          position: "absolute",
          right: "0",
          bottom: "0px",
        }}
      />;
    };
  
    return (
      <div className="d-flex mb-2 align-items-center">
        {removelabel ? null : <LabelField lableName={lableName} />}
        <label htmlFor={htmlFor} className="custom-file-upload fas cursor ">
          {renderMediaInput()}
          <input
            id={htmlFor}
            style={{ display: "none" }}
            type="file"
            disabled={disabled}
            onChange={(e) => handleChange(e)}
            accept={
              doctype === "pdf"
                ? ".pdf"
                : doctype === "video"
                ? "video/mp4"
                : doctype === "excel"
                ? ".xls,.xlsx"
                : doctype === "pdf/image"
                ? "image/png, image/gif, image/jpeg, .pdf"
                : "image/png, image/gif, image/jpeg"
            }
          />
        </label>
        {isRequired ? <span className="required ms-2 fs-6">*</span> : null}
      </div>
    );
  }

export default ImagePickerAndViewer;
