import { useState } from "react";
import { useDispatch } from "react-redux";
import { Pagination } from "@mui/material";
import PageTitle from "../../Component/pageTitle";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import usePagination from "../../Component/usePagination";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import {
  containsNumber,
  length_Ten,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { getLeadByMobileNumber } from "../../Redux/Actions/managerPanelAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import { useNavigate } from "react-router-dom";
import { getTableDataSlots } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function CouponCode() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [leadRecord, setLeadRecord] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        name: mobileNumber,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        name: mobileNumber,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        name: mobileNumber,
      };
    }
    dispatch(
      getLeadByMobileNumber(
        data,
        (res: any) => {
          setCount(res?.count);
          setLeadRecord(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      mobileNo: mobileNumber,
    };
    dispatch(
      getLeadByMobileNumber(
        postData,
        (res: any) => {
          setCount(res?.length);
          setLeadRecord(res);
        },
        () => {}
      )
    );
  };

  const onchangeCouponCode = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMobileNumber(val);
      }
    } else {
      setMobileNumber(val);
    }
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          pagename={"leadRecord"}
          count={count}
          tableData={leadRecord ? leadRecord : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <PaginationTable
          tableData={leadRecord ? leadRecord : []}
          pagename={"leadRecord"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Student Name",
            "Status",
            "Counsellor Name",
            "Exam Name",
            "Exam Stream",
            "",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
        />
      </div>
    );
  };

  const handleEdit = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    navigate("/leadDetail", {
      state: {
        leadId: data?._id,
        pageName: "leadSearch",
      },
    });
  };

  const searchValidation = () => {
    return mobileNumber === "" || mobileNumber.length < length_Ten;
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Search"} />
      <div className="row m-1">
        <div className="formDiv">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <form className="d-flex " onSubmit={handleSubmit}>
                <div className="col-md-4 d-flex mb-2 align-items-center me-3">
                  <LabelField lableName={"Mobile No."} />
                  <div className="removelabel">
                    <InputField
                      placeholder={"Enter Mobile Number"}
                      onChangeInput={(value: any) => onchangeCouponCode(value)}
                      value={mobileNumber}
                      maxlength={length_Ten}
                      minlength={length_Ten}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="d-flex mb-2 col-4 me-4">
                  <div>
                    <SubmitButton
                      name={"Search"}
                      validation={searchValidation()}
                    />
                  </div>
                </div>
              </form>
            </div>
            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
