import { useEffect, useState } from "react";
import LabelField from "../../Component/labelField";
import { Pagination } from "@mui/material";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import DatePicker from "../../Component/datePicker";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserActivityList } from "../../Redux/Actions/counsellorPanelAction";
import SubmitButton from "../../Component/submitButton";
import { useAppDispatch } from "../../hooks";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";

export default function UserActivityTracker() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const pageName = location.state?.pageName;

  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [userActivityTrackerData, setUserActivityTrackerData] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    if (pageName !== "leadDetail") {
      dispatch(updateCommonValues("selectedLeadId", ""));
      dispatch(updateCommonValues("selectedLeadName", ""));
      dispatch(updateCommonValues("selectedClickedDate", ""));
    }
    dispatch(
      getUserActivityList(
        { skip: 0, limit: rowsPerPage, startDate, endDate },
        (res: any) => {
          setCount(res?.count);
          setUserActivityTrackerData(res?.result);
        },
        () => {}
      )
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
      };
    }
    dispatch(
      getUserActivityList(
        data,
        (res: any) => {
          setCount(res?.count);
          setUserActivityTrackerData(res?.result);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickDetails = (data: any) => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    dispatch(updateCommonValues("selectedLeadId", data?.leadId));
    dispatch(updateCommonValues("selectedLeadName", data?.studentName));
    dispatch(updateCommonValues("selectedClickedDate", data?.date));
    navigate("../userActivityDetails", {
      state: {
        leadMobileNo: data?.mobileNo,
        pageName: "userActivityTracker",
      },
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      getUserActivityList(
        { skip: 0, limit: rowsPerPage, startDate, endDate },
        (res: any) => {
          setCount(res?.count);
          setUserActivityTrackerData(res?.result);
        },
        () => {}
      )
    );
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
  };

  const renderSearchMode = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between">
          <div className="col-md-4 d-flex">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date :" />
            </div>
            <div className="col-md-6">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date :" />
            </div>
            <div className="col-md-6">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
              />
            </div>
          </div>
          <div className="col-md-4">
            <SubmitButton name={"Submit"} />
          </div>
        </div>
      </form>
    );
  };
  const renderListMode = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>

        <PaginationView
          pagename={"userActivityTracker"}
          count={count}
          tableData={userActivityTrackerData ? userActivityTrackerData : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />

        <PaginationTable
          tableData={userActivityTrackerData ? userActivityTrackerData : []}
          pagename={"userActivityTracker"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Name",
            "Current Page Activity",
            "Page Activity Time",
            "Date",
            "Action",
          ]}
          handleNavigate={(value: any) => onClickDetails(value)}
        />
      </>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"USER ACTIVITY TRACKER"} />
      <div className="formDiv formWidth col-md-12">
        <div className="">
          <div className="row d-flex">
            <div className="p-2 col-12">
              {renderSearchMode()}
              {renderListMode()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
