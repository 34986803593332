import { Pagination } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import Header from "../../Component/header";
import GenaralTable from "../../Component/genaralTable";
import { getWebinarStudentInfo } from "../../Redux/Actions/bdExecutivePanelAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
export default function CompleteWebinarDetailsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [studentList, setStudentList] = useState<any>([]);
  const webinarId = location.state?.webinarId;
  const coordinatorName = location.state?.coordinatorName;
  const coordinatorphone = location.state?.coordinatorphone;
  const coordinatorEmail = location.state?.coordinatorEmail;
  const webinarDateTime = location.state?.webinarDateTime;
  const webinarLink = location.state?.webinarLink;
  const webinarfaculty = location.state?.webinarfaculty;
  const webColgName = location.state?.webColgName;
  const webColgState = location.state?.webColgState;
  const webColgCity = location.state?.webColgCity;
  const webColgExam = location.state?.webColgExam;
  const webColgDepartment = location.state?.webColgDepartment;
  const leadRegCount = location.state?.leadRegCount;
  const leadFirstYrCount = location.state?.leadFirstYrCount;
  const leadSeconYrCount = location.state?.leadSeconYrCount;
  const leadThirdYrCount = location.state?.leadThirdYrCount;

  const tableRef = useRef(null);
  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  const [colgName, setColgName] = useState([]);

  useEffect(() => {
    dispatch(
      getWebinarStudentInfo(
        webinarId,
        (res: any) => {
          setStudentList(res?.data);
          setColgName(res?.data[0]?.reference);
        },
        () => {}
      )
    );
  }, []);

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth" ref={tableRef}>
        <h6 className="label">{webColgName} </h6>
        <GenaralTable
          tableData={studentList ? studentList : []}
          pagename={"Complete_webinarList"}
          tableHead={[
            "First Name",
            "Last Name",
            "Department",
            "Subject Name",
            "Date of webinar ",
          ]}
        />
      </div>
    );
  };

  return (
    <div className=" col-md-12 maindiv">
      <Header />

      <div className="row m-0">
        <div className="col-md-12 font14 p-5 pt-2 ">
          <div className="mx-5 detailsdiv boxshadow p-3">
            <h3 className="label">Student List </h3>

            <div className="row d-flex justify-content-between font17 mt-5">
              {/* ************************ Co-Ordinator Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Co-Ordinator</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Name:</div>
                  <div className="col-md-8">{coordinatorName}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Phone :</div>
                  <div className="col-md-8">{coordinatorphone}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Email :</div>
                  <div className="col-md-8">{coordinatorEmail}</div>
                </div>
              </div>
              {/* ************************Webinar Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Webinar Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Date :</div>
                  <div className="col-md-8">
                    {moment(webinarDateTime).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Time :</div>
                  <div className="col-md-8">
                    {moment(webinarDateTime).format("hh:mm:ss a")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Link :</div>
                  <div className="col-md-8 text-break">
                    <a href={webinarLink} target="_blank" rel="noreferrer">
                      {webinarLink}
                    </a>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Faculty :</div>
                  <div className="col-md-8">
                    {webinarfaculty ? webinarfaculty : "-"}
                  </div>
                </div>
              </div>

              {/* ************************Collage Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Collage Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Collage :</div>
                  <div className="col-md-8">{webColgName}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">State :</div>
                  <div className="col-md-8">{webColgState}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">City :</div>
                  <div className="col-md-8">{webColgCity}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Exam :</div>
                  <div className="col-md-8">{webColgExam}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Department :</div>
                  <div className="col-md-8">{webColgDepartment}</div>
                </div>
              </div>

              {/* *********************************Lead Details *************************************** */}

              <div className="col-md-3 col-sm-3 d-flex">
                <div>
                  <h5 className="fw-bold">Lead Details:</h5>
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-5">Lead Registered :</div>
                    <div className="col-md-7">{leadRegCount}</div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-5">First Year :</div>
                    <div className="col-md-7">{leadFirstYrCount}</div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-5">Second Year :</div>
                    <div className="col-md-7">{leadSeconYrCount}</div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-5">Third Year :</div>
                    <div className="col-md-7">{leadThirdYrCount}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between px-3">
              <div className="d-flex mb-2 align-items-center">
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-outline-primary mb-3   py-1">
                    Export Details
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
