
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface props {
  label: any,
  value: any,
  link?: any,
}



export default function CallRecordedGraph({ label, value, link }: props) {



  const navigate = useNavigate();
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      }
    }

  };
  const graphData = {
    labels: label,
    datasets: [
      {
        label: " ",
        data: value,
        backgroundColor: [
          "#158833",
          "#158833",
        ],
        borderColor: [
          "#158833",
          "blue",
        ],
        borderWidth: 0.5,
        barPercentage: 0.2,
        links: link

      }

    ]


  };

  const chartRef: any = useRef();

  const onClick = (event: any) => {

    if (getElementsAtEvent(chartRef.current, event).length > 0) {

      const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0].datasetIndex
      const dataPoint = getElementsAtEvent(chartRef.current, event)[0].index
      // navigate(graphData.datasets[datasetIndexNum].links[dataPoint])
    }

   
  }


  return (
    <Bar options={options} data={graphData} onClick={onClick} ref={chartRef}   width={1200} height={400}
    />

  );
}
