export const defaultVal = {
  _id: 0,
  name: "Please Select",
};

export const dateFormat = {
  yyyymmdd: "YYYY-MM-DD",
  yyyymmddA: "YYYY-MM-DD LT",
};

export const getTableDataSlots = (count: any) => {
  const slotsArray = [
    { value: 1, label: 1 },
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 },
    // { value: count, label: "All" },
  ];

  return slotsArray;
};

export const leadType = [
  {
    _id: 1,
    name: "NEW",
  },
  {
    _id: 0,
    name: "SKIP",
  },
  {
    _id: 11,
    name: "EXPIRED",
  },
  {
    _id: 5,
    name: "ADMISSION DONE",
  },
];

export const experienceList = [
  {
    _id: 0,
    name: "0 - 1 Month",
  },
  {
    _id: 1,
    name: "1 Month",
  },
  {
    _id: 2,
    name: "2 Months",
  },
  {
    _id: 3,
    name: "3 Months",
  },
  {
    _id: 4,
    name: "4 Months",
  },
  {
    _id: 5,
    name: "5 Months",
  },
  {
    _id: 6,
    name: "6 Months",
  },
  {
    _id: 7,
    name: "7 Months",
  },
  {
    _id: 8,
    name: "8 Months",
  },
  {
    _id: 9,
    name: "9 Months",
  },
  {
    _id: 10,
    name: "10 Months",
  },
  {
    _id: 11,
    name: "11 Months",
  },
  {
    _id: 12,
    name: "1 Year",
  },
  {
    _id: 13,
    name: "13 Months",
  },
  {
    _id: 14,
    name: "14 Months",
  },
  {
    _id: 15,
    name: "15 Months",
  },
  {
    _id: 16,
    name: "16 Months",
  },
  {
    _id: 17,
    name: "17 Months",
  },
  {
    _id: 18,
    name: "18 Months",
  },
  {
    _id: 19,
    name: "19 Months",
  },
  {
    _id: 20,
    name: "20 Months",
  },
  {
    _id: 21,
    name: "21 Months",
  },
  {
    _id: 22,
    name: "22 Months",
  },
  {
    _id: 23,
    name: "23 Months",
  },
  {
    _id: 24,
    name: "2 Years",
  },
  {
    _id: 25,
    name: "25 Months",
  },
  {
    _id: 26,
    name: "26 Months",
  },
  {
    _id: 27,
    name: "27 Months",
  },
  {
    _id: 28,
    name: "28 Months",
  },
  {
    _id: 29,
    name: "29 Months",
  },
  {
    _id: 30,
    name: "30 Months",
  },
  {
    _id: 31,
    name: "31 Months",
  },
  {
    _id: 32,
    name: "32 Months",
  },
  {
    _id: 33,
    name: "33 Months",
  },
  {
    _id: 34,
    name: "34 Months",
  },
  {
    _id: 35,
    name: "35 Months",
  },
  {
    _id: 36,
    name: "3 Years",
  },
  {
    _id: 37,
    name: "37 Months",
  },
  {
    _id: 38,
    name: "38 Months",
  },
  {
    _id: 39,
    name: "39 Months",
  },
  {
    _id: 40,
    name: "40 Months",
  },
  {
    _id: 41,
    name: "41 Months",
  },
  {
    _id: 42,
    name: "42 Months",
  },
  {
    _id: 43,
    name: "43 Months",
  },
  {
    _id: 44,
    name: "44 Months",
  },
  {
    _id: 45,
    name: "45 Months",
  },
  {
    _id: 46,
    name: "46 Months",
  },
  {
    _id: 47,
    name: "47 Months",
  },
  {
    _id: 48,
    name: "4 Years",
  },
  {
    _id: 49,
    name: "49 Months",
  },
  {
    _id: 50,
    name: "50 Months",
  },
  {
    _id: 51,
    name: "51 Months",
  },
  {
    _id: 52,
    name: "52 Months",
  },
  {
    _id: 53,
    name: "53 Months",
  },
  {
    _id: 54,
    name: "54 Months",
  },
  {
    _id: 55,
    name: "55 Months",
  },
  {
    _id: 56,
    name: "56 Months",
  },
  {
    _id: 57,
    name: "57 Months",
  },
  {
    _id: 58,
    name: "58 Months",
  },
  {
    _id: 59,
    name: "59 Months",
  },
  {
    _id: 60,
    name: "5 Years",
  },
];

export const leadoverview = [
  {
    _id: 1,
    date: "26/02/23",
    generatedLeads: "2203",
    generatedLeadstotal: "48446",
    allocatedLeads: "519",
    allocatedLeadsTotal: "11259",
    pendingLeads: "1684",
    pendingLeadsTotal: "37187",
  },
  {
    __id: 2,
    date: "27/02/23",
    generatedLeads: "2203",
    allocatedLeads: "519",
    pendingLeads: "1684",
  },
];

export const sortLeadScoreList = [
  {
    _id: 0,
    value: -1,
    name: "High to Low",
  },
  {
    _id: 1,
    value: 1,
    name: "Low to High  ",
  },
];
export const remarkTypeList = [
  {
    _id: 0,
    name: "Team Lead Remark",
  },
  {
    _id: 1,
    name: "Manager Remark",
  },
];

export const ifasianList = [
  {
    _id: 1,
    name: "YES",
  },
  {
    _id: 2,
    name: "NO",
  },
];

export const referSourceType = [
  {
    _id: 2,
    name: "REFERENCE",
  },
  {
    _id: 10,
    name: "INCOMING",
  },
];

export const webinarStatus = [
  // {
  //   _id: 1,
  //   name: "UPCOMING",

  // },
  {
    _id: 2,
    name: "COMPLETED",
  },
  {
    _id: 3,
    name: "CANCELLED",
  },
  {
    _id: 4,
    name: "RESCHEDULE",
  },
  {
    _id: 5,
    name: "PENDING",
  },
];

export const PendingWebinarStatus = [
  {
    _id: 2,
    name: "COMPLETED",
  },

  {
    _id: 5,
    name: "PENDING",
  },
];

export const qualificationList = [
  {
    _id: 1,
    name: "BSc",
  },
  {
    _id: 2,
    name: "BCom",
  },
  {
    _id: 3,
    name: "BA",
  },
  {
    _id: 4,
    name: "MSc",
  },
  {
    _id: 5,
    name: "MA",
  },
  {
    _id: 6,
    name: "MCom",
  },
  {
    _id: 7,
    name: "MBA",
  },
  {
    _id: 8,
    name: "BTech / BE",
  },
];

export const qualificationYearList = [
  {
    _id: 1,
    qualificationId: 1,
    name: "FIRST",
  },
  {
    _id: 2,
    qualificationId: 1,
    name: "SECOND",
  },
  {
    _id: 3,
    qualificationId: 1,
    name: "THIRD",
  },
  {
    _id: 4,
    qualificationId: 2,
    name: "FIRST",
  },
  {
    _id: 5,
    qualificationId: 2,
    name: "SECOND",
  },
  {
    _id: 6,
    qualificationId: 2,
    name: "THIRD",
  },
  {
    _id: 7,
    qualificationId: 3,
    name: "FIRST",
  },
  {
    _id: 8,
    qualificationId: 3,
    name: "SECOND",
  },
  {
    _id: 9,
    qualificationId: 3,
    name: "THIRD",
  },
  {
    _id: 10,
    qualificationId: 4,
    name: "FIRST",
  },
  {
    _id: 11,
    qualificationId: 4,
    name: "SECOND",
  },
  {
    _id: 12,
    qualificationId: 5,
    name: "FIRST",
  },
  {
    _id: 13,
    qualificationId: 5,
    name: "SECOND",
  },
  {
    _id: 14,
    qualificationId: 6,
    name: "FIRST",
  },
  {
    _id: 15,
    qualificationId: 6,
    name: "SECOND",
  },
  {
    _id: 16,
    qualificationId: 7,
    name: "FIRST",
  },
  {
    _id: 17,
    qualificationId: 7,
    name: "SECOND",
  },
  {
    _id: 18,
    qualificationId: 8,
    name: "FIRST",
  },
  {
    _id: 19,
    qualificationId: 8,
    name: "SECOND",
  },
  {
    _id: 20,
    qualificationId: 8,
    name: "THIRD",
  },
  {
    _id: 21,
    qualificationId: 8,
    name: "FOURTH",
  },
];

export const studentList = [
  {
    _id: "6599d3061701f8a3a6e92d05",
    firstName: "PRINCE",
    lastName: "DABYAN",
    source: "PAID AD AUTOMATIC",
    type: 6,
    callBackDate: "2024-01-26T10:58:23.000Z",
    phone: "6398204144",
    state: "HARYANA",
    course: "LIFE SCIENCE",
    status: "NOT INTERESTED",
    statusId: 9,
    registerDate: "2024-01-06T22:24:07.065Z",
    allotedDate: "2024-01-11T10:09:51.200Z",
    remark: "Did not pick ",
  },
  {
    _id: "6599d3061701f8a3a6e92d07",
    firstName: "PRINCE",
    lastName: "DABYAN",
    source: "PAID AD AUTOMATIC",
    type: 6,
    callBackDate: "2024-01-26T10:40:18.000Z",
    phone: "6398204144",
    state: "CHATTISGARH",
    course: "LIFE SCIENCE",
    status: "NOT INTERESTED",
    statusId: 9,
    registerDate: "2024-01-06T22:24:07.065Z",
    allotedDate: "2024-01-11T10:09:51.272Z",
    remark: "cx said to me wrong no ",
  },
];

export const ResultOtherDetails = [
  {
    _id: 1,
    name: "JRF",
  },
  {
    _id: 2,
    name: "NET LS",
  },
];

export const categoryList = [
  {
    _id: 1,
    name: "UNRESERVED",
  },
  {
    _id: 2,
    name: "EWS",
  },
  {
    _id: 3,
    name: "OBS",
  },
  {
    _id: 4,
    name: "PWD",
  },
];

export const yearData = [
  {
    _id: 1,
    value: 2024,
    name: "2024",
  },
  {
    _id: 2,
    value: 2023,
    name: "2023",
  },
  {
    _id: 3,
    value: 2022,
    name: "2022",
  },
  {
    _id: 4,
    value: 2021,
    name: "2021",
  },
  {
    _id: 5,
    value: 2020,
    name: "2020",
  },
];

export const ScoreCardList = [
  {
    _id: 1,
    name: "Image",
  },
  {
    _id: 2,
    name: "PDF",
  },
];
