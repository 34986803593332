import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LabelField from "./labelField";
import InputField from "./inputField";
import CustomDropdown from "./customDropdown";
import SubmitButton from "./submitButton";
import { getState, getCity } from "../Redux/Actions/bdeAdminPanelAction";
import {
  containsNumber,
  length_Fifteen,
  length_Fifty,
  length_Four,
  length_One,
  length_Ten,
  length_Thirty,
  length_Three,
  length_ThreeHundred,
  length_Twenty,
  length_Two,
  length_TwoHundred,
  startsWithSpace,
  validateFullName,
  validateIsLettersAndDotOnly,
  validateIsNumberOnly,
  validateValueWithoutSpace,
} from "../DATA/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateIsAlphabetErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../DATA/errorMsg";
import {
  getCollegeList,
  addCollege,
  getVrifyNumber,
} from "../Redux/Actions/bdExecutivePanelAction";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
}

export default function AddCollege(props: props) {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const [college, setCollege] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [department, setDepartment] = useState<any>("");
  const [stateList, setStateList] = useState([]);
  const [ResetState, setResetState] = useState(false);
  const [district, setDistrict] = useState<any>("");
  const [districtList, setDistrictList] = useState([]);
  const [ResetDistrict, setResetDistrict] = useState(false);
  const [email, setEmail] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [collegeList, setCollegeList] = useState([]);
  const [count, setCount] = useState(0);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  useEffect(() => {
    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCity(
        val._id,
        (res: any) => {
          setDistrictList(res);
        },
        () => {}
      )
    );
  };

  const onchangeCollegeName = (val: any) => {
    if (val !== "") {
      if (!validateIsLettersAndDotOnly(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (startsWithSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setCollege(val?.toUpperCase());
      }
    } else {
      setCollege(val?.toUpperCase());
    }
  };
  const onChangeDistrict = (val: any) => {
    setDistrict(val);
  };

  const onChangeDepartment = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setDepartment(val);
      }
    } else {
      setDepartment(val);
    }
  };
  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPhone(val);
      }
    } else {
      setPhone(val);
    }
  };

  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onchangeEmail = (val: string) => {
    setEmail(val);
  };
  const handeSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      collegeName: college,
      stateId: state?._id,
      districtId: district?._id,
      department: department,
      contactNo: phone,
      email: email,
    };
    dispatch(
      addCollege(
        postData,
        (res: any) => {
          setCollege("");
          setResetState(true);
          setResetDistrict(true);
          setDepartment("");
          setPhone("");
          setEmail("");
          dispatch(
            getCollegeList(
              {},
              (res: any) => {
                setCollegeList(res?.collegeData);
                setCount(res?.count);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );

    handleClose(true);
  };

  const onHandleContactVerify = () => {
    dispatch(
      getVrifyNumber(
        phone,
        (res: any) => {
          dispatch(showSuccessSnackbar(res?.data?.msg) as any);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg) as any);
        }
      )
    );
  };

  const validation = () => {
    return (
      college === "" ||
      college?.length < length_Two ||
      state === "" ||
      district === "" ||
      phone === "" ||
      phone?.length < length_Ten ||
      department === "" ||
      department?.length < length_One ||
      email === "" ||
      email?.length < length_Three ||
      !email.match(emailsCheck)
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldivChat text-center p-md-4 py-md-4 ">
          <form onSubmit={handeSubmit}>
            <div className=" d-flex justify-content-between">
              <div>
                <h3 className="text-primary fw-bold">Add New College</h3>{" "}
              </div>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="mt-4">
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="College Name" />
                </div>
                <div className="col-md-9">
                  <InputField
                    placeholder="Enter College Name"
                    value={college}
                    onChangeInput={(value: any) => onchangeCollegeName(value)}
                    maxlength={length_ThreeHundred}
                    minlength={length_Two}
                  />
                </div>
              </div>

              <div className=" d-flex  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="State" />
                </div>
                <div className="col-md-9 ps-3">
                  <CustomDropdown
                    lableName="Select State"
                    setInputText={(value: any) => onChangeState(value)}
                    value={state}
                    options={stateList}
                    Isrequired={true}
                    reset={ResetState}
                  />
                </div>
              </div>
              <div className=" d-flex  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="District" />
                </div>
                <div className="col-md-9 ps-3">
                  <CustomDropdown
                    lableName="Select District"
                    setInputText={(value: any) => onChangeDistrict(value)}
                    value={district}
                    options={districtList}
                    Isrequired={true}
                    reset={ResetDistrict}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Department" />
                </div>
                <div className="col-md-9">
                  <InputField
                    placeholder="Enter Department"
                    value={department}
                    onChangeInput={(value: any) => onChangeDepartment(value)}
                    maxlength={length_Fifty - 1}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Phone No" />
                </div>
                <div className="col-md-6 me-0">
                  <InputField
                    placeholder="Enter Phone no"
                    value={phone}
                    onChangeInput={(value: any) => onChangeContact(value)}
                    maxlength={length_Fifteen}
                    minlength={length_Ten}
                  />
                
              
                </div>
                <div className="col-md-3 d-flex justify-content-start">
                  <button
                  type="button"
                  className={
                    phone?.length >= 10
                      ? "btn btn-success ms-2 me-2 font12 mb-2 "
                      : "btn btn-secondary me-2 font12 mb-2 "
                  }
                  onClick={onHandleContactVerify}
                >
                  {" "}
                  <span>VERIFY</span>
                </button>

                  </div>
               
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Email" />
                </div>
                <div className="col-md-9">
                  <InputField
                    placeholder="Enter College Email"
                    value={email}
                    onChangeInput={(value: any) => onchangeEmail(value)}
                    maxlength={length_TwoHundred}
                    minlength={length_Four}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center mb-3">
                <SubmitButton name={"Submit"} validation={validation()} />
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
