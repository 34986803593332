import React, { useEffect, useState } from 'react';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

 


export default function ImageDisplay() {
const location = useLocation();
const link:any = sessionStorage.getItem('imgLink');

const imageUrl = location.state?.imageUrl;

  return (
    <div className="col-md-12 maindiv">
        <div className="justify-content-center d-flex ">
                <img className="img-fluid" src={link} alt ={link}/>
              </div>

    </div>
  );
}
