import { LeadManagementModal } from "../Modals";
import * as Actions from "../Types/authTypes";

const LeadManagementReducer = (
  state = new LeadManagementModal(),
  action: any
) => {
  switch (action.type) {
    case Actions.SET:
      return { ...state, ...action.payload };
    case Actions.LOADER:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default LeadManagementReducer;
