import React, { useEffect, useState } from 'react';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
 

interface props {
  handleClose: Function;
  handleCall: Function;
  data?: any;
  title?: any;
}

export default function CallPopUP(props:props) {
  const {
    handleClose,
    handleCall,
    data,
    title,
  } = props
  return (
    <div className="slidepopup " id="slidepopup">
      <div className="d-flex justify-content-end">
        <p className="fw-bold me-2">{title}</p>
        <CloseIcon color="primary" className="cursor" onClick={()=>{handleClose()}} />
      </div>

      <div className="slidepopupInnerDiv" id="slidepopuptxt">
        <div className="d-flex justify-content-between">
          <p className=" text-success fw-bold boldfont mb-2 font14">
            {data[0]?.firstName} {data[0]?.lastName}
          </p>
          <button className="btn btn-success" onClick={(data:any)=>{handleCall(data)}} id={data[0]?._id} value={data[0]?.mobileNo}>Call Now</button>
        </div>
        {/* <div>+91-{data[0]?.mobileNo}</div> */}
        <div className="fw-bold">
          Call Back Time:{" "}
          <span className="text-primary">{moment(data[0]?.callBackDate).utc().format("DD-MM-YYYY hh:mm:ss a")}</span>
        </div>
      </div>
    </div>
  );
}
