import { useEffect, useState } from "react";
import CustomDropdown from "../../../Component/customDropdown";
import LabelField from "../../../Component/labelField";
import PageTitle from "../../../Component/pageTitle";
import { getCousesByUserId } from "../../../Redux/Actions/counsellorPanelAction";
import { useDispatch } from "react-redux";
import StudentDetails from "./studentDetails";

const StudentCourseDetails = (props: any) => {
  const dispatch = useDispatch();
  const { data: studentData } = props;

  const [batchFilter, setBatchFilter] = useState<any>("");
  const [batchFilterList, setBatchFilterList] = useState<any>([]);
  const [resetBatchFilter, setResetBatchFilter] = useState<any>(false);

  const [isViewCourseMode, setIsViewCourseMode] = useState(false);
  const [studentViewData, setStudentViewData] = useState({});

  useEffect(() => {
    dispatch(
      getCousesByUserId(
        studentData,
        (res: any) => {
          setBatchFilterList(res);
        },
        () => {}
      )
    );
  }, []);

  const onListChange = (value: any) => {
    setBatchFilter(value);
    setResetBatchFilter(false);
  };

  const onClickDetails = () => {
    let obj = {
      userId: studentData?.userId,
      courseId: batchFilter?._id,
    };
    setStudentViewData(obj);
    setIsViewCourseMode(true);
  };

  const handleBackBtn = () => {
    setIsViewCourseMode(false);
    setStudentViewData({});
  };

  const renderSearch = () => {
    return (
      <form className="row d-flex">
        <div className="col-md-6 d-flex mb-0">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Batch Filter" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              setInputText={(value: any) => onListChange(value)}
              value={batchFilter}
              options={batchFilterList}
              lableName="Select Batch Filter"
              reset={resetBatchFilter}
              defaultValue={batchFilter?._id}
            />
          </div>
        </div>
        <div className="d-flex col-md-3 justify-content-center pt-0 ms-0">
          <div className="col-md-6 d-flex justify-content-center mt-0">
            <button
              type="button"
              className={"btn mb-3 submitbtn btn-primary"}
              onClick={() => onClickDetails()}
            >
              Search{" "}
            </button>
          </div>
        </div>
      </form>
    );
  };
  return (
    <>
      {!isViewCourseMode ? (
        <div className="contentBox">
          <PageTitle
            name={"STUDENT COURSE DETAILS"}
            isGoBack
            onClick={props?.handleGoBack}
          />
          <div className="row col-12 p-2">
            <div className="formWidth formDiv">{renderSearch()}</div>
          </div>
        </div>
      ) : (
        <StudentDetails data={studentViewData} handleGoBack={handleBackBtn} />
      )}
    </>
  );
};

export default StudentCourseDetails;
