import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import { WebinarList } from "../../Redux/Actions/bdExecutivePanelAction";

export default function CancelledWebinar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  const [cancelledWebinarList, setcancelledWebinarList] = useState([]);
  const [webinarListData, setWebinarListData] = useState([]);

  useEffect(() => {
    const postData = {
      status: 3,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      WebinarList(
        postData,
        (res: any) => {
          setCount(res?.count);
          setWebinarListData(res?.webinarData);
        },
        () => {}
      )
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: 3,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: 3,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: 3,
      };
    }
    dispatch(
      WebinarList(
        data,
        (res: any) => {
          setCount(res?.count);
          setWebinarListData(res?.webinarData);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickLead = (data: any) => {
    navigate("/cancelledWebinarStatusDetails ", {
      state: {
        pageName: "CancelledWebinarList",
        webinarId: data?._id,
        webinarData: data,
      },
    });
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={webinarListData ? webinarListData : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <div>
            <PaginationTable
              tableData={webinarListData ? webinarListData : []}
              pagename={"cancelledWebinar"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Date",
                "Time",
                "Cordinator Name",
                "Cordinator Phone",
                "Exam-Exam Stream Name",
                "webinar Teacher",
                "College Name",
                "State",
                "District Name",
                "Lead generated",
                "Details",
              ]}
              edit={false}
              handleNavigate={onClickLead}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Cancelled Webinar"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12  formWidth">{renderListMode()}</div>
      </div>
    </div>
  );
}
