import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getReferenceListByCounsellorId,
  getReferencesTeamLeadList,
} from "../../Redux/Actions/managerPanelAction";
import moment from "moment";
import { useSelector } from "react-redux";
import SubmitButton from "../../Component/submitButton";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SummeryOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);
  const [counsellorByIdList, setCounsellorByIdList] = useState<any>([]);
  const [totalCount, SetTotalCount] = useState<any>(0);
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );

  const pageName = location.state?.pageName;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;

  const managerId = sessionStorage.getItem("managerId");

  const { loggedInUserRole, loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    if (loggedInUserRole === 1) {
      const postdata = {
        startDate: startDate,
        endDate: endDate,
        teamLeadId: managerId,
      };
      if (pageName === "callerDetails") {
        SetStartDate(leadStartDate);
        SetEndDate(leadEndDate);
        postdata["startDate"] = leadStartDate;
        postdata["endDate"] = leadEndDate;
      }
      dispatch(
        getReferencesTeamLeadList(
          postdata,
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      const postdata = {
        startDate: startDate,
        endDate: endDate,
        teamLeadId: loggedInUserId,
      };
      dispatch(
        getReferencesTeamLeadList(
          postdata,
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    }
  }, []);

  const showGraph = (list: any) => {
    let total = 0;
    setXLabel([]);
    setGraphdata1([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((val: any) => {
      total = total + val?.studentleadCount;
      labelList?.push(val?.name);
      graphDataList?.push(val?.studentleadCount);
    });
    setXLabel(labelList);
    setGraphdata1(graphDataList);
    SetTotalCount(total);
  };

  const showGraph1 = (list: any) => {
    setXLabel([]);
    setGraphdata1([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((val: any) => {
      labelList?.push(val?.name);
      graphDataList?.push(val?.CouncellorCount);
    });
    setXLabel(labelList);
    setGraphdata1(graphDataList);
  };
  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    navigate("../completeLeadDetail", {
      state: {
        // data: data,
        counsellorId: data?.counsellorId,
        pageName: "ReferenceSummeryOverview",
        // startDate,
        // endDate,
      },
    });
  };
  const renderListMode = () => {
    return (
      <div>
        <GenaralTable
          tableData={
            counsellorByIdList[0]?.data ? counsellorByIdList[0]?.data : []
          }
          pagename={"ReferenceSummeryOverview"}
          tableHead={[
            "First Name",
            "Last Name",
            "Exam - Exam Stream",
            // "Source",
            "Enrolled",
            "Revenue",
          ]}
          handleClick={onClickLead}
        />
      </div>
    );
  };
  const getManagerData = (val: any) => {
    if (loggedInUserRole === 1) {
      const postdata = {
        startDate: startDate,
        endDate: endDate,
        type: 2,
      };
      dispatch(
        getReferenceListByCounsellorId(
          teamLeadList[val?.index]?._id,
          postdata,
          (res: any) => {
            if (res?.data?.length > 0) {
              setCounsellorByIdList(res?.data);
            } else {
              setCounsellorByIdList([]);
            }
          }
        )
      );
    } else if (loggedInUserRole === 2) {
      const postdata = {
        startDate: startDate,
        endDate: endDate,
        type: 2,
      };
      dispatch(
        getReferenceListByCounsellorId(
          teamLeadList[val?.index]?._id,
          postdata,
          (res: any) => {
            if (res?.data?.length > 0) {
              setCounsellorByIdList(res?.data);
            } else {
              setCounsellorByIdList([]);
            }
          }
        )
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (loggedInUserRole === 1) {
      dispatch(
        getReferencesTeamLeadList(
          {
            startDate: startDate,
            endDate: endDate,
            teamLeadId: managerId,
          },
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getReferencesTeamLeadList(
          {
            startDate: startDate,
            endDate: endDate,
            teamLeadId: loggedInUserId,
          },
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    }
  };
  return (
    <div className="contentBox">
      {/* <PageTitle name={" "} /> */}
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          <h3 className="fw-bold text-primary">
            References Generated Analysis
          </h3>
          <form className=" d-flex px-5  mx-5" onSubmit={handleSubmit}>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-between mb-3">
              <div className="col-md-6 me-2 mt-3">
                <SubmitButton name={"Search"} />
              </div>
            </div>
          </form>
          <div className="  p-3">
            <h4 className="fw-bold">Overview</h4>
            <span className="fw-bold">Total Count : {totalCount}</span>
            <SalesPanelSatckGraph
              label={xLabel}
              value={Graphdata1}
              getManagerData={(val: any) => getManagerData(val)}
            />
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <span className="fw-bold me-2">Total Leads :</span>
              <span className="me-3">{counsellorByIdList[0]?.totalLeads}</span>
            </div>
            <div className="d-flex">
              <span className="fw-bold me-2">Enrolled Leads :</span>
              <span className="me-3">
                {counsellorByIdList[0]?.enrolledLeads}
              </span>
            </div>
            <div className="d-flex">
              <span className="fw-bold me-2">Total Revenue :</span>
              <span className="me-3">
                {counsellorByIdList[0]?.totalRevenue}
              </span>
            </div>
            <div className="d-flex">
              <span className="fw-bold me-2">Overall Conversion :</span>
              <span className="me-3">
                {counsellorByIdList[0]?.overallConversion}
              </span>
            </div>
          </div>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
