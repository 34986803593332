import React, { FormEvent } from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import localImages from "../../Assets/localImages";
import { BDECrmLogin, CrmLogin, ForgotPassword, UpdatePassword } from "../../Redux/Actions/AuthAction";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
function Login() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [employeeId, setEmployeedId] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  let [hasErrorDiv] = useState("");
  const [type, setType] = useState("CRM");
  const [passwordShown, setPasswordShown] = useState(false);

  const handleVerify = () => {
    if (type === "CRM") {
      const postData = {
        email: email,
      }
      dispatch(
        ForgotPassword(
          postData,
          (res: any) => {
            if(res?.isUserPresent){
              setEmailVerified(true);
              setEmployeedId(res?.user?._id)
            } else {
              setEmailVerified(false);
            }
          },
          () => { }
        )
      );
    }
    else if (type === "BDE") {
      const postData = {
        email: email,
      }
      // dispatch(
      //   BDECrmLogin(
      //     postData,
      //     (res: any) => {
      //       if (res.data.role === 1) {
      //         navigate("../BDEAdminPanel");
      //       }
      //       else if (res.data.role === 4) {
      //         navigate("../BDEPanelDashbord");
      //       }
      //       else if (res.data.role === 2) {
      //         navigate("../BDManagerDashboard");
      //       }

      //     },
      //     () => { }
      //   )
      // );
    }
  };

  const handleSubmit = (event: FormEvent<any>) => {
    event.preventDefault();
    if (type === "CRM") {
      const postData = {
        newPassword: password,
        employeeId
      }
      dispatch(
        UpdatePassword(
          postData,
          (res: any) => {
            navigate("../");
          },
          () => { }
        )
      );
    }
    else if (type === "BDE") {
      const postData = {
        email: email,
      }
      // dispatch(
      //   BDECrmLogin(
      //     postData,
      //     (res: any) => {
      //       if (res.data.role === 1) {
      //         navigate("../BDEAdminPanel");
      //       }
      //       else if (res.data.role === 4) {
      //         navigate("../BDEPanelDashbord");
      //       }
      //       else if (res.data.role === 2) {
      //         navigate("../BDManagerDashboard");
      //       }

      //     },
      //     () => { }
      //   )
      // );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const validation = () => {
    return email === ""
  }
  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  }

  const onChangeType = (value: any) => {
    setType(value);
    setEmail("");
  }
  return (
    <div className="loginBg d-flex">
      <div className="loginContainer">
        <div className="logoDiv">
          <img
            src={localImages.logoCircle}
            className="img-fluid loginlogo "
            alt="logo"
          />
        </div>
        {/* <div className="loginheader py-2 d-flex justify-content-center">
        <img src={localImages.logo} className="img-fluid loginlogo "alt="logo" />
      </div> */}
        <form className="p-5" onSubmit={handleSubmit}>
          <h3 className="mb-5">Forgot Password</h3>
          <div className="radio-button-container mb-3">
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio1"
                name="radio-group"
                checked={type === "CRM" ? true : false}
                onChange={() => setType("CRM")}
              />
              <label
                className="radio-button__label"
                htmlFor="radio1"
                onClick={() => onChangeType("CRM")}
              >
                <span className="radio-button__custom"></span>
                CRM
              </label>
            </div>
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio2"
                name="radio-group"
                onChange={() => setType("BDE")}
                checked={type === "BDE" ? true : false}
              />
              <label
                className="radio-button__label"
                htmlFor="radio2"
                onClick={() => onChangeType("BDE")}
              >
                <span className="radio-button__custom"></span>
                BDE
              </label>
            </div>
          </div>
          <label>
            <input
              id="uname"
              type="text"
              // placeholder="Enter User Name"
              name="username"
              value={email}
              className="form-control"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
              disabled={emailVerified}
            />
            <span>Email</span>
          </label>

          {emailVerified ? (
            <label className="mt-4">
              <input
                id="password"
                type={passwordShown ? "text" : "password"}
                // placeholder="Enter Password"
                name="password"
                value={password}
                className="form-control"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
              <span>Password</span>
              <span
                className="position-absolute pt-2 cursor viewPassword"
                onClick={handleToggle}
              >
                {passwordShown ? (
                  <VisibilityIcon color="action" />
                ) : (
                  <VisibilityOffIcon color="action" />
                )}{" "}
              </span>
            </label>
          ) : null}

          <div className="col-auto d-flex mt-5 justify-content-between">
            {!emailVerified ? (
              <button
                type="button"
                className={`btn btn-primary mb-1 me-1 px-4 btn-primary`}
                disabled={validation()}
                onClick={handleVerify}
              >
                {" "}
                Verify{" "}
              </button>
            ) : (
              <button
                type="submit"
                className={`btn btn-primary mb-1 me-1 px-4 btn-primary`}
                // disabled={validation()}
              >
                {" "}
                Submit{" "}
              </button>
            )}
          </div>

          {hasErrorDiv}
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            />
          </Stack>
        </form>
        {/* <div className="loginheader p-4 "></div> */}
      </div>
    </div>
  );
}

export default Login;
