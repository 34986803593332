import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import CallIcon from "@mui/icons-material/Call";
import CustomDropdown from "../../Component/customDropdown";
import LeadDetailHeader from "../../Component/LeadDetailheader";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";
import MessagePopUp from "../../Component/messagePopUp";
import EmailPopUp from "../../Component/emailPopUp";
import {
  getCallHistory,
  getCountryList,
  getExamList,
  getExamStreamList,
  getLeadPaymentHistory,
  getMainCategoryList,
  getStatesList,
  getStudentDetailsById,
  getStudentLeadScore,
  getSubExam,
  makeCalltoLead,
  updateLeadDetails,
} from "../../Redux/Actions/counsellorPanelAction";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "../../Component/button";
import {
  getStatusList,
  getSubStatusList,
} from "../../Redux/Actions/leadManagementAction";
import DateTime from "../../Component/dateTime";
import SubmitButton from "../../Component/submitButton";
import { useSelector } from "react-redux";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import {
  containsNumber,
  length_Ten,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import { getLeadByMobileNumber } from "../../Redux/Actions/managerPanelAction";
import {
  qualificationList,
  qualificationYearList,
} from "../../DATA/dataConstant";

const initialVal: {
  baseCourseId: string;
  examTypeId: string;
  subExamTypeId: string;
  maincategoryId: string;
  subcategoryId: string;
}[] = [
  {
    baseCourseId: "",
    examTypeId: "",
    subExamTypeId: "",
    maincategoryId: "",
    subcategoryId: "",
  },
];

const initialQualifiedExam: {
  examTypeId: string;
}[] = [
  {
    examTypeId: "",
  },
];

export default function LeadDetail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedInUserId, loggedInUserRole, selectedLeadId } = useSelector(
    (state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserRole: state.Auth.loggedInUserRole,
      selectedLeadId: state.Auth.selectedLeadId,
    })
  );

  const pageName = location.state?.pageName;
  const success = location.state?.success;
  const leadStatus = location.state?.status;
  const leadState = location.state?.state;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;
  const leadType = location.state?.type;
  const leadMobileNo = location.state?.leadMobileNo;

  const [leadScore, setLeadScore] = useState<any>("");
  const [rank, setRank] = useState<any>("");
  const [isSave, setIsSave] = useState(false);
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [educationYear, setEducationYear] = useState<any>("");
  const [qualification, setQualification] = useState<any>("");
  const [college, setCollege] = useState<any>("");
  const [questionAsked, setQuestionAsked] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [statusDetails, setStatusDetails] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [callDate, setCallDate] = useState<any>("");
  const [courseFee, setCourseFee] = useState<any>("");
  const [feeOffered, setFeeOffered] = useState<any>("");
  const [paidFees, setPaidFees] = useState<any>("");
  const [installamentDate, setInstallamentDate] = useState<any>("");
  const [sourceType, setSourceType] = useState<any>("");
  const [referredStudentContactNo, setReferredStudentContactNo] =
    useState<any>("");
  const [referredStudentFirstName, setreferredStudentFirstName] =
    useState<any>("");
  const [referredStudentLastName, setreferredStudentLastName] =
    useState<any>("");
  const [referredStudentLeadId, setreferredStudentLeadId] = useState<any>("");

  const [openPopup, setOpenPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  // const [qualificationList, setQualificationList] = useState([]);
  const [educationYearList, setEducationYearList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [studyMaterialList, setStudyMaterialList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusDetailsList, setStatusDetailsList] = useState([]);
  const [courseExamTypeInfoArr, setCourseExamTypeInfoArr] =
    useState<any>(initialVal);
  const [qualifiedExamArr, setQualifiedExamArr] =
    useState<any>(initialQualifiedExam);
  const [qualifiedExam, setQualifiedExam] = useState<any>([]);

  const [history, setHistory] = useState<any>(1);
  const [resetStatus, setResetStatus] = useState(false);
  const [resetStatusDetails, setResetStatusDetails] = useState(false);
  const [callHistoryData, setCallHistoryData] = useState([]);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [enrolledData, setEnrolledData] = useState([]);
  const [makeCallsList, setMakeCallsList] = useState<any>([]);
  const [leadByMobileNumberList, setLeadByMobileNumberList] = useState<any>([]);

  useEffect(() => {
    if (success) {
      setMakeCallsList([...makeCallsList, success?.call_id]);
      dispatch(showSuccessSnackbar(success?.message) as any);
    }
    dispatch(
      getStudentDetailsById(
        selectedLeadId,
        (res: any) => {
          setFirstName(res?.data[0]?.firstName?.toUpperCase());
          setLastName(res?.data[0]?.lastName?.toUpperCase());
          setContact1(res?.data[0]?.mobileNo);
          setContact2(res?.data[0]?.altMobileNo);
          setSourceType(res?.data[0]?.type);
          if (res?.data[0]?.altMobileNo?.length === 10) {
            setIsSave(true);
          }
          setEmail(res?.data[0]?.email);
          setState(res?.data[0]?.stateId);
          dispatch(
            getStatesList((res: any) => {
              if (res?.data?.length > 0) {
                setStateList(res?.data);
              } else {
                setStateList([]);
              }
            })
          );
          setCity(res?.data[0]?.cityId);
          setQualification(res?.data[0]?.qualification);
          let tempEducationYear: any = qualificationYearList?.filter?.(
            (item: any) => item?._id === parseInt(res?.data[0]?.educationYear)
          );
          setEducationYear(tempEducationYear[0]);
          // setEducationYear(res?.data[0]?.educationYear);
          setCollege(res?.data[0]?.reference?.toUpperCase());
          setQuestionAsked(res?.data[0]?.questionAsked);
          let newArr = res?.data[0]?.courses?.map((item: any) => {
            return {
              baseCourseId: item?.baseCourseId?._id,
              examTypeId: item?.examTypeId?._id,
              subExamTypeId: item?.subExamTypeId?._id,
              maincategoryId: item?.maincategoryId?._id,
              subcategoryId: item?.subcategoryId?._id,
            };
          });
          setCourseExamTypeInfoArr(newArr);
          if (res?.data[0]?.qualifiedForExam?.length > 0) {
            let qualifiedNewArr = res?.data[0]?.qualifiedForExam?.map(
              (item: any) => {
                return {
                  examTypeId: item?.examTypeId?._id,
                };
              }
            );
            setQualifiedExam(res?.data[0]?.qualifiedForExam);
            setQualifiedExamArr(qualifiedNewArr);
          }
          dispatch(
            getExamList((res: any) => {
              if (res?.data?.length > 0) {
                setExamList(res?.data);
              } else {
                setExamList([]);
              }
            })
          );
          setStatus(res?.data[0]?.leadStatusId?._id);
          setStatusDetails(res?.data[0]?.leadSubstatusId?._id);
          dispatch(
            getStatusList(
              "",
              (res: any) => {
                if (loggedInUserRole !== 8 && loggedInUserRole !== 4) {
                  let tempStatus = res?.filter(
                    (item: any) =>
                      item?._id === 4 ||
                      item?._id === 5 ||
                      item?._id === 6 ||
                      item?._id === 7 ||
                      item?._id === 8 ||
                      item?._id === 9 ||
                      item?._id === 10 ||
                      item?._id === 11 ||
                      item?._id === 12 ||
                      item?._id === 21
                  );
                  setStatusList(tempStatus);
                } else if (loggedInUserRole === 8) {
                  let tempStatus = res?.filter(
                    (item: any) =>
                      item?._id === 4 ||
                      item?._id === 5 ||
                      item?._id === 6 ||
                      item?._id === 7 ||
                      item?._id === 8 ||
                      item?._id === 9 ||
                      item?._id === 10 ||
                      item?._id === 11 ||
                      item?._id === 12 ||
                      item?._id === 15 ||
                      item?._id === 16 ||
                      item?._id === 17 ||
                      item?._id === 21
                  );
                  setStatusList(tempStatus);
                } else if (loggedInUserRole === 4) {
                  let tempStatus = res?.filter(
                    (item: any) =>
                      item?._id === 4 ||
                      item?._id === 5 ||
                      item?._id === 6 ||
                      item?._id === 7 ||
                      item?._id === 8 ||
                      item?._id === 9 ||
                      item?._id === 10 ||
                      item?._id === 11 ||
                      item?._id === 12 ||
                      item?._id === 15 ||
                      item?._id === 16 ||
                      item?._id === 17 ||
                      item?._id === 18 ||
                      item?._id === 19 ||
                      item?._id === 20 ||
                      item?._id === 21
                  );
                  setStatusList(tempStatus);
                }
              },
              () => {}
            )
          );
          if (res?.data[0]?.leadStatusId?._id > 3) {
            dispatch(
              getSubStatusList(
                res?.data[0]?.leadStatusId?._id,
                (res: any) => {
                  setStatusDetailsList(res);
                },
                () => {}
              )
            );
          }
          setRemark(res?.data[0]?.comment);
          setCallDate(res?.data[0]?.callBackDate);
          dispatch(
            getCallHistory(
              { studentLeadsId: selectedLeadId },
              (res: any) => {
                setCallHistoryData(res);
                let enrolledList = res?.filter(
                  (item: any) => item?.leadStatus?._id === 5
                );
                setEnrolledData(enrolledList);
              },
              () => {}
            )
          );
          setCourseFee(res?.data[0]?.courseFee);
          setFeeOffered(res?.data[0]?.offeredFee);
          setPaidFees(res?.data[0]?.feePaid);
          setRank(res?.data[0]?.webinarRank);
          setReferredStudentContactNo(res?.data[0]?.referredStudentContactNo);
          setreferredStudentFirstName(res?.data[0]?.referredStudentFirstName);
          setreferredStudentLastName(res?.data[0]?.referredStudentLastName);
          setreferredStudentLeadId(res?.data[0]?.referredStudentLeadId);
        },
        () => {}
      )
    );
    dispatch(
      getStudentLeadScore(
        selectedLeadId,
        (res: any) => {
          setLeadScore(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCountryList(
        val._id,
        (res: any) => {
          setCountryList(res?.data);
        },
        () => {}
      )
    );
  };

  const onPressAdd = () => {
    setCourseExamTypeInfoArr([...courseExamTypeInfoArr, initialVal[0]]);
  };

  const handleArrClose = (index: number) => {
    const newArrr = [...courseExamTypeInfoArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setCourseExamTypeInfoArr(newArrr);
  };

  const onPressAddQualifiedExam = () => {
    setQualifiedExamArr([...qualifiedExamArr, initialQualifiedExam[0]]);
  };

  const handleArrCloseQualifiedExam = (index: number) => {
    const newArrr = [...qualifiedExamArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setQualifiedExamArr(newArrr);
  };

  const onChange = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(courseExamTypeInfoArr));
    if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      if (newArrr[index].subExamTypeId) {
        dispatch(
          getSubExam(
            {
              examTypeId: value._id,
            },
            (res: any) => {
              if (res.length > 0) {
                setSubExamList(res);
              } else {
                dispatch(
                  getExamStreamList(`${value._id}`, (res: any) => {
                    setExamStreamList(res);
                  })
                );
              }
            }
          )
        );
      } else {
        dispatch(
          getExamStreamList(`${value._id}`, (res: any) => {
            setExamStreamList(res);
          })
        );
      }
    } else if (key === "subExam") {
      newArrr[index].subExamTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      dispatch(
        getExamStreamList(
          `${newArrr[index].examTypeId}/${value._id}`,
          (res: any) => {
            setExamStreamList(res);
          }
        )
      );
    } else if (key === "examStream") {
      newArrr[index].baseCourseId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      dispatch(
        getMainCategoryList(
          {
            examTypeId: newArrr[index].examTypeId,
            subExamTypeId: newArrr[index].subExamTypeId,
            baseCourseId: value?._id,
          },
          (res: any) => {
            setCourseTypeList(res);
          },
          () => {}
        )
      );
    } else if (key === "courseType") {
      newArrr[index].maincategoryId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      const subCategoryList = value?.subCategories.filter((item: any) => {
        return item.type === 2;
      });
      setStudyMaterialList(subCategoryList);
    } else if (key === "studyMaterial") {
      newArrr[index].subcategoryId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    }
    if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    }
  };

  const onChangeQualifiedExam = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(qualifiedExamArr));
    if (key === "qualifiedForExam") {
      newArrr[index].examTypeId = value?._id;
      setQualifiedExamArr(newArrr);
    }
  };

  const onChangeQualification = (val: any) => {
    setEducationYear("");
    // setResetStatusDetails(true);
    setQualification(val);
    // setResetStatus(false);
    // setQualificationList([]);
    let tempQualification: any = qualificationYearList?.filter?.(
      (item: any) => item?.qualificationId === val?._id
    );
    setEducationYearList(tempQualification);
    // dispatch(
    //   getSubStatusList(
    //     val?._id,
    //     (res: any) => {
    //       setStatusDetailsList(res);
    //     },
    //     () => { }
    //   )
    // );
  };

  const onChangeStatus = (val: any) => {
    setStatusDetails("");
    // setResetStatusDetails(true);
    setStatus(val);
    setResetStatus(false);
    setStatusDetailsList([]);
    dispatch(
      getSubStatusList(
        val?._id,
        (res: any) => {
          setStatusDetailsList(res);
        },
        () => {}
      )
    );
    if (val?._id === 12) {
      let newTime = moment().add(4, "hours").format("YYYY-MM-DD HH:mm:ss");
      if (moment(newTime).format("HH:mm:ss") > "20:00:00") {
        setCallDate(
          moment(
            moment().add(1, "days").format("YYYY-MM-DD") + " " + "10:00:00"
          ).format("YYYY-MM-DD HH:mm:ss")
        );
      } else if (moment(newTime).format("HH:mm:ss") < "20:00:00") {
        setCallDate(newTime);
      }
    }
    if (val?._id === 21) {
      let newTime = moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss");
      if (moment(newTime).format("HH:mm:ss") > "20:00:00") {
        setCallDate(
          moment(
            moment().add(1, "days").format("YYYY-MM-DD") + " " + "10:00:00"
          ).format("YYYY-MM-DD HH:mm:ss")
        );
      } else if (moment(newTime).format("HH:mm:ss") < "20:00:00") {
        setCallDate(newTime);
      }
    }
  };

  const onChangeStatusDetails = (val: any) => {
    setStatusDetails(val);
    setResetStatusDetails(false);
    if (
      val?._id === 39 ||
      val?._id === 41 ||
      val?._id === 43 ||
      val?._id === 67
    ) {
      setCallDate("");
    }
  };
  const changeRemark = (val: any) => {
    setRemark(val);
  };
  const handleClose = (value: any) => {
    setOpenEmailPopup(false);
    setOpenPopup(false);
  };
  const handleOpenMessagePopup = () => {
    setOpenPopup(true);
  };
  const handleOpenEmailPopup = () => {
    setOpenEmailPopup(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      (status?._id === 4 ||
        status?._id === 7 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11) &&
      (state === "" || state === undefined || state === null)
    ) {
      dispatch(showErrorSnackbar("Please Select State") as any);
    } else if (
      (status?._id === 4 ||
        status?._id === 7 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11) &&
      (qualification === "" ||
        qualification === undefined ||
        qualification === null)
    ) {
      dispatch(showErrorSnackbar("Please Select Qualification") as any);
    }
    // else if
    //      (contact2?.length === 10) {
    //     const postData = {
    //       mobileNo: contact2,
    //     };
    //     dispatch(
    //       getLeadByMobileNumber(
    //         postData,
    //         (res: any) => {
    //           setLeadByMobileNumberList(res);
    //           if (res?.length > 0) {
    //             dispatch(showErrorSnackbar("Lead is Already Present") as any);
    //           }
    //           else {
    //             let postData = {
    //               studentLeadId: selectedLeadId,
    //               firstName: firstName?.toUpperCase(),
    //               lastName: lastName?.toUpperCase(),
    //               altMobileNo: contact2,
    //               email: email,
    //               stateId: state?._id,
    //               stateName: state?.name,
    //               cityId: city?._id,
    //               cityName: city?.name,
    //               educationYear: educationYear?._id,
    //               qualification: qualification?._id,
    //               leadStatusId: status?._id,
    //               leadSubstatusId: statusDetails?._id,
    //               callBackDate: callDate,
    //               courses: courseExamTypeInfoArr,
    //               comment: remark,
    //               reference: college?.toUpperCase(),
    //               // call_id: makeCallsList,
    //               qualifiedForExam: qualifiedExamArr,
    //             };
    //             if (
    //               status?._id === 8 ||
    //               status?._id === 9 ||
    //               status?._id === 10 ||
    //               status?._id === 11 ||
    //               (status?._id === 15 && statusDetails?._id === 41) ||
    //               (status?._id === 16 && statusDetails?._id === 43)
    //             ) {
    //               delete postData?.callBackDate;
    //             }
    //             if (
    //               postData?.lastName === "" ||
    //               postData?.lastName === undefined ||
    //               postData?.lastName === null
    //             ) {
    //               delete postData?.lastName;
    //             }
    //             if (
    //               postData?.altMobileNo === "" ||
    //               postData?.altMobileNo === undefined ||
    //               postData?.altMobileNo === null
    //             ) {
    //               delete postData?.altMobileNo;
    //             }
    //             if (
    //               postData?.email === "" ||
    //               postData?.email === undefined ||
    //               postData?.email === null
    //             ) {
    //               delete postData?.email;
    //             }
    //             if (
    //               postData?.cityId === "" ||
    //               postData?.cityId === undefined ||
    //               postData?.cityId === null
    //             ) {
    //               delete postData?.cityId;
    //             }
    //             if (
    //               postData?.cityName === "" ||
    //               postData?.cityName === undefined ||
    //               postData?.cityName === null
    //             ) {
    //               delete postData?.cityName;
    //             }
    //             if (
    //               postData?.educationYear === "" ||
    //               postData?.educationYear === undefined ||
    //               postData?.educationYear === null
    //             ) {
    //               delete postData?.educationYear;
    //             }
    //             if (
    //               postData?.qualification === "" ||
    //               postData?.qualification === undefined ||
    //               postData?.qualification === null
    //             ) {
    //               delete postData?.qualification;
    //             }
    //             if (
    //               postData?.reference === "" ||
    //               postData?.reference === undefined ||
    //               postData?.reference === null
    //             ) {
    //               delete postData?.reference;
    //             }
    //             if (
    //               postData["qualifiedForExam"][0]["examTypeId"] === "" ||
    //               postData["qualifiedForExam"][0]["examTypeId"] === undefined ||
    //               postData["qualifiedForExam"][0]["examTypeId"] === null
    //             ) {
    //               delete postData["qualifiedForExam"];
    //             }
    //             if (
    //               postData?.comment === "" ||
    //               postData?.comment === undefined ||
    //               postData?.comment === null
    //             ) {
    //               delete postData?.comment;
    //             }
    //             if (
    //               postData?.callBackDate === "" ||
    //               postData?.callBackDate === undefined ||
    //               postData?.callBackDate === null
    //             ) {
    //               delete postData?.callBackDate;
    //             }

    //             dispatch(
    //               updateLeadDetails(
    //                 postData,
    //                 (res: any) => {
    //                   setMakeCallsList([]);
    //                   if (pageName === "newOpportunity") {
    //                     navigate("../CrmCounsellorPanel/newOpportunity", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "reEnquired") {
    //                     navigate("../CrmCounsellorPanel/reEnquired", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "enrolledLead") {
    //                     navigate("../CrmCounsellorPanel/enrolledLead", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "notIntrestedLead") {
    //                     navigate("../CrmCounsellorPanel/notIntrestedLead", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "expired") {
    //                     navigate("../CrmCounsellorPanel/expired", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "cnrLead") {
    //                     navigate("../CrmCounsellorPanel/cnrLead", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "prospectLead") {
    //                     navigate("../CrmCounsellorPanel/prospectLead", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "interested") {
    //                     navigate("../CrmCounsellorPanel/interested", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "callBackLead") {
    //                     navigate("../CrmCounsellorPanel/callBackLead", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "overdueProspect") {
    //                     navigate("../CrmCounsellorPanel/overdueProspect", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "overdueInterested") {
    //                     navigate("../CrmCounsellorPanel/overdueInterested", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "overdueCallback") {
    //                     navigate("../CrmCounsellorPanel/overdueCallback", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "overdueCNR") {
    //                     navigate("../CrmCounsellorPanel/overdueCNR", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "callList") {
    //                     navigate("../CrmCounsellorPanel/callList", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "IncomingCallList") {
    //                     navigate("../CrmCounsellorPanel/incomingCall", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "counsellorDashbord") {
    //                     navigate("../CrmCounsellorPanel/counsellorDashbord", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "leadInvalid") {
    //                     navigate("../CrmCounsellorPanel/leadInvalid", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "alreadyAdmissionDone") {
    //                     navigate("../CrmCounsellorPanel/alreadyAdmissionDone", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "leadSearch") {
    //                     navigate("../CrmCounsellorPanel/leadSearchByMobileNumber", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "leadAssignedStatusOverview") {
    //                     navigate("../CrmCounsellorPanel/leadAssignedStatusOverview", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "finalCNR") {
    //                     navigate("../CrmCounsellorPanel/finalCNR", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "welcomeCallDone") {
    //                     navigate("../CrmCounsellorPanel/welcomeCallDone", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "welcomeCallReference") {
    //                     navigate("../CrmCounsellorPanel/welcomeCallReference", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   } else if (pageName === "followUp") {
    //                     navigate("../CrmCounsellorPanel/followUp", {
    //                       state: {
    //                         pageName: "leadDetail",
    //                       },
    //                     });
    //                   }
    //                 },
    //                 () => {}
    //               )
    //             );
    //           }

    //         },
    //         () => {}
    //       )
    //     );

    //   }
    else {
      let postData = {
        studentLeadId: selectedLeadId,
        firstName: firstName?.toUpperCase(),
        lastName: lastName?.toUpperCase(),
        altMobileNo: contact2,
        email: email,
        stateId: state?._id,
        stateName: state?.name,
        cityId: city?._id,
        cityName: city?.name,
        educationYear: educationYear?._id,
        qualification: qualification?._id,
        leadStatusId: status?._id,
        leadSubstatusId: statusDetails?._id,
        callBackDate: callDate,
        courses: courseExamTypeInfoArr,
        comment: remark,
        reference: college?.toUpperCase(),
        // call_id: makeCallsList,
        qualifiedForExam: qualifiedExamArr,
        type: sourceType,
      };
      if (
        status?._id === 8 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11 ||
        (status?._id === 15 && statusDetails?._id === 41) ||
        (status?._id === 16 && statusDetails?._id === 43)
      ) {
        delete postData?.callBackDate;
      }
      if (
        postData?.lastName === "" ||
        postData?.lastName === undefined ||
        postData?.lastName === null
      ) {
        delete postData?.lastName;
      }
      if (
        postData?.altMobileNo === "" ||
        postData?.altMobileNo === undefined ||
        postData?.altMobileNo === null
      ) {
        delete postData?.altMobileNo;
      }
      if (
        postData?.email === "" ||
        postData?.email === undefined ||
        postData?.email === null
      ) {
        delete postData?.email;
      }
      if (
        postData?.cityId === "" ||
        postData?.cityId === undefined ||
        postData?.cityId === null
      ) {
        delete postData?.cityId;
      }
      if (
        postData?.cityName === "" ||
        postData?.cityName === undefined ||
        postData?.cityName === null
      ) {
        delete postData?.cityName;
      }
      if (
        postData?.educationYear === "" ||
        postData?.educationYear === undefined ||
        postData?.educationYear === null
      ) {
        delete postData?.educationYear;
      }
      if (
        postData?.qualification === "" ||
        postData?.qualification === undefined ||
        postData?.qualification === null
      ) {
        delete postData?.qualification;
      }
      if (
        postData?.reference === "" ||
        postData?.reference === undefined ||
        postData?.reference === null
      ) {
        delete postData?.reference;
      }
      if (
        postData["qualifiedForExam"][0]["examTypeId"] === "" ||
        postData["qualifiedForExam"][0]["examTypeId"] === undefined ||
        postData["qualifiedForExam"][0]["examTypeId"] === null
      ) {
        delete postData["qualifiedForExam"];
      }
      if (
        postData?.comment === "" ||
        postData?.comment === undefined ||
        postData?.comment === null
      ) {
        delete postData?.comment;
      }
      if (
        postData?.callBackDate === "" ||
        postData?.callBackDate === undefined ||
        postData?.callBackDate === null
      ) {
        delete postData?.callBackDate;
      }

      dispatch(
        updateLeadDetails(
          postData,
          (res: any) => {
            setMakeCallsList([]);
            if (pageName === "newOpportunity") {
              navigate("../CrmCounsellorPanel/newOpportunity", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "reEnquired") {
              navigate("../CrmCounsellorPanel/reEnquired", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "enrolledLead") {
              navigate("../CrmCounsellorPanel/enrolledLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "notIntrestedLead") {
              navigate("../CrmCounsellorPanel/notIntrestedLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "expired") {
              navigate("../CrmCounsellorPanel/expired", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "cnrLead") {
              navigate("../CrmCounsellorPanel/cnrLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "prospectLead") {
              navigate("../CrmCounsellorPanel/prospectLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "interested") {
              navigate("../CrmCounsellorPanel/interested", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "callBackLead") {
              navigate("../CrmCounsellorPanel/callBackLead", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueProspect") {
              navigate("../CrmCounsellorPanel/overdueProspect", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueInterested") {
              navigate("../CrmCounsellorPanel/overdueInterested", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueCallback") {
              navigate("../CrmCounsellorPanel/overdueCallback", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueCNR") {
              navigate("../CrmCounsellorPanel/overdueCNR", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "callList") {
              navigate("../CrmCounsellorPanel/callList", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "IncomingCallList") {
              navigate("../CrmCounsellorPanel/incomingCall", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "counsellorDashbord") {
              navigate("../CrmCounsellorPanel/counsellorDashbord", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "leadInvalid") {
              navigate("../CrmCounsellorPanel/leadInvalid", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "alreadyAdmissionDone") {
              navigate("../CrmCounsellorPanel/alreadyAdmissionDone", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "summary") {
              navigate("../CrmCounsellorPanel/summary", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "leadSearch") {
              navigate("../CrmCounsellorPanel/leadSearchByMobileNumber", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "leadAssignedStatusOverview") {
              navigate("../CrmCounsellorPanel/leadAssignedStatusOverview", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "finalCNR") {
              navigate("../CrmCounsellorPanel/finalCNR", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "welcomeCallDone") {
              navigate("../CrmCounsellorPanel/welcomeCallDone", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "welcomeCallReference") {
              navigate("../CrmCounsellorPanel/welcomeCallReference", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "followUp") {
              navigate("../CrmCounsellorPanel/followUp", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "interestedForUpselling") {
              navigate("../CrmCounsellorPanel/interestedForUpselling", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "prospectForUpselling") {
              navigate("../CrmCounsellorPanel/prospectForUpselling", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "technicalIssue") {
              navigate("../CrmCounsellorPanel/technicalIssue", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "userActivityDetailsList") {
              navigate("../CrmCounsellorPanel/userActivityDetails", {
                state: {
                  pageName: "leadDetail",
                  leadMobileNo,
                },
              });
            } else if (pageName === "referenceGeneratedList") {
              navigate("../CrmCounsellorPanel/referenceLeadsList", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "callRejectToday") {
              navigate("../CrmCounsellorPanel/callRejectToday", {
                state: {
                  pageName: "leadDetail",
                },
              });
            } else if (pageName === "overdueCallReject") {
              navigate("../CrmCounsellorPanel/overdueCallReject", {
                state: {
                  pageName: "leadDetail",
                },
              });
            }
          },
          () => {}
        )
      );
    }
  };

  const onClickCall = () => {
    dispatch(
      makeCalltoLead(
        {
          crmEmployeeId: loggedInUserId,
          leadMobileNo: contact1,
        },
        (res: any) => {
          setMakeCallsList([...makeCallsList, res?.success?.call_id]);
          dispatch(showSuccessSnackbar(res?.success?.message) as any);
        },
        () => {}
      )
    );
  };

  const onChangeContact2 = (val: string) => {
    setIsSave(false);
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val);
        if (val?.length === 10) {
          const postData = {
            mobileNo: val,
          };
          dispatch(
            getLeadByMobileNumber(
              postData,
              (res: any) => {
                setLeadByMobileNumberList(res);
                if (res?.length > 0) {
                  setContact2("");
                  dispatch(showErrorSnackbar("Lead is Already Present") as any);
                }
              },
              () => {}
            )
          );
        }
      }
    } else {
      setContact2(val);
    }
  };

  const onHandleContact2 = () => {
    setIsSave(true);

    let postData = {
      studentLeadId: selectedLeadId,
      altMobileNo: contact2,
    };
    if (
      postData?.altMobileNo === "" ||
      postData?.altMobileNo === undefined ||
      postData?.altMobileNo === null
    ) {
      delete postData?.altMobileNo;
    }

    dispatch(
      updateLeadDetails(
        postData,
        (res: any) => {},
        () => {}
      )
    );
  };

  const onClickCall2 = () => {
    dispatch(
      makeCalltoLead(
        {
          crmEmployeeId: loggedInUserId,
          leadMobileNo: contact2,
        },
        (res: any) => {
          setMakeCallsList([...makeCallsList, res?.success?.call_id]);
          dispatch(showSuccessSnackbar(res?.success?.message) as any);
        },
        () => {}
      )
    );
  };

  const onClickHistory = (val: any) => {
    setHistory(val);
    setPaymentHistoryData([]);
    if (val === 1) {
      dispatch(
        getCallHistory(
          { studentLeadsId: selectedLeadId },
          (res: any) => {
            setCallHistoryData(res);
            let enrolledList = res?.filter(
              (item: any) => item?.leadStatus?._id === 5
            );
            setEnrolledData(enrolledList);
          },
          () => {}
        )
      );
    } else if (val === 2) {
      dispatch(
        getLeadPaymentHistory(
          {
            _id: selectedLeadId,
          },
          (res: any) => {
            setPaymentHistoryData(res?.data);
          },
          () => {}
        )
      );
    } else if (val === 3 && referredStudentLeadId) {
      dispatch(
        getLeadPaymentHistory(
          {
            _id: referredStudentLeadId,
          },
          (res: any) => {
            setPaymentHistoryData(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const viewPDF = (val: any) => {
    sessionStorage.setItem("pdfLink", val);
    window.open(
      "/PdfViewer",
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes"
    );
  };

  return (
    <div className="col-md-12">
      <LeadDetailHeader
        handleCall={onClickCall}
        handleWhatsapp={handleOpenMessagePopup}
        page="LeadDetails"
        leadScore={leadScore}
        leadRank={rank}
        enrolledData={enrolledData}
        qualifiedExamArr={qualifiedExam}
        leadStatus={leadStatus}
        leadState={leadState}
        leadType={leadType}
        leadStartDate={leadStartDate}
        leadEndDate={leadEndDate}
        pageName={pageName}
        leadMobileNo={leadMobileNo}
      />

      <div className="row m-0">
        <div className="col-md-12 leaddetails">
          <div className="boxshadow mx-5 my-1">
            <div className="p-1 d-flex justify-content-center  ">
              <form
                onSubmit={handleSubmit}
                className="detailsdiv col-md-9 row d-flex justify-content-center px-2 "
              >
                <div className="col-md-6 col-sm-12 p-2">
                  <div className="tabheader p-2">Personnel & Contact </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="First Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter First Name"
                        value={firstName}
                        onChangeInput={(value: any) =>
                          setFirstName(value?.toUpperCase())
                        }
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Last Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChangeInput={(value: any) =>
                          setLastName(value?.toUpperCase())
                        }
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 1" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Contact 1"
                        value={contact1}
                        onChangeInput={(value: any) => setContact1(value)}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 2" />
                    </div>
                    <div className="d-flex justify-content-between col-7 me-0">
                      <InputField
                        placeholder="Enter Contact 2"
                        value={contact2}
                        onChangeInput={(value: any) => onChangeContact2(value)}
                        disabled={isSave ? true : false}
                        minlength={length_Ten}
                        maxlength={length_Ten}
                      />
                      {!isSave ? (
                        <button
                          type="button"
                          className={
                            contact2?.length === 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleContact2}
                          disabled={
                            contact2?.length > 10 ||
                            leadByMobileNumberList?.length > 0
                              ? true
                              : false
                          }
                        >
                          {" "}
                          <span>Save</span>
                        </button>
                      ) : null}

                      {contact2?.length === 10 && isSave ? (
                        <button
                          type="button"
                          className={
                            contact2?.length === 10
                              ? "btn btn-success me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onClickCall2}
                          // disabled={contact2?.length <= 10 ? false : true}
                          disabled={
                            contact2?.length > 10 ||
                            leadByMobileNumberList?.length > 0
                              ? true
                              : false
                          }
                        >
                          {" "}
                          <span>
                            <CallIcon className="font12" />
                          </span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Email" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Email"
                        value={email}
                        onChangeInput={(value: any) => setEmail(value)}
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="State" />
                    </div>
                    <div className="col-md-7 ps-3">
                      <CustomDropdown
                        lableName="Select State"
                        setInputText={(value: any) => onChangeState(value)}
                        value={state}
                        options={stateList}
                        defaultValue={stateList?.length > 0 ? state : ""}
                        Isrequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="City" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <CustomDropdown
                        lableName="Select City"
                        setInputText={(value: any) => setCity(value)}
                        value={city}
                        options={countryList}
                        defaultValue={countryList?.length > 0 ? city : ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Courses</div>
                  {courseExamTypeInfoArr?.map((item: any, index: number) => {
                    const {
                      baseCourseId,
                      examTypeId,
                      subExamTypeId,
                      maincategoryId,
                      subcategoryId,
                    } = item;
                    return (
                      <div className="position-relative" key={index}>
                        <span className="d-flex justify-content-end removeButton">
                          {courseExamTypeInfoArr.length === 1 ? null : (
                            <HighlightOffIcon
                              className="cursor"
                              color="primary"
                              fontSize="small"
                              onClick={() => handleArrClose(index)}
                            />
                          )}
                        </span>
                        <div className="position-relative">
                          <div className="row d-flex justify-content-between  pt-3">
                            <div className="col-md-4 mt-2">
                              <LabelField lableName="Exam Name" />
                            </div>
                            <div className="col-md-7">
                              <CustomDropdown
                                lableName="Select Exam"
                                setInputText={(value: any) =>
                                  onChange(index, value, "exam")
                                }
                                value={examTypeId}
                                options={examList}
                                defaultValue={
                                  examList?.length > 0 ? examTypeId : ""
                                }
                              />
                            </div>
                          </div>
                          {subExamList?.length > 0 ? (
                            <div className="row d-flex justify-content-between mt-1 ">
                              <div className="col-md-4 mt-2">
                                <LabelField lableName="Sub Exam Name" />
                              </div>
                              <div className="col-md-7 ">
                                <CustomDropdown
                                  lableName="Select Sub Exam Name"
                                  setInputText={(value: any) =>
                                    onChange(index, value, "subExam")
                                  }
                                  value={subExamTypeId}
                                  options={subExamList}
                                  defaultValue={
                                    subExamList?.length > 0 ? subExamTypeId : ""
                                  }
                                  // reset={resetSubexamList}
                                />
                              </div>
                            </div>
                          ) : null}

                          <div className="row d-flex justify-content-between mt-1">
                            <div className="col-md-4 mt-2">
                              <LabelField lableName="Exam Stream" />
                            </div>
                            <div className="col-md-7">
                              <CustomDropdown
                                lableName="Select Exam Stream"
                                setInputText={(value: any) =>
                                  onChange(index, value, "examStream")
                                }
                                value={baseCourseId}
                                options={examStreamList}
                                defaultValue={
                                  examStreamList?.length > 0 ? baseCourseId : ""
                                }
                                // reset={resetExamStream}
                              />
                            </div>
                          </div>

                          <div className="row d-flex justify-content-between mt-1">
                            <div className="col-md-4 mt-2">
                              <LabelField lableName="Course Type" />
                            </div>
                            <div className="col-md-7">
                              <CustomDropdown
                                lableName="Select Course Type"
                                setInputText={(value: any) =>
                                  onChange(index, value, "courseType")
                                }
                                value={maincategoryId}
                                options={courseTypeList}
                                defaultValue={
                                  courseTypeList?.length > 0
                                    ? maincategoryId
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="row d-flex justify-content-between mt-1">
                            <div className="col-md-4 mt-2">
                              <LabelField lableName="Study Material" />
                            </div>
                            <div className="col-md-7">
                              <CustomDropdown
                                lableName="Select Study Material"
                                setInputText={(value: any) =>
                                  onChange(index, value, "studyMaterial")
                                }
                                value={subcategoryId}
                                options={studyMaterialList}
                                defaultValue={
                                  studyMaterialList?.length > 0
                                    ? subcategoryId
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="d-flex justify-content-end">
                    <Button name={"Add Course"} handleClick={onPressAdd} />
                  </div>
                  {/* <div className=" d-flex justify-content-end p-1 px-4 ">
                  <button className="btn btn-primary">Add Course</button>
                </div> */}
                </div>

                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Education & Work</div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Qualification" />
                    </div>
                    {/* <div className="col-md-7">
                      <InputField
                        placeholder="Enter Qualification"
                        value={qualification}
                        onChangeInput={(value: any) =>
                          setQualification(value?.toUpperCase())
                        }
                      // disabled={true}
                      />
                    </div> */}
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Qualification"
                        setInputText={(value: any) =>
                          onChangeQualification(value)
                        }
                        value={qualification}
                        options={qualificationList}
                        Isrequired={true}
                        defaultValue={
                          qualificationList?.length > 0 ? qualification : ""
                        }
                        // reset={resetStatus}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Education Year" />
                    </div>
                    {/* <div className="col-md-7">
                      <InputField
                        placeholder="Enter Education Year"
                        value={educationYear}
                        onChangeInput={(value: any) => setEducationYear(value)}
                      // disabled={true}
                      />
                    </div> */}
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Education Year"
                        setInputText={(value: any) => setEducationYear(value)}
                        value={educationYear}
                        options={educationYearList}
                        // Isrequired={true}
                        defaultValue={
                          educationYearList?.length > 0 ? educationYear : ""
                        }
                        // reset={resetStatus}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="College" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter College Name"
                        value={college}
                        onChangeInput={(value: any) =>
                          setCollege(value?.toUpperCase())
                        }
                        // disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName={questionAsked?.question} />
                    </div>
                    <div className="col-md-7">
                      {/* <InputField
                        placeholder="Enter Question Asked"
                        value={questionAsked?.answer}
                        onChangeInput={(value: any) => setQuestionAsked(value)}
                        disabled={true}
                      /> */}
                      <p>{questionAsked?.answer}</p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    {qualifiedExamArr?.map((item: any, index: number) => {
                      const { examTypeId } = item;
                      return (
                        <div className="position-relative" key={index}>
                          <span className="d-flex justify-content-end removeButton">
                            {qualifiedExamArr.length === 1 ? null : (
                              <HighlightOffIcon
                                className="cursor"
                                color="primary"
                                fontSize="small"
                                onClick={() =>
                                  handleArrCloseQualifiedExam(index)
                                }
                              />
                            )}
                          </span>
                          <div className="position-relative">
                            <div className="row d-flex justify-content-between  pt-3">
                              <div className="col-md-4 mt-2">
                                <LabelField lableName="Already Qualified For Exam" />
                              </div>
                              <div className="col-md-7">
                                <CustomDropdown
                                  lableName="Select Exam"
                                  setInputText={(value: any) =>
                                    onChangeQualifiedExam(
                                      index,
                                      value,
                                      "qualifiedForExam"
                                    )
                                  }
                                  value={examTypeId}
                                  options={examList}
                                  defaultValue={
                                    examList?.length > 0 ? examTypeId : ""
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="d-flex justify-content-end">
                      <Button
                        name={"Add Exam"}
                        handleClick={onPressAddQualifiedExam}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Action & Disposition</div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status" />
                    </div>

                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status"
                        setInputText={(value: any) => onChangeStatus(value)}
                        value={status}
                        options={statusList}
                        Isrequired={true}
                        defaultValue={statusList?.length > 0 ? status : ""}
                        reset={resetStatus}
                        disabled={status?._id === 5 ? true : false}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status Details" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status Details"
                        setInputText={(value: any) =>
                          onChangeStatusDetails(value)
                        }
                        value={statusDetails}
                        options={statusDetailsList}
                        defaultValue={
                          statusDetailsList?.length > 0 ? statusDetails : ""
                        }
                        reset={resetStatusDetails}
                        disabled={status?._id === 5 ? true : false}
                      />
                    </div>
                  </div>

                  {status?._id === 4 ||
                  status?._id === 6 ||
                  status?._id === 7 ||
                  status?._id === 8 ||
                  status?._id === 9 ||
                  status?._id === 11 ||
                  status?._id === 12 ||
                  status?._id === 15 ||
                  status?._id === 16 ||
                  status?._id === 17 ||
                  status?._id === 18 ||
                  status?._id === 19 ||
                  status?._id === 20 ||
                  status?._id === 21 ? (
                    <div className="row d-flex justify-content-between">
                      <textarea
                        className="form-control"
                        placeholder={"Remark"}
                        value={remark}
                        onChange={(e) => changeRemark(e.target.value)}
                      ></textarea>
                    </div>
                  ) : null}

                  {status?._id === 4 ||
                  status?._id === 6 ||
                  status?._id === 7 ||
                  status?._id === 11 ||
                  status?._id === 12 ||
                  status?._id === 21 ||
                  (status?._id === 15 && statusDetails?._id === 42) ||
                  (status?._id === 16 && statusDetails?._id === 44) ||
                  (status?._id === 17 && statusDetails?._id === 45) ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Call Back Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={callDate}
                          setInputText={(value: any) => setCallDate(value)}
                          Isrequired={true}
                          defaultValue={callDate}
                          disabled={
                            status?._id === 12 || status?._id === 21
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 5 ? (
                    <>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Course Fee" />
                        </div>

                        <div className="col-md-7">
                          <InputField
                            placeholder="Course Fee"
                            value={courseFee}
                            onChangeInput={(value: any) => setCourseFee(value)}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between  ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Course Fee Offered" />
                        </div>

                        <div className="col-md-7">
                          <InputField
                            placeholder="Course Fee Offered"
                            value={feeOffered}
                            onChangeInput={(value: any) => setFeeOffered(value)}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Fees Paid" />
                        </div>

                        <div className="col-md-7">
                          <InputField
                            placeholder="Course Fee Offered"
                            value={paidFees}
                            onChangeInput={(value: any) => setPaidFees(value)}
                            disabled={true}
                          />
                        </div>
                      </div>
                      {statusDetails?._id === 25 ? (
                        <div className="row d-flex justify-content-between  ">
                          <div className="col-md-4 mt-2">
                            <LabelField lableName="Installment Date" />
                          </div>
                          <div className="col-md-7">
                            <div className="col-md-8">
                              <DatePicker
                                value={installamentDate}
                                setInputText={(value: string) =>
                                  setInstallamentDate(value)
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  {status?._id === 4 ||
                  status?._id === 6 ||
                  status?._id === 7 ||
                  status?._id === 8 ||
                  status?._id === 9 ||
                  status?._id === 10 ||
                  status?._id === 11 ||
                  status?._id === 12 ||
                  status?._id === 15 ||
                  status?._id === 16 ||
                  status?._id === 17 ||
                  status?._id === 18 ||
                  status?._id === 19 ||
                  status?._id === 20 ||
                  status?._id === 21 ? (
                    <div className=" d-flex justify-content-end  mt-2">
                      <SubmitButton name={"Save"} />
                    </div>
                  ) : null}
                </div>
              </form>
              <div className="col-md-3 p-3 pt-2">
                <div className="d-flex justify-content-between tabheader p-1">
                  <button
                    className={`btn btn-sm me-1 ${
                      history === 1 ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => onClickHistory(1)}
                  >
                    Call History
                  </button>
                  <button
                    className={`btn btn-sm me-1 ${
                      history === 2 ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => onClickHistory(2)}
                  >
                    Payment History
                  </button>
                  <button
                    className={`btn btn-sm me-1 ${
                      history === 3 ? " btn-success" : " btn-secondary"
                    }`}
                    onClick={() => onClickHistory(3)}
                  >
                    Reference History
                  </button>
                </div>
                <div className="historydetails">
                  {history === 1 ? (
                    callHistoryData?.map((value: any) => {
                      return (
                        <div className="my-2">
                          {value?.callBackDate ? (
                            <p className=" ps-4 fw-bold    mb-0 text-primary">
                              Call Back Date :{" "}
                              {moment(value?.callBackDate)
                                .utc()
                                .format("DD-MM-YYYY hh:mm:ss a")}
                            </p>
                          ) : null}
                          {value?.call_date ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Called Date & Time :{" "}
                                {moment(value?.call_date).format("DD-MM-YYYY")}{" "}
                                {value?.call_time}
                              </span>
                            </div>
                          ) : null}
                          {value?.call_date ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Call Type : {value?.calltype}
                              </span>
                            </div>
                          ) : null}
                          {value?.call_duration ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Call Duration : {value?.call_duration}
                              </span>
                            </div>
                          ) : null}
                          {value?.call_transfer_status ? (
                            <div className="d-flex justify-content-between">
                              <span className="ps-4 fw-bold mb-0 text-secondary">
                                Call Status : {value?.call_transfer_status}
                              </span>
                            </div>
                          ) : null}

                          <p className=" ps-4  mb-0 text-success">
                            {value?.courses[0]?.baseCourseName}
                          </p>
                          <p className=" ps-4 mb-0 text-danger">
                            {value?.courses[0]?.examTypeName}
                          </p>
                          <p className=" ps-4  mb-0 text-danger">-----</p>
                          <p className=" ps-4 mb-0 text-primary ">
                            Status : {value?.leadStatus?.status}
                          </p>
                          {value?.courses[0]?.mainCategoryName ? (
                            <p className=" ps-4 mb-0  ">
                              Course Type :{" "}
                              {value?.courses[0]?.mainCategoryName}
                            </p>
                          ) : null}
                          <p className=" ps-4 mb-0 text-success">
                            Status Details : {value?.leadSubStatus?.status}
                          </p>
                          {value?.couponName ? (
                            <p className=" ps-4 mb-0  ">
                              Coupon Code :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.couponName}
                              </span>
                            </p>
                          ) : null}
                          <div className="d-flex justify-content-center">
                            {value?.callRecords ? (
                              value?.callRecords?.map((item: any) => {
                                return (
                                  <audio
                                    className="ps-4 mb-0"
                                    src={item?.rURL}
                                    controls
                                  ></audio>
                                );
                              })
                            ) : value?.call_recording &&
                              value?.call_recording !== "None" ? (
                              <audio
                                className="ps-4 mb-0"
                                src={value?.call_recording}
                                controls
                              ></audio>
                            ) : null}
                          </div>
                          {value?.comment ? (
                            <p className=" ps-4 mb-0  ">
                              Comment :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.comment}
                              </span>
                            </p>
                          ) : null}
                          <hr />
                        </div>
                      );
                    })
                  ) : history === 2 ? (
                    paymentHistoryData?.map((value: any) => {
                      return (
                        <div className="my-2">
                          {value?.course ? (
                            <p className=" ps-4 fw-bold    mb-0 text-primary">
                              Course : {value?.course?.name}
                            </p>
                          ) : null}
                          {value?.orderDate ? (
                            <p className=" ps-4 fw-bold    mb-0 text-secondary">
                              Order Date :{" "}
                              {moment(value?.orderDate).format(
                                "DD-MM-YYYY hh:mm:ss a"
                              )}
                            </p>
                          ) : null}
                          {value?.netPayableAmount ? (
                            <p className=" ps-4 mb-0  ">
                              Fees Paid :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.netPayableAmount}
                              </span>
                            </p>
                          ) : null}
                          {value?.couponName ? (
                            <p className=" ps-4 mb-0  ">
                              Coupon Code :{" "}
                              <span className=" mb-0  text-secondary">
                                {value?.couponName}
                              </span>
                            </p>
                          ) : null}
                          <p className=" ps-4  mb-0 text-danger">-----</p>
                          {value?.subscriptionPlanMonths ? (
                            <p className=" ps-4 mb-0  ">
                              Subscription Months :{" "}
                              {value?.subscriptionPlanMonths}
                            </p>
                          ) : null}
                          {/* <p className=" ps-4 mb-0 text-success">
                              Status Details : {value?.leadSubStatus?.status}
                            </p> */}

                          {value?.userSubscriptionEndDate ? (
                            <p className=" ps-4 fw-bold    mb-0 text-secondary">
                              Course End Date :{" "}
                              {moment(value?.userSubscriptionEndDate).format(
                                "DD-MM-YYYY hh:mm:ss a"
                              )}
                            </p>
                          ) : null}
                          <hr />
                        </div>
                      );
                    })
                  ) : referredStudentContactNo ? (
                    <div>
                      {referredStudentFirstName ? (
                        <span className="fw-bold mt-2 text-dark">
                          Student Name :{" "}
                          {`${referredStudentFirstName} ${referredStudentLastName}`}
                        </span>
                      ) : null}
                      {referredStudentContactNo ? (
                        <p className="fw-bold mt-0 text-dark">
                          Mobile No : {referredStudentContactNo}
                        </p>
                      ) : null}
                      <hr />
                      {paymentHistoryData?.map((value: any) => {
                        return (
                          <div className="my-2">
                            {value?.course ? (
                              <p className=" ps-4 fw-bold    mb-0 text-primary">
                                Course : {value?.course?.name}
                              </p>
                            ) : null}
                            <p className=" ps-4  mb-0 text-danger">-----</p>
                            {value?.subscriptionPlanMonths ? (
                              <p className=" ps-4 mb-0  ">
                                Subscription Months :{" "}
                                {value?.subscriptionPlanMonths}
                              </p>
                            ) : null}

                            {value?.userSubscriptionEndDate ? (
                              <p className=" ps-4 fw-bold    mb-0 text-secondary">
                                Course End Date :{" "}
                                {moment(value?.userSubscriptionEndDate).format(
                                  "DD-MM-YYYY hh:mm:ss a"
                                )}
                              </p>
                            ) : null}
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openPopup ? (
        <MessagePopUp open={openPopup} handleClose={handleClose} />
      ) : (
        ""
      )}
      {openEmailPopup ? (
        <EmailPopUp open={openEmailPopup} handleClose={handleClose} />
      ) : (
        ""
      )}
    </div>
  );
}
