import "../../App.css";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../Module/sidebar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Component/loading";
import { BDEPanel } from "../../DATA/bDEPanel";
import Header from "../../Component/header";
import BDEDashboard from "./bdeDashboard";
import NotIntrestedBDELead from "./notIntrestedBDELead";
import IntrestedBDELead from "./intrestedBDELead";
import CallBackBDELead from "./callBackBDELead";
import OverdueInterestedBDELead from "./overdueInterestedBDELead";
import CollegeList from "./collegeList";
import CollegeDetails from "./collegeDetails";
import UpcomingWebinar from "./upcomingWebinar";
import CompleteWebinar from "./completeWebinar";
import CancelledWebinar from "./cancelledWebinar";
import PendingWebinar from "./pendingWebinar";
import CollegeDepartment from "./collegeDepartment";
import TargetStatus from "./targetStatus";
import LeadAnalysis from "./leadAnalysis";
import DepartmentwiseleadAnalysis from "./departmentwiseleadAnalysis";
import OverdueCallBackBDELead from "./overdueCallBackBDELead";
import ManagerRemark from "./managerRemark";
import ManagerRemarkDetails from "../BDTeamLead/managerRemarkDetails";
import ResultCompilation from "./resultCompilation";

function BDEPanelDashbord(Props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split("/");
  let pageName = currentURLarr[currentURLarr.length - 1];

  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={BDEPanel} pagename />
      <div className="dashboard">
     
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<BDEDashboard />} />
              <Route path="bdeDashboard" element={<BDEDashboard />} />
              <Route
                path="notIntrestedBDELead"
                element={<NotIntrestedBDELead />}
              />
              <Route path="intrestedBDELead" element={<IntrestedBDELead />} />
              <Route path="callBackBDELead" element={<CallBackBDELead />} />
              <Route
                path="overdueInterestedBDELead"
                element={<OverdueInterestedBDELead />}
              />
              <Route
                path="overduecallBackBDELead"
                element={<OverdueCallBackBDELead />}
              />
              <Route path="collegeList" element={<CollegeList />} />
              <Route path="collegeDetails" element={<CollegeDetails />} />
              <Route path="upcomingWebinar" element={<UpcomingWebinar />} />
              <Route path="completeWebinar" element={<CompleteWebinar />} />
              <Route path="cancelledWebinar" element={<CancelledWebinar />} />
              <Route path="pendingWebinar" element={<PendingWebinar />} />
              <Route path="collegeDepartment" element={<CollegeDepartment />} />
              <Route path="targetStatus" element={<TargetStatus />} />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route
                path="departmentwiseleadAnalysis"
                element={<DepartmentwiseleadAnalysis />}
              />
               <Route path="managerRemark" element={<ManagerRemark/>} />
               <Route path="managerRemarkDetails" element={<ManagerRemarkDetails/>} />
            <Route path="resultCompilation" element={<ResultCompilation />} /> 
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default BDEPanelDashbord;
