import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import { useLocation } from "react-router-dom";
import CustomDropdown from "../../Component/customDropdown";
import {
  getOverdueStudentLeadDetails,
  getStudentLeadDetails,
} from "../../Redux/Actions/managerPanelAction";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import {
  getSourceTypeList,
  getStatusList,
  getSubStatusList,
} from "../../Redux/Actions/leadManagementAction";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import { useSelector } from "react-redux";

export default function CounsellorCallDetails() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedInUserName } = useSelector((state: any) => ({
    loggedInUserName: state.Auth.loggedInUserName,
  }));

  const [analysis, setAnalysis] = useState<any>("");
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [subStatus, setSubStatus] = useState<any>("");
  const [source, setSource] = useState<any>("");

  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState<any>([]);
  const [sourceList, setSourceList] = useState([]);
  const [studentList, setstudentList] = useState<any>([]);

  const [resetStatus, setResetStatus] = useState<any>(false);
  const [resetSubStatus, setResetSubStatus] = useState<any>(false);
  const [resetSource, setResetSource] = useState<any>(false);

  const counsellorData = location.state?.data;
  const pageName = location.state?.pageName;
  const leadAnalysis = location.state?.analysis;
  const leadStatus = location.state?.status;
  const leadSource = location.state?.source;
  const leadStartDate = location.state?.sdate;
  const leadEndDate = location.state?.edate;
  const leadPage = location.state?.page;
  const leadRowsPerPage = location.state?.rowsPerPage;

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    setCount(0);
    setstudentList([]);
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        counsellorId: counsellorData?.counsellorId,
        statusId: status?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        counsellorId: counsellorData?.counsellorId,
        statusId: status?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        counsellorId: counsellorData?.counsellorId,
        statusId: status?._id,
      };
    }
    if (startDate) {
      data["startDate"] = startDate;
    }
    if (endDate) {
      data["endDate"] = endDate?._id;
    }
    if (source) {
      data["type"] = source?._id;
    }
    if (subStatus) {
      data["subStatus"] = subStatus?._id;
    }
    if (analysis === "ALL") {
      dispatch(
        getStudentLeadDetails(
          data,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    } else if (analysis === "OVERDUE") {
      dispatch(
        getOverdueStudentLeadDetails(
          data,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    dispatch(
      getStatusList(
        "",
        (res: any) => {
          setStatusList(res);
          let tempStatus = res?.filter?.(
            (item: any) => item?._id === leadStatus
          );
          setStatus(tempStatus[0]);
          setAnalysis(leadAnalysis);
          let PostData: any = {
            skip: 0,
            limit: rowsPerPage,
            // counsellorId: counsellorData?.counsellorId,
          };
          if (counsellorData) {
            PostData["counsellorId"] = counsellorData?.counsellorId;
          }
          if (pageName === "ManagerLeadAnalysis") {
            PostData["statusId"] = leadStatus;
            dispatch(
              getSubStatusList(
                leadStatus,
                (res: any) => {
                  setSubStatusList(res);
                },
                () => {}
              )
            );
          } else if (pageName === "callerDetails") {
            setStatus(leadStatus);
            setSource(leadSource);
            SetStartDate(leadStartDate);
            SetEndDate(leadEndDate);
            setRowsPerPage(leadRowsPerPage);
            setPage(leadPage);
            setCurrentPage(leadPage + 1);
            PostData["statusId"] = leadStatus?._id;
            PostData["source"] = leadSource?._id;
            PostData["startDate"] = leadStartDate;
            PostData["endDate"] = leadEndDate;
            PostData["skip"] = leadPage;
            PostData["limit"] = leadRowsPerPage;
          }
          if (leadAnalysis === "ALL") {
            dispatch(
              getStudentLeadDetails(
                PostData,
                (res: any) => {
                  setCount(res?.count);
                  setstudentList(res?.data);
                },
                () => {}
              )
            );
          } else if (leadAnalysis === "OVERDUE") {
            dispatch(
              getOverdueStudentLeadDetails(
                PostData,
                (res: any) => {
                  setCount(res?.count);
                  setstudentList(res?.data);
                },
                () => {}
              )
            );
          }
        },
        () => {}
      )
    );
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
      })
    );
  }, []);

  const onChangeStatus = (val: any) => {
    setStatus(val);
    setResetStatus(false);
    setCount(0);
    setstudentList([]);
    dispatch(
      getSubStatusList(
        val?._id,
        (res: any) => {
          setSubStatusList(res);
        },
        () => {}
      )
    );
    const PostData: any = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: counsellorData?.counsellorId,
      statusId: val?._id,
    };
    if (source) {
      PostData["type"] = source?._id;
    }
    if (startDate) {
      PostData["startDate"] = startDate;
    }
    if (endDate) {
      PostData["endDate"] = endDate;
    }
    if (analysis === "ALL") {
      dispatch(
        getStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    } else if (analysis === "OVERDUE") {
      dispatch(
        getOverdueStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    }
  };
  const onChangeSubStatus = (val: any) => {
    setSubStatus(val);
    setResetSubStatus(false);
    setCount(0);
    setstudentList([]);
    const PostData: any = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: counsellorData?.counsellorId,
      statusId: status?._id,
      subStatus: val?._id,
    };
    if (source) {
      PostData["type"] = source?._id;
    }
    if (startDate) {
      PostData["startDate"] = startDate;
    }
    if (endDate) {
      PostData["endDate"] = endDate;
    }
    if (subStatus) {
      PostData["subStatus"] = subStatus?._id;
    }
    if (analysis === "ALL") {
      dispatch(
        getStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    } else if (analysis === "OVERDUE") {
      dispatch(
        getOverdueStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const onChangeSource = (val: any) => {
    setSource(val);
    setResetSource(false);
    const PostData: any = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: counsellorData?.counsellorId,
      type: val?._id,
    };
    if (status) {
      PostData["statusId"] = status?._id;
    }
    if (startDate) {
      PostData["startDate"] = startDate;
    }
    if (endDate) {
      PostData["endDate"] = endDate;
    }
    if (subStatus) {
      PostData["subStatus"] = subStatus?._id;
    }
    if (analysis === "ALL") {
      dispatch(
        getStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    } else if (analysis === "OVERDUE") {
      dispatch(
        getOverdueStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    const PostData: any = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: counsellorData?.counsellorId,
      startDate: startDate,
      endDate: val,
    };
    if (status) {
      PostData["statusId"] = status?._id;
    }
    if (source) {
      PostData["type"] = source?._id;
    }
    if (subStatus) {
      PostData["subStatus"] = subStatus?._id;
    }
    if (analysis === "ALL") {
      dispatch(
        getStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    } else if (analysis === "OVERDUE") {
      dispatch(
        getOverdueStudentLeadDetails(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    navigate("../completeLeadDetail", {
      state: {
        // data: data ,
        counsellorId: counsellorData,
        pageName: "ManagerLeadAnalysis",
        status,
        source,
        // startDate,
        // endDate,
        analysis,
        page,
        rowsPerPage,
      },
    });
  };

  const renderSearchMode = () => {
    return (
      <div className="col-md-12 row d-flex pt-4">
        <div className="col-md-3 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9  ">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9 ">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => onChangeEndDate(value)}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Status" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Status"
              setInputText={(value: any) => onChangeStatus(value)}
              value={status}
              options={statusList}
              // Isrequired={true}
              reset={resetStatus}
              defaultValue={status?._id}
            />
          </div>
        </div>
        {status?._id === 12 ? (
          <div className="col-md-3 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Sub Status" />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Sub Status"
                setInputText={(value: any) => onChangeSubStatus(value)}
                value={subStatus}
                options={subStatusList}
                reset={resetSubStatus}
                defaultValue={subStatus?._id}
              />
            </div>
          </div>
        ) : null}

        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Source" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Source"
              setInputText={(value: any) => onChangeSource(value)}
              value={source}
              options={sourceList}
              // Isrequired={true}
              reset={resetSource}
              defaultValue={source?._id}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {studentList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"Counsellor_call_Details"}
            count={count}
            tableData={studentList ? studentList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={studentList ? studentList : []}
          pagename={"Counsellor_call_Details"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S. No.",
            "First Name",
            "Last Name",
            // "Phone No ",
            "State",
            "Course",
            "Status",
            "Source",
            "Remark",
            status?._id === 2 || status?._id === 3
              ? "Allocated Date"
              : "Call Back Date",
            // "Register Date",
          ]}
          handleClick={onClickLead}
        />
      </>
    );
  };

  return (
    <div className="contentBox">
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          <h3 className="fw-bold fontcolor">
            {counsellorData?.firstName
              ? counsellorData?.firstName + " " + counsellorData?.lastName
              : loggedInUserName}
            {}
          </h3>
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
