import "../../App.css";
import React, { Suspense } from "react"; 
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import { SuperAdminSideBar } from "../../DATA/superAdminSideBar";
import Loading from "../../Component/loading";
import Header from "../../Component/header"; 
 
import SuperAdminDashbord from "./superAdminDashboard";
import TeamLeadOverview from "./teamLeadOverview";
import TeamLeadOverViewDetails from "./teamLeadOverViewDetails"; 
import AssignedLeadDateAnalysis from "./assignedLeadDateAnalysis";
import LeadStatusOverview from "./leadStatusOverview";
import AssignedLeadDetails from "./assignedLeadDetails"; 
import SALeadAnalysis from "./sALeadAnalysis";
import SAEnrolledAnalysis from "./sAEnrollAnalysis";
import SACourseAnalysis from "./saCourseAnalysis";
import SAReferenceAnalysisManager from "./saReferenceanalysisManager";
import SAReferenceAnalysis from "./saReferenceAnalysis";
import LeadAllotedBydate from "./leadAllotedBydate";
import SALeadByAdmissionDate from "./saLeadByAdmissionDate";
import SuperManagerExamRevenue from "./smExamRevenue";
import SMTargetStatus from "./smTargetStatus";
import SACouponCode from "./sACouponCode";
import SAActiveEmployee from "./saActiveEmployee";
import SAAttendance from "./saAttendance";
import SAMonthlyReport from "./saMonthlyReport";
import DailyMeeting from "./dailyMeeting";
import SMLeadAssignedStatusDetails from "./sMLeadAssignedStatusDetails";
import SMCompleteLeadDetail from "./sMCompleteLeadDetails";
import SMSkipDetails from "./sMSkipDetails";
import SMIncentiveAllocation from "./sMIncentiveAllocation";
import LeadAllottedAnalysisbyDate from "./leadAllottedAnalysisbyDate";
import TimeAnalysisSuperManager from "./timeAnalysisSuperManager";
import RevenueAnalysisSuperManager from "./revenueAnalysisSuperManager";
import SMSummeryOverview from "./sMSummeryOverview";
import SMCompleteDetails from "./sMCompleteDetails";
import CounsellorCallDetails from "../CRMTeamLeadPanel/counsellorCallDetails";
import LeadDetail from "../CRMTeamLeadPanel/completeLeadDetail";
function SuperAdminPanelDashboard(Props:any) {
  return (
    <div className="AppBox">
      {/* <Header/>    */}
      <Sidebar data={SuperAdminSideBar } pagename/>
      <div className="dashboard">
      
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
               <Route path="/" element={<SuperAdminDashbord/>} />   
               <Route path="superAdminDashbord" element={<SuperAdminDashbord/>} />
               <Route path="teamLeadOverview" element={<TeamLeadOverview/>} />
               <Route path="teamLeadOverViewDetails" element={<TeamLeadOverViewDetails/>} />
               <Route path="assignedLeadDateAnalysis" element={<AssignedLeadDateAnalysis/>} />
               <Route path="leadStatusOverview" element={<LeadStatusOverview/>} />
               <Route path="assignedLeadDetails" element={<AssignedLeadDetails/>} />
               <Route path="sALeadAnalysis" element={<SALeadAnalysis/>} />
               <Route path="sAEnrollAnalysis" element={<SAEnrolledAnalysis/>} />
               <Route path="saCourseAnalysis" element={<SACourseAnalysis/>} />
               <Route path="saReferenceanalysisManager" element={<SAReferenceAnalysisManager/>} />
               <Route path="saReferenceAnalysis" element={<SAReferenceAnalysis/>} />
               <Route path="leadAllotedBydate" element={<LeadAllotedBydate/>} />
               <Route path="saLeadByAdmissionDate" element={<SALeadByAdmissionDate/>} />
               <Route path="smExamRevenue" element={<SuperManagerExamRevenue/>} />
               <Route path="smTargetStatus" element={<SMTargetStatus/>} />
               <Route path="sACouponCode" element={<SACouponCode/>} />
               <Route path="saActiveEmployee" element={<SAActiveEmployee/>} />
               <Route path="saAttendance" element={<SAAttendance/>} />
               <Route path="saMonthlyReport" element={<SAMonthlyReport/>} />
               <Route path="dailyMeeting" element={<DailyMeeting/>} />
               <Route path="sMLeadAssignedStatusDetails" element={<SMLeadAssignedStatusDetails/>} />
               <Route path="sMCompleteLeadDetail" element={<SMCompleteLeadDetail/>} />
               <Route path="sMSkipDetails" element={<SMSkipDetails/>} />
               <Route path="sMIncentiveAllocation" element={<SMIncentiveAllocation/>} />
               <Route path="leadAllottedAnalysisbyDate" element={<LeadAllottedAnalysisbyDate/>} />
               <Route path="timeAnalysisSuperManager" element={<TimeAnalysisSuperManager/>} />
               <Route path="revenueAnalysisSuperManager" element={<RevenueAnalysisSuperManager/>} />
               <Route path="sMSummeryOverview" element={<SMSummeryOverview/>} />
               <Route path="sMCompleteDetails" element={<SMCompleteDetails/>} />
               <Route path="counsellorCallDetails" element={<CounsellorCallDetails/>} />
               <Route path="completeLeadDetail" element={<LeadDetail/>} />

               
            </Routes>
          </Suspense>
        }
      </div>
       
    </div>
  );
}

export default  SuperAdminPanelDashboard  
  ;
