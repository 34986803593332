import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import Header from "../../Component/header";
import LabelField from "../../Component/labelField";
import InputField from "../../Component/inputField";
import ReplyIcon from "@mui/icons-material/Reply";


export default function WebinarDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const webinarId = location.state?.webinarId;
  const webinarData = location.state?.webinarData;

  const [status, setStatus] = useState<any>("Upcoming");

  return (
    <div className="col-md-12 maindiv">
       <div className="mt-2 ">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => navigate(-1)}
        >
          <ReplyIcon /> Back
        </button>

      </div>
      <div className="row m-0">
        <div className="col-md-12  font17 font10 p-5 pt-3 ">
          <div className="mx-5 detailsdiv boxshadow p-3">
            <div className="row d-flex justify-content-between font14 ">
              {/* ************************ Co-Ordinator Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Co-Ordinator</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Name:</div>
                  <div className="col-md-8">{webinarData?.coordinatorName}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Phone :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorPhone}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Email :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorEmail}
                  </div>
                </div>
              </div>
              {/* ************************Webinar Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Webinar Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Date :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Time :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("hh:mm:ss a")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4 text-break">Link :</div>
                  {/* <div className='col-md-8'>{webinarData?.meetingURL}</div> */}
                  <div className="col-md-8 text-break">
                    <a
                      href={webinarData?.meetingURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {webinarData?.meetingURL}
                    </a>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Faculty :</div>
                  <div className="col-md-8">
                    {webinarData?.teacher_data?.name
                      ? webinarData?.teacher_data?.name
                      : "-"}
                  </div>
                </div>
              </div>

              {/* ************************Collage Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Collage Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Collage :</div>
                  <div className="col-md-8">
                    {webinarData?.college_data?.collegeName}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">State :</div>
                  <div className="col-md-8">
                    {webinarData?.state_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">City :</div>
                  <div className="col-md-8">{webinarData?.city_data?.name}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Exam :</div>
                  <div className="col-md-8">
                    {webinarData?.examType_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Department :</div>
                  <div className="col-md-8 ">
                    {webinarData?.department_data?.departmentName}
                  </div>
                </div>
              </div>

              {/* *********************************Lead Details *************************************** */}

              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Lead Details:</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Lead Registered :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.TotalCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">First Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.FirstYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Second Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.SecondYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Third Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.ThirdYearCount}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-top border-3 border-dark">
              <h5 className="text-primary fw-bold pt-3">Webinar Status</h5>
            </div>
            {/* *************************************************Status****************************************************** */}
            <div className="col-md-12 mt-4 font14">
              <div className="col-md-9 mt-4">
                <div className="col-md-6 row d-flex justify-content-between mt-1">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Status" />
                  </div>
                  <div className="col-md-7  ps-3">
                    <InputField
                      placeholder="Status"
                      value={status}
                      onChangeInput={(value: any) => setStatus(value)}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
