import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ReplyIcon from "@mui/icons-material/Reply";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getCallHistory,
  getStudentDetailsById,
} from "../../Redux/Actions/counsellorPanelAction";
import moment from "moment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Height } from "@material-ui/icons";
import { addManagerRemark } from "../../Redux/Actions/managerPanelAction";
import RestorePopup from "../../Component/restorePopup";
import VisibilityIcon from "@mui/icons-material/Visibility";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function LeadCompleteDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  const counsellorId = location.state?.counsellorId;
  const managerId = location.state?.managerId;
  const pageName = location.state?.pageName;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;
  const leadAnalysis = location.state?.analysis;
  const leadPage = location.state?.page;
  const leadRowsPerPage = location.state?.rowsPerPage;

  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [id, setId] = useState<any>("");
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [educationYear, setEducationYear] = useState<any>("");
  const [qualification, setQualification] = useState<any>("");
  const [college, setCollege] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [statusDetails, setStatusDetails] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [teamLeadRemark, setTeamLeadRemark] = useState<any>("");
  const [callDate, setCallDate] = useState("");
  const [courseFee, setCourseFee] = useState<any>("");
  const [feeOffered, setFeeOffered] = useState<any>("");
  const [paidFees, setPaidFees] = useState<any>("");
  const [installamentDate, setInstallamentDate] = useState<any>("");
  const [course, setcourse] = useState<any>([]);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [studyMaterialList, setStudyMaterialList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusDetailsList, setStatusDetailsList] = useState([]);

  const [callHistoryData, setCallHistoryData] = useState([]);

  useEffect(() => {
    dispatch(
      getStudentDetailsById(
        data?._id,
        (res: any) => {
          setFirstName(res?.data[0]?.firstName);
          setLastName(res?.data[0]?.lastName);
          setContact1(res?.data[0]?.mobileNo);
          setContact2(res?.data[0]?.altMobileNo);
          setEmail(res?.data[0]?.email);
          setState(res?.data[0]?.stateName);

          setCity(res?.data[0]?.cityId);
          setEducationYear(res?.data[0]?.educationYear);
          setQualification(res?.data[0]?.qualification);
          setCollege(res?.data[0]?.reference);
          let newArr = res?.data[0]?.courses?.map((item: any) => {
            return {
              baseCourseId: item?.baseCourseId?.name,
              examTypeId: item?.examTypeId?.name,
              subExamTypeId: item?.subExamTypeId?.name,
              maincategoryId: item?.maincategoryId?.name,
              subcategoryId: item?.subcategoryId?.name,
            };
          });
          setcourse(newArr);
          setStatus(res?.data[0]?.leadStatusId?.status);
          setStatusDetails(res?.data[0]?.leadSubstatusId?.status);

          setRemark(res?.data[0]?.comment);
          setCallDate(res?.data[0]?.callBackDate);
          dispatch(
            getCallHistory(
              { studentLeadsId: data?._id, counsellorId },
              (res: any) => {
                setCallHistoryData(res);
              },
              () => {}
            )
          );
          setCourseFee(res?.data[0]?.courseFee);
          setFeeOffered(res?.data[0]?.offeredFee);
          setPaidFees(res?.data[0]?.feePaid);
        },
        () => {}
      )
    );
  }, []);

  const renderListMode = () => {
    return (
      <div className="mt-3">
        <table className=" w-100 font17">
          <thead>
            <tr>
              <th>Date</th>
              <th>Course</th>
              <th>Sub Course</th>
              <th>Course Type</th>
              <th>Status</th>
              <th>Status Details</th>
              <th>Comment</th>
              <th>Counsellor Name</th>
              <th>Coupon Name</th>
              <th>Recording</th>
              <th>Manager Comment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {callHistoryData?.map((item: any, index) => (
              <tr>
                <td>{moment(item?.createdAt).format("DD - MM - YYYY")}</td>
                <td>{item?.courses ? item?.courses[0]?.baseCourseName : ""}</td>
                <td>{item?.courses ? item?.courses[0]?.examTypeName : ""}</td>
                <td>
                  {item?.courses ? item?.courses[0]?.mainCategoryName : ""}
                </td>
                <td>{item?.leadStatus?.status}</td>
                <td>{item?.leadSubStatus?.status}</td>
                <td>{item?.comment}</td>
                <td>{item?.counsellor?.name}</td>
                <td>{item?.couponName}</td>
                <td>
                  {item?.callRecords ? (
                    item?.callRecords?.map((subItem: any) => {
                      return (
                        <audio
                          className="ps-4 mb-0"
                          src={subItem?.rURL}
                          controls
                        ></audio>
                      );
                    })
                  ) : item?.call_recording &&
                    item?.call_recording !== "None" ? (
                    <audio
                      className="ps-4 mb-0"
                      src={item?.call_recording}
                      controls
                    ></audio>
                  ) : null}
                </td>
                <td
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "15ch",
                  }}
                >
                  {item?.teamLeadRemark}
                </td>
                {pageName === "ManagerLeadAnalysis" && !item?.teamLeadRemark ? (
                  <td>
                    <span
                      className="text-Dark fw-bold me-2 cursor"
                      onClick={() => handleEdit(item)}
                    >
                      <BorderColorIcon />
                    </span>
                  </td>
                ) : item?.teamLeadRemark ? (
                  <td>
                    <span
                      className="text-Dark fw-bold me-2 cursor"
                      onClick={() => handleView(item)}
                    >
                      <VisibilityIcon />
                    </span>
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleEdit = (val: any) => {
    setOpenPopup(true);
    setOpenDeletePopup(false);
    setId(val?._id);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const changeRemark = (val: any) => {
    setTeamLeadRemark(val);
  };

  const handleSubmitManagerRemark = () => {
    setOpenPopup(false);
    dispatch(
      addManagerRemark(
        id,
        {
          teamLeadRemark,
        },
        () => {
          dispatch(
            getCallHistory(
              { studentLeadsId: data?._id, counsellorId },
              (res: any) => {
                setCallHistoryData(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const handleView = (val: any) => {
    setOpenDeletePopup(true);
    setTeamLeadRemark(val?.teamLeadRemark);
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
    setTeamLeadRemark("");
  };

  const handleBack = () => {
    if (pageName === "counsellorRemark") {
      navigate("../counsellorRemark", {
        state: {
          data: counsellorId,
          pageName: "callerDetails",
          sdate: leadStartDate,
          edate: leadEndDate,
        },
      });
    }
  };

  return (
    <div className="contentBox">
      <div className=" col-md-12 row">
        <div className=" formDiv formWidth p-4">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => {
                handleBack();
              }}
              type="submit"
              className="btn btn-outline-dark mb-3 submitbtn me-3"
            >
              <ReplyIcon /> Back
            </button>
          </div>
          <div className="d-flex Justify-Content-between border-bottom border-secondary border-5 ">
            <div className="col-md-6 pe-5 ps-0 pb-0">
              <h3 className="fw-bold border-bottom border-5 border-secondary text-primary">
                Personal & Contact
              </h3>
              <div className="d-flex justify-content-between">
                <p className="font17">First name</p>
                <p className="font17">{firstName}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Last name</p>
                <p className="font17">{lastName}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Contact 1</p>
                {/* <p className='font17'>{contact1}</p> */}
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Contact 2</p>
                {/* <p className='font17'>{contact2}</p> */}
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Email</p>
                {/* <p className='font17'>{email}</p> */}
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">State</p>
                <p className="font17">{state}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">City</p>
                <p className="font17">{city}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Collage Name</p>
                <p className="font17">{college}</p>
              </div>
            </div>

            <div className="col-md-6 p-5 pt-0 pe-0">
              <h3 className="fw-bold border-bottom border-5 border-secondary text-primary">
                Courses
              </h3>
              <div className="d-flex justify-content-between">
                <p className="font17">Course</p>
                <p className="font17">
                  {course ? course[0]?.baseCourseId : "-"}
                </p>
              </div>

              <div className="d-flex justify-content-between">
                <p className="font17">Sub Courses</p>
                <p className="font17">{course ? course[0]?.examTypeId : "-"}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Course Type</p>
                <p className="font17">
                  {course ? course[0]?.maincategoryId : "-"}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Study Material</p>
                <p className="font17">
                  {course ? course[0]?.subcategoryId : "-"}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Qualify Year</p>
                <p className="font17"> {educationYear}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Start Date</p>
                <p className="font17"> </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">End Date</p>
                <p className="font17"> </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="font17">Status</p>
                <p className="font17">{status}</p>
              </div>
            </div>
          </div>
          <div className="">{renderListMode()}</div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={openPopup}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopup}>
          <div className="modeldivChat p-3">
            <div className="col mb-4 d-flex justify-content-between ">
              <p className="fw-bold">
                <span style={{ color: "#3F51B5" }}> Manager Remark</span>
              </p>
              <ClearOutlinedIcon
                className="colorPurple cursor"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className="form-check">
              <div>
                <p className="text-primary">
                  <div className="row d-flex justify-content-between">
                    <textarea
                      className="form-control"
                      placeholder={"Comment"}
                      value={teamLeadRemark}
                      onChange={(e) => changeRemark(e.target.value)}
                    ></textarea>
                  </div>
                </p>
              </div>
              <div className="col-auto d-flex justify-content-center mt-2">
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="submit"
                  className="btn btn-outline-dark mb-3 submitbtn me-3"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleSubmitManagerRemark();
                  }}
                  type="submit"
                  className="btn btn-primary mb-3 submitbtn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {openDeletePopup ? (
        <RestorePopup
          open={openDeletePopup}
          handleClose={handleCloseDelete}
          handleSubmitRestore={() => {}}
          title={"Manager Remark"}
          data={teamLeadRemark}
          buttonTitle={"Delete"}
        />
      ) : (
        ""
      )}
    </div>
  );
}
