export const CrmAdminPanelSidebarData = [
  {
    title: "LEAD GENERATION DASHBOARD",
    subNav: [
      {
        title: "Lead Generation Overview",
        path: "leadGenerationOverview",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LEAD ORDERS SEARCH",
    subNav: [
      {
        title: "Lead Orders Search",
        path: "leadOrdersSearch",
        cName: "sub-nav",
      },
      {
        title: "Bind Coupon to Counsellor",
        path: "bindCouponToEC",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DAILY MEETING",
    subNav: [
      {
        title: "1 PM DAILY MEETING",
        path: "dailyMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "WEBSITE LEAD ASSIGN TIME",
    path: "cronTime",
  },
  {
    title: "EXAMWISE LEAD ASSIGN TIME",
    path: "cronTimeExam",
  },
  {
    title: "COUNSELLOR NEW LEADS TARGET",
    path: "counsellorCount",
  },
  {
    title: "COUNSELLOR CALL TIME TARGET",
    path: "counsellorCallTime",
  },
  {
    title: "COUNSELLOR REFERENCE TARGET",
    path: "counsellorReference",
  },
  {
    title: "COUNSELLOR TOTAL CONNECTED CALL TARGET",
    path: "counsellorTotalConnectedCall",
  },
  {
    title: "COUNSELLOR UNIQUE CALL TARGET",
    path: "counsellorUniqueCall",
  },
  {
    title: "LEAD SCORE",
    path: "leadScore",
  },
];
