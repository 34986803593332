import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";

import Loading from "../../Component/loading";
import { CrmAdminPanelSidebarData } from "../../DATA/crmAdminPanelSidebarData";
import Header from "../../Component/header";
import LeadGenerationOverview from "./leadGenerationOverview";
import LeadOverview from "./leadOverview";
import LeadOrdersSearch from "./leadOrdersSearch";
import LeadGenerationAnalysis from "./leadGenerationAnalysis";
import CronTime from "./cronTime";
import CronTimeExam from "./cronTimeExam";
import CounsellorCount from "./counsellorCount";
import CounsellorCallTime from "./counsellorCallTime";
import CounsellorReference from "./counsellorReference";
import CounsellorTotalConnectedCall from "./counsellorTotalConnectedCall";
import CounsellorUniqueCall from "./counsellorUniqueCall";
import LeadScore from "./leadScore";
import DailyMeeting from "./dailyMeeting";
import BindCouponToEC from "./bindCouponToEC";

function Dashboard(Props: any) {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={CrmAdminPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<LeadGenerationOverview />} />
              <Route
                path="leadGenerationOverview"
                element={<LeadGenerationOverview />}
              />
              <Route path="leadOrdersSearch" element={<LeadOrdersSearch />} />
              <Route path="cronTime" element={<CronTime />} />
              <Route path="cronTimeExam" element={<CronTimeExam />} />
              <Route path="leadOverview" element={<LeadOverview />} />
              <Route
                path="leadGenerationAnalysis"
                element={<LeadGenerationAnalysis />}
              />
              <Route path="counsellorCount" element={<CounsellorCount />} />
              <Route
                path="counsellorCallTime"
                element={<CounsellorCallTime />}
              />
              <Route
                path="counsellorReference"
                element={<CounsellorReference />}
              />
              <Route
                path="counsellorTotalConnectedCall"
                element={<CounsellorTotalConnectedCall />}
              />
              <Route
                path="counsellorUniqueCall"
                element={<CounsellorUniqueCall />}
              />
              <Route path="leadScore" element={<LeadScore />} />
              <Route path="dailyMeeting" element={<DailyMeeting />} />
              <Route path="bindCouponToEC" element={<BindCouponToEC />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default Dashboard;
