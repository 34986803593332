import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { useSelector } from "react-redux";
import moment from "moment";
// import { Pagination } from '@mui/material';
import Pagination from "@mui/material/Pagination";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import {
  getCourseAnalysis,
  getEmployeetByManagerId,
} from "../../Redux/Actions/managerPanelAction";
import {
  getExamList,
  getExamStreamList,
  getMainCategoryList,
} from "../../Redux/Actions/counsellorPanelAction";
import { getStatusList } from "../../Redux/Actions/leadManagementAction";
import SubmitButton from "../../Component/submitButton";
import {
  getSMCouresAnalysis,
  getSMManagerList,
} from "../../Redux/Actions/superManagerAction";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
export default function SACourseAnalysis() {
  // const dispatch = useDispatch();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    managerId,
    loggedInUserRole,
    sDate,
    eDate,
    selectedManagerId,
    selectedCounsellorId,
    selectedExamTypeId,
    selectedBaseCourseId,
    selectedMainCategoryId,
    selectedSubCategoryId,
    selectedStatus,
    loggedInUserId,
  } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
    loggedInUserRole: state.Auth.loggedInUserRole,
    sDate: state.Auth.startDate,
    eDate: state.Auth.endDate,
    selectedManagerId: state.Auth.selectedManagerId,
    selectedCounsellorId: state.Auth.selectedCounsellorId,
    selectedExamTypeId: state.Auth.selectedExamTypeId,
    selectedBaseCourseId: state.Auth.selectedBaseCourseId,
    selectedMainCategoryId: state.Auth.selectedMainCategoryId,
    selectedSubCategoryId: state.Auth.selectedSubCategoryId,
    selectedStatus: state.Auth.selectedStatus,
    loggedInUserId: state.Auth.loggedInUserId,
  }));
  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [manager, setManager] = useState<any>("");
  const [counsellor, setCounsellor] = useState<any>();
  const [exam, setExam] = useState<any>("");
  const [subExam, setSubExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [studyMaterial, setStudyMaterial] = useState<any>("");
  const [status, setStatus] = useState<any>("");

  const [managerList, setManagerList] = useState([]);
  const [counsellorList, setCounsellorList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [studyMaterialList, setStudyMaterialList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [counrseAnalysisList, setCourseAnalysisList] = useState([]);

  const [resetManager, setResetManager] = useState();
  const [resetCounsellor, setResetCounsellor] = useState();
  const [resetStatus, setResetStatus] = useState();
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState();
  const [resetExamStream, setResetExamStream] = useState(false);
  const [resetCourse, setResetCourse] = useState<any>(false);
  const [resetStudyMaterial, setResetStudyMaterial] = useState();
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const pageName = location.state?.pageName;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;
  const counsellorId = location.state?.counsellorId;
  const examTypeId = location.state?.examTypeId;
  const basecourseId = location.state?.basecourseId;
  const courseTypeId = location.state?.courseTypeId;
  const studyMaterialId = location.state?.studyMaterialId;
  const leadStatus = location.state?.status;

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: any) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
      };
    }

    if (manager) {
      data["managerId"] = manager?._id;
    }
    if (teamLead) {
      data["teamLeadId"] = teamLead?._id;
    }
    if (counsellor) {
      data["counsellorId"] = counsellor?._id;
    }
    if (exam) {
      data["examTypeId"] = exam?._id;
    }
    if (subExam) {
      data["subExamTypeId"] = subExam?._id;
    }
    if (examStream) {
      data["basecourseId"] = examStream?._id;
    }
    if (course) {
      data["maincategoryId"] = course?._id;
    }
    if (studyMaterial) {
      data["subcategoryId"] = studyMaterial?._id;
    }
    if (status) {
      data["status"] = status?._id;
    }
    dispatch(
      getSMCouresAnalysis(
        data,
        (res: any) => {
          setCount(res?.count);
          setCourseAnalysisList(res?.data);
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );

    const postData: any = {
      startDate,
      endDate,
      skip: 0,
      limit: rowsPerPage,
    };

    dispatch(
      getSMCouresAnalysis(
        postData,
        (res: any) => {
          setCount(res?.count);
          setCourseAnalysisList(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
          let tempExam = res?.data?.filter(
            (item: any) => item?._id === selectedExamTypeId
          );
          setExam(tempExam[0]);
          if (selectedExamTypeId) {
            dispatch(
              getExamStreamList(`${selectedExamTypeId}`, (res: any) => {
                setExamStreamList(res);
                if (pageName === "completeLeadDetail") {
                  let tempBaseCourse = res?.filter(
                    (item: any) => item?._id === selectedBaseCourseId
                  );
                  setExamStream(tempBaseCourse[0]);
                }
                if (selectedMainCategoryId) {
                  dispatch(
                    getMainCategoryList(
                      {
                        examTypeId: selectedExamTypeId,
                        baseCourseId: selectedBaseCourseId,
                      },
                      (res: any) => {
                        setCourseTypeList(res);
                        if (pageName === "completeLeadDetail") {
                          let tempMainCategory = res?.filter(
                            (item: any) => item?._id === selectedMainCategoryId
                          );
                          setCourse(tempMainCategory[0]);

                          const subCategoryList =
                            tempMainCategory[0]?.subCategories.filter(
                              (item: any) => {
                                return item.type === 2;
                              }
                            );
                          setStudyMaterialList(subCategoryList);
                          let tempStudyMaterial = subCategoryList?.filter(
                            (item: any) => item?._id === selectedSubCategoryId
                          );
                          setStudyMaterial(tempStudyMaterial[0]);
                        }
                      },
                      () => {}
                    )
                  );
                }
              })
            );
          }
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getStatusList(
        "",
        (res: any) => {
          setStatusList(res);
          if (pageName === "completeLeadDetail") {
            let tempStatus = res?.filter(
              (item: any) => item?._id === selectedStatus
            );
            setStatus(tempStatus[0]);
          }
        },
        () => {}
      )
    );
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (value: any) => {
    setTeamLead(value);
    dispatch(
      getEmployeetByManagerId(
        value?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    setExam(val);
    setResetExam(false);
    // setExamStream("");
    // setResetExamStream(true);
    setExamStreamList([]);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeSubExam = (val: any) => {
    setSubExam(val);
    setExamStream(val);
    setResetExamStream(false);
    dispatch(
      getMainCategoryList(
        {
          examTypeId: exam?._id,
          baseCourseId: val?._id,
        },
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeExamStream = (val: any) => {
    setExamStream(val);
    setExamStream(val);
    setResetExamStream(false);
    dispatch(
      getMainCategoryList(
        {
          examTypeId: exam?._id,
          baseCourseId: val?._id,
        },
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourse = (val: any) => {
    setCourse(val);
    setResetCourse(false);
    const subCategoryList = val?.subCategories.filter((item: any) => {
      return item.type === 2;
    });
    setStudyMaterialList(subCategoryList);
  };
  const onChangeStudyMaterial = (val: any) => {
    setStudyMaterial(val);
  };
  const onChangeStatus = (val: any) => {
    setStatus(val);
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    dispatch(updateCommonValues("selectedManagerId", manager?._id));
    dispatch(updateCommonValues("selectedCounsellorId", counsellor?._id));
    dispatch(updateCommonValues("selectedExamTypeId", exam?._id));
    dispatch(updateCommonValues("selectedBaseCourseId", examStream?._id));
    dispatch(updateCommonValues("selectedMainCategoryId", course?._id));
    dispatch(updateCommonValues("selectedSubCategoryId", studyMaterial?._id));
    dispatch(updateCommonValues("selectedStatus", status?._id));
    navigate("../sMCompleteDetails", {
      state: {
        pageName: "courseAnalysis",
      },
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData: any = {
      startDate,
      endDate,
      skip: 0,
      limit: rowsPerPage,
    };

    if (manager) {
      postData["managerId"] = manager?._id;
    }
    if (teamLead) {
      postData["teamLeadId"] = teamLead?._id;
    }
    if (counsellor) {
      postData["counsellorId"] = counsellor?._id;
    }
    if (exam) {
      postData["examTypeId"] = exam?._id;
    }
    if (subExam) {
      postData["subExamTypeId"] = subExam?._id;
    }
    if (examStream) {
      postData["basecourseId"] = examStream?._id;
    }
    if (course) {
      postData["maincategoryId"] = course?._id;
    }
    if (studyMaterial) {
      postData["subcategoryId"] = studyMaterial?._id;
    }
    if (status) {
      postData["status"] = status?._id;
    }
    dispatch(
      getSMCouresAnalysis(
        postData,
        (res: any) => {
          setCount(res?.count);
          setCourseAnalysisList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  const renderSearchMode = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="col-md-12 row d-flex pt-4">
          <div className="col-md-4 d-flex mb-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date" />
            </div>
            <div className="col-md-9  ">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date" />
            </div>
            <div className="col-md-9 ">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Manager" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Team Lead" />
            </div>
            <div className="col-md-9  ">
              <CustomDropdown
                lableName="Select Team Lead"
                setInputText={(value: any) => onChangeTeamLead(value)}
                value={teamLead}
                options={teamLeadList}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Counsellor" />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Counsellor"
                setInputText={(value: any) => onChangeCounsellor(value)}
                value={counsellor}
                options={counsellorList}
                // Isrequired={true}
                reset={resetCounsellor}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam"
                setInputText={(value: any) => onChangeExam(value)}
                value={exam}
                options={examList}
                // Isrequired={true}
                reset={resetExam}
                defaultValue={examList?.length > 0 ? exam : null}
              />
            </div>
          </div>
          {subExamList?.length > 0 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Sub Exam Name" />
              </div>
              <div className="col-md-9">
                <CustomDropdown
                  lableName="Select Sub Exam Name"
                  setInputText={(value: any) => onChangeSubExam(value)}
                  value={subExam}
                  options={subExamList}
                  reset={resetSubExam}
                  // reset={resetSubexamList}
                />
              </div>
            </div>
          ) : null}
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Stream" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam Stream"
                setInputText={(value: any) => onChangeExamStream(value)}
                value={examStream}
                options={examStreamList}
                // Isrequired={true}
                reset={resetExamStream}
                defaultValue={examStreamList?.length > 0 ? examStream : null}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Course " />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Course "
                setInputText={(value: any) => onChangeCourse(value)}
                value={course}
                options={courseTypeList}
                reset={resetCourse}
                defaultValue={courseTypeList?.length > 0 ? course : null}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Study Material " />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Study Material"
                setInputText={(value: any) => onChangeStudyMaterial(value)}
                value={studyMaterial}
                options={studyMaterialList}
                reset={resetStudyMaterial}
                defaultValue={
                  studyMaterialList?.length > 0 ? studyMaterial : null
                }
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Status " />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Status "
                setInputText={(value: any) => onChangeStatus(value)}
                value={status}
                options={statusList}
                reset={resetStatus}
                defaultValue={statusList?.length > 0 ? status : null}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between mb-2">
            <div className="col-md-6 me-2">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {counrseAnalysisList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={counrseAnalysisList ? counrseAnalysisList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={counrseAnalysisList ? counrseAnalysisList : []}
          pagename={"courseAnalysis"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.No.",
            "First Name",
            "Last Name",
            // "Phone No",
            "Exam",
            "Exam Stream",
            "Course Type",
            "Source",
            "Study Material",
            "Status",
            "Call Back Date",
            "Counsellor Name",
          ]}
          edit={false}
          handleClick={onClickLead}
        />
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Course Analysis"} />
      <div className="formDiv">
        <div className="formWidth col-md-12 row">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
