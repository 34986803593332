export const CrmDataOperatorPanelSidebarData = [
  {
    title: "IMPORT LEADS",
    path: "createImportLeads",
  },
  {
    title: "MANAGE EMPLOYEE",
    path: "manageEmployee",
  },
  {
    title: "LEAD ASSIGNMENT",
    path: "leadAssignments",
  },
  {
    title: "LEAD TRANSFER",
    path: "leadTransfer",
  },
  // {
  //   title: "INCENTIVE ALLOCATION",
  //   path: "incentiveAllocation",
  // },
  {
    title: "INCENTIVE ALLOCATION",
    subNav: [
      {
        title: "INCENTIVE ALLOCATION",
        path: "incentiveAllocation",
        cName: "sub-nav",
      },
      {
        title: "DIRECT INCENTIVE TARGET",
        path: "incentiveTarget",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEAMLEAD ALLOCATION",
    path: "teamLeadAllocation",
  },
  {
    title: "LEAD GENERATION DASHBOARD",
    subNav: [
      {
        title: "Lead Generation Overview",
        path: "leadGenerationOverview",
        cName: "sub-nav",
      },
      // {
      //   title: "Lead Generation Analysis",
      //   path: "leadGenerationAnalysis",
      //   cName: "sub-nav",
      // },
    ],
  },
  {
    title: "RESULT COMPILATION",
    path: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    path: "studentResult",
  },
  {
    title: "LEAD SEARCH",
    path: "leadSearchByMobileNumber",
  },
  {
    title: "ASSIGN STATE ZONE WISE",
    subNav: [
      {
        title: "ASSIGN STATE ZONE WISE",
        path: "assignStateZoneWise",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE ZONE",
        path: "editDeleteZone",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "WEBSITE LEAD ASSIGN TIME",
  //   path: "cronTime",
  // },
  // {
  //   title: "COUNSELLOR NEW LEADS TARGET",
  //   path: "counsellorCount",
  // },
  // {
  //   title: "COUNSELLOR CALL TIME TARGET",
  //   path: "counsellorCallTime",
  // },
  // {
  //   title: "COUNSELLOR REFERENCE TARGET",
  //   path: "counsellorReference",
  // },
  // {
  //   title: "COUNSELLOR TOTAL CONNECTED CALL TARGET",
  //   path: "counsellorTotalConnectedCall",
  // },
  // {
  //   title: "COUNSELLOR UNIQUE CALL TARGET",
  //   path: "counsellorUniqueCall",
  // },
  // {
  //   title: "LEAD SCORE",
  //   path: "leadScore",
  // },
  // {
  //   title: "OLD IMPORT LEADS",
  //   path: "oldImportLeads",
  // },
];

export const CrmDataEntryPanelSidebarData = [
  {
    title: "IMPORT LEADS",
    path: "createImportLeads",
  },
  {
    title: "RESULT COMPILATION",
    path: "resultCompilation",
  },
];
