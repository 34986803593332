import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { getLeadAssignedOverviewAnalysis } from "../../Redux/Actions/counsellorPanelAction";

export default function LeadAssignedDateAnalysis() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const leadData = location.state?.leadData;
  const counsellorData = location.state?.counsellorId;
  const leadAssignedDate = location.state?.assignedDate;
  const [analysisList, setAnalysisList] = useState<any>([]);

  useEffect(() => {
    const data: any = {
      startDate: leadAssignedDate,
      endDate: leadAssignedDate,
      type: leadData?.type,
    };
    dispatch(
      getLeadAssignedOverviewAnalysis(
        counsellorData?.counsellorId,
        data,
        (res: any) => {
          setAnalysisList(res);
        },
        () => {}
      )
    );
  }, []);

  const onClickLead = (data: any, status: any) => {
    navigate("../leadAssignedStatusOverview", {
      state: {
        pageName: "leadAssignedDateAnalysis",
        counsellorId: counsellorData,
        status: status,
        startDate: leadAssignedDate,
        endDate: leadAssignedDate,
        type: leadData?.type,
      },
    });
  };

  const renderListMode = () => {
    return (
      <div className="salesTable table-responsive">
        <table className="w-100 mb-2">
          <thead>
            <tr>
              <th rowSpan={2}>Counsellor Name</th>
              <th>New Leads </th>
              <th>Transfered </th>
              <th>Call Back</th>
              <th>Intrested</th>
              <th>Not Intrested</th>
              <th>Prospect</th>
              <th>Admission Done</th>
              <th>Lead Invalid</th>
              <th>Already Admission Done</th>
              <th>Expired</th>
              <th>CNR</th>
              <th>Call Reject</th>
              <th>Final CNR</th>
            </tr>
          </thead>
          <tbody>
            {analysisList.map((item: any, index: any) => {
              return (
                <tr>
                  <td>
                    {item?.firstName ? item?.firstName : ""}{" "}
                    {item?.lastName ? item?.lastName : ""}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 2)}>
                    {item["NEWLEAD(ASSIGNED)"]?.count
                      ? item["NEWLEAD(ASSIGNED)"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 3)}>
                    {" "}
                    {item["TRANSFERREDLEAD"]?.count
                      ? item["TRANSFERREDLEAD"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 6)}>
                    {" "}
                    {item["CALLBACK"]?.count ? item["CALLBACK"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 4)}>
                    {" "}
                    {item["INTERESTED"]?.count
                      ? item["INTERESTED"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 9)}>
                    {item["NOTINTERESTED"]?.count
                      ? item["NOTINTERESTED"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 7)}>
                    {" "}
                    {item["PROSPECT"]?.count ? item["PROSPECT"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 5)}>
                    {" "}
                    {item["ADMISSIONDONE"]?.count
                      ? item["ADMISSIONDONE"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 8)}>
                    {" "}
                    {item["LEADINVALID"]?.count
                      ? item["LEADINVALID"]?.count
                      : "-"}
                  </td>

                  <td className="cursor" onClick={() => onClickLead(item, 10)}>
                    {" "}
                    {item["ALREADYADMISSIONDONE"]?.count
                      ? item["ALREADYADMISSIONDONE"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 11)}>
                    {" "}
                    {item["EXPIRED"]?.count ? item["EXPIRED"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 12)}>
                    {" "}
                    {item["CNR"]?.count ? item["CNR"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 21)}>
                    {" "}
                    {item["CALLREJECT"]?.count
                      ? item["CALLREJECT"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 14)}>
                    {" "}
                    {item["FINALCNR"]?.count ? item["FINALCNR"]?.count : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Total Assigned Leads Analysis"} />
      <div className="col-12 row">
        <div className="p-5">{renderListMode()}</div>
      </div>
    </div>
  );
}
