import { Pagination } from '@mui/material';
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import PaginationTable from '../../Component/paginationTable';
import PaginationView from '../../Component/paginationView';
import usePagination from '../../Component/usePagination';
import { getTableDataSlots, experienceList } from '../../DATA/dataConstant';
import CustomDropdown from '../../Component/customDropdown';
import LabelField from '../../Component/labelField';
import DatePicker from '../../Component/datePicker';
import InputField from '../../Component/inputField';
import { containsNumbeAndDecimal, containsNumber, length_One, length_Ten, validateValueWithoutSpace } from '../../DATA/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';
import moment from 'moment';
import Button from '../../Component/button';
import SubmitButton from '../../Component/submitButton';
import { addBdeIncentive, BdeIncentiveAllocationList, deleteBdeIncentive, editBdeIncentiveNewDate, updateBdeIncentive } from '../../Redux/Actions/bdeAdminPanelAction';
import RestorePopup from '../../Component/restorePopup';

export default function BDEIncentiveAllocation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [experience, setExperience] = useState<any>("");
  const [resetExperience, setResetExperience] = useState(false);
  const [bronzePercentage, setBronzePercentage] = useState<any>("");
  const [bronzeIncentive, setBronzeIncentive] = useState<any>("");
  const [silverPercentage, setSilverPercentage] = useState<any>("");
  const [silverIncentive, setSilverIncentive] = useState<any>("");
  const [goldPercentage, setGoldPercentage] = useState<any>("");
  const [goldIncentive, setGoldIncentive] = useState<any>("");
  const [platinumPercentage, setPlatinumPercentage] = useState<any>("");
  const [platinumIncentive, setPlatinumIncentive] = useState<any>("");

  const [incentiveListData, setIncentiveListData] = useState<any>("");
  const [isEditMode, setisEditMode] = useState(false);
  const [isEditModeNew, setisEditModeNew] = useState(false);
  const [openAddIncentive, setResetopenAddIncentive] = useState(false);
  const [startDateNew, SetStartDateNew] = useState<any>("");
  const [endDateNew, SetEndDateNew] = useState<any>("");

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [incentiveId, setIncentiveId] = useState<any>("");


  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);



  const handleDelete = (value: any) => {
    setOpenDeletePopup(true);
    setIncentiveId(value?._id)

  };

  const handleSubmitDelete = () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteBdeIncentive(
        incentiveId,
        () => {
          getList();

        },
        () => { }
      )
    );
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,


      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,


      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,


      };
      dispatch(
        BdeIncentiveAllocationList(
          data,
          (res: any) => {
            setCount(res?.count);
            setIncentiveListData(res?.data);
          },
          () => { }
        )
      );
    }


  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };


  const showData = (e: any, val: any) => {
    e.preventDefault();

  };

  const onChangeExperience = (val: any) => {
    setExperience(val?._id)
  }

  const cancelAddIncentive = () => {
    setResetopenAddIncentive(false);
    setisEditMode(false);
    setisEditModeNew(false);
  }

  const cancelEditIncentive = () => {
    setisEditMode(false)
  }

  const cancelEditNew = () => {
    setisEditModeNew(false)
  }
  const onChangeBronzePercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumbeAndDecimal(val) ) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setBronzePercentage(val);
      }
    } else {
      setBronzePercentage(val);
    }
  }
  const onchangeBronzeIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setBronzeIncentive(val);
      }
    } else {
      setBronzeIncentive(val);
    }
  }
  const onChangeSilverPercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumbeAndDecimal(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setSilverPercentage(val);
      }
    } else {
      setSilverPercentage(val);
    }
  }
  const onchangeSilverIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setSilverIncentive(val);
      }
    } else {
      setSilverIncentive(val);
    }
  }
  const onChangeGoldPercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumbeAndDecimal(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setGoldPercentage(val);
      }
    } else {
      setGoldPercentage(val);
    }
  }
  const onchangeGoldIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setGoldIncentive(val);
      }
    } else {
      setGoldIncentive(val);
    }
  }
  const onChangePlatinumPercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumbeAndDecimal(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPlatinumPercentage(val);
      }
    } else {
      setPlatinumPercentage(val);
    }
  }
  const onchangePlatinumIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPlatinumIncentive(val);
      }
    } else {
      setPlatinumIncentive(val);
    }
  }
  const getList = () => {
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      startDate: startDate,
      endDate: endDate,
    }

    dispatch(
      BdeIncentiveAllocationList(
        postData,
        (res: any) => { 
          setCount(res?.count);
          setIncentiveListData(res?.data);
        },
        () => { }
      )
    );
  }
  const handleEdit = (value: any) => {
    setisEditMode(true);
    setIncentiveId(value?._id)
    setExperience(value?.experience);
    setBronzePercentage(value?.target?.bronze_level?.leadRatio);
    setBronzeIncentive(value?.target?.bronze_level?.incentiveAmount);
    setSilverPercentage(value?.target?.silver_level?.leadRatio);
    setSilverIncentive(value?.target?.silver_level?.incentiveAmount);
    setGoldPercentage(value?.target?.gold_level?.leadRatio);
    setGoldIncentive(value?.target?.gold_level?.incentiveAmount);
    setPlatinumPercentage(value?.target?.platinum_level?.leadRatio);
    setPlatinumIncentive(value?.target?.platinum_level?.incentiveAmount);

  };

  const handleUpdateEdit = (e: any) => {
    let newArrr: Array<string> = [];
    incentiveListData?.map((data: any, index: any) => {
      newArrr.push(data?._id);
    });
    e.preventDefault();
    const postData = {
      startDate: startDateNew,
      endDate: endDateNew,
      records: newArrr,

    }
    dispatch(
      editBdeIncentiveNewDate(
        postData,
        () => {
          setisEditModeNew(false)
          getList();
        },
        () => { }
      )
    );

  }

  const handleEditNew = (value: any) => {
    setisEditModeNew(true);
  }
  const handleOpenAddIncentive = () => {
    setResetopenAddIncentive(true);
  }
  const handleUpdate = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      experience: experience,
      target: {
        bronze_level: {
          leadRatio:bronzePercentage,
          incentiveAmount:bronzeIncentive
        },
        silver_level: {
          leadRatio: silverPercentage,
          incentiveAmount:silverIncentive
        },
        gold_level: {
          leadRatio:goldPercentage ,
          incentiveAmount: goldIncentive
        },
        platinum_level: {
          leadRatio:platinumPercentage ,
          incentiveAmount:platinumIncentive
        },
      }
    }
    dispatch(
      updateBdeIncentive(
        incentiveId,
        postData,
        () => {
          setisEditMode(false);
          setResetExperience(true);
          setBronzeIncentive("");
          setSilverIncentive("");
          setGoldIncentive("");
          setPlatinumIncentive("");
          getList();

        },
        () => { }
      )
    );
  }
  const AddIncentive = (e: any) => {
    e.preventDefault();
    setResetopenAddIncentive(false)
    const PostData = {
      startDate: startDate,
      endDate: endDate,
      experience: experience,
      target: {
        bronze_level: {
          leadRatio:bronzePercentage,
          incentiveAmount:bronzeIncentive
        },
        silver_level: {
          leadRatio: silverPercentage,
          incentiveAmount:silverIncentive
        },
        gold_level: {
          leadRatio:goldPercentage ,
          incentiveAmount: goldIncentive
        },
        platinum_level: {
          leadRatio:platinumPercentage ,
          incentiveAmount:platinumIncentive
        },
      }
    };
    dispatch(
      addBdeIncentive(
        PostData,
        (res: any) => {
          getList();
        },
        () => { }
      )
    );

  }
  const handleSearch = (e: any) => {
    e.preventDefault();
    getList();
  }

  const validation=()=>{
    return(
      bronzeIncentive === "" ||bronzePercentage===""||  
      silverIncentive === ""||silverPercentage ==="" ||
      goldIncentive ==="" ||goldPercentage ==="" ||
      platinumIncentive === "" || platinumPercentage ==="" ||
      bronzeIncentive.length<length_One ||bronzePercentage.length<length_One||  
      silverIncentive.length<length_One||silverPercentage.length<length_One||
      goldIncentive.length<length_One ||goldPercentage.length<length_One ||
      platinumIncentive.length<length_One|| platinumPercentage.length<length_One
    )
  }
  const renderSearchMode = () => {
    return (
      <>
        <form
          className="row d-flex justify-content-between"
          onSubmit={handleSearch}
        >
          <div className="col-md-4 d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-2">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>

            <div className="col-md-2 ms-3">
              <button
                className="btn btn-primary "
                type="button"
                onClick={() => handleEditNew(incentiveListData)}
              >
                {" "}
                Edit
              </button>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-between my-3">
            <div className="col-md-6 ">
              <button
                className="btn btn-primary "
                type="button"
                onClick={handleOpenAddIncentive}
              >
                {" "}
                Add Incentive
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };
  const renderListMode = () => {
    return (
      <>
        {incentiveListData?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"BdeIncentiveAllocation"}
            count={count}
            tableData={incentiveListData ? incentiveListData : []}
            onTabChange={(e: any, index: any) => showData(e, index)}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={incentiveListData ? incentiveListData : []}
          pagename={"BdeIncentiveAllocation"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Experience At IFAS",
            "Bronze Incentive",
            "Silver Incentive",
            "Gold Incentive",
            "Platnium Incentive",
            "Edit",
            "Delete",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  }
  const renderAddIncentiveMode = () => {
    return (
      <>
        <form
          className="col-md-12 row"
          onSubmit={!isEditMode ? AddIncentive : handleUpdate}
        >
          <div className="row">
            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                  Isrequired={true}
                />
              </div>
            </div>

            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                  Isrequired={true}
                  mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
          </div>



          <div className="col-6 d-flex my-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Experience at IFAS:" />
            </div>
            <div className="col-md-8 ms-1 ps-2">
              <CustomDropdown
                lableName="Select Experience"
                setInputText={(value: any) => onChangeExperience(value)}
                value={experience}
                options={experienceList}
                Isrequired={true}
                defaultValue={experienceList?.length > 0 ? experience : null}
                reset={resetExperience}
              />
            </div>
          </div>


          <div className="row">
            <h3 className="text-primary font-monospace"> Target</h3>
            <div className="row d-flex">
              <div className="row">
                <div className="col-4">
                  <p className="fw-bold">Target Level:</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Lead Ratio :</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Incentive Amount:</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Bronze Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Lead Ratio"
                    value={bronzePercentage}
                    onChangeInput={(value: any) =>
                      onChangeBronzePercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Amount"
                    value={ bronzeIncentive}
                    onChangeInput={(value: any) =>
                      onchangeBronzeIncentive  (value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Silver Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Lead Ratio"
                    value={ silverPercentage}
                    onChangeInput={(value: any) =>
                      onChangeSilverPercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Amount"
                    value={silverIncentive}
                    onChangeInput={(value: any) =>
                      onchangeSilverIncentive (value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Gold Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Lead Ratio"
                  
                    value={goldPercentage}
                    onChangeInput={(value: any) =>onChangeGoldPercentage (value)}
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Amount"
                    value={goldIncentive}
                    onChangeInput={(value: any) =>
                      onchangeGoldIncentive (value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Platinum Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Lead Ratio"
                    value={platinumPercentage}
                    onChangeInput={(value: any) =>
                      onChangePlatinumPercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Amount"
                    value={platinumIncentive}
                    onChangeInput={(value: any) =>
                      onchangePlatinumIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isEditMode ? (
            <div className='d-flex justify-content-center'>
              <div className="d-flex justify-content-center mx-1">
                <Button name={"Cancel"} handleClick={cancelAddIncentive} />
              </div>
              <div className="d-flex justify-content-center mx-1">
                <SubmitButton name={"Add Incentive"} validation={validation()}/>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Button name={"Cancel"} handleClick={cancelEditIncentive} />
              <div className="ms-3">
                <SubmitButton name={"Save & Update"} />
              </div>
            </div>
          )}
        </form>
      </>
    );
  };
  const renderEditMode = () => {
    return (
      <>

        < form className=' col-md-9 row d-flex' onSubmit={handleUpdateEdit}>
          <div className='col-md-5 d-flex my-2  me-4'>
            <div className='col-md-4 mt-2'>
              <LabelField lableName='Start Date:' />
            </div >
            <div className="col-md-8">
              <DatePicker

                value={startDateNew}
                setInputText={(value: string) => SetStartDateNew(value)}
                Isrequired={true}
                // mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
              /></div>
          </div>

          <div className='col-md-5 d-flex my-2'>
            <div className='col-md-4 mt-2'>
              <LabelField lableName='End Date:' />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={endDateNew}
                setInputText={(value: string) => SetEndDateNew(value)}
                Isrequired={true}
                // mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
              /></div>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <Button name={"Cancel"} handleClick={cancelEditNew} />
            <div className='ms-3'>
              <SubmitButton name={"Save & Update"}  />
            </div>
          </div>

        </form>
      </>

    )
  }
  return (
    <div className="contentBox">
      <PageTitle name={!isEditMode ? "Incentive Allocation" : "Edit Incentive Allocation"} isEditMode={isEditMode} setisEditMode={setisEditMode} />
      <div className='col-md-12 px-4 px-0 pt-4 formDiv '>
        <div className='justify-content-start '  >
          {!isEditModeNew ? <>
            {!openAddIncentive && !isEditMode && !isEditModeNew ?
              <> {renderSearchMode()}
                {renderListMode()}
              </>
              :
              renderAddIncentiveMode()
            }
          </> : <>
            {renderEditMode()}
            {renderListMode()}
          </>}


        </div>
        {openDeletePopup ? (
          <RestorePopup
            open={openDeletePopup}
            handleClose={handleCloseDelete}
            handleSubmitRestore={() => {
              handleSubmitDelete();
            }}
            title={"Delete Allocated Target"}
            data={"Do you want to delete this ?"}
            buttonTitle={"Delete"}
          />
        ) : (
          ""
        )}

      </div>
    </div>
  )
}

