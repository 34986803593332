
 import { Bar, getElementsAtEvent } from "react-chartjs-2";
import { useRef } from 'react';

//  import { BarChart } from '@mui/x-charts/BarChart';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface props {
   label: any,
   value:any,
  getData?:any,

}
 export default function BarGraph({ label ,value, getData}: props) {

   const options: any = {
      responsive: true,
      plugins: {
        legend: {
          position: "top"
        }
      }
    };
    const graphData = {
      labels: label,
      datasets: [
        {
          label: "",
          data: value,
          backgroundColor: [
            "blue",
            "red",
          ],
          borderColor: [
            "blue",
            "red",
          ],
          borderWidth: 0.5,
          barPercentage: 0.2,
       
        }
      ]
    
    };
  const chartRef:any = useRef();

    const onClick =(event:any)=>{
      if (chartRef.current) {
        let selectedBar=getElementsAtEvent(chartRef.current, event);
        if(selectedBar?.length>0){
          getData(selectedBar[0])
        }
     }
    }
    return (
      <Bar options={options} data={graphData} onClick={onClick} ref={chartRef}/> 
   //    <BarChart
   //    xAxis={[{ scaleType: 'band', data: ['group A', 'group B'] }]}
   //    series={[{ data: [14] }, { data: [6] }]}
   //    width={500}
   //    height={300}
   //  />
    );
 }
 