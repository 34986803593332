import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots, remarkTypeList } from "../../DATA/dataConstant";
import DatePicker from "../../Component/datePicker";
import moment from "moment";
import { getTeamLeadRemark } from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import CustomDropdown from "../../Component/customDropdown";
import RemarkPopup from "../../Component/remarkPopup";
export default function CounsellorRemark() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loggedInUserId, loggedInUserManagerId, loggedInUserRole } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserManagerId: state.Auth.loggedInUserManagerId,
      loggedInUserRole: state.Auth.loggedInUserRole,
    }));

  const [managerRemarkList, setManagerRemarkList] = useState([]);
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [remarkType, SetRemarkType] = useState<any>({
    _id: 0,
    name: "Team Lead Remark",
  });

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [mode, setMode] = useState<any>("view");
  const [totalTargetRemark, setTotalTargetRemark] = useState<any>("");
  const [totalAchievedRemark, setTotalAchievedRemark] = useState<any>("");
  const [introduction, setIntorduction] = useState<any>({
    isAudited: false,
    remark: "",
    name: "introduction",
  });
  const [prospecting, setProspecting] = useState<any>({
    isAudited: false,
    remark: "",
    name: "prospecting",
  });
  const [preparation, setPreparation] = useState<any>({
    isAudited: false,
    remark: "",
    name: "preparation",
  });
  const [approach, setApproach] = useState<any>({
    isAudited: false,
    remark: "",
    name: "approach",
  });
  const [presentation, setPresentation] = useState<any>({
    isAudited: false,
    remark: "",
    name: "presentation",
  });
  const [handlingObjections, setHandlingObjections] = useState<any>({
    isAudited: false,
    remark: "",
    name: "handlingObjections",
  });
  const [closing, setClosing] = useState<any>({
    isAudited: false,
    remark: "",
    name: "closing",
  });
  const [followUp, setFollowUp] = useState<any>({
    isAudited: false,
    remark: "",
    name: "followUp",
  });
  const [auditRemark, setAuditRemark] = useState<any>("");

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        managerId: loggedInUserManagerId,
        counsellorId: loggedInUserId,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        managerId: loggedInUserManagerId,
        counsellorId: loggedInUserId,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        teamLeadId: loggedInUserManagerId,
        counsellorId: loggedInUserId,
      };
    }
    if (remarkType?._id === 0) {
      data["teamLeadRemark"] = true;
    } else if (remarkType?._id === 1) {
      data["managerRemark"] = true;
    }
    dispatch(
      getTeamLeadRemark(
        data,
        (res: any) => {
          setCount(res?.length);
          setManagerRemarkList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    const data: any = {
      startDate: startDate,
      endDate: endDate,
      teamLeadId: loggedInUserManagerId,
      counsellorId: loggedInUserId,
    };
    if (remarkType?._id === 0) {
      data["teamLeadRemark"] = true;
    } else if (remarkType?._id === 1) {
      data["managerRemark"] = true;
    }
    dispatch(
      getTeamLeadRemark(
        data,
        (res: any) => {
          setCount(res?.length);
          setManagerRemarkList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const changeEndDate = (val: any) => {
    SetEndDate(val);
  };

  const onChangeRemarkType = (val: any) => {
    SetRemarkType(val);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const data: any = {
      startDate: startDate,
      endDate: endDate,
      teamLeadId: loggedInUserManagerId,
      counsellorId: loggedInUserId,
    };
    if (remarkType?._id === 0) {
      data["teamLeadRemark"] = true;
    } else if (remarkType?._id === 1) {
      data["managerRemark"] = true;
    }
    dispatch(
      getTeamLeadRemark(
        data,
        (res: any) => {
          setCount(res?.length);
          setManagerRemarkList(res?.data);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <>
        <form className="row" onSubmit={handleSearch}>
          <div className="d-flex">
            <div className="col-md-3 d-flex pb-3 pe-2 ">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex pb-3 pe-2">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => changeEndDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-3 me-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Remark Type" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Remark Type"
                  setInputText={(value: any) => onChangeRemarkType(value)}
                  value={remarkType}
                  options={remarkTypeList}
                  defaultValue={
                    remarkTypeList?.length > 0 ? remarkType?._id : ""
                  }
                />
              </div>
            </div>

            <div className="col-md-3 justify-content-end">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {managerRemarkList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {/* {count > 0 ? ( */}
        <PaginationView
          pagename={"counsellorRemark"}
          count={count}
          tableData={managerRemarkList ? managerRemarkList : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        {/* ) : null} */}

        <PaginationTable
          tableData={managerRemarkList ? managerRemarkList : []}
          pagename={"counsellorRemark"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "First Name",
            "Last Name",
            "Counsellor Name",
            "Status",
            "Call Duration",
            "TeamLead Remark",
            "Manager Remark",
          ]}
          handleClick={(data: any) => onClickLead(data)}
          handleEdit={(value: any, type: any) =>
            handleManagerRemarkView(value, type)
          }
        />
      </>
    );
  };

  const handleManagerRemarkView = (val: any, type: any) => {
    setOpenDeletePopup(true);
    if (type === "teamLeadRemark") {
      setIntorduction(val?.teamLeadAuditRemark?.remarkData[0]);
      setProspecting(val?.teamLeadAuditRemark?.remarkData[1]);
      setPreparation(val?.teamLeadAuditRemark?.remarkData[2]);
      setApproach(val?.teamLeadAuditRemark?.remarkData[3]);
      setPresentation(val?.teamLeadAuditRemark?.remarkData[4]);
      setHandlingObjections(val?.teamLeadAuditRemark?.remarkData[5]);
      setClosing(val?.teamLeadAuditRemark?.remarkData[6]);
      setFollowUp(val?.teamLeadAuditRemark?.remarkData[7]);
      setAuditRemark(val?.teamLeadAuditRemark?.overallRemark);
      setMode("view");
    } else if (type === "managerRemark") {
      setIntorduction(val?.managerLeadAuditRemark?.remarkData[0]);
      setProspecting(val?.managerLeadAuditRemark?.remarkData[1]);
      setPreparation(val?.managerLeadAuditRemark?.remarkData[2]);
      setApproach(val?.managerLeadAuditRemark?.remarkData[3]);
      setPresentation(val?.managerLeadAuditRemark?.remarkData[4]);
      setHandlingObjections(val?.managerLeadAuditRemark?.remarkData[5]);
      setClosing(val?.managerLeadAuditRemark?.remarkData[6]);
      setFollowUp(val?.managerLeadAuditRemark?.remarkData[7]);
      setAuditRemark(val?.managerLeadAuditRemark?.overallRemark);
      setMode("view");
    }
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
    setIntorduction("");
    setProspecting("");
    setPreparation("");
    setApproach("");
    setPresentation("");
    setHandlingObjections("");
    setClosing("");
    setFollowUp("");
    setAuditRemark("");
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?.studentLeadsId));
    navigate("../completeLeadDetail", {
      state: {
        pageName: "counsellorRemark",
        startDate,
        endDate,
      },
    });
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Manager Remark"} />
      <div className="col-12 formDiv formWidth">
        {renderSearchMode()}
        {renderListMode()}
      </div>
      {openDeletePopup ? (
        <RemarkPopup
          open={openDeletePopup}
          handleClose={handleCloseDelete}
          handleSubmitRestore={() => {}}
          title={"Remark"}
          mode={mode}
          introduction={introduction}
          prospecting={prospecting}
          preparation={preparation}
          approach={approach}
          presentation={presentation}
          handlingObjections={handlingObjections}
          closing={closing}
          followUp={followUp}
          auditRemark={auditRemark}
        />
      ) : (
        ""
      )}
    </div>
  );
}
