import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,ArcElement
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,ArcElement
);

interface props {
  data: any,
 
}


export default function pieChart({ data }: props) {
 
 
   return (
     <Pie data={data}  /> 
 
   );
}

