import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import { getState, getCity } from "../../Redux/Actions/bdeAdminPanelAction";
import {
  getBDCollegeList,
  getExecutiveByManagerID,
  getTLWebinarList,
} from "../../Redux/Actions/bdManagerPanelAction";

export default function PendingWebinarList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [executive, setExecutive] = useState<any>("");
  const [executiveList, setExecutiveList] = useState([]);
  const [ResetExecutive, setResetExecutive] = useState(false);
  const [tLWebinarList, setTLWebinarList] = useState([]);
  const [state, setState] = useState<any>("");
  const [stateList, setStateList] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  // const [upComingList, setupComingList] = useState([]);

  useEffect(() => {
    dispatch(
      getExecutiveByManagerID(
        "",
        (res: any) => {
          setExecutiveList(res);
        },
        () => {}
      )
    );
    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);
        },
        () => {}
      )
    );
    const postData = {
      startDate: startDate,
      endDate: endDate,
      status: 5,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getTLWebinarList(
        postData,
        (res: any) => {
          setTLWebinarList(res?.webinarData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  }, []);

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      executiveId: executive?._id,
      status: 5,
      skip: 0,
      limit: rowsPerPage,
      stateId: state?._id,
    };
    if (state.length === 0 && state === "") delete postData["stateId"];
    dispatch(
      getTLWebinarList(
        postData,
        (res: any) => {
          setTLWebinarList(res?.webinarData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const onChangeBDExecutive = (val: any) => {
    setExecutive(val);
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: 5,
        startDate: startDate,
        endDate: endDate,
        executiveId: executive?._id,
        stateId: state?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: 5,
        startDate: startDate,
        endDate: endDate,
        executiveId: executive?._id,
        stateId: state?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: 5,
        startDate: startDate,
        endDate: endDate,
        executiveId: executive?._id,
        stateId: state?._id,
      };
    }
    if (state.length === 0 && state === "") delete data["stateId"];

    dispatch(
      getTLWebinarList(
        data,
        (res: any) => {
          setTLWebinarList(res?.webinarData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeState = (val: any) => {
    setState(val);
  };

  const onClickLead = (data: any) => {
    navigate("/tlPendingwebinarDetails", {
      state: {
        pageName: "PendingWebinar",
        webinarId: data?._id,
        webinarData: data,
      },
    });
  };
  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        {/*  */}
        <div className="d-flex">
          <div className="col-md-4 row d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="BD Executive" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select BD Executive"
              setInputText={(value: any) => onChangeBDExecutive(value)}
              value={executive}
              options={executiveList}
              reset={ResetExecutive}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="State" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select State"
              setInputText={(value: any) => onChangeState(value)}
              value={state}
              options={stateList}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-4">
            <button className="btn btn-primary " type="submit">
              {" "}
              Search
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={tLWebinarList ? tLWebinarList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <div>
            <PaginationTable
              tableData={tLWebinarList ? tLWebinarList : []}
              pagename={"PendingWebinarTL"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Cordinator Name",
                "College Name",
                "State",
                "District",
                "Exam-Exa Stream",
                "Department",
                "Executive Name",
                "Meeting URL",
                "Registartion URL",
                "Lead Count",
                "Details",
              ]}
              edit={false}
              handleNavigate={onClickLead}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Pending Webinar"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12  formWidth">
          {SearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
