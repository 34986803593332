import React, { FormEvent } from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import localImages from "../../Assets/localImages";
import { BDECrmLogin, CrmLogin } from "../../Redux/Actions/AuthAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  let [hasErrorDiv] = useState("");
  const [type, setType] = useState("CRM");
  const [passwordShown, setPasswordShown] = useState(false);

  const handleSubmit = (event: FormEvent<any>) => {
    event.preventDefault();
    if (type === "CRM") {
      const postData = {
        username: username,
        password: password,
      };
      dispatch(
        CrmLogin(
          postData,
          (res: any) => {
            // if (
            //   res.data.role === 0 &&
            //   res?.data?.user?.username === "DATAOPERATOR"
            // ) {
            //   navigate("../CRMDataOperatorPanel");
            // }
            if (res.data.role === 13) {
              navigate("../CRMAdminPanel");
            } else if (
              res.data.role === 14 &&
              res?.data?.user?.username === "DATAOPERATOR"
            ) {
              navigate("../CRMDataOperatorPanel");
            } else if (
              res.data.role === 14 &&
              res?.data?.user?.username === "DATAENTRY"
            ) {
              navigate("../CRMDataEntryPanel");
            } else if (
              res.data.role === 3 ||
              res.data.role === 4 ||
              res.data.role === 5 ||
              res.data.role === 6 ||
              res.data.role === 7 ||
              res.data.role === 8 ||
              res.data.role === 9 ||
              res.data.role === 10
            ) {
              navigate("../CRMCounsellorPanel");
            } else if (res.data.role === 2) {
              navigate("../CRMTeamLeadPanel");
            } else if (res.data.role === 1) {
              navigate("../CRMManagerPanel");
            } else if (res.data.role === 0) {
              navigate("../SuperManager");
            }
            // else if (
            //   res.data.role === 0 &&
            //   res?.data?.user?.username === "CRMADMIN"
            // ) {
            //   navigate("../CRMAdminPanel");
            // }
          },
          () => {}
        )
      );
    } else if (type === "BDE") {
      const postData = {
        username: username,
        password: password,
      };
      dispatch(
        BDECrmLogin(
          postData,
          (res: any) => {
            if (res.data.role === 1) {
              navigate("../BDEAdminPanel", {
                state: { IsLogIn: "true" },
              });
            } else if (res.data.role === 4) {
              navigate("../BDEPanelDashbord", {
                state: { IsLogIn: "true" },
              });
            } else if (res.data.role === 3) {
              navigate("../BDTeamLead", {
                state: { IsLogIn: "true" },
              });
            }
          },
          () => {}
        )
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const validation = () => {
    return username === "" || password === "";
  };
  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const onClickForgotPassword = () => {
    navigate("../forgotPassword");
  };

  const onChangeType = (value: any) => {
    setType(value);
    setUsername("");
    setPassword("");
  };
  return (
    <div className="loginBg d-flex">
      <div className="loginContainer">
        <div className="logoDiv">
          <img
            src={localImages.logoCircle}
            className="img-fluid loginlogo "
            alt="logo"
          />
        </div>
        {/* <div className="loginheader py-2 d-flex justify-content-center">
        <img src={localImages.logo} className="img-fluid loginlogo "alt="logo" />
      </div> */}
        <form className="p-5" onSubmit={handleSubmit}>
          <h3 className="mb-5">Log in to your account</h3>
          <div className="radio-button-container mb-3">
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio1"
                name="radio-group"
                checked={type === "CRM" ? true : false}
                onChange={() => setType("CRM")}
              />
              <label
                className="radio-button__label"
                htmlFor="radio1"
                onClick={() => onChangeType("CRM")}
              >
                <span className="radio-button__custom"></span>
                CRM
              </label>
            </div>
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio2"
                name="radio-group"
                onChange={() => setType("BDE")}
                checked={type === "BDE" ? true : false}
              />
              <label
                className="radio-button__label"
                htmlFor="radio2"
                onClick={() => onChangeType("BDE")}
              >
                <span className="radio-button__custom"></span>
                BDE
              </label>
            </div>
          </div>
          <label>
            <input
              id="uname"
              type="text"
              // placeholder="Enter User Name"
              name="username"
              value={username}
              className="form-control"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              required
            />
            <span>Username</span>
          </label>

          <label className="mt-4">
            <input
              id="password"
              type={passwordShown ? "text" : "password"}
              // placeholder="Enter Password"
              name="password"
              value={password}
              className="form-control"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
            <span>Password</span>
            <span
              className="position-absolute pt-2 cursor viewPassword"
              onClick={handleToggle}
            >
              {passwordShown ? (
                <VisibilityIcon color="action" />
              ) : (
                <VisibilityOffIcon color="action" />
              )}{" "}
            </span>
          </label>

          <div className="col-auto d-flex mt-5 justify-content-between">
            <button
              type="submit"
              className={`btn btn-primary mb-1 me-1 px-4 btn-primary`}
              disabled={validation()}
            >
              {" "}
              Submit{" "}
            </button>
            <span
              className="mb-1 px-4 text-decoration-underline text-primary cursor"
              onClick={onClickForgotPassword}
            >
              {" "}
              Forgot Password{"..."}?
            </span>
          </div>

          {hasErrorDiv}
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            />
          </Stack>
        </form>
        {/* <div className="loginheader p-4 "></div> */}
      </div>
    </div>
  );
}

export default Login;
