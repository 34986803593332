
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface props {
  label: any,
  value: any,
  link?: any,
}



export default function SalesPanelSummeryGraph({ label, value, link }: props) {

 

  const navigate = useNavigate();
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      }
    }

  };
  const graphData = {
    labels: label,
    datasets: [
      {
        label: " ",
        data: value,
        backgroundColor: [
          "#1F77B4",
          "#1F77B4",
        ],
        borderColor: [
          "#1F77B4",
          "blue",
        ],
        borderWidth: 0.5,
        barPercentage: 0.2,
        links: link

      }

    ],
    options: {
      plugins : {
        legend: {   
          labels: {
            onHover: function (e:any) {
              e.native.target.style.cursor = 'pointer';
            },
            onLeave: function (e:any) {
              e.native.target.style.cursor = 'default';
            }
          }
        }
      }
    }


  };

  const chartRef: any = useRef();

  const onClick = (event: any) => {

    if (getElementsAtEvent(chartRef.current, event).length > 0) {

      const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0].datasetIndex
      const dataPoint = getElementsAtEvent(chartRef.current, event)[0].index
      navigate(graphData.datasets[datasetIndexNum].links[dataPoint])
    }


  }


  return (
    <Bar options={options} data={graphData} onClick={onClick} ref={chartRef} />

  );
}
