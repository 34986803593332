/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { useSelector } from 'react-redux';
import { setInitialState, updateCommonValues, updateMoreValues } from "../Redux/Actions/CommonAction";
import { store } from "../store";


const $http = axios.create({
  
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

const $httpNew = axios.create({
  baseURL: process.env.REACT_APP_ADMINBASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

$http.interceptors.response.use(
  (config: any) => config,
  (error: any) => {
    if (
      error?.message?.includes('403') // Block
    ) {
      handleApiError();
    } else {
      // CommonFunction.showSnackbar(message);
    }
   return Promise.reject(error);
  }
);

$httpNew.interceptors.response.use(
  (config: any) => config,
  (error: any) => {
    if (
      error?.message?.includes('403') // Block
    ) {
      handleApiError();
    } else {
      // CommonFunction.showSnackbar(message);
    }
   return Promise.reject(error);
  }
);


const handleApiError = async () => {
  const { dispatch } = store;
  localStorage.setItem("token", "");
          dispatch(updateCommonValues("authToken", ""));
         setAuthorizationToken("");
  //  await dispatch(setInitialState());
   await window.location.assign("/");
  return
};

// const handleApiError = () => {};

$http.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState: any = await store?.getState();
      if (getState) {
        const authToken = getState?.Auth?.authToken;
        if (authToken && authToken.length > 0) {
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

$httpNew.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState: any = await store?.getState();
      if (getState) {
        const authToken = getState?.Auth?.authToken;

        if (authToken && authToken.length > 0) {
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

const setAuthorizationToken = (token: any) => {
  if (token) {
    $http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

let status_code = {
  success: 200,
  successAction: 201,
  notFound: 204,
  badRequest: 400,
  Unauthorized: 401,
  invalid: 400,
  timeout: 408,
  userDelete: 410,
  serverError: 500,
};

const postApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .post(endPoint, JSON.stringify(params))
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postAdminApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $httpNew
    .post(endPoint, JSON.stringify(params))
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postUploadApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => { 
  $http
    .post(endPoint, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response);
    });
};

const putApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .put(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const getApiCall = (
  endPoint: string,
  paramsData: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .get(endPoint + paramsData, {})
    .then((response: any) => {
              successCallback(response);
          })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const getAdminApiCall = (
  endPoint: string,
  paramsData: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $httpNew
    .get(endPoint + paramsData, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const patchApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .patch(endPoint, JSON.stringify(params))
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const postUploadMedia = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .post(endPoint, params, {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postAdminUploadMedia = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $httpNew
    .post(endPoint, params, {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const deleteApiCall = (
  endPoint: string,
  paramsData: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .delete(endPoint + paramsData, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

export default {
  postApiCall,
  postAdminApiCall,
  postUploadApiCall,
  getApiCall,
  getAdminApiCall,
  patchApiCall,
  putApiCall,
  deleteApiCall,
  handleApiError,
  setAuthorizationToken,
  postUploadMedia,
  postAdminUploadMedia,
  status_code,
  $http,
};