import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import { Pagination } from "@mui/material";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import DatePicker from "../../Component/datePicker";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import {
  getEmployeetByManagerId,
  getUserActivityList,
} from "../../Redux/Actions/managerPanelAction";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function UserActivityTracker() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedInUserId, loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  const pageName = location.state?.pageName;

  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [counsellor, setCounsellor] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [counsellorList, setCounsellorList] = useState([]);
  const [userActivityTrackerData, setUserActivityTrackerData] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    if (loggedInUserRole === 1) {
      dispatch(
        getEmployeetByManagerId(
          loggedInUserId,
          (res: any) => {
            setManagerList(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(updateCommonValues("startDate", startDate));
      dispatch(updateCommonValues("endDate", endDate));
      if (pageName !== "leadDetail") {
        dispatch(updateCommonValues("selectedLeadId", ""));
        dispatch(updateCommonValues("selectedLeadName", ""));
        dispatch(updateCommonValues("selectedClickedDate", ""));
      }
      dispatch(
        getEmployeetByManagerId(
          loggedInUserId,
          (res: any) => {
            setCounsellorList(res?.data);
          },
          () => {}
        )
      );
      dispatch(
        getUserActivityList(
          {
            skip: 0,
            limit: rowsPerPage,
            startDate,
            endDate,
            teamLeadId: loggedInUserId,
          },
          (res: any) => {
            setCount(res?.count);
            setUserActivityTrackerData(res?.result);
          },
          () => {}
        )
      );
    }
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
      };
    }
    if (loggedInUserRole === 1) {
      if (manager) {
        data["teamLeadId"] = manager?._id;
      }
    } else if (loggedInUserRole === 2) {
      data["teamLeadId"] = loggedInUserId;
    }
    if (counsellor) {
      data["counsellorId"] = counsellor?._id;
    }
    dispatch(
      getUserActivityList(
        data,
        (res: any) => {
          setCount(res?.count);
          setUserActivityTrackerData(res?.result);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickDetails = (data: any) => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    dispatch(updateCommonValues("selectedLeadId", data?.leadId));
    dispatch(updateCommonValues("selectedLeadName", data?.studentName));
    dispatch(updateCommonValues("selectedClickedDate", data?.date));
    navigate("../userActivityDetails", {
      state: {
        leadMobileNo: data?.mobileNo,
        pageName: "userActivityTracker",
      },
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let postData: any = {
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate,
    };
    if (loggedInUserRole === 1) {
      if (manager) {
        postData["teamLeadId"] = manager?._id;
      }
    } else if (loggedInUserRole === 2) {
      postData["teamLeadId"] = loggedInUserId;
    }
    if (counsellor) {
      postData["counsellorId"] = counsellor?._id;
    }
    dispatch(
      getUserActivityList(
        postData,
        (res: any) => {
          setCount(res?.count);
          setUserActivityTrackerData(res?.result);
        },
        () => {}
      )
    );
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
  };

  const onChangeManager = (val: any) => {
    setManager(val);
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
  };

  const validation = () => {
    if (loggedInUserRole === 1) {
      return manager === "";
    }
  };

  const renderSearchMode = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="formWidth">
          <div className="d-flex justify-content-between mb-2">
            <div className="col-md-8 d-flex ">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-5">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-8 d-flex ms-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-5">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            {loggedInUserRole === 1 ? (
              <div className="col-md-8 d-flex me-2">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Manager" />
                </div>
                <div className="col-md-7">
                  <CustomDropdown
                    lableName="Select Manager"
                    setInputText={(value: any) => onChangeManager(value)}
                    value={manager}
                    options={managerList}
                    Isrequired={true}
                  />
                </div>
              </div>
            ) : null}
            <div className="col-md-8 d-flex me-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Counsellor" />
              </div>
              <div className="col-md-7">
                <CustomDropdown
                  lableName="Select Counsellor"
                  setInputText={(value: any) => onChangeCounsellor(value)}
                  value={counsellor}
                  options={counsellorList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2">
              <SubmitButton name={"Submit"} validation={validation()} />
            </div>
          </div>
        </div>
      </form>
    );
  };
  const renderListMode = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>

        <PaginationView
          pagename={"userActivityTrackerTeamLead"}
          count={count}
          tableData={userActivityTrackerData ? userActivityTrackerData : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />

        <PaginationTable
          tableData={userActivityTrackerData ? userActivityTrackerData : []}
          pagename={"userActivityTrackerTeamLead"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Name",
            "Current Page Activity",
            "Page Activity Time",
            "Date",
            "Counsellor Name",
            "Action",
          ]}
          handleNavigate={(value: any) => onClickDetails(value)}
        />
      </>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"USER ACTIVITY TRACKER"} />
      <div className="  p-3 formDiv">
        <div className="">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              {renderSearchMode()}
              {renderListMode()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
