export const SuperAdminSideBar = [
  {
    title: "DASHBOARD",
    path: "superAdminDashbord",
  },
  {
    title: "TEAM LEADS OVERVIEW",
    path: "teamLeadOverview",
  },

  {
    title: "TEAM ANALYSIS",
    subNav: [
      {
        title: "Lead Analysis",
        path: "sALeadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Enrolled Analysis",
        path: "sAEnrollAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Course Analysis",
        path: "saCourseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "References Generated Analysis",
        path: "saReferenceanalysisManager",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      // {
      //   title: "Leads Allotted Date",
      //   path: "leadAllotedBydate",
      //   cName: "sub-nav",
      // },
      {
        title: "Leads Allotted Date Analysis",
        path: "leadAllottedAnalysisbyDate",
        cName: "sub-nav",
      },
      
      {
        title: "Admission Date",
        path: "saLeadByAdmissionDate",
        cName: "sub-nav",
      },
      {
        title: "Exam Revenue Based On Admission Date",
        path: "smExamRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "Target Status",
        path: "smTargetStatus",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "COUPON CODE",
  //   path: "sACouponCode",
  // },
  {
    title: "MANAGE TEAM",
    subNav: [
      {
        title: "Active Employee",
        path: "saActiveEmployee",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SKIP DETAILS",
    path: "sMSkipDetails",
  },

  {
    title: "ATTENDANCE",
    subNav: [
      {
        title: "Attendance",
        path: "saAttendance",
        cName: "sub-nav",
      },

      {
        title: "Monthly Report",
        path: "saMonthlyReport",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DATA ANALYSIS",
    subNav: [
      {
        title: "Time Analysis",
        path: "timeAnalysisSuperManager",
        cName: "sub-nav",
      },
      {
        title: "Revenue Analysis",
        path: "revenueAnalysisSuperManager",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DAILY MEETING",
    subNav: [
      {
        title: "1 PM DAILY MEETING",
        path: "dailyMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INCENTIVE ALLOCATION",
    path: "sMIncentiveAllocation",
  },
];
