import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import GenaralTable from "../../Component/genaralTable";
import { useSelector } from "react-redux";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import moment from "moment";
import {
  getManagerCallRecord,
  getEmployeetByManagerId,
  getManagerDailyTalkTime,
} from "../../Redux/Actions/managerPanelAction";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function TimeAnalysisManager() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const [xLabelCallAll, setXLabelCallAll] = useState<any>([]);
  const [GraphdataCallAll, setGraphdataCallAll] = useState<any>([]);
  const [xLabelCallUnique, setXLabelCallUnique] = useState<any>([]);
  const [GraphdataCallUnique, setGraphdataCallUnique] = useState<any>([]);
  const [talkTimeList, setTalkTimeList] = useState<any>([]);
  const [talkTimeLabelList, setTalkTimeLabelList] = useState<any>([]);
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);

  const { loggedInUserId, loggedInUserRole, loggedInUserName } = useSelector(
    (state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserName: state.Auth.loggedInUserName,
    })
  );

  useEffect(() => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    dispatch(
      getEmployeetByManagerId(
        loggedInUserId,
        (res: any) => {
          setManagerList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const showGraphCallTotalConnected = (list: any) => {
    setXLabelCallTotal([]);
    setGraphdataCallTotal([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.totalConnectedCall?.achieved);
    });
    setXLabelCallTotal(labelList);
    setGraphdataCallTotal(graphDataList);
  };

  const showGraphCallAllConnected = (list: any) => {
    setXLabelCallAll([]);
    setGraphdataCallAll([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.oldConnectedCall?.achieved);
    });
    setXLabelCallAll(labelList);
    setGraphdataCallAll(graphDataList);
  };

  const showGraphCallUniqueConnected = (list: any) => {
    setXLabelCallUnique([]);
    setGraphdataCallUnique([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.uniqueConnectedCall?.achieved);
    });
    setXLabelCallUnique(labelList);
    setGraphdataCallUnique(graphDataList);
  };

  const onChangeManager = (value: any) => {
    setManager(value);
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <h4 className="fw-bold">Team Talk Time</h4>
        <GenaralTable
          tableData={talkTimeList ? talkTimeList : []}
          pagename={"timeAnalysis"}
          tableHead={talkTimeLabelList}
        />
      </div>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate,
      endDate,
    };
    dispatch(
      getManagerDailyTalkTime(
        manager?._id,
        postData,
        (res: any) => {
          let daylist = res?.dates;
          daylist?.unshift("Name");
          setTalkTimeLabelList(daylist);
          setTalkTimeList(res?.data);
        },
        () => {}
      )
    );

    const data = {
      startTime: startDate,
      endTime: endDate,
    };

    dispatch(
      getManagerCallRecord(
        manager?._id,
        data,
        (res: any) => {
          showGraphCallTotalConnected(res?.data);
          showGraphCallAllConnected(res?.data);
          showGraphCallUniqueConnected(res?.data);
        },
        () => {}
      )
    );
  };

  const getManagerData = (val: any) => {
    if (loggedInUserRole === 1) {
      navigate("../timeAnalysisOverview", {
        state: {
          pageName: "timeAnalysisOverview",
          counsellorId: manager?._id,
        },
      });
    }
  };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Time Analysis"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-2">
          <form className="d-flex px-5 mx-5" onSubmit={handleSubmit}>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div className="col-md-4 d-flex mb-3 pe-3 mt-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Manager" />
              </div>
              <div className="col-md-8 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                />
              </div>
            </div>
            <div className=" col-md-3 text-center mb-2  mt-2 me-4 ">
              <SubmitButton name={"Submit"} />
            </div>
          </form>

          <>
            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Total Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                {/* <SalesPanelSummeryGraph
                  label={xLabelCallTotal}
                  value={GraphdataCallTotal}
                  link={linkList}
                /> */}
                <SalesPanelSatckGraph
                  label={xLabelCallTotal}
                  value={GraphdataCallTotal}
                  getManagerData={(val: any) => getManagerData(val)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Old Connected calls</h4>
                <CallRecordedGraph
                  label={xLabelCallAll}
                  value={GraphdataCallAll}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Unique Connected calls</h4>
                <CallRecordedGraph
                  label={xLabelCallUnique}
                  value={GraphdataCallUnique}
                />
              </div>
            </div>
          </>

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
