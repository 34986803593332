import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import InputField from "../../Component/inputField";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  containsNumber,
  length_Fifty,
  length_Ten,
  length_Three,
  length_Two,
  startsWithSpace,
  validateFullName,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import CustomDropdown from "../../Component/customDropdown";
import Button from "../../Component/button";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import ToggleSwitchbtn from "../../Component/toggleSwitchBtn";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  getEmployeeRoleList,
  getManagerList,
  getSourceTypeList,
  updateEmployee,
} from "../../Redux/Actions/leadManagementAction";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import { useSelector } from "react-redux";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
import { getSMManagerList } from "../../Redux/Actions/superManagerAction";

const initialVal: {
  sourceType: string;
  baseCourseId: string;
  examTypeId: string;
  isActive: boolean;
}[] = [
  {
    sourceType: "",
    baseCourseId: "",
    examTypeId: "",
    isActive: false,
  },
];

export default function SAActiveEmployee() {
  const dispatch = useDispatch();
  const [isSearchEmployee, setSearchEmployee] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [firstName, setFirstname] = useState<any>("");
  const [lastName, setLastname] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [currentSalary, setCurrentSalary] = useState<any>("");
  const [userName, setUsername] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [status, setStatus] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");
  const [empId, setEmpId] = useState<any>("");
  const [courseExamTypeInfoArr, setCourseExamTypeInfoArr] =
    useState<any>(initialVal);

  const [employeeList, setEmployeeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);

  const [resetRole, setResetRole] = useState(false);
  const [resetManager, setResetManager] = useState(false);

  const [counsellor, setCounsellor] = useState<any>();
  const [counsellorList, setCounsellorList] = useState([]);
  const [resetCounsellor, setResetCounsellor] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [resetExam, setResetExam] = useState(false);
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState(false);

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  const { loggedInUserRole, loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
      };
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
    getEmpList("active");
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
  }, []);

  const getEmpList = (data: string) => {
    setCount(0);
    setEmployeeList([]);
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
    setSelectedStatus(val);
    if (val === 1) {
      getEmpList("active");
    } else if (val === 2) {
      getEmpList("deleted");
    }
  };

  const handleEdit = (value: any) => {
    setSearchEmployee(true);
    setisEditMode(true);
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    setDate(value?.yoj);
    dispatch(
      getEmployeeRoleList((res: any) => {
        setRoleList(res);
        setRole(value?.role);
      })
    );
    dispatch(
      getManagerList(
        value?.role?._id,
        (res: any) => {
          setManagerList(res);
          setManager(value?.managerId);
        },
        () => {}
      )
    );
    let newArr = value?.courseExamTypeInfoList?.map((item: any) => {
      return {
        baseCourseId: item?.baseCourseId?._id,
        examTypeId: item?.examTypeId?._id,
        sourceType: item?.sourceType?._id,
        isActive: item?.isActive,
      };
    });
    setCourseExamTypeInfoArr(newArr);
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    setCurrentSalary(value?.salary);
    setUsername(value?.username);
    setEmail(value?.email);
    setPassword(value?.password);
    setMob(value?.contactNo);
    setStatus(value?.status);
    setUniqueId(value?.uniqueId?.toUpperCase());
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
        // setSource(value?.sourceType);
      })
    );
  };

  const renderAddMode = () => {
    return (
      <>
        <h2 className="text-primary">Edit Employee</h2>
        <form
          className="col-md-10  shadow-lg py-4 px-3"
          onSubmit={handleUpdate}
        >
          <div className="row d-flex">
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => onchangeFirstName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => onchangelastName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>

            <div className="col-md-4 d-flex my-3">
              <div className="col-5 mt-2">
                <LabelField lableName="Date Of Joining" />
              </div>
              <DatePicker
                labelName={"Date of Joining"}
                value={date}
                setInputText={(value: string) => setDate(value)}
                Isrequired={true}
                defaultValue={date}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4  my-3">
              <CustomDropdown
                lableName="Select Role"
                setInputText={(value: any) => onChangeRole(value)}
                value={role}
                options={roleList}
                Isrequired={true}
                reset={resetRole}
                defaultValue={role?._id}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3 ">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                Isrequired={true}
                reset={resetManager}
                defaultValue={manager?._id}
                disabled={isEditMode}
              />
            </div>
            {/* <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Current Salary"
                value={currentSalary}
                onChangeInput={(value: any) => onchangeCurrentSalary(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
                disabled={isEditMode}
              />
            </div> */}
            {courseExamTypeInfoArr?.map((item: any, index: number) => {
              const { sourceType, baseCourseId, examTypeId, isActive } = item;
              return (
                <div className="position-relative" key={index}>
                  {/* <span className="d-flex justify-content-end removeButton">
                    {courseExamTypeInfoArr.length === 1 ? null : (
                      <HighlightOffIcon
                        className="cursor"
                        color="primary"
                        fontSize="small"
                        onClick={() => handleArrClose(index)}
                      />
                    )}
                  </span> */}
                  <div className="d-flex position-relative">
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Source"
                        setInputText={(value: any) =>
                          onChange(index, value, "source")
                        }
                        value={sourceType}
                        options={sourceList}
                        Isrequired={true}
                        // reset={resetSource}
                        defaultValue={sourceList?.length > 0 ? sourceType : ""}
                        disabled={isEditMode}
                      />
                    </div>
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Exam"
                        setInputText={(value: any) =>
                          onChange(index, value, "exam")
                        }
                        value={examTypeId}
                        options={examList}
                        Isrequired={true}
                        // reset={resetExam}
                        defaultValue={examList?.length > 0 ? examTypeId : ""}
                        disabled={isEditMode}
                      />
                    </div>
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Exam Stream"
                        setInputText={(value: any) =>
                          onChange(index, value, "examStream")
                        }
                        value={baseCourseId}
                        options={examStreamList}
                        Isrequired={true}
                        // reset={resetExamStream}
                        defaultValue={
                          examStreamList?.length > 0 ? baseCourseId : ""
                        }
                        disabled={isEditMode}
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <ToggleSwitchbtn
                        lableName="Active"
                        getToggleval={(value: any) => {
                          onChange(index, value, "isActive");
                        }}
                        isActive={isActive}
                        disabled={isEditMode}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {/* {role?._id === 1 || role?._id === 2 || role?._id === 9 ? null : (
              <div className="d-flex justify-content-end">
                <Button name={"Add More"} handleClick={onPressAdd} />
              </div>
            )} */}

            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter User Name"
                value={userName}
                onChangeInput={(value: any) => onchangeUserName(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Email"
                value={email}
                onChangeInput={(value: any) => onchangeEmail(value)}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangePassword(value)}
                disabled={isEditMode}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Mobile Number"
                value={mob}
                onChangeInput={(value: any) => onChangeContact(value)}
                maxlength={length_Ten}
                minlength={length_Ten}
                disabled={isEditMode}
                isRequired={true}
              />
            </div>

            <div className="col-md-4 my-3">
              <ToggleSwitchbtn
                lableName="Status"
                getToggleval={(value: any) => {
                  onChangeStatus(value);
                }}
                isActive={status}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Unique Id"
                value={uniqueID}
                onChangeInput={(value: any) => onchangeUniqueId(value)}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3"></div>
          </div>
          <div className="d-flex justify-content-end me-3">
            <div className="me-2">
              <Button name={"Cancel"} handleClick={cancelEditEmployee} />
            </div>
            <SubmitButton name={"Save & Update"} />
          </div>
        </form>
      </>
    );
  };

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    setCount(0);
    setEmployeeList([]);
    let data = {};
    if (loggedInUserRole === 1) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId: manager?._id,
        counsellorId: val?._id,
      };
    } else if (loggedInUserRole === 2) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
        counsellorId: val?._id,
      };
    }
  };
  const onChangeExam = (val: any) => {
    setExam(val);
    let data = {};
    if (loggedInUserRole === 1) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId: manager?._id,
        counsellorId: counsellor?._id,
        examTypeId: val?._id,
      };
    } else if (loggedInUserRole === 2) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
        counsellorId: counsellor?._id,
        examTypeId: val?._id,
      };
    }
  };

  const onchangeFirstName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFirstname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };
  const onchangelastName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLastname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };
  const onChangeRole = (val: any) => {
    setRole(val);
    setResetRole(false);
  };
  const onchangeCurrentSalary = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setCurrentSalary(val);
      }
    } else {
      setCurrentSalary(val);
    }
  };

  const onChangeExamStream = (val: string) => {};
  const onchangeUserName = (val: string) => {
    setUsername(val);
  };
  const onchangeEmail = (val: string) => {
    setEmail(val);
  };
  const onChangePassword = (val: string) => {
    setPassword(val);
  };
  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob(val);
      }
    } else {
      setMob(val);
    }
  };

  const onChange = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(courseExamTypeInfoArr));
    if (key === "source") {
      newArrr[index].sourceType = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    } else if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      dispatch(
        getExamStreamList(`${value._id}`, (res: any) => {
          setExamStreamList(res);
        })
      );
    } else if (key === "examStream") {
      newArrr[index].baseCourseId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    } else if (key === "isActive") {
      if (value.target.checked) {
        newArrr[index].isActive = true;
        setCourseExamTypeInfoArr(newArrr);
      } else {
        newArrr[index].isActive = false;
        setCourseExamTypeInfoArr(newArrr);
      }
    }
  };

  const onChangeStatus = (event: any) => {
    if (event.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const onchangeUniqueId = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const cancelEditEmployee = () => {
    setSearchEmployee(false);
    setisEditMode(false);
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let postData = {
      firstName: firstName,
      lastName: lastName,
      yoj: date,
      role: role?._id,
      managerId: manager?._id,
      salary: currentSalary,
      email: email,
      contactNo: mob,
      status: status ? 1 : 0,
      courseExamTypeInfoList: courseExamTypeInfoArr,
    };
    if (postData["managerId"] === "" || postData["managerId"] === undefined) {
      delete postData["managerId"];
    }
    if (
      postData["role"] === 3 ||
      postData["role"] === 4 ||
      postData["role"] === 5 ||
      postData["role"] === 6 ||
      postData["role"] === 7 ||
      postData["role"] === 8
    ) {
      if (
        (postData["courseExamTypeInfoList"][0]["sourceType"] === "" ||
          postData["courseExamTypeInfoList"][0]["sourceType"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["examTypeId"] === "" ||
          postData["courseExamTypeInfoList"][0]["examTypeId"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["baseCourseId"] === "" ||
          postData["courseExamTypeInfoList"][0]["baseCourseId"] === undefined)
      ) {
        delete postData["courseExamTypeInfoList"];
      }
    }
    if (
      postData["role"] === 1 ||
      postData["role"] === 2 ||
      postData["role"] === 9
    ) {
      delete postData["courseExamTypeInfoList"];
    }
  };

  const renderSearchMode = () => {
    return (
      <div className="d-flex row col-12">
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Manager" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Manager"
              setInputText={(value: any) => onChangeManager(value)}
              value={manager}
              options={managerList}
              // Isrequired={true}
              reset={resetManager}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Team Lead" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Team Lead"
              setInputText={(value: any) => onChangeTeamLead(value)}
              value={teamLead}
              options={teamLeadList}
              // Isrequired={true}
              reset={resetTeamLead}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3  ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Counsellor" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Counsellor"
              setInputText={(value: any) => onChangeCounsellor(value)}
              value={counsellor}
              options={counsellorList}
              // Isrequired={true}
              reset={resetCounsellor}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
              // Isrequired={true}
              reset={resetExam}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {employeeList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {/* {count > 0 ? ( */}
        <PaginationView
          pagename={"TeamLeadManageEmployee"}
          count={count}
          tableData={employeeList ? employeeList : []}
          tabs={["Active", "Deleted"]}
          onTabChange={(e: any, index: any) => showData(e, index)}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        {/* ) : null} */}

        {selectedStatus === 1 ? (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"Employee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Source - Exam Name - Exam Stream",
              "Experience (Months)",
              "Status",
              "Edit",
              "",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            // handleDelete={(value: any) => handleDelete(value)}
          />
        ) : (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"Employee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Source - Exam Name - Exam Stream",
              "Experience (Months)",
              "Status",
              "Restore",
              "",
            ]}
            edit={true}
            // handleEdit={(value: any) => handleRestore(value)}
          />
        )}
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Active Counsellor"} />
      <div className="formDiv">
        <div className="col-md-12 px-4 mx-0 mt-3">
          <div className="col-md-11  d-flex justify-content-between p-3">
            {!isSearchEmployee ? renderSearchMode() : ""}
          </div>
          {!isSearchEmployee && !isEditMode
            ? renderListMode()
            : renderAddMode()}
        </div>
      </div>
    </div>
  );
}
