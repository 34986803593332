import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getEmployeetByManagerId,
  getManagerRemarkAnalysis,
} from "../../Redux/Actions/managerPanelAction";
export default function RemarkAnalysisManager() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [counsellor, setCounsellor] = useState<any>("");
  const [manager, setManager] = useState<any>("");

  const [analysisList, setAnalysisList] = useState<any>([]);
  const [managerList, setManagerList] = useState([]);
  const [counsellorList, setCounsellorList] = useState([]);

  const [resetManager, setResetManager] = useState<any>(false);
  const [resetCounsellor, setResetCounsellor] = useState<any>(false);

  const { loggedInUserId } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    dispatch(
      getEmployeetByManagerId(
        loggedInUserId,
        (res: any) => {
          setManagerList(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getManagerRemarkAnalysis(
        {
          startDate,
          endDate,
          managerId: loggedInUserId,
          managerLeadRemark: true,
        },
        (res: any) => {
          setAnalysisList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val);
    setResetManager(false);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    setResetCounsellor(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData: any = {
      startDate,
      endDate,
      managerId: loggedInUserId,
      managerLeadRemark: true,
    };
    if (manager) {
      postData["teamLeadId"] = manager?._id;
    }
    if (counsellor) {
      postData["counsellorId"] = counsellor?._id;
    }
    dispatch(
      getManagerRemarkAnalysis(
        postData,
        (res: any) => {
          setAnalysisList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
  };

  const renderSearchMode = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between">
          <div className="col-md-3 d-flex me-1 mt-3">
            <div className="col-md-4 d-flex">
              <LabelField lableName="Start Date :" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex me-1 mt-3">
            <div className="col-md-4 d-flex">
              <LabelField lableName="End Date :" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex me-1 mt-3">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Manager :" />
            </div>
            <div className="col-md-8 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
                reset={resetManager}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-md-3 d-flex me-1 mt-3">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Counsellor :" />
            </div>
            <div className="col-md-8 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Counsellor"
                setInputText={(value: any) => onChangeCounsellor(value)}
                value={counsellor}
                options={counsellorList}
                // Isrequired={true}
                reset={resetCounsellor}
              />
            </div>
          </div>
          <div className="text-center mt-3">
            <SubmitButton name={"Submit"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <div className="salesTable table-responsive">
        <table className="w-100 mb-2">
          <thead>
            <tr>
              <th rowSpan={2}>Counsellor Name</th>
              <th>Total Points</th>
              <th>Introduction</th>
              <th>Prospecting</th>
              <th>Preparation</th>
              <th>Approach</th>
              <th>Presentation</th>
              <th>Handling Objection</th>
              <th>Closing</th>
              <th>Follow Up</th>
            </tr>
          </thead>
          <tbody>
            {analysisList?.map((item: any, index: any) => {
              return (
                <tr>
                  <td>{item?.name ? item?.name : ""}</td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.totalRemark
                      ? item["remarkAnalysis"]?.totalRemark
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.introduction
                      ? item["remarkAnalysis"]?.introduction
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.prospecting
                      ? item["remarkAnalysis"]?.prospecting
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.preparation
                      ? item["remarkAnalysis"]?.preparation
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.approach
                      ? item["remarkAnalysis"]?.approach
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.presentation
                      ? item["remarkAnalysis"]?.presentation
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.handlingObjections
                      ? item["remarkAnalysis"]?.handlingObjections
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.closing
                      ? item["remarkAnalysis"]?.closing
                      : "-"}
                  </td>
                  <td className="cursor">
                    {item["remarkAnalysis"]?.followUp
                      ? item["remarkAnalysis"]?.followUp
                      : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Remark Analysis"} />
      <div className="col-md-12 p-2 row">
        <div className="formDiv">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
