import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InputFieldRemark from "./inputFieldRemark";
import LabelField from "./labelField";

interface props {
  open: boolean;
  handleClose: Function;
  mode?: any;
  title?: any;
  handleSubmitRestore: Function;
  introduction?: any;
  prospecting?: any;
  preparation?: any;
  approach?: any;
  presentation?: any;
  handlingObjections?: any;
  closing?: any;
  followUp?: any;
  auditRemark?: any;
}

export default function RemarkPopup(props: props) {
  const {
    open,
    handleClose,
    mode,
    title,
    handleSubmitRestore,
    introduction,
    prospecting,
    preparation,
    approach,
    presentation,
    handlingObjections,
    closing,
    followUp,
    auditRemark,
  } = props;

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldivChat p-3">
            <div className="col mb-4 d-flex justify-content-between ">
              <p className="fw-bold">
                <span style={{ color: "#3F51B5" }}> {title}</span>
              </p>
              <ClearOutlinedIcon
                className="colorPurple cursor"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className="form-check">
              <div>
                <InputFieldRemark
                  id={"introduction"}
                  labelName={"Introduction"}
                  placeholder={"Enter Introduction"}
                  value={introduction}
                  disabled={true}
                  mode={mode}
                />
                <InputFieldRemark
                  id={"prospecting"}
                  labelName={"Prospecting"}
                  placeholder={"Enter Prospecting"}
                  value={prospecting}
                  disabled={true}
                  mode={mode}
                />
                <InputFieldRemark
                  id={"preparation"}
                  labelName={"Preparation"}
                  placeholder={"Enter Preparation"}
                  value={preparation}
                  disabled={true}
                  mode={mode}
                />
                <InputFieldRemark
                  id={"approach"}
                  labelName={"Approach"}
                  placeholder={"Enter Approach"}
                  value={approach}
                  disabled={true}
                  mode={mode}
                />
                <InputFieldRemark
                  id={"presentation"}
                  labelName={"Presentation"}
                  placeholder={"Enter Presentation"}
                  value={presentation}
                  disabled={true}
                  mode={mode}
                />
                <InputFieldRemark
                  id={"handlingObjections"}
                  labelName={"Handling Objections"}
                  placeholder={"Enter Handling Objections"}
                  value={handlingObjections}
                  disabled={true}
                  mode={mode}
                />
                <InputFieldRemark
                  id={"closing"}
                  labelName={"Closing"}
                  placeholder={"Enter Closing"}
                  value={closing}
                  disabled={true}
                  mode={mode}
                />
                <InputFieldRemark
                  id={"followUp"}
                  labelName={"Follow Up"}
                  placeholder={"Enter Follow Up"}
                  value={followUp}
                  disabled={true}
                  mode={mode}
                />
                <div className="d-flex col-12">
                  <div className="col-2">
                    <LabelField lableName="Overall Remark" />
                  </div>
                  <div className="d-flex col-10">
                    <textarea
                      className="form-control"
                      placeholder={"Comment"}
                      value={auditRemark}
                      disabled={true}
                    />
                    <span className="required ms-2 fs-6">*</span>
                  </div>
                </div>
              </div>
              {title !== "Manager Remark" ? (
                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => {
                      handleClose();
                    }}
                    type="submit"
                    className="btn btn-outline-dark mb-3 submitbtn me-3"
                  >
                    Cancel
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
