interface props {
    placeholder: string,
    value?: string | number,
    maxlength?: number,
    minlength?: number,
    onChangeInput: Function,
    isRequired?: boolean,
    disabled?: boolean
  }
  
  export default function InputField({ placeholder, value, maxlength, minlength, onChangeInput, isRequired, disabled }: props) {
    return (
      <div className="d-flex mb-2 align-items-center">
        <input
          type="text"
          placeholder={placeholder}
          required={isRequired}
          value={value}
          className="form-control inputField"
          onChange={(value) => onChangeInput(value.target.value)}
          maxLength={maxlength}
          minLength={minlength}
          disabled={disabled}
        />
        {isRequired ? <span className="required ms-2 fs-6">*</span> : null}
      </div>
    )
  }
  