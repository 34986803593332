import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import {
  getDashboardCallRecords,
  getDashboardIncomingRecords,
  getDashboardRecords,
  getDashboardRevenueRecords,
  getDashboardStatusRecords,
  getDashboardTotalRevenue,
} from "../../Redux/Actions/counsellorPanelAction";
import { useSelector } from "react-redux";
import moment from "moment";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function CounsellorDashbord() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [revenue, setRevenue] = useState<any>(0);
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [newOppourtunity, setNewOppourtunity] = useState<number>(0);
  const [reEnquired, setReEnquired] = useState<number>(0);
  const [enrolled, setEnrolled] = useState<number>(0);
  const [notInterested, setNotInterested] = useState<number>(0);
  const [expired, setExpired] = useState<number>(0);
  const [cnrLead, setCNRLead] = useState<number>(0);
  const [prospectToday, setProspectToday] = useState<number>(0);
  const [interestedToday, setInterestedToday] = useState<number>(0);
  const [callBackToday, setCallBackToday] = useState<number>(0);
  const [callRejectToday, setCallRejectToday] = useState<number>(0);
  const [overdueProspect, setOverdueProspect] = useState<number>(0);
  const [overdueInterested, setOverdueInterested] = useState<number>(0);
  const [overdueCallBack, setOverdueCallBack] = useState<number>(0);
  const [overdueCNR, setOverdueCNR] = useState<number>(0);
  const [overdueCallReject, setOverdueCallReject] = useState<number>(0);
  const [leadInvalid, setLeadInvalid] = useState<number>(0);
  const [alreadyAdmissionDone, setAlreadyAdmissionDone] = useState<number>(0);
  const [welcomeCallDone, setWelcomeCallDone] = useState<number>(0);
  const [welcomeCallReference, setWelcomeCallReference] = useState<number>(0);
  const [followUp, setFollowUp] = useState<number>(0);
  const [interestedForUpselling, setInterestedForUpselling] =
    useState<number>(0);
  const [prospectForUpselling, setProspectForUpselling] = useState<number>(0);
  const [technicalIssue, setTechnicalIssue] = useState<number>(0);
  const [finalCNR, setFinalCNR] = useState<number>(0);

  const [xLabel1, setXLabel1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [Graphdata2, setGraphdata2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);

  const [xLabel4, setXLabel4] = useState<any>([]);
  const [xLabel5, setXLabel5] = useState<any>([]);
  const [xLabel6, setXLabel6] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [Graphdata6, setGraphdata6] = useState<any>([]);

  const { loggedInUserId, loggedInUserManagerId, loggedInUserRole } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserManagerId: state.Auth.loggedInUserManagerId,
      loggedInUserRole: state.Auth.loggedInUserRole,
    }));

  useEffect(() => {
    onChangeGetRevenue();
    dispatch(
      getDashboardStatusRecords(
        loggedInUserId,
        (res: any) => {
          setNewOppourtunity(res?.data?.newOpportunity);
          setReEnquired(res?.data?.transferred);
          setEnrolled(res?.data?.enrolled);
          setNotInterested(res?.data?.notInterested);
          setExpired(res?.data?.upcomingExpire);
          setCNRLead(res?.data?.cnrCount);
          setProspectToday(res?.data?.prospect_today);
          setInterestedToday(res?.data?.interested_today);
          setCallBackToday(res?.data?.callback_today);
          setCallRejectToday(res?.data?.callReject_today);
          setOverdueProspect(res?.data?.prospect_overdue);
          setOverdueInterested(res?.data?.interested_overdue);
          setOverdueCallBack(res?.data?.callback_overdue);
          setOverdueCNR(res?.data?.cnr_overdue);
          setOverdueCallReject(res?.data?.callReject_overdue);
          setLeadInvalid(res?.data?.leadInvalid);
          setAlreadyAdmissionDone(res?.data?.alreadyAdmissionDone);
          setWelcomeCallDone(res?.data?.welcomeCallDone);
          setWelcomeCallReference(res?.data?.welcomeCallReference);
          setFollowUp(res?.data?.followUp);
          setInterestedForUpselling(res?.data?.interestedForUpselling);
          setProspectForUpselling(res?.data?.prospectForUpselling);
          setTechnicalIssue(res?.data?.technicalIssue);
          setFinalCNR(res?.data?.final_cnr);
        },
        () => {}
      )
    );
  }, []);

  const onChange = (value: string) => {
    if (value === "newOppourtunity") {
      navigate("../newOpportunity");
    } else if (value === "reEnquired") {
      navigate("../reEnquired");
    } else if (value === "enrolled") {
      navigate("../enrolledLead");
    } else if (value === "notInterested") {
      navigate("../notIntrestedLead");
    } else if (value === "expired") {
      navigate("../expired");
    } else if (value === "cnrLead") {
      navigate("../cnrLead");
    } else if (value === "prospectToday") {
      navigate("../prospectLead");
    } else if (value === "interestedToday") {
      navigate("../interested");
    } else if (value === "callBackToday") {
      navigate("../callBackLead");
    } else if (value === "overdueProspect") {
      navigate("../overdueProspect");
    } else if (value === "overdueInterested") {
      navigate("../overdueInterested");
    } else if (value === "overdueCallBack") {
      navigate("../overdueCallback");
    } else if (value === "overdueCNR") {
      navigate("../overdueCNR");
    } else if (value === "leadInvalid") {
      navigate("../leadInvalid");
    } else if (value === "alreadyAdmissionDone") {
      navigate("../alreadyAdmissionDone");
    } else if (value === "welcomeCallDone") {
      navigate("../welcomeCallDone");
    } else if (value === "welcomeCallReference") {
      navigate("../welcomeCallReference");
    } else if (value === "followUp") {
      navigate("../followUp");
    } else if (value === "interestedForUpselling") {
      navigate("../interestedForUpselling");
    } else if (value === "prospectForUpselling") {
      navigate("../prospectForUpselling");
    } else if (value === "technicalIssue") {
      navigate("../technicalIssue");
    } else if (value === "finalCNR") {
      navigate("../finalCNR");
    } else if (value === "Summary") {
      navigate("../summary");
    } else if (value === "callRejectToday") {
      navigate("../callRejectToday");
    } else if (value === "overdueCallReject") {
      navigate("../overdueCallReject");
    }
  };

  const onChangeGetRevenue = () => {
    let data = {
      startDate,
      endDate,
    };
    dispatch(
      getDashboardTotalRevenue(
        loggedInUserId,
        data,
        (res: any) => {
          setRevenue(res?.totalRevenue);
        },
        () => {}
      )
    );
    dispatch(
      getDashboardRevenueRecords(
        loggedInUserId,
        data,
        (res: any) => {
          // Revenue
          for (let i = 0; i <= xLabel1?.length; i++) {
            xLabel1?.pop();
            Graphdata1?.pop();
          }
          if (res) {
            if (res[0]?.target >= 0) {
              xLabel1?.push("Target");
              Graphdata1?.push(res[0]?.target);
            }
            if (res[0]?.achieved >= 0) {
              xLabel1?.push("Achieved");
              Graphdata1?.push(res[0]?.achieved);
            }
          }
        },
        () => {}
      )
    );

    const postData = {
      startTime: startDate,
      endTime: endDate,
    };
    if (loggedInUserRole === 5) {
      dispatch(
        getDashboardIncomingRecords(
          loggedInUserId,
          postData,
          (res: any) => {
            // Calling Time
            for (let i = 0; i <= xLabel2?.length; i++) {
              xLabel2?.pop();
              Graphdata2?.pop();
            }
            if (res?.callTime) {
              if (res?.callTime?.target >= 0) {
                xLabel2?.push("Target");
                Graphdata2?.push(res?.callTime?.target);
              }
              if (res?.callTime?.achieved >= 0) {
                xLabel2?.push("Achieved");
                Graphdata2?.push(res?.callTime?.achieved);
              }
            }
            // Reference
            for (let i = 0; i <= xLabel3?.length; i++) {
              xLabel3?.pop();
              Graphdata3?.pop();
            }
            if (res?.reference) {
              if (res?.reference?.target >= 0) {
                xLabel3?.push("Target");
                Graphdata3?.push(res?.reference?.target);
              }
              if (res?.reference?.achieved >= 0) {
                xLabel3?.push("Achieved");
                Graphdata3?.push(res?.reference?.achieved);
              }
            }
          },
          () => {}
        )
      );
    } else if (loggedInUserRole !== 5) {
      dispatch(
        getDashboardRecords(
          loggedInUserId,
          postData,
          (res: any) => {
            // Calling Time
            for (let i = 0; i <= xLabel2?.length; i++) {
              xLabel2?.pop();
              Graphdata2?.pop();
            }
            if (res?.callTime) {
              if (res?.callTime?.target >= 0) {
                xLabel2?.push("Target");
                Graphdata2?.push(res?.callTime?.target);
              }
              if (res?.callTime?.achieved >= 0) {
                xLabel2?.push("Achieved");
                Graphdata2?.push(res?.callTime?.achieved);
              }
            }
            // Reference
            for (let i = 0; i <= xLabel3?.length; i++) {
              xLabel3?.pop();
              Graphdata3?.pop();
            }
            if (res?.reference) {
              if (res?.reference?.target >= 0) {
                xLabel3?.push("Target");
                Graphdata3?.push(res?.reference?.target);
              }
              if (res?.reference?.achieved >= 0) {
                xLabel3?.push("Achieved");
                Graphdata3?.push(res?.reference?.achieved);
              }
            }
          },
          () => {}
        )
      );
    }

    dispatch(
      getDashboardCallRecords(
        loggedInUserId,
        postData,
        (res: any) => {
          // Total Connected Calls
          for (let i = 0; i <= xLabel4?.length; i++) {
            xLabel4?.pop();
            Graphdata4?.pop();
          }
          if (res?.totalConnectedCall) {
            if (res?.totalConnectedCall?.target >= 0) {
              xLabel4?.push("Target");
              Graphdata4?.push(res?.totalConnectedCall?.target);
            }
            if (res?.totalConnectedCall?.achieved >= 0) {
              xLabel4?.push("Achieved");
              Graphdata4?.push(res?.totalConnectedCall?.achieved);
            }
          }
          // Unique Connected Calls
          for (let i = 0; i <= xLabel5?.length; i++) {
            xLabel5?.pop();
            Graphdata5?.pop();
          }
          if (res?.uniqueConnectedCall) {
            if (res?.uniqueConnectedCall?.target >= 0) {
              xLabel5?.push("Target");
              Graphdata5?.push(res?.uniqueConnectedCall?.target);
            }
            if (res?.uniqueConnectedCall?.achieved >= 0) {
              xLabel5?.push("Achieved");
              Graphdata5?.push(res?.uniqueConnectedCall?.achieved);
            }
          }
          // Old Connected Calls
          for (let i = 0; i <= xLabel6?.length; i++) {
            xLabel6?.pop();
            Graphdata6?.pop();
          }
          if (res?.oldConnectedCall) {
            if (res?.oldConnectedCall?.target >= 0) {
              xLabel6?.push("Target");
              Graphdata6?.push(res?.oldConnectedCall?.target);
            }
            if (res?.oldConnectedCall?.achieved >= 0) {
              xLabel6?.push("Achieved");
              Graphdata6?.push(res?.oldConnectedCall?.achieved);
            }
          }
        },
        () => {}
      )
    );
  };

  const renderGraphMode1 = () => {
    return (
      <div className="mt-3 mx-3    p-2 cursor">
        {/* <div className="d-flex mt-5 mx-5">
          <div className="col-md-4 d-flex mb-3 me-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date" />
            </div>
            <div className="col-md-9 ">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => setStartDate(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => setEndDate(value)}
              />
            </div>
          </div>
        </div> */}
        <div className="d-flex mx-5 mt-3">
          <div className="col-md-4 pe-3 ">
            <div className="boxshadow  p-4  ">
              <BarGraph label={xLabel1} value={Graphdata1} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Revenue</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <BarGraph label={xLabel2} value={Graphdata2} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Calling Time</label>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <BarGraph label={xLabel3} value={Graphdata3} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Reference</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGraphMode2 = () => {
    return (
      <div className="mt-3 mx-3 p-2 cursor">
        {/* <div className="d-flex mt-5 mx-5">
          <div className="col-md-4 d-flex mb-3 me-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date" />
            </div>
            <div className="col-md-9 ">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => setStartDate(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => setEndDate(value)}
              />
            </div>
          </div>
        </div> */}
        <div className="d-flex mx-5 mt-3">
          <div className="col-md-4 pe-3">
            <div className="boxshadow  p-4  ">
              <BarGraph label={xLabel4} value={Graphdata4} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Total Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <BarGraph label={xLabel5} value={Graphdata5} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Unique Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-3">
            <div className="boxshadow  p-4  ">
              <BarGraph label={xLabel6} value={Graphdata6} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Old Connected Calls</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Dashboard"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth">
          <div className=" counsellorDashbord d-flex px-5  mx-5">
            <div className="col-md-3 d-flex justify-content-center me-2 mt-3">
              <div className="col-md-5 d-flex flex-row-reverse me-2">
                <LabelField lableName="Total Revenue:" />
              </div>
              <div className="col-md-7">
                <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4">
                  {revenue}
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex">
              <button className="btn" onClick={onChangeGetRevenue}>
                Search
              </button>
            </div>
          </div>
          <div className="analysisDiv">
            <div className="row d-flex justify-content-between mx-5">
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("newOppourtunity");
                  }}
                >
                  <h2 className="fw-bold text-success">{newOppourtunity}</h2>
                  <p className="fs-5">New Opportunity</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("reEnquired");
                  }}
                >
                  <h2 className="fw-bold text-success">{reEnquired}</h2>
                  <p className="fs-5">Re-Enquired</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("enrolled");
                  }}
                >
                  <h2 className="fw-bold text-success">{enrolled}</h2>
                  <p className="fs-5">Enrolled</p>
                </div>
              </div>
              <div className="col-md-4 mt-3 text-center ">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("notInterested");
                  }}
                >
                  <h2 className="fw-bold text-success">{notInterested}</h2>
                  <p className="fs-5">Not Interested</p>
                </div>
              </div>
              <div className="col-md-4 mt-3 text-center ">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("expired");
                  }}
                >
                  <h2 className="fw-bold text-success">{expired}</h2>
                  <p className="fs-5">Expired</p>
                </div>
              </div>
              <div className="col-md-4 mt-3 text-center ">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("leadInvalid");
                  }}
                >
                  <h2 className="fw-bold text-danger">{leadInvalid}</h2>
                  <p className="fs-5">Lead Invalid</p>
                </div>
              </div>
              <div className="col-md-4 mt-3 text-center ">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("cnrLead");
                  }}
                >
                  <h2 className="fw-bold text-success">{cnrLead}</h2>
                  <p className="fs-5">CNR Today</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("prospectToday");
                  }}
                >
                  <h2 className="fw-bold text-warning">{prospectToday}</h2>
                  <p className="fs-5">Prospect Today</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("interestedToday");
                  }}
                >
                  <h2 className="fw-bold text-warning">{interestedToday}</h2>
                  <p className="fs-5">Interested Today</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("callBackToday");
                  }}
                >
                  <h2 className="fw-bold text-warning">{callBackToday}</h2>
                  <p className="fs-5">Call Back Today</p>
                </div>
              </div>
              <div className="col-md-4 mt-3 text-center ">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("callRejectToday");
                  }}
                >
                  <h2 className="fw-bold text-success">{callRejectToday}</h2>
                  <p className="fs-5">Call Reject Today</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("overdueProspect");
                  }}
                >
                  <h2 className="fw-bold text-danger">{overdueProspect}</h2>
                  <p className="fs-5">Overdue Prospect</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("overdueInterested");
                  }}
                >
                  <h2 className="fw-bold text-danger">{overdueInterested}</h2>
                  <p className="fs-5">Overdue Interested</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("overdueCallBack");
                  }}
                >
                  <h2 className="fw-bold text-danger">{overdueCallBack}</h2>
                  <p className="fs-5">Overdue Call Back</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("overdueCNR");
                  }}
                >
                  <h2 className="fw-bold text-danger">{overdueCNR}</h2>
                  <p className="fs-5">Overdue CNR</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("overdueCallReject");
                  }}
                >
                  <h2 className="fw-bold text-danger">{overdueCallReject}</h2>
                  <p className="fs-5">Overdue Call Reject</p>
                </div>
              </div>
              <div className="col-md-4 mt-3 text-center ">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("alreadyAdmissionDone");
                  }}
                >
                  <h2 className="fw-bold text-danger">
                    {alreadyAdmissionDone}
                  </h2>
                  <p className="fs-5">Already Admission Done</p>
                </div>
              </div>
              {loggedInUserRole === 8 || loggedInUserRole === 4 ? (
                <>
                  <div className="col-md-4 text-center mt-3">
                    <div
                      className="boxshadow  p-4 cursor h-100"
                      onClick={() => {
                        onChange("welcomeCallDone");
                      }}
                    >
                      <h2 className="fw-bold text-danger">{welcomeCallDone}</h2>
                      <p className="fs-5">Welcome Call Done</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center mt-3">
                    <div
                      className="boxshadow  p-4 cursor"
                      onClick={() => {
                        onChange("welcomeCallReference");
                      }}
                    >
                      <h2 className="fw-bold text-danger">
                        {welcomeCallReference}
                      </h2>
                      <p className="fs-5">
                        Welcome Call & Reference Collection
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center mt-3">
                    <div
                      className="boxshadow  p-4 cursor  h-100"
                      onClick={() => {
                        onChange("followUp");
                      }}
                    >
                      <h2 className="fw-bold text-danger">{followUp}</h2>
                      <p className="fs-5">Follow - Up For Upselling</p>
                    </div>
                  </div>
                </>
              ) : null}
              {loggedInUserRole === 4 ? (
                <>
                  <div className="col-md-4 text-center mt-3">
                    <div
                      className="boxshadow  p-4 cursor h-100"
                      onClick={() => {
                        onChange("interestedForUpselling");
                      }}
                    >
                      <h2 className="fw-bold text-danger">
                        {interestedForUpselling}
                      </h2>
                      <p className="fs-5">Interested For Upselling</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center mt-3">
                    <div
                      className="boxshadow  p-4 cursor"
                      onClick={() => {
                        onChange("prospectForUpselling");
                      }}
                    >
                      <h2 className="fw-bold text-danger">
                        {prospectForUpselling}
                      </h2>
                      <p className="fs-5">Prospect For Upselling</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center mt-3">
                    <div
                      className="boxshadow  p-4 cursor  h-100"
                      onClick={() => {
                        onChange("technicalIssue");
                      }}
                    >
                      <h2 className="fw-bold text-danger">{technicalIssue}</h2>
                      <p className="fs-5">Technical Issue</p>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("finalCNR");
                  }}
                >
                  <h2 className="fw-bold text-danger">{finalCNR}</h2>
                  <p className="fs-5">Final CNR</p>
                </div>
              </div>
              <div className="col-md-4  text-center mt-3">
                <div
                  className="boxshadow  p-4 cursor"
                  onClick={() => {
                    onChange("Summary");
                  }}
                >
                  <h2 className="fw-bold text-danger">0</h2>
                  <p className="fs-5">Summary</p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="px-5 pt-5 mx-5 d-flex Justify-content-between">
                <h3 className=" col-md-3 text-primary fw-bold">Analysis</h3>

                <div className="col-md-2 d-flex  ">
                  <div className="teargetbg px-4 my-3 me-3"></div>
                  <p className="mt-3 fw-bold ">Target</p>
                </div>
                <div className="col-md-2 d-flex   me-5">
                  <div className="unachievedbg px-4 my-3 me-3"></div>
                  <p className="mt-3 fw-bold ">Achieved</p>
                </div>
              </div>

              {renderGraphMode1()}
              {renderGraphMode2()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
