
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import SubmitButton from '../../Component/submitButton';
import InputField from '../../Component/inputField'
import LabelField from '../../Component/labelField';
import { containsNumber, length_Ten, validateValueWithoutSpace } from '../../DATA/validators';
import { getCounsellorUniqueCall, updateCounsellorUniqueCall } from '../../Redux/Actions/leadManagementAction';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';
import { getWebinarCount ,updateWebinarCount } from '../../Redux/Actions/bdeAdminPanelAction';

export default function WebinarTarget() {
  const dispatch = useDispatch();

  const [updatedWebinarCount, setUpdatedWebinarCount] = useState("");
  const [id, setId] = useState<any>("");

  useEffect(() => {
    dispatch(
      getWebinarCount(
        "",
        (res: any) => {
          setId(res?._id);
          setUpdatedWebinarCount(res?.count);
        },
        () => { }
      )
    );

  }, []);


  const onchangeDepartmentCallTarget = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setUpdatedWebinarCount(value);
      }
    } else {
      setUpdatedWebinarCount(value);
    }
  }



  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      count: updatedWebinarCount
    }
    dispatch(
      updateWebinarCount(
        id,
        postData,
        (res: any) => {
          dispatch(
            getWebinarCount(
              "",
              (res: any) => {
                setUpdatedWebinarCount(res?.count);
                setId(res?._id);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );

  };


  const renderSearchMode = () => {
    return (
      <form className="d-flex" onSubmit={handleSubmit}>

        <div className="d-flex mb-2 col-6">
          <LabelField lableName={"BDE Webinar Count :"} />
          <InputField
            placeholder="Webinar Count"
            value={updatedWebinarCount}
            onChangeInput={(value: any) => onchangeDepartmentCallTarget(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>
        <div className="d-flex mb-2 col-4 me-4">
          <div>
            <SubmitButton name={"Update"} />
          </div>
        </div>
      </form>
    );
  };


  return (
    <div className="contentBox ">
      <PageTitle name={"Webinar Target"} />
      <div className="p-3 formDiv">
        
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
             
              {renderSearchMode()}
              
            </div>
           
          </div>
       
      </div>
    </div>
  );
}

