import ReplyIcon from "@mui/icons-material/Reply";
interface props {
  name: string;
  isEditMode?: boolean;
  isGoBack?: boolean;
  setisEditMode?: any;
  onClick?: any;
}

export default function PageTitle({
  name,
  isEditMode,
  setisEditMode,
  isGoBack,
  onClick,
}: props) {
  return (
    <div className="d-flex Justify-content-between">
      <h5 className="Title">{name}</h5>
      {isGoBack ? (
        <div className="w-5">
          {/* <button className="btn btn-outline-primary mb-2" onClick={onClick}>
            Go Back
          </button> */}
          <button
            type="button"
            className="btn btn-light"
            // onClick={() => navigate(-1)}
            onClick={() => onClick()}
          >
            <ReplyIcon /> Back
          </button>
        </div>
      ) : null}
    </div>
  );
}
