import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import moment from "moment";
import {
  getExecutiveListTeamLeadTarget,
  getLeadAnalysisiDetails,
  getLeadDepartmentCount,
} from "../../Redux/Actions/bdManagerPanelAction";
import GenaralTable from "../../Component/genaralTable";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function LeadOverViewDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [startDate, setStartDate] = useState<any>(
  //   moment.utc(new Date()).format("YYYY-MM-DD")
  // );
  // const [endDate, setEndDate] = useState<any>(
  //   moment.utc(new Date()).format("YYYY-MM-DD")
  // );
  const [startDate, setStartDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [leadCount, setLeadCount] = useState<any>("");
  const [webinarCount, setWebinarCount] = useState<any>("");
  const [departmentCount, setDepartmentCount] = useState<any>("");
  const [executiveListTeamLead, setExecutiveListTeamLead] = useState<any>([]);
  const [leadAnalysisiDetailsList, setLeadAnalysisiDetailsList] = useState<any>(
    []
  );

  const [xLabel1, setXLabel1] = useState<any>([]);

  const [Graphdata1, setGraphdata1] = useState<any>([]);

  useEffect(() => {
    onChangeGetRevenue();
    dispatch(
      getExecutiveListTeamLeadTarget(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphExecutiveTLTarget(res);
          setExecutiveListTeamLead(res);
        },
        () => {}
      )
    );
    let data = {
      startDate,
      endDate,
    };

    dispatch(
      getLeadDepartmentCount(
        data,
        (res: any) => {
          setDepartmentCount(res?.departmentCount);
          setWebinarCount(res?.webinarCount);
          setLeadCount(res?.leadCount);
        },
        () => {}
      )
    );
  }, []);

  const showGraphExecutiveTLTarget = (list: any) => {
    while (xLabel1.length) {
      xLabel1.pop();
      Graphdata1?.pop();
    }
    list?.map((item: any) => {
      xLabel1?.push(item?.name);
      Graphdata1?.push(item?.achievedLeads);
    });
  };

  const onChangeGetRevenue = () => {
    dispatch(
      getExecutiveListTeamLeadTarget(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphExecutiveTLTarget(res);
          setExecutiveListTeamLead(res);
          dispatch(
            getLeadDepartmentCount(
              {
                startDate,
                endDate,
              },
              (res: any) => {
                setDepartmentCount(res?.departmentCount);
                setWebinarCount(res?.webinarCount);
                setLeadCount(res?.leadCount);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const getData = (val: any) => {
    const data = {
      startDate,
      endDate,
      executiveId: executiveListTeamLead[val?.index]?._id,
    };
    dispatch(
      getLeadAnalysisiDetails(
        data,
        (res: any) => {
          setLeadAnalysisiDetailsList(res?.data);
        },
        () => {}
      )
    );
  };
  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <GenaralTable
          tableData={leadAnalysisiDetailsList ? leadAnalysisiDetailsList : []}
          pagename={"LeadOverViewDetails"}
          tableHead={[
            "Name",
            "Collage Name",
            "Department",
            "Webinar Date",
            "Webinar Url",
            "Lead Count",
          ]}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead OverView"} />

      <div className="formDiv ">
        <div className=" col-md-12 formWidth ">
          <div className=" counsellorDashbord d-flex justify-content-center px-2 mx-5">
            <div className="col-md-9 d-flex ">
              <div className="col-md-4 d-flex justify-content-center pe-2 mt-3  ">
                <div className="col-md-4 d-flex flex-row-reverse ">
                  <LabelField lableName="Total Lead:" />
                </div>
                <div className="col-md-7 me-2">
                  <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4 mb-0">
                    {leadCount ? leadCount : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex mt-3 ">
                <div className="col-md-5 d-flex flex-row-reverse mb-3">
                  <LabelField lableName="Start Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => setStartDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex me-2  mt-3">
                <div className="col-md-5 d-flex flex-row-reverse mb-3">
                  <LabelField lableName="End Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => setEndDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center ">
              <button className="btn" onClick={onChangeGetRevenue}>
                Search
              </button>
            </div>
          </div>
          {/* *************************************Lead OverView********************************************* */}
          <div className="analysisDiv">
            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-7 p-3">
                <h4 className="fw-bold">Lead Overview </h4>

                <BarGraph
                  label={xLabel1}
                  value={Graphdata1}
                  getData={(val: any) => getData(val)}
                />
              </div>
            </div>

            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
