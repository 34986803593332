import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import {
  bindCoupon,
  getEmployeeList,
  searchCoupon,
} from "../../Redux/Actions/leadManagementAction";
import InputField from "../../Component/inputField";
import {
  length_Three,
  length_Twenty,
  startsWithSpace,
  validateCouponCodeName,
} from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import CustomDropdown from "../../Component/customDropdown";
import Button from "../../Component/button";

export default function BindCouponToEC() {
  const dispatch = useDispatch();
  const [isSelectedCheckbox, setIsSelectedCheckbox] = useState<any>(false);
  const [couponCode, setCouponCode] = useState("");
  const [counsellor, setCounsellor] = useState<any>();

  const [counsellorList, setCounsellorList] = useState([]);
  const [couponCodeList, setCouponCodeList] = useState([]);

  const [resetCounsellor, setResetCounsellor] = useState(false);

  useEffect(() => {
    dispatch(
      getEmployeeList(
        {
          status: "active",
        },
        (res: any) => {
          let tempList = res?.data?.map((item: any) => {
            return {
              _id: item?._id,
              name: `${item?.firstName} ${item?.lastName}`,
            };
          });
          setCounsellorList(tempList);
        },
        () => {}
      )
    );
  }, []);

  const onchangeCouponCode = (val: string) => {
    let Coupon = val?.toUpperCase();
    if (validateCouponCodeName(Coupon) && !startsWithSpace(Coupon)) {
      setCouponCode(Coupon);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    setResetCounsellor(false);
  };

  const handleSearch = () => {
    dispatch(
      searchCoupon(
        couponCode,
        (res: any) => {
          setCouponCodeList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (counsellor) {
      let dataList = couponCodeList
        .filter((item: any) => item?.isSelected)
        .map((code: any) => {
          return code?._id;
        });
      let postData = {
        couponList: dataList,
        counsellorId: counsellor?._id,
      };

      dispatch(
        bindCoupon(
          postData,
          (res: any) => {
            setIsSelectedCheckbox(false);
            setCounsellor("");
            setResetCounsellor(true);
            dispatch(
              searchCoupon(
                couponCode,
                (res: any) => {
                  setCouponCodeList(res?.data);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Please Select Counsellor") as any);
    }
  };

  const handleAllCheck = (e: any) => {
    let newArr = JSON.parse(JSON.stringify(couponCodeList));
    if (e.target.checked) {
      setIsSelectedCheckbox(true);
      newArr.forEach((element: any) => {
        element.isSelected = true;
      });
      setCouponCodeList(newArr);
    } else {
      setIsSelectedCheckbox(false);
      newArr.forEach((element: any) => {
        element.isSelected = false;
      });
      setCouponCodeList(newArr);
    }
  };

  const handleCheck = (e: any, index: any) => {
    let newArr = JSON.parse(JSON.stringify(couponCodeList));
    if (e.target.checked) {
      newArr[index].isSelected = true;
      setCouponCodeList(newArr);
    } else {
      newArr[index].isSelected = false;
      setCouponCodeList(newArr);
    }
    let indexes = newArr?.filter((elm: any) => elm?.isSelected);
    if (newArr?.length === indexes?.length) {
      setIsSelectedCheckbox(true);
    } else {
      setIsSelectedCheckbox(false);
    }
  };

  const renderSearchMode = () => {
    return (
      <form
        className="d-flex justify-content-space-between"
        onSubmit={handleSubmit}
      >
        <div className="col-md-3 d-flex mb-3 me-2">
          <LabelField lableName={"Coupon Code"} />
          <div className="removelabel">
            <InputField
              placeholder={"Enter Coupon Code"}
              onChangeInput={(value: any) => onchangeCouponCode(value)}
              value={couponCode}
              maxlength={length_Twenty}
              minlength={length_Three}
              isRequired={true}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-12">
            <Button name={"Search"} handleClick={handleSearch} />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3 me-2">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Counsellor" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select Counsellor"
              setInputText={(value: any) => onChangeCounsellor(value)}
              value={counsellor}
              options={counsellorList}
              reset={resetCounsellor}
            />
          </div>
        </div>
        <div className="col-md-3">
          <SubmitButton name={"Bind"} />
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex col-md-12 mb-3 "></div>
        <div>
          <div className="boxshadow p-2 m-2">
            <div className="mb-3">
              <div className="fw-bold">User Details</div>
              <table className="w-100 mb-2">
                <tr>
                  <th>
                    <span>
                      <input
                        className="check"
                        type="checkbox"
                        checked={isSelectedCheckbox}
                        onChange={(value: any) => {
                          handleAllCheck(value);
                        }}
                      />
                    </span>
                  </th>
                  <th>S.N</th>
                  <th>Coupon Name</th>
                  <th>Discount</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Coupon Status</th>
                  <th>Bind</th>
                  <th>Counsellor Name</th>
                  <th>Coupon Created Date</th>
                </tr>
                {couponCodeList?.map((item: any, index: number) => {
                  return (
                    <tr>
                      <td>
                        <span>
                          <input
                            id={item?._id}
                            className="check"
                            type="checkbox"
                            checked={item.isSelected}
                            onChange={(item) => {
                              handleCheck(item, index);
                            }}
                          />
                        </span>
                      </td>
                      <td>{index + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.discPer}</td>
                      <td>{moment(item?.startDate).format("DD-MM-YYYY")}</td>
                      <td>{moment(item?.endDate).format("DD-MM-YYYY")}</td>
                      <td>{item?.couponStatus}</td>
                      <td>{item?.isBind === false ? "NO" : "Yes"}</td>
                      <td>{item?.counselorFirstName}</td>
                      <td>
                        {moment(item?.createdAt).format(
                          "DD-MM-YYYY HH:mm:ss A"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"BIND COUPON TO COUNSELLOR"} />
      <div className="col-md-12 row">
        <div className="formDiv formWidth p-4">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
