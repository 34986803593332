import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import SubmitButton from "../../Component/submitButton";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import InputField from "../../Component/inputField";
import {
  containsNumber,
  length_Fifty,
  length_Ten,
  length_Three,
  length_Twenty,
  length_Two,
  startsWithSpace,
  validateFullName,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import ToggleSwitchbtn from "../../Component/toggleSwitchBtn";
import Button from "../../Component/button";
import {
  addEmployee,
  deleteEmployee,
  getEmployeeList,
  getEmployeeRoleList,
  getManagerList,
  getSourceTypeList,
  getZonesList,
  restoreEmployee,
  updateEmployee,
} from "../../Redux/Actions/leadManagementAction";
import moment from "moment";
import RestorePopup from "../../Component/restorePopup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";

const initialVal: {
  sourceType: string;
  baseCourseId: string;
  examTypeId: string;
  isActive: boolean;
}[] = [
  {
    sourceType: "",
    baseCourseId: "",
    examTypeId: "",
    isActive: false,
  },
];

export default function ManageEmployee() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [isAddEmployee, setAddEmployee] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [firstName, setFirstname] = useState<any>("");
  const [lastName, setLastname] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [currentSalary, setCurrentSalary] = useState<any>("");
  const [userName, setUsername] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [status, setStatus] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");
  const [empId, setEmpId] = useState<any>("");
  const [zoneId, setZoneId] = useState<any>("");
  const [courseExamTypeInfoArr, setCourseExamTypeInfoArr] =
    useState<any>(initialVal);

  const [employeeList, setEmployeeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [zoneList, setZoneList] = useState<any>([]);

  const [resetRole, setResetRole] = useState(false);
  const [resetManager, setResetManager] = useState(false);
  const [resetZone, setResetZone] = useState<any>([]);

  useEffect(() => {
    getEmpList("active");
  }, []);

  const getEmpList = (data: string) => {
    setCount(0);
    setEmployeeList([]);
    dispatch(
      getEmployeeList(
        {
          skip: 0,
          limit: rowsPerPage,
          status: data,
        },
        (res: any) => {
          setCount(res?.count);
          setEmployeeList(res?.data);
        },
        () => {}
      )
    );
  };

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    }
    dispatch(
      getEmployeeList(
        data,
        (res: any) => {
          setCount(res?.count);
          setEmployeeList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
    setSelectedStatus(val);
    if (val === 1) {
      getEmpList("active");
    } else if (val === 2) {
      getEmpList("deleted");
    }
  };

  const handleDelete = (val: any) => {
    setOpenDeletePopup(true);
    setAddEmployee(false);
    setisEditMode(false);
    setEmpId(val?._id);
    setFirstname(val?.firstName);
    setLastname(val?.lastName);
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };

  const handleSubmitDelete = () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteEmployee(
        empId,
        () => {
          if (selectedStatus === 1) {
            getEmpList("active");
          } else if (selectedStatus === 2) {
            getEmpList("deleted");
          }
        },
        () => {}
      )
    );
  };
  const handleEdit = (value: any) => {
    setAddEmployee(true);
    setisEditMode(true);
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    setDate(value?.yoj);
    dispatch(
      getEmployeeRoleList((res: any) => {
        setRoleList(res);
        setRole(value?.role);
      })
    );
    dispatch(
      getManagerList(
        value?.role?._id,
        (res: any) => {
          setManagerList(res);
          setManager(value?.managerId);
        },
        () => {}
      )
    );

    let newArr = value?.courseExamTypeInfoList?.map((item: any) => {
      return {
        baseCourseId: item?.baseCourseId?._id,
        examTypeId: item?.examTypeId?._id,
        sourceType: item?.sourceType?._id,
        isActive: item?.isActive,
      };
    });
    setCourseExamTypeInfoArr(newArr);
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    setCurrentSalary(value?.salary);
    setUsername(value?.username);
    setEmail(value?.email);
    setPassword(value?.password);
    setMob(value?.contactNo);
    setStatus(value?.status);
    setUniqueId(value?.uniqueId?.toUpperCase());
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
        // setSource(value?.sourceType);
      })
    );
    dispatch(
      getZonesList((res: any) => {
        if (res?.data?.length > 0) {
          setZoneList(res?.data);
          setZoneId(value?.zoneId);
        } else {
          setZoneList([]);
          setZoneId("");
        }
      })
    );
  };

  const handleRestore = (value: any) => {
    setOpenPopup(true);
    setAddEmployee(false);
    setisEditMode(false);
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    // setStatus(value?.status);
  };

  const handleAddEmployee = () => {
    setAddEmployee(true);
    setFirstname("");
    setLastname("");
    setDate("");
    setRole("");
    setResetRole(true);
    setManager("");
    setResetManager(true);
    setCurrentSalary("");
    setUsername("");
    setEmail("");
    setPassword("");
    setMob("");
    setStatus(false);
    setUniqueId("");
    setCourseExamTypeInfoArr(initialVal);
    dispatch(
      getEmployeeRoleList((res: any) => {
        setRoleList(res);
      })
    );

    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
      })
    );
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getZonesList((res: any) => {
        if (res?.data?.length > 0) {
          setZoneList(res?.data);
        } else {
          setZoneList([]);
        }
      })
    );
  };

  const cancelAddEmployee = () => {
    setAddEmployee(false);
  };

  const onPressAdd = () => {
    setCourseExamTypeInfoArr([...courseExamTypeInfoArr, initialVal[0]]);
  };

  const handleArrClose = (index: number) => {
    const newArrr = [...courseExamTypeInfoArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setCourseExamTypeInfoArr(newArrr);
  };

  const onchangeFirstName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFirstname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onchangelastName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLastname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeRole = (val: any) => {
    setRole(val);
    setResetRole(false);
    dispatch(
      getManagerList(
        val?._id,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
  };
  const onChangeManager = (val: string) => {
    setManager(val);
    setResetManager(false);
  };
  const onchangeCurrentSalary = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setCurrentSalary(val);
      }
    } else {
      setCurrentSalary(val);
    }
  };

  const onChange = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(courseExamTypeInfoArr));
    if (key === "source") {
      newArrr[index].sourceType = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    } else if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      dispatch(
        getExamStreamList(`${value._id}`, (res: any) => {
          setExamStreamList(res);
        })
      );
    } else if (key === "examStream") {
      newArrr[index].baseCourseId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    } else if (key === "isActive") {
      if (value.target.checked) {
        newArrr[index].isActive = true;
        setCourseExamTypeInfoArr(newArrr);
      } else {
        newArrr[index].isActive = false;
        setCourseExamTypeInfoArr(newArrr);
      }
    }
  };

  const onchangeUserName = (val: string) => {
    setUsername(val);
  };
  const onchangeEmail = (val: string) => {
    setEmail(val);
  };
  const onChangePassword = (val: string) => {
    setPassword(val);
  };
  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob(val);
      }
    } else {
      setMob(val);
    }
  };
  const onChangeStatus = (event: any) => {
    if (event.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const onchangeUniqueId = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onHandleZone = (val: any) => {
    setZoneId(val);
    setResetZone(false);
  };

  const cancelEditEmployee = () => {
    setAddEmployee(false);
    setisEditMode(false);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData = {
      firstName: firstName?.toUpperCase(),
      lastName: lastName?.toUpperCase(),
      yoj: date,
      role: role?._id,
      managerId: manager?._id,
      salary: currentSalary,
      username: userName,
      email: email,
      password: password,
      contactNo: mob,
      status: status ? 1 : 0,
      uniqueId: uniqueID,
      courseExamTypeInfoList: courseExamTypeInfoArr,
      zoneId: zoneId?._id,
    };
    if (
      postData["managerId"] === "" ||
      postData["managerId"] === undefined ||
      postData["managerId"] === null
    ) {
      delete postData["managerId"];
    }
    if (
      postData["zoneId"] === "" ||
      postData["zoneId"] === undefined ||
      postData["zoneId"] === null
    ) {
      delete postData["zoneId"];
    }
    if (
      postData["role"] === 3 ||
      postData["role"] === 4 ||
      postData["role"] === 5 ||
      postData["role"] === 6 ||
      postData["role"] === 7 ||
      postData["role"] === 8 ||
      postData["role"] === 10
    ) {
      if (
        (postData["courseExamTypeInfoList"][0]["sourceType"] === "" ||
          postData["courseExamTypeInfoList"][0]["sourceType"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["examTypeId"] === "" ||
          postData["courseExamTypeInfoList"][0]["examTypeId"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["baseCourseId"] === "" ||
          postData["courseExamTypeInfoList"][0]["baseCourseId"] === undefined)
      ) {
        delete postData["courseExamTypeInfoList"];
      }
    }
    if (
      postData["role"] === 3 &&
      (postData["courseExamTypeInfoList"][0]["sourceType"] === 7 ||
        postData["courseExamTypeInfoList"][0]["sourceType"] === 16)
    ) {
      if (
        postData["courseExamTypeInfoList"][0]["sourceType"] !== "" &&
        (postData["courseExamTypeInfoList"][0]["examTypeId"] === "" ||
          postData["courseExamTypeInfoList"][0]["examTypeId"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["baseCourseId"] === "" ||
          postData["courseExamTypeInfoList"][0]["baseCourseId"] === undefined)
      ) {
        delete postData["courseExamTypeInfoList"][0]["examTypeId"];
        delete postData["courseExamTypeInfoList"][0]["baseCourseId"];
      }
    }
    if (
      postData["role"] === 0 ||
      postData["role"] === 1 ||
      postData["role"] === 2 ||
      postData["role"] === 9 ||
      postData["role"] === 11 ||
      postData["role"] === 12
    ) {
      delete postData["courseExamTypeInfoList"];
    }

    dispatch(
      addEmployee(
        postData,
        (res: any) => {
          setFirstname("");
          setLastname("");
          setDate("");
          setRole("");
          setResetRole(true);
          setManager("");
          setResetManager(true);
          setCurrentSalary("");
          setCourseExamTypeInfoArr(initialVal);
          setUsername("");
          setEmail("");
          setPassword("");
          setMob("");
          setStatus(false);
          setUniqueId("");
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("active");
        },
        () => {}
      )
    );
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let postData = {
      firstName: firstName?.toUpperCase(),
      lastName: lastName?.toUpperCase(),
      yoj: date,
      role: role?._id,
      managerId: manager?._id,
      salary: currentSalary,
      email: email,
      contactNo: mob,
      status: status ? 1 : 0,
      courseExamTypeInfoList: courseExamTypeInfoArr,
      zoneId: zoneId?._id,
    };
    if (
      postData["managerId"] === "" ||
      postData["managerId"] === undefined ||
      postData["zoneId"] === null
    ) {
      delete postData["managerId"];
    }
    if (
      postData["zoneId"] === "" ||
      postData["zoneId"] === undefined ||
      postData["zoneId"] === null
    ) {
      delete postData["zoneId"];
    }
    if (
      postData["role"] === 3 ||
      postData["role"] === 4 ||
      // postData["role"] === 5 ||
      postData["role"] === 6 ||
      postData["role"] === 7 ||
      postData["role"] === 8
    ) {
      if (postData["courseExamTypeInfoList"]?.length > 0) {
        if (
          (postData["courseExamTypeInfoList"][0]["sourceType"] === "" ||
            postData["courseExamTypeInfoList"][0]["sourceType"] ===
              undefined) &&
          (postData["courseExamTypeInfoList"][0]["examTypeId"] === "" ||
            postData["courseExamTypeInfoList"][0]["examTypeId"] ===
              undefined) &&
          (postData["courseExamTypeInfoList"][0]["baseCourseId"] === "" ||
            postData["courseExamTypeInfoList"][0]["baseCourseId"] === undefined)
        ) {
          delete postData["courseExamTypeInfoList"];
        }
      }
    }
    if (
      postData["role"] === 3 &&
      (postData["courseExamTypeInfoList"][0]["sourceType"] === 7 ||
        postData["courseExamTypeInfoList"][0]["sourceType"] === 16)
    ) {
      if (
        postData["courseExamTypeInfoList"][0]["sourceType"] !== "" &&
        (postData["courseExamTypeInfoList"][0]["examTypeId"] === "" ||
          postData["courseExamTypeInfoList"][0]["examTypeId"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["baseCourseId"] === "" ||
          postData["courseExamTypeInfoList"][0]["baseCourseId"] === undefined)
      ) {
        delete postData["courseExamTypeInfoList"][0]["examTypeId"];
        delete postData["courseExamTypeInfoList"][0]["baseCourseId"];
      }
    }

    if (
      postData["role"] === 1 ||
      postData["role"] === 2 ||
      postData["role"] === 9 ||
      postData["role"] === 11 ||
      postData["role"] === 12
    ) {
      delete postData["courseExamTypeInfoList"];
    }

    dispatch(
      updateEmployee(
        empId,
        postData,
        () => {
          setFirstname("");
          setLastname("");
          setDate("");
          setRole("");
          setResetRole(true);
          setManager("");
          setResetManager(true);
          setCurrentSalary("");
          setUsername("");
          setEmail("");
          setPassword("");
          setMob("");
          setStatus(false);
          setUniqueId("");
          setCourseExamTypeInfoArr(initialVal);
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("active");
        },
        () => {}
      )
    );
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleSubmitRestore = () => {
    setOpenPopup(false);
    let postData = {
      status: 0,
    };
    dispatch(
      restoreEmployee(
        empId,
        postData,
        () => {
          setOpenPopup(false);
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("deleted");
        },
        () => {
          setOpenPopup(false);
        }
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        {employeeList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {/* {count > 0 ? ( */}
        <PaginationView
          pagename={"ManageEmployee"}
          count={count}
          tableData={employeeList ? employeeList : []}
          tabs={["Active", "Deleted"]}
          onTabChange={(e: any, index: any) => showData(e, index)}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
          handleClick={handleAddEmployee}
          isExport={true}
          exportRef={tableRef}
        />
        {/* ) : null} */}

        {selectedStatus === 1 ? (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"Employee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Manager / Team Lead",
              "Source - Exam Name - Exam Stream",
              "Experience (Months)",
              "Status",
              "Edit",
              "Delete",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        ) : (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"Employee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Source - Exam Name - Exam Stream",
              "Experience (Months)",
              "Status",
              "Restore",
              "",
            ]}
            edit={true}
            handleEdit={(value: any) => handleRestore(value)}
          />
        )}
      </>
    );
  };

  const renderAddMode = () => {
    return (
      <>
        {!isEditMode ? (
          <h2 className="text-primary">Add Employee</h2>
        ) : (
          <h2 className="text-primary">Edit Employee</h2>
        )}
        <form
          className="col-md-10  shadow-lg py-4 px-3"
          onSubmit={!isEditMode ? handleSubmit : handleUpdate}
        >
          <div className="row d-flex justify-content-between">
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => onchangeFirstName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => onchangelastName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>

            <div className="col-md-4 d-flex my-3">
              <div className="col-5 mt-2">
                <LabelField lableName="Date Of Joining" />
              </div>
              <DatePicker
                labelName={"Date of Joining"}
                value={date}
                setInputText={(value: string) => setDate(value)}
                Isrequired={true}
                defaultValue={date}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                // disabled={isEditMode}
              />
            </div>
            <div className="col-md-4  my-3">
              <CustomDropdown
                lableName="Select Role"
                setInputText={(value: any) => onChangeRole(value)}
                value={role}
                options={roleList}
                Isrequired={true}
                reset={resetRole}
                defaultValue={role?._id}
                // disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3 ">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                Isrequired={true}
                reset={resetManager}
                defaultValue={manager?._id}
                disabled={role?._id === 0 ? true : false}
              />
            </div>
            <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Current Salary"
                value={currentSalary}
                onChangeInput={(value: any) => onchangeCurrentSalary(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
                // disabled={isEditMode}
              />
            </div>
            {courseExamTypeInfoArr?.map((item: any, index: number) => {
              const { sourceType, baseCourseId, examTypeId, isActive } = item;
              return (
                <div className="position-relative" key={index}>
                  <span className="d-flex justify-content-end removeButton">
                    {courseExamTypeInfoArr.length === 1 ? null : (
                      <HighlightOffIcon
                        className="cursor"
                        color="primary"
                        fontSize="small"
                        onClick={() => handleArrClose(index)}
                      />
                    )}
                  </span>
                  <div className="d-flex position-relative">
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Source"
                        setInputText={(value: any) =>
                          onChange(index, value, "source")
                        }
                        value={sourceType}
                        options={sourceList}
                        Isrequired={true}
                        // reset={resetSource}
                        defaultValue={sourceList?.length > 0 ? sourceType : ""}
                        disabled={
                          role?._id === 0 ||
                          role?._id === 1 ||
                          role?._id === 2 ||
                          role?._id === 7 ||
                          role?._id === 9 ||
                          role?._id === 11 ||
                          role?._id === 12
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Exam"
                        setInputText={(value: any) =>
                          onChange(index, value, "exam")
                        }
                        value={examTypeId}
                        options={examList}
                        Isrequired={true}
                        // reset={resetExam}
                        defaultValue={examList?.length > 0 ? examTypeId : ""}
                        disabled={
                          role?._id === 0 ||
                          role?._id === 1 ||
                          role?._id === 2 ||
                          role?._id === 7 ||
                          role?._id === 9 ||
                          role?._id === 11 ||
                          role?._id === 12
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Exam Stream"
                        setInputText={(value: any) =>
                          onChange(index, value, "examStream")
                        }
                        value={baseCourseId}
                        options={examStreamList}
                        Isrequired={true}
                        // reset={resetExamStream}
                        defaultValue={
                          examStreamList?.length > 0 ? baseCourseId : ""
                        }
                        disabled={
                          role?._id === 0 ||
                          role?._id === 1 ||
                          role?._id === 2 ||
                          role?._id === 7 ||
                          role?._id === 9 ||
                          role?._id === 11 ||
                          role?._id === 12
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <ToggleSwitchbtn
                        lableName="Active"
                        getToggleval={(value: any) => {
                          onChange(index, value, "isActive");
                        }}
                        isActive={isActive}
                        disabled={
                          role?._id === 0 ||
                          role?._id === 1 ||
                          role?._id === 2 ||
                          role?._id === 7 ||
                          role?._id === 9 ||
                          role?._id === 11 ||
                          role?._id === 12
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {role?._id === 0 ||
            role?._id === 1 ||
            role?._id === 2 ||
            role?._id === 9 ||
            role?._id === 11 ||
            role?._id === 12 ? null : (
              <div className="d-flex justify-content-end">
                <Button name={"Add More"} handleClick={onPressAdd} />
              </div>
            )}

            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter User Name"
                value={userName}
                onChangeInput={(value: any) => onchangeUserName(value)}
                maxlength={length_Twenty}
                minlength={length_Two}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Email"
                value={email}
                onChangeInput={(value: any) => onchangeEmail(value)}
                isRequired={true}
                // disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangePassword(value)}
                disabled={isEditMode}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Mobile Number"
                value={mob}
                onChangeInput={(value: any) => onChangeContact(value)}
                maxlength={length_Ten}
                minlength={length_Ten}
                // disabled={isEditMode}
                isRequired={true}
              />
            </div>

            <div className="col-md-4 my-3">
              <ToggleSwitchbtn
                lableName="Status"
                getToggleval={(value: any) => {
                  onChangeStatus(value);
                }}
                isActive={status}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Unique Id"
                value={uniqueID}
                onChangeInput={(value: any) => onchangeUniqueId(value)}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-3 my-1 ">
              <CustomDropdown
                lableName="Select Zone Name"
                setInputText={(value: any) => onHandleZone(value)}
                value={zoneId}
                options={zoneList}
                defaultValue={zoneList?.length > 0 ? zoneId?._id : null}
                reset={resetZone}
              />
            </div>
            <div className="col-md-4 my-3"></div>
          </div>
          <div className="d-flex justify-content-end me-3">
            <div className="me-2">
              {!isEditMode ? (
                <Button name={"Cancel"} handleClick={cancelAddEmployee} />
              ) : (
                <Button name={"Cancel"} handleClick={cancelEditEmployee} />
              )}
            </div>
            {!isEditMode ? (
              <div>
                <SubmitButton name={"Save"} validation={validation()} />
              </div>
            ) : (
              <div>
                <SubmitButton name={"Save & Update"} />
              </div>
            )}
          </div>
        </form>
      </>
    );
  };

  const validation = () => {
    return (
      firstName === "" ||
      firstName?.length < 3 ||
      lastName === "" ||
      lastName?.length < 3 ||
      date === "" ||
      role === "" ||
      currentSalary === "" ||
      userName === "" ||
      email === "" ||
      password === "" ||
      mob === "" ||
      mob?.length < 10 ||
      uniqueID === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Manage Employee"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="formDiv">
        <div className="col-md-12  px-4 mx-0 mt-3">
          {!isAddEmployee && !isEditMode ? renderListMode() : renderAddMode()}
        </div>
      </div>
      {openPopup ? (
        <RestorePopup
          open={openPopup}
          handleClose={handleClose}
          handleSubmitRestore={() => {
            handleSubmitRestore();
          }}
          title={"Restore Employee"}
          data={"Do you want to restore this employee ?"}
          buttonTitle={"Restore"}
        />
      ) : (
        ""
      )}
      {openDeletePopup ? (
        <RestorePopup
          open={openDeletePopup}
          handleClose={handleCloseDelete}
          handleSubmitRestore={() => {
            handleSubmitDelete();
          }}
          title={"Delete Employee"}
          data={"Do you want to delete this ?"}
          buttonTitle={"Delete"}
        />
      ) : (
        ""
      )}
      <div ref={tableRef} className="d-none">
        <table className="w-100">
          <thead className="font-12">
            <tr>
              <th>
                <label>Sr. No.</label>
              </th>
              <th>
                <label>Unique ID</label>
              </th>
              <th>
                <label>First Name</label>
              </th>
              <th>
                <label>Last Name</label>
              </th>
              <th>
                <label>Mobile No.</label>
              </th>
              <th>
                <label>Employee Type</label>
              </th>
              <th>
                <label>Manager / Team Lead</label>
              </th>
              <th>
                <label>Source - Exam Name - Exam Stream</label>
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#E8EAF5" }}>
            {employeeList?.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.uniqueId}</td>
                  <td>{item?.firstName}</td>
                  <td>{item?.lastName}</td>
                  <td>{item?.contactNo}</td>
                  <td>{item?.role?.name}</td>
                  <td>{item?.managerId?.name}</td>
                  {item?.courseExamTypeInfoList?.map((data: any) => {
                    return (
                      <>
                        {data?.courseExamTypeInfoList?.length === 1 ? (
                          <>
                            <td>
                              {data?.sourceType?.name} - {data.examTypeId?.name}{" "}
                              - {data?.baseCourseId?.name}
                            </td>
                          </>
                        ) : (
                          <tr>
                            <td colSpan={7}></td>
                            <td>
                              {data?.sourceType?.name} - {data.examTypeId?.name}{" "}
                              - {data?.baseCourseId?.name}
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
