import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";

import moment from "moment";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import { getState, getCity } from "../../Redux/Actions/bdeAdminPanelAction";
import { getLeadAnalysisData } from "../../Redux/Actions/bdExecutivePanelAction";

export default function LeadAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [state, setState] = useState<any>("");
  const [stateList, setStateList] = useState([]);
  const [ResetState, setResetState] = useState(false);
  const [district, setDistrict] = useState<any>("");
  const [districtList, setDistrictList] = useState([]);
  const [ResetDistrict, setResetDistrict] = useState(false);
  const [leadAnalysisDataList, setLeadAnalysisDataList] = useState<any>([]);

  useEffect(() => {
    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCity(
        val._id,
        (res: any) => {
          setDistrictList(res);
        },
        () => {}
      )
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      state: state?._id,
      district: district?._id,
    };
    dispatch(
      getLeadAnalysisData(
        postData,
        (res: any) => {
          setLeadAnalysisDataList(res);
        },
        () => {}
      )
    );
  };
  const onChangeDistrict = (val: any) => {
    setDistrict(val);
  };

  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        <div className="col-md-4 row d-flex my-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              Isrequired={true}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3"></div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="State" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select State"
              setInputText={(value: any) => onChangeState(value)}
              value={state}
              options={stateList}
              reset={ResetState}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="District" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select District"
              setInputText={(value: any) => onChangeDistrict(value)}
              value={district}
              options={districtList}
              reset={ResetDistrict}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-4">
            <button className="btn btn-primary " type="submit">
              {" "}
              Search
            </button>
          </div>
        </div>
      </form>
    );
  };
  const renderListMode = () => {
    return (
      <>
        <div className="w-100 counsellortable table-responsive">
          <>
            <table className=" w-100 text-center table-bordered border-secondary ">
              {leadAnalysisDataList?.data?.length > 0 ? (
                <>
                  <tr>
                    {leadAnalysisDataList?.data?.length > 0 ? (
                      <th rowSpan={3}>Date</th>
                    ) : null}

                    {leadAnalysisDataList?.data?.length > 0 ? (
                      <th colSpan={5}>Total</th>
                    ) : null}

                    {leadAnalysisDataList?.data?.length > 0 ? (
                      <th colSpan={5}>CSIR NET </th>
                    ) : null}

                    {leadAnalysisDataList?.data?.length > 0 ? (
                      <th colSpan={5}>IIT JAM</th>
                    ) : null}

                    {leadAnalysisDataList?.data?.length > 0 ? (
                      <th colSpan={5}>UGC NET</th>
                    ) : null}
                  </tr>

                  <tr>
                    {/* ********total*********** */}

                    <>
                      <th>Lead</th>
                      <th>Webinar Upcoming</th>
                      <th>Webinar Completed</th>
                      <th>Webinar Pending</th>
                      <th>Webinar Cancelled</th>
                    </>

                    {/* ********CSIR NET*********** */}

                    <>
                      <th>Lead</th>
                      <th>Webinar Upcoming</th>
                      <th>Webinar Completed</th>
                      <th>Webinar Pending</th>
                      <th>Webinar Cancelled</th>
                    </>

                    {/* ********IIT JAM*********** */}
                    <>
                      <th>Lead</th>
                      <th>Webinar Upcoming</th>
                      <th>Webinar Completed</th>
                      <th>Webinar Pending</th>
                      <th>Webinar Cancelled</th>
                    </>

                    {/* ********UGC NET************/}

                    <>
                      <th>Lead</th>
                      <th>Webinar Upcoming</th>
                      <th>Webinar Completed</th>
                      <th>Webinar Pending</th>
                      <th>Webinar Cancelled</th>
                    </>
                  </tr>
                  <tr>
                    {/* ********total*********** */}
                    <>
                      <th>{leadAnalysisDataList?.totals?.total_Leads}</th>
                      <th>
                        {leadAnalysisDataList?.totals?.total_WebinarUpcoming}
                      </th>
                      <th>
                        {leadAnalysisDataList?.totals?.total_WebinarCompleted}
                      </th>
                      <th>
                        {leadAnalysisDataList?.totals?.total_WebinarPending}
                      </th>
                      <th>
                        {leadAnalysisDataList?.totals?.total_WebinarCancelled}
                      </th>
                    </>

                    {/* ********CSIR NET*********** */}
                    <>
                      <th>
                        {leadAnalysisDataList?.totals?.["CSIR NET_Lead_total"]}
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "CSIR NET_WebinarUpcoming_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "CSIR NET_WebinarCompleted_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "CSIR NET_WebinarPending_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "CSIR NET_WebinarCancelled_total"
                          ]
                        }
                      </th>
                    </>

                    {/* ********IIT JAM*********** */}
                    <>
                      <th>
                        {leadAnalysisDataList?.totals?.["IIT JAM_Lead_total"]}
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "IIT JAM_WebinarUpcoming_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "IIT JAM_WebinarCompleted_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "IIT JAM_WebinarPending_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "IIT JAM_WebinarCancelled_total"
                          ]
                        }
                      </th>
                    </>

                    {/* ********UGC NET*********** */}
                    <>
                      <th>
                        {leadAnalysisDataList?.totals?.["UGC NET_Lead_total"]}
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "UGC NET_WebinarUpcoming_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "UGC NET_WebinarCompleted_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "UGC NET_WebinarPending_total"
                          ]
                        }
                      </th>
                      <th>
                        {
                          leadAnalysisDataList?.totals?.[
                            "UGC NET_WebinarCancelled_total"
                          ]
                        }
                      </th>
                    </>
                  </tr>

                  {leadAnalysisDataList?.data?.map((item: any) => {
                    return (
                      <tbody>
                        <tr>
                          {/* ********date*********** */}
                          <td> {item?.date} </td>
                          {/* ********total*********** */}

                          <td> {item?.total_Leads ? item?.total_Leads : 0} </td>
                          <td>
                            {" "}
                            {item?.total_WebinarUpcoming
                              ? item?.total_WebinarUpcoming
                              : 0}{" "}
                          </td>
                          <td>
                            {item?.total_WebinarCompleted
                              ? item?.total_WebinarCompleted
                              : 0}{" "}
                          </td>
                          <td>
                            {item?.total_WebinarPending
                              ? item?.total_WebinarPending
                              : 0}{" "}
                          </td>
                          <td>
                            {" "}
                            {item?.total_WebinarCancelled
                              ? item?.total_WebinarCancelled
                              : 0}
                          </td>

                          {/* ********CSIR NET*********** */}

                          <td>
                            {" "}
                            {item?.["CSIR NET_Lead"]
                              ? item?.["CSIR NET_Lead"]
                              : 0}
                          </td>
                          <td>
                            {" "}
                            {item?.["CSIR NET_WebinarUpcoming"]
                              ? item?.["CSIR NET_WebinarUpcoming"]
                              : 0}
                          </td>
                          <td>
                            {item?.["CSIR NET_WebinarCompleted"]
                              ? item?.["CSIR NET_WebinarCompleted"]
                              : 0}
                          </td>
                          <td>
                            {item?.["CSIR NET_WebinarPending"]
                              ? item?.["CSIR NET_WebinarPending"]
                              : 0}
                          </td>
                          <td>
                            {" "}
                            {item?.["CSIR NET_WebinarCancelled"]
                              ? item?.["CSIR NET_WebinarCancelled"]
                              : 0}
                          </td>

                          {/* ********IIT JAM*********** */}

                          <td>
                            {" "}
                            {item?.["IIT JAM_Lead"]
                              ? item?.["IIT JAM_Lead"]
                              : 0}
                          </td>
                          <td>
                            {" "}
                            {item?.["IIT JAM_WebinarUpcoming"]
                              ? item?.["IIT JAM_WebinarUpcoming"]
                              : 0}
                          </td>
                          <td>
                            {item?.["IIT JAM_WebinarCompleted"]
                              ? item?.["IIT JAM_WebinarCompleted"]
                              : 0}{" "}
                          </td>
                          <td>
                            {item?.["IIT JAM_WebinarPending"]
                              ? item?.["IIT JAM_WebinarPending"]
                              : 0}{" "}
                          </td>
                          <td>
                            {" "}
                            {item?.["IIT JAM_WebinarCancelled"]
                              ? item?.["IIT JAM_WebinarCancelled"]
                              : 0}
                          </td>

                          {/* ********UGC NET*********** */}
                          <td>
                            {" "}
                            {item?.["UGC NET_Lead"]
                              ? item?.["UGC NET_Lead"]
                              : 0}{" "}
                          </td>
                          <td>
                            {" "}
                            {item?.["UGC NET_WebinarUpcoming"]
                              ? item?.["UGC NET_WebinarUpcoming"]
                              : 0}{" "}
                          </td>
                          <td>
                            {item?.["UGC NET_WebinarCompleted"]
                              ? item?.["UGC NET_WebinarCompleted"]
                              : 0}{" "}
                          </td>
                          <td>
                            {item?.["UGC NET_WebinarPending"]
                              ? item?.["UGC NET_WebinarPending"]
                              : 0}{" "}
                          </td>
                          <td>
                            {item?.["UGC NET_WebinarCancelled"]
                              ? item?.["UGC NET_WebinarCancelled"]
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </>
              ) : (
                <tr> No Record Found</tr>
              )}
            </table>
          </>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Analysis"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12   formWidth">
          {SearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
