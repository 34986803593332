import React from "react";

interface props {
  value: string,
  setInputText: Function,
  Isrequired?: boolean,
  defaultValue?: string,
  maxdate?: any,
  mindate?: any,
  disabled?: boolean
}

export default function DateTime({
  value,
  setInputText,
  Isrequired,
  defaultValue,
  maxdate,
  mindate,
  disabled,
}:props) {
  return (
    <div className="d-flex mb-2 align-items-center">
      <input
        className="form-control"
        type="datetime-local"
        value={value?.substring(0, 19)}
        onChange={(e) => setInputText(e.target.value)}
        defaultValue={defaultValue?.substring(0, 19)}
        max={maxdate}
        min={mindate}
        required={Isrequired}
        disabled={disabled}
        step="1"
      />
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}
