import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import GenaralTable from "../../Component/genaralTable";
import { useSelector } from "react-redux";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import moment from "moment"; 
import SalesPanelSummeryGraph from "../../Component/salesPanelSummeryGraph";
import {
  getExecutiveConnectedCallTargetDetails,
  getTalkTimeAnalysisByDate
} from "../../Redux/Actions/bdManagerPanelAction";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
export default function TimeAnalysis() {
  const dispatch = useAppDispatch();
  // const [startDate, SetStartDate] = useState<any>(
  //   moment().startOf("month").format("YYYY-MM-DD")
  // );
  // const [endDate, SetEndDate] = useState<any>(
  //   moment.utc(new Date()).format("YYYY-MM-DD")
  // );
  const [startDate, SetStartDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );

  const [talkTimeList, setTalkTimeList] = useState<any>([]);
  const [talkTimeLabelList, setTalkTimeLabelList] = useState<any>([]);
  const [linkList, setLinkList] = useState<any>([]);

  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [xLabelCallOld, setXLabelCallOld] = useState<any>([]);
  const [xLabelCallUnique, setXLabelCallUnique] = useState<any>([]);

  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const [GraphdataCallOld, setGraphdataCallOld] = useState<any>([]);
  const [GraphdataCallUnique, setGraphdataCallUnique] = useState<any>([]);

  const { crmEmployeeId } = useSelector(
    (state: any) => ({
      crmEmployeeId: state.Auth.counsellorId,
    })
  );

  useEffect(() => {
    // onChangeGetRevenue();
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    dispatch(
      getTalkTimeAnalysisByDate(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let daylist = res?.date;
          daylist?.unshift("Name");
          setTalkTimeLabelList(daylist);
          setTalkTimeList(res?.data);
        },
        () => {}
      )
    );

    dispatch(
      getExecutiveConnectedCallTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphCallTotalConnected(res?.targets);
          showGraphCallOldConnected(res?.targets);
          showGraphCallUniqueConnected(res?.targets);
          let tempLinkList:any = [];
          for (let i = 0; i <= res?.targets?.length; i++) {
            tempLinkList?.push("../timeAnalysisOverview")
          }
          setLinkList(tempLinkList);
        },
        () => {}
      )
    );
  }, []);

  const showGraphCallTotalConnected = (list: any) => {
      let label:any=[];
      let data:any=[];
    list?.map((item: any) => {
      label?.push(item?.executiveName);
      data?.push(item?.totalConnectedCall?.achieved);
    });
    setXLabelCallTotal(label);
    setGraphdataCallTotal(data);
  }
  const showGraphCallOldConnected = (list: any) => {
    let label:any=[];
    let data:any=[];
    list?.map((item: any) => {
      label?.push(item?.executiveName);
      data?.push(item?.oldConnectedCall?.achieved);
    });
    setXLabelCallOld(label);
    setGraphdataCallOld(data);
  };
  const showGraphCallUniqueConnected = (list: any) => {
    let label:any=[];
    let data:any=[];
    list?.map((item: any) => {
      label?.push(item?.executiveName);
      data?.push(item?.uniqueConnectedCall?.achieved);
    });
    setXLabelCallUnique(label);
    setGraphdataCallUnique(data);
  };

  const onChange = (e: any) => {
    e.preventDefault();
    showGraphCallTotalConnected([])
    showGraphCallOldConnected([])
    showGraphCallUniqueConnected([])

    dispatch(
      getExecutiveConnectedCallTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphCallTotalConnected(res?.targets);
          showGraphCallOldConnected(res?.targets);
          showGraphCallUniqueConnected(res?.targets);
          let tempLinkList:any = [];
          for (let i = 0; i <= res?.targets?.length; i++) {
            tempLinkList?.push("../timeAnalysisOverview")
          }
          setLinkList(tempLinkList);
        },
        () => {}
      )
    );
    dispatch(
      getTalkTimeAnalysisByDate(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let daylist = res?.date;
          daylist?.unshift("Name");
          setTalkTimeLabelList(daylist);
          setTalkTimeList(res?.data);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <h4 className="fw-bold">Team Talk Time</h4>
        <GenaralTable
        tableData={talkTimeList ? talkTimeList : []}
        pagename={"BDEtimeAnalysis"}
        tableHead={talkTimeLabelList}
        />
      </div>
    );
  };

  const onChangeStartDate = (val:any) => {
    SetStartDate(val)
    dispatch(updateCommonValues("startDate", val));
  }

  const onChangeEndDate = (val:any) => {
    SetEndDate(val)
    dispatch(updateCommonValues("endDate", val));
  }

  return (
    <div className="contentBox">
      <PageTitle name={"Time Analysis"} />
      <div className="col-md-12 row">
        <div className="formDiv formWidth p-2">
          <form className="  d-flex px-5  mx-5" onSubmit={onChange}>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className=" col-md-3 text-center mb-2  mt-2 me-4 ">
              <SubmitButton name={"Submit"} />
            </div>
          </form>

          <div className="analysisDiv">
            {/***********************************************connected calls************************************************************ */}

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Total Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                {/* <CallRecordedGraph
                  label={xLabelCallTotal}
                  value={GraphdataCallTotal}
                /> */}
                <SalesPanelSummeryGraph
                  label={xLabelCallTotal}
                  value={GraphdataCallTotal}
                  link={linkList}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Old Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                <CallRecordedGraph
                  label={xLabelCallOld}
                  value={GraphdataCallOld}
                  // link={link1}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Unique Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                <CallRecordedGraph
                  label={xLabelCallUnique}
                  value={GraphdataCallUnique}
                />
              </div>
            </div>

            {/* ********************************************Talk Time list********************************************* */}

            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
