import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomDropdown from '../../Component/customDropdown';
import PageTitle from '../../Component/pageTitle';
import PaginationTable from '../../Component/paginationTable';
import PaginationView from '../../Component/paginationView';
import SubmitButton from '../../Component/submitButton';
import usePagination from '../../Component/usePagination';
import { getTableDataSlots } from '../../DATA/dataConstant';
import InputField from '../../Component/inputField';
import { containsNumber, length_Fifty, length_Hundred, length_Ten, length_Three, length_Two, startsWithSpace, validateFullName, validateUniqueId, validateValueWithoutSpace } from '../../DATA/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';
import DatePicker from '../../Component/datePicker';
import LabelField from '../../Component/labelField';
import ToggleSwitchbtn from '../../Component/toggleSwitchBtn';
import Button from '../../Component/button';
import moment from 'moment';
import RestorePopup from '../../Component/restorePopup';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { addBdeEmployee, deleteBdeEmployee, getBdeEmployeeList, getBdeEmployeeRoleList, getBdeManagerList, getState, restoreBdeEmployee, updateBdeEmployee, getTLbyManagerID } from '../../Redux/Actions/bdeAdminPanelAction';
import { useLocation } from 'react-router-dom';

const initialVal: {
  _id: any;
  isActive: boolean;
}[] = [
    {
      _id: "",
      isActive: false,
    },
  ];


export default function BdeEmployee() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isAddEmployee, setAddEmployee] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [firstName, setFirstname] = useState<any>("");
  const [lastName, setLastname] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [currentSalary, setCurrentSalary] = useState<any>("");
  const [userName, setUsername] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [status, setStatus] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");
  const [stateArr, setstateArr] = useState<any>(initialVal);

  const [employeeList, setEmployeeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [empId, setEmpId] = useState<any>("");
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState(false);
  const [resetRole, setResetRole] = useState(false);
  const [resetManager, setResetManager] = useState(false);
  const IsLogIn = location.state?.IsLogIn;



  useEffect(() => {
    getEmpList("active");
  }, []);

  const getEmpList = (data: string) => {
    setCount(0);
    setEmployeeList([]);
    dispatch(
      getBdeEmployeeList(
        {
          skip: 0,
          limit: rowsPerPage,
          status: data,
        },
        (res: any) => {
          setCount(res?.Count);
          setEmployeeList(res?.data);
        },
        () => { }
      )
    );

  }
  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: selectedStatus === 1 ? "active" : "deleted",
      };
    }

    dispatch(
      getBdeEmployeeList(
        data,
        (res: any) => {
          setCount(res?.Count);
          setEmployeeList(res?.data);
        },
        () => { }
      )
    );


  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
    setSelectedStatus(val);
    if (val === 1) {
      getEmpList("active");
    }
    else if (val === 2) {
      getEmpList("deleted");
    }

  };

  const handleDelete = (val: any) => {
    setEmpId(val?._id);
    setOpenDeletePopup(true);
    setAddEmployee(false);
    setisEditMode(false);
  }

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };

  const handleSubmitDelete = () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteBdeEmployee(
        empId,
        () => {
          if (selectedStatus === 1) {
            getEmpList("active");
          } else if (selectedStatus === 2) {
            getEmpList("deleted");
          }
        },
        () => { }
      )
    );
  };
  const handleEdit = (value: any) => {

    setEmpId(value?._id);
    setAddEmployee(true);
    setisEditMode(true);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    setDate(value?.yoj);
    dispatch(
      getBdeEmployeeRoleList(
        "",
        (res: any) => {
          setRoleList(res);
          setRole(value?.role);
        },
        () => { }
      )
    );
    dispatch(
      getBdeManagerList(
        value?.role?._id,
        (res: any) => {
          setManagerList(res);
          setManager(value?.manager_data);
        },
        () => { }
      )
    );

    dispatch(
      getTLbyManagerID(
        "",
        (res: any) => {
          setTeamLeadList(res);
          setTeamLead(value?.manager_data);
        },
        () => { }
      )
    );

    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);
          let newArr = value?.states?.map((item: any) => {
            return {
              _id: item?._id,
              isActive: item?.isActive,
            }
          })
          setstateArr(newArr)
        },
        () => { }
      )
    );
    setCurrentSalary(value?.salary);
    setUsername(value?.userName);
    setEmail(value?.email);
    setPassword(value?.password);
    setMob(value?.contactNo);
    setStatus(value?.status);
    setUniqueId(value?.uniqueId?.toUpperCase());
  };

  const handleRestore = (value: any) => {
    setOpenPopup(true);
    setAddEmployee(false);
    setisEditMode(false);
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
  };

  const handleAddEmployee = () => {
    setAddEmployee(true);
    setstateArr(initialVal);
    dispatch(
      getBdeEmployeeRoleList(
        "",
        (res: any) => {
          setRoleList(res);
        },
        () => { }
      )
    );

    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);
        },
        () => { }
      )
    );

  }

  const cancelAddEmployee = () => {
    setAddEmployee(false)
    setFirstname("");
    setLastname("");
    setDate("");
    setRole("");
    setResetRole(true);
    setManager("");
    setResetManager(true);
    setCurrentSalary("");
    setstateArr(initialVal);
    setUsername("");
    setEmail("");
    setPassword("");
    setMob("");
    setStatus(false);
    setUniqueId("");
  }

  const onPressAdd = () => {
    setstateArr([...stateArr, initialVal[0]]);

  }

  const handleArrClose = (index: number) => {
    const newArrr = [...stateArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setstateArr(newArrr);
  };

  const onchangeFirstName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFirstname(val)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  }

  const onchangelastName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLastname(val)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  }

  const onChangeRole = (val: any) => {
    setRole(val)
    setResetRole(false);
    dispatch(
      getBdeManagerList(
        "",
        (res: any) => {
          setManagerList(res);
        },
        () => { }
      )
    );
    dispatch(
      getTLbyManagerID(
        "",
        (res: any) => {
          setTeamLeadList(res);
        },
        () => { }
      )
    );
  }

  const onChangeManager = (val: any) => {
    setManager(val);
    setResetManager(false);
    dispatch(
      getTLbyManagerID(
        "",
        (res: any) => {
          setTeamLeadList(res);
        },
        () => { }
      )
    );
  }
  const onChangeTL = (val: string) => {
    setTeamLead(val)
  }
  const onchangeCurrentSalary = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setCurrentSalary(val)
      }
    } else {
      setCurrentSalary(val)
    }
  }

  const onChange = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(stateArr));
    if (key === "state") {
      newArrr?.map((item: any) => {
        if (item?._id === value?._id && !isEditMode) {
          dispatch(showErrorSnackbar("This State is already selected") as any)
        }
        else {
          newArrr[index]._id = value?._id;
          setstateArr(newArrr);
        }
      })
    }
    else if (key === "isActive") {
      if (value.target.checked) {
        newArrr[index].isActive = true;
        setstateArr(newArrr);
      } else {
        newArrr[index].isActive = false;
        setstateArr(newArrr);
      }
    }




  };

  const onchangeUserName = (val: string) => {
    setUsername(val)

  }
  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const onchangeEmail = (val: string) => {
    setEmail(val)
  }
  const onChangePassword = (val: string) => {
    setPassword(val)
  }
  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob(val)
      }
    } else {
      setMob(val)
    }
  }
  const onChangeStatus = (event: any) => {
    if (event.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const onchangeUniqueId = (val: string) => {
    if (validateUniqueId(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase())
    }
    else {
      dispatch(showErrorSnackbar("Please Character only & space is not allowed") as any);
      return;
    }
  }

  // window.onhashchange = function() {
  //   if (IsLogIn== true){
  //     history.back()
  //   }
  //   else{
  //     history.forward()
  //   }
  // }



  const cancelEditEmployee = () => {
    setAddEmployee(false)
    setisEditMode(false)
    setFirstname("");
    setLastname("");
    setDate("");
    setRole("");
    setResetRole(true);
    setManager("");
    setResetManager(true);
    setCurrentSalary("");
    setstateArr(initialVal);
    setUsername("");
    setEmail("");
    setPassword("");
    setMob("");
    setStatus(false);
    setUniqueId("");
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // if(stateArr?.length > 0){
    //   stateArr?.map((item:any)=>{ 
    //       if( item?._id.includes(stateArr)) {
    //         dispatch(showErrorSnackbar("This State is already selected") as any) 
    //       }
    //   })
    // } 
    const postData: any = {
      firstName: firstName?.toUpperCase(),
      lastName: lastName?.toUpperCase(),
      yoj: date,
      role: role?._id,
      salary: currentSalary,
      username: userName,
      password: password,
      email: email,
      contactNo: mob,
      status: status ? 1 : 0,
      uniqueId: uniqueID
    }
    if (role?._id === 3) {
      postData['managerId'] = manager?._id;
    }
    if (role?._id === 4) {
      postData['states'] = stateArr;
      postData['managerId'] = teamLead?._id;


    }

    dispatch(
      addBdeEmployee(
        postData,
        (res: any) => {
          setFirstname("");
          setLastname("");
          setDate("");
          setRole("");
          setResetRole(true);
          setManager("");
          setResetManager(true);
          setCurrentSalary("");
          setstateArr(initialVal);
          setUsername("");
          setEmail("");
          setPassword("");
          setMob("");
          setStatus(false);
          setUniqueId("");
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("active");
        },
        () => { }
      )
    );
    // }

  }


  const handleUpdate = (e: any) => {
    e.preventDefault();
    let postData = {
      yoj: date,
      role: role?._id,
      salary: currentSalary,
      password: password,
      email: email,
      contactNo: mob,
      status: status ? 1 : 0,
      managerId: manager?._id,
      states: stateArr
    }
    if (role?._id === 4) {
      postData['managerId'] = teamLead?._id;


    }
    dispatch(
      updateBdeEmployee(
        empId,
        postData,
        () => {
          setFirstname("");
          setLastname("");
          setDate("");
          setRole("");
          setResetRole(true);
          setManager("");
          setResetManager(true);
          setCurrentSalary("");
          setUsername("");
          setEmail("");
          setPassword("");
          setMob("");
          setStatus(false);
          setUniqueId("");
          setstateArr(initialVal);
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("active");
        },
        () => { }
      )
    );
  }

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleSubmitRestore = () => {
    setOpenPopup(false);
    let postData = {
      status: 0,
    };
    dispatch(
      restoreBdeEmployee(
        empId,
        postData,
        () => {
          setOpenPopup(false);
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("deleted");
        },
        () => {
          setOpenPopup(false);
        }
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        {employeeList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        <PaginationView
          pagename={"ManageEmployee"}
          count={count}
          tableData={employeeList ? employeeList : []}
          tabs={["Active", "Deleted"]}
          onTabChange={(e: any, index: any) => showData(e, index)}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
          handleClick={handleAddEmployee}
        />

        {selectedStatus === 1 ? (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"BDEmployee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "States Allocated",
              "Status",
              "Edit",
              "Delete",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        ) : (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"BDEmployee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "States Allocated",
              "Status",
              "Restore",
              "",
            ]}
            edit={true}
            handleEdit={(value: any) => handleRestore(value)}
          />
        )}
      </>
    );
  }

  const renderAddMode = () => {
    return (
      <>
        {!isEditMode ? (
          <h2 className="text-primary">Add Employee</h2>
        ) : (
          <h2 className="text-primary">Edit Employee</h2>
        )}
        <form
          className="col-md-10  shadow-lg py-4 px-3"
          onSubmit={!isEditMode ? handleSubmit : handleUpdate}
        >
          <div className="row d-flex justify-content-">
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => onchangeFirstName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => onchangelastName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>

            <div className="col-md-4 d-flex my-3">
              <div className="col-5 mt-2">
                <LabelField lableName="Date Of Joining" />
              </div>
              <DatePicker
                labelName={"Date of Joining"}
                value={date}
                setInputText={(value: string) => setDate(value)}
                Isrequired={true}
                defaultValue={date}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
            <div className="col-md-4  my-3">
              <CustomDropdown
                lableName="Select Role"
                setInputText={(value: any) => onChangeRole(value)}
                value={role}
                options={roleList}
                Isrequired={true}
                reset={resetRole}
                defaultValue={role?._id}
                disabled={isEditMode}
              />
            </div>

            {role?._id === 3 ? (
              <div className="col-md-4 my-3 ">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                  Isrequired={true}
                  reset={resetManager}
                  defaultValue={manager?._id}
                  disabled={role?._id === 2 ? true : false}
                />
              </div>
            ) : null}
            {role?._id === 4 ? (
              <div className="col-md-4 my-3  ">
                <div className="">
                  <CustomDropdown
                    lableName="Select Team Lead"
                    setInputText={(value: any) => onChangeTL(value)}
                    value={teamLead}
                    options={teamLeadList}
                    Isrequired={true}
                    reset={resetTeamLead}
                    defaultValue={manager?._id}
                  // disabled={role?._id === 2 ? true : false}
                  />
                </div>
              </div>
            ) : null}
            <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Current Salary"
                value={currentSalary}
                onChangeInput={(value: any) => onchangeCurrentSalary(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
              />
            </div>

            {role?._id !== 2 && role?._id !== 3 ? (
              <>
                {stateArr?.map((item: any, index: number) => {
                  const { _id, isActive } = item;
                  return (
                    <div className="position-relative" key={index}>
                      <span className="d-flex justify-content-end removeButton">
                        {stateArr.length === 1 ? null : (
                          <HighlightOffIcon
                            className="cursor"
                            color="primary"
                            fontSize="small"
                            onClick={() => handleArrClose(index)}
                          />
                        )}
                      </span>
                      <div className="d-flex position-relative">
                        <div className="col-md-3 my-1 ">
                          <CustomDropdown
                            lableName="Select State"
                            setInputText={(value: any) =>
                              onChange(index, value, "state")
                            }
                            value={_id}
                            options={stateList}
                            Isrequired={true}
                            defaultValue={stateList?.length > 0 && isEditMode ? _id : ""}
                          // disabled={
                          //   role?._id === 1 || role?._id === 2 || role?._id === 9 || role?._id === 5 ? true : false
                          // }
                          />
                        </div>

                        <div className="col-md-3 my-3">
                          <ToggleSwitchbtn
                            lableName="Active"
                            getToggleval={(value: any) => {
                              onChange(index, value, "isActive");
                            }}
                            isActive={isActive}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {role?._id === 1 ||
                  role?._id === 2 ||
                  role?._id === 9 ||
                  role?._id === 5 ? null : (
                  <div className="d-flex justify-content-end">
                    <Button name={"Add More"} handleClick={onPressAdd} />
                  </div>
                )}
              </>
            ) : null}

            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter User Name"
                value={userName}
                onChangeInput={(value: any) => onchangeUserName(value)}
                maxlength={length_Hundred - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>

            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangePassword(value)}
                disabled={isEditMode}
                maxlength={length_Hundred - 1}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Email"
                value={email}
                onChangeInput={(value: any) => onchangeEmail(value)}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Mobile Number"
                value={mob}
                onChangeInput={(value: any) => onChangeContact(value)}
                maxlength={length_Ten}
                minlength={length_Ten}
                isRequired={true}
              />
            </div>

            <div className="col-md-4 my-3">
              <ToggleSwitchbtn
                lableName="Status"
                getToggleval={(value: any) => {
                  onChangeStatus(value);
                }}
                isActive={status}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Unique Id"
                value={uniqueID}
                onChangeInput={(value: any) => onchangeUniqueId(value)}
                isRequired={true}
                disabled={isEditMode}
                maxlength={length_Hundred - 1}
              />
            </div>
            <div className="col-md-4 my-3"></div>
          </div>
          <div className="d-flex justify-content-end me-3">
            <div className="me-2">
              {!isEditMode ? (
                <Button name={"Cancel"} handleClick={cancelAddEmployee} />
              ) : (
                <Button name={"Cancel"} handleClick={cancelEditEmployee} />
              )}
            </div>
            {!isEditMode ? (
              <div>
                <SubmitButton name={"Save"} validation={validation()} />
              </div>
            ) : (
              <div>
                <SubmitButton name={"Save & Update"} />
              </div>
            )}
          </div>
        </form>
      </>
    );
  }

  const validation = () => {
    return (
      firstName === "" || firstName?.length < 3 ||
      lastName === "" || lastName?.length < 3 ||
      date === "" ||
      role === "" ||
      currentSalary === "" ||
      userName === "" ||
      email === "" || email?.length < length_Three || !email.match(emailsCheck) ||
      password === "" ||
      mob === "" || mob?.length < 10 ||
      uniqueID === "" 
      ||
      role?._id ===4  ? teamLead === "" : false ||
      role?._id ===3  ? manager==="" : false
    );
  }

  return (
    <div className="contentBox">
      <PageTitle
        name={"Manage Employee"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="col-md-12 px-4 px-0 pt-3 formDiv">
        {!isAddEmployee && !isEditMode ? renderListMode() : renderAddMode()}
      </div>
      {openPopup ? (
        <RestorePopup
          open={openPopup}
          handleClose={handleClose}
          handleSubmitRestore={() => {
            handleSubmitRestore();
          }}
          title={"Restore Employee"}
          data={"Do you want to restore this employee ?"}
          buttonTitle={"Restore"}
        />
      ) : (
        ""
      )}
      {openDeletePopup ? (
        <RestorePopup
          open={openDeletePopup}
          handleClose={handleCloseDelete}
          handleSubmitRestore={() => {
            handleSubmitDelete();
          }}
          title={"Delete Employee"}
          data={"Do you want to delete this ?"}
          buttonTitle={"Delete"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

