import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getEmployeetByManagerId,
  getEnrolledAnalysis,
} from "../../Redux/Actions/managerPanelAction";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function EnrolledAnalysis() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState();
  const [counsellor, setCounsellor] = useState<any>();
  const [counsellorList, setCounsellorList] = useState([]);
  const [resetCounsellor, setResetCounsellor] = useState();
  const [enrolledList, setEnrolledList] = useState([]);
  const [totalRevenueAmount, setTotalRevenueAmount] = useState(0);

  const pageName = location.state?.pageName;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;
  const counsellorId = location.state?.counsellorId;

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    setCount(0);
    setEnrolledList([]);
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        managerId,
        counsellorId: counsellor?._id,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        managerId,
        counsellorId: counsellor?._id,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        managerId,
        counsellorId: counsellor?._id,
        startDate: startDate,
        endDate: endDate,
      };
    }

    dispatch(
      getEnrolledAnalysis(
        data,
        (res: any) => {
          setCount(res?.count);
          setEnrolledList(res?.data);
          let price = 0;
          res?.data?.forEach((item: any) => {
            price = price + item?.ammountPaid;
          });
          setTotalRevenueAmount(price);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    if (loggedInUserRole === 1) {
      dispatch(
        getEmployeetByManagerId(
          managerId,
          (res: any) => {
            setManagerList(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getEmployeetByManagerId(
          managerId,
          (res: any) => {
            setCounsellorList(res?.data);
          },
          () => {}
        )
      );

      const postData: any = {
        // counsellorId:val._id,
        managerId,
        startDate: startDate,
        endDate: endDate,
        skip: 0,
        limit: rowsPerPage,
      };
      if (pageName === "callerDetails") {
        SetStartDate(leadStartDate);
        SetEndDate(leadEndDate);
        setCounsellor(counsellorId);
        postData["startDate"] = leadStartDate;
        postData["endDate"] = leadEndDate;
        postData["counsellorId"] = counsellorId;
      }
      dispatch(
        getEnrolledAnalysis(
          postData,
          (res: any) => {
            setCount(res?.count);
            setEnrolledList(res?.data);
            let price = 0;
            res?.data?.forEach((item: any) => {
              price = price + item?.ammountPaid;
            });
            setTotalRevenueAmount(price);
          },
          () => {}
        )
      );
    }
  }, []);

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    setCount(0);
    setEnrolledList([]);
    if (loggedInUserRole === 2) {
      const postData = {
        startDate: startDate,
        endDate: val,
        skip: 0,
        limit: rowsPerPage,
        managerId,
      };
      dispatch(
        getEnrolledAnalysis(
          postData,
          (res: any) => {
            setCount(res?.count);
            setEnrolledList(res?.data);
            let price = 0;
            res?.data?.forEach((item: any) => {
              price = price + item?.ammountPaid;
            });
            setTotalRevenueAmount(price);
          },
          () => {}
        )
      );
    }
  };

  const onChangeManager = (val: any) => {
    setManager(val);
    setCount(0);
    setEnrolledList([]);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
    const postData = {
      managerId: val?._id,
      startDate: startDate,
      endDate: endDate,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getEnrolledAnalysis(
        postData,
        (res: any) => {
          setCount(res?.count);
          setEnrolledList(res?.data);
          let price = 0;
          res?.data?.forEach((item: any) => {
            price = price + item?.ammountPaid;
          });
          setTotalRevenueAmount(price);
        },
        () => {}
      )
    );
  };
  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    setCount(0);
    setEnrolledList([]);
    let postData = {};
    if (loggedInUserRole === 1) {
      postData = {
        managerId: manager?._id,
        counsellorId: val?._id,
        startDate: startDate,
        endDate: endDate,
        skip: 0,
        limit: rowsPerPage,
      };
    } else if (loggedInUserRole === 2) {
      postData = {
        managerId,
        counsellorId: val?._id,
        startDate: startDate,
        endDate: endDate,
        skip: 0,
        limit: rowsPerPage,
      };
    }

    dispatch(
      getEnrolledAnalysis(
        postData,
        (res: any) => {
          setCount(res?.count);
          setEnrolledList(res?.data);
          let price = 0;
          res?.data?.forEach((item: any) => {
            price = price + item?.ammountPaid;
          });
          setTotalRevenueAmount(price);
        },
        () => {}
      )
    );
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?.studentLeadId));
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    navigate("../completeLeadDetail", {
      state: {
        // data: data,
        pageName: "enrolledAnalysis",
        // startDate,
        // endDate,
        counsellorId: counsellor?._id,
      },
    });
  };

  const renderSearchMode = () => {
    return (
      <form className="col-md-8 row d-flex pt-4">
        <div className="col-md-6 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9  ">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="col-md-6 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9 ">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => onChangeEndDate(value)}
              maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </div>
        {loggedInUserRole === 1 ? (
          <div className="col-md-6 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Select Manager" />
            </div>
            <div className="col-md-9 ps-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
                reset={resetManager}
              />
            </div>
          </div>
        ) : null}

        <div className="col-md-6 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Select Counsellor" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Counsellor"
              setInputText={(value: any) => onChangeCounsellor(value)}
              value={counsellor}
              options={counsellorList}
              Isrequired={true}
              reset={resetCounsellor}
              defaultValue={counsellorList?.length > 0 ? counsellor : null}
            />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {enrolledList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            title={`Total:  ${totalRevenueAmount}`}
            pagename={"EnrolledAnalysis"}
            count={count}
            tableData={enrolledList ? enrolledList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={enrolledList ? enrolledList : []}
          pagename={"EnrolledAnalysis"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "First Name",
            "Last Name",
            // "Contact No.",
            "State",
            "Exam",
            "Exam Stream",
            "Course Type",
            "Study Material",
            "Source",
            "Counsellor Name",
            "Amount Paid",
            "Admission Date",
            // "Registration Date",
          ]}
          edit={false}
          handleClick={onClickLead}
        />
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Enrolled Analysis Manager"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
