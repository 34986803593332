
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import SubmitButton from '../../Component/submitButton';
import InputField from '../../Component/inputField'
import LabelField from '../../Component/labelField';
import { containsNumber, length_Ten, validateValueWithoutSpace } from '../../DATA/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';
import { getCallTime ,updateCallTime } from '../../Redux/Actions/bdeAdminPanelAction';

export default function CallTimeTarget() {
  const dispatch = useDispatch();

  const [updatedCallCount, setUpdatedCallCount] = useState("");
  const [id, setId] = useState<any>("");

  useEffect(() => {
    dispatch(
      getCallTime(
        '',
        (res: any) => {
          setId(res?._id);
          setUpdatedCallCount(res?.callTime);
        },
        () => { }
      )
    );

  }, []);


  const onchangeCallTime = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setUpdatedCallCount(value);
      }
    } else {
        setUpdatedCallCount(value);
    }
  }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
     
      callTime: updatedCallCount
    }
    dispatch(
      updateCallTime(
        id,
        postData,
        (res: any) => {
          dispatch(
            getCallTime(
              "",
              (res: any) => { 
                // setUpdatedCallCount(res?.callTime);
                // setId(res?._id);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );

  };


  const renderSearchMode = () => {
    return (
      <form className="d-flex" onSubmit={handleSubmit}>

        <div className="col-md-5 d-flex mb-2 col-4">
          <LabelField lableName={"BDE Call Time :"} />
          <InputField
            placeholder="Enter Call Time"
            value={updatedCallCount}
            onChangeInput={(value: any) => onchangeCallTime(value)}
            maxlength={length_Ten}
          
          />
        </div>
        <div className="d-flex mb-2 col-4 me-4">
          <div>
            <SubmitButton name={"Update"} />
          </div>
        </div>
      </form>
    );
  };


  return (
    <div className="contentBox ">
      <PageTitle name={"Call Time Target"} />
      <div className="  p-3 formDiv">
        <div className="">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div>

              </div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}

