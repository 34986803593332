
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import SubmitButton from '../../Component/submitButton';
import InputField from '../../Component/inputField'
import LabelField from '../../Component/labelField';
import { containsNumber, length_Ten, validateValueWithoutSpace } from '../../DATA/validators';
import { getCounsellorUniqueCall, updateCounsellorUniqueCall } from '../../Redux/Actions/leadManagementAction';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';
import { getUniqueCallTime , updateUniqueCallTime} from '../../Redux/Actions/bdeAdminPanelAction';

export default function UniqueCallTarget() {
  const dispatch = useDispatch();

  const [updatedUniqueCallCount, setUpdatedUniqueCallCount] = useState("");
  const [id, setId] = useState<any>("");

  useEffect(() => {
    dispatch(
      getUniqueCallTime(
        "",
        (res: any) => {
          setId(res?._id);
          setUpdatedUniqueCallCount(res?.count);
        },
        () => { }
      )
    );

  }, []);


  const onchangeUniqueCallTarget = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setUpdatedUniqueCallCount(value);
      }
    } else {
        setUpdatedUniqueCallCount(value);
    }
  }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      count: updatedUniqueCallCount
    }
    dispatch(
      updateUniqueCallTime(
        id,
        postData,
        (res: any) => {
          dispatch(
            getUniqueCallTime(
              "",
              (res: any) => {
                setUpdatedUniqueCallCount(res?.count);
                setId(res?._id);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );

  };



  const renderSearchMode = () => {
    return (
      <form className="d-flex" onSubmit={handleSubmit}>

        <div className="d-flex mb-2 col-6">
          <LabelField lableName={"BDE Unique Call :"} />
          <InputField
            placeholder="Enter Unique Call"
            value={updatedUniqueCallCount}
            onChangeInput={(value: any) => onchangeUniqueCallTarget(value)}
            maxlength={length_Ten}
          // minlength={length_Two}
          />
        </div>
        <div className="d-flex mb-2 col-4 me-4">
          <div>
            <SubmitButton name={"Update"} />
          </div>
        </div>
      </form>
    );
  };


  return (
    <div className="contentBox ">
      <PageTitle name={"Unique Call Target"} />
      <div className="p-3 formDiv">
        <div className=" ">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div>
              </div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}

