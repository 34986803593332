import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import SubmitButton from "../../Component/submitButton";
import DatePicker from "../../Component/datePicker";
import { getResulCompilation } from "../../Redux/Actions/bdeAdminPanelAction";
import { DownloadTableExcel } from "react-export-table-to-excel";

export default function StudentResult() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [resulCompilation, setResulCompilation] = useState<any>([]);
  const [qualifyYear, setQualifyYear] = useState<any>("");
  const [qualifyFor, setQualifyFor] = useState<any>("");

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
        examTypeId: exam?._id,
        baseCourseId: examStream?._id,
      };
    }
    dispatch(
      getResulCompilation(
        data,
        (res: any) => {
          setCount(res?.Count);
          setResulCompilation(res);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeExam = (val: any) => {
    setExam(val);

    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };
  const onChangeQualifyFor = (val: string) => {
    setQualifyFor(val);
  };

  const handleNavigate = (data: any) => {
    // navigate("../collegeDetails", {
    //   state: {
    //     leadData: data?._id,
    //   },
    // });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      examTypeId: exam?._id,
      baseCourseId: examStream?._id,
      // skip: 0,
      // limit: rowsPerPage,
      // qualifyYear: qualifyYear?.name,
      // qualifyFor: qualifyFor?.name,
    };
    dispatch(
      getResulCompilation(
        postData,
        (res: any) => {
          setCount(res?.Count);
          setResulCompilation(res);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <div className=" ms-5 p-2 ">
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="col-md-4 d-flex my-3 ">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>

            <div className="col-md-4 d-flex my-3 ms-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Exam Name" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Exam"
                  setInputText={(value: any) => onChangeExam(value)}
                  value={exam}
                  options={examList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Exam Stream" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Exam Stream"
                  setInputText={(value: any) => onChangeExamStream(value)}
                  value={examStream}
                  options={examStreamList}
                />
              </div>
            </div>
            <div className=" d-flex mb-3 ms-5">
              <SubmitButton name={"Submit"} />
            </div>
          </div>
          {/* <div className="d-flex">
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Qualify Year" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Qualify Year"
                  setInputText={(value: any) => setQualifyYear(value)}
                  value={qualifyYear}
                  options={yearData}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Qualified for" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Qualified for"
                  setInputText={(value: any) => onChangeQualifyFor(value)}
                  value={qualifyFor}
                  options={ResultOtherDetails}
                />
              </div>
            </div>
            
          </div> */}
        </form>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex justify-content-end mt-4">
          <div className="mt-4">
            {resulCompilation?.length !== 0 &&
            resulCompilation?.data?.length !== 0 ? (
              <div className="d-flex mb-2 align-items-center">
                <DownloadTableExcel
                  filename="student_count"
                  sheet="Lead Count"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-outline-primary mb-3 submitbtn py-1">
                    Export Details
                  </button>
                </DownloadTableExcel>
              </div>
            ) : null}
          </div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        </div>

        <PaginationView
          pagename={"showResult"}
          count={count}
          tableData={resulCompilation ? resulCompilation : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />

        <PaginationTable
          tableData={resulCompilation ? resulCompilation : []}
          pagename={"showResult"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Student Name",
            "Exam Name",
            "Exam Stream",
            "Qualification",
            "Qualified Year",
            "Added date",
          ]}
          // handleNavigate={(value: any) => handleNavigate(value)}
        />
        <div ref={tableRef} className="d-none">
          <PaginationTable
            tableData={resulCompilation ? resulCompilation : []}
            pagename={"showResultExport"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Student Name",
              "Exam Name",
              "Exam Stream",
              "Qualification",
              "Qualified Year",
              "Added date",
              "Rank",
              "Roll Number",
            ]}
          />
        </div>
      </>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"STUDENT RESULT"} />
      <div className="  p-3 formDiv">
        <div className="">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              {renderSearchMode()}
              {renderListMode()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
