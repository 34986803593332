import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import moment from "moment";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import { getStatesList } from "../../Redux/Actions/counsellorPanelAction";
import {
  getExecutiveByManagerID,
  getTLDepartmentAddedList,
} from "../../Redux/Actions/bdManagerPanelAction";

export default function CollegeDepartmentAdded() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [state, setState] = useState<any>("");
  const [stateList, setStateList] = useState([]);
  const [ResetState, setResetState] = useState(false);
  const [executive, setExecutive] = useState<any>("");
  const [executiveList, setExecutiveList] = useState([]);
  const [ResetExecutive, setResetExecutive] = useState(false);
  const [departmentList, setDepartmentList] = useState<any>([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        stateId: state?._id,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        stateId: state?._id,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        stateId: state?._id,
        startDate: startDate,
        endDate: endDate,
      };
    }
    dispatch(
      getTLDepartmentAddedList(
        data,
        (res: any) => {
          setDepartmentList(res?.departmentData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    dispatch(
      getExecutiveByManagerID(
        "",
        (res: any) => {
          setExecutiveList(res);
        },
        () => {}
      )
    );

    // dispatch(
    //   getTLDepartmentAddedList(
    //      {},
    //     (res: any) => {
    //       setDepartmentList(res )

    //     },
    //     () => { }
    //   )
    // );
  }, []);

  const onChangeBDExecutive = (val: any) => {
    setExecutive(val);
    dispatch(
      getStatesList((res: any) => {
        if (res?.data?.length > 0) {
          setStateList(res?.data);
        } else {
          setStateList([]);
        }
      })
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();

    const Postdata = {
      startDate: startDate,
      endDate: endDate,
      stateId: state?._id,
      executiveId: executive?._id,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getTLDepartmentAddedList(
        Postdata,
        (res: any) => {
          setDepartmentList(res?.departmentData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  const onChangeState = (val: any) => {
    setState(val);
  };

  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        <div className="col-md-12 row d-flex">
          <div className="col-md-4 row d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 row d-flex">
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Executive" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Executive"
                setInputText={(value: any) => onChangeBDExecutive(value)}
                value={executive}
                options={executiveList}
                reset={ResetExecutive}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="State" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select State"
                setInputText={(value: any) => onChangeState(value)}
                value={state}
                options={stateList}
                reset={ResetState}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-4">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };
  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={departmentList ? departmentList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <div>
            <PaginationTable
              tableData={departmentList ? departmentList : []}
              pagename={"collegeDepartmentList"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Date",
                "College Name",
                "State",
                "City",
                "Exam Name",
                "Department",
                "Primary Coordinator",
                "Secondary Coordinator",
                "Coordinator Name",
              ]}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"College Departments"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12   formWidth">
          {SearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
