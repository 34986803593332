import React from "react";
import { length_Ten, length_OneThousandFiveHundred } from "../DATA/validators";
import LabelField from "./labelField";

interface props {
  labelName: string;
  placeholder: string;
  value?: any;
  disabled?: boolean;
  id?: string;
  setInputText?: any;
  mode?: any;
}

function InputFieldRemark(props: props) {
  const {
    labelName,
    placeholder = "Enter Remark",
    value,
    id,
    setInputText,
    disabled,
    mode,
  } = props;

  const onChangeText = (e: any) => {
    setInputText({ ...value, remark: allowOnlyOneSpace(e.target.value) });
  };

  const onChangeCheckbox = (e: any) => {
    if (mode === "edit") {
      setInputText({ ...value, isAudited: e.target.checked });
    }
  };

  const allowOnlyOneSpace = (str: any) => {
    return str.endsWith(" ") ? str.trim() + " " : str.trim();
  };

  return (
    <div className="d-flex mb-2 align-items-center form-check form-switch">
      <div className="col-2">
        <LabelField lableName={labelName} />
      </div>
      <input
        id={id}
        className="form-check-input me-3 ms-2"
        type="checkbox"
        role="switch"
        checked={value?.isAudited}
        defaultChecked={value?.isAudited}
        onChange={(e) => onChangeCheckbox(e)}
        disabled={mode === "view" ? true : false}
      />
      <div className="col-9">
        <textarea
          className="form-control"
          placeholder={placeholder}
          onChange={(e) => onChangeText(e)}
          maxLength={length_OneThousandFiveHundred}
          minLength={length_Ten}
          value={value?.remark}
          defaultValue={value?.remark}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default InputFieldRemark;
