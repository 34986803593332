import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import moment from "moment";
import Header from "../../Component/header";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import DateTime from "../../Component/dateTime";
import InputField from "../../Component/inputField";
import SubmitButton from "../../Component/submitButton";
import { webinarStatus } from "../../DATA/dataConstant";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { pdf_size_1MB, validateIsFileImageType } from "../../DATA/validators";
import { validateIsFileImageTypeErrMsg } from "../../DATA/errorMsg";
import {
  updateCancelledWebinar,
  updateCompletedWebinar,
  updateRescheduleWebinar,
  uploadMailScreenshot,
} from "../../Redux/Actions/bdExecutivePanelAction";
import ReplyIcon from "@mui/icons-material/Reply";

export default function CancelledWebinarStatusDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const webinarId = location.state?.webinarId;
  const webinarData = location.state?.webinarData;

  const [status, setStatus] = useState<any>("Cancelled");

  const [remark, setRemark] = useState<any>("");

  const onChangeStatus = (val: any) => {
    setStatus(val);
  };

  const changeRemark = (val: any) => {
    setRemark(val);
  };

  return (
    <div className="col-md-12 maindiv">
      <div className="mt-2 ">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => navigate(-1)}
        >
          <ReplyIcon /> Back
        </button>
      </div>
      <div className="row m-0">
        <div className="col-md-12 font14 p-5 pt-3 ">
          <div className="mx-5 detailsdiv boxshadow p-3">
            <div className="row d-flex justify-content-between font17">
              {/* ************************ Co-Ordinator Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Co-Ordinator</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Name:</div>
                  <div className="col-md-8">{webinarData?.coordinatorName}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Phone :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorPhone}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Email :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorEmail}
                  </div>
                </div>
              </div>
              {/* ************************Webinar Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Webinar Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Date :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Time :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("hh:mm:ss a")}
                  </div>
                </div>

                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Faculty :</div>
                  <div className="col-md-8">
                    {webinarData?.teacherName ? webinarData?.teacherName : "-"}
                  </div>
                </div>
              </div>

              {/* ************************Collage Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Collage Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Collage :</div>
                  <div className="col-md-8">
                    {webinarData?.college_data?.collegeName}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">State :</div>
                  <div className="col-md-8">
                    {webinarData?.state_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">City :</div>
                  <div className="col-md-8">
                    {webinarData?.college_data?.district}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Exam :</div>
                  <div className="col-md-8">
                    {webinarData?.examType_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Department :</div>
                  <div className="col-md-8">
                    {webinarData?.department_data?.deptName}
                  </div>
                </div>
              </div>

              {/* *********************************Lead Details *************************************** */}

              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Lead Details:</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Lead Registered :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.TotalCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">First Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.FirstYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Second Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.SecondYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Third Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.ThirdYearCount}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom border-3 border-dark">
              <h5 className="text-primary fw-bold">Webinar Status</h5>
            </div>
            {/* *************************************************Status****************************************************** */}
            <form className="col-md-12 mt-4">
              <div className="col-md-9 mt-4">
                <div className="col-md-6 row d-flex justify-content-between mt-1">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Status" />
                  </div>
                  <div className="col-md-7  ps-3">
                    <InputField
                      placeholder=""
                      value={status}
                      onChangeInput={(value: any) => onChangeStatus(value)}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-6 row d-flex justify-content-between mt-1">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Remark" />
                  </div>
                  <div className="col-md-7  ps-3">
                    <textarea
                      className="form-control"
                      placeholder={"Remark"}
                      value={webinarData?.remark_data?.remark}
                      onChange={(e) => changeRemark(e.target.value)}
                      disabled={true}
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
