import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import SubmitButton from "../../Component/submitButton";
import DatePicker from "../../Component/datePicker";

export default function StudentResult() {
  const dispatch = useDispatch();

  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
      };
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeExam = (val: any) => {
    setExam(val);

    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleEdit = (value: any) => {};

  const handleDelete = (value: any) => {};

  const renderSearchMode = () => {
    return (
      <div className=" ms-5 p-2 ">
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="col-md-4 d-flex my-3 ">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>

            <div className="col-md-4 d-flex my-3 ms-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Exam Name" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Exam"
                  setInputText={(value: any) => onChangeExam(value)}
                  value={exam}
                  options={examList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Exam Stream" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Exam Stream"
                  setInputText={(value: any) => onChangeExamStream(value)}
                  value={examStream}
                  options={examStreamList}
                />
              </div>
            </div>
            <div className=" d-flex mb-3 ms-5">
              <SubmitButton name={"Submit"} />
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>

        <PaginationView
          pagename={"showResult"}
          count={count}
          tableData={""}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />

        <PaginationTable
          tableData={""}
          pagename={"showResult"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Exam Name",
            "Exam Stream",
            "Qualification",
            "Added date",
            "Edit",
            "Delete",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"STUDENT RESULT"} />
      <div className="  p-3 formDiv">
        <div className="">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              {renderSearchMode()}
              {renderListMode()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
