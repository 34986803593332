import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import DatePicker from "../../Component/datePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import { getExecutiveByManagerID } from "../../Redux/Actions/bdManagerPanelAction";
import { getBDEManagerRemark } from "../../Redux/Actions/bdExecutivePanelAction";
export default function ManagerRemark() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [managerRemarkList, setManagerRemarkList] = useState([]);
  const [executive, setExecutive] = useState<any>("");
  const [executiveList, setExecutiveList] = useState([]);
  const [ResetExecutive, setResetExecutive] = useState(false);
  // const [startDate, SetStartDate] = useState<any>(
  //   moment.utc(new Date()).format("YYYY-MM-DD")
  // );
  // const [endDate, SetEndDate] = useState<any>(
  //   moment.utc(new Date()).format("YYYY-MM-DD")
  // );

  const [startDate, SetStartDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );

  const { teamLeadID } = useSelector((state: any) => ({
    teamLeadID: state.Auth.executiveID,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
        managerId: teamLeadID,
        executiveId: executive?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
        managerId: teamLeadID,
        executiveId: executive?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
        managerId: teamLeadID,
        executiveId: executive?._id,
      };
    }
    dispatch(
      getBDEManagerRemark(
        data,
        (res: any) => {
          setCount(res?.count);
          setManagerRemarkList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      managerId: teamLeadID,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getBDEManagerRemark(
        data,
        (res: any) => {
          setCount(res?.count);
          setManagerRemarkList(res?.data);
        },
        () => {}
      )
    );

    dispatch(
      getExecutiveByManagerID(
        "",
        (res: any) => {
          setExecutiveList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeBDExecutive = (val: any) => {
    setExecutive(val);
  };

  const changeEndDate = (val: any) => {
    SetEndDate(val);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const data = {
      startDate: startDate,
      endDate: endDate,
      managerId: teamLeadID,
      executiveId: executive?._id,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getBDEManagerRemark(
        data,
        (res: any) => {
          setCount(res?.count);
          setManagerRemarkList(res?.data);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <>
        <form className="row d-flex  " onSubmit={handleSearch}>
          <div className="col-md-3 d-flex pb-3 pe-2 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex pb-3 pe-2">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => changeEndDate(value)}
              />
            </div>
          </div>

          <div className="col-md-3 d-flex mb-3">
            <div className="col-md-5 mt-2">
              <LabelField lableName="BD Executive" />
            </div>
            <div className="col-md-7 ms-2 pe-2">
              <CustomDropdown
                lableName="Select BD Executive"
                setInputText={(value: any) => onChangeBDExecutive(value)}
                value={executive}
                options={executiveList}
                reset={ResetExecutive}
              />
            </div>
          </div>

          <div className="col-md-3 justify-content-end">
            <button className="btn btn-primary " type="submit">
              {" "}
              Search
            </button>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {managerRemarkList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {/* {count > 0 ? ( */}
        <PaginationView
          pagename={"ManagerRemarkTeamLead"}
          count={count}
          tableData={managerRemarkList ? managerRemarkList : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        {/* ) : null} */}

        <PaginationTable
          tableData={managerRemarkList ? managerRemarkList : []}
          pagename={"ManagerRemarkTeamLead"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Department Name",
            "Executive Name",
            "Manager Remark",
          ]}
          handleClick={(data: any) => onClickLead(data)}
        />
      </>
    );
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?.studentLeadsId));
    navigate("../ManagerRemarkDetails", {
      state: {
        // data: data,
        departmentID: data?.departmentId,
        IsEdit: true,
        pageName: "ManagerRemarkTeamLead",
      },
    });
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Manager Remark"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth pt-2 p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
