import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/loading";
import { CrmTeamLeadPanelSidebarData } from "../../DATA/crmTeamLeadPanelSidebarData";

import ManagerDashbord from "./managerDashbord";
import TeamLeadsOverview from "./teamLeadsOverview";
import CounsellorLeadOverview from "./counsellorLeadOverview";
import LeadAssignedDateAnalysis from "./leadAssignedDateAnalysis";
import LeadAssignedStatusOverview from "./leadAssignedStatusOverview";
import SummeryOverview from "./summeryOverview";
import TeamShrinkageAnalysis from "./teamShrinkageAnalysis";
import TopSellableExams from "./topSellableExams";
import LeadAnalysis from "./leadAnalysis";
import CounsellorCallDetails from "./counsellorCallDetails";
import CallerDetails from "./callerDetails";
import CompleteLeadDetail from "./completeLeadDetail";
import EnrolledAnalysis from "./enrolledAnalysis";
import CourseAnalysis from "./courseAnalysis";
import ReferenceAnalysis from "./referenceAnalysis";
import TeamLeadAssignedDate from "./teamLeadAssignedDate";
import TeamLeadAssignedDateAnalysis from "./teamLeadAssignedDateAnalysis";
import ManagerAdmissionDate from "./managerAdmissionDate";
import ManagerExamRevenue from "./managerExamRevenue";
import ManagerTargetStatus from "./managerTargetStatus";
import IncentiveTargetStatus from "../CRMCounsellorPanel/incentiveTargetStatus";
import CouponCode from "../CRMCounsellorPanel/couponCode";
import ActiveCounsellor from "./activeCounsellor";
import ExCounsellor from "./exCounsellor";
import LeadSearchByMobileNumber from "../CRMCounsellorPanel/leadSearchByMobileNumber";
import Attendance from "./attendance";
import MonthlyReport from "./monthlyReport";
import ManagerRemark from "./managerRemark";
import TimeAnalysis from "./timeAnalysis";
import TimeAnalysisOverview from "./timeAnalysisOverview";
import RevenueAnalysis from "./revenueAnalysis";
import CourseSubscriptionMonths from "./courseSubscriptionMonths";
import ResultCompilation from "./resultCompilation";
import RemarkAnalysis from "./remarkAnalysis";
import UserActivityTracker from "./userActivityTracker";
import UserActivityDetails from "./userActivityDetails";

// import WIP from "../wip";
// import SkipDetails from "./skipDetails";

function TeamLeadPanelDashboard(Props: any) {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={CrmTeamLeadPanelSidebarData} pagename />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<ManagerDashbord />} />
              <Route path="managerDashbord" element={<ManagerDashbord />} />
              <Route path="teamLeadsOverview" element={<TeamLeadsOverview />} />
              <Route
                path="counsellorLeadOverview"
                element={<CounsellorLeadOverview />}
              />
              <Route
                path="leadAssignedDateAnalysis"
                element={<LeadAssignedDateAnalysis />}
              />
              <Route
                path="leadAssignedStatusOverview"
                element={<LeadAssignedStatusOverview />}
              />
              <Route path="summeryOverview" element={<SummeryOverview />} />
              <Route path="enrolledAnalysis" element={<EnrolledAnalysis />} />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route
                path="counsellorCallDetails"
                element={<CounsellorCallDetails />}
              />
              <Route path="callerDetails" element={<CallerDetails />} />
              <Route
                path="completeLeadDetail"
                element={<CompleteLeadDetail />}
              />
              <Route
                path="teamShrinkageAnalysis"
                element={<TeamShrinkageAnalysis />}
              />
              <Route path="courseAnalysis" element={<CourseAnalysis />} />
              <Route path="referenceAnalysis" element={<ReferenceAnalysis />} />
              <Route
                path="teamLeadAssignedDate"
                element={<TeamLeadAssignedDate />}
              />
              <Route
                path="teamLeadAssignedDateAnalysis"
                element={<TeamLeadAssignedDateAnalysis />}
              />
              <Route
                path="managerAdmissionDate"
                element={<ManagerAdmissionDate />}
              />
              <Route
                path="managerExamRevenue"
                element={<ManagerExamRevenue />}
              />
              <Route
                path="managerTargetStatus"
                element={<ManagerTargetStatus />}
              />
              <Route
                path="incentiveTargetStatus"
                element={<IncentiveTargetStatus />}
              />
              <Route path="activeCounsellor" element={<ActiveCounsellor />} />
              <Route path="couponCode" element={<CouponCode />} />
              <Route path="exCounsellor" element={<ExCounsellor />} />
              {/* <Route path="skipDetails" element={<SkipDetails/>} />    */}
              <Route
                path="leadSearchByMobileNumber"
                element={<LeadSearchByMobileNumber />}
              />
              <Route path="topSellableExams" element={<TopSellableExams />} />
              <Route path="attendance" element={<Attendance />} />
              <Route path="monthlyReport" element={<MonthlyReport />} />
              <Route path="managerRemark" element={<ManagerRemark />} />
              <Route path="timeAnalysis" element={<TimeAnalysis />} />
              <Route
                path="timeAnalysisOverview"
                element={<TimeAnalysisOverview />}
              />
              <Route path="revenueAnalysis" element={<RevenueAnalysis />} />
              <Route path="remarkAnalysis" element={<RemarkAnalysis />} />
              <Route
                path="CourseSubscriptionMonths"
                element={<CourseSubscriptionMonths />}
              />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route
                path="userActivityTracker"
                element={<UserActivityTracker />}
              />
              <Route
                path="userActivityDetails"
                element={<UserActivityDetails />}
              />

              {/* <Route path="*" element={<WIP/>} />  */}
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default TeamLeadPanelDashboard;
