const regexs = {
    stringOnly: "",
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }
  
  
  // Validation functions start
  export const containsSpecialChars = (str: any) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  };
  export const emailCheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  
  export const containSpaceBetween = (str: any) => {
    const regex = /\s{2,}/;
    return regex.test(str);
  };
  export const containSpace = (str: any) => {
    const regex = /\s{1,}/;
    return regex.test(str);
  };
  export function containsNumber(str: any) {
    return /^[0-9]+$/.test(str);
  }
  export function containsNumberwithDash(str: any) {
    return /^[0-9/-]+$/.test(str);
  }
  export function containsNumberDecimal(str: any) {
    return /^[0-9]\d*(\.[0-9]\d)?/.test(str);
  }
  export function hasWhiteSpace(str: any) {
    return /\s+/gi.test(str);
  }
  
  export function startsWithSpace(str: any) {
    return /^\s/.test(str);
  }
  
  export function startsWithNumber(str: any) {
    return /^\d/.test(str);
  }
  
  export const startsWithSpecialChars = (str: any) => {
    const specialChars = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  };
  
  
  export function containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(str: any) {
    if (
      startsWithSpecialChars(str) ||
      startsWithNumber(str) ||
      startsWithSpace(str)
    ) {
      return true;
    }
    return false;
  }
  
  export function containsSpecialCharsOrNumberOrWhiteSpace(str: any) {
    if (
      containsSpecialChars(str) ||
      containsNumber(str) ||
      startsWithSpace(str)
    ) {
      return true;
    }
    return false;
  }
  
  export function containsSpecialCharsOrStartsWithNumberOrWhiteSpace(str: any) {
    if (
      containsSpecialChars(str) ||
      startsWithNumber(str) ||
      startsWithSpace(str)
    ) {
      return true;
    }
    return false;
  }
  
  export function containsAllowedSpecialCharsOrNumberOrWhiteSpace(str: any) {
    const AllowedSpecialChar = ["&", ":", ","];
    str = str.charAt(str.length - 1);
    if (containsSpecialChars(str)) {
      if (!AllowedSpecialChar.includes(str)) {
        return true;
      }
    }
  }
  export const validateNameWithoutSpace = (str: any) => {
    if (containSpaceBetween(str)) {
      return false;
    }
    return true;
  };
  
  export const validateValueWithoutSpace = (str: any) => {
    if (containSpace(str)) {
      return false;
    }
    return true;
  };
  
  export function validateFullName(str: any) {
    if (
      typeof str !== 'string' ||
      /[0-9]+/g.test(str) ||
      containsSpecialChars(str) ||
      startsWithSpace(str) || containSpaceBetween(str)
    ) {
      return false;
    }
    return true;
  }

  export function validateUniqueId(str: any) {
    if (
      typeof str !== 'string' ||
      /[0-9]+/g.test(str) ||
      containsSpecialChars(str) ||
      startsWithSpace(str)||  hasWhiteSpace(str)
    ) {
      return false;
    }
    return true;
  }
  
  export function validateCouponCodeName(str: any) {
    if (
      startsWithNumber(str) ||
      startsWithSpace(str) ||
      // hasWhiteSpace(str) ||
      containsSpecialChars(str)
    ) {
      return false;
    }
    return true;
  }
  
  export function validateIsFileImageType(e: any) {
    if (e.target.files[0]?.type.substring(0, 5) === "image") {
      return true;
    }
    return false;
  }
  
  export function validateIsFileVideoType(e: any) {
    if (e.target.files[0]?.type.substring(0, 5) === "video") {
      return true;
    }
    return false;
  }
  
  export function validateIsFilePDFType(e: any) {
    if (e.target.files[0]?.type === "application/pdf") {
      return true;
    }
    return false;
  }
  
  export const validateIsLettersAndDotOnly = (value: any) => {
    return /^[a-zA-Z\s.]*$/.test(value);
  };
  export const validateIsLettersAndDigitsOnly = (value: any) => {
    return /^[A-Za-z0-9]*$/.test(value);
  };
  export const validateIsLettersAndDigitsSpaceOnly = (value: any) => {
    return /^[A-Za-z0-9\s]*$/.test(value);
  };
  export const validateIsLettersAndSpaceOnly = (value: any) => {
    return /^[A-Za-z\s]*$/.test(value);
  };
  export function containsNumbeAndDecimal(str: any) {
    return /^[0-9\s.{1,2}]+$/.test(str);
  }
  export function validateisValidPrice(value: any) {
    // return ^\d{0,8}(\.\d{1,4})?$.test(price)
    return /^\d{0,5}(\.\d{1,2})?$/.test(value);
    return !/^\s*$/.test(value) && !isNaN(value);
    // return value.match(/^[+-]?((\.\d+)|(\d+(\.\d+)?))$/)
  }
  
  export const validateIsUrlPattern = (urlString: any) => {
    return urlString.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
  };
  
  export const isValidDiscount = (value: any) => {
    let r = /^[0-9]\d*(\.[0-9]\d)?/;
    // let r = /(\d+(?:\.\d+)?)/;
    let isValidate = false;
    isValidate = r.test(value) || value === "";
  
    return isValidate;
  };
  
  export const validateDecimal = (str: any) => {
    // check if its in range from 0 to 100
    return str >= 0 && str <= 100;
  };
  
  export const validateDecimalNumber = (str: any) => {
    // check if its in range from 0 to 100
    return str >= 0 && str <= 500;
  };
  
  export const validateLoginId = (str: any) => {
    if (
      startsWithNumber(str) ||
      startsWithSpace(str) ||
      startsWithSpecialChars(str)
      // || hasWhiteSpace(str)
    ) {
      return false;
    }
    return true;
  };
  
  export const validateMeetingId = (str: any) => {
    if (
      // startsWithNumber(str) ||
      startsWithSpace(str) ||
      startsWithSpecialChars(str)
      // || hasWhiteSpace(str)
    ) {
      return false;
    }
    return true;
  };
  
  export const validatePassword = (str: any) => {
    if (startsWithSpace(str)) {
      return false;
    }
    return true;
  };
  
  export const validateEmailAddress = (str: any) => {
    return str.match(regexs.email);
  };
  
  export const validateIsNumberOnly = (value: any) => {
    return /\D/.test(value);
  };
  // export const checkValidName = (name: any) => {
  //   let regName = /^[a-zA-Z]+$/;
  //   let regFullName = /^[a-zA-Z]+ [a-zA-Z]+$/;
  //   if (regName.test(name) || regFullName.test(name)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // export const validateEmptyString = (value: any) => {
  //   return /^\s*$/.test(value);
  // };
  
  export const validateExpertDetails = (value: any) => {
    return /^[a-zA-Z\s.,?!]*$/.test(value);
  };
  
  export const validatePackageName = (value: any) => {
    return /^[a-zA-Z\s-:]*$/.test(value);
  };
  
  export const validateSubject = (value: any) => {
    return /^[A-Za-z0-9\s/&:-]*$/.test(value);
  };
  export const validateMinMaxMarks = (value: any) => {
    return /^[0-9\s/+-.]*$/.test(value);
  };
  export const validateTopic = (value: any) => {
    return /^[A-Za-z0-9\s]*$/.test(value);
  };
  export const validateDigitOnly = (value: any) => {
    return /^[0-9]*$/.test(value);
  };
  // Validation functions ends
  export const length_One = 1;
  export const length_Two = 2;
  export const length_Three = 4;
  export const length_Four = 5;
  export const length_Five = 6;
  export const length_Six = 7;
  export const length_Seven = 8;
  export const length_Eight = 9;
  export const length_Ten = 10;
  export const length_Fifteen = 15;
  export const length_Twenty = 20;
  export const length_Thirty = 31;
  export const length_Fifty = 51;
  export const length_Hundred = 101;
  export const length_Hundred_TwentyFive = 126;
  export const length_Thousand = 1000;
  
  
  
  export const length_OneHundredFifty = 151;
  export const length_TwoHundred = 200;
  export const length_ThreeHundred = 300;
  export const length_FiveHundred = 500;
  export const length_OneThousandFiveHundred = 1500;
  export const length_FiveThousand = 5000;
  
  // Image size in kb
  export const image_size_200 = 204800;
  export const image_size_150 = 153600;
  export const image_size_5MB = 5120000;
  export const image_size_1MB = 1048576;
  export const image_size_2MB = 2097152
  export const pdf_size_1MB = 10240000;
  export const pdf_size_50MB = 52428800;
  export const pdf_size_100MB = 104857600;
  export const book_price = 10001;
  export const video_size_25MB = 25600000;
  