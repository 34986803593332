import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SnackbarReducerModal } from '../Redux/Modals';

function ErrorSnackbar() {
    const dispatch = useAppDispatch();
    const {  errorSnackbarOpen,  message } = useAppSelector(
        ( state: {UI:SnackbarReducerModal}) => ({
            successSnackbarOpen: state.UI.successSnackbarOpen,
            errorSnackbarOpen: state.UI.errorSnackbarOpen,
            infoSnackbarOpen: state.UI.infoSnackbarOpen,
            message: state.UI.message,
        })
    );
    function handleClose() {
        dispatch({ type: "SNACKBAR_CLEAR" });
    }
    return (

        <Snackbar open={errorSnackbarOpen} autoHideDuration={100000} onClose={handleClose}>
            <MuiAlert elevation={6}  variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
}

export default ErrorSnackbar;