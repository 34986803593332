import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Interface } from "readline";

interface props {
  lableName: string;
  style?: any;
}

export default function LabelField({ lableName, style }: props) {
  return (
    <label style={style} className="label me-2">
      {lableName}
    </label>
  );
}
