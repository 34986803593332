import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "react-circular-progressbar/dist/styles.css";
import LabelField from "./labelField";
import InputField from "./inputField";
import CustomDropdown from "./customDropdown";
import SubmitButton from "./submitButton";
import { getCity, getState } from "../Redux/Actions/bdeAdminPanelAction";
import {
  containsNumber,
  length_Fifteen,
  length_Four,
  length_One,
  length_OneHundredFifty,
  length_Ten,
  length_Thirty,
  length_Three,
  length_ThreeHundred,
  length_Two,
  length_TwoHundred,
  startsWithSpace,
  validateFullName,
  validateIsLettersAndDotOnly,
  validateValueWithoutSpace,
} from "../DATA/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateIsAlphabetErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../DATA/errorMsg";
import { updateCollege } from "../Redux/Actions/bdExecutivePanelAction";
import { useSelector } from "react-redux";
interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
}

export default function EditCollege(props: props) {
  const dispatch = useDispatch();
  const { open, handleClose, data } = props;
  const { CollegeName, CollegeContactNo, CollegeEmail } = useSelector(
    (state: any) => ({
      CollegeName: state.Auth.CollegeName,
      CollegeContactNo: state.Auth.CollegeContactNo,
      CollegeEmail: state.Auth.CollegeEmail,
    })
  );
  const [college, setCollege] = useState<any>(CollegeName);
  const [state, setState] = useState<any>("");
  const [department, setDepartment] = useState<any>();
  const [stateList, setStateList] = useState([]);
  const [ResetState, setResetState] = useState(false);
  const [district, setDistrict] = useState<any>("");
  const [districtList, setDistrictList] = useState([]);
  const [ResetDistrict, setResetDistrict] = useState(false);
  const [email, setEmail] = useState<any>(CollegeEmail);
  const [phone, setPhone] = useState<any>(CollegeContactNo);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  useEffect(() => {
    dispatch(
      getState(
        "",
        (res: any) => {
          // const allIndia = {
          //   name: "ALL INDIA",
          //   _id: "0",
          // };
          // res.unshift(allIndia);
          setStateList(res);
          setState(props?.data?.state?._id);
        },
        () => {}
      )
    );
    setDepartment(data?.department);
  }, [data]);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCity(
        val._id,
        (res: any) => {
          setDistrictList(res);
          setDistrict(props?.data?.district?._id);
        },
        () => {}
      )
    );
  };
  const onchangeCollegeName = (val: any) => {
    if (val !== "") {
      if (!validateIsLettersAndDotOnly(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (startsWithSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setCollege(val?.toUpperCase());
      }
    } else {
      setCollege(val?.toUpperCase());
    }
  };

  const onChangeDistrict = (val: any) => {
    setDistrict(val);
  };
  const onChangeDepartment = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setDepartment(val);
      }
    } else {
      setDepartment(val);
    }
  };

  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPhone(val);
      }
    } else {
      setPhone(val);
    }
  };
  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onchangeEmail = (val: string) => {
    setEmail(val);
  };
  const handeSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      collegeName: college,
      stateId: state?._id,
      districtId: district?._id,
      department: department,
      contactNo: phone,
      email: email,
    };
    dispatch(
      updateCollege(
        data?._id,
        postData,
        (res: any) => {
          setCollege("");
          setResetState(true);
          setResetDistrict(true);
          setDepartment("");
          setPhone("");
          setEmail("");
        },
        () => {}
      )
    );
    handleClose(true);
  };

  const validation = () => {
    return (
      college === "" ||
      college?.length < length_Two ||
      state === "" ||
      email === "" ||
      email?.length < length_Three ||
      !email.match(emailsCheck) ||
      district === "" ||
      phone === "" ||
      phone?.length < length_Ten ||
      department === "" ||
      department?.length < length_One
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      onClose={closePopup}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldivChat text-center p-md-4 py-md-4 ">
          <form onSubmit={handeSubmit}>
            <div className=" d-flex justify-content-between">
              <div>
                <h3 className="text-primary fw-bold">Edit College Details</h3>{" "}
              </div>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="College Name" />
                </div>
                <div className="col-md-9">
                  <InputField
                    placeholder="Enter College Name"
                    value={college}
                    onChangeInput={(value: any) => onchangeCollegeName(value)}
                    maxlength={length_ThreeHundred}
                    minlength={length_Two}
                  />
                </div>
              </div>

              <div className=" d-flex  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="State" />
                </div>
                <div className="col-md-9 ps-3">
                  <CustomDropdown
                    lableName="Select State"
                    setInputText={(value: any) => onChangeState(value)}
                    value={state}
                    options={stateList}
                    Isrequired={true}
                    reset={ResetState}
                    defaultValue={stateList?.length > 0 ? state : ""}
                  />
                </div>
              </div>
              <div className=" d-flex  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="District" />
                </div>
                <div className="col-md-9 ps-3">
                  <CustomDropdown
                    lableName="Select District"
                    setInputText={(value: any) => onChangeDistrict(value)}
                    value={district}
                    options={districtList}
                    Isrequired={true}
                    reset={ResetDistrict}
                    defaultValue={districtList?.length > 0 ? district : ""}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Department" />
                </div>
                <div className="col-md-9">
                  <InputField
                    placeholder="Enter Department"
                    value={department}
                    onChangeInput={(value: any) => onChangeDepartment(value)}
                    maxlength={length_Thirty}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Phone No" />
                </div>
                <div className="col-md-9">
                  <InputField
                    placeholder="Enter Phone no"
                    value={phone}
                    onChangeInput={(value: any) => onChangeContact(value)}
                    maxlength={length_Fifteen}
                    minlength={length_Ten}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Email" />
                </div>
                <div className="col-md-9">
                  <InputField
                    placeholder="Enter College Email"
                    value={email}
                    onChangeInput={(value: any) => onchangeEmail(value)}
                    maxlength={length_TwoHundred}
                    minlength={length_Four}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-center mb-3">
                <SubmitButton name={"Submit"} validation={validation()} />
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
