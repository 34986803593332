import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "react-circular-progressbar/dist/styles.css";
import LabelField from "./labelField";
import InputField from "./inputField";
import SubmitButton from "./submitButton";
import DateTime from "./dateTime";
import {
  updateWebinar,
  getTeachersList,
  getWebinarListByDeptID,
} from "../Redux/Actions/bdExecutivePanelAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  containsNumber,
  length_Fifteen,
  length_Fifty,
  length_Ten,
  length_Thirty,
  length_Three,
  length_Two,
  length_TwoHundred,
  validateFullName,
  validateValueWithoutSpace,
} from "../DATA/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../DATA/errorMsg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CustomDropdown from "./customDropdown";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  departmentID?: any;
}

export default function EditWebinarSchedule(props: props) {
  const { open, handleClose, data, departmentID } = props;
  const dispatch = useDispatch();
  const [meetingURL, setMeetingURL] = useState<any>("");
  const [coordinatorname, setCoordinatorName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [registrationURL, setRegistrationURL] = useState<any>("");
  const [teachername, setTeacherName] = useState<any>("");
  const [teachernameList, setTeachernameList] = useState([]);
  const [ResetTeachername, setResetTeachername] = useState(false);
  const [copied, setcopied] = useState(false);
  const [webinarList, setWebinarList] = useState([]);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
    setMeetingURL("");
    setCoordinatorName("");
    setDate("");
    setEmail("");
    setPhone("");

    setTeacherName("");
  }

  useEffect(() => {
    dispatch(
      getTeachersList(
        {},
        (res: any) => {
          setTeachernameList(res?.data);
        },
        () => {}
      )
    );
    setMeetingURL(data?.meetingURL);
    setCoordinatorName(data?.coordinatorName);
    setDate(data?.webinarAt);
    setEmail(data?.coordinatorEmail);
    setPhone(data?.coordinatorPhone);
    setRegistrationURL(data?.registerURL);
    setTeacherName(data?.teacher_data?._id);
  }, [data]);

  const onchangeDate = (val: any) => {
    setDate(val);
    dispatch(
      getTeachersList(
        {},
        (res: any) => {
          setTeachernameList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeMeetingURL = (val: any) => {
    setMeetingURL(val);
  };

  const onChangeTeacherName = (val: any) => {
    setTeacherName(val);
  };
  const onChangeCoordinatorName = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else {
        setCoordinatorName(val);
      }
    } else {
      setCoordinatorName(val);
    }
  };
  const onChangePhone = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPhone(val);
      }
    } else {
      setPhone(val);
    }
  };

  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onChangeEmail = (val: any) => {
    setEmail(val);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      coordinatorName: coordinatorname,
      coordinatorPhone: phone,
      coordinatorEmail: email,
      teacherId: teachername?._id,
      meetingURL: meetingURL,
      webinarAt: date,
    };
    dispatch(
      updateWebinar(
        data?._id,
        postData,
        (res: any) => {
          setRegistrationURL(res?.registerURL);
          dispatch(
            getWebinarListByDeptID(
              departmentID,
              (res: any) => {
                setWebinarList(res?.data);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const validation = () => {
    return (
      date === "" ||
      meetingURL === "" ||
      registrationURL === "" ||
      teachername === "" ||
      teachername === "" ||
      coordinatorname === "" ||
      coordinatorname?.length < length_Two ||
      phone === "" ||
      phone?.length < length_Ten ||
      email === "" ||
      email?.length < length_Three
      //  || !email.match(emailsCheck)
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldivChat text-center p-md-4 py-md-4 ">
          <form onSubmit={handleSubmit}>
            <div className=" d-flex justify-content-between">
              <div>
                <h3 className="text-primary fw-bold">Edit Webinar Details</h3>{" "}
              </div>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="mt-4">
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName=" Date" />
                </div>
                <div className="col-md-8 ps-3">
                  <DateTime
                    value={date}
                    // setInputText={(value: any) => setDate(value)}
                    setInputText={(value: any) => onchangeDate(value)}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Meeting Url :" />
                </div>
                <div className="col-md-8 ps-">
                  <InputField
                    placeholder="Enter Meeting Url"
                    value={meetingURL}
                    onChangeInput={(value: any) => onChangeMeetingURL(value)}
                  />
                </div>
              </div>
              {/* <div className="row d-flex justify-content-between  ">
                                <div className="col-md-4 mt-2">
                                    <LabelField lableName="Teacher Name :" />
                                </div>
                                <div className="col-md-8">
                                    <InputField
                                        placeholder="Enter Teacher Name "
                                        value={teachername}
                                        onChangeInput={(value: any) => onChangeTeacherName(value)}
                                        minlength={length_Two}
                                        maxlength={length_Twenty}
                                    />
                                </div>
                            </div> */}
              <div className=" d-flex  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Teacher Name" />
                </div>
                <div className="col-md-8 ps-3 pt-1 ">
                  <CustomDropdown
                    lableName="Select Teacher Name"
                    setInputText={(value: any) => onChangeTeacherName(value)}
                    value={teachername}
                    options={teachernameList}
                    defaultValue={
                      teachernameList.length > 0 ? teachername : null
                    }
                    reset={ResetTeachername}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Coordinator Name :" />
                </div>
                <div className="col-md-8">
                  <InputField
                    placeholder="Enter Coordinator Name "
                    value={coordinatorname}
                    onChangeInput={(value: any) =>
                      onChangeCoordinatorName(value)
                    }
                    minlength={length_Two}
                    maxlength={length_Fifty}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Coordinator Phone :" />
                </div>
                <div className="col-md-8">
                  <InputField
                    placeholder="Enter Phone no"
                    value={phone}
                    onChangeInput={(value: any) => onChangePhone(value)}
                    minlength={length_Ten}
                    maxlength={length_Fifteen}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between  ">
                <div className="col-md-4 mt-2">
                  <LabelField lableName="Coordinator Email :" />
                </div>
                <div className="col-md-8">
                  <InputField
                    placeholder="Enter Coordinator Email"
                    value={email}
                    onChangeInput={(value: any) => onChangeEmail(value)}
                    minlength={length_Two}
                    maxlength={length_Thirty}
                  />
                </div>
              </div>

              <div className=" d-flex justify-content-center mb-3">
                <SubmitButton name={"Update"} validation={validation()} />
              </div>
            </div>
          </form>
          <div className="row d-flex justify-content-between  ">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Registration Url :" />
            </div>
            <div className="col-md-8 position-relative">
              <InputField
                placeholder="registrationURL"
                value={registrationURL}
                onChangeInput={(value: any) => setRegistrationURL(value)}
                disabled
              />

              <CopyToClipboard
                text={registrationURL}
                onCopy={() => setcopied(true)}
              >
                <button className="btn btn-font copybtn">
                  {" "}
                  <ContentCopyOutlinedIcon />
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
