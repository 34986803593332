import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";

import moment from "moment";
export default function ExCounsellor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [manager, setManager] = useState();
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  return (
    <div className="contentBox">
      <PageTitle name={"Ex Counsellor"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5"></div>
      </div>
    </div>
  );
}
