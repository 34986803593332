import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from '../../Component/pageTitle';
import PieChart from '../../Component/pieChart';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { getSellableExamRevenueList } from '../../Redux/Actions/managerPanelAction';
import { useSelector } from 'react-redux';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TopSellableExams() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [piedata, setPiedata] = useState<any>([]);
  const [sellableExamRevenueList, setSellableExamRevenueList] = useState([])
  const [pieColordata, setPieColordata] = useState<any>([]);


  const startDate = location.state.sDate;
  const endDate = location.state.eDate;
  const examStreamId = location.state.examStreamId;
  const managerId = location.state.managerId
  const examId = sessionStorage.getItem('examId');

  const { loggedInUserRole, crmEmployeeId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    crmEmployeeId: state.Auth.counsellorId,
  }));

  let color: any = [];

  useEffect(() => {
    if (loggedInUserRole === 1) {
      const data = {
        startDate,
        endDate,
        examTypeId: examId,
        managerId: managerId,
        baseCourseId: examStreamId,
      };
      dispatch(
        getSellableExamRevenueList(
          data,
          (res: any) => {
            setSellableExamRevenueList(res?.data)
            piechartData(res?.data)
          },
          () => { }
        )
      );
    }
    else if (loggedInUserRole === 2) {
      const data = {
        startDate,
        endDate,
        examTypeId: examId,
        baseCourseId: examStreamId,
      };
      dispatch(
        getSellableExamRevenueList(
          data,
          (res: any) => {
            setSellableExamRevenueList(res?.data)
            piechartData(res?.data)
          },
          () => { }
        )
      );
    }


  }, []);



  const piechartData = (list: any) => {
    let arr: any = [];
    let color: any = []
    let name;
    let revenue;
    list?.map((item: any) => {
      name = item?.categoryName;
      revenue = item?.revenue;
      arr?.push(name);
      arr?.push(revenue);
      setPiedata(arr);
      color.push("#F9F9F9")
      if (item?.categoryName === "LIVE COURSE") {
        color.push("#F23E36")
      }
      else if (item?.categoryName === "RECORED COURSE") {
        color.push("#F38F0C")
      }
      else if (item?.categoryName === "PREMIER COURSE") {
        color.push("#40E0D0")
      }
      else if (item?.categoryName === "CRASH COURSE") {
        color.push("#DFFF00")
      }
      else if (item?.categoryName === "TEST SERIES") {
        color.push("#158833")
      }
      else if (item?.categoryName === "LECTURE SERIES") {
        color.push("#d63384")
      }
      else if (item?.categoryName === "WITHOUT STUDY MATERIAL" || item?.categoryName === "STUDY MATERIAL") {
        color.push("#1F77B4")
      }
      setPieColordata(color)
    });

  };

  const TopSellExam = {
    // labels: 
    datasets: [
      {
        // label: ["Live","Recorded","Premier","Crash","Test Series","Lecture Series","Study Material"],
        data: piedata,
        backgroundColor: pieColordata,

      },
    ],

  };

  return (
    <div className="contentBox">
      <PageTitle name={""} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth pt-2 p-5">
          <h4 className='fw-bold'>Top Sellable Exam</h4>
          <div className='d-flex'>
            <div className="col-md-7 d-flex ">

              <div className='col-md-7 me-5'>
                <PieChart data={TopSellExam} />
              </div>

              <div className="col-md-4  ">
                {sellableExamRevenueList?.map((item: any) => {
                  return (
                    <>
                      {item?.categoryName === "LIVE COURSE" ? <div className='  d-flex  '>
                        <div className='unachievedbg px-4 my-3 me-3'></div>
                        <p className='mt-3 fw-bold '>Live ({item?.revenue})</p>
                      </div> : null}
                      {item?.categoryName === "RECORED COURSE" ? <div className='  d-flex  '>
                        <div className='Recordedbg px-4 my-3 me-3'></div>
                        <p className='mt-3 fw-bold '>Recorded ({item?.revenue})</p>
                      </div> : null}
                      {item?.categoryName === "PREMIER COURSE" ? <div className='  d-flex  '>
                        <div className='premierbg px-4 my-3 me-3'></div>
                        <p className='mt-3 fw-bold '>Premier ({item?.revenue})</p>
                      </div> : null}
                      {item?.categoryName === "CRASH COURSE" ? <div className='  d-flex  '>
                        <div className='crashbg px-4 my-3 me-3'></div>
                        <p className='mt-3 fw-bold '>Crash ({item?.revenue})</p>
                      </div> : null}
                      {item?.categoryName === "TEST SERIES" ? <div className='  d-flex  '>
                        <div className='textbg px-4 my-3 me-3'></div>
                        <p className='mt-3 fw-bold '>Test Series ({item?.revenue})</p>
                      </div> : null}
                      {item?.categoryName === "LECTURE SERIES" ? <div className='  d-flex  '>
                        <div className='lecturebg px-4 my-3 me-3'></div>
                        <p className='mt-3 fw-bold '>Lecture Series ({item?.revenue})</p>
                      </div> : null}
                      {item?.categoryName === "WITHOUT STUDY MATERIAL" || item?.categoryName === "STUDY MATERIAL" ? <div className='  d-flex  '>
                        <div className='StudyMaterialbg px-4 my-3 me-3'></div>
                        <p className='mt-3 fw-bold '>Study material ({item?.revenue})</p>
                      </div> : null}
                    </>
                  )
                })}
              </div>

            </div>

          </div>


        </div>
      </div>
    </div>
  );
}

