export const BDManagerSideBar = [
 
  {
    title: "DASHBOARD",
    path: "bdTLDashboard",
  },
  {
    title: "COLLEGE LIST",
    path: "bdCollegeList",
  },
  {
    title: "WEBINAR",
    subNav: [
      {
        title: "Upcoming",
        path: "upcomingWebinarList",
        cName: "sub-nav",
      },
      {
        title: "Completed",
        path: "completeWebinarList",
        cName: "sub-nav",
      },
      {
        title: "Cancelled",
        path: "cancelledWebinarList",
        cName: "sub-nav",
      },
        {
        title: "Pending",
        path: "pendingWebinarList",
        cName: "sub-nav",
      },
      
    ],
  },

  {
    title: "WEBINAR ANALYSIS",
    subNav: [
      {
        title: "Not Interested",
        path: "notIntrestedList",
        cName: "sub-nav",
      },
      {
        title: "Interested Today",
        path: "intrestedList",
        cName: "sub-nav",
      },
      {
        title: "Call Back Today",
        path: "callBackTodayList",
        cName: "sub-nav",
      },
        {
        title: "Overdue Interested",
        path: "overdueIntrestedList",
        cName: "sub-nav",
      },
      {
        title: "Overdue Call Back",
        path: "overdueCallBack",
        cName: "sub-nav",
      },
      
    ],
  },
  {
    title: "COLLEGE DEPARTMENT ADDED",
    path: "collegeDepartmentAdded",
  },
  {
    title: "MANAGE TEAM",
    path: "manageTeam",
  },
  {
    title: "TARGET DASHBOARD",
    path: "targetStatusDashboard",
  },
  {
    title: "MANAGER REMARK",
    path: "managerRemark",
  },
  {
    title: "LEAD ANALYSIS",
    subNav: [
      {
        title: "Lead Analysis",
        path: "leadAnalysisTL",
        cName: "sub-nav",
      },
      {
        title: "Lead Department Wise Analysis",
        path: "departmentwiseleadAnalysisTL",
        cName: "sub-nav",
      },
    
      
    ],
  },
  {
    title: "ANALYSIS",
    subNav: [
      {
        title: "Time Analysis",
        path: "timeAnalysis",
        cName: "sub-nav",
      },
      {
        title: "Lead OverView Analysis",
        path: "leadOverViewDetails",
        cName: "sub-nav",
      },
      {
        title: "Webinar OverView Analysis",
        path: "WebinarOverViewDetails",
        cName: "sub-nav",
      },
      {
        title: "Department OverView Analysis",
        path: "departmentOverViewDetails",
        cName: "sub-nav",
      },
      
    ],
  },
  // {
  //   title: "RESULT COMPILATION",
  //   path: "resultCompilation",
  // },
];
