import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import moment from "moment";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import { getState, getCity } from "../../Redux/Actions/bdeAdminPanelAction";
import { getDepartmentLeadAnalysis } from "../../Redux/Actions/bdExecutivePanelAction";
export default function DepartmentwiseleadAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [state, setState] = useState<any>("");
  const [stateList, setStateList] = useState([]);
  const [ResetState, setResetState] = useState(false);
  const [district, setDistrict] = useState<any>("");
  const [districtList, setDistrictList] = useState([]);
  const [ResetDistrict, setResetDistrict] = useState(false);
  const [leadadDepartment, setLeadadDepartment] = useState<any>([]);

  useEffect(() => {
    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);
        },
        () => {}
      )
    );
    const postData = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getDepartmentLeadAnalysis(
        postData,
        (res: any) => {
          setLeadadDepartment(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCity(
        val._id,
        (res: any) => {
          setDistrictList(res);
        },
        () => {}
      )
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      state: state?._id,
      district: district?._id,
    };
    dispatch(
      getDepartmentLeadAnalysis(
        postData,
        (res: any) => {
          setLeadadDepartment(res);
        },
        () => {}
      )
    );
  };
  const onChangeDistrict = (val: any) => {
    setDistrict(val);
  };

  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        <div className="col-md-4 row d-flex my-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              Isrequired={true}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3"></div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="State" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select State"
              setInputText={(value: any) => onChangeState(value)}
              value={state}
              options={stateList}
              // Isrequired={true}
              reset={ResetState}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="District" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select District"
              setInputText={(value: any) => onChangeDistrict(value)}
              value={district}
              options={districtList}
              // Isrequired={true}
              reset={ResetDistrict}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-4">
            <button className="btn btn-primary " type="submit">
              {" "}
              Search
            </button>
          </div>
        </div>
      </form>
    );
  };
  // const LedadDepartment = {
  //   data: {
  //     leadCount: 30,
  //     ExamAndBaseCourseList: [
  //       {
  //         examTypeName: "CSIR NET",
  //         baseCourseNames: [
  //           {
  //             baseCourse: "PHYSICAL SCIENCE",
  //             HoriHeadcount: 28,
  //           },
  //           {
  //             baseCourse: "LIFE SCIENCE",
  //             HoriHeadcount: 29,
  //           },
  //           {
  //             baseCourse: "MATHEMATICAL SCIENCE",
  //             HoriHeadcount: 30,
  //           },
  //           {
  //             baseCourse: "CHEMICAL SCIENCE",
  //             HoriHeadcount: 31,
  //           },
  //         ],
  //       },
  //       {
  //         examTypeName: "IIT JAM",
  //         baseCourseNames: [
  //           {
  //             baseCourse: "BIOTECHNOLOGY (BT)",
  //             HoriHeadcount: 32,
  //           },
  //           {
  //             baseCourse: "GEOLOGY (GG)",
  //             HoriHeadcount: 33,
  //           },
  //           {
  //             baseCourse: "CHEMISTRY (CY)",
  //             HoriHeadcount: 34,
  //           },
  //         ],
  //       },
  //     ],
  //     examData: [
  //       {
  //         date: "2024-02-20",
  //         VertiHeadcount: 25,
  //         examCounts: [
  //           {
  //             examTypeName: "CSIR NET",
  //             basecourse: [
  //               {
  //                 basecourseName: "PHYSICAL SCIENCE",
  //                 count: 10,
  //               },
  //               {
  //                 basecourseName: "LIFE SCIENCE",
  //                 count: 20,
  //               },
  //               {
  //                 basecourseName: "MATHEMATICAL SCIENCE",
  //                 count: 30,
  //               },
  //               {
  //                 basecourseName: "CHEMICAL SCIENCE",
  //                 count: 40,
  //               },
  //             ],
  //           },
  //           {
  //             examTypeName: "IIT JAM",
  //             basecourse: [
  //               {
  //                 basecourseName: "BIOTECHNOLOGY (BT)",
  //                 count: 15,
  //               },
  //               {
  //                 basecourseName: "GEOLOGY (GG)",
  //                 count: 30,
  //               },
  //               {
  //                 basecourseName: "CHEMISTRY (CY)",
  //                 count: 45,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         date: "2024-02-21",
  //         VertiHeadcount: 26,
  //         examCounts: [
  //           {
  //             examTypeName: "CSIR NET",
  //             basecourse: [
  //               {
  //                 basecourseName: "PHYSICAL SCIENCE",
  //                 count: 2,
  //               },
  //               {
  //                 basecourseName: "LIFE SCIENCE",
  //                 count: 4,
  //               },
  //               {
  //                 basecourseName: "MATHEMATICAL SCIENCE",
  //                 count: 6,
  //               },
  //               {
  //                 basecourseName: "CHEMICAL SCIENCE",
  //                 count: 8,
  //               },
  //             ],
  //           },
  //           {
  //             examTypeName: "IIT JAM",
  //             basecourse: [
  //               {
  //                 basecourseName: "BIOTECHNOLOGY (BT)",
  //                 count: 1,
  //               },
  //               {
  //                 basecourseName: "GEOLOGY (GG)",
  //                 count: 1,
  //               },
  //               {
  //                 basecourseName: "CHEMISTRY (CY)",
  //                 count: 1,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         date: "2024-02-22",
  //         VertiHeadcount: 27,
  //         examCounts: [
  //           {
  //             examTypeName: "CSIR NET",
  //             basecourse: [
  //               {
  //                 basecourseName: "PHYSICAL SCIENCE",
  //                 count: 12,
  //               },
  //               {
  //                 basecourseName: "LIFE SCIENCE",
  //                 count: 13,
  //               },
  //               {
  //                 basecourseName: "MATHEMATICAL SCIENCE",
  //                 count: 14,
  //               },
  //               {
  //                 basecourseName: "CHEMICAL SCIENCE",
  //                 count: 15,
  //               },
  //             ],
  //           },
  //           {
  //             examTypeName: "IIT JAM",
  //             basecourse: [
  //               {
  //                 basecourseName: "BIOTECHNOLOGY (BT)",
  //                 count: 5,
  //               },
  //               {
  //                 basecourseName: "GEOLOGY (GG)",
  //                 count: 10,
  //               },
  //               {
  //                 basecourseName: "CHEMISTRY (CY)",
  //                 count: 15,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // };

  const renderListMode = () => {
    return (
      <>
        <div className="w-100 counsellortable table-responsive">
          <>
            <table className=" w-100 text-center table-bordered border-secondary ">
              <>
                <>
                  <tr>
                    <th rowSpan={4}>Date</th>
                    <th>Total</th>

                    {leadadDepartment?.ExamAndBaseCourseList?.map(
                      (item: any) => {
                        return (
                          <>
                            <th colSpan={item?.baseCourseNames?.length}>
                              {item?.examTypeName}
                            </th>
                          </>
                        );
                      }
                    )}
                  </tr>

                  <tr>
                    <th>Leads</th>
                    {leadadDepartment?.ExamAndBaseCourseList?.map(
                      (val1: any) => {
                        return (
                          <>
                            {val1?.baseCourseNames?.map((val2: any) => {
                              return (
                                <>
                                  {/* *******CSIR NET**************** */}
                                  {val1?.examTypeName === "CSIR NET" &&
                                  val2?.baseCourse === "CHEMICAL SCIENCE" ? (
                                    <th>{"CHEMICAL SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CSIR NET" &&
                                  val2?.baseCourse === "CSIR APTITUDE" ? (
                                    <th>{"CSIR APTITUDE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CSIR NET" &&
                                  val2?.baseCourse === "LIFE SCIENCE" ? (
                                    <th>{"LIFE SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CSIR NET" &&
                                  val2?.baseCourse ===
                                    "MATHEMATICAL SCIENCE" ? (
                                    <th>{"MATHEMATICAL SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CSIR NET" &&
                                  val2?.baseCourse === "PHYSICAL SCIENCE" ? (
                                    <th>{"PHYSICAL SCIENCE"}</th>
                                  ) : null}
                                  {/* *******UGC NET**************** */}
                                  {val1?.examTypeName === "UGC NET" &&
                                  val2?.baseCourse === "COMMERCE" ? (
                                    <th>{"COMMERCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "UGC NET" &&
                                  val2?.baseCourse === "COMPUTER SCIENCE" ? (
                                    <th>{"COMPUTER SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "UGC NET" &&
                                  val2?.baseCourse === "ECONOMICS" ? (
                                    <th>{"ECONOMICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "UGC NET" &&
                                  val2?.baseCourse === "ENGLISH" ? (
                                    <th>{"ENGLISH"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "UGC NET" &&
                                  val2?.baseCourse === "GEOGRAPHY" ? (
                                    <th>{"GEOGRAPHY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "UGC NET" &&
                                  val2?.baseCourse === "MANAGEMENT" ? (
                                    <th>{"MANAGEMENT"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "UGC NET" &&
                                  val2?.baseCourse === "UGC NET PAPER 1" ? (
                                    <th>{"UGC NET PAPER 1"}</th>
                                  ) : null}
                                  {/* *******IIT JAM**************** */}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse === "BIOTECHNOLOGY (BT)" ? (
                                    <th>{"BIOTECHNOLOGY (BT)"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse === "CHEMISTRY (CY)" ? (
                                    <th>{"CHEMISTRY (CY)"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse === "ECONOMICS" ? (
                                    <th>{"ECONOMICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse === "ECONOMICS (EN)" ? (
                                    <th>{"ECONOMICS (EN)"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse === "GEOLOGY (GG)" ? (
                                    <th>{"GEOLOGY (GG)"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse ===
                                    "MATHEMATICAL STATISTICS (MS)" ? (
                                    <th>{"MATHEMATICAL STATISTICS (MS)"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse === "MATHEMATICS (MA)" ? (
                                    <th>{"MATHEMATICS (MA)"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "IIT JAM" &&
                                  val2?.baseCourse === "PHYSICS (PH)" ? (
                                    <th>{"PHYSICS (PH)"}</th>
                                  ) : null}
                                  {/* *******DBT**************** */}
                                  {val1?.examTypeName === "DBT" &&
                                  val2?.baseCourse === "BET (DBT)" ? (
                                    <th>{"PHYSICS (PH)"}</th>
                                  ) : null}
                                  {/* *******NBHM**************** */}
                                  {val1?.examTypeName === "NBHM" &&
                                  val2?.baseCourse ===
                                    "MATHS (MASTER'S + DOCTORAL)" ? (
                                    <th>{"MATHS (MASTER'S + DOCTORAL)"}</th>
                                  ) : null}
                                  {/* *******"GATE"**************** */}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse === "BIOTECHNOLOGY" ? (
                                    <th>{"BIOTECHNOLOGY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse === "CHEMISTRY" ? (
                                    <th>{"CHEMISTRY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse ===
                                    "COMPUTER SCIENCE & INFORMATION TECHNOLOGY" ? (
                                    <th>
                                      {
                                        "COMPUTER SCIENCE & INFORMATION TECHNOLOGY"
                                      }
                                    </th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse ===
                                    "ECOLOGY AND EVOLUTION" ? (
                                    <th>{"ECOLOGY AND EVOLUTION"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse === "GATE APTITUDE" ? (
                                    <th>{"GATE APTITUDE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse ===
                                    "HUMANITIES & SOCIAL SCIENCES - ECONOMICS (XH-C1)" ? (
                                    <th>
                                      {
                                        "HUMANITIES & SOCIAL SCIENCES - ECONOMICS (XH-C1)"
                                      }
                                    </th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse ===
                                    "HUMANITIES & SOCIAL SCIENCES - ENGLISH (XH-C2)" ? (
                                    <th>
                                      {
                                        "HUMANITIES & SOCIAL SCIENCES - ENGLISH (XH-C2)"
                                      }
                                    </th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse === "LIFE SCIENCE" ? (
                                    <th>{"LIFE SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse ===
                                    "MATHEMATICAL SCIENCE" ? (
                                    <th>{"MATHEMATICAL SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse === "PHYSICAL SCIENCE" ? (
                                    <th>{"PHYSICAL SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "GATE" &&
                                  val2?.baseCourse === "STATISTICS" ? (
                                    <th>{"STATISTICS"}</th>
                                  ) : null}
                                  {/* *******"TIFR"**************** */}
                                  {val1?.examTypeName === "TIFR" &&
                                  val2?.baseCourse === "BIOLOGY" ? (
                                    <th>{"BIOLOGY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "TIFR" &&
                                  val2?.baseCourse === "CHEMISTRY" ? (
                                    <th>{"CHEMISTRY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "TIFR" &&
                                  val2?.baseCourse ===
                                    "COMPUTER & SYSTEM SCIENCES" ? (
                                    <th>{"COMPUTER & SYSTEM SCIENCES"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "TIFR" &&
                                  val2?.baseCourse === "MATHEMATICS" ? (
                                    <th>{"MATHEMATICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "TIFR" &&
                                  val2?.baseCourse ===
                                    "MSC WILDLIFE BIOLOGY AND CONSERVATION" ? (
                                    <th>
                                      {"MSC WILDLIFE BIOLOGY AND CONSERVATION"}
                                    </th>
                                  ) : null}
                                  {val1?.examTypeName === "TIFR" &&
                                  val2?.baseCourse === "PHYSICS" ? (
                                    <th>{"PHYSICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "TIFR" &&
                                  val2?.baseCourse === "SCIENCE EDUCATION " ? (
                                    <th>{"SCIENCE EDUCATION "}</th>
                                  ) : null}
                                  {/* *******"BARC-OCES"**************** */}
                                  {val1?.examTypeName === "BARC-OCES" &&
                                  val2?.baseCourse === "BIOSCIENCE" ? (
                                    <th>{"BIOSCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "BARC-OCES" &&
                                  val2?.baseCourse === "CHEMISTRY" ? (
                                    <th>{"CHEMISTRY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "BARC-OCES" &&
                                  val2?.baseCourse === "PHYSICS" ? (
                                    <th>{"PHYSICS"}</th>
                                  ) : null}
                                  {/* *******"ICMR"**************** */}
                                  {val1?.examTypeName === "ICMR" &&
                                  val2?.baseCourse === "LIFE SCIENCE" ? (
                                    <th>{"LIFE SCIENCE"}</th>
                                  ) : null}
                                  {/* *******"JEST"**************** */}
                                  {val1?.examTypeName === "JEST" &&
                                  val2?.baseCourse === "PHYSICS" ? (
                                    <th>{"PHYSICS"}</th>
                                  ) : null}
                                  {/* *******"GAT B"**************** */}
                                  {val1?.examTypeName === "GAT B" &&
                                  val2?.baseCourse === "GAT - B" ? (
                                    <th>{"GAT - B"}</th>
                                  ) : null}
                                  {/* *******"CUET PG"**************** */}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse ===
                                    "ATMOSPEHERIC SCIENCE" ? (
                                    <th>{"ATMOSPEHERIC SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "BIOCHEMISTRY" ? (
                                    <th>{"BIOCHEMISTRY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "BOTANY" ? (
                                    <th>{"BOTANY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "CHEMISTRY" ? (
                                    <th>{"CHEMISTRY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "COMMERCE" ? (
                                    <th>{"COMMERCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse ===
                                    "COMPUTER SCIENCE & INFORMATION TECHNOLOGY" ? (
                                    <th>
                                      {
                                        "COMPUTER SCIENCE & INFORMATION TECHNOLOGY"
                                      }
                                    </th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "CUET (PG) PART A" ? (
                                    <th>{"CUET (PG) PART A"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "ECONOMICS" ? (
                                    <th>{"ECONOMICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "ENGLISH" ? (
                                    <th>{"ENGLISH"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "GEOGRAPHY" ? (
                                    <th>{"GEOGRAPHY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "LIFE SCIENCE" ? (
                                    <th>{"LIFE SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "MATHEMATICS" ? (
                                    <th>{"MATHEMATICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "MICROBIOLOGY" ? (
                                    <th>{"MICROBIOLOGY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse ===
                                    "NANOSCIENCE / INTEGRATIVE BIOSCIENCE" ? (
                                    <th>
                                      {"NANOSCIENCE / INTEGRATIVE BIOSCIENCE"}
                                    </th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "PHYSICS" ? (
                                    <th>{"PHYSICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "PLANT BIOTECHNOLOGY" ? (
                                    <th>{"PLANT BIOTECHNOLOGY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "STATISTICS" ? (
                                    <th>{"STATISTICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "CUET PG" &&
                                  val2?.baseCourse === "ZOOLOGY" ? (
                                    <th>{"ZOOLOGY"}</th>
                                  ) : null}
                                  {/* *******"SET"**************** */}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "CHEMICAL SCIENCE" ? (
                                    <th>{"CHEMICAL SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "COMMERCE" ? (
                                    <th>{"COMMERCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "COMPUTER SCIENCE" ? (
                                    <th>{"COMPUTER SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "ECONOMICS" ? (
                                    <th>{"ECONOMICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "ENGLISH" ? (
                                    <th>{"ENGLISH"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "GEOGRAPHY" ? (
                                    <th>{"GEOGRAPHY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "LIFE SCIENCE" ? (
                                    <th>{"LIFE SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "MANAGEMENT" ? (
                                    <th>{"MANAGEMENT"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse ===
                                    "MATHEMATICAL SCIENCE" ? (
                                    <th>{"MATHEMATICAL SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "PHYSICAL SCIENCE" ? (
                                    <th>{"PHYSICAL SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "SET" &&
                                  val2?.baseCourse === "SET PAPER 1" ? (
                                    <th>{"SET PAPER 1"}</th>
                                  ) : null}
                                  {/* *******"PSC"**************** */}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "BIOCHEMISTRY" ? (
                                    <th>{"BIOCHEMISTRY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "BIOLOGY" ? (
                                    <th>{"BIOLOGY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "BOTANY" ? (
                                    <th>{"BOTANY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse ===
                                    "BUSINESS ADMINISTRATION" ? (
                                    <th>{"BUSINESS ADMINISTRATION"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "CHEMISTRY" ? (
                                    <th>{"CHEMISTRY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "COMMERCE" ? (
                                    <th>{"COMMERCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "COMPUTER SCIENCE" ? (
                                    <th>{"COMPUTER SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse ===
                                    "ECONOMIC AND FINANCIAL MANAGEMENT" ? (
                                    <th>
                                      {"ECONOMIC AND FINANCIAL MANAGEMENT"}
                                    </th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "ECONOMICS" ? (
                                    <th>{"ECONOMICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "ENGLISH" ? (
                                    <th>{"ENGLISH"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "FORENSIC SCIENCE" ? (
                                    <th>{"FORENSIC SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "GEOGRAPHY" ? (
                                    <th>{"GEOGRAPHY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "GEOLOGY" ? (
                                    <th>{"GEOLOGY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "LIFE SCIENCE" ? (
                                    <th>{"LIFE SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "MANAGEMENT" ? (
                                    <th>{"MANAGEMENT"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "MATHEMATICS" ? (
                                    <th>{"MATHEMATICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "MATHS" ? (
                                    <th>{"MATHS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "MICROBIOLOGY" ? (
                                    <th>{"MICROBIOLOGY"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "PAPER III" ? (
                                    <th>{"PAPER III"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "PHYSICS" ? (
                                    <th>{"PHYSICS"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "SCIENCE" ? (
                                    <th>{"SCIENCE"}</th>
                                  ) : null}
                                  {val1?.examTypeName === "PSC" &&
                                  val2?.baseCourse === "ZOOLOGY" ? (
                                    <th>{"ZOOLOGY"}</th>
                                  ) : null}
                                </>
                              );
                            })}
                          </>
                        );
                      }
                    )}
                  </tr>
                </>
                <tr>
                  <td className="fw-bold">
                    {leadadDepartment?.TotalLeadCount}
                  </td>
                  {leadadDepartment?.ExamAndBaseCourseList?.map((val: any) => {
                    return (
                      <>
                        {val?.baseCourseNames?.map((HoriCount: any) => {
                          return (
                            <td className="fw-bold">
                              {HoriCount?.totalLeadCount}
                            </td>
                          );
                        })}
                      </>
                    );
                  })}
                </tr>

                {leadadDepartment?.examData?.map((valBody: any) => {
                  return (
                    <>
                      <tbody>
                        <>
                          <tr>
                            <td>{valBody?.date}</td>
                            <td className="fw-bold">{valBody?.Totalcount}</td>
                            {valBody?.examCounts?.map((body: any) => {
                              return (
                                <>
                                  {body?.basecourse?.map((data: any) => {
                                    return (
                                      <>
                                        {/* {data?.count ? (
                                          <td>{data?.count}</td>
                                        ) : (
                                          <td>0</td>
                                        )} */}

                                        {/* ******************CSIR NET************ */}
                                        {body?.examTypeName === "CSIR NET" &&
                                        data?.basecourseName ===
                                          "CHEMICAL SCIENCE" ? (
                                          <>
                                            {body?.examTypeName ===
                                              "CSIR NET" &&
                                            data?.basecourseName ===
                                              "CHEMICAL SCIENCE" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {body?.examTypeName === "CSIR NET" &&
                                        data?.basecourseName ===
                                          "CSIR APTITUDE" ? (
                                          <>
                                            {body?.examTypeName ===
                                              "CSIR NET" &&
                                            data?.basecourseName ===
                                              "CSIR APTITUDE" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {body?.examTypeName === "CSIR NET" &&
                                        data?.basecourseName ===
                                          "LIFE SCIENCE" ? (
                                          <>
                                            {body?.examTypeName ===
                                              "CSIR NET" &&
                                            data?.basecourseName ===
                                              "LIFE SCIENCE" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {body?.examTypeName === "CSIR NET" &&
                                        data?.basecourseName ===
                                          "MATHEMATICAL SCIENCE" ? (
                                          <>
                                            {body?.examTypeName ===
                                              "CSIR NET" &&
                                            data?.basecourseName ===
                                              "MATHEMATICAL SCIENCE" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {body?.examTypeName === "CSIR NET" &&
                                        data?.basecourseName ===
                                          "PHYSICAL SCIENCE" ? (
                                          <>
                                            {body?.examTypeName ===
                                              "CSIR NET" &&
                                            data?.basecourseName ===
                                              "PHYSICAL SCIENCE" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {/* ******************UGC NET************ */}
                                        {body?.examTypeName === "UGC NET" &&
                                        data?.basecourseName === "COMMERCE" ? (
                                          <>
                                            {body?.examTypeName === "UGC NET" &&
                                            data?.basecourseName ===
                                              "COMMERCE" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {body?.examTypeName === "UGC NET" &&
                                        data?.basecourseName ===
                                          "COMPUTER SCIENCE" ? (
                                          <>
                                            {body?.examTypeName === "UGC NET" &&
                                            data?.basecourseName ===
                                              "COMPUTER SCIENCE" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {data?.basecourseName ===
                                        "ECONOMICS" ? (
                                          <>
                                            {body?.examTypeName === "UGC NET" &&
                                            data?.basecourseName ===
                                              "ECONOMICS" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {data?.basecourseName === "ENGLISH" ? (
                                          <>
                                            {body?.examTypeName === "UGC NET" &&
                                            data?.basecourseName ===
                                              "ENGLISH" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {data?.basecourseName ===
                                        "GEOGRAPHY" ? (
                                          <>
                                            {body?.examTypeName === "UGC NET" &&
                                            data?.basecourseName ===
                                              "GEOGRAPHY" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {data?.basecourseName ===
                                        "MANAGEMENT" ? (
                                          <>
                                            {body?.examTypeName === "UGC NET" &&
                                            data?.basecourseName ===
                                              "MANAGEMENT" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}

                                        {data?.basecourseName ===
                                        "UGC NET PAPER 1" ? (
                                          <>
                                            {body?.examTypeName === "UGC NET" &&
                                            data?.basecourseName ===
                                              "UGC NET PAPER 1" ? (
                                              <td>{data?.count}</td>
                                            ) : (
                                              <td>0</td>
                                            )}
                                          </>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </tr>
                        </>
                      </tbody>
                    </>
                  );
                })}
              </>
            </table>
          </>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"LEAD DEPARTMENT WISE ANALYSIS"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12   formWidth">
          {SearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
