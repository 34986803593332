import { useLocation, useNavigate } from "react-router-dom";
import localImages from "../Assets/localImages";
import { useAppDispatch } from "../hooks";
import { CrmLogout } from "../Redux/Actions/AuthAction";
import { showSuccessSnackbar } from "../Redux/Actions/snackbarAction";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuIcon, setMenuIcon] = useState<any>(false);

  const IsLogIn = location.state?.IsLogIn;

  const { loggedInUserName } = useSelector((state: any) => ({
    loggedInUserName: state.Auth.loggedInUserName,
  }));

  const SignOut = () => {
    dispatch(
      CrmLogout(
        (res: any) => {
          dispatch(showSuccessSnackbar("Logout Succesfully"));
          navigate("/");
        },
        () => {}
      )
    );
  };

  // useEffect(() => {
  //   const element: any = document?.getElementById("mySidenav");
  //   if (element !== null) {
  //     element.style.width = "20%";
  //     setMenuIcon(true);
  //   }
  // }, []);

  // const openNav = () => {
  //   const element: any = document?.getElementById("mySidenav");
  //   if (element !== null) {
  //     element.style.width = "20%";
  //     setMenuIcon(true);
  //   }
  // };

  // const closeNav = () => {
  //   const element: any = document?.getElementById("mySidenav");
  //   if (element !== null) {
  //     element.style.width = "0";
  //     setMenuIcon(false);
  //   }
  // };

  return (
    <header className={window.location.pathname === "/login" ? "hide " : ""}>
      {/* {!menuIcon ? (
        <span
          className="cursor fw-bold mb-0"
          style={{ fontSize: "20px" }}
          onClick={() => {
            openNav();
          }}
        >
          {/* &#9776; 
          {<MenuIcon />}
        </span>
      ) : null} */}

      <div className="d-flex justify-content-between w-100">
        {/* {IsLogIn ? (
          <div className=" ">
            {/* <ArrowBackIosIcon color="primary" />  
            <img src={localImages.logo} className="logo" />
          </div>
        ) : (
          <div className=" ">
            {/* <ArrowBackIosIcon color="primary" onClick={() => navigate(-1)} />  
            <img src={localImages.logo} className="logo" />
            {menuIcon ? (
              <div className="">
                <span
                  className="closebtn cursor fw-bold mb-0"
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    closeNav();
                  }}
                >
                  {/* &#9776;  
                  {<CloseIcon />}
                </span>
              </div>
            ) : null}
          </div>
        )} */}
<img src={localImages.logo} className="logo" />
        <div className="d-flex">
          {/* <p className="text-primary text-decoration-underline font14 cursor">Change Password</p> */}
          <div className="dropdown">
            <div className="profile mx-2">
              <img
                src={localImages.profile}
                alt="Profile"
                className="img-fluid py-1"
              />
            </div>

            <div className="dropdown-content p-2">
              <p className="text-dark fw-bold mb-0 d-flex justify-content-center p-2">
                {loggedInUserName}
              </p>
              <button
                type="button"
                className="btn btn-light border"
                onClick={SignOut}
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
