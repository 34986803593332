class AuthReducerModal {
  loader = false;
  authToken = "";
  counsellorId = "";
  managerId = "";
  loggedInUserId = "";
  loggedInUserManagerId = "";
  loggedInUserName = "";
  loggedInUserRole = "";
  selectedLeadId = "";
  selectedLeadName = "";
  selectedClickedDate = "";
  selectedManagerId = "";
  selectedCounsellorId = "";
  selectedExamTypeId = "";
  selectedSubExamTypeId = "";
  selectedBaseCourseId = "";
  selectedMainCategoryId = "";
  selectedSubCategoryId = "";
  selectedStatus = "";
  selectedSubStatus = "";
  selectedPage = "";
  selectedRowsPerPage = "";
  startDate = "";
  endDate = "";
  CollegeName = "";
  CollegeContactNo = "";
  CollegeEmail = "";
  CollegeState = "";
  CollegeDistric = "";
  executiveID = "";
}

class LeadManagementModal {}

class SnackbarReducerModal {
  successSnackbarOpen = false;
  errorSnackbarOpen = false;
  infoSnackbarOpen = false;
  message = "";
}

export { AuthReducerModal, LeadManagementModal, SnackbarReducerModal };
