import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getCounsellorListById,
  getTeamLeadList,
  getTeamLeadListByManager,
} from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TeamShrinkageAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);
  const [counsellorByIdList, setCounsellorByIdList] = useState<any>([]);

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  useEffect(() => {
    // dispatch(
    //   getTeamLeadList(
    //     "",
    //     (res: any) => {
    //       setTeamLeadList(res?.data)
    //       showGraph(res?.data);

    //     },
    //     () => { }
    //   )
    // );

    if (loggedInUserRole === 1) {
      dispatch(
        getTeamLeadListByManager(
          {},
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getTeamLeadList(
          {},
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    }
  }, []);

  const showGraph = (list: any) => {
    setXLabel([]);
    setGraphdata1([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((val: any) => {
      labelList?.push(val?._id?.name);
      graphDataList?.push(val?._id?.status);
    });
    setXLabel(labelList);
    setGraphdata1(graphDataList);
  };

  const renderListMode = () => {
    return (
      <div>
        <GenaralTable
          tableData={counsellorByIdList ? counsellorByIdList : []}
          pagename={"TeamShrinkageAnalysis"}
          tableHead={[
            "First Name",
            "Last Name",
            "Exam",
            "Exam Stream",
            "Source",
            "Status",
          ]}
        />
      </div>
    );
  };

  const getManagerData = (val: any) => {
    dispatch(
      getCounsellorListById(
        teamLeadList[val?.index]?._id?._id,
        (res: any) => {
          setCounsellorByIdList(res?.data);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={" "} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          <h3 className="fw-bold text-primary">Dashboard</h3>
          <p>Wednesday 8 November 2023, 12:00</p>
          <div className="col-md-7 p-3">
            <h4 className="fw-bold">Summery Overview </h4>
            <SalesPanelSatckGraph
              label={xLabel}
              value={Graphdata1}
              getManagerData={(val: any) => getManagerData(val)}
            />
          </div>

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
