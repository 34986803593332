import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import GenaralTable from "../../Component/genaralTable";
import { useSelector } from "react-redux";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import moment from "moment";

import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import { getSMManagerList } from "../../Redux/Actions/superManagerAction";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";

export default function TimeAnalysisSuperManager() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const [xLabelCallAll, setXLabelCallAll] = useState<any>([]);
  const [GraphdataCallAll, setGraphdataCallAll] = useState<any>([]);
  const [xLabelCallUnique, setXLabelCallUnique] = useState<any>([]);
  const [GraphdataCallUnique, setGraphdataCallUnique] = useState<any>([]);
  const [talkTimeList, setTalkTimeList] = useState<any>([]);
  const [talkTimeLabelList, setTalkTimeLabelList] = useState<any>([]);
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata, setGraphdata1] = useState<any>([]);
  const [xLabel4, setXLabel4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState(false);
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState(false);

  const { loggedInUserRole, crmEmployeeId, loggedInUserName, loggedInUserId } = useSelector(
    (state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      crmEmployeeId: state.Auth.counsellorId,
      loggedInUserName: state.Auth.loggedInUserName,
      loggedInUserId: state.Auth.loggedInUserId,
    })
  );

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => { }
      )
    );

  }, []);

  const showGraphCallTotalConnected = (list: any) => {
    setXLabelCallTotal([]);
    setGraphdataCallTotal([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.totalConnectedCall?.achieved);
    });
    setXLabelCallTotal(labelList);
    setGraphdataCallTotal(graphDataList);
  };

  const showGraphCallAllConnected = (list: any) => {
    setXLabelCallAll([]);
    setGraphdataCallAll([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.oldConnectedCall?.achieved);
    });
    setXLabelCallAll(labelList);
    setGraphdataCallAll(graphDataList);
  };

  const showGraphCallUniqueConnected = (list: any) => {
    setXLabelCallUnique([]);
    setGraphdataCallUnique([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.uniqueConnectedCall?.achieved);
    });
    setXLabelCallUnique(labelList);
    setGraphdataCallUnique(graphDataList);
  };

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {

          setTeamLeadList(res?.data);
        },
        () => { }
      )
    );
  };
  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);


  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <h4 className="fw-bold">Team Talk Time</h4>
        <GenaralTable
          tableData={talkTimeList ? talkTimeList : []}
          pagename={"timeAnalysis"}
          tableHead={talkTimeLabelList}
        />
      </div>
    );
  };

  const getDatesInRange = (startDate: any, endDate: any) => {
    for (
      var arr = [], dt = new Date(startDate);
      dt <= new Date(endDate);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    let temp = arr.map((item: any) => item.toISOString().slice(0, 10));
    return temp;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();



  };

  const getManagerData = (val: any) => {

    navigate("../timeAnalysisOverview", {
      state: {
        pageName: "timeAnalysisOverview",
        counsellorId: manager?._id,
      },
    });

  };

  return (
    <div className="contentBox">
      <PageTitle name={"Time Analysis"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-2">
          <form className="d-flex row px-5  " onSubmit={handleSubmit}>
            <div className="col-md-4 d-flex  mt-3 ">
              <div className="col-md-3 mt-2 ">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex   mt-3">
              <div className="col-md-4 mt-2 ">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div className="col-md-4 d-flex mb-3 mt-2">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Select Manager" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                  // Isrequired={true}
                  reset={resetManager}
                />
              </div>
            </div>
            <div className='col-md-4 d-flex mb-3'>
              <div className='col-md-3 mt-2'>
                <LabelField lableName='Select Team Lead' />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Team Lead"
                  setInputText={(value: any) =>
                    onChangeTeamLead(value)
                  }
                  value={teamLead}
                  options={teamLeadList}
                  Isrequired={true}
                  reset={resetTeamLead}

                /></div>
            </div>
            <div className=" col-md-3 text-center mb-2  mt-2 me-4 ">
              <SubmitButton name={"Submit"} />
            </div>
          </form>

          <>
            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Total Connected calls</h4>
                {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                {/* <SalesPanelSummeryGraph
                  label={xLabelCallTotal}
                  value={GraphdataCallTotal}
                  link={linkList}
                /> */}
                <SalesPanelSatckGraph
                  label={xLabelCallTotal}
                  value={GraphdataCallTotal}
                  getManagerData={(val: any) => getManagerData(val)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Old Connected calls</h4>
                <CallRecordedGraph
                  label={xLabelCallAll}
                  value={GraphdataCallAll}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-12 p-3">
                <h4 className="fw-bold">Unique Connected calls</h4>
                <CallRecordedGraph
                  label={xLabelCallUnique}
                  value={GraphdataCallUnique}
                />
              </div>
            </div>
          </>

          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
