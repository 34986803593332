import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import moment from 'moment';
import LabelField from '../../Component/labelField';
import DatePicker from '../../Component/datePicker';
import CustomDropdown from '../../Component/customDropdown';
import { getState, getCity, } from '../../Redux/Actions/bdeAdminPanelAction';
import { getDepartmentLeadAnalysis } from '../../Redux/Actions/bdExecutivePanelAction';
import { getExecutiveByManagerID } from '../../Redux/Actions/bdManagerPanelAction';
export default function DepartmentwiseleadAnalysisTL() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(moment.utc(new Date()).format("YYYY-MM-DD"));
  const [endDate, SetEndDate] = useState<any>(moment.utc(new Date()).format("YYYY-MM-DD"));
  const [state, setState] = useState<any>("")
  const [stateList, setStateList] = useState([])
  const [ResetState, setResetState] = useState(false);
  const [district, setDistrict] = useState<any>("")
  const [districtList, setDistrictList] = useState([])
  const [ResetDistrict, setResetDistrict] = useState(false);
  const [executive, setExecutive] = useState<any>("")
  const [executiveList, setExecutiveList] = useState([])
  const [ResetExecutive, setResetExecutive] = useState(false); 

  useEffect(() => {
    dispatch(
      getExecutiveByManagerID(
          "",
        (res: any) => {
          setExecutiveList(res )
            
        },
        () => { }
      )
    );

  }, [])

  const onChangeBDExecutive=(val:any)=>{
    setExecutive(val)
    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);

        },
        () => { }
      )
    );
   
  }

  const onChangeState = (val: any) => {
    setState(val)
    dispatch(
      getCity(
        val._id,
        (res: any) => {
          setDistrictList(res);

        },
        () => { }
      )
    );
  }

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      state: state?._id,
      district: district?._id,

    }
    dispatch(
      getDepartmentLeadAnalysis(
        postData,
        (res: any) => {
        },
        () => { }
      )
    );
  }
  const onChangeDistrict = (val: any) => {
    setDistrict(val)
  }

  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        <div className="col-md-4 row d-flex my-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              Isrequired={true}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
        <div className="col-md-3 mt-2">
            <LabelField lableName="BD Executive" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select BD Executive"
              setInputText={(value: any) => onChangeBDExecutive(value)}
              value={executive}
              options={executiveList}
              Isrequired={true}
              reset={ResetExecutive}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="State" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select State"
              setInputText={(value: any) => onChangeState(value)}
              value={state}
              options={stateList}
              Isrequired={true}
              reset={ResetState}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="District" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select District"
              setInputText={(value: any) => onChangeDistrict(value)}
              value={district}
              options={districtList}
              Isrequired={true}
              reset={ResetDistrict}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-4">
            <button className="btn btn-primary " type="submit">
              {" "}
              Search
            </button>
          </div>
        </div>
      </form>
    )
  }
  const LedadDepartment = {
    data: [
      {
        examTypeName: "CSIR NET",
        kshama: [
          {
            basecourseName: "LIFE SCIENCE",
            _id: "2023-12-11",
          },
          {
            basecourseName: "CHEMICAL SCIENCE",
            _id: "2023-12-12",
          },
          {
            basecourseName: "PHYSICAL SCIENCE",
            _id: "2023-12-13",
          },
          {
            basecourseName: "MATHEMATICAL SCIENCE",
            _id: "2023-12-14",
          },
        ],
        data:[
          {
            date: "2023-12-11",
            totalLeads: 20,
            lsLeads: 40,
            csLeads: 40,
            psLeads: 40,
            msLeads: 40, 
          },
          {
            date: "2023-12-12",
            totalLeads: 30,
            lsLeads: 40,
            csLeads: 40, 
          },
          {
            date: "2023-12-13",
            totalLeads: 50,
            lsLeads: 40,
            csLeads: 40,
            qqLeads: 40,
            wwLeads: 40, 
            eeLeads: 40,
            rrLeads: 40,  
          },
        ]
        
      },
      {
        examTypeName: "IIT-JAM",
        kshama: [
          {
            basecourseName: "BIOTECHNOLOGY",
            _id: "2023-12-11",
          },  
          {
            basecourseName: "CHEMICAL SCIENCE",
            _id: "2023-12-12",
          },
          {
            basecourseName: "PHYSICAL SCIENCE",
            _id: "2023-12-13",
          },
          {
            basecourseName: "MATHEMATICAL SCIENCE",
            _id: "2023-12-14",
          },
          {
            basecourseName: "MATHEMATICAL SCIENCE",
            _id: "2023-12-14",
          },
        ],
        data:[
          {
            date: "2023-12-11",
            totalLeads: 20,
            lsLeads: 50,
            csLeads: 60,
            psLeads: 70,
            msLeads: 80, 
          },
          {
            date: "2023-12-12",
            totalLeads: 30,
            lsLeads: 40,
            csLeads: 40, 
          },
        
        ]
        
      },
    ],
    
  };
  const renderListMode = () => {
    return (
      <>
        <div className="w-100 counsellortable table-responsive">
          {LedadDepartment?.data?.map((item: any) => {
            return (
              <>
                <table className=" w-100 text-center table-bordered border-secondary ">
                  <>
                    <tr>
                      <th rowSpan={3}>Date</th>
                      <th>Total</th>
                      <th colSpan={item?.kshama?.length}>{item?.examTypeName}</th>
                    </tr>
                    <tr>
                      <th>Leads</th>
                      {item?.kshama?.map((item: any) => {
                        return <th>{item?.basecourseName}</th>;
                      })}
                    </tr> 
                   {item?.data?.map((val:any)=>{
                    return(
                      <tbody>
                        <tr>
                        <td>{val?.date}</td>
                        <td>{val?.totalLeads}</td>
                        <td>{val?.lsLeads}</td>
                        <td>{val?.csLeads}</td>
                        <td>{val?.psLeads}</td>
                        <td>{val?.msLeads}</td>

                        </tr>
                      </tbody>
                    )
                   })} 
                  </>
                </table>
              </>
            );
          })
          }
        </div>
      </>
    );
  };




  return (
    <div className="contentBox">
      <PageTitle name={"LEAD DEPARTMENT WISE ANALYSIS"} />
      <div className="  p-2 formDiv">
        <div className='col-md-12   formWidth'>
          {SearchMode()}
          {renderListMode()}



        </div>


      </div>
    </div>
  )
}

