import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { useSelector } from "react-redux";
import {
  getEmployeetByManagerId,
  getTeamAnalysis,
  getTeamOverdueAnalysis,
} from "../../Redux/Actions/managerPanelAction";
import { getRevenueLeadsAnalysisStatusByDate } from "../../Redux/Actions/counsellorPanelAction";
export default function LeadAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [manager, setManager] = useState<any>("");
  const [analysis, setAnalysis] = useState<any>("ALL");
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState();
  const [analysisList, setAnalysisList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>("");

  const teamAnalysisPostData = location.state?.postData;
  const pageName = location.state?.pageName;

  const { loggedInUserRole, loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    if (pageName === "teamLeadAssignedDateAnalysis") {
      dispatch(
        getRevenueLeadsAnalysisStatusByDate(
          teamAnalysisPostData,
          (res: any) => {
            console?.log("ressss", res);
            setAnalysisList(res?.record);
            setTotalCount(res?.totalStatusCounts);
          },
          () => {}
        )
      );
    } else {
      if (loggedInUserRole === 1) {
        dispatch(
          getEmployeetByManagerId(
            loggedInUserId,
            (res: any) => {
              setManagerList(res?.data);
            },
            () => {}
          )
        );
      }
      if (loggedInUserRole === 2) {
        dispatch(
          getTeamAnalysis(
            {
              managerId: loggedInUserId,
            },
            (res: any) => {
              setAnalysisList(res?.record);
              setTotalCount(res?.totalStatusCounts);
            },
            () => {}
          )
        );
      }
    }
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getTeamAnalysis(
        {
          managerId: val?._id,
        },
        (res: any) => {
          setAnalysisList(res?.record);
          setTotalCount(res?.totalStatusCounts);
        },
        () => {}
      )
    );
  };

  const handleAnalysis = (e: any, val: any, managerId: any) => {
    setAnalysis(val);
    if (loggedInUserRole === 1) {
      if (val === "ALL") {
        dispatch(
          getTeamAnalysis(
            {
              managerId: manager?._id,
            },
            (res: any) => {
              setAnalysisList(res?.record);
              setTotalCount(res?.totalStatusCounts);
            },
            () => {}
          )
        );
      } else if (val === "OVERDUE") {
        dispatch(
          getTeamOverdueAnalysis(
            {
              managerId: manager?._id,
            },
            (res: any) => {
              setAnalysisList(res?.record);
              setTotalCount(res?.totalStatusCounts);
            },
            () => {}
          )
        );
      }
    } else if (loggedInUserRole === 2) {
      if (val === "ALL") {
        dispatch(
          getTeamAnalysis(
            {
              managerId: loggedInUserId,
            },
            (res: any) => {
              setAnalysisList(res?.record);
              setTotalCount(res?.totalStatusCounts);
            },
            () => {}
          )
        );
      } else if (val === "OVERDUE") {
        dispatch(
          getTeamOverdueAnalysis(
            {
              managerId: loggedInUserId,
            },
            (res: any) => {
              setAnalysisList(res?.record);
              setTotalCount(res?.totalStatusCounts);
            },
            () => {}
          )
        );
      }
    }
  };

  const onClickLead = (data: any, status: any) => {
    navigate("../counsellorCallDetails", {
      state: {
        data: data,
        pageName: "ManagerLeadAnalysis",
        analysis,
        status: status,
      },
    });
  };
  const renderSearchMode = () => {
    return (
      <div className="row d-flex">
        {loggedInUserRole === 1 ? (
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Select Manager" />
            </div>
            <div className="col-md-8 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
                reset={resetManager}
              />
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className={`btn mb-3 me-2 cursor ${
              analysis === "ALL" ? "btn-primary" : "btn-secondary"
            }`}
            onClick={(e: any) => handleAnalysis(e, "ALL", loggedInUserId)}
          >
            All
          </button>
          <button
            type="button"
            className={`btn mb-3 me-2 cursor ${
              analysis === "OVERDUE" ? "btn-primary" : "btn-secondary"
            }`}
            onClick={(e: any) => handleAnalysis(e, "OVERDUE", loggedInUserId)}
          >
            Overdue
          </button>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <div className="salesTable table-responsive">
        <table className="w-100 mb-2">
          <thead>
            {analysis === "ALL" ? (
              <tr>
                <th rowSpan={2}>Counsellor Name</th>
                <th>New Leads </th>
                <th>Transfered </th>
                <th>Call Back</th>
                <th>Intrested</th>
                <th>Not Intrested</th>
                <th>Prospect</th>
                <th>Admission Done</th>
                <th>Lead Invalid</th>
                <th>Already Admission Done</th>
                <th>Expired</th>
                <th>CNR</th>
                <th>Call Reject</th>
                <th>Final CNR</th>
                <th>Welcome Call Done</th>
                <th>Welcome Call & Reference Collection</th>
                <th>Follow-up For Upselling</th>
                <th>Interested for Upselling</th>
                <th>Prospect for Upselling</th>
                <th>Technical Issue</th>
              </tr>
            ) : (
              <tr>
                <th rowSpan={2}>Counsellor Name</th>
                <th>Overdue Callback</th>
                <th>Overdue Interested</th>
                <th>Overdue Prospect</th>
                <th>Overdue CNR</th>
                <th>Overdue Call Reject</th>
              </tr>
            )}

            {analysis === "ALL" ? (
              <tr>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 2)}
                >
                  {totalCount?.["NEWLEAD(ASSIGNED)"]}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 3)}
                >
                  {totalCount?.TRANSFERREDLEAD}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 6)}
                >
                  {totalCount?.CALLBACK}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 4)}
                >
                  {totalCount?.INTERESTED}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 9)}
                >
                  {totalCount?.NOTINTERESTED}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 7)}
                >
                  {totalCount?.PROSPECT}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 5)}
                >
                  {totalCount?.ADMISSIONDONE}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 8)}
                >
                  {totalCount?.LEADINVALID}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 10)}
                >
                  {totalCount?.ALREADYADMISSIONDONE}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 11)}
                >
                  {totalCount?.EXPIRED}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 12)}
                >
                  {totalCount?.CNR}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 21)}
                >
                  {totalCount?.CALLREJECT}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 14)}
                >
                  {totalCount?.FINALCNR}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 15)}
                >
                  {totalCount?.WELCOMECALLDONE}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 16)}
                >
                  {totalCount?.WELCOMECALLREFERENCE}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 17)}
                >
                  {totalCount?.FOLLOWUP}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 18)}
                >
                  {totalCount?.INTERESTEDFORUPSELLING}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 19)}
                >
                  {totalCount?.PROSPECTFORUPSELLING}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 20)}
                >
                  {totalCount?.TECHNICALISSUE}
                </th>
              </tr>
            ) : (
              <tr>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 6)}
                >
                  {totalCount?.OVERDUECALLBACK}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 4)}
                >
                  {totalCount?.OVERDUEINTERESTED}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 7)}
                >
                  {totalCount?.OVERDUEPROSPECT}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 12)}
                >
                  {totalCount?.OVERDUECNR}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 21)}
                >
                  {totalCount?.OVERDUECALLREJECT}
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {analysisList.map((item: any, index: any) => {
              return analysis === "ALL" ? (
                <tr>
                  <td>
                    {item?.firstName ? item?.firstName : ""}{" "}
                    {item?.lastName ? item?.lastName : ""}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 2)}>
                    {item["NEWLEAD(ASSIGNED)"]?.count
                      ? item["NEWLEAD(ASSIGNED)"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 3)}>
                    {" "}
                    {item["TRANSFERREDLEAD"]?.count
                      ? item["TRANSFERREDLEAD"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 6)}>
                    {" "}
                    {item["CALLBACK"]?.count ? item["CALLBACK"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 4)}>
                    {" "}
                    {item["INTERESTED"]?.count
                      ? item["INTERESTED"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 9)}>
                    {item["NOTINTERESTED"]?.count
                      ? item["NOTINTERESTED"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 7)}>
                    {" "}
                    {item["PROSPECT"]?.count ? item["PROSPECT"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 5)}>
                    {" "}
                    {item["ADMISSIONDONE"]?.count
                      ? item["ADMISSIONDONE"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 8)}>
                    {" "}
                    {item["LEADINVALID"]?.count
                      ? item["LEADINVALID"]?.count
                      : "-"}
                  </td>

                  <td className="cursor" onClick={() => onClickLead(item, 10)}>
                    {" "}
                    {item["ALREADYADMISSIONDONE"]?.count
                      ? item["ALREADYADMISSIONDONE"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 11)}>
                    {" "}
                    {item["EXPIRED"]?.count ? item["EXPIRED"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 12)}>
                    {" "}
                    {item["CNR"]?.count ? item["CNR"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 21)}>
                    {" "}
                    {item["CALLREJECT"]?.count
                      ? item["CALLREJECT"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 14)}>
                    {" "}
                    {item["FINALCNR"]?.count ? item["FINALCNR"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 15)}>
                    {" "}
                    {item["WELCOMECALLDONE"]?.count
                      ? item["WELCOMECALLDONE"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 16)}>
                    {" "}
                    {item["WELCOMECALLREFERENCE"]?.count
                      ? item["WELCOMECALLREFERENCE"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 17)}>
                    {" "}
                    {item["FOLLOWUP"]?.count ? item["FOLLOWUP"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 18)}>
                    {" "}
                    {item["INTERESTEDFORUPSELLING"]?.count
                      ? item["INTERESTEDFORUPSELLING"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 19)}>
                    {" "}
                    {item["PROSPECTFORUPSELLING"]?.count
                      ? item["PROSPECTFORUPSELLING"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 20)}>
                    {" "}
                    {item["TECHNICALISSUE"]?.count
                      ? item["TECHNICALISSUE"]?.count
                      : "-"}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    {item?.firstName ? item?.firstName : ""}{" "}
                    {item?.lastName ? item?.lastName : ""}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 6)}>
                    {" "}
                    {item["OVERDUECALLBACK"]?.count
                      ? item["OVERDUECALLBACK"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 4)}>
                    {" "}
                    {item["OVERDUEINTERESTED"]?.count
                      ? item["OVERDUEINTERESTED"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 7)}>
                    {" "}
                    {item["OVERDUEPROSPECT"]?.count
                      ? item["OVERDUEPROSPECT"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 12)}>
                    {" "}
                    {item["OVERDUECNR"]?.count
                      ? item["OVERDUECNR"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 21)}>
                    {" "}
                    {item["OVERDUECALLREJECT"]?.count
                      ? item["OVERDUECALLREJECT"]?.count
                      : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Analysis"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
