import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import moment from "moment";
import Header from "../../Component/header";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import DateTime from "../../Component/dateTime";
import InputField from "../../Component/inputField";
import SubmitButton from "../../Component/submitButton";
import { webinarStatus } from "../../DATA/dataConstant";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import ReplyIcon from "@mui/icons-material/Reply";

import {
  containsNumber,
  length_Fifty,
  length_Ten,
  length_Three,
  length_Two,
  pdf_size_1MB,
  startsWithSpace,
  validateEmailAddress,
  validateFullName,
  validateIsFileImageType,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  validateEmailAddressErrMsg,
  validateIsFileImageTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import {
  getExamStreamList,
  updateCancelledWebinar,
  updateCompletedWebinar,
  updatePendingWebinar,
  updateRescheduleWebinar,
  uploadMailScreenshot,
} from "../../Redux/Actions/bdExecutivePanelAction";
import Button from "../../Component/button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function WebinarStatusDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const webinarId = location.state?.webinarId;
  const webinarData = location.state?.webinarData;

  const initialVal: {
    _id: any;
    coName: any;
    coPhone: any;
    coEmail: any;
  }[] = [
    {
      _id: "",
      coName: "",
      coPhone: "",
      coEmail: "",
    },
  ];

  const [status, setStatus] = useState<any>("");
  const [statusList, setStatusList] = useState([]);
  const [resetStatus, setResetStatus] = useState(false);
  const [dateTime, setDateTime] = useState("");
  const [remark, setRemark] = useState<any>("");
  const [image, setImage] = useState<any>("");
  const [UploadImage, setUploadImage] = useState<any>("");
  const [fName1, setFName1] = useState<any>("");
  const [lName1, setLName1] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [email1, setEmail1] = useState<any>("");
  const [fName2, setFName2] = useState<any>("");
  const [lName2, setLName2] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email2, setEmail2] = useState<any>("");
  const [fName3, setFName3] = useState<any>("");
  const [lName3, setLName3] = useState<any>("");
  const [contact3, setContact3] = useState<any>("");
  const [email3, setEmail3] = useState<any>("");
  const [fName4, setFName4] = useState<any>("");
  const [lName4, setLName4] = useState<any>("");
  const [contact4, setContact4] = useState<any>("");
  const [email4, setEmail4] = useState<any>("");
  const [fName5, setFName5] = useState<any>("");
  const [lName5, setLName5] = useState<any>("");
  const [contact5, setContact5] = useState<any>("");
  const [email5, setEmail5] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [uploadMailScreenshotFile, setUploadMailScreenshotFile] =
    useState<any>("");
  const [uploadMailScreenshotData, setUploadMailScreenshotData] =
    useState<any>("");
  const [ResetWebStatus, setResetWebStatus] = useState(false);
  const [examStream1, setExamStream1] = useState<any>("");
  const [examStream2, setExamStream2] = useState<any>("");
  const [examStream3, setExamStream3] = useState<any>("");
  const [examStream4, setExamStream4] = useState<any>("");
  const [examStream5, setExamStream5] = useState<any>("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [resetExamStreamList, setResetExamStreamList] = useState(false);
  const [stateArr, setstateArr] = useState<any>(initialVal);

  useEffect(() => {
    dispatch(
      getExamStreamList(
        `${webinarData?.examType_data?._id}`,
        (res: any) => {
          setExamStreamList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeStatus = (val: any) => {
    setStatus(val);
  };

  const changeRemark = (val: any) => {
    setRemark(val);
  };

  const onChangeExamStream1 = (val: any) => {
    setExamStream1(val);
  };
  const onChangeExamStream2 = (val: any) => {
    setExamStream2(val);
  };
  const onChangeExamStream3 = (val: any) => {
    setExamStream3(val);
  };
  const onChangeExamStream4 = (val: any) => {
    setExamStream4(val);
  };
  const onChangeExamStream5 = (val: any) => {
    setExamStream5(val);
  };

  const handleImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 150 KB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setSelectedFile(reader.result);
        setUploadMailScreenshotData(file);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadMailScreenshot(
          file,
          (res: any) => {
            delete res?.filePath;
            setUploadMailScreenshotFile(res);
          },
          () => {}
        )
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (status?._id === 2) {
      const postData1: any = {
        status: 2,
        mailURL: uploadMailScreenshotFile?.imageLogo,
        rankers: [
          {
            firstName: fName1,
            lastName: lName1,
            phoneNo: contact1,
            email: email1,
            rank: "1",
            basecourseId: examStream1?._id,
          },
          {
            firstName: fName2,
            lastName: lName2,
            phoneNo: contact2,
            email: email2,
            rank: "2",
            basecourseId: examStream2?._id,
          },
          {
            firstName: fName3,
            lastName: lName3,
            phoneNo: contact3,
            email: email3,
            rank: "3",
            basecourseId: examStream3?._id,
          },
          {
            firstName: fName4,
            lastName: lName4,
            phoneNo: contact4,
            email: email4,
            rank: "4",
            basecourseId: examStream4?._id,
          },
          {
            firstName: fName5,
            lastName: lName5,
            phoneNo: contact5,
            email: email5,
            rank: "5",
            basecourseId: examStream5?._id,
          },
        ],
      };
      if (
        postData1?.rankers[0]?.email === "" ||
        postData1?.rankers[0]?.email === undefined ||
        postData1?.rankers[0]?.email === null
      ) {
        delete postData1?.rankers[0]?.email;
      }
      if (
        postData1?.rankers[1]?.email === "" ||
        postData1?.rankers[1]?.email === undefined ||
        postData1?.rankers[1]?.email === null
      ) {
        delete postData1?.rankers[1]?.email;
      }
      if (
        postData1?.rankers[2]?.email === "" ||
        postData1?.rankers[2]?.email === undefined ||
        postData1?.rankers[2]?.email === null
      ) {
        delete postData1?.rankers[2]?.email;
      }
      if (
        postData1?.rankers[3]?.email === "" ||
        postData1?.rankers[3]?.email === undefined ||
        postData1?.rankers[3]?.email === null
      ) {
        delete postData1?.rankers[3]?.email;
      }
      if (
        postData1?.rankers[4]?.email === "" ||
        postData1?.rankers[4]?.email === undefined ||
        postData1?.rankers[4]?.email === null
      ) {
        delete postData1?.rankers[4]?.email;
      }
      dispatch(
        updateCompletedWebinar(
          webinarId,
          postData1,
          (res: any) => {
            setStatus("");
            setResetWebStatus(true);
            setSelectedFile("");
            setFName1("");
            setLName1("");
            setContact1("");
            setEmail1("");
            setFName2("");
            setLName2("");
            setContact2("");
            setEmail2("");
            setFName3("");
            setLName3("");
            setContact3("");
            setEmail3("");
            setFName4("");
            setLName4("");
            setContact4("");
            setEmail4("");
            setFName5("");
            setLName5("");
            setContact5("");
            setEmail5("");
            setResetExamStreamList(true);
          },
          () => {}
        )
      );
    } else if (status?._id === 3) {
      const postData2 = {
        status: 3,
        remark: remark,
      };

      dispatch(
        updateCancelledWebinar(
          webinarId,
          postData2,
          (res: any) => {
            // setStatus("");
            setRemark("");
            setResetWebStatus(true);
          },
          () => {}
        )
      );
    } else if (status?._id === 4) {
      const postData3 = {
        remark: remark,
        webinarAt: dateTime,
      };
      dispatch(
        updateRescheduleWebinar(
          webinarId,
          postData3,
          (res: any) => {
            // setStatus("");
            setRemark("");
            setResetWebStatus(true);
            setDateTime("");
          },
          () => {}
        )
      );
    } else if (status?._id === 5) {
      const postData4 = {
        status: 5,
        mailURL: "",
        rankers: [
          {
            firstName: "",
            lastName: "",
            phoneNo: "",
            email: "",
            rank: "1",
            basecourseId: "",
          },
          {
            firstName: "",
            lastName: "",
            phoneNo: "",
            email: "",
            rank: "2",
            basecourseId: "",
          },
          {
            firstName: "",
            lastName: "",
            phoneNo: "",
            email: "",
            rank: "3",
            basecourseId: "",
          },
          {
            firstName: "",
            lastName: "",
            phoneNo: "",
            email: "",
            rank: "4",
            basecourseId: "",
          },
          {
            firstName: "",
            lastName: "",
            phoneNo: "",
            email: "",
            rank: "5",
            basecourseId: "",
          },
        ],
      };
      dispatch(
        updatePendingWebinar(
          webinarId,
          postData4,
          (res: any) => {
            // setStatus("");
            setRemark("");
            setDateTime("");
            setResetWebStatus(true);
          },
          () => {}
        )
      );
    }
  };
  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const validation1 = () => {
    return (
      status === "" ||
      // selectedFile === "" ||
      fName1 === "" ||
      lName1 === "" ||
      contact1 === "" ||
      contact1?.length < length_Ten ||
      examStream1 === "" ||
      fName2 === "" ||
      lName2 === "" ||
      contact2 === "" ||
      contact2?.length < length_Ten ||
      examStream2 === "" ||
      fName3 === "" ||
      lName3 === "" ||
      contact3 === "" ||
      contact3?.length < length_Ten ||
      examStream3 === "" ||
      fName4 === "" ||
      lName4 === "" ||
      contact4 === "" ||
      contact4?.length < length_Ten ||
      examStream4 === "" ||
      fName5 === "" ||
      lName5 === "" ||
      contact5 === "" ||
      contact5?.length < length_Ten ||
      examStream5 === ""
    );
  };

  const validation2 = () => {
    return status === "" || remark === "";
  };

  const validation3 = () => {
    return status === "" || dateTime === "" || remark === "";
  };

  // validations

  // Rank1
  const onChangeFirstName1 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName1(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName1 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName1(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact1 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact1(val?.toUpperCase());
      }
    } else {
      setContact1(val?.toUpperCase());
    }
  };

  const onChangeEmail1 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail1(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail1(val);
    }
  };

  //  Rank2

  const onChangeFirstName2 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName2(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName2 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName2(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact2 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val?.toUpperCase());
      }
    } else {
      setContact2(val?.toUpperCase());
    }
  };

  const onChangeEmail2 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail2(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail2(val);
    }
  };

  //  Rank3

  const onChangeFirstName3 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName3(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName3 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName3(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact3 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact3(val?.toUpperCase());
      }
    } else {
      setContact3(val?.toUpperCase());
    }
  };

  const onChangeEmail3 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail3(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail3(val);
    }
  };

  //  Rank4

  const onChangeFirstName4 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName4(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName4 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName4(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact4 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact4(val?.toUpperCase());
      }
    } else {
      setContact4(val?.toUpperCase());
    }
  };

  const onChangeEmail4 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail4(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail4(val);
    }
  };

  //  Rank5

  const onChangeFirstName5 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFName5(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeLastName5 = (val: any) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLName5(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const onChangeContact5 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact5(val?.toUpperCase());
      }
    } else {
      setContact5(val?.toUpperCase());
    }
  };

  const onChangeEmail5 = (val: any) => {
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail5(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail5(val);
    }
  };

  const onPressAdd = () => {
    setstateArr([...stateArr, initialVal[0]]);
  };

  const handleArrClose = (index: number) => {
    const newArrr = [...stateArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setstateArr(newArrr);
  };

  const onChangeCordinator = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(stateArr));
    if (key === "coName") {
      newArrr[index].coName = value;
      setstateArr(newArrr);
    } else if (key === "coPhone") {
      newArrr[index].coPhone = value;
      setstateArr(newArrr);
    } else if (key === "coEmail") {
      newArrr[index].coEmail = value;
      setstateArr(newArrr);
    }
  };

  return (
    <div className="col-md-12 maindiv">
      <div className="mt-2 ">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => navigate(-1)}
        >
          <ReplyIcon /> Back
        </button>
      </div>
      <div className="row m-0">
        <div className="col-md-12  font17 font10 p-5 pt-3 ">
          <div className="mx-5 detailsdiv boxshadow p-3">
            <div className="row d-flex justify-content-between font14 ">
              {/* ************************ Co-Ordinator Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Co-Ordinator</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Name:</div>
                  <div className="col-md-8">{webinarData?.coordinatorName}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Phone :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorPhone}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Email :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorEmail}
                  </div>
                </div>
              </div>
              {/* ************************Webinar Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Webinar Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Date :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Time :</div>
                  <div className="col-md-8">
                    {moment(webinarData?.webinarAt?.slice(0, 19)).format("LTS")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4 text-break">Link :</div>
                  {/* <div className='col-md-8'>{webinarData?.meetingURL}</div> */}
                  <div className="col-md-8 text-break">
                    <a
                      href={webinarData?.meetingURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {webinarData?.meetingURL}
                    </a>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Faculty :</div>
                  <div className="col-md-8">
                    {webinarData?.teacher_data?.name
                      ? webinarData?.teacher_data?.name
                      : "-"}
                  </div>
                </div>
              </div>

              {/* ************************Collage Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Collage Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Collage :</div>
                  <div className="col-md-8">
                    {webinarData?.college_data?.collegeName}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">State :</div>
                  <div className="col-md-8">
                    {webinarData?.state_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">City :</div>
                  <div className="col-md-8">{webinarData?.city_data?.name}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Exam :</div>
                  <div className="col-md-8">
                    {webinarData?.examType_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Department :</div>
                  <div className="col-md-8 ">
                    {webinarData?.department_data?.departmentName}
                  </div>
                </div>
              </div>

              {/* *********************************Lead Details *************************************** */}

              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Lead Details:</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Lead Registered :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.TotalCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">First Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.FirstYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Second Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.SecondYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Third Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.ThirdYearCount}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom border-3 border-dark">
              <h5 className="text-primary fw-bold">Webinar Status</h5>
            </div>
            {/* *************************************************Status****************************************************** */}
            <form className="col-md-12 mt-4" onSubmit={handleSubmit}>
              <div className="col-md-9 mt-4">
                <div className="col-md-6 row d-flex justify-content-between mt-1">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Status" />
                  </div>
                  <div className="col-md-7  ps-3">
                    <CustomDropdown
                      lableName="Select Status"
                      setInputText={(value: any) => onChangeStatus(value)}
                      value={status}
                      options={webinarStatus}
                      reset={ResetWebStatus}
                    />
                  </div>
                </div>
                {status?._id === 4 ? (
                  <div className="col-md-6 row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Date & Time" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <DateTime
                        value={dateTime}
                        setInputText={(value: any) => setDateTime(value)}
                      />
                    </div>
                  </div>
                ) : null}
                {status?._id === 3 || status?._id === 4 ? (
                  <div className="col-md-6 row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Remark" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <textarea
                        className="form-control"
                        placeholder={"Remark"}
                        value={remark}
                        onChange={(e) => changeRemark(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                ) : null}
                {status?._id === 2 || status?._id === 5 ? (
                  //  <div className="col-md-6 row d-flex justify-content-between mt-2">
                  //     <div className="col-md-4 mt-2">
                  //       <LabelField lableName="Mail ScreenShot" />
                  //     </div>
                  //     <div className="col-md-7 ps-3  ">
                  //       <input type="file" id="myFile" name="filename" className="form-control" />
                  //     </div>
                  //   </div>
                  <div className="row d-flex justify-content-between">
                    <div className="d-flex">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Mail ScreenShot" />
                      </div>
                      <div className="col-md-8">
                        <ImagePickerAndViewer
                          lableName={"Upload Sample EXCEL"}
                          value={selectedFile}
                          removelabel={true}
                          htmlFor={`photo-upload`}
                          handleChange={(e: any) => handleImageChange(e)}
                          disabled={status?._id === 5}
                        />
                        <label className="col-md-8">
                          {uploadMailScreenshotData?.name
                            ? uploadMailScreenshotData?.name
                            : ""}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {status?._id === 2 || status?._id === 5 ? (
                <>
                  <div className="pt-4">
                    <h5 className="fw-bold text-center">Topper List</h5>
                    <div className="col-md-12 row d-flex justify-content-between">
                      <div className="col-md-1 fw-bold pt-3">Rank 1</div>
                      <div className="col-md-11 row d-flex justify-content-between px-0">
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="First Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter First Name"
                              value={fName1}
                              onChangeInput={(value: any) =>
                                onChangeFirstName1(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Last Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Last Name"
                              value={lName1}
                              onChangeInput={(value: any) =>
                                onChangeLastName1(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Phone no" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Phone no"
                              value={contact1}
                              maxlength={10}
                              minlength={10}
                              onChangeInput={(value: any) =>
                                onChangeContact1(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Email" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Email"
                              value={email1}
                              onChangeInput={(value: any) =>
                                onChangeEmail1(value)
                              }
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-4  pt-2 px-0 ">
                            <LabelField lableName="Subject Name" />
                          </div>
                          <div className="col-md-8">
                            <CustomDropdown
                              lableName="Select Subject Name"
                              setInputText={(value: any) =>
                                onChangeExamStream1(value)
                              }
                              value={examStream1}
                              options={examStreamList}
                              disabled={status?._id === 5}
                              Isrequired={true}
                              reset={resetExamStreamList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 row d-flex justify-content-between">
                      <div className="col-md-1 fw-bold pt-3">Rank 2</div>
                      <div className="col-md-11 row d-flex justify-content-between px-0">
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="First Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter First Name"
                              value={fName2}
                              onChangeInput={(value: any) =>
                                onChangeFirstName2(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Last Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Last Name"
                              value={lName2}
                              onChangeInput={(value: any) =>
                                onChangeLastName2(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Phone no" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Phone no"
                              value={contact2}
                              maxlength={10}
                              onChangeInput={(value: any) =>
                                onChangeContact2(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Email" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Email"
                              value={email2}
                              onChangeInput={(value: any) =>
                                onChangeEmail2(value)
                              }
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-4  pt-2 px-0 ">
                            <LabelField lableName="Subject Name" />
                          </div>
                          <div className="col-md-8">
                            <CustomDropdown
                              lableName="Select Subject Name"
                              setInputText={(value: any) =>
                                onChangeExamStream2(value)
                              }
                              value={examStream2}
                              options={examStreamList}
                              Isrequired={true}
                              disabled={status?._id === 5}
                              reset={resetExamStreamList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 row d-flex justify-content-between">
                      <div className="col-md-1 fw-bold pt-3">Rank 3</div>
                      <div className="col-md-11 row d-flex justify-content-between px-0">
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="First Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter First Name"
                              value={fName3}
                              onChangeInput={(value: any) =>
                                onChangeFirstName3(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Last Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Last Name"
                              value={lName3}
                              onChangeInput={(value: any) =>
                                onChangeLastName3(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Phone no" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Phone no"
                              value={contact3}
                              maxlength={10}
                              onChangeInput={(value: any) =>
                                onChangeContact3(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Email" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Email"
                              value={email3}
                              onChangeInput={(value: any) =>
                                onChangeEmail3(value)
                              }
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-4  pt-2 px-0 ">
                            <LabelField lableName="Subject Name" />
                          </div>
                          <div className="col-md-8">
                            <CustomDropdown
                              lableName="Select Subject Name"
                              setInputText={(value: any) =>
                                onChangeExamStream3(value)
                              }
                              value={examStream3}
                              options={examStreamList}
                              Isrequired={true}
                              disabled={status?._id === 5}
                              reset={resetExamStreamList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 row d-flex justify-content-between">
                      <div className="col-md-1 fw-bold pt-3">Rank 4</div>
                      <div className="col-md-11 row d-flex justify-content-between px-0">
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="First Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter First Name"
                              value={fName4}
                              onChangeInput={(value: any) =>
                                onChangeFirstName4(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Last Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Last Name"
                              value={lName4}
                              onChangeInput={(value: any) =>
                                onChangeLastName4(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Phone no" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Phone no"
                              value={contact4}
                              maxlength={10}
                              onChangeInput={(value: any) =>
                                onChangeContact4(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Email" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Email"
                              value={email4}
                              onChangeInput={(value: any) =>
                                onChangeEmail4(value)
                              }
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-4  pt-2 px-0 ">
                            <LabelField lableName="Subject Name" />
                          </div>
                          <div className="col-md-8">
                            <CustomDropdown
                              lableName="Select Subject Name"
                              setInputText={(value: any) =>
                                onChangeExamStream4(value)
                              }
                              value={examStream4}
                              options={examStreamList}
                              Isrequired={true}
                              disabled={status?._id === 5}
                              reset={resetExamStreamList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 row d-flex justify-content-between">
                      <div className="col-md-1 fw-bold pt-3">Rank 5</div>
                      <div className="col-md-11 row d-flex justify-content-between px-0">
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="First Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter First Name"
                              value={fName5}
                              onChangeInput={(value: any) =>
                                onChangeFirstName5(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Last Name" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Last Name"
                              value={lName5}
                              onChangeInput={(value: any) =>
                                onChangeLastName5(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                              maxlength={length_Fifty - 1}
                              minlength={length_Two}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Phone no" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Phone no"
                              value={contact5}
                              maxlength={10}
                              onChangeInput={(value: any) =>
                                onChangeContact5(value)
                              }
                              isRequired={true}
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-3  pt-2 px-0 ">
                            <LabelField lableName="Email" />
                          </div>
                          <div className="col-md-9">
                            <InputField
                              placeholder="Enter Email"
                              value={email5}
                              onChangeInput={(value: any) =>
                                onChangeEmail5(value)
                              }
                              disabled={status?._id === 5}
                            />
                          </div>
                        </div>
                        <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                          <div className="col-md-4  pt-2 px-0 ">
                            <LabelField lableName="Subject Name" />
                          </div>
                          <div className="col-md-8">
                            <CustomDropdown
                              lableName="Select Subject Name"
                              setInputText={(value: any) =>
                                onChangeExamStream5(value)
                              }
                              value={examStream5}
                              options={examStreamList}
                              Isrequired={true}
                              disabled={status?._id === 5}
                              reset={resetExamStreamList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  {/* <div className="mt-4 col-md-12  ">
                    <h5 className="fw-bold text-center">Co-Ordinator Details</h5>
                    {stateArr?.map((item: any, index: number) => {
                      const { _id, coName, coPhone, coEmail } = item;
                      return (

                        <div className="row d-flex justify-content-between">

                          <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2">

                            <div className="col-md-4  pt-2 ">
                              <LabelField lableName="Coordinator Name" />
                            </div>
                            <div className="col-md-8">
                              <InputField
                                placeholder="Enter Name"
                                value={coName}
                                onChangeInput={(value: any) =>
                                  onChangeCordinator(index, value, "coName")
                                }
                                disabled={status?._id === 5}
                                maxlength={length_Fifty - 1}
                                minlength={length_Two}
                              />
                            </div>
                          </div>
                          <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 ">
                            <div className="col-md-4  pt-2">
                              <LabelField lableName="Coordinator Phone No." />
                            </div>
                            <div className="col-md-8">
                              <InputField
                                placeholder="Enter Name"
                                value={coPhone}
                                onChangeInput={(value: any) =>
                                  onChangeCordinator(index, value, "coPhone")
                                }
                                disabled={status?._id === 5}
                                maxlength={length_Ten}
                                minlength={length_Ten}
                              />
                            </div>
                          </div>
                          <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2">
                            <div className="col-md-4  pt-2">
                              <LabelField lableName="Coordinator Email" />
                            </div>
                            <div className="col-md-8">
                              <InputField
                                placeholder="Enter Name"
                                value={coEmail}
                                onChangeInput={(value: any) =>
                                  onChangeCordinator(index, value, "coEmail")
                                }
                                disabled={status?._id === 5}
                                maxlength={length_Fifty - 1}
                                minlength={length_Two}
                              />
                            </div>
                          </div>

                          <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2">
                            <span className="d-flex justify-content-end removeButton">
                              {stateArr.length === 1 ? null : (
                                <HighlightOffIcon
                                  className="cursor"
                                  color="primary"
                                  fontSize="small"
                                  onClick={() => handleArrClose(index)}
                                />
                              )}
                            </span>
                            <div className="col-md-8">

                              <Button name={"Add More"} handleClick={onPressAdd} />
                            </div>
                          </div>

                        </div>
                      );
                    })}
                  </div> */}
                </>
              ) : null}
              <div className=" col-md-12 text-center mb-2  mt-2 me-4 ">
                <SubmitButton
                  name={"Submit"}
                  validation={
                    status?._id === 2
                      ? validation1()
                      : status?._id === 3
                      ? validation2()
                      : status?._id === 4
                      ? validation3()
                      : false
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
