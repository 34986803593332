import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import DatePicker from "../../Component/datePicker";
import moment from "moment";
import {
  getEmployeetByManagerId,
  getMonthlyReport,
} from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
export default function MonthlyReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState(false);
  const [counsellor, setCounsellor] = useState<any>("");
  const [counsellorList, setCounsellorList] = useState([]);
  const [resetCounsellor, setResetCounsellor] = useState(false);
  const [monthlyReportList, setMonthlyReportList] = useState([]);
  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
      };
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    if (loggedInUserRole === 1) {
      dispatch(
        getEmployeetByManagerId(
          managerId,
          (res: any) => {
            setTeamLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getEmployeetByManagerId(
          managerId,
          (res: any) => {
            setCounsellorList(res?.data);
          },
          () => {}
        )
      );
    }
  }, []);

  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);
    setResetCounsellor(true);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
    // const data = {
    //   startDate: startDate,
    //   endDate:endDate,
    //   _id:val?._id,
    // };
    // dispatch(
    //   getMonthlyReport(
    //     data,
    //       (res: any) => {
    //         setMonthlyReportList(res?.data?.attendance)
    //       },
    //       () => { }
    //     )
    //   );
  };
  const onChangeCounsellor = (val: any) => {
    setCounsellor(val?._id);
    let data = {};
    if (loggedInUserRole === 1) {
      data = {
        startDate: startDate,
        endDate: endDate,
        managerId: teamLead?._id,
        _id: val?._id,
      };
    } else if (loggedInUserRole === 2) {
      data = {
        startDate: startDate,
        endDate: endDate,
        managerId,
        _id: val?._id,
      };
    }

    dispatch(
      getMonthlyReport(
        data,
        (res: any) => {
          setMonthlyReportList(res?.data?.attendance);
          setCount(res?.data?.count);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <>
        <form className="row d-flex  ">
          <div className="col-md-4 d-flex mb-3 me-2 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3 me-2">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
              />
            </div>
          </div>

          {loggedInUserRole === 1 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Select Team Lead" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Team Lead"
                  setInputText={(value: any) => onChangeTeamLead(value)}
                  value={teamLead}
                  options={teamLeadList}
                  Isrequired={true}
                  reset={resetTeamLead}
                />
              </div>
            </div>
          ) : null}
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Select Counsellor" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Counsellor"
                setInputText={(value: any) => onChangeCounsellor(value)}
                value={counsellor}
                options={counsellorList}
                Isrequired={true}
                reset={resetCounsellor}
              />
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {monthlyReportList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"TeamLeadManageEmployee"}
            count={count}
            tableData={monthlyReportList ? monthlyReportList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={monthlyReportList ? monthlyReportList : []}
          pagename={"MonthlyReport"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={["S.N", "Date", "Name", "Status", "Logout Time"]}
          // handleClick={onClickLead}
        />
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Monthly Report"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth pt-2 p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
