
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'; 
import SubmitButton from '../../Component/submitButton';
import InputField from '../../Component/inputField';
import { containsNumber, containsNumberDecimal, length_One, length_Ten, length_Three, validateDecimalNumber, validateValueWithoutSpace } from '../../DATA/validators'; 
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg'; 
import { getGlobalIncentiveAllocation ,updateGlobalIncentiveAllocaton} from '../../Redux/Actions/bdeAdminPanelAction';


export default function ManagerTLIncentiveAllocation() {
  const dispatch = useDispatch(); 
  const [incentiveId, setIncentiveId] = useState<any>("");
  const [bronzeMgIncentive, setBronzeMgIncentive] = useState<any>("");
  const [silverMgIncentive, setSilverMgIncentive] = useState<any>("");
  const [goldMgIncentive, setGoldMgIncentive] = useState<any>("");
  const [platinumMgIncentive, setPlatinumMgIncentive] = useState<any>("");

  const [bronzeTeamIncentive, setBronzeTeamIncentive] = useState<any>("");
  const [silverTeamIncentive, setSilverTeamIncentive] = useState<any>("");
  const [goldTeamIncentive, setGoldTeamIncentive] = useState<any>("");
  const [platinumTeamIncentive, setPlatinumTeamIncentive] = useState<any>("");
 
  useEffect(() => {
    getIncentives();
  }, []);

  const getIncentives = () => {
    dispatch(
      getGlobalIncentiveAllocation(
        "",
        (res: any) => {
          setIncentiveId(res?._id);
          setBronzeMgIncentive(res?.manager_bronze_level);
          setSilverMgIncentive(res?.manager_silver_level);
          setGoldMgIncentive(res?.manager_gold_level);
          setPlatinumMgIncentive(res?.manager_platinum_level);
          setBronzeTeamIncentive(res?.teamLead_bronze_level);
          setSilverTeamIncentive(res?.teamLead_silver_level);
          setGoldTeamIncentive(res?.teamLead_gold_level);
          setPlatinumTeamIncentive(res?.teamLead_platinum_level);
        },
        () => { }
      )
    );
  }
 

  const validateMinMaxLength = (
    value:any,
    maxValidationLength:any
  ) => {
    const isValid = validateMaxLength(value, maxValidationLength);
    return isValid;
  };

  const validateMaxLength = (value:any, maxValidationLength:any) => {
    const errorMsg = `Length greater than ${maxValidationLength} not allowed`;
    if (maxValidationLength != null && value.length > maxValidationLength) {
      dispatch(showErrorSnackbar(errorMsg) as any);
      return false;
    }
    return true;
  };

  const onchangeBronzeMgIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setBronzeMgIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setBronzeMgIncentive("");
  };

  const onchangeSilverMgIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setSilverMgIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setSilverMgIncentive("");
  }

  const onchangeGoldMgIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setGoldMgIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setGoldMgIncentive("");
  }

  const onchangePlatinumMgIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setPlatinumMgIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setPlatinumMgIncentive("");
  }

  const onchangeBronzeTeamIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setBronzeTeamIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setBronzeTeamIncentive("");
  }

  const onchangeSilverTeamIncentive = (val: any) => {

    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setSilverTeamIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setSilverTeamIncentive("");
  }

  const onchangeGoldTeamIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setGoldTeamIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setGoldTeamIncentive("");
  }

  const onchangePlatinumTeamIncentive = (val: any) => {
    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setPlatinumTeamIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setPlatinumTeamIncentive("");
  }
 
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData = {
      manager_bronze_level: bronzeMgIncentive,
      manager_silver_level: silverMgIncentive,
      manager_gold_level: goldMgIncentive,
      manager_platinum_level: platinumMgIncentive,
      teamLead_bronze_level: bronzeTeamIncentive,
      teamLead_silver_level: silverTeamIncentive,
      teamLead_gold_level: goldTeamIncentive,
      teamLead_platinum_level: platinumTeamIncentive,
    };
    dispatch(
      updateGlobalIncentiveAllocaton(
        incentiveId,
        postData,
        (res: any) => {
          getIncentives()
        },
        () => { }
      )
    );
  };



  const renderAddIncentiveMode = () => {
    return (
      <>
        <form className="col-md-12 row"  onSubmit={handleSubmit}>

          <div className="row">
            <h3 className="fw-bold"> Manager Allocation</h3>
            <h3 className="text-primary font-monospace"> Target</h3>
            <div className="row d-flex">
              <div className="row">
                <div className="col-4">
                  <p className="fw-bold">Target Level:</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Target Ratio :</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Bronze Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={bronzeMgIncentive}
                    onChangeInput={(value: any) =>
                      onchangeBronzeMgIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Silver Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={silverMgIncentive}
                    onChangeInput={(value: any) =>
                      onchangeSilverMgIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Gold Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={goldMgIncentive}
                    onChangeInput={(value: any) => onchangeGoldMgIncentive(value)}
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Platinum Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={platinumMgIncentive}
                    onChangeInput={(value: any) =>
                      onchangePlatinumMgIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* *****************************************Team Lead*************************************** */}

          <div className="row">
            <h3 className="fw-bold"> Team Lead Allocation</h3>
            <h3 className="text-primary font-monospace"> Target</h3>
            <div className="row d-flex">
              <div className="row">
                <div className="col-4">
                  <p className="fw-bold">Target Level:</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Target Ratio :</p>
                </div>
              </div>
           
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Bronze Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={bronzeTeamIncentive}
                    onChangeInput={(value: any) =>
                      onchangeBronzeTeamIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Silver Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={silverTeamIncentive}
                    onChangeInput={(value: any) =>
                      onchangeSilverTeamIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Gold Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={goldTeamIncentive}
                    onChangeInput={(value: any) => onchangeGoldTeamIncentive(value)}
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Platinum Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={platinumTeamIncentive}
                    onChangeInput={(value: any) =>
                      onchangePlatinumTeamIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className="d-flex justify-content-center mx-1">
              <SubmitButton name={"Add Incentive"} />
            </div>
          </div>

        </form>
      </>
    );
  };

  return (
    <div className="contentBox">
      {/* <PageTitle name={ "Team Lead Allocation" }  /> */}
      <div className='col-md-12 px-4 mx-0 mt-4 formDiv '>
        <div className='justify-content-start '  >

          {renderAddIncentiveMode()}



        </div>


      </div>
    </div>
  )
}

