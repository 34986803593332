import { useEffect, useState } from "react";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getTargetStatus } from "../../Redux/Actions/counsellorPanelAction";
import { dateFormat } from "../../DATA/dataConstant";
import "../../App.css";
import { getBDETargetStatus } from "../../Redux/Actions/bdExecutivePanelAction";
import SubmitButton from "../../Component/submitButton";

export default function TargetStatus() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [resetTargetList, setResetTargetList] = useState<any>([]);

  const { executiveID } = useSelector((state: any) => ({
    executiveID: state.Auth.executiveID,
  }));

  useEffect(() => {
    const postData = {
      bdeId: executiveID,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getBDETargetStatus(
        postData,
        (res: any) => {
          setResetTargetList(res);
        },
        () => {}
      )
    );
  }, []);

  const onEndDateChange = (val: any) => {
    SetEndDate(val);
    const postData = {
      bdeId: executiveID,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getBDETargetStatus(
        postData,
        (res: any) => {
          setResetTargetList(res);
        },
        () => {}
      )
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      bdeId: executiveID,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getBDETargetStatus(
        postData,
        (res: any) => {
          setResetTargetList(res);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        {resetTargetList?.map((value: any, index: any) => {
          return (
            <div>
              <div className="d-flex mt-5 justify-content-center ">
                <p className="fw-bold fs-5">
                  Target Start Date :{" "}
                  <span>
                    {moment(value.startDate).utc().format("DD-MM-YYYY")}{" "}
                  </span>
                </p>
                <p className="fw-bold fs-5 ms-5">
                  Target End Date :{" "}
                  <span>
                    {moment(value.endDate).utc().format("DD-MM-YYYY")}
                  </span>
                </p>
              </div>
              <div className="row d-flex justify-content-between ">
                <div className="col-md-2 text-center  ">
                  <div className=" boxshadow rounded py-3 ">
                    <h4 className=" ">Lead Generated</h4>
                    <h2 className="fw-bold">
                      {value?.achievedLeads ? value?.achievedLeads : 0}
                    </h2>
                  </div>
                </div>
                <div className="col-md-2 text-center  ">
                  <div className=" boxshadow rounded py-3 ">
                    <h4 className=" ">CSIR NET</h4>
                    <h2 className="fw-bold">{value?.examType_CSIR_NET}</h2>
                  </div>
                </div>
                <div className="col-md-2 text-center  ">
                  <div className=" boxshadow rounded py-3 ">
                    <h4 className=" ">IIT JAM</h4>
                    <h2 className="fw-bold">{value?.examType_IIT_JAM}</h2>
                  </div>
                </div>
                <div className="col-md-2 text-center  ">
                  <div className=" boxshadow rounded py-3 ">
                    <h4 className=" ">UGC NET</h4>
                    <h2 className="fw-bold">{value?.examType_UGC_NET}</h2>
                  </div>
                </div>
                <div className="col-md-2 text-center  ">
                  <div className=" boxshadow rounded py-3 ">
                    <h4 className="fw-bold">Department</h4>
                    <div className="row d-flex justify-content-between text-center">
                      <div className="col-md-6 ">
                        <h5 className=" ">Added</h5>
                        <h2 className="fw-bold">{value?.achievedDepartment}</h2>
                      </div>
                      <div className="col-md-6">
                        <h5 className=" ">Target</h5>
                        <h2 className="fw-bold">{value?.DepartmentTarget}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mx-3 mt-4">
                <p className="fw-bold">
                  Target Achieved :{" "}
                  <span>{value?.achievedLeads ? "YES" : "NO"} </span>
                </p>

                <p className="fw-bold">
                  Incentive Achieved :{" "}
                  <span> {value?.Incentive_Amount ? "YES" : "NO"}</span>
                </p>
              </div>
              <table className="w-100">
                <tr>
                  <th>Target Level </th>
                  <th>Start Lead</th>
                  <th>End Lead</th>
                  <th>Achieved Leads</th>
                  <th>Incentive Amount</th>
                  <th>Max Incentive</th>
                </tr>
                <tbody>
                  {value?.target?.map((data: any, index: any) => {
                    return (
                      <tr>
                        <td className="fw-bold">
                          {data?.level === "bronze_level"
                            ? "Bronze Level"
                            : data?.level === "silver_level"
                            ? "Silver Level"
                            : data?.level === "gold_level"
                            ? "Gold Level"
                            : "Platinum Level"}{" "}
                        </td>

                        <td>{data?.startLead}</td>
                        <td>{data?.endLead}</td>
                        <td>{data?.achievedLeads}</td>
                        <td>{data?.Incentive_Amount}</td>
                        <td>{data?.Max_Incentive}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Target Status"} />
      <div className=" formDiv  ">
        <div className="col-md-12 formWidth">
          <div className="row col-md-12 p-3  ">
            <form className="d-flex" onSubmit={handleSearch}>
              <div className="col-md-4 d-flex mb-3 me-2">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Start Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => SetStartDate(value)}
                    maxdate={moment().startOf("month").format("YYYY-MM-DD")}
                  />
                </div>
              </div>

              <div className="col-md-4 d-flex mb-3 me-5">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="End Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => onEndDateChange(value)}
                    // Isrequired={true}
                    
                  />
                </div>
              </div>
              <div>
                <SubmitButton name="Search" />
              </div>
            </form>

            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
