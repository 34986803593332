import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import SubmitButton from "../../Component/submitButton";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots, experienceList } from "../../DATA/dataConstant";
import InputField from "../../Component/inputField";
import {
  containsNumber,
  containsNumberDecimal,
  length_One,
  length_Ten,
  length_Three,
  validateDecimalNumber,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  addIncentive,
  deleteIncentive,
  editIncentiveNewDate,
  getEmployeeRoleList,
  getSourceTypeList,
  incentiveAllocationList,
  updateIncentive,
} from "../../Redux/Actions/leadManagementAction";
import Button from "../../Component/button";
import RestorePopup from "../../Component/restorePopup";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import moment from "moment";

export default function SMIncentiveAllocation() {
  const dispatch = useDispatch();
  const [searchStartDate, SetSearchStartDate] = useState<any>("");
  const [searchEndDate, SetSearchEndDate] = useState<any>("");
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [startDateNew, SetStartDateNew] = useState<any>("");
  const [endDateNew, SetEndDateNew] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examSearch, setExamSearch] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [examStream, setExamStream] = useState<any>("");
  const [examStreamSearch, setExamStreamSearch] = useState<any>("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [openAddIncentive, setResetopenAddIncentive] = useState(false);
  const [counsellor, setCounsellor] = useState<any>("");
  const [counsellorList, setCounsellorList] = useState([]);
  const [resetCounSellor, setResetCounSellor] = useState(false);
  const [experience, setExperience] = useState<any>("");
  const [resetExperience, setResetExperience] = useState(false);
  const [bronzePercentage, setBronzePercentage] = useState<any>("");
  const [bronzeIncentive, setBronzeIncentive] = useState<any>("");
  const [silverPercentage, setSilverPercentage] = useState<any>("");
  const [silverIncentive, setSilverIncentive] = useState<any>("");
  const [goldPercentage, setGoldPercentage] = useState<any>("");
  const [goldIncentive, setGoldIncentive] = useState<any>("");
  const [platinumPercentage, setPlatinumPercentage] = useState<any>("");
  const [platinumIncentive, setPlatinumIncentive] = useState<any>("");

  const [incentiveListData, setIncentiveListData] = useState<any>("");
  const [isEditMode, setisEditMode] = useState(false);
  const [isEditModeNew, setisEditModeNew] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [incentiveId, setIncentiveId] = useState<any>("");

  const [leadSource, setLeadSource] = useState<any>("");
  const [sourceList, setSourceList] = useState([]);
  const [resetLeadSource, setResetLeadSource] = useState(false);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    // getList();
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getEmployeeRoleList((res: any) => {
        let tempArr = res?.filter(
          (item: any) => item?._id !== 1 && item?._id !== 2
        );
        setCounsellorList(tempArr);
      })
    );
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
      })
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: searchStartDate,
        endDate: searchEndDate,
        baseCourseId: examStream?._id,
        examTypeId: exam?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: searchStartDate,
        endDate: searchEndDate,
        baseCourseId: examStream?._id,
        examTypeId: exam?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: searchStartDate,
        endDate: searchEndDate,
        baseCourseId: examStream?._id,
        examTypeId: exam?._id,
      };
    }

    dispatch(
      incentiveAllocationList(data, (res: any) => {
        setCount(res?.count);
        setIncentiveListData(res?.data);
      })
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
  };
  const onChangeExam = (val: any) => {
    setExam(val);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamSearch = (val: any) => {
    setExamSearch(val);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };
  const onChangeExamStreamSearch = (val: any) => {
    setExamStreamSearch(val);
  };

  const handleOpenAddIncentive = () => {
    setResetopenAddIncentive(true);
  };

  const getList = () => {
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      startDate: searchStartDate,
      endDate: searchEndDate,
      baseCourseId: examStreamSearch?._id,
      examTypeId: examSearch?._id,
      counsellorType: counsellor,
    };

    if (postData["examTypeId"] === "" || postData["examTypeId"] === undefined) {
      delete postData["examTypeId"];
    }
    if (
      postData["baseCourseId"] === "" ||
      postData["baseCourseId"] === undefined
    ) {
      delete postData["baseCourseId"];
    }
    if (
      postData["counsellorType"] === "" ||
      postData["counsellorType"] === undefined
    ) {
      delete postData["counsellorType"];
    }
    dispatch(
      incentiveAllocationList(postData, (res: any) => {
        setCount(res?.count);
        setIncentiveListData(res?.data);
      })
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    getList();
  };
  const onChangeCounsellor = (val: any) => {
    setCounsellor(val?._id);
  };
  const onChangeExperience = (val: any) => {
    setExperience(val?._id);
  };

  const validateMinMaxLength = (value: any, maxValidationLength: any) => {
    const isValid = validateMaxLength(value, maxValidationLength);
    return isValid;
  };

  const validateMaxLength = (value: any, maxValidationLength: any) => {
    const errorMsg = `Length greater than ${maxValidationLength} not allowed`;
    if (maxValidationLength != null && value.length > maxValidationLength) {
      dispatch(showErrorSnackbar(errorMsg) as any);
      return false;
    }
    return true;
  };

  const onChangeBronzePercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setBronzePercentage(val);
      }
    } else {
      setBronzePercentage(val);
    }
  };
  const onchangeBronzeIncentive = (val: any) => {
    // if (val !== "") {
    //   if (!containsNumber(val)) {
    //     dispatch(showErrorSnackbar("Please enter number only") as any);
    //     return;
    //   } else if (!validateValueWithoutSpace(val)) {
    //     dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
    //     return;
    //   } else {
    //     setBronzeIncentive(val);
    //   }
    // } else {
    //   setBronzeIncentive(val);
    // }

    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setBronzeIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setBronzeIncentive("");
  };

  const onChangeSilverPercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setSilverPercentage(val);
      }
    } else {
      setSilverPercentage(val);
    }
  };
  const onchangeSilverIncentive = (val: any) => {
    // if (val !== "") {
    //   if (!containsNumber(val)) {
    //     dispatch(showErrorSnackbar("Please enter number only") as any);
    //     return;
    //   } else if (!validateValueWithoutSpace(val)) {
    //     dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
    //     return;
    //   } else {
    //     setSilverIncentive(val);
    //   }
    // } else {
    //   setSilverIncentive(val);
    // }

    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setSilverIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setSilverIncentive("");
  };

  const onChangeGoldPercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setGoldPercentage(val);
      }
    } else {
      setGoldPercentage(val);
    }
  };
  const onchangeGoldIncentive = (val: any) => {
    // if (val !== "") {
    //   if (!containsNumber(val)) {
    //     dispatch(showErrorSnackbar("Please enter number only") as any);
    //     return;
    //   } else if (!validateValueWithoutSpace(val)) {
    //     dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
    //     return;
    //   } else {
    //     setGoldIncentive(val);
    //   }
    // } else {
    //   setGoldIncentive(val);
    // }

    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setGoldIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setGoldIncentive("");
  };

  const onChangePlatinumPercentage = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPlatinumPercentage(val);
      }
    } else {
      setPlatinumPercentage(val);
    }
  };
  const onchangePlatinumIncentive = (val: any) => {
    // if (val !== "") {
    //   if (!containsNumber(val)) {
    //     dispatch(showErrorSnackbar("Please enter number only") as any);
    //     return;
    //   } else if (!validateValueWithoutSpace(val)) {
    //     dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
    //     return;
    //   } else {
    //     setPlatinumIncentive(val);
    //   }
    // } else {
    //   setPlatinumIncentive(val);
    // }

    if (val !== "") {
      if (!containsNumberDecimal(val)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateDecimalNumber(val)) {
        return;
      } else {
        setPlatinumIncentive(val);
        return;
      }
    }
    const isValid = validateMinMaxLength(val, length_Three - 1);
    if (!isValid) return;
    setPlatinumIncentive("");
  };

  const AddIncentive = (e: any) => {
    e.preventDefault();
    setResetopenAddIncentive(false);
    const PostData = {
      startDate: startDate,
      endDate: endDate,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      counsellorType: counsellor,
      experience: experience,
      type: leadSource?._id,
      target: {
        bronze_level: {
          percent: bronzePercentage,
          incentivetarget: bronzeIncentive,
        },
        silver_level: {
          percent: silverPercentage,
          incentivetarget: silverIncentive,
        },
        gold_level: {
          percent: goldPercentage,
          incentivetarget: goldIncentive,
        },
        platinum_level: {
          percent: platinumPercentage,
          incentivetarget: platinumIncentive,
        },
      },
    };

    dispatch(
      addIncentive(PostData, (res: any) => {
        getList();
      })
    );
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setIncentiveId(value?._id);
    SetStartDate(value?.startDate?.substring(0, 10));
    SetEndDate(value?.endDate?.substring(0, 10));
    setExam(value?.examTypeId?._id);
    setExamStream(value?.baseCourseId?._id);
    setCounsellor(value?.counsellorType?._id);
    setExperience(value?.experience);
    setLeadSource(value?.type);
    setBronzePercentage(value?.target?.bronze_level?.percent);
    setBronzeIncentive(value?.target?.bronze_level?.incentivetarget);
    setSilverPercentage(value?.target?.silver_level?.percent);
    setSilverIncentive(value?.target?.silver_level?.incentivetarget);
    setGoldPercentage(value?.target?.gold_level?.percent);
    setGoldIncentive(value?.target?.gold_level?.incentivetarget);
    setPlatinumPercentage(value?.target?.platinum_level?.percent);
    setPlatinumIncentive(value?.target?.platinum_level?.incentivetarget);
  };

  const handleEditNew = (value: any) => {
    setisEditModeNew(true);
  };

  const handleDelete = (value: any) => {
    setOpenDeletePopup(true);
    setIncentiveId(value?._id);
  };

  const handleSubmitDelete = () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteIncentive(incentiveId, () => {
        getList();
      })
    );
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };

  const cancelAddIncentive = () => {
    setResetopenAddIncentive(false);
    setisEditMode(false);
    setisEditModeNew(false);
  };

  const cancelEditIncentive = () => {
    setisEditMode(false);
  };

  const cancelEditNew = () => {
    setisEditModeNew(false);
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      counsellorType: counsellor,
      experience: experience,
      type: leadSource?._id,
      target: {
        bronze_level: {
          percent: bronzePercentage,
          incentivetarget: bronzeIncentive,
        },
        silver_level: {
          percent: silverPercentage,
          incentivetarget: silverIncentive,
        },
        gold_level: {
          percent: goldPercentage,
          incentivetarget: goldIncentive,
        },
        platinum_level: {
          percent: platinumPercentage,
          incentivetarget: platinumIncentive,
        },
      },
    };
    dispatch(
      updateIncentive(incentiveId, postData, () => {
        setisEditMode(false);
        setResetExam(true);
        setResetExamStream(true);
        setResetCounSellor(true);
        setResetExperience(true);
        setBronzeIncentive("");
        setSilverIncentive("");
        setGoldIncentive("");
        setPlatinumIncentive("");
        setResetLeadSource(true);
        const postData = {
          skip: 0,
          limit: rowsPerPage,
          startDate: startDate,
          endDate: endDate,
          baseCourseId: examStreamSearch?._id,
          examTypeId: examSearch?._id,
          counsellorType: counsellor,
        };

        if (
          postData["examTypeId"] === "" ||
          postData["examTypeId"] === undefined
        ) {
          delete postData["examTypeId"];
        }
        if (
          postData["baseCourseId"] === "" ||
          postData["baseCourseId"] === undefined
        ) {
          delete postData["baseCourseId"];
        }
        if (
          postData["counsellorType"] === "" ||
          postData["counsellorType"] === undefined
        ) {
          delete postData["counsellorType"];
        }
        dispatch(
          incentiveAllocationList(postData, (res: any) => {
            setCount(res?.count);
            setIncentiveListData(res?.data);
          })
        );
      })
    );
  };

  const handleUpdateEdit = (e: any) => {
    let newArrr: Array<string> = [];
    incentiveListData?.map((data: any, index: any) => {
      newArrr.push(data?._id);
    });
    e.preventDefault();
    const postData = {
      startDate: startDateNew,
      endDate: endDateNew,
      records: newArrr,
    };
    dispatch(
      editIncentiveNewDate(postData, () => {
        setisEditModeNew(false);
        getList();
      })
    );
  };

  const onleadSource = (value: any) => {
    setLeadSource(value);
  };

  const renderSearchMode = () => {
    return (
      <>
        <form
          className="row d-flex justify-content-between"
          onSubmit={handleSearch}
        >
          <div className="col-md-4 d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={searchStartDate}
                setInputText={(value: string) => SetSearchStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={searchEndDate}
                setInputText={(value: string) => SetSearchEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-2">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>

            <div className="col-md-2 ms-3">
              <button
                className="btn btn-primary "
                type="button"
                onClick={() => handleEditNew(incentiveListData)}
              >
                {" "}
                Edit
              </button>
            </div>
          </div>
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Name:" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Exam"
                setInputText={(value: any) => onChangeExamSearch(value)}
                value={examSearch}
                options={examList}
                reset={resetExam}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Stream:" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Exam Stream"
                setInputText={(value: any) => onChangeExamStreamSearch(value)}
                value={examStreamSearch}
                options={examStreamList}
                reset={resetExamStream}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Counsellor Type:" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Counsellor Type"
                setInputText={(value: any) => onChangeCounsellor(value)}
                value={counsellor}
                options={counsellorList}
                defaultValue={counsellorList?.length > 0 ? counsellor : ""}
                reset={resetCounSellor}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between my-3">
            <div className="col-md-6 ">
              <button
                className="btn btn-primary "
                type="button"
                onClick={handleOpenAddIncentive}
              >
                {" "}
                Add Incentive
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {incentiveListData?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"IncentiveAllocation"}
            count={count}
            tableData={incentiveListData ? incentiveListData : []}
            onTabChange={(e: any, index: any) => showData(e, index)}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={incentiveListData ? incentiveListData : []}
          pagename={"IncentiveAllocation"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Source",
            "Exam Type",
            "Exam Stream",
            "Experience At IFAS",
            "Counsellor Type",
            "Bronze Incentive",
            "Silver Incentive",
            "Gold Incentive",
            "Platinum Incentive",
            "Edit",
            "Delete",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const renderAddIncentiveMode = () => {
    return (
      <>
        <form
          className="col-md-12 row"
          onSubmit={!isEditMode ? AddIncentive : handleUpdate}
        >
          <div className="row">
            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                  Isrequired={true}
                  defaultValue={startDate}
                  // mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                  Isrequired={true}
                  defaultValue={endDate}
                  mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Exam Name:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Exam"
                  setInputText={(value: any) => onChangeExam(value)}
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  defaultValue={exam}
                  reset={resetExam}
                />
              </div>
            </div>
            <div className="col-md-6 d-flex my-2">
              <div className="col-md-4 mt-2 ">
                <LabelField lableName="Exam Stream:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Exam Stream"
                  setInputText={(value: any) => onChangeExamStream(value)}
                  value={examStream}
                  options={examStreamList}
                  defaultValue={examStreamList?.length > 0 ? examStream : ""}
                  Isrequired={true}
                  reset={resetExamStream}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex my-2 ">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Counsellor Type:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Counsellor Type"
                  setInputText={(value: any) => onChangeCounsellor(value)}
                  value={counsellor}
                  options={counsellorList}
                  defaultValue={counsellorList?.length > 0 ? counsellor : ""}
                  Isrequired={true}
                  reset={resetCounSellor}
                />
              </div>
            </div>
            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Experience at IFAS:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="Select Experience"
                  setInputText={(value: any) => onChangeExperience(value)}
                  value={experience}
                  options={experienceList}
                  Isrequired={true}
                  defaultValue={experience}
                  reset={resetExperience}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex my-2">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Lead Source:" />
              </div>
              <div className="col-md-8 ms-1 ps-2">
                <CustomDropdown
                  lableName="select Lead Source"
                  setInputText={(value: any) => onleadSource(value)}
                  value={leadSource}
                  options={sourceList}
                  Isrequired={true}
                  defaultValue={leadSource}
                  reset={resetLeadSource}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <h3 className="text-primary font-monospace"> Target</h3>
            <div className="row d-flex">
              <div className="row">
                <div className="col-4">
                  <p className="fw-bold">Target Level:</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Target Ratio :</p>
                </div>
                <div className="col-4">
                  <p className="fw-bold">Incentive Percentage:</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Bronze Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={bronzeIncentive}
                    onChangeInput={(value: any) =>
                      onchangeBronzeIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Percentage"
                    value={bronzePercentage}
                    onChangeInput={(value: any) =>
                      onChangeBronzePercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Silver Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={silverIncentive}
                    onChangeInput={(value: any) =>
                      onchangeSilverIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Percentage"
                    value={silverPercentage}
                    onChangeInput={(value: any) =>
                      onChangeSilverPercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Gold Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={goldIncentive}
                    onChangeInput={(value: any) => onchangeGoldIncentive(value)}
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Percentage"
                    value={goldPercentage}
                    onChangeInput={(value: any) =>
                      onChangeGoldPercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h6 className="text-primary fw-bold">Platinum Level</h6>
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Target"
                    value={platinumIncentive}
                    onChangeInput={(value: any) =>
                      onchangePlatinumIncentive(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
                <div className="col-md-4">
                  <InputField
                    placeholder="Enter Percentage"
                    value={platinumPercentage}
                    onChangeInput={(value: any) =>
                      onChangePlatinumPercentage(value)
                    }
                    maxlength={length_Ten}
                    minlength={length_One}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isEditMode ? (
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center mx-1">
                <Button name={"Cancel"} handleClick={cancelAddIncentive} />
              </div>
              <div className="d-flex justify-content-center mx-1">
                <SubmitButton name={"Add Incentive"} />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Button name={"Cancel"} handleClick={cancelEditIncentive} />
              <div className="ms-3">
                <SubmitButton name={"Save & Update"} />
              </div>
            </div>
          )}
        </form>
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <form className=" col-md-9 row d-flex" onSubmit={handleUpdateEdit}>
          <div className="col-md-5 d-flex my-2  me-4">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={startDateNew}
                setInputText={(value: string) => SetStartDateNew(value)}
                Isrequired={true}
                mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>

          <div className="col-md-5 d-flex my-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={endDateNew}
                setInputText={(value: string) => SetEndDateNew(value)}
                Isrequired={true}
                mindate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <Button name={"Cancel"} handleClick={cancelEditNew} />
            <div className="ms-3">
              <SubmitButton name={"Save & Update"} />
            </div>
          </div>
        </form>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={
          !isEditMode ? "Incentive Allocation" : "Edit Incentive Allocation"
        }
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="col-md-12 px-4 mx-0 mt-4 formDiv ">
        <div className="justify-content-start ">
          {!isEditModeNew ? (
            <>
              {!openAddIncentive && !isEditMode && !isEditModeNew ? (
                <>
                  {" "}
                  {renderSearchMode()}
                  {renderListMode()}
                </>
              ) : (
                renderAddIncentiveMode()
              )}
            </>
          ) : (
            <>
              {renderEditMode()}
              {renderListMode()}
            </>
          )}
        </div>

        {openDeletePopup ? (
          <RestorePopup
            open={openDeletePopup}
            handleClose={handleCloseDelete}
            handleSubmitRestore={() => {
              handleSubmitDelete();
            }}
            title={"Delete Allocated Target"}
            data={"Do you want to delete this ?"}
            buttonTitle={"Delete"}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
