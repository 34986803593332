import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import { useLocation } from "react-router-dom";
import CustomDropdown from "../../Component/customDropdown";
import { getAssignedStudentLeadDetails } from "../../Redux/Actions/managerPanelAction";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import {
  getSourceTypeList,
  getStatusList,
} from "../../Redux/Actions/leadManagementAction";
import { useSelector } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function LeadAssignedStatusOverview() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [statusList, setStatusList] = useState([]);
  const [resetStatus, setResetStatus] = useState();
  const [source, setSource] = useState<any>();
  const [sourceList, setSourceList] = useState([]);
  const [resetSource, setResetSource] = useState();
  const [studentList, setstudentList] = useState<any>([]);
  const pageName = location.state?.pageName;
  const leadStatus = location.state?.status;
  const leadSource = location.state?.type;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;

  const { loggedInUserId, loggedInUserManagerId, loggedInUserName } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserManagerId: state.Auth.loggedInUserManagerId,
      loggedInUserName: state.Auth.loggedInUserName,
    }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    setCount(0);
    setstudentList([]);
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
        statusId: status?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
        statusId: status?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        counsellorId: loggedInUserId,
        statusId: status?._id,
      };
    }
    if (startDate) {
      data["startDate"] = startDate;
    }
    if (endDate) {
      data["endDate"] = endDate;
    }
    if (source) {
      data["type"] = source?._id;
    }
    dispatch(
      getAssignedStudentLeadDetails(
        data,
        (res: any) => {
          setCount(res?.count);
          setstudentList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    dispatch(
      getStatusList(
        "",
        (res: any) => {
          setStatusList(res);
          let tempStatus = res?.filter?.(
            (item: any) => item?._id === leadStatus
          );
          setStatus(tempStatus[0]);
        },
        () => {}
      )
    );
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
        let tempSource = res?.find((item: any) => item?._id === leadSource);
        setSource(tempSource);
      })
    );
    let PostData: any = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: loggedInUserId,
    };
    if (pageName === "leadAssignedDateAnalysis") {
      SetStartDate(leadStartDate);
      SetEndDate(leadEndDate);
      PostData["statusId"] = leadStatus;
      PostData["startDate"] = leadStartDate;
      PostData["endDate"] = leadEndDate;
      PostData["source"] = leadSource;
    } else if (pageName === "leadDetail") {
      setStatus(leadStatus);
      setSource(leadSource);
      SetStartDate(leadStartDate);
      SetEndDate(leadEndDate);
      PostData["statusId"] = leadStatus;
      PostData["source"] = leadSource;
      PostData["startDate"] = leadStartDate;
      PostData["endDate"] = leadEndDate;
    }
    dispatch(
      getAssignedStudentLeadDetails(
        PostData,
        (res: any) => {
          setCount(res?.count);
          setstudentList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    navigate("/leadDetail", {
      state: {
        pageName: "leadAssignedStatusOverview",
        status: status,
        startDate: startDate,
        endDate: endDate,
        type: source,
      },
    });
  };

  const renderSearchMode = () => {
    return (
      <div className="col-md-8 row d-flex pt-4">
        <div className="col-md-6 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9  ">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-6 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9 ">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-6 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Status" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Status"
              setInputText={(value: any) => setStatus(value)}
              value={status}
              options={statusList}
              // Isrequired={true}
              reset={resetStatus}
              defaultValue={status?._id}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-6 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Source" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Source"
              setInputText={(value: any) => setSource(value)}
              value={source}
              options={sourceList}
              // Isrequired={true}
              reset={resetSource}
              defaultValue={source?._id}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {studentList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"Counsellor_call_Details"}
            count={count}
            tableData={studentList ? studentList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={studentList ? studentList : []}
          pagename={"Counsellor_call_Details"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S. No.",
            "First Name",
            "Last Name",
            // "Phone No ",
            "State",
            "Course",
            "Status",
            "Source",
            "Remark",
            "Allocated Date",
            // "Register Date",
          ]}
          handleClick={onClickLead}
        />
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Total Assigned Leads Analysis"} />
      <div className="col-12 row">
        <div className="p-4">
          <h3 className="fw-bold fontcolor">{loggedInUserName}</h3>
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
