import { useEffect, useState } from "react";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import { getTableDataSlots } from "../../DATA/dataConstant";
import usePagination from "../../Component/usePagination";
import { Pagination } from "@mui/material";
import PaginationView from "../../Component/paginationView";
import PaginationTable from "../../Component/paginationTable";
import {
  getAssignedLeadsList,
  getCollegeList,
  getCounsellorList,
  getEmployeeRoleList,
  getSourceTypeList,
  getStatusList,
  getSubStatusList,
  transferLead,
} from "../../Redux/Actions/leadManagementAction";
import { useAppDispatch } from "../../hooks";
import LabelField from "../../Component/labelField";
import {
  getExamList,
  getExamStreamList,
  getSubExam,
} from "../../Redux/Actions/counsellorPanelAction";

export default function LeadTransfer() {
  const dispatch = useAppDispatch();

  const [isSelectedCheckbox, setIsSelectedCheckbox] = useState<any>(false);
  const [fromCounsellorType, setFromCounsellorType] = useState<any>("");
  const [fromCounsellorName, setFromCounsellorName] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [subExam, setSubExam] = useState<any>();
  const [leadStatus, setLeadStatus] = useState<any>("");
  const [leadStatusDetails, setLeadStatusDetails] = useState<any>("");
  const [leadSource, setLeadSource] = useState<any>("");
  const [leadCollege, setLeadCollege] = useState<any>("");

  const [toCounsellorType, setToCounsellorType] = useState<any>("");
  const [toCounsellorName, setToCounsellorName] = useState<any>("");
  const [leadTransferList, setLeadTransferList] = useState<any>([]);

  const [fromCounsellorTypeList, setFromCounsellorTypeList] = useState([]);
  const [fromCounsellorNameList, setFromCounsellorNameList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [leadStatusList, setLeadStatusList] = useState([]);
  const [leadStatusDetailsList, setLeadStatusDetailsList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [toCounsellorTypeList, setToCounsellorTypeList] = useState([]);
  const [toCounsellorNameList, setToCounsellorNameList] = useState([]);

  const [resetFromCounsellorType, setResetFromCounsellorType] = useState(false);
  const [resetFromCounsellorName, setResetFromCounsellorName] = useState(false);
  const [resetSubExam, setResetSubExam] = useState();
  const [resetExam, setResetExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [resetLeadStatus, setResetLeadStatus] = useState(false);
  const [resetLeadStatusDetails, setResetLeadStatusDetails] = useState(false);
  const [resetLeadSource, setResetLeadSource] = useState(false);
  const [resetLeadCollege, setResetLeadCollege] = useState(false);
  const [resetToCounsellorType, setResetToCounsellorType] = useState(false);
  const [resetToCounsellorName, setResetToCounsellorName] = useState(false);

  useEffect(() => {
    dispatch(
      getEmployeeRoleList((res: any) => {
        let tempArr = res?.filter(
          (item: any) => item?._id !== 1 && item?._id !== 2
        );
        setFromCounsellorTypeList(tempArr);
        setToCounsellorTypeList(tempArr);
      })
    );
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getStatusList(
        "",
        (res: any) => {
          setLeadStatusList(res);
        },
        () => {}
      )
    );
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceList(res);
      })
    );
  }, []);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        counsellorId: fromCounsellorName?._id,
        examTypeId: exam?._id,
        subExamTypeId: subExam?._id,
        basecourseId: examStream?._id,
        status: leadStatus?._id,
        subStatus: leadStatusDetails?._id,
        type: leadSource?._id,
        reference: leadCollege?.name,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        counsellorId: fromCounsellorName?._id,
        examTypeId: exam?._id,
        subExamTypeId: subExam?._id,
        basecourseId: examStream?._id,
        status: leadStatus?._id,
        subStatus: leadStatusDetails?._id,
        type: leadSource?._id,
        reference: leadCollege?.name,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        counsellorId: fromCounsellorName?._id,
        examTypeId: exam?._id,
        subExamTypeId: subExam?._id,
        basecourseId: examStream?._id,
        status: leadStatus?._id,
        subStatus: leadStatusDetails?._id,
        type: leadSource?._id,
        reference: leadCollege?.name,
      };
    }

    if (data["examTypeId"] === "" || data["examTypeId"] === undefined) {
      delete data["examTypeId"];
    }
    if (data["subExamTypeId"] === "" || data["subExamTypeId"] === undefined) {
      delete data["subExamTypeId"];
    }
    if (data["basecourseId"] === "" || data["basecourseId"] === undefined) {
      delete data["basecourseId"];
    }
    if (data["status"] === "" || data["status"] === undefined) {
      delete data["status"];
    }
    if (data["subStatus"] === "" || data["subStatus"] === undefined) {
      delete data["subStatus"];
    }
    if (data["type"] === "" || data["type"] === undefined) {
      delete data["type"];
    }
    if (data["reference"] === "" || data["reference"] === undefined) {
      delete data["reference"];
    }

    dispatch(
      getAssignedLeadsList(
        data,
        (res: any) => {
          setCount(res?.count);
          setLeadTransferList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeFromCounsellorType = (value: any) => {
    setFromCounsellorType(value);
    setResetFromCounsellorType(false);
    setFromCounsellorName("");
    setResetFromCounsellorName(true);
    setLeadStatus("");
    setResetLeadStatus(true);
    setLeadStatusDetails("");
    setResetLeadStatusDetails(true);
    dispatch(
      getCounsellorList(
        value?._id,
        (res: any) => {
          setFromCounsellorNameList(res);
        },
        () => {}
      )
    );
  };

  const onChangeFromCounsellorName = (value: any) => {
    setFromCounsellorName(value);
    setResetFromCounsellorName(false);
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    dispatch(
      getSubExam(
        {
          examTypeId: val._id,
        },
        (res: any) => {
          if (res.length > 0) {
            setSubExamList(res);
          } else {
            dispatch(
              getExamStreamList(`${val._id}`, (res: any) => {
                setExamStreamList(res);
              })
            );
          }
        }
      )
    );
  };

  const onChangeSubExam = (val: any) => {
    setSubExam(val);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };

  const onChangeExamStreamSearch = (val: any) => {
    setExamStream(val);
  };

  const onLeadStatusChange = (value: any) => {
    setLeadStatus(value);
    setResetLeadStatus(false);
    setLeadStatusDetails("");
    setResetLeadStatusDetails(true);
    dispatch(
      getSubStatusList(
        value?._id,
        (res: any) => {
          setLeadStatusDetailsList(res);
        },
        () => {}
      )
    );
  };
  const onLeadStatusDetailsChange = (value: any) => {
    setLeadStatusDetails(value);
    setResetLeadStatusDetails(false);
  };
  const onleadSource = (value: any) => {
    setLeadSource(value);
    setResetLeadSource(false);
    setLeadCollege("");
    setResetLeadCollege(true);
    setCollegeList([]);

    const PostData = {
      counsellorId: fromCounsellorName?._id,
      type: value?._id,
      status: leadStatus?._id,
      subStatus: leadStatusDetails?._id,
    };

    if (PostData["status"] === "" || PostData["status"] === undefined) {
      delete PostData["status"];
    }
    if (PostData["subStatus"] === "" || PostData["subStatus"] === undefined) {
      delete PostData["subStatus"];
    }

    dispatch(
      getCollegeList(
        PostData,
        (res: any) => {
          setCollegeList(res?.data);
        },
        () => {}
      )
    );
  };

  const onleadCollege = (value: any) => {
    setLeadCollege(value);
    setResetLeadCollege(false);
  };

  const validations = () => {
    return fromCounsellorType === "" || fromCounsellorName === "";
  };

  const onChangeToCounsellorType = (value: any) => {
    setToCounsellorType(value);
    setResetToCounsellorType(false);
    dispatch(
      getCounsellorList(
        value?._id,
        (res: any) => {
          setToCounsellorNameList(res);
        },
        () => {}
      )
    );
  };

  const onChangeToCounsellorName = (value: any) => {
    setToCounsellorName(value);
    setResetToCounsellorName(false);
  };

  const handleAllCheck = (e: any) => {
    let newArr = JSON.parse(JSON.stringify(leadTransferList));
    if (e.target.checked) {
      setIsSelectedCheckbox(true);
      newArr.forEach((element: any) => {
        element.isSelected = true;
      });
      setLeadTransferList(newArr);
    } else {
      setIsSelectedCheckbox(false);
      newArr.forEach((element: any) => {
        element.isSelected = false;
      });
      setLeadTransferList(newArr);
    }
  };

  const handleCheck = (e: any, index: any) => {
    let newArr = JSON.parse(JSON.stringify(leadTransferList));
    if (e.target.checked) {
      newArr[index].isSelected = true;
      setLeadTransferList(newArr);
    } else {
      newArr[index].isSelected = false;
      setLeadTransferList(newArr);
    }
    let indexes = newArr?.filter((elm: any) => elm?.isSelected);
    if (newArr?.length === indexes?.length) {
      setIsSelectedCheckbox(true);
    } else {
      setIsSelectedCheckbox(false);
    }
  };

  const validations1 = () => {
    return toCounsellorType === "" || toCounsellorName === "" || validateList();
  };

  const validateList = () => {
    let result = false;
    let indexes = leadTransferList?.filter((elm: any) => elm?.isSelected);
    if (indexes?.length <= 0) {
      result = true;
    }
    return result;
  };

  const renderListMode = () => {
    return (
      <>
        {leadTransferList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            pagename={"LeadTransfer"}
            count={count}
            tableData={leadTransferList ? leadTransferList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={leadTransferList ? leadTransferList : []}
          pagename={"LeadTransfer"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "First name",
            "Last name",
            // "Mobile Number",
            // "Mobile Number(Optional)",
            // "Email",
            "State",
            "City",
            "Exam",
            "Exam Stream",
            "Education year",
            "Source",
            "Reference",
            "Call Back Date",
          ]}
          edit={false}
          isSelectedCheckbox={isSelectedCheckbox}
          handleAllCheck={(value: any) => handleAllCheck(value)}
          handleCheck={(value: any, index: number) => handleCheck(value, index)}
        />
      </>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLeadTransferList([]);
    const PostData = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: fromCounsellorName?._id,
      examTypeId: exam?._id,
      subExamTypeId: subExam?._id,
      basecourseId: examStream?._id,
      status: leadStatus?._id,
      subStatus: leadStatusDetails?._id,
      type: leadSource?._id,
      reference: leadCollege?.name,
    };

    if (PostData["examTypeId"] === "" || PostData["examTypeId"] === undefined) {
      delete PostData["examTypeId"];
    }
    if (
      PostData["subExamTypeId"] === "" ||
      PostData["subExamTypeId"] === undefined
    ) {
      delete PostData["subExamTypeId"];
    }
    if (
      PostData["basecourseId"] === "" ||
      PostData["basecourseId"] === undefined
    ) {
      delete PostData["basecourseId"];
    }
    if (PostData["status"] === "" || PostData["status"] === undefined) {
      delete PostData["status"];
    }
    if (PostData["subStatus"] === "" || PostData["subStatus"] === undefined) {
      delete PostData["subStatus"];
    }
    if (PostData["type"] === "" || PostData["type"] === undefined) {
      delete PostData["type"];
    }
    if (PostData["reference"] === "" || PostData["reference"] === undefined) {
      delete PostData["reference"];
    }

    dispatch(
      getAssignedLeadsList(
        PostData,
        (res: any) => {
          setCount(res?.count);
          setLeadTransferList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleTransfer = (e: any) => {
    e.preventDefault();
    let newArr = JSON.parse(JSON.stringify(leadTransferList));
    let tempArr = newArr?.filter((item: any) => item?.isSelected);
    let studentLeadIds: Array<string> = [];
    tempArr.forEach((element: any) => {
      studentLeadIds.push(element?._id);
    });
    const PostData = {
      counsellorId: toCounsellorName?._id,
      studentLeadIds: studentLeadIds,
    };

    dispatch(
      transferLead(
        PostData,
        (res: any) => {
          setToCounsellorType("");
          setResetToCounsellorType(true);
          setToCounsellorName("");
          setResetToCounsellorName(true);
          setIsSelectedCheckbox(false);
          setLeadTransferList([]);

          const PostData = {
            skip: 0,
            limit: rowsPerPage,
            counsellorId: fromCounsellorName?._id,
            examTypeId: exam?._id,
            subExamTypeId: subExam?._id,
            basecourseId: examStream?._id,
            status: leadStatus?._id,
            subStatus: leadStatusDetails?._id,
            type: leadSource?._id,
            reference: leadCollege?.name,
          };

          if (
            PostData["examTypeId"] === "" ||
            PostData["examTypeId"] === undefined
          ) {
            delete PostData["examTypeId"];
          }
          if (
            PostData["subExamTypeId"] === "" ||
            PostData["subExamTypeId"] === undefined
          ) {
            delete PostData["subExamTypeId"];
          }
          if (
            PostData["basecourseId"] === "" ||
            PostData["basecourseId"] === undefined
          ) {
            delete PostData["basecourseId"];
          }
          if (PostData["status"] === "" || PostData["status"] === undefined) {
            delete PostData["status"];
          }
          if (
            PostData["subStatus"] === "" ||
            PostData["subStatus"] === undefined
          ) {
            delete PostData["subStatus"];
          }
          if (PostData["type"] === "" || PostData["type"] === undefined) {
            delete PostData["type"];
          }
          if (
            PostData["reference"] === "" ||
            PostData["reference"] === undefined
          ) {
            delete PostData["reference"];
          }

          dispatch(
            getAssignedLeadsList(
              PostData,
              (res: any) => {
                setCount(res?.count);
                setLeadTransferList(res?.data);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const renderLeadFrom = () => {
    return (
      <form className="col-6 px-2" onSubmit={handleSubmit}>
        <div>
          <div className="fw-bold text-primary pb-2">FROM :</div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName={"Counsellor Type :"} />
            </div>
            <CustomDropdown
              lableName={"select Counsellor Type"}
              setInputText={(value: any) => onChangeFromCounsellorType(value)}
              value={fromCounsellorType}
              options={fromCounsellorTypeList}
              Isrequired={true}
              reset={resetFromCounsellorType}
            />
          </div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName={"Counsellor Name :"} />
            </div>
            <CustomDropdown
              lableName={"select Counsellor Name"}
              setInputText={(value: any) => onChangeFromCounsellorName(value)}
              value={fromCounsellorName}
              options={fromCounsellorNameList}
              Isrequired={true}
              reset={resetFromCounsellorName}
            />
          </div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName="Exam Name:" />
            </div>
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
              reset={resetExam}
            />
          </div>

          {subExamList?.length > 0 ? (
            <div className="d-flex Justify-content-between mb-2">
              <div className="col-4 align-item-center d-flex me-3">
                <LabelField lableName="Sub Exam Name" />
              </div>
              <CustomDropdown
                lableName="Select Exam"
                setInputText={(value: any) => onChangeSubExam(value)}
                value={subExam}
                options={subExamList}
                reset={resetSubExam}
              />
            </div>
          ) : null}
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName="Exam Stream:" />
            </div>
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExamStreamSearch(value)}
              value={examStream}
              options={examStreamList}
              reset={resetExamStream}
            />
          </div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName={"Lead Status:"} />
            </div>
            <CustomDropdown
              lableName={"select Lead Status"}
              setInputText={(value: any) => onLeadStatusChange(value)}
              value={leadStatus}
              options={leadStatusList}
              // Isrequired={true}
              reset={resetLeadStatus}
            />
          </div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName={"Lead Status Details :"} />
            </div>
            <CustomDropdown
              lableName={"select Lead Status Details"}
              setInputText={(value: any) => onLeadStatusDetailsChange(value)}
              value={leadStatusDetails}
              options={leadStatusDetailsList}
              // Isrequired={true}
              reset={resetLeadStatusDetails}
            />
          </div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName={"Lead Source :"} />
            </div>
            <CustomDropdown
              lableName={"select Lead Source"}
              setInputText={(value: any) => onleadSource(value)}
              value={leadSource}
              options={sourceList}
              // Isrequired={true}
              reset={resetLeadSource}
            />
          </div>
          {leadSource?._id === 1 || leadSource?._id === 4 ? (
            <div className="d-flex Justify-content-between mb-2">
              <div className="col-4 align-item-center d-flex me-3">
                <LabelField lableName={"College Name :"} />
              </div>
              <CustomDropdown
                lableName={"select College Name"}
                setInputText={(value: any) => onleadCollege(value)}
                value={leadCollege}
                options={collegeList}
                // Isrequired={true}
                reset={resetLeadCollege}
              />
            </div>
          ) : null}

          <div className="d-flex justify-content-center ms-5">
            <button
              type="submit"
              className={`btn btn-primary mb-3 align-items-center importLeadBtn mt-2 ${
                validations() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations()}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderLeadTo = () => {
    return (
      <form className="col-6 px-2" onSubmit={handleTransfer}>
        <div>
          <div className="fw-bold text-primary pb-2">TO :</div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName={"Counsellor Type :"} />
            </div>
            <CustomDropdown
              lableName={"select Counsellor Type"}
              setInputText={(value: any) => onChangeToCounsellorType(value)}
              value={toCounsellorType}
              options={toCounsellorTypeList}
              Isrequired={true}
              reset={resetToCounsellorType}
            />
          </div>
          <div className="d-flex Justify-content-between mb-2">
            <div className="col-4 align-item-center d-flex me-3">
              <LabelField lableName={"Counsellor Name :"} />
            </div>
            <CustomDropdown
              lableName={"select Counsellor Name"}
              setInputText={(value: any) => onChangeToCounsellorName(value)}
              value={toCounsellorName}
              options={toCounsellorNameList}
              Isrequired={true}
              reset={resetToCounsellorName}
            />
          </div>
          <div className="d-flex justify-content-center ms-5">
            <button
              type="submit"
              className={`btn btn-primary mb-3 align-items-center importLeadBtn mt-2 ${
                validations1() ? "btn-secondary" : "btn-primary"
              }`}
              disabled={validations1()}
            >
              {" "}
              Transfer Lead{" "}
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Transfer"} />
      <div className=" formDiv ">
        <div className="px-4   pt-4  ">
          <div className="col-12">
            <div className="d-flex col-auto">
              {renderLeadFrom()}
              {renderLeadTo()}
            </div>
            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
