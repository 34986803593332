import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";

import LabelField from "../../Component/labelField";

import SubmitButton from "../../Component/submitButton";
import { getSMManagerList } from "../../Redux/Actions/superManagerAction";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
import {
  assignLeadList,
  getSourceTypeList,
} from "../../Redux/Actions/leadManagementAction";

export default function SMSkipDetails() {
  const dispatch = useDispatch();

  const [source, setSource] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>({
    _id: 0,
    name: "SKIP",
  });

  const [leadList, setLeadList] = useState<any>([]);
  const [sourceTypeList, setSourceTypeList] = useState<any>([]);
  const [resetSource, setResetSource] = useState(false);
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [resetManager, setResetManager] = useState(false);
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState(false);
  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  const { executiveID, loggedInUserRole, loggedInUserId } = useSelector(
    (state: any) => ({
      executiveID: state.Auth.executiveID,
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserId: state.Auth.loggedInUserId,
    })
  );
  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: selectedStatus?._id,
        type: source?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: selectedStatus?._id,
        type: source?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: selectedStatus?._id,
        type: source?._id,
      };
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeSource = (val: any) => {
    setSource(val);
    setResetSource(false);
    const postData = {
      status: selectedStatus?._id,
      type: val?._id,
    };
  };
  const onChangeManager = (value: any) => {
    setManager(value);
    dispatch(
      getEmployeetByManagerId(
        value?._id,
        (res: any) => {
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceTypeList(res);
      })
    );
  };
  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      status: selectedStatus?._id,
      type: source?._id,
    };

    dispatch(
      assignLeadList(
        postData,
        (res: any) => {
          setCount(res?.count);
          setLeadList(res?.data);
        },
        () => {}
      )
    );
  };

  const validation = () => {
    return source === "";
  };

  const renderSearchMode = () => {
    return (
      <form
        className="col-md-12 row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Manager" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Manager"
              setInputText={(value: any) => onChangeManager(value)}
              value={manager}
              options={managerList}
              // Isrequired={true}
              reset={resetManager}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Team Lead" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Team Lead"
              setInputText={(value: any) => onChangeTeamLead(value)}
              value={teamLead}
              options={teamLeadList}
              // Isrequired={true}
              reset={resetTeamLead}
            />
          </div>
        </div>
        <div className="col-md-4 row d-flex justify-content-between pe-3 ">
          <div className="col-md-3 row d-flex justify-content-between mb-2  ">
            <LabelField lableName={"Source :"} />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select Source"
              setInputText={(value: any) => onChangeSource(value)}
              value={source}
              options={sourceTypeList}
              reset={resetSource}
            />
          </div>
        </div>

        <div>
          <SubmitButton name="Search" validation={validation()} />
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        {leadList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"SkipDetails"}
            count={count}
            tableData={leadList ? leadList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={leadList ? leadList : []}
          pagename={"SkipDetails"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "First Name",
            "Last Name",
            "Mobile No.",
            "Email",
            "State",
            "City",
            "Exam Name",
            "Exam Stream",
            "Education Year",
            "Source",
            "Reference",
          ]}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Skip Details"} />
      <div className="formDiv">
        <div className="row m-1">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">{renderSearchMode()}</div>
            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
