import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import SubmitButton from "../../Component/submitButton";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots, leadType } from "../../DATA/dataConstant";
import InputField from "../../Component/inputField";

import LabelField from "../../Component/labelField";
import { length_Ten } from "../../DATA/validators";
import {
  admissionDoneLeadList,
  assignAdmissionDoneLead,
  assignExpireLead,
  assignLead,
  assignLeadList,
  assignSkipLead,
  expireLeadList,
  getCollegeListForAssign,
  getSourceTypeList,
} from "../../Redux/Actions/leadManagementAction";
import DatePicker from "../../Component/datePicker";
import {
  getExamList,
  getExamStreamList,
  getMainCategoryList,
  getStatesList,
} from "../../Redux/Actions/counsellorPanelAction";

export default function LeadAssignment() {
  const dispatch = useDispatch();

  const [source, setSource] = useState<any>("");
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [mainCategory, setMainCategory] = useState<any>("");
  const [leadCollege, setLeadCollege] = useState<any>("");
  const [resetLeadCollege, setResetLeadCollege] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [resetMainCategory, setResetMainCategory] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [examList, setExamList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [leadCount, setLeadCount] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<any>({
    _id: 1,
    name: "NEW",
  });

  const [leadList, setLeadList] = useState<any>([]);
  const [collegeList, setCollegeList] = useState([]);
  const [sourceTypeList, setSourceTypeList] = useState<any>([]);
  const [resetSource, setResetSource] = useState(false);
  const [state, setState] = useState<any>("");
  const [stateList, setStateList] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(
      getSourceTypeList((res: any) => {
        setSourceTypeList(res);
      })
    );
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      assignLeadList(
        {
          skip: 0,
          limit: 10,
          status: selectedStatus?._id,
        },
        (res: any) => {
          setCount(res?.count);
          setLeadList(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getStatesList((res: any) => {
        if (res?.data?.length > 0) {
          setStateList(res?.data);
        } else {
          setStateList([]);
        }
      })
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: selectedStatus?._id,
        type: source?._id,
        startDate,
        endDate,
        examTypeId: exam?._id,
        // subExamTypeId: subExam?._id,
        baseCourseId: examStream?._id,
        mainCategoryId: mainCategory?._id,
        stateId: state?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: selectedStatus?._id,
        type: source?._id,
        startDate,
        endDate,
        examTypeId: exam?._id,
        // subExamTypeId: subExam?._id,
        baseCourseId: examStream?._id,
        mainCategoryId: mainCategory?._id,
        stateId: state?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: selectedStatus?._id,
        type: source?._id,
        startDate,
        endDate,
        examTypeId: exam?._id,
        // subExamTypeId: subExam?._id,
        baseCourseId: examStream?._id,
        mainCategoryId: mainCategory?._id,
        stateId: state?._id,
      };
    }

    if (
      data["startDate"] === "" ||
      data["startDate"] === undefined ||
      data["startDate"] === null
    ) {
      delete data["startDate"];
    }
    if (
      data["endDate"] === "" ||
      data["endDate"] === undefined ||
      data["endDate"] === null
    ) {
      delete data["endDate"];
    }
    if (
      data["examTypeId"] === "" ||
      data["examTypeId"] === undefined ||
      data["examTypeId"] === null
    ) {
      delete data["examTypeId"];
    }
    // if (data["subExamTypeId"] === "" || data["subExamTypeId"] === undefined || data["subExamTypeId"] === null) {
    //   delete data["subExamTypeId"];
    // }
    if (
      data["baseCourseId"] === "" ||
      data["baseCourseId"] === undefined ||
      data["baseCourseId"] === null
    ) {
      delete data["baseCourseId"];
    }
    if (
      data["stateId"] === "" ||
      data["stateId"] === undefined ||
      data["stateId"] === null
    ) {
      delete data["stateId"];
    }
    if (
      data["mainCategoryId"] === "" ||
      data["mainCategoryId"] === undefined ||
      data["mainCategoryId"] === null
    ) {
      delete data["mainCategoryId"];
    }

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          data,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          data,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          data,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }

    dispatch(
      getCollegeListForAssign(
        data,
        (res: any) => {
          setCollegeList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onchangeLeadCount = (val: any) => {
    setLeadCount(val);
  };

  const onTabChange = (e: any, value: any) => {
    setSelectedStatus(value);
    setSource("");
    setResetSource(true);
    setResetState(true);
    setResetExam(true);
    setResetExamStream(true);
    setResetMainCategory(true);
    setResetLeadCollege(true);
    setCount(0);
    setLeadList([]);
    const postData = {
      skip: 0,
      limit: 10,
      status: value?._id,
    };

    if (value?._id === 1 || value?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (value?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (value?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }
  };

  // const onTabChangeExpire = (e: any, value: any) => {
  //   setSelectedStatus(value);
  //   const postData = {
  //     skip: 0,
  //     limit: 10,
  //     status: value?._id,
  //   };

  //   dispatch(
  //     expireLeadList(
  //       postData,
  //       (res: any) => {
  //         setCount(res?.count);
  //         setExpireDataLeadList(res?.data)
  //       },
  //       () => { }
  //     )
  //   );

  // }

  // const onTabChangeAdmissionDone = (e: any, value: any) => {
  //   setSelectedStatus(value);
  //   const postData = {
  //     skip: 0,
  //     limit: 10,
  //     status: value?._id,
  //   };

  //   dispatch(
  //     admissionDoneLeadList(
  //       postData,
  //       (res: any) => {
  //         setCount(res?.count);
  //         setExpireDataLeadList(res?.data)
  //       },
  //       () => { }
  //     )
  //   );

  // }

  const onChangeSource = (val: any) => {
    setSource(val);
    setResetSource(false);
    setResetState(true);
    setResetExam(true);
    setResetExamStream(true);
    setResetMainCategory(true);
    setResetLeadCollege(true);
    const postData = {
      skip: 0,
      limit: 10,
      status: selectedStatus?._id,
      type: val?._id,
    };

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }

    dispatch(
      getCollegeListForAssign(
        postData,
        (res: any) => {
          setCollegeList(res?.data);
        },
        () => {}
      )
    );
  };

  const changeEndDate = (val: any) => {
    SetEndDate(val);
    const postData = {
      skip: 0,
      limit: 10,
      status: selectedStatus?._id,
      type: source?._id,
      startDate,
      endDate: val,
    };
    if (
      postData["startDate"] === "" ||
      postData["startDate"] === undefined ||
      postData["startDate"] === null
    ) {
      delete postData["startDate"];
    }
    if (
      postData["endDate"] === "" ||
      postData["endDate"] === undefined ||
      postData["endDate"] === null
    ) {
      delete postData["endDate"];
    }

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }

    dispatch(
      getCollegeListForAssign(
        postData,
        (res: any) => {
          setCollegeList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeState = (val: any) => {
    setState(val);
    setResetState(false);
    setResetExam(true);
    setResetExamStream(true);
    setResetMainCategory(true);
    setResetLeadCollege(true);
    const postData = {
      skip: 0,
      limit: 10,
      status: selectedStatus?._id,
      type: source?._id,
      startDate,
      endDate,
      stateId: val?._id,
      examTypeId: exam?._id,
      // subExamTypeId: subExam?._id,
      baseCourseId: examStream?._id,
    };
    if (
      postData["startDate"] === "" ||
      postData["startDate"] === undefined ||
      postData["startDate"] === null
    ) {
      delete postData["startDate"];
    }
    if (
      postData["endDate"] === "" ||
      postData["endDate"] === undefined ||
      postData["endDate"] === null
    ) {
      delete postData["endDate"];
    }
    if (
      postData["examTypeId"] === "" ||
      postData["examTypeId"] === undefined ||
      postData["examTypeId"] === null
    ) {
      delete postData["examTypeId"];
    }
    // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
    //   delete postData["subExamTypeId"];
    // }
    if (
      postData["baseCourseId"] === "" ||
      postData["baseCourseId"] === undefined ||
      postData["baseCourseId"] === null
    ) {
      delete postData["baseCourseId"];
    }
    if (
      postData["stateId"] === "" ||
      postData["stateId"] === undefined ||
      postData["stateId"] === null
    ) {
      delete postData["stateId"];
    }

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }

    dispatch(
      getCollegeListForAssign(
        postData,
        (res: any) => {
          setCollegeList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    setResetExam(false);
    setExamStream("");
    setResetExamStream(true);
    setResetMainCategory(true);
    setResetLeadCollege(true);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
    const postData = {
      skip: 0,
      limit: 10,
      status: selectedStatus?._id,
      type: source?._id,
      startDate,
      endDate,
      stateId: state?._id,
      examTypeId: val?._id,
    };
    if (
      postData["startDate"] === "" ||
      postData["startDate"] === undefined ||
      postData["startDate"] === null
    ) {
      delete postData["startDate"];
    }
    if (
      postData["endDate"] === "" ||
      postData["endDate"] === undefined ||
      postData["endDate"] === null
    ) {
      delete postData["endDate"];
    }
    if (
      postData["examTypeId"] === "" ||
      postData["examTypeId"] === undefined ||
      postData["examTypeId"] === null
    ) {
      delete postData["examTypeId"];
    }
    if (
      postData["stateId"] === "" ||
      postData["stateId"] === undefined ||
      postData["stateId"] === null
    ) {
      delete postData["stateId"];
    }

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }

    dispatch(
      getCollegeListForAssign(
        postData,
        (res: any) => {
          setCollegeList(res?.data);
        },
        () => {}
      )
    );
  };

  // const onChangeSubExam = (val: any) => {
  //   setSubExam(val)
  //   dispatch(
  //     getExamStreamList(
  //       `${val._id}`,
  //       (res: any) => {
  //         setExamStreamList(res);
  //       },
  //       () => { }
  //     )
  //   );

  // }

  const onChangeExamStreamSearch = (val: any) => {
    setExamStream(val);
    setResetExamStream(false);
    setResetMainCategory(true);
    setResetLeadCollege(true);
    const postData = {
      skip: 0,
      limit: 10,
      status: selectedStatus?._id,
      type: source?._id,
      startDate,
      endDate,
      stateId: state?._id,
      examTypeId: exam?._id,
      // subExamTypeId: subExam?._id,
      baseCourseId: val?._id,
    };
    if (
      postData["startDate"] === "" ||
      postData["startDate"] === undefined ||
      postData["startDate"] === null
    ) {
      delete postData["startDate"];
    }
    if (
      postData["endDate"] === "" ||
      postData["endDate"] === undefined ||
      postData["endDate"] === null
    ) {
      delete postData["endDate"];
    }
    if (
      postData["examTypeId"] === "" ||
      postData["examTypeId"] === undefined ||
      postData["examTypeId"] === null
    ) {
      delete postData["examTypeId"];
    }
    // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
    //   delete postData["subExamTypeId"];
    // }
    if (
      postData["baseCourseId"] === "" ||
      postData["baseCourseId"] === undefined ||
      postData["baseCourseId"] === null
    ) {
      delete postData["baseCourseId"];
    }
    if (
      postData["stateId"] === "" ||
      postData["stateId"] === undefined ||
      postData["stateId"] === null
    ) {
      delete postData["stateId"];
    }

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }

    dispatch(
      getCollegeListForAssign(
        postData,
        (res: any) => {
          setCollegeList(res?.data);
        },
        () => {}
      )
    );
    dispatch(
      getMainCategoryList(
        {
          examTypeId: exam?._id,
          baseCourseId: val?._id,
        },
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };

  const onChangeMainCategory = (value: any) => {
    setLeadCollege(value);
    setResetMainCategory(false);
    setResetLeadCollege(true);

    const postData = {
      skip: 0,
      limit: 10,
      status: selectedStatus?._id,
      type: source?._id,
      startDate,
      endDate,
      stateId: state?._id,
      examTypeId: exam?._id,
      mainCategoryId: value?._id,
      // subExamTypeId: subExam?._id,
      baseCourseId: examStream?._id,
    };
    if (
      postData["startDate"] === "" ||
      postData["startDate"] === undefined ||
      postData["startDate"] === null
    ) {
      delete postData["startDate"];
    }
    if (
      postData["endDate"] === "" ||
      postData["endDate"] === undefined ||
      postData["endDate"] === null
    ) {
      delete postData["endDate"];
    }
    if (
      postData["examTypeId"] === "" ||
      postData["examTypeId"] === undefined ||
      postData["examTypeId"] === null
    ) {
      delete postData["examTypeId"];
    }
    // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
    //   delete postData["subExamTypeId"];
    // }
    if (
      postData["baseCourseId"] === "" ||
      postData["baseCourseId"] === undefined ||
      postData["baseCourseId"] === null
    ) {
      delete postData["baseCourseId"];
    }
    if (
      postData["stateId"] === "" ||
      postData["stateId"] === undefined ||
      postData["stateId"] === null
    ) {
      delete postData["stateId"];
    }
    if (
      postData["mainCategoryId"] === "" ||
      postData["mainCategoryId"] === undefined ||
      postData["mainCategoryId"] === null
    ) {
      delete postData["mainCategoryId"];
    }

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }
  };

  const onleadCollege = (value: any) => {
    setLeadCollege(value);
    setResetLeadCollege(false);

    const postData = {
      skip: 0,
      limit: 10,
      status: selectedStatus?._id,
      type: source?._id,
      startDate,
      endDate,
      stateId: state?._id,
      examTypeId: exam?._id,
      // subExamTypeId: subExam?._id,
      mainCategoryId: mainCategory?._id,
      baseCourseId: examStream?._id,
      reference: value?.name,
    };
    if (
      postData["startDate"] === "" ||
      postData["startDate"] === undefined ||
      postData["startDate"] === null
    ) {
      delete postData["startDate"];
    }
    if (
      postData["endDate"] === "" ||
      postData["endDate"] === undefined ||
      postData["endDate"] === null
    ) {
      delete postData["endDate"];
    }
    if (
      postData["examTypeId"] === "" ||
      postData["examTypeId"] === undefined ||
      postData["examTypeId"] === null
    ) {
      delete postData["examTypeId"];
    }
    // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
    //   delete postData["subExamTypeId"];
    // }
    if (
      postData["baseCourseId"] === "" ||
      postData["baseCourseId"] === undefined ||
      postData["baseCourseId"] === null
    ) {
      delete postData["baseCourseId"];
    }
    if (
      postData["stateId"] === "" ||
      postData["stateId"] === undefined ||
      postData["stateId"] === null
    ) {
      delete postData["stateId"];
    }
    if (
      postData["reference"] === "" ||
      postData["reference"] === undefined ||
      postData["reference"] === null
    ) {
      delete postData["reference"];
    }
    if (
      postData["mainCategoryId"] === "" ||
      postData["mainCategoryId"] === undefined ||
      postData["mainCategoryId"] === null
    ) {
      delete postData["mainCategoryId"];
    }

    if (selectedStatus?._id === 1 || selectedStatus?._id === 0) {
      dispatch(
        assignLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        expireLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        admissionDoneLeadList(
          postData,
          (res: any) => {
            setCount(res?.count);
            setLeadList(res?.users);
          },
          () => {}
        )
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let newArrr: Array<string> = [];
    leadList?.map((data: any, index: any) => {
      if (index < leadCount) {
        newArrr.push(data?._id);
      }
    });

    const PostData = {
      leadId: newArrr,
    };

    if (selectedStatus?._id === 1) {
      dispatch(
        assignLead(
          PostData,
          (res: any) => {
            setLeadList(res);
            setLeadCount("");
            const postData = {
              skip: 0,
              limit: 10,
              status: selectedStatus?._id,
              type: source?._id,
              startDate,
              endDate,
              stateId: state?._id,
              examTypeId: exam?._id,
              // subExamTypeId: subExam?._id,
              baseCourseId: examStream?._id,
              mainCategoryId: mainCategory?._id,
            };
            if (
              postData["startDate"] === "" ||
              postData["startDate"] === undefined ||
              postData["startDate"] === null
            ) {
              delete postData["startDate"];
            }
            if (
              postData["endDate"] === "" ||
              postData["endDate"] === undefined ||
              postData["endDate"] === null
            ) {
              delete postData["endDate"];
            }
            if (
              postData["examTypeId"] === "" ||
              postData["examTypeId"] === undefined ||
              postData["examTypeId"] === null
            ) {
              delete postData["examTypeId"];
            }
            // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
            //   delete postData["subExamTypeId"];
            // }
            if (
              postData["baseCourseId"] === "" ||
              postData["baseCourseId"] === undefined ||
              postData["baseCourseId"] === null
            ) {
              delete postData["baseCourseId"];
            }
            if (
              postData["stateId"] === "" ||
              postData["stateId"] === undefined ||
              postData["stateId"] === null
            ) {
              delete postData["stateId"];
            }
            if (
              postData["mainCategoryId"] === "" ||
              postData["mainCategoryId"] === undefined ||
              postData["mainCategoryId"] === null
            ) {
              delete postData["mainCategoryId"];
            }

            dispatch(
              assignLeadList(
                postData,
                (res: any) => {
                  setCount(res?.count);
                  setLeadList(res?.data);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 11) {
      dispatch(
        assignExpireLead(
          PostData,
          (res: any) => {
            setLeadList(res);
            setLeadCount("");
            const postData = {
              skip: 0,
              limit: 10,
              status: selectedStatus?._id,
              type: source?._id,
              startDate,
              endDate,
              stateId: state?._id,
              examTypeId: exam?._id,
              // subExamTypeId: subExam?._id,
              baseCourseId: examStream?._id,
              mainCategoryId: mainCategory?._id,
            };
            if (
              postData["startDate"] === "" ||
              postData["startDate"] === undefined ||
              postData["startDate"] === null
            ) {
              delete postData["startDate"];
            }
            if (
              postData["endDate"] === "" ||
              postData["endDate"] === undefined ||
              postData["endDate"] === null
            ) {
              delete postData["endDate"];
            }
            if (
              postData["examTypeId"] === "" ||
              postData["examTypeId"] === undefined ||
              postData["examTypeId"] === null
            ) {
              delete postData["examTypeId"];
            }
            // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
            //   delete postData["subExamTypeId"];
            // }
            if (
              postData["baseCourseId"] === "" ||
              postData["baseCourseId"] === undefined ||
              postData["baseCourseId"] === null
            ) {
              delete postData["baseCourseId"];
            }
            if (
              postData["stateId"] === "" ||
              postData["stateId"] === undefined ||
              postData["stateId"] === null
            ) {
              delete postData["stateId"];
            }
            if (
              postData["mainCategoryId"] === "" ||
              postData["mainCategoryId"] === undefined ||
              postData["mainCategoryId"] === null
            ) {
              delete postData["mainCategoryId"];
            }
            dispatch(
              expireLeadList(
                postData,
                (res: any) => {
                  setCount(res?.count);
                  setLeadList(res?.data);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 0) {
      dispatch(
        assignSkipLead(
          PostData,
          (res: any) => {
            setLeadList(res);
            setLeadCount("");
            const postData = {
              skip: 0,
              limit: 10,
              status: selectedStatus?._id,
              type: source?._id,
              startDate,
              endDate,
              stateId: state?._id,
              examTypeId: exam?._id,
              // subExamTypeId: subExam?._id,
              baseCourseId: examStream?._id,
            };
            if (
              postData["startDate"] === "" ||
              postData["startDate"] === undefined ||
              postData["startDate"] === null
            ) {
              delete postData["startDate"];
            }
            if (
              postData["endDate"] === "" ||
              postData["endDate"] === undefined ||
              postData["endDate"] === null
            ) {
              delete postData["endDate"];
            }
            if (
              postData["examTypeId"] === "" ||
              postData["examTypeId"] === undefined ||
              postData["examTypeId"] === null
            ) {
              delete postData["examTypeId"];
            }
            // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
            //   delete postData["subExamTypeId"];
            // }
            if (
              postData["baseCourseId"] === "" ||
              postData["baseCourseId"] === undefined ||
              postData["baseCourseId"] === null
            ) {
              delete postData["baseCourseId"];
            }
            if (
              postData["stateId"] === "" ||
              postData["stateId"] === undefined ||
              postData["stateId"] === null
            ) {
              delete postData["stateId"];
            }

            dispatch(
              assignLeadList(
                postData,
                (res: any) => {
                  setCount(res?.count);
                  setLeadList(res?.data);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    } else if (selectedStatus?._id === 5) {
      dispatch(
        assignAdmissionDoneLead(
          PostData,
          (res: any) => {
            setLeadList(res);
            setLeadCount("");
            const postData = {
              skip: 0,
              limit: 10,
              status: selectedStatus?._id,
              type: source?._id,
              startDate,
              endDate,
              stateId: state?._id,
              examTypeId: exam?._id,
              // subExamTypeId: subExam?._id,
              baseCourseId: examStream?._id,
            };
            if (
              postData["startDate"] === "" ||
              postData["startDate"] === undefined ||
              postData["startDate"] === null
            ) {
              delete postData["startDate"];
            }
            if (
              postData["endDate"] === "" ||
              postData["endDate"] === undefined ||
              postData["endDate"] === null
            ) {
              delete postData["endDate"];
            }
            if (
              postData["examTypeId"] === "" ||
              postData["examTypeId"] === undefined ||
              postData["examTypeId"] === null
            ) {
              delete postData["examTypeId"];
            }
            // if (postData["subExamTypeId"] === "" || postData["subExamTypeId"] === undefined || postData["subExamTypeId"] === null) {
            //   delete postData["subExamTypeId"];
            // }
            if (
              postData["baseCourseId"] === "" ||
              postData["baseCourseId"] === undefined ||
              postData["baseCourseId"] === null
            ) {
              delete postData["baseCourseId"];
            }
            if (
              postData["stateId"] === "" ||
              postData["stateId"] === undefined ||
              postData["stateId"] === null
            ) {
              delete postData["stateId"];
            }

            dispatch(
              admissionDoneLeadList(
                postData,
                (res: any) => {
                  setCount(res?.count);
                  setLeadList(res?.users);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    }
  };

  const searchValidation = () => {
    return source === "" || leadCount === "";
  };

  const renderSearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSubmit}
      >
        <div className="col-md-4 d-flex my-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Source :" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select Source"
              setInputText={(value: any) => onChangeSource(value)}
              value={source}
              options={sourceTypeList}
              reset={resetSource}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => changeEndDate(value)}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="State" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select State"
              setInputText={(value: any) => onChangeState(value)}
              value={state}
              options={stateList}
              reset={resetState}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name:" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
              reset={resetExam}
            />
          </div>
        </div>
        {/* {subExamList?.length > 0 ? (
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Sub Exam Name" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Sub Exam Name"
                setInputText={(value: any) => onChangeSubExam(value)}
                value={subExam}
                options={subExamList}
                reset={resetSubExam}
              // reset={resetSubexamList}
              />
            </div>
          </div>
        ) : null} */}
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Stream:" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select Exam Stream"
              setInputText={(value: any) => onChangeExamStreamSearch(value)}
              value={examStream}
              options={examStreamList}
              reset={resetExamStream}
            />
          </div>
        </div>

        {selectedStatus?._id === 5 ? (
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Course Type" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: any) => onChangeMainCategory(value)}
                value={mainCategory}
                options={courseTypeList}
                reset={resetMainCategory}
              />
            </div>
          </div>
        ) : null}

        {source?._id === 1 || source?._id === 4 ? (
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="College Name:" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName={"select College Name"}
                setInputText={(value: any) => onleadCollege(value)}
                value={leadCollege}
                options={collegeList}
                // Isrequired={true}
                reset={resetLeadCollege}
              />
            </div>
          </div>
        ) : null}
        <div className="d-flex mb-2 col-4">
          <LabelField lableName={"Lead Count :"} />
          <InputField
            placeholder="Enter lead Count"
            value={leadCount}
            onChangeInput={(value: any) => onchangeLeadCount(value)}
            maxlength={length_Ten}
            // minlength={length_Two}
          />
        </div>

        <div className="d-flex mb-2 col-4 me-4">
          <div>
            <SubmitButton name={"Assign"} validation={searchValidation()} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        {leadList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"LeadAssignment"}
            count={count}
            tableData={leadList ? leadList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        {selectedStatus?._id === 1 || selectedStatus?._id === 0 ? (
          <PaginationTable
            tableData={leadList ? leadList : []}
            pagename={"LeadAssignment"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "First Name",
              "Last Name",
              // "Mobile No.",
              // "Alternate Mobile No.",
              // "Email",
              "State",
              "City",
              "Exam Name",
              "Exam Stream",
              "Education Year",
              "Source",
              "Reference",
              "Registration Date",
            ]}
          />
        ) : selectedStatus?._id === 11 ? (
          <PaginationTable
            tableData={leadList ? leadList : []}
            pagename={"LeadAssignment"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "First Name",
              "Last Name",
              // "Mobile No.",
              // "Alternate Mobile No.",
              // "Email",
              "State",
              "City",
              "Exam Name",
              "Exam Stream",
              "Education Year",
              "Source",
              "Reference",
              "Expire Date",
            ]}
          />
        ) : (
          <PaginationTable
            tableData={leadList ? leadList : []}
            pagename={"LeadAssignment"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "First Name",
              "Last Name",
              // "Mobile No.",
              // "Alternate Mobile No.",
              // "Email",
              "State",
              "City",
              "Exam Name",
              "Exam Stream",
              "Education Year",
              "Source",
              "Reference",
              "Admission Date",
            ]}
          />
        )}
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Assignment"} />
      <div className="row m-1">
        <div className="formDiv">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div>
                {leadType?.map((data: any, index: number) => {
                  return (
                    <button
                      className={`  btn  me-4 mb-3 boxshadow p-2  rounded bgwhite ${
                        selectedStatus?._id === data?._id
                          ? "btn-primary"
                          : "btn btn-outline-dark"
                      }`}
                      // onClick={(e) => data?._id === 1 || data?._id === 0 ? onTabChange(e, data) : data?._id === 11 ? onTabChangeExpire(e, data) : onTabChangeAdmissionDone(e, data)}
                      onClick={(e) => onTabChange(e, data)}
                      type="button"
                    >
                      {data.name}
                    </button>
                  );
                })}
              </div>
            </div>
            {renderSearchMode()}
            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
