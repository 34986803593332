import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LabelField from "../../Component/labelField";
import {
  getCallHistory,
  getLeadPaymentHistory,
  getStudentDetailsById,
} from "../../Redux/Actions/counsellorPanelAction";
import moment from "moment";
import { addManagerRemark } from "../../Redux/Actions/managerPanelAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ReplyIcon from "@mui/icons-material/Reply";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import RemarkPopup from "../../Component/remarkPopup";
import { useSelector } from "react-redux";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import InputFieldRemark from "../../Component/inputFieldRemark";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";

const initialVal: {
  baseCourseId: string;
  examTypeId: string;
  subExamTypeId: string;
  maincategoryId: string;
  subcategoryId: string;
}[] = [
  {
    baseCourseId: "",
    examTypeId: "",
    subExamTypeId: "",
    maincategoryId: "",
    subcategoryId: "",
  },
];

export default function SMCompleteDetails() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    loggedInUserRole,
    selectedLeadId,
    sDate,
    eDate,
    selectedManagerId,
    selectedCounsellorId,
    selectedExamTypeId,
    selectedSubExamTypeId,
    selectedBaseCourseId,
    selectedMainCategoryId,
    selectedSubCategoryId,
    selectedStatus,
  } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    selectedLeadId: state.Auth.selectedLeadId,
    sDate: state.Auth.startDate,
    eDate: state.Auth.endDate,
    selectedManagerId: state.Auth.selectedManagerId,
    selectedCounsellorId: state.Auth.selectedCounsellorId,
    selectedExamTypeId: state.Auth.selectedExamTypeId,
    selectedSubExamTypeId: state.Auth.selectedSubExamTypeId,
    selectedBaseCourseId: state.Auth.selectedBaseCourseId,
    selectedMainCategoryId: state.Auth.selectedMainCategoryId,
    selectedSubCategoryId: state.Auth.selectedSubCategoryId,
    selectedStatus: state.Auth.selectedStatus,
  }));

  const counsellorData = location.state?.counsellorId;
  const pageName = location.state?.pageName;
  const leadStatus = location.state?.status;
  const leadSource = location.state?.source;
  const leadAnalysis = location.state?.analysis;
  const leadPage = location.state?.page;
  const leadRowsPerPage = location.state?.rowsPerPage;
  const examTypeId = location.state?.examTypeId;
  const basecourseId = location.state?.basecourseId;
  const courseTypeId = location.state?.courseTypeId;
  const LeadstudyMaterialId = location.state?.LeadstudyMaterialId;

  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [id, setId] = useState<any>("");
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [educationYear, setEducationYear] = useState<any>("");
  const [college, setCollege] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [mode, setMode] = useState<any>("view");
  const [introduction, setIntorduction] = useState<any>({
    isAudited: false,
    remark: "",
    name: "introduction",
  });
  const [prospecting, setProspecting] = useState<any>({
    isAudited: false,
    remark: "",
    name: "prospecting",
  });
  const [preparation, setPreparation] = useState<any>({
    isAudited: false,
    remark: "",
    name: "preparation",
  });
  const [approach, setApproach] = useState<any>({
    isAudited: false,
    remark: "",
    name: "approach",
  });
  const [presentation, setPresentation] = useState<any>({
    isAudited: false,
    remark: "",
    name: "presentation",
  });
  const [handlingObjections, setHandlingObjections] = useState<any>({
    isAudited: false,
    remark: "",
    name: "handlingObjections",
  });
  const [closing, setClosing] = useState<any>({
    isAudited: false,
    remark: "",
    name: "closing",
  });
  const [followUp, setFollowUp] = useState<any>({
    isAudited: false,
    remark: "",
    name: "followUp",
  });
  const [auditRemark, setAuditRemark] = useState<any>("");

  const [courseExamTypeInfoArr, setCourseExamTypeInfoArr] =
    useState<any>(initialVal);

  const [history, setHistory] = useState<any>(1);
  const [callHistoryData, setCallHistoryData] = useState([]);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);

  useEffect(() => {
    dispatch(
      getStudentDetailsById(
        selectedLeadId,
        (res: any) => {
          setFirstName(res?.data[0]?.firstName?.toUpperCase());
          setLastName(res?.data[0]?.lastName?.toUpperCase());
          setContact1(res?.data[0]?.mobileNo);
          setContact2(res?.data[0]?.altMobileNo);
          setEmail(res?.data[0]?.email);
          setState(res?.data[0]?.stateName);
          setCity(res?.data[0]?.cityName);
          setEducationYear(res?.data[0]?.educationYear);
          setCollege(res?.data[0]?.reference?.toUpperCase());
          let newArr = res?.data[0]?.courses?.map((item: any) => {
            return {
              baseCourseId: item?.baseCourseId?.name,
              examTypeId: item?.examTypeId?.name,
              subExamTypeId: item?.subExamTypeId?.name,
              maincategoryId: item?.maincategoryId?.name,
              subcategoryId: item?.subcategoryId?.name,
            };
          });
          setCourseExamTypeInfoArr(newArr);
          setStatus(res?.data[0]?.leadStatusId?.status);

          // setRemark(res?.data[0]?.comment);

          dispatch(
            getCallHistory(
              { studentLeadsId: selectedLeadId },
              (res: any) => {
                setCallHistoryData(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  }, []);

  const onClickHistory = (val: any) => {
    setHistory(val);
    if (val === 1) {
      dispatch(
        getCallHistory(
          { studentLeadsId: selectedLeadId },
          (res: any) => {
            setCallHistoryData(res);
          },
          () => {}
        )
      );
    } else if (val === 2) {
      dispatch(
        getLeadPaymentHistory(
          {
            _id: selectedLeadId,
          },
          (res: any) => {
            setPaymentHistoryData(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const handleEdit = (val: any) => {
    setOpenPopup(true);
    setOpenDeletePopup(false);
    setId(val?._id);
    if (loggedInUserRole === 1) {
      setIntorduction(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[0]
          : {
              isAudited: false,
              remark: "",
              name: "introduction",
            }
      );
      setProspecting(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[1]
          : {
              isAudited: false,
              remark: "",
              name: "prospecting",
            }
      );
      setPreparation(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[2]
          : {
              isAudited: false,
              remark: "",
              name: "preparation",
            }
      );
      setApproach(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[3]
          : {
              isAudited: false,
              remark: "",
              name: "approach",
            }
      );
      setPresentation(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[4]
          : {
              isAudited: false,
              remark: "",
              name: "presentation",
            }
      );
      setHandlingObjections(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[5]
          : {
              isAudited: false,
              remark: "",
              name: "handlingObjections",
            }
      );
      setClosing(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[6]
          : {
              isAudited: false,
              remark: "",
              name: "closing",
            }
      );
      setFollowUp(
        val?.managerLeadAuditRemark
          ? val?.managerLeadAuditRemark?.remarkData[7]
          : {
              isAudited: false,
              remark: "",
              name: "followup",
            }
      );
      setAuditRemark(val?.managerLeadAuditRemark?.overallRemark);
      setMode("edit");
    } else if (loggedInUserRole === 2) {
      setIntorduction(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[0]
          : {
              isAudited: false,
              remark: "",
              name: "introduction",
            }
      );
      setProspecting(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[1]
          : {
              isAudited: false,
              remark: "",
              name: "prospecting",
            }
      );
      setPreparation(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[2]
          : {
              isAudited: false,
              remark: "",
              name: "preparation",
            }
      );
      setApproach(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[3]
          : {
              isAudited: false,
              remark: "",
              name: "approach",
            }
      );
      setPresentation(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[4]
          : {
              isAudited: false,
              remark: "",
              name: "presentation",
            }
      );
      setHandlingObjections(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[5]
          : {
              isAudited: false,
              remark: "",
              name: "handlingObjections",
            }
      );
      setClosing(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[6]
          : {
              isAudited: false,
              remark: "",
              name: "closing",
            }
      );
      setFollowUp(
        val?.teamLeadAuditRemark
          ? val?.teamLeadAuditRemark?.remarkData[7]
          : {
              isAudited: false,
              remark: "",
              name: "followUp",
            }
      );
      setAuditRemark(val?.teamLeadAuditRemark?.overallRemark);
      setMode("edit");
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const changeRemark = (val: any) => {
    setAuditRemark(val);
  };

  const handleSubmitManagerRemark = () => {
    if (introduction.isAudited) {
      delete introduction.remark;
    }
    if (prospecting.isAudited) {
      delete prospecting.remark;
    }
    if (preparation.isAudited) {
      delete preparation.remark;
    }
    if (approach.isAudited) {
      delete approach.remark;
    }
    if (presentation.isAudited) {
      delete presentation.remark;
    }
    if (handlingObjections.isAudited) {
      delete handlingObjections.remark;
    }
    if (closing.isAudited) {
      delete closing.remark;
    }
    if (followUp.isAudited) {
      delete followUp.remark;
    }
    let data: any = {};
    if (
      introduction.remark === "" ||
      prospecting.remark === "" ||
      preparation.remark === "" ||
      approach.remark === "" ||
      presentation.remark === "" ||
      handlingObjections.remark === "" ||
      closing.remark === "" ||
      followUp.remark === "" ||
      auditRemark === ""
    ) {
      dispatch(
        showErrorSnackbar("Remark is mandatory if toggle is OFF") as any
      );
    } else {
      if (loggedInUserRole === 1) {
        data["managerLeadRemark"] = {
          remarkData: [
            introduction,
            prospecting,
            preparation,
            approach,
            presentation,
            handlingObjections,
            closing,
            followUp,
          ],
          overallRemark: auditRemark,
        };
      } else if (loggedInUserRole === 2) {
        data["teamLeadRemark"] = {
          remarkData: [
            introduction,
            prospecting,
            preparation,
            approach,
            presentation,
            handlingObjections,
            closing,
            followUp,
          ],
          overallRemark: auditRemark,
        };
      }

      dispatch(
        addManagerRemark(
          id,
          data,
          () => {
            setOpenPopup(false);
            dispatch(
              getCallHistory(
                {
                  studentLeadsId: selectedLeadId,
                  counsellorId: counsellorData?.counsellorId,
                },
                (res: any) => {
                  setCallHistoryData(res);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    }
  };

  const handleTeamLeadRemarkView = (val: any) => {
    setOpenDeletePopup(true);
    setIntorduction(val?.teamLeadAuditRemark?.remarkData[0]);
    setProspecting(val?.teamLeadAuditRemark?.remarkData[1]);
    setPreparation(val?.teamLeadAuditRemark?.remarkData[2]);
    setApproach(val?.teamLeadAuditRemark?.remarkData[3]);
    setPresentation(val?.teamLeadAuditRemark?.remarkData[4]);
    setHandlingObjections(val?.teamLeadAuditRemark?.remarkData[5]);
    setClosing(val?.teamLeadAuditRemark?.remarkData[6]);
    setFollowUp(val?.teamLeadAuditRemark?.remarkData[7]);
    setAuditRemark(val?.teamLeadAuditRemark?.overallRemark);
    setMode("view");
  };

  const handleManagerRemarkView = (val: any) => {
    setOpenDeletePopup(true);
    setIntorduction(val?.managerLeadAuditRemark?.remarkData[0]);
    setProspecting(val?.managerLeadAuditRemark?.remarkData[1]);
    setPreparation(val?.managerLeadAuditRemark?.remarkData[2]);
    setApproach(val?.managerLeadAuditRemark?.remarkData[3]);
    setPresentation(val?.managerLeadAuditRemark?.remarkData[4]);
    setHandlingObjections(val?.managerLeadAuditRemark?.remarkData[5]);
    setClosing(val?.managerLeadAuditRemark?.remarkData[6]);
    setFollowUp(val?.managerLeadAuditRemark?.remarkData[7]);
    setAuditRemark(val?.managerLeadAuditRemark?.overallRemark);
    setMode("view");
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
    setIntorduction("");
    setProspecting("");
    setPreparation("");
    setApproach("");
    setPresentation("");
    setHandlingObjections("");
    setClosing("");
    setFollowUp("");
    setAuditRemark("");
  };

  const viewPDF = (val: any) => {
    sessionStorage.setItem("pdfLink", val);
    window.open(
      "/PdfViewer",
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes"
    );
  };

  const handleBack = () => {
    if (pageName === "ManagerLeadAnalysis") {
      navigate("../counsellorCallDetails", {
        state: {
          data: counsellorData,
          pageName: "callerDetails",
          status: leadStatus,
          source: leadSource,
          sdate: sDate,
          edate: eDate,
          analysis: leadAnalysis,
          page: leadPage,
          rowsPerPage: leadRowsPerPage,
        },
      });
    } else if (pageName === "summaryOverview") {
      navigate("../summeryOverview", {
        state: {
          data: counsellorData,
          pageName: "callerDetails",
        },
      });
    } else if (pageName === "leadAssignedStatusOverview") {
      navigate("../leadAssignedStatusOverview", {
        state: {
          counsellorId: counsellorData,
          pageName: "callerDetails",
          status: leadStatus?._id,
          type: leadSource?._id,
          startDate: sDate,
          endDate: eDate,
        },
      });
    } else if (pageName === "sAenrolledAnalysis") {
      navigate("../sAenrolledAnalysis", {
        state: {
          counsellorId: counsellorData,
          pageName: "callerDetails",
          startDate: sDate,
          endDate: eDate,
        },
      });
    } else if (pageName === "timeAnalysisOverview") {
      navigate("../timeAnalysisOverview", {
        state: {
          counsellorId: counsellorData,
          pageName: "callerDetails",
          // startDate: sDate,
          // endDate: eDate,
        },
      });
    } else if (pageName === "courseAnalysis") {
      navigate("../courseAnalysis", {
        state: {
          // counsellorId: counsellorData,
          pageName: "completeLeadDetail",
          // startDate: sDate,
          // endDate: eDate,
          // examTypeId: examTypeId,
          // basecourseId: basecourseId,
          // courseTypeId: courseTypeId,
          // studyMaterialId: LeadstudyMaterialId,
        },
      });
    } else if (pageName === "SMrefrenceGeneratedAnalysis") {
      navigate("../saReferenceAnalysis", {
        state: {
          counsellorId: counsellorData,
          pageName: "callerDetails",
          startDate: sDate,
          endDate: eDate,
        },
      });
    } else if (pageName === "counsellorRemark") {
      navigate("../counsellorRemark", {
        state: {
          counsellorId: counsellorData,
          pageName: "callerDetails",
          startDate: sDate,
          endDate: eDate,
        },
      });
    }
  };

  return (
    <div className="contentBox">
      <div className="col-md-12">
      <div className="formDiv">
        <div className="mx-2 my-1">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => handleBack()}
          >
            <ReplyIcon /> Back
          </button>
        </div>

        <div className="row m-0">
          <div className="col-md-12 leaddetails">
            <div className="boxshadow mx-5 my-1">
              <div className="p-1 d-flex justify-content-space-around">
                <div className="detailsdiv col-md-6 row d-flex">
                  <div className="mx-2 p-2">
                    <div className="col-md-12 col-sm-12 p-2">
                      <div className="tabheader p-2">Personnel & Contact </div>
                      <div className="row d-flex justify-content-between pt-2 ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="First Name" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{firstName}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Last Name" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{lastName}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 1" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          {/* <p>{contact1}</p> */}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Contact 2" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          {/* <p>{contact2}</p> */}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Email" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{email}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="State" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{state}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="City" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{city}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="College Name" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{college}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 p-2">
                      <div className="tabheader p-2">Courses</div>
                      <div className="row d-flex justify-content-between pt-2 ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Course" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>
                            {courseExamTypeInfoArr
                              ? courseExamTypeInfoArr[0]?.baseCourseId
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between  ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Sub Courses" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>
                            {courseExamTypeInfoArr
                              ? courseExamTypeInfoArr[0]?.baseCourseId
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Course Type" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>
                            {courseExamTypeInfoArr
                              ? courseExamTypeInfoArr[0]?.examTypeId
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Study Material" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>
                            {courseExamTypeInfoArr
                              ? courseExamTypeInfoArr[0]?.maincategoryId
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Qualify Year" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{educationYear}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Start Date" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="End Date" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{}</p>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Status" />
                        </div>
                        <div className="col-md-6 d-flex justify-content-end me-2">
                          <p>{status}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailsdiv col-md-6 row d-flex">
                  <div className="mx-3 p-2 mt-2">
                    <div className="d-flex justify-content-between tabheader p-1 mx-2">
                      <button
                        className={
                          history === 1
                            ? "btn btn-sm btn-success"
                            : "btn btn-sm btn-secondary"
                        }
                        onClick={() => onClickHistory(1)}
                      >
                        Call History
                      </button>
                      <button
                        className={
                          history === 2
                            ? "btn btn-sm btn-success"
                            : "btn btn-sm btn-secondary"
                        }
                        onClick={() => onClickHistory(2)}
                      >
                        Payment History
                      </button>
                    </div>
                    <div className="historydetails">
                      {history === 1
                        ? callHistoryData?.map((value: any) => {
                            return (
                              <div className="my-2">
                                {value?.createdAt ? (
                                  <p className=" ps-4 fw-bold    mb-0 text-primary">
                                    Updated Date :{" "}
                                    {moment(value?.createdAt)
                                      .utc()
                                      .format("DD-MM-YYYY hh:mm:ss a")}
                                  </p>
                                ) : null}
                                {value?.callBackDate ? (
                                  <p className=" ps-4 fw-bold    mb-0 text-primary">
                                    Call Back Date :{" "}
                                    {moment(value?.callBackDate)
                                      .utc()
                                      .format("DD-MM-YYYY hh:mm:ss a")}
                                  </p>
                                ) : null}
                                {value?.call_date ? (
                                  <div className="d-flex justify-content-between">
                                    <span className="ps-4 fw-bold mb-0 text-secondary">
                                      Called Date & Time :{" "}
                                      {moment(value?.call_date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      {value?.call_time}
                                    </span>
                                  </div>
                                ) : null}
                                {value?.calltype ? (
                                  <div className="d-flex justify-content-between">
                                    <span className="ps-4 fw-bold mb-0 text-secondary">
                                      Call Type : {value?.calltype}
                                    </span>
                                  </div>
                                ) : null}
                                {value?.call_duration ? (
                                  <div className="d-flex justify-content-between">
                                    <span className="ps-4 fw-bold mb-0 text-secondary">
                                      Call Duration : {value?.call_duration}
                                    </span>
                                  </div>
                                ) : null}
                                {value?.call_transfer_status ? (
                                  <div className="d-flex justify-content-between">
                                    <span className="ps-4 fw-bold mb-0 text-secondary">
                                      Call Status :{" "}
                                      {value?.call_transfer_status}
                                    </span>
                                  </div>
                                ) : null}

                                <p className=" ps-4  mb-0 text-success">
                                  {value?.courses[0]?.baseCourseName}
                                </p>
                                <p className=" ps-4 mb-0 text-danger">
                                  {value?.courses[0]?.examTypeName}
                                </p>
                                {value?.courses[0]?.mainCategoryName ? (
                                  <p className=" ps-4 mb-0  ">
                                    {value?.courses[0]?.mainCategoryName}
                                  </p>
                                ) : null}
                                <p className=" ps-4  mb-0 text-danger">-----</p>
                                <p className=" ps-4 mb-0 text-primary ">
                                  Status : {value?.leadStatus?.status}
                                </p>

                                <p className=" ps-4 mb-0 text-success">
                                  Status Details :{" "}
                                  {value?.leadSubStatus?.status}
                                </p>
                                {value?.couponName ? (
                                  <p className=" ps-4 mb-0  ">
                                    Coupon Code :{" "}
                                    <span className=" mb-0  text-secondary">
                                      {value?.couponName}
                                    </span>
                                  </p>
                                ) : null}
                                <div className="d-flex justify-content-center">
                                  {value?.callRecords ? (
                                    value?.callRecords?.map((item: any) => {
                                      return (
                                        <audio
                                          className="w-100 p-2 mx-2"
                                          src={item?.rURL}
                                          controls
                                        ></audio>
                                      );
                                    })
                                  ) : value?.call_recording &&
                                    value?.call_recording !== "None" ? (
                                    <audio
                                      className="w-100 p-2 mx-2"
                                      src={value?.call_recording}
                                      controls
                                    ></audio>
                                  ) : null}
                                </div>
                                {value?.comment ? (
                                  <p className=" ps-4 mb-0  ">
                                    Comment :{" "}
                                    <span className=" mb-0  text-secondary">
                                      {value?.comment}
                                    </span>
                                  </p>
                                ) : null}
                                {/* {value?.remark ? ( */}
                                <p className=" ps-4 mb-0  ">
                                  Team Lead Comment :{" "}
                                  <span className=" mb-0  text-secondary">
                                    {/* {value?.teamLeadRemark} */}
                                  </span>
                                  {loggedInUserRole === 2 &&
                                  (pageName === "ManagerLeadAnalysis" ||
                                    pageName === "leadAssignedStatusOverview" ||
                                    pageName === "enrolledAnalysis" ||
                                    pageName === "courseAnalysis" ||
                                    pageName === "managerRemark" ||
                                    pageName === "summaryOverview") ? (
                                    <>
                                      <span
                                        className="text-Dark fw-bold me-2 ms-2 cursor"
                                        onClick={() => handleEdit(value)}
                                      >
                                        <BorderColorIcon />
                                      </span>
                                      {value?.teamLeadAuditRemark ? (
                                        <span
                                          className="text-Dark fw-bold me-2 ms-2 cursor"
                                          onClick={() =>
                                            handleTeamLeadRemarkView(value)
                                          }
                                        >
                                          <span className="text-success">
                                            <VisibilityIcon />
                                            View
                                          </span>
                                        </span>
                                      ) : null}
                                    </>
                                  ) : value?.teamLeadAuditRemark?.remarkData
                                      ?.length > 0 ? (
                                    <span
                                      className="text-Dark fw-bold me-2 ms-2 cursor"
                                      onClick={() =>
                                        handleTeamLeadRemarkView(value)
                                      }
                                    >
                                      <span className="text-primary">
                                        <VisibilityIcon />
                                        View
                                      </span>
                                    </span>
                                  ) : (
                                    <td></td>
                                  )}
                                </p>

                                <p className=" ps-4 mb-0  ">
                                  Manager Comment :{" "}
                                  <span className=" mb-0  text-secondary">
                                    {/* {value?.managerRemark} */}
                                  </span>
                                  {loggedInUserRole === 1 &&
                                  (pageName === "ManagerLeadAnalysis" ||
                                    pageName === "leadAssignedStatusOverview" ||
                                    pageName === "enrolledAnalysis" ||
                                    pageName === "courseAnalysis" ||
                                    pageName === "managerRemark" ||
                                    pageName === "summaryOverview") ? (
                                    <>
                                      <span
                                        className="text-Dark fw-bold me-2 ms-2 cursor"
                                        onClick={() => handleEdit(value)}
                                      >
                                        <BorderColorIcon />
                                      </span>
                                      {value?.managerLeadAuditRemark ? (
                                        <span
                                          className="text-Dark fw-bold me-2 ms-2 cursor"
                                          onClick={() =>
                                            handleManagerRemarkView(value)
                                          }
                                        >
                                          <span className="text-success">
                                            <VisibilityIcon />
                                            View
                                          </span>
                                        </span>
                                      ) : null}
                                    </>
                                  ) : value?.managerLeadAuditRemark?.remarkData
                                      ?.length > 0 ? (
                                    <span
                                      className="text-Dark fw-bold me-2 ms-2 cursor"
                                      onClick={() =>
                                        handleManagerRemarkView(value)
                                      }
                                    >
                                      <span className="text-success">
                                        <VisibilityIcon />
                                        View
                                      </span>
                                    </span>
                                  ) : (
                                    <td></td>
                                  )}
                                </p>

                                {/* ) : null} */}
                                <hr />
                              </div>
                            );
                          })
                        : paymentHistoryData?.map((value: any) => {
                            return (
                              <div className="my-2">
                                {value?.course ? (
                                  <p className=" ps-4 fw-bold    mb-0 text-primary">
                                    Course : {value?.course?.name}
                                  </p>
                                ) : null}
                                {value?.orderDate ? (
                                  <p className=" ps-4 fw-bold    mb-0 text-secondary">
                                    Order Date :{" "}
                                    {moment(value?.orderDate).format(
                                      "DD-MM-YYYY hh:mm:ss a"
                                    )}
                                  </p>
                                ) : null}
                                {value?.netPayableAmount ? (
                                  <p className=" ps-4 mb-0  ">
                                    Fees Paid :{" "}
                                    <span className=" mb-0  text-secondary">
                                      {value?.netPayableAmount}
                                    </span>
                                  </p>
                                ) : null}
                                {value?.couponName ? (
                                  <p className=" ps-4 mb-0  ">
                                    Coupon Code :{" "}
                                    <span className=" mb-0  text-secondary">
                                      {value?.couponName}
                                    </span>
                                  </p>
                                ) : null}
                                <p className=" ps-4  mb-0 text-danger">-----</p>
                                {value?.subscriptionPlanMonths ? (
                                  <p className=" ps-4 mb-0  ">
                                    Subscription Months :{" "}
                                    {value?.subscriptionPlanMonths}
                                  </p>
                                ) : null}
                                {value?.userSubscriptionEndDate ? (
                                  <p className=" ps-4 fw-bold    mb-0 text-secondary">
                                    Course End Date :{" "}
                                    {moment(
                                      value?.userSubscriptionEndDate
                                    ).format("DD-MM-YYYY hh:mm:ss a")}
                                  </p>
                                ) : null}
                                <hr />
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={openPopup}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopup}>
          <div className="modeldivChat p-3">
            <div className="mb-2 d-flex justify-content-between ">
              <p className="fw-bold">
                <span style={{ color: "#3F51B5" }}>Remark</span>
              </p>
              <ClearOutlinedIcon
                className="colorPurple cursor"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className="form-check">
              <InputFieldRemark
                id={"introduction"}
                labelName={"Introduction"}
                placeholder={"Enter Introduction"}
                setInputText={(val: any) => setIntorduction(val)}
                value={introduction}
                disabled={introduction?.isAudited ? true : false}
                mode={mode}
              />
              <InputFieldRemark
                id={"prospecting"}
                labelName={"Prospecting"}
                placeholder={"Enter Prospecting"}
                setInputText={(val: any) => setProspecting(val)}
                value={prospecting}
                disabled={prospecting?.isAudited ? true : false}
                mode={mode}
              />
              <InputFieldRemark
                id={"preparation"}
                labelName={"Preparation"}
                placeholder={"Enter Preparation"}
                setInputText={(val: any) => setPreparation(val)}
                value={preparation}
                disabled={preparation?.isAudited ? true : false}
                mode={mode}
              />
              <InputFieldRemark
                id={"approach"}
                labelName={"Approach"}
                placeholder={"Enter Approach"}
                setInputText={(val: any) => setApproach(val)}
                value={approach}
                disabled={approach?.isAudited ? true : false}
                mode={mode}
              />
              <InputFieldRemark
                id={"presentation"}
                labelName={"Presentation"}
                placeholder={"Enter Presentation"}
                setInputText={(val: any) => setPresentation(val)}
                value={presentation}
                disabled={presentation?.isAudited ? true : false}
                mode={mode}
              />
              <InputFieldRemark
                id={"handlingObjections"}
                labelName={"Handling Objections"}
                placeholder={"Enter Handling Objections"}
                setInputText={(val: any) => setHandlingObjections(val)}
                value={handlingObjections}
                disabled={handlingObjections?.isAudited ? true : false}
                mode={mode}
              />
              <InputFieldRemark
                id={"closing"}
                labelName={"Closing"}
                placeholder={"Enter Closing"}
                setInputText={(val: any) => setClosing(val)}
                value={closing}
                disabled={closing?.isAudited ? true : false}
                mode={mode}
              />
              <InputFieldRemark
                id={"followUp"}
                labelName={"Follow Up"}
                placeholder={"Enter Follow Up"}
                setInputText={(val: any) => setFollowUp(val)}
                value={followUp}
                disabled={followUp?.isAudited ? true : false}
                mode={mode}
              />
              <div className="d-flex col-12">
                <div className="col-2">
                  <LabelField lableName="Overall Remark" />
                </div>
                <div className="d-flex col-10">
                  <textarea
                    className="form-control"
                    placeholder={"Comment"}
                    value={auditRemark}
                    onChange={(e) => changeRemark(e.target.value)}
                  />
                  <span className="required ms-2 fs-6">*</span>
                </div>
              </div>
              <div className="col-auto d-flex justify-content-center mt-2">
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="submit"
                  className="btn btn-outline-dark mb-3 submitbtn me-3"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleSubmitManagerRemark();
                  }}
                  type="submit"
                  className="btn btn-primary mb-3 submitbtn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {openDeletePopup ? (
        <RemarkPopup
          open={openDeletePopup}
          handleClose={handleCloseDelete}
          handleSubmitRestore={() => {}}
          title={"Remark"}
          mode={mode}
          introduction={introduction}
          prospecting={prospecting}
          preparation={preparation}
          approach={approach}
          presentation={presentation}
          handlingObjections={handlingObjections}
          closing={closing}
          followUp={followUp}
          auditRemark={auditRemark}
        />
      ) : (
        ""
      )}
    </div></div>
  );
}
