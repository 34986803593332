
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Component/pageTitle';
import SubmitButton from '../../Component/submitButton';
import InputField from '../../Component/inputField'
import LabelField from '../../Component/labelField';
import { containsNumber, length_Ten, validateValueWithoutSpace } from '../../DATA/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnlyErrMsg } from '../../DATA/errorMsg';
import { getTotalConnectedcall,updateTotalConnectedCall} from '../../Redux/Actions/bdeAdminPanelAction';

export default function TotalConnectedCount() {
  const dispatch = useDispatch();

  const [updatedTotalCount, setUpdatedTotalCount] = useState("");
  const [id, setId] = useState<any>("");

  useEffect(() => {
    dispatch(
      getTotalConnectedcall(
        "",
        (res: any) => {
          setId(res?._id);
          setUpdatedTotalCount(res?.count);
        },
        () => { }
      )
    );

  }, []);


  const onchangeTotalCount = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setUpdatedTotalCount(value);
      }
    } else {
        setUpdatedTotalCount(value);
    }
  }



  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      count: updatedTotalCount
    }
    dispatch(
      updateTotalConnectedCall(
        id,
        postData,
        (res: any) => {
          dispatch(
            getTotalConnectedcall(
              "",
              (res: any) => {
                setUpdatedTotalCount(res?.count);
                setId(res?._id);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  

  };


  const renderSearchMode = () => {
    return (
      <form className="d-flex" onSubmit={handleSubmit}>

        <div className="d-flex mb-2 col-6">
          <LabelField lableName={" BDE Connected Call :"} />
          <InputField
            placeholder="Enter Connected Call"
            value={updatedTotalCount}
            onChangeInput={(value: any) => onchangeTotalCount(value)}
            maxlength={length_Ten}
          
          />
        </div>
        <div className="d-flex mb-2 col-4 me-4">
          <div>
            <SubmitButton name={"Update"} />
          </div>
        </div>
      </form>
    );
  };


  return (
    <div className="contentBox ">
      <PageTitle name={" Connected Call Target"} />
      <div className=" p-3 formDiv">
         
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div>
              </div>
            </div>
            {renderSearchMode()}
          </div>
      </div>
    </div>
  );
}

