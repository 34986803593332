import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { Link } from "react-router-dom";
import { CrmLogout } from "../Redux/Actions/AuthAction";
import { showSuccessSnackbar } from "../Redux/Actions/snackbarAction";
import { useEffect, useState } from "react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import localImages from "../Assets/localImages";
import { useSelector } from "react-redux";
export default function Sidebar(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuIcon, setMenuIcon] = useState<any>(false);
  const currentURLarr = useLocation().pathname.split("/");
  let link = currentURLarr[currentURLarr.length - 1];
  const { loggedInUserName } = useSelector((state: any) => ({
    loggedInUserName: state.Auth.loggedInUserName,
  }));
  const SignOut = () => {
    dispatch(
      CrmLogout(
        (res: any) => {
          dispatch(showSuccessSnackbar("Logout Succesfully"));
          navigate("/");
        },
        () => {}
      )
    );
  };
  return (
    <>
      <div id="mySidenav" className="sidenav">
        <div className="userInfo d-flex align-items-center  justify-content-center pt-3">
          <img src={localImages.logo} className="logo" />
        </div>
        <div className="d-flex justify-content-center border-bottom">
          <div className=" p-2">
            <div className="d-flex justify-content-center ">
              <div className="profilebtn ">
                <img
                  className="img-fluid mb-2  "
                  src={localImages.profile}
                  alt="profile"
                />
              </div>
            </div>
            <h6 className=" fw-bold text-start d-flex align-items-center mt-2 text-uppercase">
              {loggedInUserName}
            </h6>
          </div>
        </div>
        <div className="overflow-auto">
          <ul className="sidebarList">
            {" "}
            <div className="accordion" id="accordionExample">
              {" "}
              {props?.data?.map((val: any, key: any) => {
                if (val?.subNav === undefined) {
                  return (
                    <li
                      key={key}
                      className={
                        val?.path === link
                          ? "sideListRow active"
                          : "sideListRow"
                      }
                    >
                      <Link to={val?.path}>{val?.title}</Link>
                      <span></span>
                    </li>
                  );
                } else {
                  return (
                    <div className="accordion-item rounded-0">
                      <h2 className="accordion-header" id={"heading" + key}>
                        <button
                          className="accordion-button collapsed p-2"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapse" + key}
                          aria-expanded="false"
                          aria-controls={"collapse" + key}
                        >
                          {val?.title}
                        </button>
                      </h2>
                      <div
                        id={"collapse" + key}
                        className="accordion-collapse collapse"
                        aria-labelledby={"heading" + key}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body p-0">
                          {val?.subNav?.map((data: any, index: any) => {
                            return (
                              <li
                                key={index}
                                id={index}
                                // onClick={() => handleResetEditMode(val.title)}
                                className={
                                  data.path === link
                                    ? "sideListRow active"
                                    : "sideListRow"
                                }
                              >
                                <Link to={data.path}>{data.title}</Link>
                              </li>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </ul>
        </div>

        <div className="logout cursor border-top d-flex" onClick={SignOut}>
          <div className="me-2 text-danger">
            <PowerSettingsNewIcon />
          </div>
          <h6 className="mb-0 fw-bold text-danger">Logout</h6>
        </div>
      </div>
      {/* <div className="sidebar">
        {" "}
        <ul className="sidebarList">
          {" "}
          <div className="accordion" id="accordionExample">
            {" "}
            {props?.data?.map((val: any, key: any) => {
              if (val?.subNav === undefined) {
                return (
                  <li
                    key={key}
                    className={
                      val?.path === link ? "sideListRow active" : "sideListRow"
                    }
                  >
                    <Link to={val?.path}>{val?.title}</Link>
                    <span></span>
                  </li>
                );
              } else {
                return (
                  <div className="accordion-item rounded-0">
                    <h2 className="accordion-header " id={"heading" + key}>
                      <button
                        className="accordion-button collapsed font-14"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + key}
                        aria-expanded="false"
                        aria-controls={"collapse" + key}
                      >
                        {val?.title}
                      </button>
                    </h2>
                    <div
                      id={"collapse" + key}
                      className="accordion-collapse collapse"
                      aria-labelledby={"heading" + key}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        {val?.subNav?.map((data: any, index: any) => {
                          return (
                            <li
                              key={index}
                              id={index}
                              // onClick={() => handleResetEditMode(val.title)}
                              className={
                                data.path === link
                                  ? "sideListRow active"
                                  : "sideListRow"
                              }
                            >
                              <Link to={data.path}>{data.title}</Link>
                            </li>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </ul>
      </div> */}
    </>
  );
}
