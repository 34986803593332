import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import PageTitle from '../../Component/pageTitle';
import DatePicker from '../../Component/datePicker';
import LabelField from '../../Component/labelField';
import SubmitButton from '../../Component/submitButton';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getEmployeetByManagerId, getTeamLeadAssignedOverview, getTeamLeadListByManager } from '../../Redux/Actions/managerPanelAction';
import CustomDropdown from '../../Component/customDropdown';
import { getManagerList } from '../../Redux/Actions/leadManagementAction';
import { getSMManagerList, getSMTeamLeadOverview } from '../../Redux/Actions/superManagerAction';

export default function TeamLeadOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(moment.utc(new Date()).format("YYYY-MM-DD"));
  const [endDate, SetEndDate] = useState<any>(moment.utc(new Date()).format("YYYY-MM-DD"));
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [leadsDataList, setLeadsDataList] = useState<any>([]);
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const { executiveID,loggedInUserRole ,loggedInUserId} = useSelector((state: any) => ({
    executiveID: state.Auth.executiveID,
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
    
  }));

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );
}, []);

  const onClickLead = (e:any, data:any) => {
    navigate("../teamLeadOverViewDetails", {  state: { leadDataDetails: data ,   startDate:startDate,
      endDate:endDate} });
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
     const data = {
      startDate,
      endDate,
      managerId:manager?._id,
      teamLeadId:teamLead?._id
    };
    dispatch(
      getSMTeamLeadOverview             (
        loggedInUserId,
        data,
        (res:any) => {
          setLeadsDataList(res);
        },
        () => {}
      )
    );
    

  }
  const onChangeManager = (value: any) => {
    setManager(value); 
    dispatch(
      getEmployeetByManagerId(
        value?._id,
        (res: any) => {
        
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (value: any) => {
    setTeamLead(value);
  
  
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex justify-content-between " onSubmit={handleSubmit}>
        <div className="col-md-3 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
            />
          </div>
        </div>
        
          <div className="col-md-3 d-flex mb-3">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Manager" />
            </div>
            <div className="col-md-8 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                // Isrequired={true}
              />
            </div>
          </div>
            
          <div className="col-md-3 d-flex mb-3 ">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Team Lead" />
              </div>
              <div className="col-md-8  ">
                <CustomDropdown
                  lableName="Select Team Lead"
                  setInputText={(value: any) => onChangeTeamLead(value)}
                  value={teamLead}
                  options={teamLeadList}
                />
              </div>
            </div>
        

        <div className="col-md-12 d-flex justify-content-center mb-3">
          <div className=" ">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
         <table className="w-100 ">
            <tr className="">
              <th>S.N</th>
              <th>Date</th>
              <th>Source</th>
            </tr>
            <tbody className=" ">
              {
                leadsDataList?.map((item:any, index:any)=>{
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.assignedDate}</td>
                      <td>
                        <table className="w-100">
                          <tr>
                            <th>Total</th>
                            <th>{item?.totalLeadCount}</th>
                          </tr>
                          {item?.data?.map((subItem:any) => {
                            return (
                              <tr>
                                <td>{subItem?.firstName} {" "} {subItem?.lastName}</td>
                                <td onClick={(e) => onClickLead(e , subItem )}>
                                  <span className="leaddetailsOverView cursor">
                                    {subItem?.count}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Total Assigned Leads"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}

