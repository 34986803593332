import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import { ifasianList } from "../../DATA/dataConstant";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import Header from "../../Component/header";
import DateTime from "../../Component/dateTime";
import moment from "moment";
import WebinarSchedule from "../../Component/webinarSchedule";
import CallIcon from "@mui/icons-material/Call";
import ReplyIcon from "@mui/icons-material/Reply";

import {
  containsNumber,
  hasWhiteSpace,
  length_Fifteen,
  length_One,
  length_Ten,
  length_Thirty,
  length_Three,
  length_Twenty,
  length_Two,
  length_TwoHundred,
  startsWithSpace,
  validateEmailAddress,
  validateFullName,
  validateIsLettersAndDotOnly,
  validateIsLettersAndSpaceOnly,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  validateEmailAddressErrMsg,
  validateIsAlphabetErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import {
  addDepartment,
  getLeadStatusList,
  getSubStatusbyStatusID,
  getLeadType,
  getExamStreamList,
  getDetailsbyDepartmentID,
  getCallHistory,
  updateDepartmentDetails,
  makeCalltoLeadBde,
  getWebinarListByDeptID,
  getDepartmentList,
  getVrifyNumber,
} from "../../Redux/Actions/bdExecutivePanelAction";
import {
  getExamList,
  getSubExam,
} from "../../Redux/Actions/counsellorPanelAction";
import EditWebinarSchedule from "../../Component/editWebinarSchedule";
export default function Departmentdetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const collegeData = location.state?.college;
  const departmentID = location.state?.departmentId;
  const IsEdit = location.state?.IsEdit;
  const IsCheck = location.state?.IsCheck;
  const pageName = location.state?.pageName;
  const startDate = location.state?.startDate;
  const endDate = location.state?.endDate;
  const [firstName1, setFirstName1] = useState<any>("");
  const [lastName1, setLastName1] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email1, setEmail1] = useState<any>("");
  const [designation1, setDesignation1] = useState<any>("");
  const [firstName2, setFirstName2] = useState<any>("");
  const [lastName2, setLastName2] = useState<any>("");
  const [mob1, setMob1] = useState<any>("");
  const [mob2, setMob2] = useState<any>("");
  const [email2, setEmail2] = useState<any>("");
  const [designation2, setDesignation2] = useState<any>("");
  const [college, setCollege] = useState<any>("");
  const [ifasian1, setIfasian1] = useState<any>("");
  const [ifasian2, setIfasian2] = useState<any>("");
  const [department, setDepartment] = useState("");
  const [firstYear, setFirstYear] = useState("");
  const [secondYear, setSecondYear] = useState("");
  const [thirdYear, setThirdYear] = useState("");
  const [departmentstatus, setDepartmentstatus] = useState<any>("");
  const [departmentstatusList, setDepartmentstatusList] = useState([]);
  const [resetDepartmentstatus, setResetDepartmentstatus] = useState(false);
  const [statusDetails, setStatusDetails] = useState<any>("");
  const [statusDetailsList, setStatusDetailsList] = useState<any>([]);
  const [resetStatusDetails, setResetStatusDetails] = useState(false);
  const [callDate, setCallDate] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [openPopup, setOpenPopup] = useState(false);
  const [leadType, setLeadType] = useState<any>("");
  const [leadTypeList, setLeadTypeList] = useState([]);
  const [resetLeadType, setResetLeadType] = useState(false);
  const [exam, setExam] = useState<any>();
  const [examList, setExamList] = useState([]);
  const [resetExamList, setResetExamList] = useState(false);
  const [examStream, setExamStream] = useState<any>("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [resetExamStreamList, setResetExamStreamList] = useState(false);
  const [subExam, setSubExam] = useState<any>();
  const [subExamList, setSubExamList] = useState([]);
  const [resetSubExam, setResetSubExam] = useState();
  const [departmentDeatils, setDepartmentDeatils] = useState<any>();
  const [departmentDeatilsData, setDepartmentDeatilsData] = useState<any>();

  const [callHistory, setCallHistory] = useState<any>([]);
  const [webinarList, setWebinarList] = useState([]);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [webinarDetails, setWebinarDetails] = useState<any>();
  const [mindate, setmindate] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [numberverify, setNumberverify] = useState(false);

  useEffect(() => {
    setmindate(moment.utc(new Date()).format("YYYY-MM-DD hh:mm:ss"));
  }, []);

  useEffect(() => {
    dispatch(
      getLeadStatusList(
        "",
        (res: any) => {
          setDepartmentstatusList(res);
        },
        () => {}
      )
    );
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getLeadType(
        "",
        (res: any) => {
          setLeadTypeList(res);
        },
        () => {}
      )
    );
    dispatch(
      getSubStatusbyStatusID(
        departmentstatus?._id,
        (res: any) => {
          setStatusDetailsList(res);
        },
        () => {}
      )
    );

    if (IsCheck === "true") {
      dispatch(
        getDetailsbyDepartmentID(
          departmentID,
          (res: any) => {
            setDepartmentDeatils(res);
            setDepartment(res?.departmentName);
            setFirstName1(res?.primaryContact?.firstName);
            setLastName1(res?.primaryContact?.lastName);
            setContact1(res?.primaryContact?.contact1);
            setContact2(res?.primaryContact?.contact2);
            if (res?.primaryContact?.contact2?.length === 10) {
              setIsSave(true);
            }
            setEmail1(res?.primaryContact?.email);
            setDesignation1(res?.primaryContact?.designation);
            setIfasian1(
              res?.primaryContact?.IFASian === "YES"
                ? 1
                : res?.primaryContact?.IFASian === "NO"
                ? 2
                : null
            );
            setFirstName2(res?.secondaryContact?.firstName);
            setLastName2(res?.secondaryContact?.lastName);
            setMob1(res?.secondaryContact?.contact1);
            setMob2(res?.secondaryContact?.contact2);
            if (res?.secondaryContact?.contact2?.length === 10) {
              setIsSave(true);
            }
            setEmail2(res?.secondaryContact?.email);
            setDesignation2(res?.secondaryContact?.designation);
            setIfasian2(
              res?.secondaryContact?.IFASian === "Yes"
                ? 1
                : res?.secondaryContact?.IFASian === "No"
                ? 2
                : null
            );
            setExam(res?.examTypeId);
            setSubExam(res?.subexamTypeId);
            setExamStream(res?.baseCourseId);
            setFirstYear(res?.FirstYear);
            setSecondYear(res?.SecondYear);
            setThirdYear(res?.ThirdYear);
            setDepartmentstatus(res?.departmentStatus);
            setStatusDetails(res?.statusDetails);
            setLeadType(res?.leadType);
            setRemark(res?.remark);
            setCallDate(res?.callBackDate);
          },
          () => {}
        )
      );
    }
    /********************************************** Call History************************************************************/
    if (IsCheck === "true") {
      const PostData = {
        departmentId: departmentID,
        // collegeId: collegeData?._id,
      };
      dispatch(
        getCallHistory(
          PostData,
          (res: any) => {
            setCallHistory(res);
          },
          () => {}
        )
      );
    }
    /***********************************************Webinar List by Department********************************************************/
    if (IsCheck === "true") {
      dispatch(
        getWebinarListByDeptID(
          departmentID,
          (res: any) => {
            setWebinarList(res?.data);
          },
          () => {}
        )
      );
    }
  }, []);

  const onClickPrimaryCall = () => {
    dispatch(
      makeCalltoLeadBde(
        {
          leadMobileNo: contact1,
        },
        (res: any) => {},
        () => {}
      )
    );
  };

  const onClickSecondaryCall = () => {
    dispatch(
      makeCalltoLeadBde(
        {
          leadMobileNo: mob1,
        },
        (res: any) => {},
        () => {}
      )
    );
  };

  const onClickPrimaryCall2 = () => {
    dispatch(
      makeCalltoLeadBde(
        {
          leadMobileNo: contact2,
        },
        (res: any) => {},
        () => {}
      )
    );
  };

  const onClickSecondaryCall2 = () => {
    dispatch(
      makeCalltoLeadBde(
        {
          leadMobileNo: mob2,
        },
        (res: any) => {},
        () => {}
      )
    );
  };

  const handleEditwebinarSchedule = (val: any) => {
    setWebinarDetails(val);
    setOpenEditPopup(true);
  };

  const onchangeDepartment = (val: any) => {
    if (val !== "") {
      if (!validateIsLettersAndDotOnly(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (startsWithSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setDepartment(val);
      }
    } else {
      setDepartment(val);
    }
  };

  /************************************************ Primary Contact*****************************************************************/
  const onchangeFirstName1 = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setFirstName1(val);
      }
    } else {
      setFirstName1(val);
    }
  };
  const onchangeLastName1 = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setLastName1(val);
      }
    } else {
      setLastName1(val);
    }
  };
  const onchangeContact1 = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact1(val);
      }
    } else {
      setContact1(val);
    }
  };
  const onchangeContact2 = (val: any) => {
    setIsSave(false);
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val);
      }
    } else {
      setContact2(val);
    }
  };
  var emailsCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onChangeEmail1 = (val: any) => {
    // if (!startsWithSpace(val) && !hasWhiteSpace(val)) {
    //   setEmail1(val);
    // } else {
    //   dispatch(showErrorSnackbar("Please enter valid Email") as any);
    //   return;
    // }
    if (!validateValueWithoutSpace(val)) {
      dispatch(showErrorSnackbar("space not allowed") as any);
      return;
    } else {
      setEmail1(val);
    }
    if (!validateEmailAddress(val)) {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg) as any);
      return;
    } else {
      setEmail1(val);
    }
  };
  const onChangeDesignation1 = (val: any) => {
    if (val !== "") {
      if (!validateIsLettersAndSpaceOnly(val)) {
        dispatch(showErrorSnackbar("Please enter valid Designation") as any);
        return;
      } else {
        setDesignation1(val);
      }
    } else {
      setDesignation1(val);
    }
  };

  const onChangeIFASian1 = (val: any) => {
    setIfasian1(val);
  };

  /***************************************************Secondary Conatct**************************************************************************/
  const onchangeFirstName2 = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setFirstName2(val);
      }
    } else {
      setFirstName2(val);
    }
  };
  const onchangeLastName2 = (val: any) => {
    if (val !== "") {
      if (!validateFullName(val)) {
        dispatch(showErrorSnackbar("Please enter alphabet only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsAlphabetErrMsg) as any);
        return;
      } else {
        setLastName2(val);
      }
    } else {
      setLastName2(val);
    }
  };
  const onchangeMob1 = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob1(val);
      }
    } else {
      setMob1(val);
    }
  };
  const onchangeMob2 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob2(val);
      }
    } else {
      setMob2(val);
    }
  };

  const onChangeEmail2 = (val: any) => {
    if (!startsWithSpace(val) && !hasWhiteSpace(val)) {
      setEmail2(val);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email") as any);
      return;
    }
  };
  const onChangeDesignation2 = (val: any) => {
    if (val !== "") {
      if (!validateIsLettersAndSpaceOnly(val)) {
        dispatch(showErrorSnackbar("Please enter valid Designation") as any);
        return;
      } else {
        setDesignation2(val);
      }
    } else {
      setDesignation2(val);
    }
  };
  const onChangeIFASian2 = (val: any) => {
    setIfasian2(val);
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    dispatch(
      getSubExam(
        {
          examTypeId: val._id,
        },
        (res: any) => {
          if (res.length > 0) {
            setSubExamList(res);
          } else {
            dispatch(
              getExamStreamList(
                `${val._id}`,
                (res: any) => {
                  setExamStreamList(res?.data);
                },
                () => {}
              )
            );
          }
        }
      )
    );
  };

  const onChangeExamStream = (val: any) => {
    setExamStream(val);
  };

  const onChangeSubExam = (val: any) => {
    setSubExam(val);
    dispatch(
      getExamStreamList(
        `${val._id}`,
        (res: any) => {
          setExamStreamList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeFirstYear = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setFirstYear(val);
      }
    } else {
      setFirstYear(val);
    }
  };

  const onChangeSecondYear = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setSecondYear(val);
      }
    } else {
      setSecondYear(val);
    }
  };

  const onChangeThirdYear = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setThirdYear(val);
      }
    } else {
      setThirdYear(val);
    }
  };
  const onChangeStatus = (val: any) => {
    setDepartmentstatus(val);
    dispatch(
      getSubStatusbyStatusID(
        val?._id,
        (res: any) => {
          setStatusDetailsList(res);
        },
        () => {}
      )
    );
  };
  const onChangeStatusDetails = (val: any) => {
    setStatusDetails(val);
    setResetStatusDetails(false);
    dispatch(
      getLeadType(
        "",
        (res: any) => {
          setLeadTypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeLeadType = (val: any) => {
    setLeadType(val);
  };
  const changeRemark = (val: any) => {
    setRemark(val);
  };

  const onChangeCallDate = (val: any) => {
    setCallDate(val);
  };

  const handleClose = (value: any) => {
    setOpenPopup(false);
  };
  const handleEditClose = (value: any) => {
    setOpenPopup(false);
    setOpenEditPopup(false);
    {
      dispatch(
        getWebinarListByDeptID(
          departmentID,
          (res: any) => {
            setWebinarList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const handlewebinarSchedule = () => {
    setOpenPopup(true);
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const postData = {
      departmentName: department,
      collegeId: collegeData?._id,
      primaryContact: {
        firstName: firstName1,
        lastName: lastName1,
        contact1: contact1,
        contact2: contact2,
        email: email1,
        designation: designation1,
        IFASian: ifasian1?.name,
      },
      secondaryContact: {
        firstName: firstName2,
        lastName: lastName2,
        contact1: mob1,
        contact2: mob2,
        email: email2,
        designation: designation2,
        IFASian: ifasian2?.name,
      },
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      FirstYear: firstYear,
      SecondYear: secondYear,
      ThirdYear: thirdYear,
      departmentStatus: departmentstatus?._id,
      statusDetails: statusDetails?._id,
      leadType: leadType?._id,
      remark: remark,
      callBackDate: callDate,
    };
    if (
      postData?.primaryContact?.contact2 === "" ||
      postData?.primaryContact?.contact2 === undefined ||
      postData?.primaryContact?.contact2 === null
    ) {
      delete postData?.primaryContact?.contact2;
    }

    if (
      postData?.secondaryContact?.firstName === "" ||
      postData?.secondaryContact?.firstName === undefined ||
      postData?.secondaryContact?.firstName === null
    ) {
      delete postData?.secondaryContact?.firstName;
    }
    if (
      postData?.secondaryContact?.lastName === "" ||
      postData?.secondaryContact?.lastName === undefined ||
      postData?.secondaryContact?.lastName === null
    ) {
      delete postData?.secondaryContact?.lastName;
    }
    if (
      postData?.secondaryContact?.contact1 === "" ||
      postData?.secondaryContact?.contact1 === undefined ||
      postData?.secondaryContact?.contact1 === null
    ) {
      delete postData?.secondaryContact?.contact1;
    }
    if (
      postData?.secondaryContact?.contact2 === "" ||
      postData?.secondaryContact?.contact2 === undefined ||
      postData?.secondaryContact?.contact2 === null
    ) {
      delete postData?.secondaryContact?.contact2;
    }
    if (
      postData?.secondaryContact?.email === "" ||
      postData?.secondaryContact?.email === undefined ||
      postData?.secondaryContact?.email === null
    ) {
      delete postData?.secondaryContact?.email;
    }
    if (
      postData?.secondaryContact?.designation === "" ||
      postData?.secondaryContact?.designation === undefined ||
      postData?.secondaryContact?.designation === null
    ) {
      delete postData?.secondaryContact?.designation;
    }
    if (
      postData?.secondaryContact?.IFASian === "" ||
      postData?.secondaryContact?.IFASian === undefined ||
      postData?.secondaryContact?.IFASian === null
    ) {
      delete postData?.secondaryContact?.IFASian;
    }
    if (
      postData?.callBackDate === "" ||
      postData?.callBackDate === undefined ||
      postData?.callBackDate === null
    ) {
      delete postData?.callBackDate;
    }

    dispatch(
      updateDepartmentDetails(
        departmentID,
        postData,
        (res: any) => {
          if (pageName === "notIntrestedBDELead") {
            navigate("../BDEPanelDashbord/notIntrestedBDELead");
          } else if (pageName === "IntrestedBDELead") {
            navigate("../BDEPanelDashbord/intrestedBDELead");
          } else if (pageName === "CallBackBDELead") {
            navigate("../BDEPanelDashbord/callBackBDELead");
          } else if (pageName === "OverdueInterestedBDELead") {
            navigate("../BDEPanelDashbord/overdueInterestedBDELead");
          } else if (pageName === "overdueCallBackBDELead") {
            navigate("../BDEPanelDashbord/overdueCallBackBDELead");
          }
          navigate(-1);
        },
        () => {}
      )
    );
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    const postData = {
      departmentName: department,
      collegeId: collegeData?._id,
      primaryContact: {
        firstName: firstName1,
        lastName: lastName1,
        contact1: contact1,
        contact2: contact2,
        email: email1,
        designation: designation1,
        IFASian: ifasian1?.name,
      },
      secondaryContact: {
        firstName: firstName2,
        lastName: lastName2,
        contact1: mob1,
        contact2: mob2,
        email: email2,
        designation: designation2,
        IFASian: ifasian2?.name,
      },
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      FirstYear: firstYear,
      SecondYear: secondYear,
      ThirdYear: thirdYear,
      departmentStatus: departmentstatus?._id,
      statusDetails: statusDetails?._id,
      leadType: leadType?._id,
      remark: remark,
      callBackDate: callDate,
    };
    if (
      postData?.primaryContact?.contact2 === "" ||
      postData?.primaryContact?.contact2 === undefined ||
      postData?.primaryContact?.contact2 === null
    ) {
      delete postData?.primaryContact?.contact2;
    }

    if (
      postData?.secondaryContact?.firstName === "" ||
      postData?.secondaryContact?.firstName === undefined ||
      postData?.secondaryContact?.firstName === null
    ) {
      delete postData?.secondaryContact?.firstName;
    }
    if (
      postData?.secondaryContact?.lastName === "" ||
      postData?.secondaryContact?.lastName === undefined ||
      postData?.secondaryContact?.lastName === null
    ) {
      delete postData?.secondaryContact?.lastName;
    }
    if (
      postData?.secondaryContact?.contact1 === "" ||
      postData?.secondaryContact?.contact1 === undefined ||
      postData?.secondaryContact?.contact1 === null
    ) {
      delete postData?.secondaryContact?.contact1;
    }
    if (
      postData?.secondaryContact?.contact2 === "" ||
      postData?.secondaryContact?.contact2 === undefined ||
      postData?.secondaryContact?.contact2 === null
    ) {
      delete postData?.secondaryContact?.contact2;
    }
    if (
      postData?.secondaryContact?.email === "" ||
      postData?.secondaryContact?.email === undefined ||
      postData?.secondaryContact?.email === null
    ) {
      delete postData?.secondaryContact?.email;
    }
    if (
      postData?.secondaryContact?.designation === "" ||
      postData?.secondaryContact?.designation === undefined ||
      postData?.secondaryContact?.designation === null
    ) {
      delete postData?.secondaryContact?.designation;
    }
    if (
      postData?.secondaryContact?.IFASian === "" ||
      postData?.secondaryContact?.IFASian === undefined ||
      postData?.secondaryContact?.IFASian === null
    ) {
      delete postData?.secondaryContact?.IFASian;
    }
    if (
      postData?.callBackDate === "" ||
      postData?.callBackDate === undefined ||
      postData?.callBackDate === null
    ) {
      delete postData?.callBackDate;
    }
    dispatch(
      addDepartment(
        postData,
        (res: any) => {
          dispatch(
            getDepartmentList(
              collegeData?._id,
              (res: any) => {
                setDepartmentDeatilsData(res?.data);
              },
              () => {}
            )
          );
          // setDepartment("");
          //       setFirstName1("");
          //       setLastName1("");
          //       setContact1("");
          //       setContact2("");
          //       setEmail1("");
          //       setDesignation1("");
          //       setIfasian1("");
          //       setFirstName2("");
          //       setLastName2("");
          //       setMob1("");
          //       setMob2("");
          //       setEmail2("");
          //       setDesignation2("");
          //       setIfasian2("");
          //       setExam("");
          //       setExamStream("");
          //       setFirstYear("");
          //       setSecondYear("");
          //       setThirdYear("");
          //       setDepartmentstatus("");
          //       setStatusDetails("");
          //       setLeadType("");
          //       setRemark("");
          //       setCallDate("");
          navigate(-1);
        },

        () => {}
      )
    );
  };

  const onHandleContact2 = () => {
    setIsSave(true);

    const postData = {
      departmentName: department,
      collegeId: collegeData?._id,
      primaryContact: {
        firstName: firstName1,
        lastName: lastName1,
        contact1: contact1,
        contact2: contact2,
        email: email1,
        designation: designation1,
        IFASian: ifasian1?.name,
      },
      secondaryContact: {
        firstName: firstName2,
        lastName: lastName2,
        contact1: mob1,
        contact2: mob2,
        email: email2,
        designation: designation2,
        IFASian: ifasian2?.name,
      },
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      FirstYear: firstYear,
      SecondYear: secondYear,
      ThirdYear: thirdYear,
      departmentStatus: departmentstatus?._id,
      statusDetails: statusDetails?._id,
      leadType: leadType?._id,
      remark: remark,
      callBackDate: callDate,
    };
    if (
      postData?.callBackDate === "" ||
      postData?.callBackDate === undefined ||
      postData?.callBackDate === null
    ) {
      delete postData?.callBackDate;
    }
    if (
      postData?.primaryContact?.contact2 === "" ||
      postData?.primaryContact?.contact2 === undefined ||
      postData?.primaryContact?.contact2 === null
    ) {
      delete postData?.primaryContact?.contact2;
    }
    if (
      postData?.secondaryContact?.contact2 === "" ||
      postData?.secondaryContact?.contact2 === undefined ||
      postData?.secondaryContact?.contact2 === null
    ) {
      delete postData?.secondaryContact?.contact2;
    }

    dispatch(
      updateDepartmentDetails(
        departmentID,
        postData,
        (res: any) => {
          if (pageName === "notIntrestedBDELead") {
            navigate("../BDEPanelDashbord/notIntrestedBDELead");
          } else if (pageName === "IntrestedBDELead") {
            navigate("../BDEPanelDashbord/intrestedBDELead");
          } else if (pageName === "CallBackBDELead") {
            navigate("../BDEPanelDashbord/callBackBDELead");
          } else if (pageName === "OverdueInterestedBDELead") {
            navigate("../BDEPanelDashbord/overdueInterestedBDELead");
          }
        },
        () => {}
      )
    );
  };

  const onHandleContact1Verify = () => {
    dispatch(
      getVrifyNumber(
        contact1,
        (res: any) => {
          dispatch(showSuccessSnackbar(res?.data?.msg) as any);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg) as any);
        }
      )
    );
  };

  const onHandleContact2Verify = () => {
    dispatch(
      getVrifyNumber(
        contact2,
        (res: any) => {
          dispatch(showSuccessSnackbar(res?.data?.msg) as any);
          setNumberverify(true);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg) as any);
        }
      )
    );
  };

  const onHandleSecContact1Verify = () => {
    dispatch(
      getVrifyNumber(
        mob1,
        (res: any) => {
          dispatch(showSuccessSnackbar(res?.data?.msg) as any);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg) as any);
        }
      )
    );
  };
  const onHandleSecContact2Verify = () => {
    dispatch(
      getVrifyNumber(
        mob2,
        (res: any) => {
          dispatch(showSuccessSnackbar(res?.data?.msg) as any);
          setNumberverify(true);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.data?.msg) as any);
        }
      )
    );
  };

  const onClickBack = () => {
    if (pageName === "overdueCallBackBDELead") {
      navigate("/BDEPanelDashbord/overduecallBackBDELead", {
        state: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    } else if (pageName === "notIntrestedBDELead") {
      navigate("/BDEPanelDashbord/notIntrestedBDELead", {
        state: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    } else if (pageName === "CallBackBDELead") {
      navigate("/BDEPanelDashbord/callBackBDELead", {
        state: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    } else if (pageName === "IntrestedBDELead") {
      navigate("/BDEPanelDashbord/intrestedBDELead", {
        state: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    } else if (pageName === "OverdueInterestedBDELead") {
      navigate("/BDEPanelDashbord/overdueInterestedBDELead", {
        state: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    } else {
      navigate(-1);
    }
  };
  const validation = () => {
    return department === "" ||
      department?.length < length_Three ||
      firstName1 === "" ||
      firstName1?.length <= length_Two ||
      lastName1 === "" ||
      lastName1?.length <= length_Two ||
      email1 === "" ||
      email1?.length < length_Three ||
      !email1.match(emailsCheck) ||
      designation1 === "" ||
      ifasian1 === "" ||
      exam === "" ||
      examStream === "" ||
      firstYear === "" ||
      secondYear === "" ||
      thirdYear === "" ||
      departmentstatus === "" ||
      departmentstatus?._id === 2 ||
      departmentstatus?._id === 3
      ? callDate === ""
      : false;
  };

  return (
    <div className="col-md-12">
      <div className="mt-2 ">
        <button
          type="button"
          className="btn btn-light"
          // onClick={() => navigate(-1 ,
          //   )}
          onClick={onClickBack}
        >
          <ReplyIcon /> Back
        </button>
      </div>

      <div className="row m-0">
        <div className="col-md-12 detailsdiv font14">
          <div className="boxshadow mx-5 my-1">
            <div className="d-flex Justify-content-between p-2 pt-3">
              <div className="col-md-9 px-3">
                <h4 className="fw-bold text-uppercase">
                  {collegeData?.collegeName}
                </h4>
              </div>
              {IsCheck === "true" ? (
                <div className="col-md-3 px-3">
                  {/* <button type="button" className="btn btn-success me-2  font12" >
                  <span>
                    <WhatsAppIcon className="font14" />
                  </span>
                  <span className=" d-xl-inline-block ms-2">WhatsApp</span>
                </button> */}
                  <button
                    type="button"
                    className="btn btn-primary me-2 font12"
                    onClick={handlewebinarSchedule}
                  >
                    <span className="  d-xl-inline-block">
                      Webinar Schedule
                    </span>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="p-1 d-flex justify-content-center  ">
              <div className="col-md-9 row d-flex justify-content-center px-2 ">
                <div className=" pt-2 fw-bold ">
                  <h5>{collegeData?.college_data?.collegeName}</h5>
                </div>
                {/* *********************************************Deaprtment************************************************ */}
                <div className=" pt-2 ">
                  {/* <div className="tabheader p-2">Department Details</div> */}
                  <div className="col-md-7 row d-flex justify-content-between mt-2 pt-2">
                    <div className="col-md-4  ">
                      <LabelField lableName="Department Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Department Name"
                        value={department}
                        onChangeInput={(value: any) =>
                          onchangeDepartment(value?.toUpperCase())
                        }
                        // disabled={IsEdit ? true : false}
                        maxlength={length_Thirty}
                        minlength={length_Three}
                        isRequired={true}
                      />
                    </div>
                  </div>
                </div>
                {/* *********************************************Primary Contact ********************************************************** */}
                <div className="col-md-6 col-sm-12 p-2">
                  <div className="tabheader p-2 d-flex justify-content-between">
                    <p className="mb-0 mt-2">Primary Contact </p>
                    <button
                      className="btn btn-success"
                      onClick={onClickPrimaryCall}
                    >
                      Call
                    </button>
                  </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="First Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter First Name"
                        value={firstName1}
                        onChangeInput={(value: any) =>
                          onchangeFirstName1(value?.toUpperCase())
                        }
                        // disabled={IsEdit ? true : false}
                        maxlength={length_Thirty}
                        minlength={length_Two}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Last Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Last Name"
                        value={lastName1}
                        onChangeInput={(value: any) =>
                          onchangeLastName1(value?.toUpperCase())
                        }
                        // disabled={IsEdit ? true : false}
                        maxlength={length_Thirty}
                        minlength={length_Two}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 1" />
                    </div>
                    <div className="col-md-7 d-flex justify-content-between  me-0">
                      <InputField
                        placeholder="Enter Contact 1"
                        value={contact1}
                        onChangeInput={(value: any) => onchangeContact1(value)}
                        disabled={IsEdit ? true : false}
                        maxlength={length_Fifteen}
                        minlength={length_Ten}
                        isRequired={true}
                      />
                      {!IsEdit ? (
                        <button
                          type="button"
                          className={
                            contact1?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleContact1Verify}
                        >
                          {" "}
                          <span>VERIFY</span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 2" />
                    </div>
                    <div className="col-md-7 d-flex justify-content-between  me-0">
                      <InputField
                        placeholder="Enter Contact 2"
                        value={contact2}
                        onChangeInput={(value: any) => onchangeContact2(value)}
                        disabled={
                          IsEdit && departmentDeatils?.primaryContact?.contact2
                            ? true
                            : false
                        }
                        maxlength={length_Fifteen}
                        minlength={length_Ten}
                      />
                      {!isSave &&
                      IsEdit &&
                      !departmentDeatils?.primaryContact?.contact2 &&
                      !numberverify ? (
                        <button
                          type="button"
                          className={
                            contact2?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleContact2Verify}
                        >
                          {" "}
                          <span>VERIFY</span>
                        </button>
                      ) : IsEdit && !isSave ? (
                        <>
                          <button
                            type="button"
                            className={
                              contact2?.length >= 10
                                ? "btn btn-success ms-2 me-2 font12 mb-2 "
                                : "btn btn-secondary me-2 font12 mb-2 "
                            }
                            onClick={onHandleContact2}
                          >
                            {" "}
                            <span>Save</span>
                          </button>
                        </>
                      ) : null}

                      {!IsEdit ? (
                        <button
                          type="button"
                          className={
                            contact2?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleContact2Verify}
                        >
                          {" "}
                          <span>VERIFY</span>
                        </button>
                      ) : null}

                      {IsEdit && departmentDeatils?.primaryContact?.contact2 ? (
                        <button
                          type="button"
                          className={"btn btn-success me-2 font12 mb-2 "}
                          onClick={onClickPrimaryCall2}
                        >
                          {" "}
                          <span>
                            <CallIcon className="font12" />
                          </span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Email" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Email"
                        value={email1}
                        onChangeInput={(value: any) => onChangeEmail1(value)}
                        // disabled={IsEdit ? true : false}
                        maxlength={length_TwoHundred}
                        minlength={length_Three}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Designation" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Designation"
                        value={designation1}
                        onChangeInput={(value: any) =>
                          onChangeDesignation1(value)
                        }
                        // disabled={IsEdit ? true : false}
                        maxlength={length_Twenty}
                        minlength={length_Three}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="IFASian" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <CustomDropdown
                        lableName="Select"
                        setInputText={(value: any) => onChangeIFASian1(value)}
                        value={ifasian1}
                        options={ifasianList}
                        defaultValue={ifasianList?.length > 0 ? ifasian1 : null}
                        // disabled={IsEdit ? true : false}
                        Isrequired={true}
                      />
                    </div>
                  </div>
                </div>
                {/* ************************************************Seconadry Contact************************************************** */}
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2 d-flex justify-content-between">
                    <p className="mb-0 mt-2">Seconadry Contact</p>
                    <button
                      className="btn btn-success"
                      onClick={onClickSecondaryCall}
                    >
                      Call
                    </button>
                  </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="First Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter First Name"
                        value={firstName2}
                        onChangeInput={(value: any) =>
                          onchangeFirstName2(value?.toUpperCase())
                        }
                        disabled={
                          IsEdit &&
                          departmentDeatils?.secondaryContact?.firstName !==
                            undefined
                            ? true
                            : false
                        }
                        maxlength={length_Thirty}
                        minlength={length_Two}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Last Name" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Last Name"
                        value={lastName2}
                        onChangeInput={(value: any) =>
                          onchangeLastName2(value?.toUpperCase())
                        }
                        disabled={
                          IsEdit &&
                          departmentDeatils?.secondaryContact?.lastName !==
                            undefined
                            ? true
                            : false
                        }
                        maxlength={length_Thirty}
                        minlength={length_Two}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 1" />
                    </div>
                    <div className="col-md-7 d-flex justify-content-between  me-0">
                      <InputField
                        placeholder="Enter Contact 1"
                        value={mob1}
                        onChangeInput={(value: any) => onchangeMob1(value)}
                        disabled={
                          IsEdit &&
                          departmentDeatils?.secondaryContact?.contact1 !==
                            undefined
                            ? true
                            : false
                        }
                        maxlength={length_Fifteen}
                        minlength={length_Ten}
                      />
                      {!IsEdit ? (
                        <button
                          type="button"
                          className={
                            mob1?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleSecContact1Verify}
                        >
                          {" "}
                          <span>VERIFY</span>
                        </button>
                      ) : null}

                      {(IsEdit && mob1 === undefined) || mob1?.length >= 10 ? (
                        <button
                          type="button"
                          className={
                            mob1?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleSecContact1Verify}
                        >
                          {" "}
                          <span>VERIFY</span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 2" />
                    </div>
                    <div className="col-md-7 d-flex justify-content-between  me-0">
                      <InputField
                        placeholder="Enter Contact 2"
                        value={mob2}
                        onChangeInput={(value: any) => onchangeMob2(value)}
                        disabled={
                          IsEdit &&
                          departmentDeatils?.secondaryContact?.contact2 !==
                            undefined
                            ? true
                            : false
                        }
                        maxlength={length_Fifteen}
                        minlength={length_Ten}
                      />
                      {/* {IsEdit && mob2===undefined ? (
                        <button
                          type="button"
                          className={
                            mob2?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleContact2}
                        >
                          {" "} 
                          <span>Save</span>
                        </button>
                      ) : null}
                      {IsEdit &&
                      departmentDeatils?.secondaryContact?.contact2 ? (
                        <button
                          type="button"
                          className={"btn btn-success me-2 font12 mb-2 "}
                          onClick={onClickSecondaryCall2}
                        >
                          {" "}
                          <span>
                            <CallIcon className="font12" />
                          </span>
                        </button>
                      ) : null} */}

                      {!IsEdit ? (
                        <button
                          type="button"
                          className={
                            mob2?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleSecContact2Verify}
                        >
                          {" "}
                          <span>VERIFY</span>
                        </button>
                      ) : null}

                      {IsEdit &&
                      (mob2 === undefined || mob2?.length >= 10) &&
                      !numberverify ? (
                        <button
                          type="button"
                          className={
                            mob2?.length >= 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleSecContact2Verify}
                        >
                          {" "}
                          <span>VERIFY</span>
                        </button>
                      ) : IsEdit &&
                        (mob2 === undefined || mob2?.length >= 10) ? (
                        <>
                          <button
                            type="button"
                            className={
                              mob2?.length >= 10
                                ? "btn btn-success ms-2 me-2 font12 mb-2 "
                                : "btn btn-secondary me-2 font12 mb-2 "
                            }
                            onClick={onHandleContact2}
                          >
                            {" "}
                            <span>Save</span>
                          </button>
                        </>
                      ) : null}

                      {IsEdit &&
                      departmentDeatils?.secondaryContact?.contact2 ? (
                        <button
                          type="button"
                          className={"btn btn-success me-2 font12 mb-2 "}
                          onClick={onClickSecondaryCall2}
                        >
                          {" "}
                          <span>
                            <CallIcon className="font12" />
                          </span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Email" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Email"
                        value={email2}
                        onChangeInput={(value: any) => onChangeEmail2(value)}
                        disabled={
                          IsEdit &&
                          departmentDeatils?.secondaryContact?.email !==
                            undefined
                            ? true
                            : false
                        }
                        maxlength={length_TwoHundred}
                        minlength={length_Three}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Designation" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Designation"
                        value={designation2}
                        onChangeInput={(value: any) =>
                          onChangeDesignation2(value)
                        }
                        disabled={
                          IsEdit &&
                          departmentDeatils?.secondaryContact?.designation !==
                            undefined
                            ? true
                            : false
                        }
                        maxlength={length_Twenty}
                        minlength={length_Three}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="IFASian" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <CustomDropdown
                        lableName="Select"
                        setInputText={(value: any) => onChangeIFASian2(value)}
                        value={ifasian2}
                        options={ifasianList}
                        defaultValue={ifasianList?.length > 0 ? ifasian2 : null}
                        disabled={
                          IsEdit &&
                          departmentDeatils?.secondaryContact?.IFASian !==
                            undefined
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Exam</div>
                  <div className="row d-flex justify-content-between  pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Exam" />
                    </div>
                    <div className="col-md-7 ps-3">
                      <CustomDropdown
                        lableName="Select Exam"
                        setInputText={(value: any) => onChangeExam(value)}
                        value={exam}
                        options={examList}
                        defaultValue={examList?.length > 0 ? exam : null}
                        reset={resetExamList}
                        // disabled={IsEdit ? true : false}
                        Isrequired={true}
                      />
                    </div>
                  </div>

                  {subExamList?.length > 0 ? (
                    <div className="row d-flex justify-content-between">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Sub Exam Name" />
                      </div>
                      <div className="col-md-7 ps-3">
                        <CustomDropdown
                          lableName="Select Sub Exam"
                          setInputText={(value: any) => onChangeSubExam(value)}
                          value={subExam}
                          options={subExamList}
                          defaultValue={
                            subExamList?.length > 0 ? subExam : null
                          }
                          reset={resetSubExam}
                          // disabled={IsEdit ? true : false}
                          Isrequired={true}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Exam Steram" />
                    </div>
                    <div className="col-md-7 ps-3">
                      <CustomDropdown
                        lableName="Select Exam"
                        setInputText={(value: any) => onChangeExamStream(value)}
                        value={examStream}
                        options={examStreamList}
                        defaultValue={
                          examStreamList?.length > 0 ? examStream : null
                        }
                        reset={resetExamStreamList}
                        // disabled={IsEdit ? true : false}
                        Isrequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="1st Year" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="1st Year"
                        value={firstYear}
                        onChangeInput={(value: any) => onChangeFirstYear(value)}
                        maxlength={length_Three}
                        minlength={length_One}
                        // disabled={IsEdit ? true : false}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="2nd Year" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="2nd Year"
                        value={secondYear}
                        onChangeInput={(value: any) =>
                          onChangeSecondYear(value)
                        }
                        maxlength={length_Three}
                        minlength={length_One}
                        // disabled={IsEdit ? true : false}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="3rd Year" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="3rd Year"
                        value={thirdYear}
                        onChangeInput={(value: any) => onChangeThirdYear(value)}
                        maxlength={length_Three}
                        minlength={length_One}
                        // disabled={IsEdit ? true : false}
                        isRequired={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Action & Disposition</div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status" />
                    </div>

                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status"
                        setInputText={(value: any) => onChangeStatus(value)}
                        value={departmentstatus}
                        options={departmentstatusList}
                        Isrequired={true}
                        reset={resetDepartmentstatus}
                        defaultValue={departmentstatus}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status Details" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status Details"
                        setInputText={(value: any) =>
                          onChangeStatusDetails(value)
                        }
                        value={statusDetails}
                        options={statusDetailsList}
                        defaultValue={
                          statusDetailsList?.length > 0 ? statusDetails : null
                        }
                        reset={resetStatusDetails}
                      />
                    </div>
                  </div>
                  {departmentstatus?._id === 2 ||
                  departmentstatus?._id === 3 ? (
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Lead Type" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Lead Type"
                          setInputText={(value: any) => onChangeLeadType(value)}
                          value={leadType}
                          options={leadTypeList}
                          defaultValue={
                            leadTypeList?.length > 0 ? leadType : null
                          }
                          reset={resetLeadType}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className=" px-3 py-2">
                    <textarea
                      className="form-control"
                      placeholder={"Remark"}
                      value={remark}
                      defaultValue={remark}
                      onChange={(e) => changeRemark(e.target.value)}
                    ></textarea>
                  </div>

                  {departmentstatus?._id === 2 ||
                  departmentstatus?._id === 3 ? (
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Call Back Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={callDate}
                          setInputText={(value: any) => onChangeCallDate(value)}
                          Isrequired={true}
                          defaultValue={callDate}
                          mindate={mindate}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className=" d-flex justify-content-end  ">
                    <SubmitButton name={IsEdit ?"Update":"Save"} validation={validation()} />
                  </div> */}
                  <></>
                  {IsEdit ? (
                    <div className=" d-flex justify-content-end  ">
                      <SubmitButton
                        name={"Update"}
                        validation={validation()}
                        handleClick={handleUpdate}
                      />
                    </div>
                  ) : (
                    <div className=" d-flex justify-content-end  ">
                      <SubmitButton
                        name={"Save"}
                        validation={validation()}
                        handleClick={handleSubmit}
                      />
                    </div>
                  )}
                </div>
              </div>
              {IsCheck === "true" ? (
                <div className="col-md-3 p-3 pt-2">
                  <div className="tabheader p-2">Call History</div>
                  <div className="historydetails font14">
                    {callHistory?.map((item: any) => {
                      return (
                        <div className="my-2 p-3 border-bottom  border-secondary shadow mb-2   rounded">
                          <p className=" ps-4 fw-bold    mb-0  ">
                            Name : {item?.name}
                          </p>

                          <p className=" ps-4 mb-0   ">
                            Status :{item?.departmentStatus}
                          </p>
                          <p className=" ps-4 mb-0   ">
                            Status Details:{item?.statusDetails}
                          </p>
                          {item?.managerRemark !== null ? (
                            <p className=" ps-4 mb-0   ">
                              Manager Comment:{item?.managerRemark}
                            </p>
                          ) : (
                            ""
                          )}
                          {item?.call_recording ? (
                            <p className=" ps-4 mb-0  ">
                              Comment :{" "}
                              <span className=" mb-0   ">{item?.remark}</span>
                            </p>
                          ) : null}
                          {item?.call_recording ? (
                            <p className=" ps-4 mb-0  ">
                              call Date :{" "}
                              {item?.call_date ? (
                                <span className=" mb-0   ">
                                  {moment(item?.call_date)
                                    .utc()
                                    .format("DD-MM-YYYY ")}
                                </span>
                              ) : (
                                <span>{null}</span>
                              )}
                            </p>
                          ) : null}
                          {item?.call_recording ? (
                            <p className=" ps-4 mb-0  ">
                              call Time :{" "}
                              {item?.call_time ? (
                                <span className=" mb-0   ">
                                  {item?.call_time}
                                </span>
                              ) : (
                                <span>{null}</span>
                              )}
                            </p>
                          ) : null}

                          {item?.call_recording ? (
                            <div className="justify-content-center">
                              {/* {item?.call_recording?.map((val: any) => {
                              return ( */}
                              <audio
                                className="ps-4 mb-0"
                                controls
                                src={item?.call_recording}
                              ></audio>
                              {/* );
                            })} */}
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {IsCheck === "true" ? (
              <div>
                <table className="w-100">
                  <thead className=" ">
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Coordinator Name</th>
                      <th>Coordinator Phone</th>
                      <th>Coordinator Email</th>
                      <th>Webinar Teacher</th>
                      <th>Lead Generated</th>
                      <th>Admission</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  {webinarList?.length > 0 ? (
                    <tbody>
                      {webinarList?.map((item: any, index: any) => {
                        return (
                          <tr>
                            <td>
                              {moment(item?.webinarAt)
                                .utc()
                                .format("DD-MM-YYYY ")}
                            </td>
                            <td>
                              {moment(item?.webinarAt).utc().format("LT")}
                            </td>
                            <td>{item?.coordinatorName}</td>
                            <td>{item?.coordinatorPhone}</td>
                            <td>{item?.coordinatorEmail}</td>
                            <td>{item?.teacher_data?.name}</td>
                            <td>
                              {item?.generated_count
                                ? item?.generated_count
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              {item?.admission_count
                                ? item?.admission_count
                                : "-"}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => handleEditwebinarSchedule(item)}
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <td>No Record Found</td>
                  )}
                </table>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <WebinarSchedule
        open={openPopup}
        handleClose={handleClose}
        data={departmentDeatils}
        college={collegeData}
      />
      <EditWebinarSchedule
        open={openEditPopup}
        handleClose={handleEditClose}
        data={webinarDetails}
        departmentID={departmentID}
      />
    </div>
  );
}
