import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import Header from "../../Component/header";
import LabelField from "../../Component/labelField";
import InputField from "../../Component/inputField";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import CustomDropdown from "../../Component/customDropdown";
import { getExamStreamList } from "../../Redux/Actions/bdExecutivePanelAction";
import ReplyIcon from "@mui/icons-material/Reply";

export default function CompletedWebinarStatusDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const webinarId = location.state?.webinarId;
  const webinarData = location.state?.webinarData;

  const [status, setStatus] = useState<any>("Completed");
  const [statusList, setStatusList] = useState([]);
  const [resetStatus, setResetStatus] = useState(false);
  const [dateTime, setDateTime] = useState("");
  const [remark, setRemark] = useState<any>("");
  const [image, setImage] = useState<any>("");
  const [UploadImage, setUploadImage] = useState<any>("");
  const [fName1, setFName1] = useState<any>("");
  const [lName1, setLName1] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [email1, setEmail1] = useState<any>("");
  const [fName2, setFName2] = useState<any>("");
  const [lName2, setLName2] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email2, setEmail2] = useState<any>("");
  const [fName3, setFName3] = useState<any>("");
  const [lName3, setLName3] = useState<any>("");
  const [contact3, setContact3] = useState<any>("");
  const [email3, setEmail3] = useState<any>("");
  const [fName4, setFName4] = useState<any>("");
  const [lName4, setLName4] = useState<any>("");
  const [contact4, setContact4] = useState<any>("");
  const [email4, setEmail4] = useState<any>("");
  const [fName5, setFName5] = useState<any>("");
  const [lName5, setLName5] = useState<any>("");
  const [contact5, setContact5] = useState<any>("");
  const [email5, setEmail5] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [uploadMailScreenshotFile, setUploadMailScreenshotFile] =
    useState<any>("");
  const [uploadMailScreenshotData, setUploadMailScreenshotData] =
    useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [imageFull, setImageFull] = useState();

  useEffect(() => {
    dispatch(
      getExamStreamList(
        `${webinarData?.examType_data?._id}`,
        (res: any) => {
          setExamStreamList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeExamStream = (val: any) => {
    setExamStream(val);
  };

  const onChangeStatus = (val: any) => {
    setStatus(val);
  };

  const changeRemark = (val: any) => {
    setRemark(val);
  };

  const handleImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleOnClick = (val: any) => {
    sessionStorage.setItem("imgLink", val);
    window.open("/image", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes");
  };

  return (
    <div className="col-md-12 maindiv">
      <div className="mt-2 ">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => navigate(-1)}
        >
          <ReplyIcon /> Back
        </button>
      </div>
      <div className="row m-0">
        <div className="col-md-12 font14 p-5 pt-3 ">
          <div className="mx-5 detailsdiv boxshadow p-3">
            <div className="row d-flex justify-content-between font17">
              {/* ************************ Co-Ordinator Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Co-Ordinator</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Name:</div>
                  <div className="col-md-8">{webinarData?.coordinatorName}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Phone :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorPhone}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Email :</div>
                  <div className="col-md-8">
                    {webinarData?.coordinatorEmail}
                  </div>
                </div>
              </div>
              {/* ************************Webinar Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Webinar Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Date :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Time :</div>
                  <div className="col-md-8">
                    {moment(webinarData.webinarAt).format("hh:mm:ss a")}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Link :</div>
                  <div className="col-md-8 text-break">
                    <a
                      href={webinarData?.meetingURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {webinarData?.meetingURL}
                    </a>
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Faculty :</div>
                  <div className="col-md-8">
                    {webinarData?.teacher_data?.name
                      ? webinarData?.teacher_data?.name
                      : "-"}
                  </div>
                </div>
              </div>

              {/* ************************Collage Details***************************** */}
              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Collage Details :</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Collage :</div>
                  <div className="col-md-8">
                    {webinarData?.college_data?.collegeName}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">State :</div>
                  <div className="col-md-8">
                    {webinarData?.state_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">City :</div>
                  <div className="col-md-8">{webinarData?.city_data?.name}</div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Exam :</div>
                  <div className="col-md-8">
                    {webinarData?.examType_data?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-4">Department :</div>
                  <div className="col-md-8">
                    {webinarData?.department_data?.departmentName}
                  </div>
                </div>
              </div>

              {/* *********************************Lead Details *************************************** */}

              <div className="col-md-3 col-sm-3">
                <h5 className="fw-bold">Lead Details:</h5>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Lead Registered :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.TotalCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">First Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.FirstYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Second Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.SecondYearCount}
                  </div>
                </div>
                <div className="row d-flex justify-content-between">
                  <div className="col-md-5">Third Year :</div>
                  <div className="col-md-7">
                    {webinarData?.leadcount?.ThirdYearCount}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom border-3 border-dark">
              <h5 className="text-primary fw-bold">Webinar Status</h5>
            </div>
            {/* *************************************************Status****************************************************** */}
            <form className="col-md-12 mt-4" onSubmit={handleSubmit}>
              <div className="col-md-9 mt-4">
                <div className="col-md-6 row d-flex justify-content-between mt-1">
                  <div className="col-md-4 mt-2">
                    <LabelField lableName="Status" />
                  </div>

                  <div className="col-md-7  ps-3">
                    <InputField
                      placeholder=""
                      value={status}
                      onChangeInput={(value: any) => onChangeStatus(value)}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="row d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Mail ScreenShot" />
                    </div>
                    {/* <a
                      href={webinarData?.mailURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {"image"}
                    </a> */}
                    <div className="col-md-8">
                      <ImagePickerAndViewer
                        lableName={""}
                        value={webinarData?.mailURL}
                        removelabel={true}
                        htmlFor={`photo-upload`}
                        handleChange={(e: any) => handleImageChange(e)}
                        disabled={true}
                        handleNavigate={() =>
                          handleOnClick(webinarData?.mailURL)
                        }
                      />

                      <label className="col-md-8">
                        {uploadMailScreenshotData?.name
                          ? uploadMailScreenshotData?.name
                          : ""}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-4">
                <h5 className="fw-bold text-center">Topper List</h5>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 1</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={webinarData?.rankers[0]?.firstName}
                          onChangeInput={(value: any) =>
                            setFName1(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={webinarData?.rankers[0]?.lastName}
                          onChangeInput={(value: any) =>
                            setLName1(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={webinarData?.rankers[0]?.phoneNo}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            setContact1(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={webinarData?.rankers[0]?.email}
                          onChangeInput={(value: any) => setEmail1(value)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={webinarData?.rankers[0]?.basecourseId}
                          defaultValue={
                            examStreamList?.length > 0
                              ? webinarData?.rankers[0]?.basecourseId
                              : null
                          }
                          options={examStreamList}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 2</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={webinarData?.rankers[1]?.firstName}
                          onChangeInput={(value: any) =>
                            setFName2(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={webinarData?.rankers[1]?.lastName}
                          onChangeInput={(value: any) =>
                            setLName2(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={webinarData?.rankers[1]?.phoneNo}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            setContact2(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={webinarData?.rankers[1]?.email}
                          onChangeInput={(value: any) => setEmail2(value)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={webinarData?.rankers[1]?.basecourseId}
                          defaultValue={
                            examStreamList?.length > 0
                              ? webinarData?.rankers[1]?.basecourseId
                              : null
                          }
                          options={examStreamList}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 3</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={webinarData?.rankers[2]?.firstName}
                          onChangeInput={(value: any) =>
                            setFName3(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={webinarData?.rankers[2]?.lastName}
                          onChangeInput={(value: any) =>
                            setLName3(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={webinarData?.rankers[2]?.phoneNo}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            setContact3(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={webinarData?.rankers[2]?.email}
                          onChangeInput={(value: any) => setEmail3(value)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={webinarData?.rankers[2]?.basecourseId}
                          defaultValue={
                            examStreamList?.length > 0
                              ? webinarData?.rankers[2]?.basecourseId
                              : null
                          }
                          options={examStreamList}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 4</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={webinarData?.rankers[3]?.firstName}
                          onChangeInput={(value: any) =>
                            setFName4(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={webinarData?.rankers[3]?.lastName}
                          onChangeInput={(value: any) =>
                            setLName4(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={webinarData?.rankers[3]?.phoneNo}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            setContact4(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={webinarData?.rankers[3]?.email}
                          onChangeInput={(value: any) => setEmail4(value)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={webinarData?.rankers[3]?.basecourseId}
                          defaultValue={
                            examStreamList?.length > 0
                              ? webinarData?.rankers[3]?.basecourseId
                              : null
                          }
                          options={examStreamList}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 row d-flex justify-content-between">
                  <div className="col-md-1 fw-bold pt-3">Rank 5</div>
                  <div className="col-md-11 row d-flex justify-content-between px-0">
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter First Name"
                          value={webinarData?.rankers[4]?.firstName}
                          onChangeInput={(value: any) =>
                            setFName5(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Last Name"
                          value={webinarData?.rankers[4]?.lastName}
                          onChangeInput={(value: any) =>
                            setLName5(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Phone no" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Phone no"
                          value={webinarData?.rankers[4]?.phoneNo}
                          maxlength={10}
                          onChangeInput={(value: any) =>
                            setContact5(value?.toUpperCase())
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-2  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-3  pt-2 px-0 ">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Email"
                          value={webinarData?.rankers[4]?.email}
                          onChangeInput={(value: any) => setEmail5(value)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  row d-flex justify-content-between mt-2 pt-2 px-0">
                      <div className="col-md-4  pt-2 px-0 ">
                        <LabelField lableName="Subject Name" />
                      </div>
                      <div className="col-md-8">
                        <CustomDropdown
                          lableName="Select Subject Name"
                          setInputText={(value: any) =>
                            onChangeExamStream(value)
                          }
                          value={webinarData?.rankers[4]?.basecourseId}
                          defaultValue={
                            examStreamList?.length > 0
                              ? webinarData?.rankers[4]?.basecourseId
                              : null
                          }
                          options={examStreamList}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
