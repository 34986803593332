import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import {
  getIncomingCallList,
  makeCalltoLead,
} from "../../Redux/Actions/counsellorPanelAction";
import moment from "moment";
import { getTableDataSlots } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";

export default function IncomingCall() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedInUserId } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  const pageName = location.state?.pageName;

  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const [callList, setCallList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      getIncomingCallList(
        {
          startDate,
          endDate,
          counsellorId: loggedInUserId,
        },
        (res: any) => {
          setCallList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        counsellorId: loggedInUserId,
      };
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleEdit = (value: any) => {
    dispatch(updateCommonValues("selectedLeadId", value?.studentLeadsId));
    dispatch(
      makeCalltoLead(
        {
          crmEmployeeId: loggedInUserId,
          leadMobileNo: value?.mobileNo,
        },
        (res: any) => {
          navigate("/leadDetail", {
            state: {
              pageName: "IncomingCallList",
              success: res?.success,
            },
          });
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={callList ? callList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
          <div>
            <PaginationTable
              tableData={callList ? callList : []}
              pagename={"IncomingCallList"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Name",
                // "Number ",
                "Incoming Call Date",
                "Incoming Call Status",
                "Call",
              ]}
              edit={false}
              handleEdit={(value: any) => handleEdit(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData();
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Incoming Call"} />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">
          <form className="d-flex" onSubmit={handleSubmit}>
            <div className="col-md-3 d-flex pb-3 pe-2 ">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex pb-3 pe-2">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 justify-content-end">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>
          </form>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
