import "../../App.css";
import React, { Suspense ,useEffect,useState } from "react"; 
import { useDispatch,useSelector } from 'react-redux'; 
import Sidebar from "../../Module/sidebar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Component/loading";
import { BDManagerSideBar } from "../../DATA/bdManagerSideBar";
import Header from "../../Component/header"; 
import BDManagerDashboard from "./bdTeamLeadDashboard"; 
import BDCollegeList from "./bdCollegeList";
import ShowCollegeDetails from "./showCollegeDetails";
import UpcomingWebinarList from "./upcomingWebinarList";
import CompleteWebinarList from "./completeWebinarList";
import CancelledWebinarList from "./cancelledWebinarList";
import PendingWebinarList from "./pendingWebinarList";
import CollegeDepartmentAdded from "./collegeDepartmentAdded";
import TargetStatusDashboard from "./targetStatusDashboard";
import NotIntrestedList from "./notIntrestedList";
import WebinarAnalysisDetails from "./webinarAnalysisDetails";
import IntrestedList from "./intrestedList";
import CallBackTodayList from "./callBackTodayList";
import OverdueIntrestedList from "./overdueIntrestedList";
 import ManageTeam from "./manageTeam";
 import LeadAnalysisTL from "./leadAnalysisTL";
 import DepartmentwiseleadAnalysisTL from "./departmentwiseleadAnalysisTL";
 import BDTLDashboard from "./bdTLDashboard";
 import LeadOverViewDetails from "./leadOverViewDetails";
 import WebinarOverViewDetails from "./webinarOverViewDetails";
 import DepartmentOverViewDetails from "./departmentOverViewDetails";
import TimeAnalysis from "./timeAnalysis";
import TimeAnalysisOverview from "./timeAnalysisOverview";
import OverdueCallBack from "./overdueCallBack";
import WebinarStatusAnalysis from "./webinarStatusAnalysis";
import ManagerRemarkDetails from "./managerRemarkDetails";
import ManagerRemark from "./managerRemark";
import ResultCompilation from "./resultCompilation";
function BDTeamLead(Props:any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split("/");
  let pageName = currentURLarr[currentURLarr.length - 1];
 

 
 
  return (
    <div className="AppBox">
      {/* <Header/> */}
      <Sidebar data={BDManagerSideBar} pagename/>
      <div className="dashboard">
        
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
            <Route path="/" element={<BDTLDashboard />} /> 
            <Route path="bdCollegeList" element={<BDCollegeList />} /> 
            <Route path="showCollegeDetails" element={<ShowCollegeDetails />} /> 
            <Route path="upcomingWebinarList" element={<UpcomingWebinarList />} /> 
            <Route path="completeWebinarList" element={<CompleteWebinarList />} /> 
            <Route path="cancelledWebinarList" element={<CancelledWebinarList />} /> 
            <Route path="pendingWebinarList" element={<PendingWebinarList />} /> 
            <Route path="collegeDepartmentAdded" element={<CollegeDepartmentAdded />} /> 
            <Route path="targetStatusDashboard" element={<TargetStatusDashboard />} /> 
            <Route path="notIntrestedList" element={<NotIntrestedList />} /> 
            <Route path="webinarAnalysisDetails" element={<WebinarAnalysisDetails />} /> 
            <Route path="intrestedList" element={<IntrestedList />} /> 
            <Route path="callBackTodayList" element={<CallBackTodayList />} /> 
            <Route path="overdueIntrestedList" element={<OverdueIntrestedList />} /> 
            <Route path="manageTeam" element={<ManageTeam />} />   
            <Route path="leadAnalysisTL" element={<LeadAnalysisTL />} />   
            <Route path="departmentwiseleadAnalysisTL" element={<DepartmentwiseleadAnalysisTL />} />   
            <Route path="bdTLDashboard" element={<BDTLDashboard />} />     
            <Route path="leadOverViewDetails" element={<LeadOverViewDetails />} />     
            <Route path="webinarOverViewDetails" element={<WebinarOverViewDetails />} />     
            <Route path="departmentOverViewDetails" element={<DepartmentOverViewDetails />} /> 
            <Route path="timeAnalysis" element={<TimeAnalysis />} />     
            <Route path="timeAnalysisOverview" element={<TimeAnalysisOverview />} />     
            <Route path="overdueCallBack" element={<OverdueCallBack />} />     
            <Route path="webinarStatusAnalysis" element={<WebinarStatusAnalysis />} />   
            <Route path="managerRemarkDetails" element={<ManagerRemarkDetails />} /> 
            <Route path="managerRemark" element={<ManagerRemark />} />     
            <Route path="resultCompilation" element={<ResultCompilation />} /> 
            </Routes>
          </Suspense>
        }
      </div>
       
    </div>
  );
}

export default BDTeamLead;
