interface props {
   name: String,
   validation?:boolean,
   handleClick?:any

}

export default function SubmitButton({ name ,validation, handleClick}: props) {
   return (
      <button type="submit" className={validation ? "btn btn-secondary px-5 searchButton ":"btn btn-primary px-5 searchButton"} disabled={validation } onClick={handleClick}>{name}</button>
   );
}
