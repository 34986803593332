import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import {
  assignStateZoneWise,
  getAssignedStatesZoneWise,
  getZonesList,
  updateAssignedStatesZoneWise,
} from "../../Redux/Actions/leadManagementAction";
import CustomDropdown from "../../Component/customDropdown";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { startsWithSpace, validateFullName } from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

export default function AssignStateZoneWise() {
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [zoneId, setZoneId] = useState<any>("");
  const [zoneName, setZoneName] = useState<any>("");

  const [assignedStateList, setAssignedStateList] = useState<any>([]);
  const [zoneList, setZoneList] = useState<any>([]);
  const [resetZone, setResetZone] = useState<any>([]);

  useEffect(() => {
    fetchZone();
  }, []);

  const fetchZone = () => {
    dispatch(
      getZonesList((res: any) => {
        if (res?.data?.length > 0) {
          setZoneList(res?.data);
        } else {
          setZoneList([]);
        }
      })
    );
  };

  const onHandleZone = (val: any) => {
    setZoneId(val?._id);
    setResetZone(false);
    fetchState(val?._id);
  };

  const fetchState = (zoneId: any) => {
    dispatch(
      getAssignedStatesZoneWise(`${zoneId}`, (res: any) => {
        if (res?.data?.states?.length > 0) {
          setAssignedStateList(res?.data?.states);
        } else {
          setAssignedStateList([]);
        }
      })
    );
  };

  const changeCheckBox = (event: any, index: number) => {
    let newArray = JSON.parse(JSON.stringify(assignedStateList));
    if (event.target.checked) newArray[index]["isSelected"] = true;
    else newArray[index]["isSelected"] = false;
    setAssignedStateList(newArray);
  };

  const handleAssign = (e: any) => {
    e.preventDefault();
    let newArray = JSON.parse(JSON.stringify(assignedStateList));
    const stateIds = newArray
      ?.filter((item: any) => item.isSelected)
      .map((item: any) => {
        return item?._id;
      });
    const postData = {
      stateIds,
    };
    dispatch(
      updateAssignedStatesZoneWise(zoneId, postData, () => {
        setAssignedStateList([]);
        fetchState(zoneId);
      })
    );
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const onChangeZone = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setZoneName(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const handleSubmit = () => {
    dispatch(
      assignStateZoneWise(
        {
          name: zoneName?.toUpperCase(),
        },
        () => {
          setZoneName("");
          fetchZone();
          setOpenPopup(false);
        }
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <form className="p-2 formDiv" onSubmit={handleAssign}>
        <div className="d-flex">
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Zone" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Zone Name"
                setInputText={(value: any) => onHandleZone(value)}
                value={zoneId}
                options={zoneList}
                defaultValue={zoneList?.length > 0 ? zoneId : null}
                Isrequired={true}
                reset={resetZone}
              />
            </div>
          </div>
          <div className="">
            <button
              type="button"
              onClick={() => {
                setOpenPopup(true);
              }}
              className="btn btn-primary mb-3 submitbtn "
            >
              ADD ZONE
            </button>
          </div>
        </div>

        <div className="d-flex mb-2">
          <div className="col-md-1 mt-2">
            <label className="label">States</label>
          </div>
          <div
            className="assign_container ms-2 border w-50 rounded p-3"
            style={{ height: "410px", overflow: "auto" }}
          >
            <div className="input-border">
              {assignedStateList.map((sList: any, index: number) => {
                const { _id } = sList;
                return (
                  <div key={index} className="d-flex center p-1">
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <input
                            id={_id}
                            className="check me-2"
                            type="checkbox"
                            checked={sList?.isSelected}
                            onChange={(event) => {
                              changeCheckBox(event, index);
                            }}
                          />
                          <span className="checkVal">{sList?.name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <SubmitButton name={"Submit"} validation={validation()} />
        </div>
      </form>
    );
  };

  const validation = () => {
    return zoneId === "";
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"ASSIGN STATE ZONE WISE"} />
      <div className="p-3 formDiv">{renderSearchMode()}</div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modelChat"
          open={openPopup}
          onClose={() => {
            handleClose();
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPopup}>
            <div className="modeldivChat p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}> {"Add Zone"}</span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
              <div className="form-check">
                <div>
                  <div className="col-md-9 d-flex mb-3">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Zone Name" />
                    </div>
                    <div className="col-md-9">
                      <InputField
                        placeholder="Enter Zone Name"
                        value={zoneName}
                        onChangeInput={(value: any) => onChangeZone(value)}
                        isRequired={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => {
                      handleClose();
                    }}
                    type="submit"
                    className="btn btn-outline-dark mb-3 submitbtn me-3"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    type="submit"
                    className="btn btn-primary mb-3 submitbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
