import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getCounsellorDetailsByTeamId,
  getCounsellorListById,
  getManagerCallRecord,
  getManagerRevenueList,
  getTeamLeadList,
  getTeamLeadListByManager,
} from "../../Redux/Actions/managerPanelAction";
import moment from "moment";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import { useSelector } from "react-redux";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import SubmitButton from "../../Component/submitButton";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import {
  getExecutiveConnectedCallTargetDetails,
  getExecutiveConnectedCallTargetDetailsById,
} from "../../Redux/Actions/bdManagerPanelAction";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TimeAnalysisOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const counsellorData = location.state?.counsellorId;
  const { loggedInUserRole, crmEmployeeId, sDate, eDate } = useSelector(
    (state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      crmEmployeeId: state.Auth.counsellorId,
      sDate: state.Auth.startDate,
      eDate: state.Auth.endDate,
    })
  );

  const [startDate, SetStartDate] = useState<any>(sDate);
  const [endDate, SetEndDate] = useState<any>(eDate);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);
  const [counsellorByIdList, setCounsellorByIdList] = useState<any>([]);
  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const pageName = location.state?.pageName;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;

  useEffect(() => {
    if (loggedInUserRole === 1) {
      const data: any = {
        startTime: startDate,
        endTime: endDate,
      };
      dispatch(
        getManagerCallRecord(
          counsellorData,
          data,
          (res: any) => {
            showGraphCallTotalConnected(res?.data);
            setTeamLeadList(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 3) {
      const data: any = {
        startTime: startDate,
        endTime: endDate,
      };
      // if (pageName === "callerDetails") {
      //   SetStartDate(leadStartDate);
      //   SetEndDate(leadEndDate);
      //   data["startDate"] = leadStartDate;
      //   data["endDate"] = leadEndDate;
      // }
      // dispatch(
      //   getManagerCallRecord(
      //     crmEmployeeId,
      //     data,
      //     (res: any) => {
      //       showGraphCallTotalConnected(res?.data);
      //       setTeamLeadList(res?.data);
      //     },
      //     () => {}
      //   )
      // );
      dispatch(
        getExecutiveConnectedCallTargetDetails(
          {
            startDate,
            endDate,
          },
          (res: any) => {
            showGraphCallTotalConnected(res?.targets);
            setTeamLeadList(res?.targets);
          },
          () => {}
        )
      );
    }
  }, []);

  const showGraphCallTotalConnected = (list: any) => {
    setXLabelCallTotal([]);
    setGraphdataCallTotal([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.executiveName);
      graphDataList?.push(item?.totalConnectedCall?.achieved);
    });
    setXLabelCallTotal(labelList);
    setGraphdataCallTotal(graphDataList);
  };

  const onChange = () => {
    showGraphCallTotalConnected([]);
    setTeamLeadList([]);
    let data = {
      startTime: startDate,
      endTime: endDate,
    };
    // dispatch(
    //   getManagerCallRecord(
    //     crmEmployeeId,
    //     data,
    //     (res: any) => {
    //       showGraphCallTotalConnected(res?.data);
    //       setTeamLeadList(res?.data);
    //     },
    //     () => {}
    //   )
    // );
    dispatch(
      getExecutiveConnectedCallTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphCallTotalConnected(res?.targets);
          setTeamLeadList(res?.targets);
        },
        () => {}
      )
    );
  };

  const onClickLead = (data: any) => {
    // dispatch(updateCommonValues("selectedLeadId", data?.studentLeadId));
    if (loggedInUserRole === 1) {
      // navigate("../completeLeadDetail", {
      //   state: {
      //     pageName: "timeAnalysisOverview",
      //     counsellorId: counsellorData,
      //   },
      // });
    } else if (loggedInUserRole === 3) {
      navigate("../webinarAnalysisDetails", {
        state: {
          pageName: "timeAnalysisOverview",
          departmentId: data?.departmentId,
          departmentData: data,
          IsEdit: "true",
          IsCheck: "true",
        },
      });
    }
  };

  const renderListMode = () => {
    return (
      <div>
        <GenaralTable
          tableData={counsellorByIdList ? counsellorByIdList : []}
          pagename={"BDETimeAnalysisOverview"}
          tableHead={[
            "Sr.No.",
            "First Name",
            "Last Name",
            "Executive Name",
            "Call Duration",
            "Call Date",
            "Call Time",
          ]}
          handleClick={onClickLead}
        />
      </div>
    );
  };
  const getManagerData = (val: any) => {
    if (loggedInUserRole === 3) {
      const data = {
        startDate,
        endDate,
        executiveId: teamLeadList[val?.index]?.executiveId,
      };
      dispatch(
        getExecutiveConnectedCallTargetDetailsById(
          data,
          (res: any) => {
            setCounsellorByIdList(res);
          },
          () => {}
        )
      );
    }
  };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Time Analysis Overview"} />
      <div className="col-md-12 row">
        <div className="formDiv formWidth p-">
          <div className="d-flex px-5 mx-5">
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className=" col-md-3 text-center mb-2  mt-2 me-4 ">
              <SubmitButton name={"Submit"} handleClick={onChange} />
            </div>
          </div>
          <div className="d-flex justify-content-between mx-3 mt-3 boxshadow">
            <div className="col-md-12 p-3">
              <h4 className="fw-bold">Overview </h4>
              <SalesPanelSatckGraph
                label={xLabelCallTotal}
                value={GraphdataCallTotal}
                getManagerData={(val: any) => getManagerData(val)}
              />
            </div>
          </div>
          <div className="my-3 mx-3">{renderListMode()}</div>
        </div>
      </div>
    </div>
  );
}
