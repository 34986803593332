import GenaralTable from "../../../Component/genaralTable";
import PageTitle from "../../../Component/pageTitle";
import PaginationTable from "../../../Component/paginationTable";

const StudentTestData = (props: any) => {
  const { studentData } = props?.data;

  const renderList = () => {
    return (
      <>
        <GenaralTable
          tableData={studentData ? studentData : []}
          pagename={"studentTestData"}
          tableHead={[
            "S.NO",
            "TEST NAME",
            "TOTAL MARKS",
            "CORRECT MARKS",
            "WRONG MARKS",
            "SKIPPED QUESTION",
            "RANK OF TEST",
          ]}
        />
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={studentData?.testType ? studentData?.testType : ""}
        isGoBack
        onClick={props?.handleBack}
      />
      <div className="row col-12 p-2">
        <div className="formWidth formDiv">{renderList()}</div>
      </div>
    </div>
  );
};

export default StudentTestData;
