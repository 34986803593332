import "../../App.css";
import React, { Suspense ,useEffect,useState } from "react"; 
import { useDispatch,useSelector } from 'react-redux'; 
import Sidebar from "../../Module/sidebar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Component/loading";
import { BDEManagementPanel } from "../../DATA/BDEManagementPanel"; 
import Header from "../../Component/header"; 
import BdeEmployee from "./bdeEmployee";
import BDEIncentiveAllocation from "./bdeIncentiveAllocation";
import CallTimeTarget from "./callTimeTarget";
import TotalConnectedCount from "./totalConnectedCall";
import UniqueCallTarget from "./uniqueCallTarget";
import DepartmentCallTarget from "./departmentCallTarget";
import ManagerTLIncentiveAllocation from "./managerTLIncentiveAllocation";
import WebinarTarget from "./webinarTarget";
import ResultCompilation from "./resultCompilation";
import StudentResult from "./studentResult";
function BdeAdminPanelDashboard(Props:any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split("/");
  let pageName = currentURLarr[currentURLarr.length - 1];
 

 
 
  return (
    <div className="AppBox">
      {/* <Header/> */}
      <Sidebar data={BDEManagementPanel} pagename/>
      <div className="dashboard">
       
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
            <Route path="/" element={<BdeEmployee />} /> 
            <Route path="bdeEmployee" element={<BdeEmployee />} /> 
            <Route path="bdeIncentiveAllocation" element={<BDEIncentiveAllocation />} /> 
            <Route path="callTimeTarget" element={<CallTimeTarget />} /> 
            <Route path="totalConnectedCount" element={<TotalConnectedCount/>} /> 
            <Route path="uniqueCallTarget" element={<UniqueCallTarget/>} /> 
            <Route path="departmentCallTarget" element={<DepartmentCallTarget/>} /> 
            <Route path="managerTLIncentiveAllocation" element={<ManagerTLIncentiveAllocation/>} /> 
            <Route path="webinarTarget" element={<WebinarTarget />} /> 
            <Route path="resultCompilation" element={<ResultCompilation />} /> 
            <Route path="studentResult" element={<StudentResult/>} /> 
            
            </Routes>
          </Suspense>
        }
      </div>
       
    </div>
  );
}

export default BdeAdminPanelDashboard;
