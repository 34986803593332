import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import {
  getExamList,
  getExamStreamList,
  getRevenueLeadsAnalysisList,
  getSubExam,
} from "../../Redux/Actions/counsellorPanelAction";
import CustomDropdown from "../../Component/customDropdown";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import { getEmployeetByManagerId } from "../../Redux/Actions/managerPanelAction";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import { getSMManagerList } from "../../Redux/Actions/superManagerAction";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LeadAllottedAnalysisbyDate() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [exam, setExam] = useState<any>("");
  const [subExam, setSubExam] = useState<any>();
  const [examStream, setExamStream] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [counsellor, setCounsellor] = useState<any>("");

  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [revenueLeadList, setRevenueLeadList] = useState<any>([]);
  const [managerList, setManagerList] = useState([]);
  const [counsellorList, setCounsellorList] = useState([]);

  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata, setGraphdata] = useState<any>([]);

  const [resetExam, setResetExam] = useState<any>(false);
  const [resetSubExam, setResetSubExam] = useState<any>(false);
  const [resetExamStream, setResetExamStream] = useState<any>(false);
  const [resetManager, setResetManager] = useState<any>(false);
  const [resetCounsellor, setResetCounsellor] = useState<any>(false);
  const [teamLead, setTeamLead] = useState<any>("");
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [resetTeamLead, setResetTeamLead] = useState();
  const [xLabelExpiry, setXLabelExpiry] = useState<any>([]);
  const [GraphdataExpiry, setGraphdataExpiry] = useState<any>([]);
  const { managerId, loggedInUserRole, loggedInUserId } = useSelector(
    (state: any) => ({
      managerId: state.Auth.counsellorId,
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserId: state.Auth.loggedInUserId,
    })
  );

  useEffect(() => {
    dispatch(
      getSMManagerList(
        loggedInUserId,
        (res: any) => {
          setManagerList(res);
        },
        () => {}
      )
    );

    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    const postData = {
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(
      getRevenueLeadsAnalysisList(
        postData,
        (res: any) => {
          setRevenueLeadList(res);
          showGraph(res);
          showExpiryGraph(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setTeamLeadList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeTeamLead = (val: any) => {
    setTeamLead(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    setResetCounsellor(false);
  };

  const onChangeExam = (val: any) => {
    setExam(val);
    setResetExam(false);
    if (subExamList) {
      dispatch(
        getSubExam(
          {
            examTypeId: val._id,
          },
          (res: any) => {
            if (res.length > 0) {
              setSubExamList(res);
            } else {
              dispatch(
                getExamStreamList(`${val._id}`, (res: any) => {
                  setExamStreamList(res);
                })
              );
            }
          }
        )
      );
    } else {
      dispatch(
        getExamStreamList(`${val._id}`, (res: any) => {
          setExamStreamList(res);
        })
      );
    }
  };

  const onChangeSubExam = (val: any) => {
    setSubExam(val);
    setResetSubExam(false);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: any) => {
    setExamStream(val);
    setResetExamStream(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setRevenueLeadList([]);
    showGraph([]);
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      counsellorId: counsellor?._id,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      managerId: manager?._id,
      teamLeadId: teamLead?._id,
    };
    dispatch(
      getRevenueLeadsAnalysisList(
        postData,
        (res: any) => {
          setRevenueLeadList(res);
          showGraph(res);
          showExpiryGraph(res);
        },
        () => {}
      )
    );
  };

  const showGraph = (list: any) => {
    while (xLabel?.length > 0 && Graphdata?.length > 0) {
      xLabel?.pop();
      Graphdata?.pop();
    }
    if (xLabel?.length === 0 && Graphdata?.length === 0) {
      if (list?.total?.WEBSITE_REGISTRATION_Leads > 0) {
        xLabel?.push("Website");
        Graphdata?.push(list?.total?.WEBSITE_REGISTRATION_Revenue);
      }
      if (list?.total?.WEBINAR_Leads > 0) {
        xLabel?.push("Webinar");
        Graphdata?.push(list?.total?.WEBINAR_Revenue);
      }
      if (list?.total?.FIELD_MARKETING_Leads > 0) {
        xLabel?.push("Field Marketing");
        Graphdata?.push(list?.total?.FIELD_MARKETING_Revenue);
      }
      if (list?.total?.PAID_AD_MANUAL_Leads > 0) {
        xLabel?.push("PAID AD MANUAL");
        Graphdata?.push(list?.total?.PAID_AD_MANUAL_Percentage);
      }
      if (list?.total?.PAID_AD_AUTOMATIC_Leads > 0) {
        xLabel?.push("PAID AD AUTOMATIC");
        Graphdata?.push(list?.total?.PAID_AD_AUTOMATIC_Percentage);
      }
      if (list?.total?.HP_ENQUIRY_Leads > 0) {
        xLabel?.push("HP Enquiry");
        Graphdata?.push(list?.total?.HP_ENQUIRY_Revenue);
      }
      if (list?.total?.ORGANIC_SOCIAL_MEDIA_Leads > 0) {
        xLabel?.push("Organic Social Media");
        Graphdata?.push(list?.total?.ORGANIC_SOCIAL_MEDIA_Revenue);
      }
      if (list?.total?.APP_REGISTRATION_Leads > 0) {
        xLabel?.push("App Registration");
        Graphdata?.push(list?.total?.APP_REGISTRATION_Revenue);
      }
      // if(list?.total?.REFERENCE_Leads >= 0){
      xLabel?.push("Reference");
      Graphdata?.push(list?.total?.REFERENCE_Revenue);
      // }
    }
  };
  const showExpiryGraph = (list: any) => {
    while (xLabelExpiry?.length > 0 && GraphdataExpiry?.length > 0) {
      xLabelExpiry?.pop();
      GraphdataExpiry?.pop();
    }
    if (xLabelExpiry?.length === 0 && GraphdataExpiry?.length === 0) {
      if (list?.total?.WEBSITE_REGISTRATION_expLeads > 0) {
        xLabelExpiry?.push("Website");
        GraphdataExpiry?.push(list?.total?.WEBSITE_REGISTRATION_expRevenue);
      }
      if (list?.total?.WEBINAR_expLeads > 0) {
        xLabelExpiry?.push("Webinar");
        GraphdataExpiry?.push(list?.total?.WEBINAR_expRevenue);
      }
      if (list?.total?.FIELD_MARKETING_expLeads > 0) {
        xLabelExpiry?.push("Field Marketing");
        GraphdataExpiry?.push(list?.total?.FIELD_MARKETING_expRevenue);
      }
      if (list?.total?.PAID_AD_MANUAL_expLeads > 0) {
        xLabelExpiry?.push("PAID AD MANUAL");
        GraphdataExpiry?.push(list?.total?.PAID_AD_MANUAL_expPercentage);
      }
      if (list?.total?.PAID_AD_AUTOMATIC_expLeads > 0) {
        xLabelExpiry?.push("PAID AD AUTOMATIC");
        GraphdataExpiry?.push(list?.total?.PAID_AD_AUTOMATIC_expPercentage);
      }
      if (list?.total?.HP_ENQUIRY_expLeads > 0) {
        xLabelExpiry?.push("HP Enquiry");
        GraphdataExpiry?.push(list?.total?.HP_ENQUIRY_expRevenue);
      }
      if (list?.total?.ORGANIC_SOCIAL_MEDIA_expLeads > 0) {
        xLabelExpiry?.push("Organic Social Media");
        GraphdataExpiry?.push(list?.total?.ORGANIC_SOCIAL_MEDIA_expRevenue);
      }
      if (list?.total?.APP_REGISTRATION_expLeads > 0) {
        xLabelExpiry?.push("App Registration");
        GraphdataExpiry?.push(list?.total?.APP_REGISTRATION_expRevenue);
      }
      // if(list?.total?.REFERENCE_Leads >= 0){
      xLabelExpiry?.push("Reference");
      GraphdataExpiry?.push(list?.total?.REFERENCE_expRevenue);
      // }
    }
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSubmit}>
        <div className="col-md-4 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Manager" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Manager"
              setInputText={(value: any) => onChangeManager(value)}
              value={manager}
              options={managerList}
              // Isrequired={true}
              reset={resetManager}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3 ">
          <div className="col-md-4 mt-2">
            <LabelField lableName="Select Team Lead" />
          </div>
          <div className="col-md-8 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Team Lead"
              setInputText={(value: any) => onChangeTeamLead(value)}
              value={teamLead}
              options={teamLeadList}
              // Isrequired={true}
              reset={resetTeamLead}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Counsellor" />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Counsellor"
              setInputText={(value: any) => onChangeCounsellor(value)}
              value={counsellor}
              options={counsellorList}
              // Isrequired={true}
              reset={resetCounsellor}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
              // Isrequired={true}
              reset={resetExam}
            />
          </div>
        </div>
        {subExamList?.length > 0 ? (
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Sub Exam Name" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select Sub Exam Name"
                setInputText={(value: any) => onChangeSubExam(value)}
                value={subExam}
                options={subExamList}
                reset={resetSubExam}
                // reset={resetSubexamList}
              />
            </div>
          </div>
        ) : null}
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Stream" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam Stream"
              setInputText={(value: any) => onChangeExamStream(value)}
              value={examStream}
              options={examStreamList}
              // Isrequired={true}
              reset={resetExamStream}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <div className="w-100 counsellortable table-responsive">
        <>
          <table className=" w-100 text-center table-bordered border-secondary ">
            {revenueLeadList?.data?.length > 0 ? (
              <>
                <tr>
                  {revenueLeadList?.data?.length > 0 ? (
                    <th rowSpan={3}>Date</th>
                  ) : null}
                  {revenueLeadList?.data?.length > 0 ? (
                    <th colSpan={4}>Total</th>
                  ) : null}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads ? (
                    <th colSpan={4}>Website</th>
                  ) : null}
                  {revenueLeadList?.total?.WEBINAR_Leads ? (
                    <th colSpan={4}>Webinar</th>
                  ) : null}
                  {revenueLeadList?.total?.FIELD_MARKETING_Leads ? (
                    <th colSpan={4}>Field Marketing</th>
                  ) : null}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_Leads ? (
                    <th colSpan={4}>PAID AD MANUAL</th>
                  ) : null}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads ? (
                    <th colSpan={4}>PAID AD AUTOMATIC</th>
                  ) : null}
                  {revenueLeadList?.total?.HP_ENQUIRY_Leads ? (
                    <th colSpan={4}>HP Enquiry</th>
                  ) : null}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads ? (
                    <th colSpan={4}>Organic Social Media</th>
                  ) : null}
                  {revenueLeadList?.total?.APP_REGISTRATION_Leads ? (
                    <th colSpan={4}>App Registration</th>
                  ) : null}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? ( */}
                  <th colSpan={8}>Reference</th>
                  {/* ) : null} */}
                </tr>

                <tr>
                  {/* ********total*********** */}
                  {revenueLeadList?.total?.total_Leads ||
                  revenueLeadList?.total?.total_Leads >= 0 ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>Conversion</th>
                    </>
                  ) : null}

                  {/* ********website*********** */}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads ||
                  revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads > 0 ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Webinar*********** */}
                  {revenueLeadList?.total?.WEBINAR_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Field Marketing*********** */}
                  {revenueLeadList?.total?.FIELD_MARKETING_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********PAID AD MANUAL*********** */}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********PAID AD AUTOMATIC*********** */}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********HP Enquiry*********** */}
                  {revenueLeadList?.total?.HP_ENQUIRY_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Organic Social Media*********** */}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********App Registration*********** */}
                  {revenueLeadList?.total?.APP_REGISTRATION_Leads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}
                  {/* ********Reference*********** */}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? (
                <> */}
                  <th>Lead</th>
                  <th>Admission</th>
                  <th>Revenue</th>
                  <th>conversion</th>
                  <th>Exp Lead</th>
                  <th>Exp Admission</th>
                  <th>Exp Revenue</th>
                  <th>Exp conversion</th>
                  {/*</>
               ) : null} */}
                </tr>
                <tr>
                  {/* ********total*********** */}
                  {revenueLeadList?.total?.total_Leads ||
                  revenueLeadList?.total?.total_Leads === 0 ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.total_Leads
                          ? revenueLeadList?.total?.total_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.total_AdmissionCount
                          ? revenueLeadList?.total?.total_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.total_Revenue
                          ? revenueLeadList?.total?.total_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.total_Percentage
                          ? revenueLeadList?.total?.total_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********website*********** */}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads ||
                  revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads > 0 ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads
                          ? revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.WEBSITE_REGISTRATION_AdmissionCount
                          ? revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_Revenue
                          ? revenueLeadList?.total?.WEBSITE_REGISTRATION_Revenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.WEBSITE_REGISTRATION_AdmissionCount /
                            revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Webinar*********** */}
                  {revenueLeadList?.total?.WEBINAR_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_Leads
                          ? revenueLeadList?.total?.WEBINAR_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_AdmissionCount
                          ? revenueLeadList?.total?.WEBINAR_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_Revenue
                          ? revenueLeadList?.total?.WEBINAR_Revenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total?.WEBINAR_AdmissionCount /
                            revenueLeadList?.total?.WEBINAR_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Field Marketing*********** */}
                  {revenueLeadList?.total?.FIELD_MARKETING_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_Leads
                          ? revenueLeadList?.total?.FIELD_MARKETING_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_AdmissionCount
                          ? revenueLeadList?.total
                              ?.FIELD_MARKETING_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_Revenue
                          ? revenueLeadList?.total?.FIELD_MARKETING_Revenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.FIELD_MARKETING_AdmissionCount /
                            revenueLeadList?.total?.FIELD_MARKETING_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********PAID AD MANUAL*********** */}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_Leads
                          ? revenueLeadList?.total?.PAID_AD_MANUAL_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_AdmissionCount
                          ? revenueLeadList?.total
                              ?.PAID_AD_MANUAL_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_Percentage
                          ? revenueLeadList?.total?.PAID_AD_MANUAL_Percentage
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_Percentage
                          ? revenueLeadList?.total?.PAID_AD_MANUAL_Percentage
                          : 0}
                        {(
                          (revenueLeadList?.total
                            ?.PAID_AD_MANUAL_AdmissionCount /
                            revenueLeadList?.total?.PAID_AD_MANUAL_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********PAID AD AUTOMATIC*********** */}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads
                          ? revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.PAID_AD_AUTOMATIC_AdmissionCount
                          ? revenueLeadList?.total
                              ?.PAID_AD_AUTOMATIC_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Percentage
                          ? revenueLeadList?.total?.PAID_AD_AUTOMATIC_Percentage
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Percentage
                          ? revenueLeadList?.total?.PAID_AD_AUTOMATIC_Percentage
                          : 0}
                        {(
                          (revenueLeadList?.total
                            ?.PAID_AD_AUTOMATIC_AdmissionCount /
                            revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********HP Enquiry*********** */}
                  {revenueLeadList?.total?.HP_ENQUIRY_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_Leads
                          ? revenueLeadList?.total?.HP_ENQUIRY_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_AdmissionCount
                          ? revenueLeadList?.total?.HP_ENQUIRY_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_Revenue
                          ? revenueLeadList?.total?.HP_ENQUIRY_Revenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total?.HP_ENQUIRY_AdmissionCount /
                            revenueLeadList?.total?.HP_ENQUIRY_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Organic Social Media*********** */}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads
                          ? revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                          ? revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Revenue
                          ? revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Revenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.ORGANIC_SOCIAL_MEDIA_AdmissionCount /
                            revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********App Registration*********** */}
                  {revenueLeadList?.total?.APP_REGISTRATION_Leads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_Leads
                          ? revenueLeadList?.total?.APP_REGISTRATION_Leads
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_AdmissionCount
                          ? revenueLeadList?.total
                              ?.APP_REGISTRATION_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_Revenue
                          ? revenueLeadList?.total?.APP_REGISTRATION_Revenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.APP_REGISTRATION_AdmissionCount /
                            revenueLeadList?.total?.APP_REGISTRATION_Leads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}
                  {/* ********Reference*********** */}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? ( */}
                  <>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_Leads
                        ? revenueLeadList?.total?.REFERENCE_Leads
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_AdmissionCount
                        ? revenueLeadList?.total?.REFERENCE_AdmissionCount
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_Revenue
                        ? revenueLeadList?.total?.REFERENCE_Revenue
                        : 0}
                    </th>
                    <th>
                      {(
                        (revenueLeadList?.total?.REFERENCE_AdmissionCount /
                          revenueLeadList?.total?.REFERENCE_Leads) *
                        100
                      ).toFixed(2)}
                      %
                    </th>
                    {/* Expired Refrence */}
                    <th>
                      {revenueLeadList?.total?.totalexp_Leads
                        ? revenueLeadList?.total?.totalexp_Leads
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.totalexp_AdmissionCount
                        ? revenueLeadList?.total?.totalexp_AdmissionCount
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.totalexp_Revenue
                        ? revenueLeadList?.total?.totalexp_Revenue
                        : 0}
                    </th>
                    <th>
                      {(
                        (revenueLeadList?.total?.totalexp_AdmissionCount /
                          revenueLeadList?.total?.totalexp_Leads) *
                        100
                      ).toFixed(2)}
                      %
                    </th>
                  </>
                  {/* ) : null} */}
                </tr>

                {revenueLeadList?.data?.map((data: any) => {
                  return (
                    <tbody>
                      {data?._id?.status === 2 ? (
                        <tr>
                          {/* **************total********** */}
                          <td>
                            {data?._id?.status === 2 ? data?._id?.date1 : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.total_Leads
                                ? data?.total_Leads
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.total_AdmissionCount
                                ? data?.total_AdmissionCount
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.total_Revenue
                                ? data?.total_Revenue
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.total_Percentage
                                ? data?.total_Percentage
                                : 0
                              : null}
                            %
                          </td>
                          {/* **************website********** */}
                          {revenueLeadList?.total?.WEBSITE_REGISTRATION_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBSITE_REGISTRATION_Leads
                                    ? data?.WEBSITE_REGISTRATION_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBSITE_REGISTRATION_AdmissionCount
                                    ? data?.WEBSITE_REGISTRATION_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBSITE_REGISTRATION_Revenue
                                    ? data?.WEBSITE_REGISTRATION_Revenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBSITE_REGISTRATION_Percentage
                                    ? data?.WEBSITE_REGISTRATION_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********Webinar*********** */}
                          {revenueLeadList?.total?.WEBINAR_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBINAR_Leads
                                    ? data?.WEBINAR_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBINAR_AdmissionCount
                                    ? data?.WEBINAR_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBINAR_Revenue
                                    ? data?.WEBINAR_Revenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.WEBINAR_Percentage
                                    ? data?.WEBINAR_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********Field Marketing*********** */}

                          {revenueLeadList?.total?.FIELD_MARKETING_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.FIELD_MARKETING_Leads
                                    ? data?.FIELD_MARKETING_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.FIELD_MARKETING_AdmissionCount
                                    ? data?.FIELD_MARKETING_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.FIELD_MARKETING_Revenue
                                    ? data?.FIELD_MARKETING_Revenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.FIELD_MARKETING_Percentage
                                    ? data?.FIELD_MARKETING_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********PAID AD MANUAL*********** */}

                          {revenueLeadList?.total?.PAID_AD_MANUAL_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_MANUAL_Leads
                                    ? data?.PAID_AD_MANUAL_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_MANUAL_AdmissionCount
                                    ? data?.PAID_AD_MANUAL_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_MANUAL_Percentage
                                    ? data?.PAID_AD_MANUAL_Percentage
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_MANUAL_Percentage
                                    ? data?.PAID_AD_MANUAL_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********PAID AD AUTOMATIC*********** */}

                          {revenueLeadList?.total?.PAID_AD_AUTOMATIC_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_AUTOMATIC_Leads
                                    ? data?.PAID_AD_AUTOMATIC_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_AUTOMATIC_AdmissionCount
                                    ? data?.PAID_AD_AUTOMATIC_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_AUTOMATIC_Percentage
                                    ? data?.PAID_AD_AUTOMATIC_Percentage
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.PAID_AD_AUTOMATIC_Percentage
                                    ? data?.PAID_AD_AUTOMATIC_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}
                          {/* ********HP Enquiry*********** */}

                          {revenueLeadList?.total?.HP_ENQUIRY_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.HP_ENQUIRY_Leads
                                    ? data?.HP_ENQUIRY_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.HP_ENQUIRY_AdmissionCount
                                    ? data?.HP_ENQUIRY_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.HP_ENQUIRY_Revenue
                                    ? data?.HP_ENQUIRY_Revenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.HP_ENQUIRY_Percentage
                                    ? data?.HP_ENQUIRY_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}
                          {/* ********Organic Social Media*********** */}

                          {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.ORGANIC_SOCIAL_MEDIA_Leads
                                    ? data?.ORGANIC_SOCIAL_MEDIA_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                                    ? data?.ORGANIC_SOCIAL_MEDIA_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.ORGANIC_SOCIAL_MEDIA_Revenue
                                    ? data?.ORGANIC_SOCIAL_MEDIA_Revenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.ORGANIC_SOCIAL_MEDIA_Percentage
                                    ? data?.ORGANIC_SOCIAL_MEDIA_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********App Registration*********** */}

                          {revenueLeadList?.total?.APP_REGISTRATION_Leads &&
                          data?._id?.status === 2 ? (
                            <>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.APP_REGISTRATION_Leads
                                    ? data?.APP_REGISTRATION_Leads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.APP_REGISTRATION_AdmissionCount
                                    ? data?.APP_REGISTRATION_AdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.APP_REGISTRATION_Revenue
                                    ? data?.APP_REGISTRATION_Revenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 2
                                  ? data?.APP_REGISTRATION_Percentage
                                    ? data?.APP_REGISTRATION_Percentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}
                          {/* ********Reference*********** */}
                          {/* {data?.REFERENCE_Leads >= 0 ? (
                      <> */}
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_Leads
                                ? data?.REFERENCE_Leads
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_AdmissionCount
                                ? data?.REFERENCE_AdmissionCount
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_Revenue
                                ? data?.REFERENCE_Revenue
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_Percentage
                                ? data?.REFERENCE_Percentage
                                : 0
                              : null}
                            %
                          </td>
                          {/* ****************************Expire Leads***************************** */}
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_expLeads
                                ? data?.REFERENCE_expLeads
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_expAdmissionCoun
                                ? data?.REFERENCE_expAdmissionCoun
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_expRevenue
                                ? data?.REFERENCE_expRevenue
                                : 0
                              : null}
                          </td>
                          <td>
                            {data?._id?.status === 2
                              ? data?.REFERENCE_expPercentage
                                ? data?.REFERENCE_expPercentage
                                : 0
                              : null}
                            %
                          </td>
                          {/* </>
                    ) : null} */}
                        </tr>
                      ) : null}
                    </tbody>
                  );
                })}
              </>
            ) : (
              <tr> No Record Found</tr>
            )}
          </table>
        </>
      </div>
    );
  };

  const renderGraphMode = () => {
    return (
      <>
        {Graphdata?.length > 0 ? (
          <div className="col-md-6 p-3 ms-5">
            <CallRecordedGraph label={xLabel} value={Graphdata} />
          </div>
        ) : null}
      </>
    );
  };
  const renderExpiryListMode = () => {
    return (
      <div className="w-100 counsellortable table-responsive">
        <>
          <table className=" w-100 text-center table-bordered border-secondary ">
            {revenueLeadList?.data?.length > 0 ? (
              <>
                <tr>
                  {revenueLeadList?.data?.length > 0 ? (
                    <th rowSpan={3}>Date</th>
                  ) : null}
                  {revenueLeadList?.data?.length > 0 ? (
                    <th colSpan={4}>Total</th>
                  ) : null}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_expLeads ? (
                    <th colSpan={4}>Website</th>
                  ) : null}
                  {revenueLeadList?.total?.WEBINAR_expLeads ? (
                    <th colSpan={4}>Webinar</th>
                  ) : null}
                  {revenueLeadList?.total?.FIELD_MARKETING_expLeads ? (
                    <th colSpan={4}>Field Marketing</th>
                  ) : null}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_expLeads ? (
                    <th colSpan={4}>PAID AD MANUAL</th>
                  ) : null}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expLeads ? (
                    <th colSpan={4}>PAID AD AUTOMATIC</th>
                  ) : null}
                  {revenueLeadList?.total?.HP_ENQUIRY_expLeads ? (
                    <th colSpan={4}>HP Enquiry</th>
                  ) : null}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_expLeads ? (
                    <th colSpan={4}>Organic Social Media</th>
                  ) : null}
                  {revenueLeadList?.total?.APP_REGISTRATION_expLeads ? (
                    <th colSpan={4}>App Registration</th>
                  ) : null}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? ( */}
                  <th colSpan={4}>Reference</th>
                  {/* ) : null} */}
                </tr>

                <tr>
                  {/* ********total*********** */}
                  {revenueLeadList?.total?.totalexp_Leads ||
                  revenueLeadList?.total?.totalexp_Leads >= 0 ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>Conversion</th>
                    </>
                  ) : null}

                  {/* ********website*********** */}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_expLeads ||
                  revenueLeadList?.total?.WEBSITE_REGISTRATION_expLeads > 0 ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Webinar*********** */}
                  {revenueLeadList?.total?.WEBINAR_expLeads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Field Marketing*********** */}
                  {revenueLeadList?.total?.FIELD_MARKETING_expLeads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********PAID AD MANUAL*********** */}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_expLeads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********PAID AD AUTOMATIC*********** */}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expLeads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********HP Enquiry*********** */}
                  {revenueLeadList?.total?.HP_ENQUIRY_expLeads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********Organic Social Media*********** */}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_expLeads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}

                  {/* ********App Registration*********** */}
                  {revenueLeadList?.total?.APP_REGISTRATION_expLeads ? (
                    <>
                      <th>Lead</th>
                      <th>Admission</th>
                      <th>Revenue</th>
                      <th>conversion</th>
                    </>
                  ) : null}
                  {/* ********Reference*********** */}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? (
                <> */}
                  <th>Lead</th>
                  <th>Admission</th>
                  <th>Revenue</th>
                  <th>conversion</th>
                  {/*</>
               ) : null} */}
                </tr>
                <tr>
                  {/* ********total*********** */}
                  {revenueLeadList?.total?.totalexp_Leads ||
                  revenueLeadList?.total?.totalexp_Leads === 0 ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.totalexp_Leads
                          ? revenueLeadList?.total?.totalexp_Leads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total?.totalexp_AdmissionCount
                          ? revenueLeadList?.total?.totalexp_AdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.totalexp_Revenue
                          ? revenueLeadList?.total?.totalexp_Revenue
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.totalexp_Percentage
                          ? revenueLeadList?.total?.totalexp_Percentage
                          : 0}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********website*********** */}
                  {revenueLeadList?.total?.WEBSITE_REGISTRATION_expLeads ||
                  revenueLeadList?.total?.WEBSITE_REGISTRATION_expLeads > 0 ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_expLeads
                          ? revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.WEBSITE_REGISTRATION_expAdmissionCount
                          ? revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_expAdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBSITE_REGISTRATION_expRevenue
                          ? revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_expRevenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.WEBSITE_REGISTRATION_expAdmissionCount /
                            revenueLeadList?.total
                              ?.WEBSITE_REGISTRATION_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Webinar*********** */}
                  {revenueLeadList?.total?.WEBINAR_expLeads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_expLeads
                          ? revenueLeadList?.total?.WEBINAR_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_expAdmissionCount
                          ? revenueLeadList?.total?.WEBINAR_expAdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.WEBINAR_expRevenue
                          ? revenueLeadList?.total?.WEBINAR_expRevenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total?.WEBINAR_expAdmissionCount /
                            revenueLeadList?.total?.WEBINAR_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Field Marketing*********** */}
                  {revenueLeadList?.total?.FIELD_MARKETING_expLeads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_expLeads
                          ? revenueLeadList?.total?.FIELD_MARKETING_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.FIELD_MARKETING_expAdmissionCount
                          ? revenueLeadList?.total
                              ?.FIELD_MARKETING_expAdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.FIELD_MARKETING_expRevenue
                          ? revenueLeadList?.total?.FIELD_MARKETING_expRevenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.FIELD_MARKETING_expAdmissionCount /
                            revenueLeadList?.total?.FIELD_MARKETING_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********PAID AD MANUAL*********** */}
                  {revenueLeadList?.total?.PAID_AD_MANUAL_expLeads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_expLeads
                          ? revenueLeadList?.total?.PAID_AD_MANUAL_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_expPercentage
                          ? revenueLeadList?.total?.PAID_AD_MANUAL_expPercentage
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_expPercentage
                          ? revenueLeadList?.total?.PAID_AD_MANUAL_expPercentage
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_MANUAL_expPercentage
                          ? revenueLeadList?.total?.PAID_AD_MANUAL_expPercentage
                          : 0}
                        {(
                          (revenueLeadList?.total
                            ?.PAID_AD_MANUAL_expPercentage /
                            revenueLeadList?.total?.PAID_AD_MANUAL_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********PAID AD AUTOMATIC*********** */}
                  {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expLeads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expLeads
                          ? revenueLeadList?.total?.PAID_AD_AUTOMATIC_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expPercentage
                          ? revenueLeadList?.total
                              ?.PAID_AD_AUTOMATIC_expPercentage
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expPercentage
                          ? revenueLeadList?.total
                              ?.PAID_AD_AUTOMATIC_expPercentage
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expPercentage
                          ? revenueLeadList?.total
                              ?.PAID_AD_AUTOMATIC_expPercentage
                          : 0}
                        {(
                          (revenueLeadList?.total
                            ?.PAID_AD_AUTOMATIC_expPercentage /
                            revenueLeadList?.total
                              ?.PAID_AD_AUTOMATIC_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********HP Enquiry*********** */}
                  {revenueLeadList?.total?.HP_ENQUIRY_expLeads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_expLeads
                          ? revenueLeadList?.total?.HP_ENQUIRY_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_expAdmissionCount
                          ? revenueLeadList?.total?.HP_ENQUIRY_expAdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.HP_ENQUIRY_expRevenue
                          ? revenueLeadList?.total?.HP_ENQUIRY_expRevenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.HP_ENQUIRY_expAdmissionCount /
                            revenueLeadList?.total?.HP_ENQUIRY_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********Organic Social Media*********** */}
                  {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_expLeads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_expLeads
                          ? revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.ORGANIC_SOCIAL_MEDIA_expAdmissionCount
                          ? revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_expAdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.ORGANIC_SOCIAL_MEDIA_expRevenue
                          ? revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_expRevenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.ORGANIC_SOCIAL_MEDIA_expAdmissionCount /
                            revenueLeadList?.total
                              ?.ORGANIC_SOCIAL_MEDIA_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}

                  {/* ********App Registration*********** */}
                  {revenueLeadList?.total?.APP_REGISTRATION_expLeads ? (
                    <>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_expLeads
                          ? revenueLeadList?.total?.APP_REGISTRATION_expLeads
                          : 0}{" "}
                      </th>
                      <th>
                        {revenueLeadList?.total
                          ?.APP_REGISTRATION_expAdmissionCount
                          ? revenueLeadList?.total
                              ?.APP_REGISTRATION_expAdmissionCount
                          : 0}
                      </th>
                      <th>
                        {revenueLeadList?.total?.APP_REGISTRATION_expRevenue
                          ? revenueLeadList?.total?.APP_REGISTRATION_expRevenue
                          : 0}
                      </th>
                      <th>
                        {(
                          (revenueLeadList?.total
                            ?.APP_REGISTRATION_expAdmissionCount /
                            revenueLeadList?.total?.APP_REGISTRATION_expLeads) *
                          100
                        ).toFixed(2)}
                        %
                      </th>
                    </>
                  ) : null}
                  {/* ********Reference*********** */}
                  {/* {revenueLeadList?.total?.REFERENCE_Leads >= 0 ? ( */}
                  <>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_expLeads
                        ? revenueLeadList?.total?.REFERENCE_expLeads
                        : 0}{" "}
                    </th>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_expAdmissionCount
                        ? revenueLeadList?.total?.REFERENCE_expAdmissionCount
                        : 0}
                    </th>
                    <th>
                      {revenueLeadList?.total?.REFERENCE_expRevenue
                        ? revenueLeadList?.total?.REFERENCE_expRevenue
                        : 0}
                    </th>
                    <th>
                      {(
                        (revenueLeadList?.total?.REFERENCE_expAdmissionCount /
                          revenueLeadList?.total?.REFERENCE_expLeads) *
                        100
                      ).toFixed(2)}
                      %
                    </th>
                  </>
                  {/* ) : null} */}
                </tr>

                {revenueLeadList?.data?.map((data: any) => {
                  return (
                    <tbody>
                      {data?._id?.status === 11 ? (
                        <tr>
                          {/* **************total********** */}
                          <td>
                            {data?._id?.status === 11 ? data?._id?.date1 : null}{" "}
                          </td>
                          <td>
                            {data?._id?.status === 11
                              ? data?.totalexp_Leads
                                ? data?.totalexp_Leads
                                : 0
                              : null}{" "}
                          </td>
                          <td>
                            {data?._id?.status === 11
                              ? data?.totalexp_AdmissionCount
                                ? data?.totalexp_AdmissionCount
                                : 0
                              : null}{" "}
                          </td>
                          <td>
                            {data?._id?.status === 11
                              ? data?.totalexp_Revenue
                                ? data?.totalexp_Revenue
                                : 0
                              : null}{" "}
                          </td>
                          <td>
                            {data?._id?.status === 11
                              ? data?.totalexp_Percentage
                                ? data?.totalexp_Percentage
                                : 0
                              : null}{" "}
                          </td>
                          {/* **************website********** */}
                          {revenueLeadList?.total
                            ?.WEBSITE_REGISTRATION_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBSITE_REGISTRATION_expLeads
                                    ? data?.WEBSITE_REGISTRATION_expLeads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBSITE_REGISTRATION_expAdmissionCount
                                    ? data?.WEBSITE_REGISTRATION_expAdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBSITE_REGISTRATION_expRevenue
                                    ? data?.WEBSITE_REGISTRATION_expRevenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBSITE_REGISTRATION_expPercentage
                                    ? data?.WEBSITE_REGISTRATION_expPercentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********Webinar*********** */}
                          {revenueLeadList?.total?.WEBINAR_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBINAR_expLeads
                                    ? data?.WEBINAR_expLeads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBINAR_expAdmissionCount
                                    ? data?.WEBINAR_expAdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBINAR_expRevenue
                                    ? data?.WEBINAR_expRevenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.WEBINAR_expPercentage
                                    ? data?.WEBINAR_expPercentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********Field Marketing*********** */}

                          {revenueLeadList?.total?.FIELD_MARKETING_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.FIELD_MARKETING_expLeads
                                    ? data?.FIELD_MARKETING_expLeads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.FIELD_MARKETING_expAdmissionCount
                                    ? data?.FIELD_MARKETING_expAdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.FIELD_MARKETING_expRevenue
                                    ? data?.FIELD_MARKETING_expRevenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.FIELD_MARKETING_expPercentage
                                    ? data?.FIELD_MARKETING_expPercentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********PAID AD MANUAL*********** */}

                          {revenueLeadList?.total?.PAID_AD_MANUAL_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_MANUAL_expLeads
                                    ? data?.PAID_AD_MANUAL_expLeads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_MANUAL_expPercentage
                                    ? data?.PAID_AD_MANUAL_expPercentage
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_MANUAL_expPercentage
                                    ? data?.PAID_AD_MANUAL_expPercentage
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_MANUAL_expPercentage
                                    ? data?.PAID_AD_MANUAL_expPercentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********PAID AD AUTOMATIC*********** */}

                          {revenueLeadList?.total?.PAID_AD_AUTOMATIC_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_AUTOMATIC_expLeads
                                    ? data?.PAID_AD_AUTOMATIC_expLeads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_AUTOMATIC_expPercentage
                                    ? data?.PAID_AD_AUTOMATIC_expPercentage
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_AUTOMATIC_expPercentage
                                    ? data?.PAID_AD_AUTOMATIC_expPercentage
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.PAID_AD_AUTOMATIC_expPercentage
                                    ? data?.PAID_AD_AUTOMATIC_expPercentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}
                          {/* ********HP Enquiry*********** */}

                          {revenueLeadList?.total?.HP_ENQUIRY_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.HP_ENQUIRY_expLeads
                                    ? data?.HP_ENQUIRY_expLeads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.HP_ENQUIRY_expAdmissionCount
                                    ? data?.HP_ENQUIRY_expAdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.HP_ENQUIRY_expRevenue
                                    ? data?.HP_ENQUIRY_expRevenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.HP_ENQUIRY_expPercentage
                                    ? data?.HP_ENQUIRY_expPercentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}
                          {/* ********Organic Social Media*********** */}

                          {revenueLeadList?.total
                            ?.ORGANIC_SOCIAL_MEDIA_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.ORGANIC_SOCIAL_MEDIA_expLeads
                                    ? data?.ORGANIC_SOCIAL_MEDIA_expLeads
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.ORGANIC_SOCIAL_MEDIA_expAdmissionCount
                                    ? data?.ORGANIC_SOCIAL_MEDIA_expAdmissionCount
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.ORGANIC_SOCIAL_MEDIA_expRevenue
                                    ? data?.ORGANIC_SOCIAL_MEDIA_expRevenue
                                    : 0
                                  : null}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.ORGANIC_SOCIAL_MEDIA_expPercentage
                                    ? data?.ORGANIC_SOCIAL_MEDIA_expPercentage
                                    : 0
                                  : null}
                                %
                              </td>
                            </>
                          ) : null}

                          {/* ********App Registration*********** */}

                          {revenueLeadList?.total?.APP_REGISTRATION_expLeads &&
                          data?._id?.status === 11 ? (
                            <>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.APP_REGISTRATION_expLeads
                                    ? data?.APP_REGISTRATION_expLeads
                                    : 0
                                  : null}{" "}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.APP_REGISTRATION_expAdmissionCount
                                    ? data?.APP_REGISTRATION_expAdmissionCount
                                    : 0
                                  : null}{" "}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.APP_REGISTRATION_expRevenue
                                    ? data?.APP_REGISTRATION_expRevenue
                                    : 0
                                  : null}{" "}
                              </td>
                              <td>
                                {data?._id?.status === 11
                                  ? data?.APP_REGISTRATION_expPercentage
                                    ? data?.APP_REGISTRATION_expPercentage
                                    : 0
                                  : null}{" "}
                                %
                              </td>
                            </>
                          ) : null}
                          {/* ********Reference*********** */}
                          {/* {data?.REFERENCE_Leads >= 0 ? (
                      <> */}
                          <td>
                            {data?._id?.status === 11
                              ? data?.REFERENCE_expLeads
                                ? data?.REFERENCE_expLeads
                                : 0
                              : null}{" "}
                          </td>
                          <td>
                            {data?._id?.status === 11
                              ? data?.REFERENCE_expAdmissionCount
                                ? data?.REFERENCE_expAdmissionCount
                                : 0
                              : null}{" "}
                          </td>
                          <td>
                            {data?._id?.status === 11
                              ? data?.REFERENCE_expRevenue
                                ? data?.REFERENCE_expRevenue
                                : 0
                              : null}{" "}
                          </td>
                          <td>
                            {data?._id?.status === 11
                              ? data?.REFERENCE_expPercentage
                                ? data?.REFERENCE_expPercentage
                                : 0
                              : null}{" "}
                            %
                          </td>
                          {/* </>
                    ) : null} */}
                        </tr>
                      ) : null}
                    </tbody>
                  );
                })}
              </>
            ) : (
              <tr> No Record Found</tr>
            )}
          </table>
        </>
      </div>
    );
  };

  const renderExpiryGraphMode = () => {
    return (
      <>
        {GraphdataExpiry?.length > 0 ? (
          <div className="col-md-6 p-3 ms-5">
            <CallRecordedGraph label={xLabelExpiry} value={GraphdataExpiry} />
          </div>
        ) : null}
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"Lead Allotted Date Analysis"} />
      <div className="col-md-12 formDiv formWidth">
        <div className="row m-2">
          {renderSearchMode()}
          <h3>New Leads</h3>
          {renderListMode()}
          {renderGraphMode()}
          <h3>Expire Leads</h3>
          {renderExpiryListMode()}
          {renderExpiryGraphMode()}
        </div>
      </div>
    </div>
  );
}
