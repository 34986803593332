import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getCountryList,
  getExamList,
  getExamStreamList,
  getMainCategoryList,
  getStatesList,
  getSubExam,
  referNewLead,
} from "../../Redux/Actions/counsellorPanelAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "../../Component/button";
import {
  getStatusList,
  getSubStatusList,
} from "../../Redux/Actions/leadManagementAction";
import DateTime from "../../Component/dateTime";
import SubmitButton from "../../Component/submitButton";
import {
  containsNumber,
  length_Ten,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import { getLeadByMobileNumber } from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
import {
  qualificationList,
  qualificationYearList,
  referSourceType,
} from "../../DATA/dataConstant";
import moment from "moment";
import { getVrifyStudentNumber } from "../../Redux/Actions/bdeAdminPanelAction";

const initialVal: {
  baseCourseId: string;
  examTypeId: string;
  subExamTypeId: string;
  maincategoryId: string;
  subcategoryId: string;
}[] = [
  {
    baseCourseId: "",
    examTypeId: "",
    subExamTypeId: "",
    maincategoryId: "",
    subcategoryId: "",
  },
];

const initialQualifiedExam: {
  examTypeId: string;
}[] = [
  {
    examTypeId: "",
  },
];

export default function LeadDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [referredStudentContactNo, setReferredStudentContactNo] =
    useState<any>("");
  const [referredStudentFirstName, setreferredStudentFirstName] =
    useState<any>("");
  const [referredStudentLastName, setreferredStudentLastName] =
    useState<any>("");
  const [educationYear, setEducationYear] = useState<any>("");
  const [qualification, setQualification] = useState<any>("");
  const [college, setCollege] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [statusDetails, setStatusDetails] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [callDate, setCallDate] = useState<any>("");
  const [courseFee, setCourseFee] = useState<any>("");
  const [feeOffered, setFeeOffered] = useState<any>("");
  const [paidFees, setPaidFees] = useState<any>("");
  const [installamentDate, setInstallamentDate] = useState<any>("");
  const [source, setSource] = useState<any>("");
  const [sourceTypeList, setSourceTypeList] = useState(referSourceType);

  const [openPopup, setOpenPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  // const [qualificationList, setQualificationList] = useState([]);
  const [educationYearList, setEducationYearList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [studyMaterialList, setStudyMaterialList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusDetailsList, setStatusDetailsList] = useState([]);
  const [courseExamTypeInfoArr, setCourseExamTypeInfoArr] =
    useState<any>(initialVal);
  const [qualifiedExamArr, setQualifiedExamArr] =
    useState<any>(initialQualifiedExam);

  const [resetStatus, setResetStatus] = useState(false);
  const [resetStatusDetails, setResetStatusDetails] = useState(false);

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  useEffect(() => {
    if (loggedInUserRole !== 5) {
      let tempSource = sourceTypeList?.filter((item: any) => item?._id === 2);
      setSourceTypeList(tempSource);
    }
    dispatch(
      getStatesList((res: any) => {
        if (res?.data?.length > 0) {
          setStateList(res?.data);
        } else {
          setStateList([]);
        }
      })
    );
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    dispatch(
      getStatusList(
        "",
        (res: any) => {
          if (loggedInUserRole !== 8 && loggedInUserRole !== 4) {
            let tempStatus = res?.filter(
              (item: any) =>
                item?._id === 4 ||
                item?._id === 6 ||
                item?._id === 7 ||
                item?._id === 8 ||
                item?._id === 9 ||
                item?._id === 10 ||
                item?._id === 11 ||
                item?._id === 12
            );
            setStatusList(tempStatus);
          } else if (loggedInUserRole === 8) {
            let tempStatus = res?.filter(
              (item: any) =>
                item?._id === 4 ||
                item?._id === 6 ||
                item?._id === 7 ||
                item?._id === 8 ||
                item?._id === 9 ||
                item?._id === 10 ||
                item?._id === 11 ||
                item?._id === 12 ||
                item?._id === 15 ||
                item?._id === 16 ||
                item?._id === 17
            );
            setStatusList(tempStatus);
          } else if (loggedInUserRole === 4) {
            let tempStatus = res?.filter(
              (item: any) =>
                item?._id === 4 ||
                item?._id === 5 ||
                item?._id === 6 ||
                item?._id === 7 ||
                item?._id === 8 ||
                item?._id === 9 ||
                item?._id === 10 ||
                item?._id === 11 ||
                item?._id === 12 ||
                item?._id === 15 ||
                item?._id === 16 ||
                item?._id === 17 ||
                item?._id === 18 ||
                item?._id === 19 ||
                item?._id === 20
            );
            setStatusList(tempStatus);
          }
        },
        () => {}
      )
    );
  }, []);

  const onChangeContact1 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact1(val);
        if (val?.length === 10) {
          const postData = {
            mobileNo: val,
          };
          dispatch(
            getLeadByMobileNumber(
              postData,
              (res: any) => {
                if (res?.length > 0) {
                  dispatch(showErrorSnackbar("Lead is Already Present") as any);
                }
              },
              () => {}
            )
          );
        }
      }
    } else {
      setContact1(val);
    }
  };

  const onChangeContact2 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val);
        if (val?.length === 10) {
          const postData = {
            mobileNo: val,
          };
          dispatch(
            getLeadByMobileNumber(
              postData,
              (res: any) => {
                if (res?.length > 0) {
                  dispatch(showErrorSnackbar("Lead is Already Present") as any);
                }
              },
              () => {}
            )
          );
        }
      }
    } else {
      setContact2(val);
    }
  };

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCountryList(
        val._id,
        (res: any) => {
          setCountryList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeReferredContactNo = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setReferredStudentContactNo(val);
      }
    } else {
      setReferredStudentContactNo(val);
    }
  };

  const onClickverify = (val: any) => {
    dispatch(
      getVrifyStudentNumber(
        referredStudentContactNo,
        (res: any) => {
          // dispatch(showSuccessSnackbar(res?.msg) as any);
          if (res?.data) {
            setreferredStudentFirstName(res?.data?.firstName);
            setreferredStudentLastName(res?.data?.lastName);
          } else {
            setReferredStudentContactNo("");
            setreferredStudentFirstName("");
            setreferredStudentLastName("");
          }
        },
        () => {}
      )
    );
  };

  const onPressAdd = () => {
    setCourseExamTypeInfoArr([...courseExamTypeInfoArr, initialVal[0]]);
  };

  const handleArrClose = (index: number) => {
    const newArrr = [...courseExamTypeInfoArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setCourseExamTypeInfoArr(newArrr);
  };

  const onPressAddQualifiedExam = () => {
    setQualifiedExamArr([...qualifiedExamArr, initialQualifiedExam[0]]);
  };

  const handleArrCloseQualifiedExam = (index: number) => {
    const newArrr = [...qualifiedExamArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setQualifiedExamArr(newArrr);
  };

  const onChange = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(courseExamTypeInfoArr));
    if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      if (newArrr[index].subExamTypeId) {
        dispatch(
          getSubExam(
            {
              examTypeId: value._id,
            },
            (res: any) => {
              if (res.length > 0) {
                setSubExamList(res);
              } else {
                dispatch(
                  getExamStreamList(`${value._id}`, (res: any) => {
                    setExamStreamList(res);
                  })
                );
              }
            }
          )
        );
      } else {
        dispatch(
          getExamStreamList(`${value._id}`, (res: any) => {
            setExamStreamList(res);
          })
        );
      }
    } else if (key === "subExam") {
      newArrr[index].subExamTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      dispatch(
        getExamStreamList(
          `${newArrr[index].examTypeId._id}/${value._id}`,
          (res: any) => {
            setExamStreamList(res);
          }
        )
      );
    } else if (key === "examStream") {
      newArrr[index].baseCourseId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      let data = {
        examTypeId: newArrr[index].examTypeId,
        subExamTypeId: newArrr[index].subExamTypeId,
        baseCourseId: value?._id,
      };
      if (newArrr[index].subExamTypeId === "") {
        delete data["subExamTypeId"];
      }
      dispatch(
        getMainCategoryList(
          data,
          (res: any) => {
            setCourseTypeList(res);
          },
          () => {}
        )
      );
    } else if (key === "courseType") {
      newArrr[index].maincategoryId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
      const subCategoryList = value?.subCategories.filter((item: any) => {
        return item.type === 2;
      });
      setStudyMaterialList(subCategoryList);
    } else if (key === "studyMaterial") {
      newArrr[index].subcategoryId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    }
    if (key === "exam") {
      newArrr[index].examTypeId = value?._id;
      setCourseExamTypeInfoArr(newArrr);
    }
  };

  const onChangeQualifiedExam = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(qualifiedExamArr));
    if (key === "qualifiedForExam") {
      newArrr[index].examTypeId = value?._id;
      setQualifiedExamArr(newArrr);
    }
  };

  const onChangeQualification = (val: any) => {
    setEducationYear("");
    setQualification(val);
    let tempQualification: any = qualificationYearList?.filter?.(
      (item: any) => item?.qualificationId === val?._id
    );
    setEducationYearList(tempQualification);
  };

  const onChangeStatus = (val: any) => {
    setStatusDetails("");
    // setResetStatusDetails(true);
    setStatus(val);
    setResetStatus(false);
    setStatusDetailsList([]);
    dispatch(
      getSubStatusList(
        val?._id,
        (res: any) => {
          setStatusDetailsList(res);
        },
        () => {}
      )
    );
    if (val?._id === 12) {
      let newTime = moment().add(4, "hours").format("YYYY-MM-DD HH:mm:ss");
      if (moment(newTime).format("HH:mm:ss") > "20:00:00") {
        setCallDate(
          moment(
            moment().add(1, "days").format("YYYY-MM-DD") + " " + "10:00:00"
          ).format("YYYY-MM-DD HH:mm:ss")
        );
      } else if (moment(newTime).format("HH:mm:ss") < "20:00:00") {
        setCallDate(newTime);
      }
    }
  };
  const onChangeStatusDetails = (val: any) => {
    setStatusDetails(val);
    setResetStatusDetails(false);
    if (val?._id === 39) {
      setCallDate("");
    }
  };
  const changeRemark = (val: any) => {
    setRemark(val);
  };
  const handleClose = (value: any) => {
    setOpenEmailPopup(false);
    setOpenPopup(false);
  };
  const handleOpenMessagePopup = () => {
    setOpenPopup(true);
  };
  const handleOpenEmailPopup = () => {
    setOpenEmailPopup(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      (status?._id === 4 ||
        status?._id === 7 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11) &&
      (state === "" || state === undefined || state === null)
    ) {
      dispatch(showErrorSnackbar("Please Select State") as any);
    } else if (
      loggedInUserRole !== 5 &&
      (referredStudentContactNo === "" ||
        referredStudentContactNo === undefined ||
        referredStudentContactNo === null ||
        referredStudentFirstName === "" ||
        referredStudentFirstName === undefined ||
        referredStudentFirstName === null)
    ) {
      dispatch(
        showErrorSnackbar("Please Fill Referred By Student Mobile No") as any
      );
    } else if (
      (status?._id === 4 ||
        status?._id === 7 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11) &&
      (qualification === "" ||
        qualification === undefined ||
        qualification === null)
    ) {
      dispatch(showErrorSnackbar("Please Select Qualification") as any);
    } else {
      courseExamTypeInfoArr?.map((item: any) => {
        if (item["subExamTypeId"] === "") {
          delete item?.subExamTypeId;
        }

        if (item["maincategoryId"] === "") {
          delete item?.maincategoryId;
        }
        if (item["subcategoryId"] === "") {
          delete item?.subcategoryId;
        }
        return item;
      });
      let postData = {
        firstName: firstName?.toUpperCase(),
        lastName: lastName?.toUpperCase(),
        mobileNo: contact1,
        altMobileNo: contact2,
        email: email,
        stateId: state?._id,
        stateName: state?.name,
        cityId: city?._id,
        cityName: city?.name,
        educationYear: educationYear?._id,
        qualification: qualification?._id,
        leadStatusId: status?._id,
        leadSubstatusId: statusDetails?._id,
        callBackDate: callDate,
        courses: courseExamTypeInfoArr,
        comment: remark,
        reference: college?.toUpperCase(),
        source: source?.name,
        type: source?._id,
        qualifiedForExam: qualifiedExamArr,
        referredStudentContactNo,
        referredStudentFirstName,
        referredStudentLastName,
      };
      if (
        status?._id === 8 ||
        status?._id === 9 ||
        status?._id === 10 ||
        status?._id === 11 ||
        (status?._id === 15 && statusDetails?._id === 41) ||
        (status?._id === 16 && statusDetails?._id === 43)
      ) {
        delete postData?.callBackDate;
      }
      if (
        postData?.lastName === "" ||
        postData?.lastName === undefined ||
        postData?.lastName === null
      ) {
        delete postData?.lastName;
      }
      if (
        postData?.altMobileNo === "" ||
        postData?.altMobileNo === undefined ||
        postData?.altMobileNo === null
      ) {
        delete postData?.altMobileNo;
      }
      if (
        postData?.email === "" ||
        postData?.email === undefined ||
        postData?.email === null
      ) {
        delete postData?.email;
      }
      if (
        postData?.stateId === "" ||
        postData?.stateId === undefined ||
        postData?.stateId === null
      ) {
        delete postData?.stateId;
      }
      if (
        postData?.stateName === "" ||
        postData?.stateName === undefined ||
        postData?.stateName === null
      ) {
        delete postData?.stateName;
      }
      if (
        postData?.cityId === "" ||
        postData?.cityId === undefined ||
        postData?.cityId === null
      ) {
        delete postData?.cityId;
      }
      if (
        postData?.cityName === "" ||
        postData?.cityName === undefined ||
        postData?.cityName === null
      ) {
        delete postData?.cityName;
      }
      if (
        postData?.referredStudentContactNo === "" ||
        postData?.referredStudentContactNo === undefined ||
        postData?.referredStudentContactNo === null ||
        postData?.referredStudentFirstName === "" ||
        postData?.referredStudentFirstName === undefined ||
        postData?.referredStudentFirstName === null ||
        postData?.referredStudentLastName === "" ||
        postData?.referredStudentLastName === undefined ||
        postData?.referredStudentLastName === null
      ) {
        delete postData?.referredStudentContactNo;
        delete postData?.referredStudentFirstName;
        delete postData?.referredStudentLastName;
      }

      if (
        postData?.educationYear === "" ||
        postData?.educationYear === undefined ||
        postData?.educationYear === null
      ) {
        delete postData?.educationYear;
      }
      if (
        postData?.qualification === "" ||
        postData?.qualification === undefined ||
        postData?.qualification === null
      ) {
        delete postData?.qualification;
      }
      if (
        postData?.reference === "" ||
        postData?.reference === undefined ||
        postData?.reference === null
      ) {
        delete postData?.reference;
      }
      if (
        postData["qualifiedForExam"][0]["examTypeId"] === "" ||
        postData["qualifiedForExam"][0]["examTypeId"] === undefined ||
        postData["qualifiedForExam"][0]["examTypeId"] === null
      ) {
        delete postData["qualifiedForExam"];
      }
      if (
        postData?.comment === "" ||
        postData?.comment === undefined ||
        postData?.comment === null
      ) {
        delete postData?.comment;
      }
      if (
        postData?.callBackDate === "" ||
        postData?.callBackDate === undefined ||
        postData?.callBackDate === null
      ) {
        delete postData?.callBackDate;
      }

      dispatch(
        referNewLead(postData, (res: any) => {
          setFirstName("");
          setLastName("");
          setContact1("");
          setContact2("");
          setEmail("");
          setState("");
          setCity("");
          setEducationYear("");
          setQualification("");
          setCollege("");
          setQualifiedExamArr(initialQualifiedExam);
          setCourseExamTypeInfoArr(initialVal);
          setStatus("");
          setStatusDetails("");
          setRemark("");
          setCallDate("");
          navigate("../counsellorDashbord");
        })
      );
    }
  };

  return (
    <div className="contentBox">
      <div className="col-md-12">
        <div className="row m-0">
          <div className="col-md-12 leaddetails font14">
            <div className="boxshadow mx-4 my-1">
              <div className="p-1 d-flex justify-content-center  ">
                <form
                  onSubmit={handleSubmit}
                  className="detailsdiv col-md-12 row d-flex justify-content-center px-2 "
                >
                  <div className="row d-flex justify-content-between mt-2">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Source" />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Source"
                        setInputText={(value: any) => setSource(value)}
                        value={source}
                        options={sourceTypeList}
                        defaultValue={
                          sourceTypeList?.length > 0 ? sourceTypeList : ""
                        }
                        Isrequired={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 p-2">
                    <div className="tabheader p-2">Personnel & Contact </div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="First Name" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter First Name"
                          value={firstName}
                          onChangeInput={(value: any) =>
                            setFirstName(value?.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Last Name" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChangeInput={(value: any) =>
                            setLastName(value?.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Contact 1" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Contact 1"
                          value={contact1}
                          onChangeInput={(value: any) =>
                            onChangeContact1(value)
                          }
                          minlength={length_Ten}
                          maxlength={length_Ten}
                          isRequired={true}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Contact 2" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Contact 2"
                          value={contact2}
                          onChangeInput={(value: any) =>
                            onChangeContact2(value)
                          }
                          minlength={length_Ten}
                          maxlength={length_Ten}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Email" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Email"
                          value={email}
                          onChangeInput={(value: any) => setEmail(value)}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="State" />
                      </div>
                      <div className="col-md-7 ps-3">
                        <CustomDropdown
                          lableName="Select State"
                          setInputText={(value: any) => onChangeState(value)}
                          value={state}
                          options={stateList}
                          defaultValue={stateList?.length > 0 ? state : ""}
                          Isrequired={true}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="City" />
                      </div>
                      <div className="col-md-7  ps-3">
                        <CustomDropdown
                          lableName="Select City"
                          setInputText={(value: any) => setCity(value)}
                          value={city}
                          options={countryList}
                          defaultValue={countryList?.length > 0 ? city : ""}
                          // Isrequired={true}
                        />
                      </div>
                    </div>
                    {loggedInUserRole !== 5 ? (
                      <>
                        <div className="row d-flex justify-content-between  ">
                          <div className="col-md-4 mt-2">
                            <LabelField lableName="Referred By Student" />
                          </div>
                          <div className="d-flex justify-content-space-between col-7 me-0">
                            <div className="col-9 me-4">
                              <InputField
                                placeholder="Enter Contact number"
                                value={referredStudentContactNo}
                                onChangeInput={(value: any) =>
                                  onChangeReferredContactNo(value)
                                }
                                minlength={length_Ten}
                                maxlength={length_Ten}
                                isRequired={true}
                              />
                            </div>
                            <button
                              type="button"
                              className={
                                referredStudentContactNo?.length === 10
                                  ? "btn btn-success searchButton font12 mb-2 "
                                  : "btn btn-outline-secondary searchButton font12 mb-2 "
                              }
                              onClick={onClickverify}
                            >
                              Verify
                            </button>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between pt-2 ">
                          <div className="col-md-4 mt-2">
                            <LabelField lableName="Referred By Student" />
                          </div>
                          <div className="d-flex justify-content-space-between col-7 me-0">
                            <div className="col-md-6">
                              <InputField
                                placeholder="Enter First Name"
                                value={referredStudentFirstName}
                                onChangeInput={(value: any) =>
                                  setreferredStudentFirstName(
                                    value?.toUpperCase()
                                  )
                                }
                                disabled={true}
                                isRequired={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <InputField
                                placeholder="Enter Last Name"
                                value={referredStudentLastName}
                                onChangeInput={(value: any) =>
                                  setreferredStudentLastName(
                                    value?.toUpperCase()
                                  )
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Courses</div>
                    {courseExamTypeInfoArr?.map((item: any, index: number) => {
                      const {
                        baseCourseId,
                        examTypeId,
                        subExamTypeId,
                        maincategoryId,
                        subcategoryId,
                      } = item;
                      return (
                        <div className="position-relative" key={index}>
                          <span className="d-flex justify-content-end removeButton">
                            {courseExamTypeInfoArr.length === 1 ? null : (
                              <HighlightOffIcon
                                className="cursor"
                                color="primary"
                                fontSize="small"
                                onClick={() => handleArrClose(index)}
                              />
                            )}
                          </span>
                          <div className="position-relative">
                            <div className="row d-flex justify-content-between  pt-3">
                              <div className="col-md-4 mt-2">
                                <LabelField lableName="Exam Name" />
                              </div>
                              <div className="col-md-7">
                                <CustomDropdown
                                  lableName="Select Exam"
                                  setInputText={(value: any) =>
                                    onChange(index, value, "exam")
                                  }
                                  value={examTypeId}
                                  options={examList}
                                  defaultValue={
                                    examList?.length > 0 ? examTypeId : ""
                                  }
                                  Isrequired={true}
                                />
                              </div>
                            </div>
                            {subExamList?.length > 0 ? (
                              <div className="row d-flex justify-content-between mt-1 ">
                                <div className="col-md-4 mt-2">
                                  <LabelField lableName="Sub Exam Name" />
                                </div>
                                <div className="col-md-7 ">
                                  <CustomDropdown
                                    lableName="Select Sub Exam Name"
                                    setInputText={(value: any) =>
                                      onChange(index, value, "subExam")
                                    }
                                    value={subExamTypeId}
                                    options={subExamList}
                                    defaultValue={
                                      subExamList?.length > 0
                                        ? subExamTypeId
                                        : ""
                                    }
                                    Isrequired={true}
                                  />
                                </div>
                              </div>
                            ) : null}

                            <div className="row d-flex justify-content-between mt-1">
                              <div className="col-md-4 mt-2">
                                <LabelField lableName="Exam Stream" />
                              </div>
                              <div className="col-md-7">
                                <CustomDropdown
                                  lableName="Select Exam Stream"
                                  setInputText={(value: any) =>
                                    onChange(index, value, "examStream")
                                  }
                                  value={baseCourseId}
                                  options={examStreamList}
                                  defaultValue={
                                    examStreamList?.length > 0
                                      ? baseCourseId
                                      : ""
                                  }
                                  Isrequired={true}
                                />
                              </div>
                            </div>

                            <div className="row d-flex justify-content-between mt-1">
                              <div className="col-md-4 mt-2">
                                <LabelField lableName="Course Type" />
                              </div>
                              <div className="col-md-7">
                                <CustomDropdown
                                  lableName="Select Course Type"
                                  setInputText={(value: any) =>
                                    onChange(index, value, "courseType")
                                  }
                                  value={maincategoryId}
                                  options={courseTypeList}
                                  defaultValue={
                                    courseTypeList?.length > 0
                                      ? maincategoryId
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="row d-flex justify-content-between mt-1">
                              <div className="col-md-4 mt-2">
                                <LabelField lableName="Study Material" />
                              </div>
                              <div className="col-md-7">
                                <CustomDropdown
                                  lableName="Select Study Material"
                                  setInputText={(value: any) =>
                                    onChange(index, value, "studyMaterial")
                                  }
                                  value={subcategoryId}
                                  options={studyMaterialList}
                                  defaultValue={
                                    studyMaterialList?.length > 0
                                      ? subcategoryId
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="d-flex justify-content-end">
                      <Button name={"Add Course"} handleClick={onPressAdd} />
                    </div>
                  </div>

                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Education & Work</div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Qualification" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Qualification"
                          setInputText={(value: any) =>
                            onChangeQualification(value)
                          }
                          value={qualification}
                          options={qualificationList}
                          Isrequired={true}
                          defaultValue={
                            qualificationList?.length > 0 ? qualification : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Education Year" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Education Year"
                          setInputText={(value: any) => setEducationYear(value)}
                          value={educationYear}
                          options={educationYearList}
                          defaultValue={
                            educationYearList?.length > 0 ? educationYear : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="College" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter College Name"
                          value={college}
                          onChangeInput={(value: any) =>
                            setCollege(value?.toUpperCase())
                          }
                          // disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      {qualifiedExamArr?.map((item: any, index: number) => {
                        const { examTypeId } = item;
                        return (
                          <div className="position-relative" key={index}>
                            <span className="d-flex justify-content-end removeButton">
                              {qualifiedExamArr.length === 1 ? null : (
                                <HighlightOffIcon
                                  className="cursor"
                                  color="primary"
                                  fontSize="small"
                                  onClick={() =>
                                    handleArrCloseQualifiedExam(index)
                                  }
                                />
                              )}
                            </span>
                            <div className="position-relative">
                              <div className="row d-flex justify-content-between  pt-3">
                                <div className="col-md-4 mt-2">
                                  <LabelField lableName="Already Qualified For Exam" />
                                </div>
                                <div className="col-md-7">
                                  <CustomDropdown
                                    lableName="Select Exam"
                                    setInputText={(value: any) =>
                                      onChangeQualifiedExam(
                                        index,
                                        value,
                                        "qualifiedForExam"
                                      )
                                    }
                                    value={examTypeId}
                                    options={examList}
                                    defaultValue={
                                      examList?.length > 0 ? examTypeId : ""
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-end">
                        <Button
                          name={"Add Exam"}
                          handleClick={onPressAddQualifiedExam}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Action & Disposition</div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Status" />
                      </div>

                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Status"
                          setInputText={(value: any) => onChangeStatus(value)}
                          value={status}
                          options={statusList}
                          Isrequired={true}
                          defaultValue={statusList?.length > 0 ? status : ""}
                          reset={resetStatus}
                          disabled={status?._id === 5 ? true : false}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Status Details" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Status Details"
                          setInputText={(value: any) =>
                            onChangeStatusDetails(value)
                          }
                          value={statusDetails}
                          options={statusDetailsList}
                          defaultValue={
                            statusDetailsList?.length > 0 ? statusDetails : ""
                          }
                          reset={resetStatusDetails}
                          Isrequired={true}
                          disabled={status?._id === 5 ? true : false}
                        />
                      </div>
                    </div>

                    {status?._id === 4 ||
                    status?._id === 6 ||
                    status?._id === 7 ||
                    status?._id === 8 ||
                    status?._id === 9 ||
                    status?._id === 11 ||
                    status?._id === 12 ||
                    status?._id === 15 ||
                    status?._id === 16 ||
                    status?._id === 17 ||
                    status?._id === 18 ||
                    status?._id === 19 ||
                    status?._id === 20 ? (
                      <div className="row d-flex justify-content-between ">
                        <textarea
                          className="form-control"
                          placeholder={"Remark"}
                          value={remark}
                          onChange={(e) => changeRemark(e.target.value)}
                        ></textarea>
                      </div>
                    ) : null}

                    {status?._id === 4 ||
                    status?._id === 6 ||
                    status?._id === 7 ||
                    status?._id === 11 ||
                    status?._id === 12 ||
                    (status?._id === 15 && statusDetails?._id === 42) ||
                    (status?._id === 16 && statusDetails?._id === 44) ||
                    (status?._id === 17 && statusDetails?._id === 45) ? (
                      <div className="row d-flex justify-content-between  ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Call Back Date" />
                        </div>
                        <div className="col-md-7">
                          <DateTime
                            value={callDate}
                            setInputText={(value: any) => setCallDate(value)}
                            Isrequired={true}
                            defaultValue={callDate}
                            disabled={status?._id === 12 ? true : false}
                          />
                        </div>
                      </div>
                    ) : null}

                    {status?._id === 5 ? (
                      <>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4 mt-2">
                            <LabelField lableName="Course Fee" />
                          </div>

                          <div className="col-md-7">
                            <InputField
                              placeholder="Course Fee"
                              value={courseFee}
                              onChangeInput={(value: any) =>
                                setCourseFee(value)
                              }
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between  ">
                          <div className="col-md-4 mt-2">
                            <LabelField lableName="Course Fee Offered" />
                          </div>

                          <div className="col-md-7">
                            <InputField
                              placeholder="Course Fee Offered"
                              value={feeOffered}
                              onChangeInput={(value: any) =>
                                setFeeOffered(value)
                              }
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between">
                          <div className="col-md-4 mt-2">
                            <LabelField lableName="Fees Paid" />
                          </div>

                          <div className="col-md-7">
                            <InputField
                              placeholder="Course Fee Offered"
                              value={paidFees}
                              onChangeInput={(value: any) => setPaidFees(value)}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {statusDetails?._id === 25 ? (
                          <div className="row d-flex justify-content-between  ">
                            <div className="col-md-4 mt-2">
                              <LabelField lableName="Installment Date" />
                            </div>
                            <div className="col-md-7">
                              <div className="col-md-8">
                                <DatePicker
                                  value={installamentDate}
                                  setInputText={(value: string) =>
                                    setInstallamentDate(value)
                                  }
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {status?._id === 4 ||
                    status?._id === 6 ||
                    status?._id === 7 ||
                    status?._id === 8 ||
                    status?._id === 9 ||
                    status?._id === 10 ||
                    status?._id === 11 ||
                    status?._id === 12 ||
                    status?._id === 15 ||
                    status?._id === 16 ||
                    status?._id === 17 ||
                    status?._id === 18 ||
                    status?._id === 19 ||
                    status?._id === 20 ? (
                      <div className=" d-flex justify-content-end mt-2 ">
                        <SubmitButton name={"Save"} />
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}