import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Panel/Auth/login";
import ForgotPassword from "./Panel/Auth/forgotPassword";
import ErrorSnackbar from "./Component/errorSnackbar";
import Backdrop from "@mui/material/Backdrop";
import SuccessSnackbar from "./Component/successSnackbar";
import { useAppSelector } from "./hooks";
import { AuthReducerModal } from "./Redux/Modals";
import CRMAdminPanelDashboard from "./Panel/CRMAdminPanel/crmAdminDashboard";
import CRMDataOperatorDashboard from "./Panel/CRMDataOperatorPanel/crmDataOperatorDashboard";
import CRMDataEntryDashboard from "./Panel/CRMDataEntryPanel/crmDataEntryDashboard";
import CrmCounsellorDashboard from "./Panel/CRMCounsellorPanel/crmCounsellorDashboard";
import CrmTeamLeadDashboard from "./Panel/CRMTeamLeadPanel/crmTeamLeadDashboard";
import CrmManagerDashboard from "./Panel/CRMManagerPanel/crmManagerDashboard";

import LeadDetail from "./Panel/CRMCounsellorPanel/leadDetail";
import BdeAdminPanelDashboard from "./Panel/BDEAdmin/bdeAdminPanelDashboard";
import BDEPanelDashbord from "./Panel/BDEdashboardPanel/bdepanelDashbord";
import Departmentdetails from "./Panel/BDEdashboardPanel/departmentdetails";
import WebinarStatusDetails from "./Panel/BDEdashboardPanel/webinarStatusDetails";
import CompletedWebinarStatusDetails from "./Panel/BDEdashboardPanel/completedWebinarStatusDetails";
import CancelledWebinarStatusDetails from "./Panel/BDEdashboardPanel/cancelledWebinarStatusDetails";
import PendingWebinarStatusDetails from "./Panel/BDEdashboardPanel/pendingWebinarStatusDetails ";
import BDManagerDashboard from "./Panel/BDTeamLead/bdTeamLeadDashboard";
import BDTeamLead from "./Panel/BDTeamLead/bdTeamLead";
import Details from "./Panel/BDTeamLead/details";
import WebinarDetails from "./Panel/BDTeamLead/webinarDetails";
import TLCompletewebinarDetails from "./Panel/BDTeamLead/tlCompletewebinarDetails";
import TLCancelledwebinarDetails from "./Panel/BDTeamLead/tlCancelledWebinarDetails";
import TLPendingwebinarDetails from "./Panel/BDTeamLead/tlPendingWebinarDetails ";
import SuperAdminPanelDashboard from "./Panel/CRMSuperManagerPanel/superAdminPaneldashboard";
import CompleteWebinarDetailsList from "./Panel/BDTeamLead/completeWebiarDeatilsList";
import ImageDisplay from "./Panel/BDEdashboardPanel/image";

function App() {
  const { loader } = useAppSelector((state: { Auth: AuthReducerModal }) => ({
    loader: state?.Auth?.loader,
  }));

  return (
    <div className="App">
      {loader ? (
        <Backdrop sx={{ color: "#fff", zIndex: "10001" }} open>
          <span className="loader"></span>
        </Backdrop>
      ) : (
        ""
      )}
      <SuccessSnackbar />
      <ErrorSnackbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="CRMAdminPanel/*" element={<CRMAdminPanelDashboard />} />
        <Route
          path="CRMDataOperatorPanel/*"
          element={<CRMDataOperatorDashboard />}
        />
        <Route path="CRMDataEntryPanel/*" element={<CRMDataEntryDashboard />} />
        <Route
          path="CrmCounsellorPanel/*"
          element={<CrmCounsellorDashboard />}
        />
        <Route path="CrmTeamLeadPanel/*" element={<CrmTeamLeadDashboard />} />
        <Route path="CrmManagerPanel/*" element={<CrmManagerDashboard />} />
        <Route path="SuperManager/*" element={<SuperAdminPanelDashboard />} />

        <Route path="BDEAdminPanel/*" element={<BdeAdminPanelDashboard />} />
        <Route path="BDEPanelDashbord/*" element={<BDEPanelDashbord />} />
        <Route path="BDTeamLead/*" element={<BDTeamLead />} />

        <Route path="leadDetail" element={<LeadDetail />} />
        <Route path="departmentdetails" element={<Departmentdetails />} />
        <Route path="webinarStatusDetails" element={<WebinarStatusDetails />} />
        <Route
          path="completedWebinarStatusDetails"
          element={<CompletedWebinarStatusDetails />}
        />
        <Route
          path="cancelledWebinarStatusDetails"
          element={<CancelledWebinarStatusDetails />}
        />
        <Route
          path="pendingWebinarStatusDetails"
          element={<PendingWebinarStatusDetails />}
        />
        <Route path="details" element={<Details />} />
        <Route path="webinarDetails" element={<WebinarDetails />} />
        <Route
          path="tlCompletewebinarDetails"
          element={<TLCompletewebinarDetails />}
        />
        <Route
          path="tlCancelledwebinarDetails"
          element={<TLCancelledwebinarDetails />}
        />
        <Route
          path="tlPendingwebinarDetails"
          element={<TLPendingwebinarDetails />}
        />
        <Route
          path="completeWebinarDetailsList"
          element={<CompleteWebinarDetailsList />}
        />
        <Route path="image" element={<ImageDisplay />} />
      </Routes>
    </div>
  );
}
export default App;
