import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getManagerReferencesTeamList,
  getReferenceListByCounsellorId,
} from "../../Redux/Actions/managerPanelAction";
import { getManagerReferencesByTeamLeadId } from "../../Redux/Actions/managerPanelAction";
import moment from "moment";
import { useSelector } from "react-redux";
import SubmitButton from "../../Component/submitButton";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SAReferenceAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [Graphdata2, setGraphdata2] = useState<any>([]);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);
  const [counsellorList, setCounsellorList] = useState<any>([]);
  const [counsellorByIdList, setCounsellorByIdList] = useState<any>([]);
  const [showList, setShowList] = useState<any>(false);
  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const managerId = sessionStorage.getItem("managerId");

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  useEffect(() => {
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      managerId: managerId,
    };
    dispatch(
      getManagerReferencesTeamList(
        postdata,
        (res: any) => {
          setTeamLeadList(res?.data);
          showGraph(res?.data);
        },
        () => {}
      )
    );
  }, []);
  const showGraph = (list: any) => {
    setXLabel([]);
    setGraphdata1([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((val: any) => {
      labelList?.push(val?.name);
      graphDataList?.push(val?.studentleadCount);
    });
    setXLabel(labelList);
    setGraphdata1(graphDataList);
  };
  const showGraph2 = (list: any) => {
    setXLabel2([]);
    setGraphdata2([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((val: any) => {
      labelList?.push(val?.name);
      graphDataList?.push(val?.studentleadCount);
    });
    setXLabel2(labelList);
    setGraphdata2(graphDataList);
  };

  const onClickLead = (data: any) => {
    navigate("../sMCompleteDetails", {
      state: {
        data: data,
        counsellorId: data?.counsellorId,
        pageName: "SMrefrenceGeneratedAnalysis",
        startDate,
        endDate,
      },
    });
  };
  const renderListMode = () => {
    return (
      <div>
        <GenaralTable
          tableData={
            counsellorByIdList[0]?.data ? counsellorByIdList[0]?.data : []
          }
          pagename={"ReferenceSummeryOverview"}
          tableHead={[
            "First Name",
            "Last Name",
            "Exam - Exam Stream",
            "Enrolled",
            "Revenue",
          ]}
          handleClick={onClickLead}
        />
      </div>
    );
  };
  const getManagerData = (val: any) => {
    setShowList(true);
    dispatch(
      getManagerReferencesByTeamLeadId(
        teamLeadList[val?.index]?._id,

        (res: any) => {
          setCounsellorList(res?.data);
          showGraph2(res?.data);
        },
        () => {}
      )
    );
  };

  const getCouncellorData = (val: any) => {
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      type: 2,
    };
    dispatch(
      getReferenceListByCounsellorId(
        counsellorList[val?.index]?._id?._id,
        postdata,
        (res: any) => {
          if (res?.data?.length > 0) {
            setCounsellorByIdList(res?.data);
          } else {
            setCounsellorByIdList([]);
          }
        }
      )
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postdata = {
      startDate: startDate,
      endDate: endDate,
    };
  };
  return (
    <div className="contentBox">
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          <h3 className="fw-bold text-primary">
            References Generated Analysis
          </h3>
          <form className=" d-flex px-5  mx-5" onSubmit={handleSubmit}>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-between mb-3">
              <div className="col-md-6 me-2 mt-3">
                <SubmitButton name={"Search"} />
              </div>
            </div>
          </form>
          <div className="  p-3">
            <h4 className="fw-bold">Summary Overview</h4>
            <SalesPanelSatckGraph
              label={xLabel}
              value={Graphdata1}
              getManagerData={(val: any) => getManagerData(val)}
            />
          </div>
          {showList ? (
            <>
              <div className="  p-3">
                <h4 className="fw-bold"> Councellor Summary Overview</h4>
                <SalesPanelSatckGraph
                  label={xLabel2}
                  value={Graphdata2}
                  getManagerData={(val: any) => getCouncellorData(val)}
                />
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <span className="fw-bold me-2">Total Leads :</span>
                  <span className="me-3">
                    {counsellorByIdList[0]?.totalLeads}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fw-bold me-2">Enrolled Leads :</span>
                  <span className="me-3">
                    {counsellorByIdList[0]?.enrolledLeads}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fw-bold me-2">Total Revenue :</span>
                  <span className="me-3">
                    {counsellorByIdList[0]?.totalRevenue}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fw-bold me-2">Overall Conversion :</span>
                  <span className="me-3">
                    {counsellorByIdList[0]?.overallConversion}
                  </span>
                </div>
              </div>
              {renderListMode()}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
