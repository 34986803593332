import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import PageTitle from '../../Component/pageTitle';
import DatePicker from '../../Component/datePicker';
import LabelField from '../../Component/labelField';
import SubmitButton from '../../Component/submitButton';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDailyMeetingForByManagerId } from '../../Redux/Actions/managerPanelAction';
export default function LeadAnalysis() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, 'day').format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, 'day').format("YYYY-MM-DD")
  );
  const [dailyMeetingList, setDailyMeetingList] = useState<any>([]);

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  useEffect(() => {
      dispatch(
        getDailyMeetingForByManagerId(
          managerId,
          {
            startDate,
            endDate,
          },
          (res: any) => {
            setDailyMeetingList(res?.data);
          },
          () => { }
        )
      );
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data = {
      startDate,
      endDate,
    };

    dispatch(
      getDailyMeetingForByManagerId(
        managerId,
        data,
        (res: any) => {
          setDailyMeetingList(res?.data);
        },
        () => { }
      )
    );

  }

  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSubmit}>
        <div className="col-md-4 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <div className="salesTable table-responsive">
        {dailyMeetingList?.map((item:any)=>{
          return (
            <table className="w-100 mb-2">
              <thead>
              <tr>
                  <th className="bg-body text-align-center" colSpan={2}>{item?.managerName}</th>
                  <th className="bg-body text-align-center" colSpan={4}>Total Sales Revenue Target :{item?.totalSalesRevenueTarget}</th>
                  <th className="bg-body text-align-center" colSpan={4}>Total Sales Revenue Achieved :{item?.totalSalesRevenue}</th>
                </tr>
                <tr>
                  <th>Team Lead Name</th>
                  {/* <th>Manager Name</th>
                  <th>Lead Type</th> */}
                  <th>Sales Revenue Target</th>
                  <th>Sales Revenue Achieved</th>
                  {/* <th>Prospect For Today</th> */}
                  <th>% Achieved Sales</th>
                  <th>Team Size</th>
                  <th>Present Counsellor</th>
                  <th>Absent</th>
                  <th>Shrinkage %</th>
                  <th>Calling Complete</th>
                  <th>Total Call Time</th>
                  <th>% Achieved Call Time</th>
                  <th>References Generated</th>
                  <th>References Target</th>
                  <th>References Deficite</th>
                </tr>
              </thead>
              <tbody>
                {
                  item?.TL_LIST?.map((subItem:any)=>{
                    return (
                      <tr>
                        <td>
                          {subItem?.name ? subItem?.name : "-"}
                        </td>
                        {/* <td>
                          {subItem?.name ? subItem?.managerName : "-"}
                        </td>
                        <td>
                          {subItem?.leadType ? subItem?.leadType : "-"}
                        </td> */}
                        <td>
                          {subItem?.salesRevenueTarget || subItem?.salesRevenueTarget >= 0 ? subItem?.salesRevenueTarget : "-"}
                        </td>
                        <td>
                          {subItem?.salesRevenueAchieved || subItem?.salesRevenueAchieved >= 0 ? subItem?.salesRevenueAchieved : "-"}
                        </td>
                        {/* <td>
                          {subItem?.prospectForToday ? subItem?.prospectForToday : "-"}
                        </td> */}
                        <td>
                          {subItem?.percentAchievedSales || subItem?.percentAchievedSales > 0 ? subItem?.percentAchievedSales : "-"}
                        </td>
                        <td>
                          {subItem?.teamSize ? subItem?.teamSize : "-"}
                        </td>
                        <td>
                          {subItem?.present || subItem?.present >= 0 ? subItem?.present : "-"}
                        </td>
                        <td>
                          {subItem?.absent || subItem?.absent >= 0 ? subItem?.absent : "-"}
                        </td>
                        <td>
                          {subItem?.shrinkagePercentage || subItem?.shrinkagePercentage >= 0 ? subItem?.shrinkagePercentage : "-"}
                        </td>
                        <td>
                          {subItem?.callingComplete || subItem?.callingComplete >= 0 ? subItem?.callingComplete : "-"}
                        </td>
                        <td>
                          {subItem?.totalTalkTime || subItem?.totalTalkTime >= 0 ? subItem?.totalTalkTime : "-"}
                        </td>
                        <td>
                          {subItem?.achievedCallTime || subItem?.achievedCallTime >= 0 ? subItem?.achievedCallTime : "-"}
                        </td>
                        <td>
                          {subItem?.referenceGenerated || subItem?.referenceGenerated >= 0 ? subItem?.referenceGenerated : "-"}
                        </td>
                        <td>
                          {subItem?.referenceLeadTarget || subItem?.referenceLeadTarget >= 0 ? subItem?.referenceLeadTarget : "-"}
                        </td>
                        <td>
                          {subItem?.referenceDeficit || subItem?.referenceDeficit >= 0 ? subItem?.referenceDeficit : "-"}
                        </td>
      
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          );
        })}
        
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Daily Meeting"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}

