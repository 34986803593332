import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Component/customDropdown";
import PageTitle from "../../Component/pageTitle";
import SubmitButton from "../../Component/submitButton";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  getExamStreamList,
  getExamList,
  getMainCategoryList,
  getBatchName,
  getSubExam,
  getCourseSubRevenueTLeadList,
} from "../../Redux/Actions/counsellorPanelAction";
import moment from "moment";
import { useSelector } from "react-redux";

export default function CourseSubscriptionMonths() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [examStream, setExamStream] = useState<any>("");
  const [examStreamList, setExamStreamList] = useState([]);
  const [resetExamStream, setResetExamStream] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [batchName, setBatchName] = useState<any>("");
  const [batchList, setBatchList] = useState([]);
  const [resetBatchName, setResetBatchName] = useState(false);
  const [categoryName, setCategoryName] = useState<any>("");
  const [categoryList, setCategoryList] = useState([]);
  const [resetCategoryName, setResetCategoryName] = useState(false);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [courseSubRevenueList, setCourseSubRevenueList] = useState<any>([]);
  const [subExam, setSubExam] = useState<any>();
  const [subExamList, setSubExamList] = useState([]);
  const [resetSubExam, setResetSubExam] = useState();

  const { teamLeadId } = useSelector((state: any) => ({
    teamLeadId: state.Auth.counsellorId,
  }));

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
    const postData = {
      startDate: startDate,
      endDate: endDate,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      courseId: batchName?._id,
      subCategoryId: categoryName?._id,
    };
    dispatch(
      getCourseSubRevenueTLeadList(
        teamLeadId,
        postData,
        (res: any) => {
          setCourseSubRevenueList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeExam = (val: any) => {
    setExam(val);
    if (subExamList) {
      dispatch(
        getSubExam(
          {
            examTypeId: val._id,
          },
          (res: any) => {
            if (res.length > 0) {
              setSubExamList(res);
            } else {
              dispatch(
                getExamStreamList(`${val._id}`, (res: any) => {
                  setExamStreamList(res);
                })
              );
            }
          }
        )
      );
    } else {
      dispatch(
        getExamStreamList(`${val._id}`, (res: any) => {
          setExamStreamList(res);
        })
      );
    }
  };

  const onChangeSubExam = (val: any) => {
    setSubExam(val);
    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: any) => {
    setExamStream(val);

    dispatch(
      getBatchName(
        {
          examTypeId: exam?._id,
          baseCourseId: val?._id,
        },
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
    dispatch(
      getMainCategoryList(
        {
          examTypeId: exam?._id,
          baseCourseId: val?._id,
        },
        (res: any) => {
          setSubcategoryList(res);
        },
        () => {}
      )
    );
  };

  const onChangeBatchName = (val: any) => {
    setBatchName(val);
  };
  const onChangeCategoryName = (val: any) => {
    setCategoryName(val);
  };

  const getList = () => {};

  const handleSearch = (e: any) => {
    e.preventDefault();
    getList();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      baseCourseId: examStream?._id,
      examTypeId: exam?._id,
      courseId: batchName?._id,
      subCategoryId: categoryName?._id,
    };
    dispatch(
      getCourseSubRevenueTLeadList(
        teamLeadId,
        postData,
        (res: any) => {
          setCourseSubRevenueList(res);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <>
        <form
          className="row d-flex justify-content-between"
          onSubmit={handleSearch}
        >
          <div className="col-md-4 d-flex mb-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam"
                setInputText={(value: any) => onChangeExam(value)}
                value={exam}
                options={examList}
                // Isrequired={true}
                reset={resetExam}
              />
            </div>
          </div>
          {subExamList?.length > 0 ? (
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Sub Exam Name" />
              </div>
              <div className="col-md-9">
                <CustomDropdown
                  lableName="Select Sub Exam Name"
                  setInputText={(value: any) => onChangeSubExam(value)}
                  value={subExam}
                  options={subExamList}
                  reset={resetSubExam}
                  // reset={resetSubexamList}
                />
              </div>
            </div>
          ) : null}
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Stream" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam Stream"
                setInputText={(value: any) => onChangeExamStream(value)}
                value={examStream}
                options={examStreamList}
                // Isrequired={true}
                reset={resetExamStream}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Batch Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Batch Name"
                setInputText={(value: any) => onChangeBatchName(value)}
                value={batchName}
                options={batchList}
                // Isrequired={true}
                reset={resetBatchName}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Category Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Category Name"
                setInputText={(value: any) => onChangeCategoryName(value)}
                value={categoryName}
                options={subcategoryList}
                // Isrequired={true}
                reset={resetCategoryName}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between mb-3">
            <div className="col-md-6 me-2">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="counsellortable table-responsive w-100">
          {/* <table className='text-center table-bordered border-secondary w-100'> */}

          {courseSubRevenueList?.data?.map((item: any) => {
            return (
              <>
                {item?.data?.courses?.map((val: any) => {
                  return (
                    <>
                      <div className="tableheader text-start fw-bold">
                        <div className="d-flex justify-content-between">
                          <>
                            {val?._id?.baseCourseId?.name} (
                            {val?._id?.examTypeId?.name})
                          </>
                          {/* <>
                          {item?.data?.firstName} {item?.data?.lastName}
                          </> */}
                        </div>
                      </div>

                      {val?.subData?.map((subItem: any) => {
                        return (
                          <>
                            {subItem?.data?.map((subVal: any) => {
                              return (
                                <>
                                  <table className="text-center table-bordered border-secondary w-100">
                                    <thead>
                                      <tr>
                                        <th rowSpan={4}>Date</th>
                                        <th
                                          className="heading  position-relative"
                                          colSpan={19}
                                        >
                                          {val?._id?.mainCategoryId?.name}
                                          <span className="position-absolute end-0 px-2 text-success ">
                                            Total Revenue = {val?.mainRevenue}
                                          </span>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th
                                          className="heading position-relative"
                                          colSpan={28}
                                        >
                                          {val?.subData?.map((subCat: any) => {
                                            return (
                                              <>
                                                {subCat?.subCategoryId?.name}
                                                <span className="position-absolute end-0 px-2 text-success">
                                                  Sub Revenue ={" "}
                                                  {subCat?.subTotalRevenue}
                                                </span>
                                              </>
                                            );
                                          })}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th colSpan={2}>24 M</th>
                                        <th colSpan={2}>12 M</th>
                                        <th colSpan={2}>9 M</th>
                                        <th colSpan={2}>6 M</th>
                                        <th colSpan={2}>5 M</th>
                                        <th colSpan={2}>4 M</th>
                                        <th colSpan={2}>3 M</th>
                                        <th colSpan={2}>2 M</th>
                                        <th colSpan={2}>1 M</th>
                                      </tr>
                                      <tr>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                        <th>Enrolled</th>
                                        <th>Revenue</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {subItem?.data?.map((data: any) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{data?.date}</td>
                                              {data?.enrolledCount_24 ? (
                                                <td>
                                                  {data?.enrolledCount_24}
                                                </td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_24 ? (
                                                <td>{data?.revenue_24}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_12 ? (
                                                <td>
                                                  {data?.enrolledCount_12}
                                                </td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_12 ? (
                                                <td>{data?.revenue_12}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_9 ? (
                                                <td>{data?.enrolledCount_9}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_9 ? (
                                                <td>{data?.revenue_9}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_6 ? (
                                                <td>{data?.enrolledCount_6}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_6 ? (
                                                <td>{data?.revenue_6}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_5 ? (
                                                <td>{data?.enrolledCount_5}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_5 ? (
                                                <td>{data?.revenue_5}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_4 ? (
                                                <td>{data?.enrolledCount_4}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_4 ? (
                                                <td>{data?.revenue_4}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_3 ? (
                                                <td>{data?.enrolledCount_3}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_3 ? (
                                                <td>{data?.revenue_3}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_2 ? (
                                                <td>{data?.enrolledCount_2}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_2 ? (
                                                <td>{data?.revenue_2}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_1 ? (
                                                <td>{data?.enrolledCount_1}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                              {data?.enrolledCount_1 ? (
                                                <td>{data?.revenue_1}</td>
                                              ) : (
                                                <td>0</td>
                                              )}
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </>
            );
          })}

          {/* </table> */}

          {/* <table className='text-center table-bordered border-secondary w-100'>

           
                      <>
                       <tr >
                        <th colSpan={11} >
                          <div className='tableheader text-start fw-bold' >Life Science ( CSIR-NET)</div>
                        </th>
                        </tr>
                        <tr >
                          <th rowSpan={4}>Date</th>
                          <th className="heading position-relative " colSpan={10}>Study Material<span className='position-absolute end-0 px-2 text-success '>Total Revenue = </span></th>
                        </tr>
                        <tr >
                          <th className="heading position-relative" colSpan={10}>cbcbc<span className='position-absolute end-0 px-2 text-success'>Sub Revenue = </span></th>
                        </tr>
                        <tr >
                           <th colSpan={2}>Complete</th>
                           <th colSpan={2}>Individual</th>
                           <th colSpan={2}>Combo</th>
                           <th colSpan={2}>Practice</th>
                           <th colSpan={2}>Pre-Book</th>  
                        </tr>
                     
                        <tr >
                         <th>Enrolled</th>
                         <th>Revenue</th>
                         <th>Enrolled</th>
                         <th>Revenue</th>
                         <th>Enrolled</th>
                         <th>Revenue</th>
                         <th>Enrolled</th>
                         <th>Revenue</th>
                         <th>Enrolled</th>
                         <th>Revenue</th>
                          
                        </tr>
                        <tbody><tr>
                       
                          <td>22-11-2023</td>
                           <td>1</td>
                           <td>5000</td>
                           <td>1</td>
                           <td>5000</td>
                           <td>1</td>
                           <td>5000</td>
                           <td>1</td>
                           <td>5000</td>
                           <td>1</td>
                           <td>5000</td>
                          </tr>
                        </tbody>
                      </>
                
            
          </table> */}
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name="Course Subscription Months" />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">
          {renderSearchMode()}
          <div>
            <h4 className="fw-bold">
              Grand Total={" "}
              <span className="text-success">
                {courseSubRevenueList?.total}
              </span>
            </h4>
          </div>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
