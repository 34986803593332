import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import moment from "moment";
import {
  getDeptAnalysisDetails,
  getExecutiveDeptTargetDetails,
  getLeadDepartmentCount,
} from "../../Redux/Actions/bdManagerPanelAction";
import GenaralTable from "../../Component/genaralTable";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function DepartmentOverViewDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [startDate, setStartDate] = useState<any>(
  //   moment.utc(new Date()).format("YYYY-MM-DD")
  // );
  // const [endDate, setEndDate] = useState<any>(
  //   moment.utc(new Date()).format("YYYY-MM-DD")
  // );
  const [startDate, setStartDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [leadCount, setLeadCount] = useState<any>("");
  const [webinarCount, setWebinarCount] = useState<any>("");
  const [departmentCount, setDepartmentCount] = useState<any>("");
  const [deptAnalysisList, setDeptAnalysisList] = useState([]);
  const [deptTargetDetails, setDeptTargetDetails] = useState<any>([]);

  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);

  useEffect(() => {
    onChangeGetRevenue();
    dispatch(
      getExecutiveDeptTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphExecutiveDept(res);
          setDeptTargetDetails(res);
        },
        () => {}
      )
    );
  }, []);

  const showGraphExecutiveDept = (list: any) => {
    let total = 0;
    while (xLabel3.length) {
      xLabel3.pop();
      Graphdata3?.pop();
    }
    list?.achieved?.map((item: any) => {
      total = total + item?.count;
      xLabel3?.push(item?.executiveName);
      Graphdata3?.push(item?.count);
    });
  };

  const onChangeGetRevenue = () => {
    dispatch(
      getExecutiveDeptTargetDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          showGraphExecutiveDept(res);
          setDeptTargetDetails(res);
          dispatch(
            getLeadDepartmentCount(
              {
                startDate,
                endDate,
              },
              (res: any) => {
                setDepartmentCount(res?.departmentCount);
                setWebinarCount(res?.webinarCount);
                setLeadCount(res?.leadCount);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const getData = (val: any) => {
    const data = {
      startDate,
      endDate,
      executiveId: deptTargetDetails?.achieved[val?.index]?.executiveId,
    };
    dispatch(
      getDeptAnalysisDetails(
        data,
        (res: any) => {
          setDeptAnalysisList(res?.data);
        },
        () => {}
      )
    );
  };
  const renderListMode = () => {
    return (
      <div className="col-12 formWidth">
        <GenaralTable
          tableData={deptAnalysisList ? deptAnalysisList : []}
          pagename={"DepartmentOverViewDetails"}
          tableHead={[
            "Name",
            "College Name",
            "Department",
            "Total Webinar",
            "Webinar Url",
          ]}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Department OverView"} />

      <div className="formDiv ">
        <div className=" col-md-12 formWidth ">
          <div className=" counsellorDashbord d-flex justify-content-center px-2 mx-5">
            <div className="col-md-9 d-flex ">
              <div className="col-md-4 d-flex justify-content-center pe-2 mt-3">
                <div className="col-md-4 d-flex flex-row-reverse">
                  <LabelField lableName="Department Added :" />
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-center h-75 bg-white rounded border text-center fw-bold text-success fs-4">
                    {departmentCount ? departmentCount : 0}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex mt-3 ">
                <div className="col-md-5 d-flex flex-row-reverse mb-3">
                  <LabelField lableName="Start Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => setStartDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex me-2  mt-3">
                <div className="col-md-5 d-flex flex-row-reverse mb-3">
                  <LabelField lableName="End Date:" />
                </div>
                <div className="col-md-7">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => setEndDate(value)}
                    maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center me-2  ">
              <button className="btn" onClick={onChangeGetRevenue}>
                Search
              </button>
            </div>
          </div>
            {/* *************************************Lead OverView********************************************* */}
          <div className="analysisDiv">
            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-7 p-3">
                <h4 className="fw-bold">Department Overview </h4>

                <BarGraph
                  label={xLabel3}
                  value={Graphdata3}
                  getData={(val: any) => getData(val)}
                />
              </div>
            </div>

            {/* ********************************************Talk Time list********************************************* */}

            {renderListMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
