import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import SubmitButton from "../../Component/submitButton";
import InputField from "../../Component/inputField";
import { startsWithSpace, validateFullName } from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import LabelField from "../../Component/labelField";
import Button from "../../Component/button";
import {
  deleteAssignedStatesZoneWise,
  getZonesList,
  updateAssignedStatesZoneWise,
} from "../../Redux/Actions/leadManagementAction";
import RestorePopup from "../../Component/restorePopup";

export default function EditDeleteAssignedStatesZoneWise() {
  const dispatch = useDispatch();

  const [Id, setId] = useState<any>("");
  const [zoneName, setZoneName] = useState<any>("");
  const [zoneList, setZoneList] = useState<any>([]);

  const [isEditMode, setisEditMode] = useState<any>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<any>(false);

  useEffect(() => {
    fetchZone();
  }, []);

  const fetchZone = () => {
    dispatch(
      getZonesList((res: any) => {
        if (res?.data?.length > 0) {
          setZoneList(res?.data);
        } else {
          setZoneList([]);
        }
      })
    );
  };

  const handleDelete = (val: any) => {
    setOpenDeletePopup(true);
    setisEditMode(false);
    setId(val?._id);
    setZoneName(val?.name);
  };

  const handleCloseDelete = () => {
    setOpenDeletePopup(false);
  };

  const handleSubmitDelete = async () => {
    setOpenDeletePopup(false);
    dispatch(
      deleteAssignedStatesZoneWise(Id, () => {
        fetchZone();
      })
    );
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setId(value?._id);
    setZoneName(value?.name);
  };

  const onChangeZone = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setZoneName(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const cancelEditZone = () => {
    setisEditMode(false);
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let postData = {
      name: zoneName?.toUpperCase(),
    };

    dispatch(
      updateAssignedStatesZoneWise(Id, postData, () => {
        setId("");
        setZoneName("");
        setisEditMode(false);
        fetchZone();
      })
    );
  };

  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={zoneList ? zoneList : []}
          pagename={"zoneList"}
          tableHead={["S.N", "Zone Name", "Edit", "Delete"]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <form className="col-md-12 shadow-lg py-5 px-3" onSubmit={handleUpdate}>
          <div className="row d-flex justify-content-between">
            <div className="col-md-6 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Zone Name" />
              </div>
              <div className="col-md-9">
                <InputField
                  placeholder="Enter Zone Name"
                  value={zoneName}
                  onChangeInput={(value: any) => onChangeZone(value)}
                  isRequired={true}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center me-3">
            <div className="me-2">
              <Button name={"Cancel"} handleClick={cancelEditZone} />
            </div>
            <div>
              <SubmitButton name={"Save & Update"} validation={validation()} />
            </div>
          </div>
        </form>
      </>
    );
  };

  const validation = () => {
    return zoneName === "";
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT / DELETE ZONE"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="formDiv">
        <div className="col-md-12  px-4 mx-0 mt-3">
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
      {openDeletePopup ? (
        <RestorePopup
          open={openDeletePopup}
          handleClose={handleCloseDelete}
          handleSubmitRestore={() => {
            handleSubmitDelete();
          }}
          title={"Delete Zone"}
          data={"Do you want to delete this ?"}
          buttonTitle={"Delete"}
        />
      ) : (
        ""
      )}
    </div>
  );
}
