import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DatePicker from "../../Component/datePicker";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import SubmitButton from "../../Component/submitButton";
import usePagination from "../../Component/usePagination";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import moment from "moment";
import {
  getLeadCountExamwise,
  getLeadCountStatewise,
  getTotalLeadCount,
  getTotalLeadCountByDate,
} from "../../Redux/Actions/leadManagementAction";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function LeadOverview() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const leadData = location.state?.leadData;
  const leadType = location.state?.leadType;
  const [startDate, SetStartDate] = useState<any>(
    leadData?.date ? leadData?.date : leadData?.startDate
  );
  const [endDate, SetEndDate] = useState<any>(
    leadData?.date ? leadData?.date : leadData?.endDate
  );
  const [openSoucewise, setOpenSoucewise] = useState(false);
  const [openStatewise, setOpenStatewise] = useState(false);
  const [openSoucewiseList, setOpenSoucewiseList] = useState<any>([]);
  const [openStatewiseList, setOpenStatewiseList] = useState<any>([]);
  const [statewiseList, setStatewiseList] = useState<any>([]);

  useEffect(() => {
    const data: any = {
      startDate: leadData?.date ? leadData?.date : leadData?.startDate,
      endDate: leadData?.date ? leadData?.date : leadData?.endDate,
      status: "",
    };
    if (leadType === "assigned") {
      data["status"] = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }
    if (leadType === "unassigned") {
      data["status"] = [1];
    }
    if (leadType === "all") {
      delete data["status"];
    }
    dispatch(
      getTotalLeadCount(
        data,
        (res: any) => {
          setOpenSoucewiseList(res);
        },
        () => {}
      )
    );
  }, []);

  const gotoSourceWiselead = (val: any) => {
    setOpenSoucewise(true);
    // setOpenStatewise(true);
    const data: any = {
      startDate: leadData?.date ? leadData?.date : leadData?.startDate,
      endDate: leadData?.date ? leadData?.date : leadData?.endDate,
      type: val?.type,
      status: "",
    };
    if (leadType === "assigned") {
      data["status"] = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }
    if (leadType === "unassigned") {
      data["status"] = [1];
    }
    if (leadType === "all") {
      delete data["status"];
    }
    dispatch(
      getLeadCountExamwise(
        data,
        (res: any) => {
          setOpenStatewiseList(res);
        },
        () => {}
      )
    );
  };
  const gotoStateWiseList = (val: any) => {
    setOpenStatewise(true);
    const data: any = {
      startDate: leadData?.date ? leadData?.date : leadData?.startDate,
      endDate: leadData?.date ? leadData?.date : leadData?.endDate,
      type: val?.type,
      status: "",
      baseCourseId: val?.basecourseId,
      examTypeId: val?.examTypeId,
    };
    if (leadType === "assigned") {
      data["status"] = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }
    if (leadType === "unassigned") {
      data["status"] = [1];
    }
    if (leadType === "all") {
      delete data["status"];
    }
    dispatch(
      getLeadCountStatewise(
        data,
        (res: any) => {
          setStatewiseList(res);
        },
        () => {}
      )
    );
  };
  const handleSearch = (e: any) => {
    e.preventDefault();

    const data = {
      startDate,
      endDate,
    };

    dispatch(
      getTotalLeadCountByDate(
        data,
        (res: any) => {
          // setLeadsDataList(res[0]?.totalData);
          // let arr = [];
          // arr.push(res[0]?.totalLeads);
          // arr.push(res[0]?.totalAssignedLead);
          // arr.push(res[0]?.totalUnassignedLead);
          // setTotalLeadsDataList(arr);
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <form className="col-md-12 d-flex " onSubmit={handleSearch}>
        <div className="col-md-4 d-flex mb-3 me-2 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3 me-2">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
              disabled={true}
            />
          </div>
        </div>

        {/* <div className="col-md-4 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div> */}
      </form>
    );
  };

  const renderListWithSource = () => {
    return (
      <div className="col-12 formWidth">
        <table className=" w-100 ">
          <tr className=" ">
            <th>Date </th>
            <th>Source</th>
            <th>Count</th>
          </tr>
          <tbody className=" ">
            <tr>
              <td rowSpan={openSoucewiseList[0]?.sources?.length + 1}>
                {leadData?.date}
              </td>
              <td className="fw-bold">Total</td>
              <td className="fw-bold">
                {openSoucewiseList[0]?.totalStatusCount}
              </td>
            </tr>
            {openSoucewiseList[0]?.sources?.map((item: any) => {
              return (
                <tr>
                  <td>{item?.source}</td>
                  <td onClick={() => gotoSourceWiselead(item)}>
                    <span className="leaddetailsOverView cursor">
                      {item?.statusCount}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderListSourceWise = () => {
    return (
      <div className="col-12 formWidth">
        <table className=" w-100 ">
          <tr className=" ">
            <th>Source </th>
            <th>Exam Name</th>
            <th>Exam Stream</th>
            <th>Count</th>
          </tr>
          <tbody className=" ">
            <td className="fw-bold text-end" colSpan={3}>
              Total
            </td>
            <td className="fw-bold">
              {openStatewiseList[0]?.totalStatusCount}
            </td>

            {openStatewiseList[0]?.sources?.map((item: any) => {
              return (
                <tr>
                  <td>{item?.source}</td>
                  <td>{item?.examTypeName}</td>
                  <td>{item?.basecourseName}</td>
                  <td onClick={() => gotoStateWiseList(item)}>
                    <span className="leaddetailsOverView cursor">
                      {item?.statusCount}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderListStateWise = () => {
    return (
      <div className="col-12 formWidth">
        <table className=" w-100 ">
          <tr className=" ">
            <th>Source </th>
            <th>State</th>
            <th>Count</th>
          </tr>
          <tbody className=" ">
            <td className="fw-bold text-end" colSpan={2}>
              Total
            </td>
            <td className="fw-bold">{statewiseList[0]?.totalStatusCount}</td>
            {statewiseList[0]?.sources?.map((item: any) => {
              return (
                <tr>
                  <td>{item?.source}</td>
                  <td>{item?.stateName}</td>
                  <td>
                    <span className="leaddetailsOverView cursor">
                      {item?.statusCount}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <div className="d-flex">
        <ArrowBackIosIcon
          className="mt-3 ms-3"
          color="primary"
          onClick={() => navigate(-1)}
        />
        <PageTitle name={"Total Generated Leads"} />
      </div>
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {!openSoucewise && !openStatewise ? renderListWithSource() : ""}
          {openSoucewise && !openStatewise ? renderListSourceWise() : ""}
          {openStatewise && openSoucewise ? renderListStateWise() : ""}
        </div>
      </div>
    </div>
  );
}
