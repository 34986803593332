import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import SubmitButton from "../../Component/submitButton";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import {
  containsNumber,
  length_Ten,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  getCronTimeByExam,
  updateCronTimeByExam,
} from "../../Redux/Actions/leadManagementAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import CustomDropdown from "../../Component/customDropdown";
import { getExamList } from "../../Redux/Actions/counsellorPanelAction";

export default function CronTime() {
  const dispatch = useDispatch();

  const [id, setId] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [updatedCronTime, setUpdatedCronTime] = useState("");
  const [examList, setExamList] = useState([]);

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
  }, []);

  const onChangeExam = (value: any) => {
    setExam(value);
    dispatch(
      getCronTimeByExam(
        value?._id,
        (res: any) => {
          setId(res?._id);
          setUpdatedCronTime(res?.cronTime_minute);
        },
        () => {}
      )
    );
  };

  const onchangeCronTime = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setUpdatedCronTime(value);
      }
    } else {
      setUpdatedCronTime(value);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      cronTime_minute: updatedCronTime,
    };
    dispatch(
      updateCronTimeByExam(
        id,
        postData,
        () => {},
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <form className="" onSubmit={handleSubmit}>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name:" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onChangeExam(value)}
              value={exam}
              options={examList}
            />
          </div>
        </div>
        <div className="d-flex mb-2 col-4">
          <LabelField lableName={"Cron Time :"} />
          <InputField
            placeholder="Enter Cron Time"
            value={updatedCronTime}
            onChangeInput={(value: any) => onchangeCronTime(value)}
            maxlength={length_Ten}
            // minlength={length_Two}
          />
        </div>
        <div className="d-flex mb-2 col-4 me-4">
          <div>
            <SubmitButton name={"Update"} />
          </div>
        </div>
      </form>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Cron Time"} />
      <div className="row m-1">
        <div className="formDiv">
          <div className="row d-flex w-100">
            <div className="px-3 col-12 mt-3">
              <div></div>
            </div>
            {renderSearchMode()}
          </div>
        </div>
      </div>
    </div>
  );
}
