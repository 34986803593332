import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate ,useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import {
  getCallBackTodayAndInterestedTodaylist,
  getExecutiveByManagerID,
} from "../../Redux/Actions/bdManagerPanelAction";
import moment from "moment";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";

export default function NotIntrestedList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sDate = location.state?.startDate;
  const [startDate, SetStartDate] = useState<any>(
    sDate ? sDate : moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const eDate = location.state?.endDate;
  const [endDate, SetEndDate] = useState<any>(
    eDate ? eDate : moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [executive, setExecutive] = useState<any>("");
  const [executiveList, setExecutiveList] = useState([]);
  const [ResetExecutive, setResetExecutive] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(
      getExecutiveByManagerID(
        "",
        (res: any) => {
          setExecutiveList(res);
        },
        () => {}
      )
    );
    const postData = {
      startDate: startDate,
      endDate: endDate,
      status: 2,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getCallBackTodayAndInterestedTodaylist(
        postData,
        (res: any) => {
          setDepartmentList(res?.departmentData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: 2,
        startDate: startDate,
        endDate: endDate,
        executiveId: executive?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: 2,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: 2,
        startDate: startDate,
        endDate: endDate,
        executiveId: executive?._id,
      };
    }
    dispatch(
      getCallBackTodayAndInterestedTodaylist(
        data,
        (res: any) => {
          setDepartmentList(res?.departmentData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeBDExecutive = (val: any) => {
    setExecutive(val);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      executiveId: executive?._id,
      status: 2,
      skip: 0,
      limit: rowsPerPage,
    };
    dispatch(
      getCallBackTodayAndInterestedTodaylist(
        postData,
        (res: any) => {
          setDepartmentList(res?.departmentData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const onClickLead = (data: any) => {
    navigate("../webinarStatusAnalysis", {
      state: {
        pageName: "interestedBDTeamLead",
        departmentId: data?._id,
        departmentData: data,
        IsEdit: "true",
        IsCheck: "true",
        startDate: startDate,
        endDate: endDate,
      },
    });
  };

  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        <div className="col-md-12 row d-flex">
          <div className="col-md-4 row d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
                Isrequired={true}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
                Isrequired={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 d-flex">
          <div className="col-md-4 d-flex my-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="BD Executive" />
            </div>
            <div className="col-md-9">
              <CustomDropdown
                lableName="Select BD Executive"
                setInputText={(value: any) => onChangeBDExecutive(value)}
                value={executive}
                options={executiveList}
                reset={ResetExecutive}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex my-3 ms-3">
            <div className="col-md-4">
              <button className="btn btn-primary " type="submit">
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={departmentList ? departmentList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <div>
            <PaginationTable
              tableData={departmentList ? departmentList : []}
              pagename={"interestedDepartment"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Co-ordinator Name",
                "College Name",
                "Exam Name",
                "Department",
                "Details",
              ]}
              edit={false}
              handleNavigate={onClickLead}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={" Interested Today"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12   formWidth">
          {SearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
