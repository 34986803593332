import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import moment from "moment";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import AddCollege from "../../Component/addCollege";
import { getState, getCity } from "../../Redux/Actions/bdeAdminPanelAction";
import {
  getBDCollegeList,
  getExecutiveByManagerID,
} from "../../Redux/Actions/bdManagerPanelAction";

export default function BDCollegeList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, SetStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [state, setState] = useState<any>("");
  const [stateList, setStateList] = useState([]);
  const [ResetState, setResetState] = useState(false);
  const [district, setDistrict] = useState<any>("");
  const [districtList, setDistrictList] = useState([]);
  const [ResetDistrict, setResetDistrict] = useState(false);
  const [executive, setExecutive] = useState<any>("");
  const [executiveList, setExecutiveList] = useState([]);
  const [ResetExecutive, setResetExecutive] = useState(false);
  const [bdeColgList, setBdeColgList] = useState([]);
  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  //   const [collegeList, setCollegeList] = useState([]);

  const { TeamLeadID } = useSelector((state: any) => ({
    TeamLeadID: state.Auth.executiveID,
  }));

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
        state: state?._id,
        district: district?._id,
        executiveId: executive?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
        state: state?._id,
        district: district?._id,
        executiveId: executive?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
        state: state?._id,
        district: district?._id,
        executiveId: executive?._id,
      };
    }

    dispatch(
      getBDCollegeList(
        data,
        (res: any) => {
          setBdeColgList(res?.collegeData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    dispatch(
      getState(
        "",
        (res: any) => {
          const allIndia = {
            name: "ALL INDIA",
            _id: "0",
          };
          res.unshift(allIndia);
          setStateList(res);
        },
        () => {}
      )
    );
    dispatch(
      getBDCollegeList(
        {
          startDate: startDate,
          endDate: endDate,
          skip: 0,
          limit: rowsPerPage,
        },
        (res: any) => {
          setBdeColgList(res?.collegeData);
          setCount(res?.count);
        },
        () => {}
      )
    );

    dispatch(
      getExecutiveByManagerID(
        "",
        (res: any) => {
          setExecutiveList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCity(
        val._id,
        (res: any) => {
          setDistrictList(res);
        },
        () => {}
      )
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      state: state?._id,
      district: district?._id,
      executiveId: executive?._id,
      skip: 0,
      limit: rowsPerPage,
    };
    if (
      postData?.executiveId === "" ||
      postData?.executiveId === undefined ||
      postData?.executiveId === null
    ) {
      delete postData?.executiveId;
    }
    dispatch(
      getBDCollegeList(
        postData,
        (res: any) => {
          setBdeColgList(res?.collegeData);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  const onChangeDistrict = (val: any) => {
    setDistrict(val);
  };
  const onChangeBDExecutive = (val: any) => {
    setExecutive(val);
  };

  const handleNavigate = (data: any) => {
    navigate("../showCollegeDetails", {
      state: {
        ColgId: data?._id,
      },
    });
  };

  const SearchMode = () => {
    return (
      <form
        className="row d-flex justify-content-between"
        onSubmit={handleSearch}
      >
        {/*  */}
        <div className="col-md-4 row d-flex my-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              Isrequired={true}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date:" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="BD Executive" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select BD Executive"
              setInputText={(value: any) => onChangeBDExecutive(value)}
              value={executive}
              options={executiveList}
              reset={ResetExecutive}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="State" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select State"
              setInputText={(value: any) => onChangeState(value)}
              value={state}
              options={stateList}
              reset={ResetState}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="District" />
          </div>
          <div className="col-md-9">
            <CustomDropdown
              lableName="Select District"
              setInputText={(value: any) => onChangeDistrict(value)}
              value={district}
              options={districtList}
              reset={ResetDistrict}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex my-3">
          <div className="col-md-4">
            <button className="btn btn-primary " type="submit">
              {" "}
              Search
            </button>
          </div>
        </div>
      </form>
    );
  };
  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={bdeColgList ? bdeColgList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <div>
            <PaginationTable
              tableData={bdeColgList ? bdeColgList : []}
              pagename={"TLcollegeList"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Collage Name",
                "Total Department",
                "Added Department",
                "Remaining Department",
                "Details",
              ]}
              handleNavigate={(value: any) => handleNavigate(value)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"College List"} />
      <div className="  p-2 formDiv">
        <div className="col-md-12   formWidth">
          {SearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
